<div class="mat-dialog-title" *ngIf="viewOnly">
	<p>
		<strong>{{'sensor.addAction.headline.prefix' | translate}} {{'sensor.addAction.label.'+parentType | translate}}
			"{{parentName}}"
		</strong>
	</p>
</div>
<div class="mat-dialog-title" *ngIf="!viewOnly">
	<p>
		<strong>{{'sensor.addAction.headline.prefix' | translate}} {{'sensor.addAction.label.'+parentType | translate}}
			"{{parentName}}"
			{{'sensor.addAction.headline.suffix' | translate}}</strong>
	</p>
</div>

<div class="form-field">
	<label for="name-id" appTranslate="general.label.name"></label>
	<input id="name-id" [(ngModel)]="alarmAction.name" maxlength="40" [disabled]="viewOnly" />
</div>

<div class="row">
	<div class="col-4">
		<div class="form-field" [class.error]="false">
			<label for="input-alarmLevel" appTranslate="alarmGroup.dialog.level.label"></label>
			<select [(ngModel)]="alarmAction.alarmLevel" [disabled]="viewOnly">
				<option [value]="null">Alle</option>
				<option value="-1" appTranslate="alarmGroup.dialog.level.-1"></option>
				<option value="1" appTranslate="alarmGroup.dialog.level.1"></option>
				<option value="2" appTranslate="alarmGroup.dialog.level.2"></option>
			</select>
		</div>
	</div>

</div>

<div class="row">
	<div class="col-7">
		<div class="form-field" [class.error]="false">
			<label for="input-actionType" appTranslate="sensor.addAction.label.actionType"></label>
			<select [(ngModel)]="alarmAction.actionType" [disabled]="viewOnly">
				<option [value]="null">-</option>
				<option *ngFor="let element of alarmActionTypes" [value]="element"
					[appTranslate]="'alarmAction.type.' + element"></option>
			</select>
		</div>
	</div>

	<div class="col-3 role">
		<div class="form-field">
			<label for="input-actionType" appTranslate="sensor.addAction.label.additionalAuth"></label>
			<label class="switch">
				<input type="checkbox" (click)="toggleAuth()" id="input-additionalAuth" [class.disabled]="viewOnly"
					[(ngModel)]="alarmAction.additionalAuth" [disabled]="viewOnly" />
				<span class="slider round" [class.disabled]="viewOnly"></span>
			</label>
		</div>
	</div>


</div>

<div class="row ch-margin-top-24" *ngIf="isLinkVisible()">
	<div class="col-12">
		<div class="form-field" [class.error]="false">
			<label for="input-link" appTranslate="sensor.addAction.label.link"></label>
			<input id="input-link" [(ngModel)]="alarmAction.properties.link" [disabled]="viewOnly" />
		</div>
	</div>
</div>

<div class="row ch-margin-top-24" *ngIf="isChannelVisible()">
	<div class="col-12">
		<div class="form-field" [class.error]="false">
			<label for="input-alarmGroupId" appTranslate="sensor.addAction.label.channel"></label>
			<select [(ngModel)]="alarmAction.properties.alarmChannelId" [disabled]="viewOnly">
				<option [value]="null">-</option>
				<option [ngValue]="entry.id" *ngFor="let entry of alarmChannelList">{{entry.name}}</option>
			</select>
		</div>
	</div>
</div>

<div class="row ch-margin-top-24" *ngIf="isTemplateVisible()">
	<div class="col-6">
		<label for="input-userId" appTranslate="{{ 'alarmGroup.member.dialog.template.alarm' | translate }}"></label>
		<select [(ngModel)]="alarmAction.properties.alarmMessageTemplateId" [disabled]="viewOnly">
			<option [value]="null" [selected]="!alarmAction.properties.alarmMessageTemplateId">
				-
				{{ "alarmGroup.member.dialog.template.default" | translate }}
				-
			</option>
			<option [ngValue]="entry.id" *ngFor="let entry of actionStartTemplateList">
				{{ entry.name }}
			</option>
		</select>
	</div>

	<div class="col-6">
		<label for="input-userId">{{
			"alarmGroup.member.dialog.template.allClear" | translate
			}}</label>
		<select [(ngModel)]="alarmAction.properties.clearMessageTemplateId" [disabled]="viewOnly">
			<option [value]="null" [selected]="!alarmAction.properties.clearMessageTemplateId">
				-
				{{ "alarmGroup.member.dialog.template.default" | translate }}
				-
			</option>
			<option [ngValue]="entry.id" *ngFor="let entry of actionEndTemplateList">
				{{ entry.name }}
			</option>
		</select>
	</div>
</div>


<mat-dialog-actions class="dialog-actions">
	<button *ngIf="!viewOnly" mat-flat-button class="btn-primary" (click)="addAlarmAction()"
		appTranslate="alarmGroup.dialog.actions.save"></button>
	<button mat-flat-button class="btn-secondary" (click)="cancel()"
		appTranslate="alarmGroup.dialog.actions.cancel"></button>
</mat-dialog-actions>