import { Component, OnDestroy } from '@angular/core';
import { LatLng, Map, control } from 'leaflet';
import { Store } from '@ngxs/store';
import { LeafletHelper } from 'src/app/helpers/leaflet.helper';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { GuiActions } from 'src/app/state/dashboard/gui.action';
import { WebsocketService } from 'src/app/services/websocket.service';
import { Topic, WebsocketMessage } from 'src/app/models/websocketMessageData';
import {
  SensorBox,
  WebsocketSensorBox,
} from 'src/app/state/noysee/models/sensorBox';
import { SensorState } from 'src/app/state/noysee/sensor.state';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnDestroy {
  leafletMap: Map;
  mapOptions$ = this.store.select(GuiState.guiContext).pipe(
    first((context) => !!context),
    map((context) =>
      LeafletHelper.mapOptions(context.zoomLevel, context.mapCenter),
    ),
  );

  constructor(
    private store: Store,
    private websocketService: WebsocketService,
  ) {}

  mapReady(leafletMap: Map) {
    // Dispatch events to setup states
    this.store.dispatch(new SensorActions.FetchForMap(leafletMap.getBounds()));
    // Add zoom control
    leafletMap.addControl(control.zoom({ position: 'bottomleft' }));
    // Register map events
    leafletMap.on('moveend', ($event) => {
      // Update gui context
      this.store.dispatch(
        new GuiActions.UpdateGuiContext({
          mapCenter: leafletMap.getCenter(),
          zoomLevel: leafletMap.getZoom(),
        }),
      );
      // Emit new bounding box values to websocket
      this.websocketService.dashboardWebSocket
        .pipe(
          first((socket) => !!socket),
          withLatestFrom(this.store.select(GuiState.selectedCustomer)),
        )
        .subscribe(([socket, customer]) => {
          if (socket?.OPEN) {
            const bounds = $event.target.getBounds();
            socket.send(
              JSON.stringify({
                boundingBox: {
                  north: bounds.getNorth(),
                  east: bounds.getEast(),
                  south: bounds.getSouth(),
                  west: bounds.getWest(),
                },
                customerId: customer.id,
              }),
            );
          }
        });
    });
    // Change view on selected customer change
    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      if (customer && this.leafletMap) {
        try {
          leafletMap.setView(
            new LatLng(customer.mapCenterLat, customer.mapCenterLon),
            customer.mapZoomDashboard,
          );
        } catch (e) {
          console.error('Error setting map view', e);
        }
      }
    });
    // Register websocket listener for sensor updates
    this.websocketService.dashboardWebSocket
      .pipe(first((socket) => !!socket))
      .subscribe((socket) => {
        socket.addEventListener('message', (event) => {
          const message = new WebsocketMessage<WebsocketSensorBox[]>(
            event.data,
          );
          if (
            message.topic === Topic.SENSOR_BOX_NEW_VALUE ||
            message.topic === Topic.SENSOR_BOX_META
          ) {
            for (const sensorBox of message.data) {
              if (
                this.store
                  .selectSnapshot(SensorState.mapList)
                  .find((s) => s.id === sensorBox.sensorBoxId)
              ) {
                console.log('Map: Websocket update for sensors in view');
                this.store.dispatch(
                  new SensorActions.FetchForMap(leafletMap.getBounds()),
                );
              }
            }
          }
        });
      });
    // Assign map
    this.leafletMap = leafletMap;
  }

  ngOnDestroy(): void {
    this.leafletMap = undefined;
  }
}
