import { Component, Input } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-sensors-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  sensorImageSrc: string | undefined;

  @Input()
  set sensorId(id: number | undefined) {
    this.sensorImageSrc = id
      ? environment.dashboardApiUrl +
        '/sensorBox/image?id=' +
        id +
        '&hash=' +
        Math.random()
      : null;
  }

  constructor() {}
}
