<h3 id="form-legend" class="inline" appTranslate="header.help.title"></h3>
<p appTranslate="header.help.text"></p>

<table *ngIf="email || phone" aria-label="Contact">
  <tr *ngIf="email">
    <th scope="row" appTranslate="header.help.emailaddress"></th>
    <td><a href="mailto:{{ email }}">{{ email }}</a></td>
  </tr>
  <tr *ngIf="phone">
    <th scope="row" appTranslate="header.help.phone"></th>
    <td><a href="tel:{{ phone }}">{{ phone }}</a></td>
  </tr>
</table>

<p *ngIf="isSuperAdmin" class="ch-margin-top-24">
  <strong appTranslate="header.help.superadmin"></strong>
</p>
<table *ngIf="isSuperAdmin">
  <tr>
    <th scope="row" appTranslate="header.help.noyseeFrontend"></th>
    <td>{{frontendVersion}} [{{frontendGitHash}}]</td>
  </tr>
  <tr>
    <th scope="row" appTranslate="header.help.noyseeBackend"></th>
    <td>{{backendVersion}} [{{backendBuildDate}}]</td>
  </tr>
</table>
