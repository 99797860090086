import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import moment from 'moment';

@Component({
  template: ` <input type="date" [(ngModel)]="value" /> `,
})
export class DateCellEditorComponent implements ICellEditorAngularComp {
  value: string | Date;

  agInit({ value }: ICellEditorParams<any, any, any>): void {
    this.value = moment(value).format('YYYY-MM-DD');
  }

  getValue() {
    return moment(this.value, 'YYYY-MM-DD').toDate();
  }
}
