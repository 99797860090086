import { Component } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import {
  NavigationEnd,
  Route,
  RouteConfigLoadEnd,
  Router,
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { Store } from '@ngxs/store';
import { isSensorWeatherStation } from 'src/app/state/noysee/sensor.util';
import { GuiActions } from 'src/app/state/dashboard/gui.action';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent {
  isDetail$: Observable<boolean> = this.router.events.pipe(
    filter(
      (event) =>
        event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd,
    ),
    map((event) => {
      return this.router.url.indexOf('detail') > -1;
    }),
  );

  menuEntries$: Observable<any[]> = combineLatest([
    this.store.select(SensorState.currentSensor),
    this.authenticationService.getFeatureSet(),
  ]).pipe(
    // Make sure both sensor and feature set are available
    filter(([_, featureSet]) => !!featureSet),
    // Get menu entries
    map(([sensorBox, featureSet]) => {
      // Skip the first two layers, since they contain an empty path and just add components to the default view
      const appChildren = this.router.config.find(
        (route) => route.path === 'dashboard',
      )['_loadedConfig'].routes[0].children;
      const pathSegments = this.router.url.split('/');
      pathSegments.splice(0, 2);
      // Get actual menu entries
      const menuEntries = this.getMenuEntries(
        appChildren,
        JSON.parse(JSON.stringify(pathSegments)),
      );
      return {
        featureSet,
        menuEntries,
        pathSegments,
        isWeatherStation: isSensorWeatherStation(sensorBox as any),
      };
    }),
    // Determine root path for menu entry links
    map(({ featureSet, menuEntries, pathSegments, isWeatherStation }) => {
      pathSegments.splice(pathSegments.length - 1, 1);
      pathSegments.unshift('/dashboard');
      this.rootPath = pathSegments.join('/');
      return { featureSet, menuEntries, isWeatherStation };
    }),
    // Set menu entry visibility based on feature set
    map(({ featureSet, menuEntries, isWeatherStation }) =>
      menuEntries.map((entry) => {
        entry.disabled = false;
        // Figure out menu entry visibility

        if (entry?.weatherStationOnly && !featureSet.isSuperadmin()) {
          // superadmin can always see history
          entry.disabled = !isWeatherStation;
        }
        if (
          entry?.requiredPrivilege &&
          !featureSet.hasPrivilege(entry?.requiredPrivilege, null)
        ) {
          entry.disabled = true;
        }
        if (
          entry?.requiredFeature &&
          !featureSet.hasFeature(entry?.requiredFeature)
        ) {
          entry.disabled = true;
        }

        return entry;
      }),
    ),
  );
  showMenu$: Observable<boolean> = this.menuEntries$.pipe(
    map((entries) => entries.length > 0),
    tap((showMenu) =>
      this.store.dispatch(new GuiActions.SetMenuVisibility(showMenu)),
    ),
  );
  rootPath: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private store: Store,
  ) {}

  private getMenuEntries(children: Route[], pathSegments: string[]): any[] {
    if (children.length === 0 || pathSegments.length === 0) {
      return [];
    }

    let segment = pathSegments.shift();
    segment = segment.split('?')[0]; // Remove query params if existent

    // Try to find child based on path
    let child = children.find((current) => current.path === segment);
    if (!child) {
      // Check if current child contains a child with an :id that has to be matched dynamically
      child = children.find((current) => current.path === ':id');
    }
    if (!child) {
      // Check if current child contains a child with an empty path
      const empty = children.find((current) => current.path === '');

      if (!empty || !empty.children) {
        return [];
      } else {
        child = empty.children.find((current) => current.path === segment);
      }
    }

    if (
      pathSegments.length >= 1 &&
      (child.children ||
        (child['_loadedConfig'] && child['_loadedConfig']['routes']))
    ) {
      return this.getMenuEntries(
        child.children ? child.children : child['_loadedConfig']['routes'],
        pathSegments,
      );
    } else if (
      pathSegments.length === 0 &&
      child.data &&
      child.data.menuEntries
    ) {
      return child.data.menuEntries;
    }

    return [];
  }
}
