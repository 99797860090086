import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {PasswordValidators} from '../../../../../helpers/password-validators';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {
  formGroup: FormGroup;

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    console.log('validating', pass, confirmPass);
    return pass === confirmPass ? null : {noMatch: true};
  }

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<PasswordModalComponent>) {
    this.formGroup = this.formBuilder.group({
      oldPassword: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, PasswordValidators.passwordMatchesPolicies]),
      confirmPassword: new FormControl(null, [Validators.required]),
    }, {validators: this.checkPasswords});
  }

  ngOnInit(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  confirm() {
    this.dialogRef.close([this.f.oldPassword.value, this.f.password.value, this.f.confirmPassword.value]);
  }

  cancel() {
    this.dialogRef.close([]);
  }
}
