import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  IoTSensorBoxSettings,
  ComMode,
  CommandEnum,
  SensorBoxHardwareStates,
} from '../../../../state/noysee/models/sensor';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { GridState } from '../../../../shared/data-table/data-table.model';
import { NoyseeService } from '../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LookupEntry } from 'src/app/models/apiResults';
import { ReplaceDeviceDialogComponent } from '../../admin/noyseeDevices/replaceDevice-modal/replaceDeviceDialog.component';
import { LookupService } from 'src/app/services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { Firmware } from 'src/app/models/firmware';
import { UpgradeFirmwareDialogComponent } from '../../admin/firmware/upgradeFirmware-modal/upgradeFirmwareDialog.component';

@Component({
  selector: 'app-admin-box',
  templateUrl: './admin-box.component.html',
  styleUrls: ['./admin-box.component.scss'],
})
export class AdminBoxComponent implements OnInit, OnDestroy {
  ComMode = ComMode;
  SensorBoxHardwareStates = SensorBoxHardwareStates;
  mode: string;
  adminBoxForm: FormGroup;

  routerSubscription: Subscription;
  iotDetails: IoTSensorBoxSettings;
  iotDetails$: Observable<IoTSensorBoxSettings>;
  iotSubscription: Subscription;

  // firmwareList: Firmware[];
  loraConfigList: LookupEntry[];

  isSuperAdmin: boolean;
  isAdmin: boolean;

  ctx: GridState = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private lookupService: LookupService,
    private noyseeService: NoyseeService,
  ) {
    this.mode = '';
    this.iotDetails = new IoTSensorBoxSettings();
    this.adminBoxForm = null;

    this.isSuperAdmin = false;
    this.isAdmin = false;

    this.adminBoxForm = this.formBuilder.group({
      name: new FormControl('', []),
      noyseeSN: new FormControl('', []),
      simICCID: new FormControl('', []),
      imei: new FormControl('', []),
      stateText: new FormControl('', []),
      state: new FormControl('', []),
      installedFirmware: new FormControl('', []),
      firmwareToInstall: new FormControl('', []),
      newFirmwareVersion: new FormControl('', []),
      uuid: new FormControl('', []),
      datasource: new FormControl('', []),
      primary_sensorOffset: new FormControl('', []),
      primary_sensorScale: new FormControl('', []),
      primary_limit1: new FormControl('', []),
      primary_limit2: new FormControl('', []),
      primary_limit3: new FormControl('', []),
      primary_limit4: new FormControl('', []),
      primary_limit5: new FormControl('', []),
      primary_limitDelta: new FormControl('', []),
      primary_hysteresis: new FormControl('', []),
      primary_offset: new FormControl('', []),
      primary_scale: new FormControl('', []),
      primary_maxGradientUp: new FormControl('', []),
      primary_maxGradientDown: new FormControl('', []),
      primary_qualityRetries: new FormControl('', []),
      primary_gradientRetries: new FormControl('', []),
      primary_minQuality: new FormControl('', []),

      loraAppSKey: new FormControl('', []),
      loraAppeui: new FormControl('', []),
      loraAppkey: new FormControl('', []),
      loraDevAdr: new FormControl('', []),
      loraNetSKey: new FormControl('', []),
      loraMode: new FormControl('', []),
      comMode: new FormControl('', []),
      isPublic: new FormControl('', []),

      loraServer: new FormControl('', []),

      measurementInterval: new FormControl('', []),
      aliveInterval: new FormControl('', []),
      timeoutInterval: new FormControl('', []),
      sensorBoxTemplateName: new FormControl('', []),
      alarmCoolDownHours: new FormControl('', []),
      predefinedAppEui: new FormControl('', []),

      secondary_sensorOffset: new FormControl('', []),
      secondary_sensorScale: new FormControl('', []),
      secondary_limit1: new FormControl('', []),
      secondary_limit2: new FormControl('', []),
      secondary_limit3: new FormControl('', []),
      secondary_limitDelta: new FormControl('', []),
      secondary_hysteresis: new FormControl('', []),
      secondary_offset: new FormControl('', []),
      secondary_scale: new FormControl('', []),
      secondary_maxGradientUp: new FormControl('', []),
      secondary_maxGradientDown: new FormControl('', []),
      secondary_qualityRetries: new FormControl('', []),
      secondary_gradientRetries: new FormControl('', []),
      secondary_minQuality: new FormControl('', []),

      authMethod: new FormControl('', []),
      authSecret: new FormControl('', []),
      hardwareState: new FormControl('', []),
    });

    this.f.installedFirmware.disable();
    this.f.firmwareToInstall.disable();
    this.f.sensorBoxTemplateName.disable();
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.route.snapshot.params;
        this.loadSensorBox();
      });

    this.lookupService.getLoraConfigList().then((result) => {
      this.loraConfigList = result;
    });

    this.loadSensorBox();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.iotSubscription.unsubscribe();
  }

  get f() {
    return this.adminBoxForm.controls;
  }

  fileUploadResponse(result) {
    if (!result.ok) {
      console.log(result);
    }
  }

  loadSensorBox() {
    const url = this.route.snapshot.params;
    const id = url.id;

    this.iotDetails$ = this.noyseeService.loadAdminSettings(id);
    this.iotSubscription = this.iotDetails$.subscribe((iotSettings) => {
      this.iotDetails = iotSettings;
      this.dataToForm();
    });
  }

  private dataToForm() {
    this.adminBoxForm.patchValue({
      noyseeSN: this.iotDetails.noyseeSN,
      simICCID: this.iotDetails.simICCID,
      imei: this.iotDetails.imei,
      name: this.iotDetails.name,
      installedFirmware:
        this.iotDetails.installedFirmware != null
          ? this.iotDetails.installedFirmware.name +
            ' (' +
            this.iotDetails.installedFirmware.firmwareVersion +
            ')'
          : '-',
      firmwareToInstall:
        this.iotDetails.firmwareToInstall != null
          ? this.iotDetails.firmwareToInstall.name +
            ' (' +
            this.iotDetails.firmwareToInstall.firmwareVersion +
            ')'
          : '-',
      newFirmwareVersion: null,
      uuid: this.iotDetails.uuid,
      datasource: this.iotDetails.datasource,

      loraAppSKey: this.iotDetails.loraAppSKey,
      loraAppeui: this.iotDetails.loraAppeui,
      loraAppkey: this.iotDetails.loraAppkey,
      loraDevAdr: this.iotDetails.loraDevAdr,
      loraNetSKey: this.iotDetails.loraNetSKey,
      loraMode: this.iotDetails.loraMode,
      comMode: this.iotDetails.comMode,
      isPublic: this.iotDetails.public,
      measurementInterval: this.iotDetails.measurementInterval,
      aliveInterval: this.iotDetails.aliveInterval,
      timeoutInterval: this.iotDetails.timeoutInterval,
      loraServer:
        this.iotDetails.loraServer != null
          ? this.iotDetails.loraServer.id
          : null,
      alarmCoolDownHours: this.iotDetails.alarmCoolDownHours,
      predefinedAppEui: this.iotDetails.loraAppeui,
      authSecret: this.iotDetails.authSecret,
      authMethod: this.iotDetails.authMethod,
      hardwareState: this.iotDetails.hardwareState,
    });

    const primarySensor =
      this.iotDetails.sensors[this.iotDetails.primarySensor];
    this.adminBoxForm.patchValue({
      primary_sensorOffset: primarySensor.sensorOffset,
      primary_sensorScale: primarySensor.sensorScale,
      primary_limit1: primarySensor.limit1,
      primary_limit2: primarySensor.limit2,
      primary_limit3: primarySensor.limit3,
      primary_limit4: primarySensor.limit4,
      primary_limit5: primarySensor.limit5,
      primary_limitDelta: primarySensor.limitDelta,
      primary_hysteresis: primarySensor.hysteresis,

      primary_offset: primarySensor.offset,
      primary_scale: primarySensor.scale,
      primary_maxGradientUp: primarySensor.maxGradientUp,
      primary_maxGradientDown: primarySensor.maxGradientDown,
      primary_minQuality: primarySensor.minQuality,
      primary_qualityRetries: primarySensor.qualityRetries,
      primary_gradientRetries: primarySensor.gradientRetries,
    });

    if (this.iotDetails.secondarySensor) {
      const secondarySensor =
        this.iotDetails.sensors[this.iotDetails.secondarySensor];
      this.adminBoxForm.patchValue({
        secondary_sensorOffset: secondarySensor.sensorOffset,
        secondary_sensorScale: secondarySensor.sensorScale,
        secondary_limit1: secondarySensor.limit1,
        secondary_limit2: secondarySensor.limit2,
        secondary_limit3: secondarySensor.limit3,
        secondary_limit4: secondarySensor.limit4,
        secondary_limit5: secondarySensor.limit5,
        secondary_limitDelta: secondarySensor.limitDelta,
        secondary_hysteresis: secondarySensor.hysteresis,
        secondary_offset: secondarySensor.offset,
        secondary_scale: secondarySensor.scale,
        secondary_maxGradientUp: secondarySensor.maxGradientUp,
        secondary_maxGradientDown: secondarySensor.maxGradientDown,
        secondary_minQuality: secondarySensor.minQuality,
        secondary_qualityRetries: secondarySensor.qualityRetries,
        secondary_gradientRetries: secondarySensor.gradientRetries,
      });
    }
  }

  saveSensorBox() {
    // this.submitted = true;
    if (this.adminBoxForm.invalid) {
      return;
    }
    this.iotDetails.name = this.f.name.value;
    this.iotDetails.uuid = this.f.uuid.value;
    this.iotDetails.datasource = this.f.datasource.value;

    this.iotDetails.loraAppSKey = this.f.loraAppSKey.value;
    this.iotDetails.loraAppeui = this.f.loraAppeui.value;
    this.iotDetails.loraAppkey = this.f.loraAppkey.value;
    this.iotDetails.loraDevAdr = this.f.loraDevAdr.value;
    this.iotDetails.loraMode = this.f.loraMode.value;
    this.iotDetails.loraNetSKey = this.f.loraNetSKey.value;

    this.iotDetails.noyseeSN = this.f.noyseeSN.value;
    this.iotDetails.comMode = this.f.comMode.value;
    this.iotDetails.imei = this.f.imei.value;
    this.iotDetails.simICCID = this.f.simICCID.value;

    this.iotDetails.measurementInterval = this.f.measurementInterval.value;
    this.iotDetails.aliveInterval = this.f.aliveInterval.value;
    this.iotDetails.timeoutInterval = this.f.timeoutInterval.value;
    this.iotDetails.alarmCoolDownHours = this.f.alarmCoolDownHours.value;
    this.iotDetails.loraServer = { id: this.f.loraServer.value, name: '' };
    this.iotDetails.authMethod = this.f.authMethod.value;
    this.iotDetails.authSecret = this.f.authSecret.value;
    this.iotDetails.hardwareState = this.f.hardwareState.value;

    const primarySensor =
      this.iotDetails.sensors[this.iotDetails.primarySensor];
    if (primarySensor) {
      primarySensor.sensorOffset = this.f.primary_sensorOffset.value;
      primarySensor.sensorScale = this.f.primary_sensorScale.value;

      primarySensor.offset = this.f.primary_offset.value;
      primarySensor.scale = this.f.primary_scale.value;

      primarySensor.limit1 = this.f.primary_limit1.value;
      primarySensor.limit2 = this.f.primary_limit2.value;
      primarySensor.limit3 = this.f.primary_limit3.value;
      // primarySensor.limit4= this.f.primary_limit4.value;
      // primarySensor.limit5= this.f.primary_limit5.value;
      primarySensor.limitDelta = this.f.primary_limitDelta.value;
      primarySensor.hysteresis = this.f.primary_hysteresis.value;
      primarySensor.maxGradientUp = this.f.primary_maxGradientUp.value;
      primarySensor.maxGradientDown = this.f.primary_maxGradientDown.value;

      primarySensor.minQuality = this.f.primary_minQuality.value;
      primarySensor.qualityRetries = this.f.primary_qualityRetries.value;
      primarySensor.gradientRetries = this.f.primary_gradientRetries.value;
    }

    const secondarySensor =
      this.iotDetails.sensors[this.iotDetails.secondarySensor];
    if (secondarySensor) {
      secondarySensor.sensorOffset = this.f.secondary_sensorOffset.value;
      secondarySensor.sensorScale = this.f.secondary_sensorScale.value;

      secondarySensor.offset = this.f.secondary_offset.value;
      secondarySensor.scale = this.f.secondary_scale.value;

      secondarySensor.limit1 = this.f.secondary_limit1.value;
      secondarySensor.limit2 = this.f.secondary_limit2.value;
      secondarySensor.limit3 = this.f.secondary_limit3.value;
      // primarySensor.limit4= this.f.primary_limit4.value;
      // primarySensor.limit5= this.f.primary_limit5.value;
      secondarySensor.limitDelta = this.f.secondary_limitDelta.value;
      secondarySensor.hysteresis = this.f.secondary_hysteresis.value;
      secondarySensor.maxGradientUp = this.f.secondary_maxGradientUp.value;
      secondarySensor.maxGradientDown = this.f.secondary_maxGradientDown.value;
      secondarySensor.minQuality = this.f.secondary_minQuality.value;
      secondarySensor.qualityRetries = this.f.secondary_qualityRetries.value;
      secondarySensor.gradientRetries = this.f.secondary_gradientRetries.value;
    }

    this.noyseeService
      .saveAdminSettings(this.iotDetails)
      .subscribe((result) => {
        this.iotDetails = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('Daten gespeichert.', 'SUCCESS');
      });

    if (this.f.newFirmwareVersion.value != null) {
      this.toastHelper.openSnackBar('Firmwareupdate initiiert.', 'SUCCESS');
    }

    this.router.navigate(['/dashboard', 'sensors', 'overview']);
  }

  gotoNoyseeDevice(sno: String) {
    this.router.navigate(['/dashboard', 'admins', 'devices', 'detail', sno]);
  }

  backToSensorDetail() {
    if (this.iotDetails) {
      this.router.navigate([
        '/dashboard',
        'sensors',
        'detail',
        this.iotDetails.id,
      ]);
    } else {
      this.router.navigate(['/dashboard', 'sensors', 'overview']);
    }
  }

  replaceBox() {
    const dialogOptions: MatDialogConfig = {
      data: {
        sensorBoxId: this.iotDetails.id,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(ReplaceDeviceDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        this.loadSensorBox();
      });
  }

  scheduleCfg() {
    this.noyseeService
      .scheduleCommand(this.iotDetails.id, CommandEnum.CONFIG)
      .then((result) => {
        this.toastHelper.openSnackBar('Kommando CONFIG ausgelöst.', 'SUCCESS');
      });
  }

  scheduleOta() {
    this.noyseeService
      .scheduleCommand(this.iotDetails.id, CommandEnum.OTA)
      .then((result) => {
        this.toastHelper.openSnackBar('Kommando OTA ausgelöst.', 'SUCCESS');
      });
  }

  scheduleLocate() {
    this.noyseeService
      .scheduleCommand(this.iotDetails.id, CommandEnum.LOCATE)
      .then((result) => {
        this.toastHelper.openSnackBar('Kommando LOCATE ausgelöst.', 'SUCCESS');
      });
  }

  scheduleSysInfo() {
    this.noyseeService
      .scheduleCommand(this.iotDetails.id, CommandEnum.SYSINFO)
      .then((result) => {
        this.toastHelper.openSnackBar('Kommando SYSINFO ausgelöst.', 'SUCCESS');
      });
  }

  scheduleDeepSleep() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie für dieses Gerät einen DEEP_SLEEP senden?',
    );
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        try {
          this.noyseeService
            .scheduleCommand(this.iotDetails.id, CommandEnum.DEEP_SLEEP)
            .then(() => {
              this.toastHelper.openSnackBar(
                'Kommando DEEP_SLEEP ausgelöst.',
                'SUCCESS',
              );
            });
        } catch (e) {
          this.toastHelper.openSnackBar(
            'Fehler bei Kommando DEEP_SLEEP.',
            'ERROR',
          );
        }
      }
    });
  }

  upgradeFirmware() {
    const dialogOptions: MatDialogConfig = {
      data: {
        sensorBoxId: this.iotDetails.id,
        sensorBoxName: this.iotDetails.name,
        installedFirmware: this.iotDetails.installedFirmware,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(UpgradeFirmwareDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        this.loadSensorBox();
      });
  }

  showCommandQueue() {
    this.router.navigate([
      '/dashboard',
      'sensors',
      'detail',
      this.iotDetails.id,
      'command-queue',
    ]);
  }

  getUnit(): string {
    if (
      this.iotDetails &&
      this.iotDetails.primarySensor &&
      this.iotDetails.sensors[this.iotDetails.primarySensor]
    ) {
      return this.iotDetails.sensors[this.iotDetails.primarySensor].unit;
    }
  }

  getSecondaryUnit(): string {
    if (
      this.iotDetails &&
      this.iotDetails.secondarySensor &&
      this.iotDetails.sensors[this.iotDetails.secondarySensor]
    ) {
      return this.iotDetails.sensors[this.iotDetails.secondarySensor].unit;
    }
  }

  getCalculationType(): string {
    if (
      this.iotDetails &&
      this.iotDetails.primarySensor &&
      this.iotDetails.sensors[this.iotDetails.primarySensor]
    ) {
      return this.iotDetails.sensors[this.iotDetails.primarySensor]
        .calculationType;
    }
  }

  getSecondaryCalculationType(): string {
    if (
      this.iotDetails &&
      this.iotDetails.secondarySensor &&
      this.iotDetails.sensors[this.iotDetails.secondarySensor]
    ) {
      return this.iotDetails.sensors[this.iotDetails.secondarySensor]
        .calculationType;
    }
  }

  chooseAppEui() {
    const eui: string = this.f.predefinedAppEui.value;
    this.adminBoxForm.patchValue({
      loraAppeui: eui,
    });
  }

  maybePredefined() {
    const eui = this.f.loraAppeui.value;
    this.adminBoxForm.patchValue({
      predefinedAppEui: eui,
    });
  }

  hideSecret(): boolean {
    return !this.f.authMethod.value || this.f.authMethod.value === 'none';
  }
}
