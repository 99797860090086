import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseCellRenderer } from '../util/interfaces';

export interface CheckboxCellRendererParams
  extends ICellRendererParams,
    BaseCellRenderer {
  onChange: (value: boolean, data: any, rowIndex: number) => void;
}

@Component({
  template: `
    <mat-checkbox
      [checked]="params.value"
      color="primary"
      (change)="params.onChange($event.checked, params.data, params.rowIndex)"
    ></mat-checkbox>
  `,

  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CheckboxCellRenderer implements ICellRendererAngularComp {
  params: CheckboxCellRendererParams;
  agInit(params: CheckboxCellRendererParams): void {
    this.params = params;
  }
  refresh(params: CheckboxCellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
