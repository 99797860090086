import { LatLng } from 'leaflet';
import { Customer } from '../../../models/customerList';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

export interface DashboardContext {
  [key: number]: GuiDetail; // "userId": GuiDetail
}

export class GuiDetail {
  userId: number;
  mapCenter: LatLng;
  zoomLevel: number;
  selectedCustomer: Customer;
  primaryPanelActive: boolean;
  showMenu: boolean;

  constructor(user: User) {
    this.userId = user.id;
    this.selectedCustomer = user.customer;
    this.mapCenter = new LatLng(
      user.customer.mapCenterLat || environment.guiDefaults.mapCoordinates.lat,
      user.customer.mapCenterLon || environment.guiDefaults.mapCoordinates.lng,
    );
    this.zoomLevel = user.customer.mapZoomDashboard || 11;
    this.primaryPanelActive = true;
    this.showMenu = true;
  }
}
