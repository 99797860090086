import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges
} from '@angular/core';
import {Subject} from 'rxjs';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  inputWidth: number;

  @Input()
  disabled = false;

  @Input()
  max: Date;

  @Input()
  formControl: FormControl;

  onChange: (value: any) => {};
  onBlur: (value: any) => {};
  destroy$: Subject<void> = new Subject();


  constructor(@Optional() @Self() private ngControl: NgControl, private _elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.formControl = new FormControl(null, []);

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ngControl?.control) {
      const control = this.ngControl.control;
      control.setValidators(control.validator);
      this.formControl.patchValue(control.value, {emitEvent: false});
      this.formControl.setValidators(control.validator);
      this.changeDetector.detectChanges();
    }

    if ('disabled' in changes) {
      this.toggleDisable();
    }
  }

  toggleDisable() {
    if (this.disabled !== false) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  writeValue(obj: any): void {
    if (this.formControl) {
      this.formControl.setValue(obj);
      if (obj) {
        this.formControl.markAsTouched();
      }
    }
  }
}
