import { LatLng } from 'leaflet';

export enum TopoType {
  WATERWAYS = 'waterways',
  KACHELMANN_RAIN = 'kachelmann_rain',
  RADAR_OWM = 'rainradar_owm',
}

export enum PolygoneType {
	EG_std='EG_std', 
	EG_custom='EG_custom', 
	VG_kreis='VG_kreis',
	VG_gem='VG_gem'
}

export class Waterway {
  wayId: number;
  nodeId: number;
  name: string;
  hexColor: string;
  parts: WaterwayPart[];
  width: number;
  visible: boolean;
}

export class WaterwayPart {
  name: string;
  nodes: LatLng[];
  tunnel: boolean;
  waterway: string; // Allowed values: https://wiki.openstreetmap.org/wiki/Key:waterway
  wayId: string;
}

export class GeoPolygone {
	id: number;
	type: string;
	polygoneType: PolygoneType;
	name: string;
	description: string;
	externalId: string;
	latNorth: number;
	latSouth: number;
	lonWest: number;
	lonEast: number;
	latCenter: number;
	lonCenter: number;
}