import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { TranslatePipe } from '@ngx-translate/core';
import { AlarmChannelService } from '@app/state/noysee/alarmChannel.service';
import { AlarmActionService } from '@app/state/noysee/alarmAction.service';
import { AlarmAction, AlarmActionType } from '@app/state/noysee/models/alarmAction';
import { LookupService } from '@app/services/lookup.service';
import { MessageTemplateClass } from '@app/models/messageTemplate';

@Component({
	selector: 'app-addSensorBoxAlarmAction',
	templateUrl: './addAlarmAction.component.html',
	styleUrls: ['./addAlarmAction.component.scss'],
})
export class AddSensorBoxAlarmActionComponent implements OnInit {
	AlarmActionType = AlarmActionType;
	alarmActionTypes = Object.values(AlarmActionType);

	parentId: number;
	parentName: string;

	alarmAction: AlarmAction;

	parentType: string;

	isSuperAdmin: boolean;
	isAdmin: boolean;

	alarmChannelList: LookupEntry[];
	actionStartTemplateList: LookupEntry[];
	actionEndTemplateList: LookupEntry[];

	viewOnly: boolean = false;

	constructor(
		private toastHelper: ToastHelper,
		private authenticationService: AuthenticationService,
		private lookupService: LookupService,
		private alarmChannelService: AlarmChannelService,
		private translatePipe: TranslatePipe,
		private alarmActionService: AlarmActionService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<AddSensorBoxAlarmActionComponent>
	) {
		this.isSuperAdmin = false;
		this.isAdmin = false;
	}

	ngOnInit(): void {
		this.parentId = this.data?.parentId;
		this.parentName = this.data?.parentName;
		this.parentType = this.data?.parentType;

		if (this.data?.mode == "edit" || this.data?.mode == "view") {
			this.alarmActionService.getAlarmAction(this.data?.id).then(result => {
				this.alarmAction = result;
			});
			this.viewOnly = this.data?.mode == "view";
		}

		if (!this.alarmAction) {
			this.alarmAction = new AlarmAction();
			this.alarmAction.properties = {};
			this.alarmAction.active = true;
		}

		this.isSuperAdmin = this.authenticationService.isSuperAdmin();
		this.isAdmin = this.authenticationService.isAdmin();

		this.alarmChannelService.getList().then((result) => {
			this.alarmChannelList = result;
		});
		this.lookupService.getMessageTemplateList(MessageTemplateClass.ACTION_START).then((data) => {
			this.actionStartTemplateList = data;
		});
		this.lookupService.getMessageTemplateList(MessageTemplateClass.ACTION_END).then((data) => {
			this.actionEndTemplateList = data;
		});
	}

	isLinkVisible(): boolean {
		return this.alarmAction.actionType == AlarmActionType.dynamicLink || this.alarmAction.actionType == AlarmActionType.staticLink;
	}

	isTemplateVisible(): boolean {
		return this.alarmAction.actionType == AlarmActionType.channelAlert || this.alarmAction.actionType == AlarmActionType.messageAlert;
	}

	isChannelVisible(): boolean {
		return this.alarmAction.actionType == AlarmActionType.channelAlert;
	}

	executeAlarmAction(): void {
		this.dialogRef.close();
	}

	addAlarmAction(): void {

		if (
			this.parentId == null ||
			this.alarmAction.name == null ||
			this.alarmAction.actionType == null
		) {
			return;
		}

		console.debug(this.parentType);

		if (this.parentType === 'sensorBox') {
			this.alarmActionService
				.saveAlarmAction(this.parentId, this.alarmAction)
				.then(result => {
					this.toastHelper.openSnackBar(
						this.translatePipe.transform('alarmGroup.dialog.events.add'),
						'SUCCESS'
					);
					this.dialogRef.close();
				});
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}

	toggleAuth() {
		this.alarmAction.additionalAuth = !this.alarmAction.additionalAuth;
	}
}
