import { Component, Input } from '@angular/core';
import {
  Map,
  LatLng,
  LatLngBounds,
  LayerGroup,
  TileLayer,
  tileLayer,
} from 'leaflet';
import { KachelmannService } from 'src/app/state/dashboard/kachelmann.service';
import { range } from 'lodash';
import { LayerType } from 'src/app/state/dashboard/models/mapLayer';
import { TopoType } from 'src/app/models/topo';
import { MapLayerComponent } from '../layer.util';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';

const defaultOpacity = 0.3;

@Component({
  selector: 'leaflet-rain-layer',
  template: `
    <!-- Layers -->
    <div [leafletLayer]="layer"></div>
    <div *ngIf="showAll" [leafletLayer]="allLayers"></div>
    <!-- Control -->
    <app-time-slider
      [map]="_map"
      [layerSubType]="TopoType.KACHELMANN_RAIN"
      [loading]="loading"
      (offsetChanged)="showRainLayerForOffset($event)"
      (start)="setAllRainTileLayers($event)"
      (stop)="reset($event)"
    ></app-time-slider>
  `,
})
export class RainComponent extends MapLayerComponent<TileLayer> {
  TopoType = TopoType;
  loading: boolean = true;
  allLayers: LayerGroup<TileLayer> = new LayerGroup([]);
  showAll: boolean = false;

  @Input() set leafletMap(map: Map) {
    if (map) {
      this.registerMapAndFilterChange(map);
      // Update available rain layers every 15 minutes
      // The redraw function of a tile layer should re-fetch all tiles
      setInterval(
        () => {
          this.layer.eachLayer((layer: TileLayer) => layer.redraw());
        },
        15 * 60 * 1000,
      );
    }
  }

  constructor(store: Store) {
    super(store, LayerType.TOPO, TopoType.KACHELMANN_RAIN);
  }

  activate(offset: number = 0) {
    console.log('Activating');
    this.loading = true;
    this.showRainLayerForOffset(offset);
    return of(this.layer);
  }

  reset(offset: number = 0) {
    if (this.showAll) {
      // Update single rain layer
      this.loading = true;
      this.layer = new LayerGroup([this.getRainTileLayer(offset)]);
    }
    this.showAll = false;
  }

  async setAllRainTileLayers(currentOffset: number): Promise<void> {
    this.allLayers = new LayerGroup(
      range(9).map((offset) =>
        this.getRainTileLayer(
          -15 * offset,
          offset === currentOffset ? defaultOpacity : 0,
        ),
      ),
    );
    this.allLayers.eachLayer((layer: TileLayer) => layer.setOpacity(0));

    this.showAll = true;
    this.loading = true;
    this._map.fireEvent('dataloading');
    await Promise.all(
      this.allLayers
        .getLayers()
        .map(
          (layer) =>
            new Promise<void>((resolve) =>
              (layer as TileLayer).addEventListener('load', () => resolve()),
            ),
        ),
    );

    this._map.fireEvent('dataload');
    this.loading = false;
    this.showRainLayerForOffset(currentOffset);
  }

  showRainLayerForOffset(offset: number) {
    if (!this.showAll) {
      // Update single rain layer
      this.layer = new LayerGroup([this.getRainTileLayer(offset)]);
      this.layer.eachLayer((layer: TileLayer) =>
        layer.setOpacity(defaultOpacity),
      );
      this.allLayers.eachLayer((layer: TileLayer) => layer.setOpacity(0));
    } else {
      // Update opacity based on given offset
      this.allLayers.eachLayer((layer: TileLayer) =>
        layer.setOpacity(+layer.options.id === offset ? defaultOpacity : 0),
      );
      this.layer.eachLayer((layer: TileLayer) => layer.setOpacity(0));
    }
  }

  private getRainTileLayer(
    offset: number,
    opacity: number = defaultOpacity,
  ): TileLayer {
    return tileLayer(KachelmannService.getRelativeTileLayerUrl(offset), {
      opacity,
      attribution: 'Rain Radar © 2023 Kachelmann',
      id: offset.toString(),
      updateWhenZooming: false,
      maxZoom: 14,
      bounds: new LatLngBounds(
        new LatLng(46.78, 5.16),
        new LatLng(55.24, 15.69),
      ),
    });
  }
}
