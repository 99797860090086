import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NoyseeDevice} from '../models/noyseeDevice';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {NoyseeDeviceListResult, NoyseeDeviceResult, LookupListResult, LookupEntry} from '../models/apiResults';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DevicesService {

  constructor(private http: HttpClient) {
  }

  getNoyseeDeviceList(): Promise<NoyseeDevice[]> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/noyseeDevice/list`).pipe(map(result => {
      if (!result.ok) {
        throw new Error(result.errorCode);
      }
      return result.data;
    })).toPromise();
  }

  getDeviceBySno(sno: String): Observable<NoyseeDevice> {
    return this.http.get<NoyseeDeviceResult>(`${environment.dashboardApiUrl}/noyseeDevice/detail?sno=` + sno)
      .pipe(map((result: NoyseeDeviceResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }));
  }

  deleteDeviceBySno(sno: String): Promise<NoyseeDevice> {
    return this.http.delete<NoyseeDeviceResult>(`${environment.dashboardApiUrl}/noyseeDevice/detail?sno=` + sno)
      .pipe(map((result: NoyseeDeviceResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }



  async createDevice(device): Promise<any> {
    // const newDevice = await this.http.post<NoyseeDevice>(`${environment.dashboardApiUrl}/noyseeDevice/detail?sno=` + device.noyseeSN, device).toPromise();
    // this.notificationService.updateOpenNotifications();
    // return newDevice;
    return null;
  }

  async updateDevice(device): Promise<any> {
    const newDevice = this.http.put<NoyseeDevice>(`${environment.dashboardApiUrl}/noyseeDevice/detail?sno=` + device.noyseeSN, device).toPromise();
    //  this.notificationService.updateOpenNotifications();
    return newDevice;
  }

  async activateDevice(noyseeSN: string, name: string, lat: number, lon: number, templateId: number, customerId: number, activate: boolean): Promise<NoyseeDevice> {
    return this.http.post<NoyseeDeviceResult>(`${environment.dashboardApiUrl}/noyseeDevice/activate`, {
      noyseeSN,
      name,
      lat,
      lon,
      templateId,
      customerId,
      activate
    })
      .pipe(map((result: NoyseeDeviceResult) => {
        if (result?.ok) {
          return result.data;
        }
      })).toPromise();
  }

  async replaceDevice(sensorBoxId: number, noyseeSN: string): Promise<NoyseeDevice> {
    return this.http.post<NoyseeDeviceResult>(`${environment.dashboardApiUrl}/noyseeDevice/replace`, {
      sensorBoxId,
      noyseeSN,
    })
      .pipe(map((result: NoyseeDeviceResult) => {
        if (result?.ok) {
          return result.data;
        }
      })).toPromise();
  }

  getInstallableDeviceList(): Promise<NoyseeDevice[]> {
    return this.http.get<NoyseeDeviceListResult>(`${environment.dashboardApiUrl}/noyseeDevice/installableList`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }


}
