import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LatLng, LayerGroup, Map, MapOptions } from 'leaflet';
import { NoyseeService } from '../../../../state/noysee/noysee.service';
import { LeafletHelper } from '../../../../helpers/leaflet.helper';
import { AuthenticationService } from '../../../../services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Sensor } from 'src/app/state/noysee/models/sensor';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';

@Component({
  selector: 'app-map-microsite',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(undefined);
  mapOptions: MapOptions;
  sensorLayer: LayerGroup = new LayerGroup<any>();
  waterwayLayer: LayerGroup = new LayerGroup();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly leafletHelper: LeafletHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
    private readonly noyseeService: NoyseeService,
  ) {}

  ngOnInit(): void {
    const customer = this.authenticationService.customer();
    this.mapOptions = LeafletHelper.mapOptions(
      this.activatedRoute.snapshot.queryParams.zoomLevel ||
        customer.mapZoomDashboard ||
        11,
      new LatLng(
        this.activatedRoute.snapshot.queryParams.mapCenterLat ??
          customer.mapCenterLat,
        this.activatedRoute.snapshot.queryParams.mapCenterLng ??
          customer.mapCenterLon,
      ),
      false,
    );

    this.map$
      .pipe(
        tap((map) => {
          if (map) {
            this.generateMapLayers();

            this.store.dispatch(new SensorActions.FetchForMap(map.getBounds()));
          }
        }),
      )
      .subscribe();
  }

  mapReady(map: Map) {
    this.map$.next(map);
  }

  private async generateMapLayers() {
    // Generate sensor layer
    this.store.select(SensorState.mapList).subscribe((mapList) => {
      this.sensorLayer = new LayerGroup<any>(
        mapList.map((sensor: SensorBox) =>
          this.leafletHelper.generateSensorMarker(sensor, false, () =>
            this.router.navigate(['/micro', 'sensor', sensor.id]),
          ),
        ),
      );
      // Generate waterway layer if required
      if (this.activatedRoute.snapshot.queryParams.waterways) {
        this.getWaterways();
      }
    });
  }

  private async getWaterways() {
    await this.leafletHelper
      .generateWaterwayLayers(
        this.map$.value.getBounds(),
        this.map$.value.getZoom(),
        this.getWaterways,
      )
      .pipe(first((layer) => !!layer))
      .subscribe((layer) => (this.waterwayLayer = layer));
    this.changeDetectorRef.detectChanges();
  }
}
