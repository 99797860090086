import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, SortDirection } from 'ag-grid-community';

export interface TranslationHeaderParams extends IHeaderParams {
  alignment: 'start' | 'end' | 'center';
}

@Component({
  selector: 'app-translation-header',
  template: `
    <div
      class="header-wrapper {{ params.enableSorting ? 'sort' : 'no-sort' }}"
      [style.justify-content]="params.alignment || 'start'"
      (click)="params.enableSorting && onSortRequested()"
    >
      <div [translate]="params.displayName"></div>
      <span
        *ngIf="params.enableSorting"
        class="header-sort-indicator {{ sortDirection || 'no-sort' }}"
      >
        <mat-icon *ngIf="sortDirection === 'asc'">arrow_upward</mat-icon>
        <mat-icon *ngIf="sortDirection === 'desc'">arrow_downward</mat-icon>
        <mat-icon *ngIf="!sortDirection">arrow_upward</mat-icon>
      </span>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
      .header-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .header-wrapper.no-sort {
        cursor: initial;
      }
      .header-wrapper:hover .header-sort-indicator.no-sort {
        display: flex;
      }
      .header-sort-indicator {
        display: flex;
        color: initial !important;
      }
      .header-sort-indicator.no-sort {
        display: none;
      }
      mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
        opacity: 0.4;
        font-weight: bold;
      }
    `,
  ],
})
export class TranslationHeaderComponent implements IHeaderAngularComp {
  params: TranslationHeaderParams;
  sortDirection: SortDirection = null;

  agInit(params: TranslationHeaderParams): void {
    this.params = params;

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  refresh(params: TranslationHeaderParams): boolean {
    this.params = params;
    return true;
  }

  onSortChanged() {
    this.sortDirection = this.params.column.getSort();
  }

  onSortRequested() {
    this.params.progressSort();
  }
}
