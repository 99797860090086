<div
  *ngIf="showMenu$ | async"
  class="navigation-menu container-fluid ch-padding-8"
>
  <ng-container *ngFor="let entry of menuEntries$ | async">
    <div *ngIf="!entry.disabled" class="menu-entry ch-margin-top-24">
      <a
        [routerLink]="[rootPath, entry.path]"
        routerLinkActive="active"
        [title]="entry.tooltip | translate"
      >
        <div class="menu-icon ch-padding-8 ch-margin-bottom-8">
          <img
            src="assets/img/{{ entry.icon }}.svg"
            [alt]="entry.tooltip | translate"
          />
        </div>
        <p [innerHTML]="entry.tooltip | translate"></p>
      </a>
    </div>
  </ng-container>
</div>
