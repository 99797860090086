import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { CustomerService } from 'src/app/services/customer.service';
import {
  Customer,
  AgsLookup,
  createEmptyCustomer,
} from 'src/app/models/customerList';
import { LookupEntry } from 'src/app/models/apiResults';
import { LookupService } from 'src/app/services/lookup.service';
import * as moment from 'moment';
import { AddAgsDialogComponent } from '../addAgs-modal/addAgsDialog.component';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

@Component({
  selector: 'app-alarmGroup-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  customerDetails: Customer;
  submitted: boolean;
  crudDeviceForm: FormGroup;
  mode: string;
  isSuperAdmin: boolean;
  isAdmin: boolean;

  isEditable: boolean;

  templateList: LookupEntry[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private customerService: CustomerService,
    private lookupService: LookupService,
    private matDialog: MatDialog,
    private store: Store,
  ) {
    this.crudDeviceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      mapCenterLat: new FormControl('', [Validators.required]),
      mapCenterLon: new FormControl('', [Validators.required]),
      zoomDashboard: new FormControl('', [Validators.required]),
      zoomApp: new FormControl('', [Validators.required]),
      alarmMessageTemplateId: new FormControl('', []),
      clearMessageTemplateId: new FormControl('', []),
      ags: new FormControl('', []),
      bigRegistrationDate: new FormControl('', []),
      featurePhoneCall: new FormControl('', []),
      featureVoiceMessage: new FormControl('', []),
      featureApp: new FormControl('', []),
      featureWeather: new FormControl('', []),
      featureRainRadar: new FormControl('', []),
      featureAlarmGroups: new FormControl('', []),
      featureAlarmRules: new FormControl('', []),
      featureAlarmActions: new FormControl('', []),
      maueCostCenter: new FormControl('', [Validators.maxLength(20)]),
      featureReports: new FormControl('', []),
    });
    this.submitted = false;
  }

  ngOnInit(): void {
    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.isEditable = true;

    this.customerDetails = createEmptyCustomer();
    if (this.mode === 'add') {
      this.customerDetails = createEmptyCustomer();
      this.dataToForm();
    } else if (this.mode === 'edit') {
      this.loadCustomer();
    } else {
      this.customerDetails = createEmptyCustomer();
      this.isEditable = false;
      this.f.name.disable();
      this.f.description.disable();
      this.f.active.disable();
    }

    this.f.bigRegistrationDate.disable();

    this.lookupService.getMessageTemplateList().then((response) => {
      this.templateList = response;
    });
  }

  ngOnDestroy() { }

  private parseDate(date: string | Date): string {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  }

  private dataToForm() {
    this.crudDeviceForm.patchValue({
      name: this.customerDetails.name,
      active: this.customerDetails.active,
      ags: this.customerDetails.ags,
      mapCenterLat: this.customerDetails.mapCenterLat,
      mapCenterLon: this.customerDetails.mapCenterLon,
      zoomApp: this.customerDetails.mapZoomApp,
      zoomDashboard: this.customerDetails.mapZoomDashboard,
      bigRegistrationDate: this.parseDate(
        this.customerDetails.bigRegistrationDate,
      ),
      alarmMessageTemplateId: this.customerDetails.alarmMessageTemplate?.id,
      clearMessageTemplateId: this.customerDetails.clearMessageTemplate?.id,

      featureVoiceMessage: this.customerDetails.featureVoiceMessage,
      featurePhoneCall: this.customerDetails.featurePhoneCall,
      featureApp: this.customerDetails.featureApp,
      featureNina: this.customerDetails.featureNina,
      featureWeather: this.customerDetails.featureWeather,
      featureRainRadar: this.customerDetails.featureRainRadar,
      featureAlarmGroups: this.customerDetails.featureAlarmGroups,
      featureAlarmRules: this.customerDetails.featureAlarmRules,
      featureAlarmActions: this.customerDetails.featureAlarmActions,
      maueCostCenter: this.customerDetails.maueCostCenter,
      featureReports: this.customerDetails.featureReports,
    });
  }

  getMapCenter() {
    const context = this.store.selectSnapshot(GuiState.guiContext);
    this.crudDeviceForm.patchValue({
      mapCenterLat: context.mapCenter.lat,
      mapCenterLon: context.mapCenter.lng,
      zoomDashboard: context.zoomLevel,
    });
  }

  async loadCustomer() {
    const url = this.route.snapshot.params;
    this.customerService.load(url.id).then((result) => {
      this.customerDetails = result;
      this.dataToForm();
    });
  }

  get f() {
    return this.crudDeviceForm.controls;
  }

  toggleActive() {
    this.customerDetails.active = !this.customerDetails.active;
    this.crudDeviceForm.patchValue({
      active: this.customerDetails.active,
    });
  }

  toggleFeature(featureName: string) {
    this.customerDetails[featureName] = !this.customerDetails[featureName];
    this.crudDeviceForm.patchValue({
      active: this.customerDetails.active,
    });
  }

  async saveCustomer() {
    this.submitted = true;

    if (this.crudDeviceForm.invalid) {
      return;
    }

    this.customerDetails.name = this.f.name.value;

    this.customerDetails.active = this.f.active.value;
    this.customerDetails.ags = this.f.ags.value;
    this.customerDetails.mapCenterLat = this.f.mapCenterLat.value;
    this.customerDetails.mapCenterLon = this.f.mapCenterLon.value;
    this.customerDetails.mapZoomApp = this.f.zoomApp.value;
    this.customerDetails.mapZoomDashboard = this.f.zoomDashboard.value;
    this.customerDetails.alarmMessageTemplate = this.f.alarmMessageTemplateId
      .value
      ? { id: this.f.alarmMessageTemplateId.value, name: null }
      : null;
    this.customerDetails.clearMessageTemplate = this.f.clearMessageTemplateId
      .value
      ? { id: this.f.clearMessageTemplateId.value, name: null }
      : null;

    this.customerDetails.featurePhoneCall = this.f.featurePhoneCall.value;
    this.customerDetails.featureVoiceMessage = this.f.featureVoiceMessage.value;
    this.customerDetails.featureApp = this.f.featureApp.value;
    this.customerDetails.featureWeather = this.f.featureWeather.value;
    this.customerDetails.featureRainRadar = this.f.featureRainRadar.value;
    this.customerDetails.featureAlarmGroups = this.f.featureAlarmGroups.value;
    this.customerDetails.featureAlarmRules = this.f.featureAlarmRules.value;
    this.customerDetails.featureAlarmActions = this.f.featureAlarmActions.value;
    this.customerDetails.featureReports = this.f.featureReports.value;

    this.customerDetails.maueCostCenter = this.f.maueCostCenter.value;

    try {
      if (this.mode === 'add') {
        this.customerService
          .createCustomer(this.customerDetails)
          .then((result) => {
            this.customerDetails = result;
            this.toastHelper.openSnackBar('Kunde gespeichert!', 'SUCCESS');
            this.goBack();
          });
      } else {
        this.customerService.save(this.customerDetails).then((result) => {
          this.customerDetails = result;
          this.toastHelper.openSnackBar('Kunde gespeichert!', 'SUCCESS');
          this.goBack();
        });
      }
    } catch (e) {
      this.toastHelper.openCrudChangeError();
    }
  }

  deleteAlarmGroup() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diesen Kunden wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.customerService.delete(this.customerDetails.id).then(() => {
            this.customerService.getList().then(() => {
              this.toastHelper.openSnackBar('Kunde gelöscht!', 'SUCCESS');
            });
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'customers', 'overview']);
  }

  removeAgs(agsLookup: AgsLookup) {
    this.customerDetails.alertingAgsList =
      this.customerDetails.alertingAgsList.filter(
        (item) => item.ags != agsLookup.ags,
      );
  }

  addAgs() {
    const dialogOptions: MatDialogConfig = {
      data: {
        customer: this.customerDetails,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddAgsDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result.selectedAgs) {
          this.customerDetails.alertingAgsList.push(result.selectedAgs);
        }
      });
  }
}
