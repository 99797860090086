import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapComponent } from './base/map/map.component';
import { FilterComponent } from './base/map/filter/filter.component';
import { DashboardComponent } from './base/dashboard/dashboard.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HeaderComponent } from './base/header/header.component';
import { RouterModule } from '@angular/router';
import { PrimaryPanelComponent } from './base/primary-panel/primary-panel.component';
import { BreadcrumbComponent } from './base/breadcrumb/breadcrumb.component';
import { NavigationMenuComponent } from './base/navigation-menu/navigation-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ApprovalModalComponent } from './base/approval-modal/approval-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { HelpComponent } from './base/help/help.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { NotificationsComponent } from './notifications/notifications.component';
import { SensorsModule } from './sensors/sensors.module';
import { AlarmGroupsModule } from './alarmGroups/alarmGroups.module';
import { AlarmRulesModule } from './alarmRules/alarmRules.module';
import { UsersModule } from './users/users.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { StyleguideComponent } from './base/styleguide/styleguide.component';
import { TimeSliderComponent } from './base/map/controls/time-slider/time-slider.component';
import { RainComponent } from './base/map/layers/rain/rain.component';
import { WaterwaysComponent } from './base/map/layers/waterways/waterways.component';
import { WeatherStationsComponent } from './base/map/layers/weather-stations/weather-stations.component';
import { WaterlevelSensorsComponent } from './base/map/layers/waterlevel-sensors/waterlevel-sensors.component';
import { WarningBrokerModule } from './warningBroker/warningBroker.module';
import { PrecipAreaSensorsComponent } from './base/map/layers/precip-area-sensors/precip-area-sensors.component';
import { AlarmActionsModule } from './alarmActions/alarmActions.module';
import { AlarmChannelsModule } from './alarmChannels/alarmChannels.module';
import { PrecipSensorsComponent } from './base/map/layers/precip-sensors/precip-sensors.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HeaderComponent,
    MapComponent,
    FilterComponent,
    PrimaryPanelComponent,
    BreadcrumbComponent,
    NavigationMenuComponent,
    ApprovalModalComponent,
    HelpComponent,
    NotificationsComponent,
    StyleguideComponent,
    TimeSliderComponent,
    RainComponent,
    WaterwaysComponent,
    WeatherStationsComponent,
    WaterlevelSensorsComponent,
    PrecipAreaSensorsComponent,
    PrecipSensorsComponent,
  ],
  imports: [
    DashboardRoutingModule,
    SensorsModule,
    AlarmGroupsModule,
    AlarmRulesModule,
    AlarmActionsModule,
    AlarmChannelsModule,
    UsersModule,
    CommonModule,
    LeafletModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild(),
    MatSelectModule,
    MatSliderModule,
    WarningBrokerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
