import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { SensorActions } from 'src/app/state/noysee/sensor.action';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss'],
})
export class SensorComponent implements OnInit {
  sensor$ = this.store.select(SensorState.currentSensor);

  hideExtra: boolean;
  hideBack: boolean;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Set sensor ID
    this.store.dispatch(
      new SensorActions.Select(
        parseInt(this.activatedRoute.snapshot.params['uuid'], 10),
      ),
    );

    this.hideExtra = this.activatedRoute.snapshot.queryParams['hideExtra'];

    this.hideBack = this.activatedRoute.snapshot.queryParams['hideBack'];
  }
}
