
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { LookupService } from '../../../../../services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { formatNumber } from '@angular/common';
import { ImportConfigService } from '@app/services/importConfig.service';
import { ImportConfig } from '@app/models/importConfig';
import { SensorState } from '@app/state/noysee/sensor.state';
import { Store } from '@ngxs/store';
import { GeoPolygonService } from '@app/services/geo.service';
import { GeoPolygone, PolygoneType } from '@app/models/topo';

@Component({
  selector: 'app-importConfigDetail-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  PolygoneType=PolygoneType;
	
  routerSubscription: Subscription;
  geoPolygone: GeoPolygone;
  submitted: boolean;

  crudForm: FormGroup;

  sensorBoxList$ = this.store.select(SensorState.myList);
  geoPolygones: LookupEntry[];

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
	private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private matDialog: MatDialog,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private geoPolygoneService: GeoPolygonService,
    private lookupService: LookupService) {

    this.geoPolygone = new GeoPolygone();

    this.crudForm = this.formBuilder.group({
      active: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      polygoneType: new FormControl('', []),
      externalId: new FormControl('', []),
	  latCenter: new FormControl('', []),
	  lonCenter: new FormControl('', []),
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';

  }

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadGeoPolygone();
    } else {
      // this.geoPolygone.active = true;
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadGeoPolygone();
      });

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {

    this.crudForm.patchValue({
      // active: this.geoPolygone.active,
      name: this.geoPolygone.name,
      description: this.geoPolygone.description,
      polygoneType: this.geoPolygone.polygoneType,
      externalId: this.geoPolygone.externalId,
	  latCenter: this.geoPolygone.latCenter,
	  lonCenter: this.geoPolygone.lonCenter,
	  latNorth:  this.geoPolygone.latNorth,
      latSouth:  this.geoPolygone.latSouth,
	  lonWest: this.geoPolygone.lonWest,
	  lonEast: this.geoPolygone.lonEast,
    });

  }

  loadGeoPolygone() {
    const url = this.route.snapshot.params;
    this.geoPolygoneService.load( url.id).then(data => {
      this.geoPolygone = data;
      this.dataToForm();
    });

  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    // this.geoPolygone.active = !this.geoPolygone.active;
    this.crudForm.patchValue({
      // active: this.geoPolygone.active,
    });
  }

  async save() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    // this.geoPolygone.active = this.f.active.value;
    this.geoPolygone.description = this.f.description.value;
    this.geoPolygone.name = this.f.name.value;
	this.geoPolygone.latCenter = this.f.latCenter.value;
	this.geoPolygone.lonCenter = this.f.lonCenter.value;

    try {
      this.geoPolygoneService.save(this.geoPolygone).then(result => {
        this.geoPolygone = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('Vorlage gespeichert!', 'SUCCESS');
        this.goBack();
      }).catch(error => {
        this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
      });

    } catch (e) {
      this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
    }
  }

  deleteGeoPolygone() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie diesen Gebiet wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.geoPolygoneService.delete(this.geoPolygone.id).then(result => {
            this.goBack();
            this.toastHelper.openSnackBar('Gebiet gelöscht!', 'SUCCESS');
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'geoPolygone', 'overview']);
  }

  exportFileTypes(): { label: string, value: string }[] {
    return [];
  }

}
