<ng-container *ngIf="mapOptions$ | async as mapOptions">
  <div
    id="bg-map"
    leaflet
    [leafletOptions]="mapOptions"
    (leafletMapReady)="mapReady($event)"
  >
    <!-- Custom Layer -->
    <ng-container *ngIf="leafletMap">
      <leaflet-rain-layer [leafletMap]="leafletMap"></leaflet-rain-layer>
      <leaflet-waterways-layer
        [leafletMap]="leafletMap"
      ></leaflet-waterways-layer>
      <leaflet-waterlevel-sensor-layer
        [leafletMap]="leafletMap"
      ></leaflet-waterlevel-sensor-layer>
	  <leaflet-precip-sensor-layer
	    [leafletMap]="leafletMap"
	  ></leaflet-precip-sensor-layer>
      <leaflet-precipArea-sensor-layer
        [leafletMap]="leafletMap"
      ></leaflet-precipArea-sensor-layer>
      <leaflet-weather-stations-layer
        [leafletMap]="leafletMap"
      ></leaflet-weather-stations-layer>
      <!-- Controls -->
      <leaflet-loading-control [map]="leafletMap"></leaflet-loading-control>
    </ng-container>
  </div>

  <leaflet-layer-filter></leaflet-layer-filter>
</ng-container>
