import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTableComponent } from './data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IconRegistrationService } from './icon/icon-registration.service';
import { IconComponent } from './icon/icon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from './paginator/paginator.component';
import { PdfService } from './pdf/pdf.service';
import { DownloadService } from './download/download.service';
import { ColumnTextComponent } from './data-table/column-text/column-text.component';
import { ColumnIconlabelComponent } from './data-table/column-iconlabel/column-iconlabel.component';
import { BaseColumnComponent } from './data-table/base-column.component';
import { RowActionMenuComponent } from './data-table/row-action-menu/row-action-menu.component';
import { RowActionButtonsComponent } from './data-table/row-action-buttons/row-action-buttons.component';
import { ColumnEditorComponent } from './data-table/column-editor/column-editor.component';
import { DataTableStateService } from './data-table-state/data-table-state.service';
import { DatePickerComponent } from './input/date-picker/date-picker.component';
import { TileListComponent } from './input/tile-list/tile-list.component';
import { AbListComponent } from './input/ab-list/ab-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { TranslateDirective } from './translate/translate.directive';
import { SharedAgGridModule } from './ag-grid/ag-grid.module';
import { DateRangeSelectComponent } from './form/date-range-select/date-range-select.component';
import { DateValueChartComponent } from './chart/date-value-chart/date-value-chart.component';

const matModules = [
  MatButtonModule,
  MatDatepickerModule,
  MatMenuModule,
  MatIconModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatInputModule,
  MatTooltipModule,
  MatDialogModule,
  MatListModule,
];

@NgModule({
  declarations: [
    DataTableComponent,
    IconComponent,
    PaginatorComponent,
    BaseColumnComponent,
    ColumnTextComponent,
    ColumnIconlabelComponent,
    RowActionMenuComponent,
    RowActionButtonsComponent,
    ColumnEditorComponent,
    DatePickerComponent,
    TileListComponent,
    TranslateDirective,
    DateRangeSelectComponent,
    DateValueChartComponent,
    AbListComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgxMaterialTimepickerModule.setLocale('de-DE'),
    ...matModules,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    DatePipe,
    TranslatePipe,
    DownloadService,
    PdfService,
    DataTableStateService,
  ],
  exports: [
    DataTableComponent,
    NgxMaterialTimepickerModule,
    ...matModules,
    IconComponent,
    DatePickerComponent,
    TranslateDirective,
    TileListComponent,
    AbListComponent,
    SharedAgGridModule,
    DateRangeSelectComponent,
    DateValueChartComponent,
  ],
})
export class SharedModule {
  constructor(iconRegistrationService: IconRegistrationService) {
    iconRegistrationService.initializeIcons();
  }
}
