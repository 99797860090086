import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ImageService } from '../../../../services/image.service';
import { ImageEditorComponent } from '../../users/image-editor/image-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';
import { Observable } from 'rxjs';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { filter, map, startWith, tap } from 'rxjs/operators';

const latLonRegex = '^[0-9]+.[0-9]*$';

@Component({
  selector: 'app-crud-sensor',
  templateUrl: './crud-sensor.component.html',
  styleUrls: ['./crud-sensor.component.scss'],
})
export class CrudSensorComponent implements OnInit {
  mode: string = '';
  sensorBox$: Observable<SensorBox> = this.store
    .select(SensorState.currentSensor)
    .pipe(
      startWith(new SensorBox()),
      filter((sensorBox) => !!sensorBox),
      tap(console.log),
      // Patch values in form when sensor exists
      tap((sensorBox) => this.dataToForm(sensorBox)),
    );
  crudSensorForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
    address: new FormControl('', [Validators.maxLength(60)]),
    lon: new FormControl('', [
      Validators.required,
      Validators.pattern(latLonRegex),
    ]),
    lat: new FormControl('', [
      Validators.required,
      Validators.pattern(latLonRegex),
    ]),
    datasource: new FormControl(
      { value: '', disabled: true },
      Validators.required,
    ),
    maueInventoryNumber: new FormControl({ value: '', disabled: true }, []),
    sensors: new FormGroup({}),
  });
  submitted: boolean = false;

  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;

  @ViewChild('imageUpload') imageUpload: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private imageService: ImageService,
    private dialog: MatDialog,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    // Fetch sensor initally
    if (this.mode === 'edit') {
      this.store.dispatch(
        new SensorActions.Select(
          parseInt(this.route.snapshot.paramMap.get('id'), 10),
        ),
      );
    }
  }

  get f() {
    return this.crudSensorForm.controls;
  }

  private patchPrimarySensor(sensorBox: SensorBox) {
    this.crudSensorForm.setControl(
      'sensors',
      new FormGroup({
        [sensorBox.primarySensor]: new FormGroup({
          limit1: new FormControl(null),
          limit2: new FormControl(null),
          limitDelta: new FormControl(null),
          validationMin: new FormControl(null),
          validationMax: new FormControl(null),
          displayMinY: new FormControl(null),
          displayMaxY: new FormControl(null),
          maxGradientUp: new FormControl(null),
          maxGradientDown: new FormControl(null),
        }),
      }),
    );
  }

  dataToForm(sensorBox: SensorBox) {
    // Patch primary sensor into form
    this.patchPrimarySensor(sensorBox);

    // Update values in form
    this.crudSensorForm.patchValue(sensorBox);
  }

  saveSensor(sensorBox: SensorBox) {
    this.submitted = true;
    // Do nothing if form invalid
    if (this.crudSensorForm.invalid) {
      return;
    }
    let payload = {
      ...this.store.selectSnapshot(SensorState.currentSensor),
      ...this.crudSensorForm.getRawValue(),
      sensors: {
        ...this.store.selectSnapshot(SensorState.currentSensor).sensors,
        ...this.crudSensorForm.getRawValue().sensors,
      },
    };

    if (this.mode === 'add') {
      payload.statusInfo = 'normal';
      this.store.dispatch(new SensorActions.Create(payload));
      this.router.navigate(['/dashboard', 'sensors']);
    } else {
      /*mode === edit*/
      this.store.dispatch(new SensorActions.Update(sensorBox.id, payload));
      this.router.navigate(['/dashboard', 'sensors', 'detail', sensorBox.id]);
    }
  }

  backToSensorDetail(sensorBox: SensorBox) {
    if (sensorBox.id) {
      this.router.navigate(['/dashboard', 'sensors', 'detail', sensorBox.id]);
    } else {
      this.router.navigate(['/dashboard', 'sensors', 'overview']);
    }
  }

  selectImage(): void {
    this.imageUpload.nativeElement.click();
  }

  openImageEditor(image: any, sensorBox: SensorBox) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      height: '80%',
      width: '80%',
      data: {
        title: 'sensor.crud.image',
        image,
      },
    });

    dialogRef.afterClosed().subscribe((imageResponse: string) => {
      if (!!imageResponse) {
        this.imageService.uploadSensorImage(imageResponse, sensorBox.id);
      }
    });
  }
}
