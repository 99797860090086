import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Directive({
  selector: '[appTranslate]',
})
export class TranslateDirective implements OnInit, OnChanges {
  @Input() appTranslate: string;

  /**
   * This directive exists to make translating strings in the templates easier.
   * If other content exists in the selected tag, this pipe WILL OVERRIDE it.
   * Therefore, add a new span where the translated text should be (inside the tag) and translate the text inside the span.
   *
   * @param elementRef - The element that the directive exists on
   * @param renderer - Renderer for native html content
   * @param sanitizer - DomSanitizer to directly render HTML strings
   * @param translatePipe - Translate pipe for translating strings
   */
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly translatePipe: TranslatePipe,
  ) {}

  ngOnInit() {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      this.translatePipe.transform(this.appTranslate),
    );
  }

  ngOnChanges() {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      this.translatePipe.transform(this.appTranslate),
    );
  }
}
