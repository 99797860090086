import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { UsersService } from '../../../../../services/users.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TestAlarmModalComponent } from '../test-alarm-modal/test-alarm-modal.component';
import { TestMessageModalComponent } from '../test-message-modal/test-message-modal.component';
import { Store } from '@ngxs/store';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';
import { AddAlarmContactsComponent } from '../addAlarmContacts-modal/addAlarmContacts.component';

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
	@Input()
	set sensor(sensor$: Observable<SensorBox>) {
		this.sensorSubscription?.unsubscribe();
		this.sensorSubscription = sensor$.subscribe(async (sensor) => {
			if (sensor) {
				this.currentSensor = sensor;

				this.sensorName = sensor.name;
				this.currentSensorId = sensor.id;
				this.alarmReceiverVisible = (sensor as any).alarmReceiverVisible;
				this.alarmReceiverEditable = (sensor as any).alarmReceiverEditable;
				if (sensor.sensors[sensor.primarySensor]) {
					this.limit1 = sensor.sensors[sensor.primarySensor].limit1;
					this.limit2 = sensor.sensors[sensor.primarySensor].limit2;
					this.unit = sensor.sensors[sensor.primarySensor].unit;
				}

				this.alarmUsers = await this.getAlarmUsers(sensor);

				this.filterAlarms();
				this.sortAlarms();
			}
		});
	}

	currentSensor: SensorBox;
	limit1: number;
	limit2: number;
	unit: string;
	alarmUsers: any;
	filterString: string;
	sortTopic: string;
	alarmReceiverVisible: boolean;
	alarmReceiverEditable: boolean;
	isSuperAdmin: boolean;
	isAdmin: boolean;
	currentSensorId: number;
	sensorName: string;
	sensorSubscription: Subscription;

	featureVoiceMessage: boolean;
	featurePhoneCall: boolean;

	constructor(
		private authenticationService: AuthenticationService,
		private matDialog: MatDialog,
		private usersService: UsersService,
		private noyseeService: NoyseeService,
		private store: Store,
		private router: Router,
	) {
		this.currentSensorId = null;
		this.limit1 = 0;
		this.limit2 = 0;
		this.unit = '';
		this.alarmReceiverVisible = false;
		this.alarmReceiverEditable = false;
		this.alarmUsers = [];
		this.filterString = '';
		this.sortTopic = 'sort-abc';
		this.isSuperAdmin = this.authenticationService.isSuperAdmin();
		this.isAdmin = this.authenticationService.isAdmin();
		this.featurePhoneCall =
			this.authenticationService.customer().featurePhoneCall;
		this.featureVoiceMessage =
			this.authenticationService.customer().featureVoiceMessage;
	}

	async getAlarmUsers(sensor): Promise<any[]> {
		return Promise.all(
			sensor.alarmReceiver.map(async (alarm) => {
				const user = await this.usersService.getById(alarm.userId).toPromise();
				return { ...alarm, ...user };
			}),
		);
	}

	filterAlarmArrayByUserUuid(arr: any[], uuid: string) {
		return arr.find((elem) => elem.userId === uuid);
	}

	toggleDetailForAlarm(
		uuid: string,
		limit: 'fail' | 'limit1' | 'limit2',
		detail: 'enabled' | 'phone' | 'sms' | 'email' | 'voiceMessage',
	) {
		const alarm = this.filterAlarmArrayByUserUuid(this.alarmUsers, uuid);

		const currentAlarm = {
			sensorBoxId: this.currentSensorId,
			alarmReceiver: {
				...alarm,
				[limit]: {
					...alarm[limit],
					[detail]: !alarm[limit][detail],
				},
			},
		};

		// userId is the important on in this merged object
		this.noyseeService
			.changeAlarm(currentAlarm)
			.subscribe(() =>
				this.store.dispatch(new SensorActions.Select(this.currentSensorId)),
			);
	}

	removeAlarm(uuid: string) {
		const currentAlarmReceiver = {
			id: this.currentSensorId,
			alarmReceiver: this.alarmUsers.filter(function(alarm) {
				return alarm.userId !== uuid;
			}),
		};
		this.noyseeService.removeAlarmReceiver(currentAlarmReceiver);
	}

	filterAlarms() {
		const _this = this;

		this.filterString = this.filterString.toLowerCase();

		// filter users
		this.alarmUsers = this.alarmUsers.map((user) => ({
			...user,
			hidden: !user.name?.toLowerCase().includes(_this.filterString),
		}));
	}

	sortAlarms() {
		switch (this.sortTopic) {
			case 'sort-abc':
				this.sortAlarmsAlphabetical();
				break;
			default:
				this.sortAlarmsAlphabetical();
				break;
		}
	}

	sortAlarmsAlphabetical() {
		this.alarmUsers.sort((a, b) => {
			if (a.name && b.name) {
				const nameA = a.name;
				const nameB = b.name;

				return nameA.localeCompare(nameB);
			}
		});
	}

	testAlarm() {
		const dialogOptions: MatDialogConfig = {
			data: {
				name: this.sensorName,
				id: this.currentSensorId,
			},
			maxWidth: 600,
		};

		this.matDialog.open(TestAlarmModalComponent, dialogOptions);
	}

	testMessage() {
		const dialogOptions: MatDialogConfig = {
			data: {
				name: this.sensorName,
				id: this.currentSensorId,
			},
			maxWidth: 600,
		};
		this.matDialog.open(TestMessageModalComponent, dialogOptions);
	}

	addAlarm(): void {
		const dialogOptions: MatDialogConfig = {
			data: {
				sensorBox: this.currentSensor,
			},
			maxWidth: 900,
		};
		this.matDialog
			.open(AddAlarmContactsComponent, dialogOptions)
			.afterClosed()
			.toPromise().then(l => {
				this.getAlarmUsers(this.currentSensor);
			});
	}

}
