import { Component, Input } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { range } from 'lodash';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input()
  gridApi!: GridApi;

  totalPagesArray(gridApi: GridApi): number[] {
    return range(gridApi.paginationGetTotalPages());
  }
}
