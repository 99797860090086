<div class="primary-panel-large container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode == 'add'" appTranslate="alarmRule.table.actions.add"></h1>
      <h1 *ngIf="mode == 'edit'" appTranslate="alarmRule.table.actions.edit"></h1>
    </div>
  </div>
  <form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveAlarmRule()">

    <div class="row ch-margin-top-24">
      <div class="col-1 role">
        <strong appTranslate="alarmGroup.member.state.active"></strong>
      </div>
      <div class="col-4 role">
        <div class="form-field" [class.error]="submitted && f.active.invalid">
          <label class="switch">
            <input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">{{'alarmRule.table.columns.name' | translate}} *</label>
          <input placeholder="" id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4"
               appTranslate="alarmRule.crud.error.name"></div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.description.invalid">
          <label for="input-description"><span appTranslate="alarmRule.crud.description"></span></label>
          <input placeholder="" id="input-description" formControlName="description">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-alarmMessageTemplateId" appTranslate="alarmRule.template.alarm"></label>
          <select [attr.disabled]="null" id="select-alarmMessageTemplateId" formControlName="alarmMessageTemplateId">
            <option [ngValue]="null" selected="true">-</option>
            <option [ngValue]="lookup.id" *ngFor="let lookup of templateList">{{lookup.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="input-clearMessageTemplateId" appTranslate="alarmRule.template.allClear"></label>
          <select [attr.disabled]="null" id="select-clearMessageTemplateId" formControlName="clearMessageTemplateId">
            <option [ngValue]="null" selected="true">-</option>
            <option [ngValue]="lookup.id" *ngFor="let lookup of templateList">{{lookup.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.description.invalid">
          <label for="input-definition"><span appTranslate="alarmRule.crud.rule"></span></label>
          <textarea placeholder="" id="input-definition" formControlName="definition" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudForm.disabled" appTranslate="alarmRule.crud.actions.save"></button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled"
                appTranslate="alarmRule.crud.actions.cancel"></button>
      </div>
      <div class="col-3">
      </div>
      <div *ngIf="mode === 'edit'" class="col-3">
        <button type="button" class="btn-secondary" (click)="deleteAlarmRule()" *ngIf="!crudForm.disabled"
                appTranslate="alarmRule.crud.actions.delete"></button>
      </div>
    </div>
  </form>

  <div *ngIf="mode === 'edit' || mode === 'view'" class="col-12 ch-margin-top-24">
    <app-data-table [dataTableOptions]="dataTableOptions" [state]="gridState" [data]="groupMemberList"></app-data-table>
  </div>
</div>
