import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'devices',
    data: {
      breadcrumbLabel: 'device.headerLabel'
    },
    loadChildren: () =>
      import('./noyseeDevices/noyseeDevices.module').then(mod => mod.NoyseeDevicesModule)
  },
  {
    path: 'firmware',
    data: {
      breadcrumbLabel: 'firmware.headerLabel'
    },
    loadChildren: () =>
      import('./firmware/firmware.module').then(mod => mod.FirmwareModule)
  },
  {
    path: 'messageTemplates',
    data: {
      breadcrumbLabel: 'messageTemplate.headerLabel'
    },
    loadChildren: () =>
      import('./messageTemplates/messageTemplates.module').then(mod => mod.MessageTemplatesModule)
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then(mod => mod.BillingModule)
  },
  {
    path: 'customers',
    data: {
      breadcrumbLabel: 'customer.headerLabel'
    },
    loadChildren: () =>
      import('./customers/customers.module').then(mod => mod.CustomersModule)
  },
  {
    path: 'exportFiles',
    data: {
      breadcrumbLabel: 'exportFile.table.actions.export'
    },
    loadChildren: () =>
      import('./exportFiles/exportFiles.module').then(mod => mod.ExportFilesModule)
  },
  {
    path: 'importConfig',
    data: {
      breadcrumbLabel: 'importConfig.headerLabel'
    },
    loadChildren: () =>
      import('./importConfig/importConfig.module').then(mod => mod.ImportConfigModule)
  },
  {
    path: 'geoPolygone',
    data: {
      breadcrumbLabel: 'geoPolygone.headerLabel'
    },
    loadChildren: () =>
      import('./geoPolygone/geoPolygone.module').then(mod => mod.GeoPolygoneModule)
  },
  
  {
    path: 'loraWanConfigs',
    data: {
      breadcrumbLabel: 'loraWanConfig.serverLabel'
    },
    loadChildren: () =>
      import('./loraWanConfigs/loraWanConfigs.module').then(mod => mod.LoraWanConfigsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
