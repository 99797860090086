import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  AbstractControlOptions,
} from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { ExportFile } from '../../../../../models/exportFile';
import { LookupService } from '../../../../../services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ExportFileService } from 'src/app/services/exportFile.service';
import { Product, BillingState, BillingMethod } from 'src/app/models/billing';
import { BillingService } from 'src/app/services/billing';
import * as moment from 'moment';
import { GridState } from 'src/app/shared/data-table/data-table.model';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

@Component({
  selector: 'app-delivery-detail',
  templateUrl: './deliveryDetail.component.html',
  styleUrls: ['./deliveryDetail.component.scss'],
})
export class DeliveryDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;

  delivery: Product;
  customerList: LookupEntry[];
  productList: Product[];

  submitted: boolean;
  crudForm: FormGroup;

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  ctx: GridState = null;

  billable: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private store: Store,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private billingService: BillingService,
    private lookupService: LookupService,
  ) {
    this.delivery = new Product();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      customerId: new FormControl('', []),
      selectedProductId: new FormControl('', []),
      amount: new FormControl('', []),
      price: new FormControl('', []),
      billingInterval: new FormControl('', []),
      delivery: new FormControl('', []),
      trackingNumber: new FormControl('', []),
      contractStart: new FormControl('', []),
      contractEnd: new FormControl('', []),
      contractPeriodBase: new FormControl('', []),
      contractPeriodExtension: new FormControl('', []),
      noticePeriod: new FormControl('', []),
      maueOrderNumber: new FormControl({ value: '', disabled: true }, []),
      maueInventoryNumber: new FormControl({ value: '', disabled: true }, []),
      maueDeviceModel: new FormControl({ value: '', disabled: true }, []),
      dmat: new FormControl({ value: '', disabled: true }, []),
      billingMethod: new FormControl('', []),
      billingState: new FormControl({ value: '', disabled: true }, []),
    },
      { validators: ContractStartValidator } as AbstractControlOptions);
    this.submitted = false;


    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';
  }

  ngOnInit(): void {
    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    this.lookupService.getCustomerList().then((result) => {
      this.customerList = result;

      console.debug(this.customerList);
      this.delivery.customer = this.customerList.find(
        (c) => c.id === this.store.selectSnapshot(GuiState.selectedCustomer).id,
      );
      console.debug(this.delivery.customer.id);

    });

    this.billingService.getProducts().then((result) => {
      this.productList = result;
    });

    if (this.mode == 'add') {
      console.debug(this.store.selectSnapshot(GuiState.selectedCustomer).id);
      this.delivery.billingState = BillingState.OPEN;
      this.delivery.contractStart = null;
      this.delivery.contractEnd = null;
    } else if (this.mode == 'view') {
      this.loadDelivery();
      this.billable = this.delivery.billingMethod!=BillingMethod.NONE && this.delivery.billingState==BillingState.OPEN;
    }

    this.dataToForm();

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadDelivery();
      });
  }

  ngAfterViewInit(): void { }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  setBillingInterval($event: any) {
    this.delivery.billingInterval = $event.target.value;
  }

  private dataToForm() {

    this.crudForm.patchValue({
      name: this.delivery.name,
      description: this.delivery.description,
      selectedProductId: this.delivery.product?.id,
      customerId: (this.mode == 'add') ? this.store.selectSnapshot(GuiState.selectedCustomer).id : this.delivery.customer?.id,
      amount: this.delivery.amount,
      price: this.delivery.price,
      billingInterval: this.delivery.billingInterval,

      delivery: this.formatDate(this.delivery.delivery),
      trackingNumber: this.delivery.trackingNumber,

      contractStart: this.formatDate(this.delivery.contractStart),
      contractEnd: this.formatDate(this.delivery.contractEnd),

      contractPeriodBase: this.delivery.contractPeriodBase,
      contractPeriodExtension: this.delivery.contractPeriodExtension,
      noticePeriod: this.delivery.noticePeriod,
      dmat: this.delivery.dmat,
      maueOrderNumber: this.delivery.maueOrderNumber,
      maueInventoryNumber: this.delivery.maueInventoryNumber,
      maueDeviceModel: this.delivery.maueDeviceModel,
      billingMethod: this.delivery.billingMethod,
      billingState: (this.mode === 'add') ? BillingState.OPEN : this.delivery.billingState,
    });

    if (this.delivery.billingMethod == BillingMethod.MAuE) {
      this.f.price.disable();
      this.f.contractPeriodBase.disable();
      this.f.contractPeriodExtension.disable();
      this.f.noticePeriod.disable();
      this.f.billingInterval.disable();
    } else if (this.delivery.billingMethod == BillingMethod.NOYSEE) {
      this.f.price.disable();
      this.f.contractPeriodBase.disable();
      this.f.contractPeriodExtension.disable();
      this.f.noticePeriod.disable();
      this.f.billingInterval.disable();
      this.f.contractPeriodBase.enable();
      this.f.contractPeriodExtension.enable();
      this.f.noticePeriod.enable();
      this.f.billingInterval.enable();
    } else {
      this.f.price.enable();
      this.f.contractPeriodBase.enable();
      this.f.contractPeriodExtension.enable();
      this.f.noticePeriod.enable();
      this.f.billingInterval.enable();
    }

    if (this.mode == 'add') {
      this.f.customerId.enable();
    } else {
      this.f.customerId.disable();
      this.crudForm.disable();
    }
  }

  loadDelivery() {
    const url = this.route.snapshot.params;

    this.billingService.getDelivery(url.id).then((data) => {
      this.delivery = data;

      this.dataToForm();
    });
  }

  get f() {
    return this.crudForm.controls;
  }

  async saveDelivery() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.delivery.name = this.f.name.value;
    this.delivery.description = this.f.description.value;

    this.delivery.amount = this.f.amount.value;
    this.delivery.price = this.f.price.value;
    this.delivery.billingInterval = this.f.billingInterval.value;

    this.delivery.contractPeriodBase = this.f.contractPeriodBase.value;
    this.delivery.contractPeriodExtension =
      this.f.contractPeriodExtension.value;
    this.delivery.noticePeriod = this.f.noticePeriod.value;

    this.delivery.delivery = this.parseDate(this.f.delivery.value);
    this.delivery.trackingNumber = this.f.trackingNumber.value;

    this.delivery.contractStart = this.parseDate(this.f.contractStart.value);
    this.delivery.contractEnd = this.parseDate(this.f.contractEnd.value);

    this.delivery.billingMethod = this.f.billingMethod.value;
    this.delivery.billingState = this.f.billingState.value;

    this.delivery.customer = this.delivery.customer = this.customerList.find(
      (c) => c.id === this.store.selectSnapshot(GuiState.selectedCustomer).id,
    );

    try {
      this.billingService
        .addDelivery(this.delivery)
        .then((result) => {
          this.delivery = result;
          console.debug(this.delivery);
          this.dataToForm();
          this.toastHelper.openSnackBar('Lieferung gespeichert!', 'SUCCESS');
          this.goBack();
        })
        .catch((error) => {
          this.toastHelper.openSnackBar(
            'Fehler beim Speichern der Lieferung!',
            'ERROR',
          );
        });
    } catch (e) {
      this.toastHelper.openSnackBar(
        'Fehler beim Speichern der Lieferung!',
        'ERROR',
      );
    }
  }

  goBack() {
    this.router.navigate([
      '/dashboard',
      'admins',
      'billing',
      'deliveries',
      'overview',
    ]);
  }

  exportFileTypes(): { label: string; value: string }[] {
    return [];
  }

  private formatDate(date: string | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  private parseDate(date: string): Date {
    return date ? new Date(date) : null;
  }

  selectBillingMethod($event) {
    this.delivery.billingMethod = this.f.billingMethod.value;
    this.dataToForm();
  }

  selectProduct($event) {
    const selectedProduct = this.productList.find(
      (p) => p.id === this.f.selectedProductId.value,
    );
    if (this.mode == 'add') {
      this.delivery.billingState = BillingState.OPEN;
    }

    if (selectedProduct) {
      this.delivery.product = Object.assign({}, selectedProduct);
      this.delivery.customer = this.customerList.find(
        (c) => c.id == this.store.selectSnapshot(GuiState.selectedCustomer).id,
      );

      this.delivery.contractPeriodBase = selectedProduct.contractPeriodBase;
      this.delivery.contractPeriodExtension = selectedProduct.contractPeriodExtension;
      this.delivery.noticePeriod = selectedProduct.noticePeriod;
      this.delivery.billingInterval = selectedProduct.billingInterval;
      this.delivery.price = selectedProduct.price;
      this.delivery.name = selectedProduct.name;
      this.delivery.description = selectedProduct.description;
      this.delivery.billingMethod = selectedProduct.billingMethod;

      this.delivery.dmat = selectedProduct.dmat;
      this.delivery.maueDeviceModel = selectedProduct.maueDeviceModel;

      this.delivery.amount = 1;
      this.delivery.delivery = new Date();
      this.delivery.billingInterval = selectedProduct.billingInterval;
      this.dataToForm();
    }
  }


  deleteDelivery() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie die Lieferung wirklich löschen?<br>' +
      this.delivery.id +
      ' ' +
      this.delivery.name,
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.billingService.deleteDelivery(this.delivery).then((result) => {
            this.toastHelper.openSnackBar(
              'Position ' + this.delivery.name + ' gelöscht.',
              'SUCCESS',
            );
            this.goBack();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  maueStart() {
    // const dialogRef = this.approvalDialogHelper.openApprovalDialog(
    //   'Möchten Sie die Abrechnung wirklich starten?<br>' +
    //   this.delivery.id +
    //   ' ' +
    //   this.delivery.name,
    // );
    // dialogRef.afterClosed().subscribe(async (result) => {
    //   if (result) {
    //     try {
    //       this.billingService.startBilling(this.delivery).then((result) => {
    //         this.toastHelper.openSnackBar(
    //           'Position ' + this.delivery.name + ' wird abgerechnet.',
    //           'SUCCESS',
    //         );
    //         this.delivery= result;
    //         this.dataToForm();
    //       });
    //     } catch (e) {
    //       this.toastHelper.openCrudDeleteError();
    //     }
    //   }
    // });
    this.billingService.workflowMaueStart(this.delivery).then((result) => {
      this.delivery= result;
      this.dataToForm();
    });

  }

  maueCancel() {

  }

}

export const ContractStartValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const billingInterval = control.get('billingInterval');
  const contractStart = control.get('contractStart');
  const invalid = billingInterval && billingInterval.value != null && billingInterval.value != 0 && (contractStart.value == null || contractStart.value == '' || contractStart.value == 'Invalid date');
  return invalid ? { contractStartMissing: true } : null;
}

