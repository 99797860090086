<div class="mat-dialog-title">
  <p><strong appTranslate="sensor.editRenderedInfo.headline"></strong></p>
</div>
<div class="form-field">
  <label for="content" appTranslate="sensor.editRenderedInfo.label"></label>
  <textarea cols="60" rows="10" [(ngModel)]="content"></textarea>
</div>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="saveRenderedInfo()"
    appTranslate="sensor.editRenderedInfo.actions.save"></button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()"
    appTranslate="sensor.editRenderedInfo.actions.cancel"></button>
</mat-dialog-actions>