<app-table-header
  [title]="tableConfiguration.title"
  [compact]="tableConfiguration.compact"
  [dataLength]="rowData?.length"
  [tableActions]="tableConfiguration.tableActions"
></app-table-header>
<ag-grid-angular
  class="ag-theme-noysee"
  [class.compact]="tableConfiguration.compact"
  style="width: 100%"
  domLayout="autoHeight"
  (gridReady)="onGridReady($event)"
  (cellEditRequest)="cellEditRequest.emit($event)"
  [readOnlyEdit]="true"
  [columnDefs]="tableConfiguration.columns"
  [gridOptions]="defaultGridOptions"
  [rowData]="rowData"
  [noRowsOverlayComponent]="noRowsOverlayComponent"
  [loadingOverlayComponent]="loadingOverlayComponent"
  (cellEditingStopped)="cellEditingStopped.emit($event)"
></ag-grid-angular>
<app-table-footer
  *ngIf="apis$ | async as apis"
  [gridApi]="apis[0]"
  [columnApi]="apis[1]"
  [gridOptions]="tableConfiguration.gridOptions"
  [csvExportParams]="tableConfiguration.csvExportParams"
  [pdfExportParams]="tableConfiguration.pdfExportParams"
></app-table-footer>
