<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode === 'add'" appTranslate="apiKey.crud.headlines.add"></h1>
      <h1 *ngIf="mode === 'edit'" appTranslate="apiKey.crud.headlines.edit"></h1>
    </div>
  </div>
  <form *ngIf="crudApiKeyForm" class="form" [formGroup]="crudApiKeyForm" (ngSubmit)="saveUser()">
    <div *ngIf="mode === 'edit'" class="row ch-margin-top-24">
      <div class="col-12 qrcode-wrapper">
        <span class="qrcode-viewer ch-margin-right-16">
          <img [src]="qrcodeImage" class="qrcode-image" alt="QR-Code"/>
        </span>
      </div>
    </div>

    <div *ngIf="mode === 'add' && isSuperAdmin" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-customer" appTranslate="forms.user.customer.label"></label>
          <input placeholder="" id="input-customer" disabled value="{{user.customer.name}}">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.username.invalid">
          <label for="input-username" appTranslate="forms.user.username.label" class="important"></label>
          <input placeholder="" id="input-username" formControlName="username">
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'edit' || mode === 'add'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name" appTranslate="forms.user.name.label" class="important"></label>
          <input placeholder="" id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid && f.name.errors.required" class="error-message ch-margin-top-4"
               appTranslate="forms.user.name.error"></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.apiKey.invalid">
          <label for="input-apiKey" appTranslate="forms.user.apiKey.label" class="important"></label>
          <input placeholder="" id="input-apiKey" formControlName="apiKey">
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12 role">
        <div class="form-field" [class.error]="submitted && f.role.invalid">
          <label for="select-role" appTranslate="forms.user.role.label" class="imporant"></label>
          <select
            [attr.disabled]="((!isSuperAdmin && !isAdmin) || (isAdmin && isEditUserSuperadmin())) ? 'disabled' : null"
            id="select-role" formControlName="role">
			<option selected disabled hidden>Auswählen...</option>
			<option *ngFor="let r of roles" [value]="r.value" [disabled]="r.disabled" [hidden]="r.disabled">{{r.label|translate}}</option>
          </select>
          <div *ngIf="submitted && f.role.invalid && f.role.errors.required" class="error-message ch-margin-top-4"
               appTranslate="forms.user.role.error"></div>
        </div>
        <mat-icon [inline]="true"
                  [matTooltip]="('forms.user.role.tooltip' | translate)">info
        </mat-icon>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudApiKeyForm.disabled" appTranslate="user.crud.actions.save"></button>
        <button class="btn-secondary" (click)="goBack()" *ngIf="crudApiKeyForm.disabled"
                appTranslate="user.crud.actions.back"></button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudApiKeyForm.disabled"
                appTranslate="user.crud.actions.cancel"></button>
      </div>
    </div>
  </form>
</div>
