<div class="date-picker">
    <input matInput
           [style.width]="(inputWidth ? inputWidth + 'px': 'auto')"
           [formControl]='formControl'
           [matDatepicker]='$any(datepicker)'
           [max]="max ?? undefined"
           autocomplete='off'>
  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-datepicker #datepicker></mat-datepicker>
</div>
