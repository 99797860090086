import {Injectable} from '@angular/core';
import { GridState } from '../data-table/data-table.model';

@Injectable()
export class DataTableStateService {
  serializeState(state: GridState): string {
    return encodeURIComponent(JSON.stringify(state));
  }

  deserializeState(ctx: string): GridState {
    return JSON.parse(decodeURIComponent(ctx));
  }
}
