<div class="primary-panel-large container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode == 'add'">Firmware anlegen</h1>
      <h1 *ngIf="mode == 'edit'">Firmware bearbeiten</h1>
    </div>
  </div>
  <form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveExportFile()">

    <div class="row ch-margin-top-24">
      <div class="col-1 role">
        <strong>Aktiv</strong>
      </div>
      <div class="col-4 role">
        <div class="form-field" [class.error]="submitted && f.active.invalid">
          <label class="switch">
            <input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-8">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">Name *</label>
          <input placeholder="" id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">Bitte einen Namen für die
            Firmware wählen!
          </div>
          <div *ngIf="f.name.errors?.maxlength" class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize40"></div>

        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-8">
        <div class="form-field" [class.error]="submitted && f.code.invalid">
          <label for="input-code">Firmware</label>
          <input type="file" placeholder="" id="input-code" formControlName="code" accept="image/*"
            (change)="codeChanged($event)" #codeUpload>
          <div *ngIf="firmwareError" class="error-message ch-margin-top-4">
            {{firmwareError}}
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-firmwareVersion">Version *</label>
          <input placeholder="" id="input-firmwareVersion" formControlName="firmwareVersion">
          <div *ngIf="submitted && f.firmwareVersion.invalid" class="error-message ch-margin-top-4">Bitte eine Versionsnummer für die
            Firmware wählen!
          </div>
          <div *ngIf="f.firmwareVersion.errors?.pattern" class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.int"></div>
        </div>

      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.sensorBoxTemplateId.invalid">
          <label for="select-sensorBoxTemplateId">SensorBoxTemplate *</label>
          <select [attr.disabled]="null" id="select-sensorBoxTemplateId" formControlName="sensorBoxTemplateId">
            <option [ngValue]="null" selected="true">-</option>
            <option [ngValue]="entry.id" *ngFor="let entry of templateList">
              {{ entry.name }} (ID: {{ entry.id }})
            </option>
          </select>
          <div *ngIf="submitted && f.sensorBoxTemplateId.invalid" class="error-message ch-margin-top-4">SensorBoxTemplate wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.description.invalid">
          <label for="input-description"><span>Beschreibung</span></label>
          <textarea placeholder="" id="input-description" formControlName="description" rows="5"></textarea>
        </div>
      </div>
    </div>


    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudForm.disabled">Speichern</button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled">Abbrechen</button>
      </div>
      <div class="col-3">
      </div>
    </div>
  </form>

</div>