import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';
import { SensorState } from '../../../../../state/noysee/sensor.state';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
  selectedSensor: string = this.route.snapshot.queryParams.sensor;
  sensorBox$: Observable<SensorBox> = this.store.select(
    SensorState.currentSensor,
  );

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {}
}
