import {Injectable} from '@angular/core';

export enum ErrorCodes {
  DUPLICATE_LOGIN_NAME = 'DUPLICATE_LOGIN_NAME',
  EMAIL_MISSING = 'EMAIL_MISSING',
  CUSTOMER_UNKNOWN = 'CUSTOMER_UNKNOWN',
  USER_DOES_NOT_EXIST_OR_LOCKED = 'USER_DOES_NOT_EXIST_OR_LOCKED',
  PASSWORD_WRONG = 'PASSWORD_WRONG',
  PASSWORD_TO_SHORT = 'PASSWORD_TO_SHORT',
  PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH',
  PASSWORD_MISSING = 'PASSWORD_MISSING',
}

@Injectable({providedIn: 'root'})
export class ErrorHandler {
  constructor() {}

  static getText(error: ErrorCodes) {
    switch (error) {
      case ErrorCodes.DUPLICATE_LOGIN_NAME:
        return 'Benutzername/E-Mail existiert bereits.';
      case ErrorCodes.EMAIL_MISSING:
        return 'Fehlende E-Mail Adresse.';
      case ErrorCodes.CUSTOMER_UNKNOWN:
        return 'Kunde unbekannt.';
      case ErrorCodes.USER_DOES_NOT_EXIST_OR_LOCKED:
        return 'Benutzer existiert nicht oder ist gesperrt.';
      case ErrorCodes.PASSWORD_WRONG:
        return 'Altes Passwort falsch.';
      case ErrorCodes.PASSWORD_TO_SHORT:
        return 'Passwort zu kurz.';
      case ErrorCodes.PASSWORDS_DONT_MATCH:
        return 'Passwörter stimmen nicht überein.';
      case ErrorCodes.PASSWORD_MISSING:
        return 'Passwort fehlt.';
      default:
        return 'Ein Fehler ist aufgetreten.';
    }
  }
}
