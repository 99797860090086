import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../../services/users.service';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { User } from '../../../../models/user';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../../services/authentication.service';
import { environment } from '../../../../../environments/environment';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { getRoles, Role } from '../../../../models/role.enum';
import { GridState } from '../../../../shared/data-table/data-table.model';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { Subscription } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import { ErrorCodes, ErrorHandler } from '../../../../models/errorHandler';
import { ImageService } from '../../../../services/image.service';
import { TranslatePipe } from '@ngx-translate/core';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { Store } from '@ngxs/store';
import { Customer } from 'src/app/models/customerList';

@Component({
  selector: 'app-crud-apiKey',
  templateUrl: './crud-apiKey.component.html',
  styleUrls: ['./crud-apiKey.component.scss'],
})
export class CrudApiKeyComponent implements OnInit, OnDestroy {
  Role = Role;
  mode: string;
  user: User;
  crudApiKeyForm: FormGroup;
  submitted: boolean;
  range: FormGroup;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isGuest: boolean;
  ctx: GridState = null;
  qrcodeImage: SafeUrl | string;
  userSubscription: Subscription;
  uploadConfigSubscription: Subscription;
  roles: { label: string; value: string; disabled: boolean }[] = [];

  phoneRequired = false;

  emailDuplicated = false;

  selectedCustomer: Customer;

  get problemAdministration() {
    return this.crudApiKeyForm.get('claimProblemAdministration').value;
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private toastHelper: ToastHelper,
    private router: Router,
    private dataTableStateService: DataTableStateService,
    private store: Store,
    private imageService: ImageService,
  ) {
    this.user = new User();
    this.crudApiKeyForm = this.formBuilder.group({
      username: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      apiKey: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });
    this.mode = '';
    this.submitted = false;
    this.qrcodeImage = environment.avatarPlaceholderUrl;
    this.isSuperAdmin = false;
    this.isAdmin = false;
    this.isGuest = false;
    this.roles = getRoles(this.authenticationService);
  }

  ngOnInit(): void {
    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    this.store
      .select(GuiState.selectedCustomer)
      .pipe(first((customer) => !!customer))
      .subscribe((customer) => {
        this.selectedCustomer = customer;
      });

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isGuest = this.authenticationService.isGuest();

    this.f.username.disable();
    this.f.apiKey.disable();

    const url = this.route.parent.snapshot.url;
    this.applicationContextService.selectedUserId = +url[url.length - 1].path;
    this.removeControls(this.mode);

    if (this.mode === 'edit') {
      // get correct values here
      this.userService
        .getById(this.applicationContextService.selectedUserId)
        .pipe(first((user) => !!user))
        .subscribe((user) => {
          this.user = user;
          this.crudApiKeyForm.patchValue({
            username: this.user.login,
            name: this.user.name,
            apiKey: this.user.key,
            role: this.user.role,
          });

          if (
            (this.user.role === Role.ADMIN ||
              this.user.role === Role.SUPERADMIN) &&
            this.authenticationService.isAdmin() &&
            this.authenticationService.authenticatedUser.id !== this.user.id
          ) {
            this.crudApiKeyForm.disable();
          }
        });
      this.imageService
        .getQRCodeImage(this.applicationContextService.selectedUserId)
        .then((imageUrl) => {
          this.qrcodeImage = imageUrl;
        });
    } else if (this.mode === 'add') {
      this.user.customer = this.selectedCustomer;
    }
  }

  get f() {
    return this.crudApiKeyForm.controls;
  }

  ngOnDestroy() {
    this.applicationContextService.selectedUserId = 0;
    this.userSubscription?.unsubscribe();
    this.uploadConfigSubscription?.unsubscribe();
  }

  async saveUser() {
    this.submitted = true;

    if (this.crudApiKeyForm.invalid) {
      return;
    }

    this.user.name = this.f.name.value;
    this.user.role = this.f.role.value;

    try {
      if (this.mode === 'add') {
        if (this.authenticationService.isSuperAdmin) {
          this.user.customer = this.store.selectSnapshot(
            GuiState.selectedCustomer,
          );
        } else {
          this.user.customer = this.authenticationService.customer();
        }

        await this.userService.createApiKey(
          this.user.customer.id,
          this.f.name.value,
          this.f.role.value,
        );
        this.toastHelper.openSnackBar('apiKey.crud.success.add', 'SUCCESS');
      } else if (this.mode === 'edit') {
        await this.userService.saveApiKey(this.user);
        this.toastHelper.openSnackBar('apiKey.crud.success.edit', 'SUCCESS');
      }
      let navRoute = ['/users', 'overview'];
      if (this.ctx?.url) {
        navRoute = [this.ctx?.url];
      }
      await this.router.navigate(navRoute, { state: this.ctx });
    } catch (e) {
      if (this.mode === 'add') {
        if (e.message === ErrorCodes.DUPLICATE_LOGIN_NAME) {
          this.f.username.setErrors({ duplicatedUsername: true });
          this.emailDuplicated = true;
        }
        this.toastHelper.openSnackBar(ErrorHandler.getText(e.message), 'ERROR');
      } else {
        this.toastHelper.openCrudChangeError();
      }
    }
  }

  isEditUserSuperadmin() {
    return this.user.role === Role.SUPERADMIN;
  }

  goBack() {
    let navRoute = ['/users', 'overview'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  private removeControls(mode: string): void {
    switch (mode) {
      case 'invite':
        this.crudApiKeyForm.removeControl('username');
        this.crudApiKeyForm.removeControl('name');
        this.crudApiKeyForm.removeControl('phone');
        this.crudApiKeyForm.removeControl('password');
        break;
      case 'edit':
        this.crudApiKeyForm.removeControl('password');
        break;
      case 'edit-self':
        this.crudApiKeyForm.removeControl('password');
        break;
    }
  }
}
