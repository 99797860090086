import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { icon, LatLng, latLng, Marker, marker, tileLayer } from 'leaflet';
import { environment } from '../../../../../../environments/environment';
import { Sensor } from '../../../../../state/noysee/models/sensor';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';

const markerSensorClasses = {
  '-4': 'marker marker-sensor marker-planned marker-grey selected',
  '-3': 'marker marker-sensor marker-disabled marker-orange selected',
  '-2': 'marker marker-sensor marker-defective marker-orange selected',
  '-1': 'marker marker-sensor marker-error marker-orange selected',
  '0': 'marker marker-sensor marker-normal marker-green selected',
  '1': 'marker marker-sensor marker-limit1 marker-orange selected',
  '2': 'marker marker-sensor marker-limit2 marker-red selected',
  '3': 'marker marker-sensor marker-limit3 marker-red selected',
  '4': 'marker marker-sensor marker-limit4 marker-red selected',
  '5': 'marker marker-sensor marker-limit5 marker-red selected',
};

@Component({
  selector: 'app-sensors-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private _currentSensor: SensorBox;

  get currentSensor(): SensorBox {
    return this._currentSensor;
  }

  @Input()
  set currentSensor(sensor: SensorBox) {
    // Update current sensor
    this._currentSensor = sensor;

    // Update map
    this.sensorLayer.length = 0;
    const markerForSensor = marker([sensor.lat, sensor.lon], {
      icon: icon({
        iconSize: this.markerSize_sensor,
        iconUrl: 'assets/icons/filter/' + sensor.frontendType + '.svg',
        className: markerSensorClasses[sensor.state],
      }),
    });

    this.sensorLayer.push(markerForSensor);
    this.mapLayers.length = 0;
    this.sensorLayer.forEach((currentMarker) =>
      this.mapLayers.push(currentMarker),
    );
    this.map$
      .pipe(first((map) => !!map))
      .subscribe((map) => map.setView(new LatLng(sensor.lat, sensor.lon)));
  }

  map$ = new ReplaySubject<L.Map>(1);
  mapOptions: any;
  mapLayers: Marker[];
  sensorLayer: Marker[];
  markerSize_sensor: [number, number];

  constructor() {
    this.mapLayers = [];
    this.sensorLayer = [];
    this.markerSize_sensor = [
      environment.licensedFeatures.mapMarkerDiameter,
      environment.licensedFeatures.mapMarkerDiameter,
    ];
    this.mapOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'),
      ],
      zoom: 15,
      zoomControl: false,
      scrollWheelZoom: false,
      center: latLng(49.006889, 8.403653),
      dragging: true,
    };
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  // when map is ready - position zoomControl to topright
  mapReady(map: L.Map) {
    this.map$.next(map);
  }
}
