import {Component, Input} from '@angular/core';
import {TableAction} from '../data-table.model';

@Component({
  selector: 'app-row-action-menu',
  templateUrl: './row-action-menu.component.html',
  styleUrls: ['./row-action-menu.component.scss']
})
export class RowActionMenuComponent {

  @Input()
  actionList: TableAction[];

  @Input()
  data: any;
}
