import { Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { LookupService } from '../../../../../services/lookup.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageTemplateService } from 'src/app/services/messageTemplate.service';
import { MessageTemplate, MessageTemplateClass, getTemplateClasses } from 'src/app/models/messageTemplate';

@Component({
  selector: 'app-messageTemplate-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class MessageTemplateDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  messageTemplate: MessageTemplate;
  submitted: boolean;

  crudForm: FormGroup;

  templateList: LookupEntry[];

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private matDialog: MatDialog,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private messageTemplateService: MessageTemplateService,
    private lookupService: LookupService) {

    this.messageTemplate = new MessageTemplate();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      description: new FormControl('', []),
      subject: new FormControl('', []),
      body: new FormControl('', []),
      instruction: new FormControl('', []),
      templateClass: new FormControl('', []),
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';
  }

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadMessageTemplate();
    } else {
      this.messageTemplate.active = true;
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadMessageTemplate();
      });

    this.lookupService.getMessageTemplateList().then(result => {
      this.templateList = result;
    });

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {

    this.crudForm.patchValue({
      name: this.messageTemplate.name,
      description: this.messageTemplate.description,
      active: this.messageTemplate.active,
      subject: this.messageTemplate.subject,
      body: this.messageTemplate.body,
      instruction: this.messageTemplate.instruction,
      templateClass: this.messageTemplate.templateClass,
    });
    // this.f.noyseeSN.disable();
  }

  loadMessageTemplate() {
    const url = this.route.snapshot.params;

    this.messageTemplateService.load(url.id).then(data => {
      this.messageTemplate = data;
      this.dataToForm();
    });

  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    const _this = this;
    _this.messageTemplate.active = !_this.messageTemplate.active;
    _this.crudForm.patchValue({
      active: _this.messageTemplate.active,
    });
  }

  async saveMessageTemplate() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.messageTemplate.name = this.f.name.value;
    this.messageTemplate.description = this.f.description.value;
    this.messageTemplate.active= this.f.active.value;
    this.messageTemplate.subject= this.f.subject.value;
    this.messageTemplate.body= this.f.body.value;
    this.messageTemplate.instruction= this.f.instruction.value;
    this.messageTemplate.templateClass= this.f.templateClass.value;

    try {
      this.messageTemplateService.save(this.messageTemplate).then(result => {
        this.messageTemplate = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('Vorlage gespeichert!', 'SUCCESS');
        this.goBack();
      }).catch(error => {
        this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
      });

    } catch (e) {
        this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
    }
  }

  deleteMessageTemplate() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie diese Vorlage wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.messageTemplateService.delete(this.messageTemplate.id).then(result => {
            this.goBack();
            this.toastHelper.openSnackBar('Alarmregel gelöscht!', 'SUCCESS');
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'messageTemplates', 'overview']);
  }

  templateClasses(): { label: string, value: string }[] {
    return getTemplateClasses();
  }

}
