import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  DateValueChartDataPoint,
  LegendDataItem,
} from '../../../../../../shared/chart/date-value-chart/date-value-chart.component';
import { ChartHelper, Trend } from '../../../../../../helpers/chart.helper';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { SensorState } from '../../../../../../state/noysee/sensor.state';
import { SensorBox } from '../../../../../../state/noysee/models/sensorBox';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getLegendData,
  getSensorBoxDataForDateRange,
} from '../../../../../../shared/chart/util';
import { SensorService } from '../../../../../../state/noysee/sensor.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sensor-chart',
  templateUrl: './sensor-chart.component.html',
  styleUrls: ['./sensor-chart.component.scss'],
})
export class SensorChartComponent implements AfterViewInit, OnDestroy {
  @Input()
  displaySensorSelection?: boolean = false;
  @Input()
  selectedSensorInput?: string;

  trend: Trend = 'STAGNANT';
  delta$: BehaviorSubject<number> = new BehaviorSubject(0);
  sensorBox$: Observable<SensorBox> = this.store
    .select(SensorState.currentSensor)
    .pipe(filter((sensorBox) => !!sensorBox));
  selectedSensor$: BehaviorSubject<string> = new BehaviorSubject('');
  chartData$: Observable<DateValueChartDataPoint[]>;
  destroy$: Subject<void> = new Subject();
  getLegendData = getLegendData;

  constructor(
    private store: Store,
    private sensorService: SensorService,
    private chartHelper: ChartHelper,
    private cdr: ChangeDetectorRef,
    public translateService: TranslateService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    // Set the selected sensor to the primary sensor of the sensor box if sensor selection input is empty
    // The await is required, otherwise the selectedSensor$ is not initialized when the chartData$ is created
    this.sensorBox$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.selectedSensor$),
        tap(([sensorBox, selectedSensor]) => {
          if (!selectedSensor) {
            this.selectedSensor$.next(
              this.selectedSensorInput || sensorBox.primarySensor,
            );
            // this is required, otherwise a change detection error is thrown
            this.cdr.detectChanges();
          }
        }),
      )
      .subscribe();
    // This has to be in afterViewInit because the selectedSensor input is not available in the constructor
    this.chartData$ = combineLatest([
      this.sensorBox$,
      this.selectedSensor$,
      this.delta$,
    ]).pipe(
      switchMap(([sensorBox, selectedSensor, delta]) =>
        getSensorBoxDataForDateRange(
          this.sensorService,
          sensorBox.id,
          this.getBeginDateFromDelta(delta),
          new Date(),
          selectedSensor,
          // The idea here, is that if no selected sensor was passed, and the currently selected sensor is the sensorBox's primary sensor,
          // the secondary sensor should be used for the chart data (if it exists)
          // Otherwise, the secondary sensor should be ignored and only the currently selected sensor should be used
          this.selectedSensorInput
            ? undefined
            : selectedSensor === sensorBox.primarySensor
              ? sensorBox.secondarySensor
              : undefined,
        ),
      ),
      map(({ recordedData, futureData }) => recordedData.concat(futureData)),
      tap((data) => (this.trend = this.getTrend(data))),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectionChange(sensor: string): void {
    this.selectedSensor$.next(sensor);
  }

  onDeltaChange(delta: number): void {
    this.delta$.next(delta);
  }

  onExport(
    sensorBox: SensorBox,
    selectedSensor: string,
    data: DateValueChartDataPoint[],
  ): void {
    this.chartHelper.exportToCsv(sensorBox, selectedSensor, data);
  }

  // Delta is either 0 or below 0
  private getBeginDateFromDelta(delta: number): Date {
    // If 0 is passed, return the last 24 hours
    if (delta === 0) {
      return moment().subtract(24, 'hours').toDate();
    }
    // If a negative number is passed, return the last x days
    return moment().add(delta, 'days').toDate();
  }

  private getTrend(data: DateValueChartDataPoint[]): Trend {
    const sums = {
      x: 0,
      y: 0,
      xy: 0,
      xx: 0,
      yy: 0,
    };

    let noValueCount = 0;
    data
      .filter((item) => item.value !== undefined)
      .forEach((item, index) => {
        const value =
          typeof item.value === 'number' ? item.value : parseFloat(item.value);
        sums.x += index + 1;
        sums.y += value;
        sums.xy += value * (index + 1);
        sums.xx += (index + 1) * (index + 1);
        sums.yy += value * value;
      });

    const length = data.length - noValueCount;
    const slope =
      (length * sums.xy - sums.x * sums.y) /
      (length * sums.xx - sums.x * sums.x);
    // This value is unused
    // const intercept = (sums.y - slope * sums.x) / length;

    // First calculate linear regression through the values of the chart data
    if (slope > 0.2) {
      return 'RISING';
    } else if (slope < -0.2) {
      return 'FALLING';
    }
    return 'STAGNANT';
  }
}
