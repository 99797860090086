import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorsRoutingModule } from './sensors-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { MatButtonModule } from '@angular/material/button';
import { CrudSensorComponent } from './crud-sensor/crud-sensor.component';
import { AdminBoxComponent } from './admin/admin-box.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { InformationComponent } from './detail/information/information.component';
import { ContactsComponent } from './detail/contacts/contacts.component';
import { SensorBoxAlarmGroupsComponent } from './detail/alarmGroups/alarmGroups.component';
import { ImageComponent } from './detail/image/image.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DetailComponent } from './detail/detail/detail.component';
import { MapComponent } from './detail/map/map.component';
import { TestAlarmModalComponent } from './detail/test-alarm-modal/test-alarm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TestMessageModalComponent } from './detail/test-message-modal/test-message-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { AddSensorBoxAlarmGroupComponent } from './detail/addAlarmGroup-modal/addAlarmGroup.component';
import { SharedModule } from '../../../shared/shared.module';
import { BoxCommandQueueComponent } from './box-command-queue/box-command-queue.component';
import { WeatherComponent } from './detail/weather/weather.component';
import { LegacyWeatherComponent } from './detail/legacy-weather/legacy-weather.component';
import { SymbolComponent } from './detail/weather/symbol/symbol.component';
import { DescriptionComponent } from './detail/description/description.component';
import { AdminShortcutsComponent } from './detail/adminShortcuts/adminShortcuts.component';
import { EditRenderedInfoModalComponent } from './detail/edit-renderedInfo-modal/editRenderedInfo-modal.component';
import { SensorBoxAlarmActionsComponent } from './detail/alarmActions/alarmActions.component';
import { ValuesComponent } from './detail/values/values.component';
import { SensorChartComponent } from './detail/values/sensor-chart/sensor-chart.component';
import { SensorChartHeaderComponent } from './detail/values/sensor-chart/sensor-chart-header/sensor-chart-header.component';
import { WeatherStationComponent } from './detail/values/weather-station/weather-station.component';
import { WeatherStationWindComponent } from './detail/values/weather-station/weather-station-wind/weather-station-wind.component';
import { WeatherStationSensorComponent } from './detail/values/weather-station/weather-station-sensor/weather-station-sensor.component';
import { SensorChartControlsComponent } from './detail/values/sensor-chart/sensor-chart-controls/sensor-chart-controls.component';
import { HistoryComponent } from './detail/history/history.component';
import { AddSensorBoxAlarmActionComponent } from './detail/addAlarmAction-modal/addAlarmAction.component';
import { AddAlarmContactsComponent } from './detail/addAlarmContacts-modal/addAlarmContacts.component';

@NgModule({
  declarations: [
    OverviewComponent,
    CrudSensorComponent,
    AdminBoxComponent,
    BoxCommandQueueComponent,
    InformationComponent,
    ImageComponent,
    ContactsComponent,
    AddAlarmContactsComponent,
    DetailComponent,
    MapComponent,
    TestAlarmModalComponent,
    TestMessageModalComponent,
    SensorBoxAlarmGroupsComponent,
    AddSensorBoxAlarmGroupComponent,
    AddSensorBoxAlarmActionComponent,
    WeatherComponent,
    LegacyWeatherComponent,
    SymbolComponent,
    DescriptionComponent,
    AdminShortcutsComponent,
    EditRenderedInfoModalComponent,
    SensorBoxAlarmActionsComponent,
    ValuesComponent,
    SensorChartComponent,
    SensorChartHeaderComponent,
    WeatherStationComponent,
    WeatherStationWindComponent,
    WeatherStationSensorComponent,
    SensorChartControlsComponent,
    HistoryComponent,
  ],
  exports: [
    ImageComponent,
    ValuesComponent,
    MapComponent,
    SensorChartComponent,
  ],
  imports: [
    CommonModule,
    SensorsRoutingModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatInputModule,
    LeafletModule,
    MatDialogModule,
    TranslateModule,
    MatSelectModule,
    SharedModule,
  ],
})
export class SensorsModule {}
