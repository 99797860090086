import {Injectable} from '@angular/core';
import {ExportToCsv} from 'export-to-csv';
import {of} from 'rxjs';
import {PdfService} from '../pdf/pdf.service';
import {DateHelper} from '../../helpers/date.helper';
import {PdfOptions} from '../pdf/pdf.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DownloadService {
  private _csvExporter: ExportToCsv;

  constructor(private pdfService: PdfService, private translateService: TranslateService) {
  }

  initializeCsvExporter() {
    const lang = this.translateService.currentLang ?? this.translateService.defaultLang;
    let decimalSeparator = '.';

    if (lang === 'de') {
      decimalSeparator = ',';
    }

    this._csvExporter = new ExportToCsv(
      {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator,
        showLabels: true,
        showTitle: true,
        title: 'Platzhalter',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
      }
    );
  }

  exportToCsv(title: string, data: any[], header?: string[]) {
    if (!this._csvExporter) {
      this.initializeCsvExporter();
    }

    // Additional rules for content formatting
    data.forEach(item => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          // Objects cannot be displayed, therefore:
          // Try to parse them to string directly or convert them into array
          if (typeof item[key] === 'object') {
            item[key] = item[key].text ?? Object.values(item[key]);
          }
          // Arrays cannot be displayed, therefore join elements to string
          if (Array.isArray(item[key])) {
            item[key] = item[key].join('');
          }
        }
      }
    });

    const fileTimestamp = DateHelper.generateDateString(new Date());

    this._csvExporter.options.title = title;
    this._csvExporter.options.filename = `${title.replace(/ /g, '_')}_${fileTimestamp}`;

    if (header) {
      this._csvExporter.options.useKeysAsHeaders = false;
      this._csvExporter.options.headers = header;
    } else {
      this._csvExporter.options.useKeysAsHeaders = true;
    }

    this._csvExporter.generateCsv(data);
  }

  exportToPdf(pdfConfig: PdfOptions) {

    const {data, title} = pdfConfig;
    let {header} = pdfConfig;

    const fileTimestamp = DateHelper.generateDateString(new Date());
    const filename = `${title}_${fileTimestamp}.pdf`;

    if (data.length > 0) {
      if (!Array.isArray(data[0])) {
        if (!header && data.length > 0) {
          header = Object.keys(data[0]);
        }
      }

      const rows = data.map((row) => {
        if (Array.isArray(row)) {
          return row;
        }

        return Object.values(row);
      });


      if (Array.isArray(data[0]) && !header) {
        throw new Error('NO Headers for PDF Provided.');
      }

      return this.pdfService.exportTable({
        ...pdfConfig,
        header,
        data: rows,
        filename
      });
    }

    return of('NO DATA Provided');
  }
}
