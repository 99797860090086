import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Subscription } from 'rxjs';
import {
  AlarmGroup,
  AlarmGroupMember,
  AlarmGroupMemberType,
} from '../../../../state/noysee/models/alarmGroup';
import { AlarmGroupService } from '../../../../state/noysee/alarmGroup.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddAlarmGroupMemberComponent } from '../addMember-modal/addAlarmGroupMember.component';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-alarmGroup-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class AlarmGroupDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  alarmGroupDetails: AlarmGroup;
  submitted: boolean;
  crudDeviceForm: FormGroup;
  mode: string;
  isSuperAdmin: boolean;
  isAdmin: boolean;

  isEditable: boolean;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;
  dataTableOptions: DataTableOptions<AlarmGroupMember>;
  gridState: GridState;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private alarmGroupService: AlarmGroupService,
  ) {
    this.alarmGroupDetails = new AlarmGroup();

    this.crudDeviceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      alarming: new FormControl('', []),
      clearing: new FormControl('', []),
      description: new FormControl('', []),
    });
    this.submitted = false;
  }

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.gridState = this.router.getCurrentNavigation().extras
        .state as GridState;
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.loadAlarmGroup();

    this.isEditable = true;
    if (this.mode === 'add') {
      this.alarmGroupDetails.active = true;
      this.alarmGroupDetails.alarming = true;
      this.alarmGroupDetails.clearing = true;
    } else if (this.mode === 'edit') {
    } else {
      this.isEditable = false;
      this.f.name.disable();
      this.f.description.disable();
      this.f.active.disable();
    }
    this.dataToForm();

    this.initializeMemberTable();
  }

  ngOnDestroy() {}

  private initializeMemberTable() {
    // Initialize data table
    this.dataTableOptions = {
      title: 'alarmGroup.member.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'alarmGroup.member.table.actions.add',
          rule: () => this.isSuperAdmin || this.isAdmin,
          actionFn: () => this.addAlarmGroupMember(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'alarmGroup.member.table.actions.edit',
            icon: 'app-pencil',
            rule: () => this.isSuperAdmin || this.isAdmin,
            actionFn: (member: AlarmGroupMember) =>
              this.editMessengerAlarmGroupMember(member),
          },
          {
            label: 'alarmGroup.member.table.actions.delete',
            icon: 'app-delete',
            rule: () => this.isSuperAdmin || this.isAdmin,
            actionFn: (member: AlarmGroupMember) =>
              this.removeAlarmGroupMember(member.id),
          },
        ],
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'alarmGroup.member.table.columns.id',
          width: '10%',
          sortable: true,
          filterFn: (member: AlarmGroupMember, arg) =>
            member.id.toString(10).includes(arg),
        },
        {
          key: 'name',
          displayName: 'alarmGroup.member.table.columns.name',
          width: '40%',
          sortable: true,
          filterFn: (member: AlarmGroupMember, arg) =>
            member.name.toLowerCase().includes(arg.toLowerCase()),
          actionFn: (member: AlarmGroupMember) =>
            this.editMessengerAlarmGroupMember(member),
        },
        {
          key: 'memberType',
          displayName: 'alarmGroup.member.table.columns.memberType',
          width: '10%',
          sortable: true,
          valueTransform: (member: AlarmGroupMember) =>
            'alarmGroup.member.memberType.' + member.memberType,
          filterType: TableFilterType.SELECT,
          filterValues: this.getMemberTypes(),
          filterFn: (member: AlarmGroupMember, arg) =>
            member.memberType === arg,
        },
        {
          key: 'settings',
          displayName: 'alarmGroup.member.table.columns.settings',
          width: '30%',
          valueTransform: (member: AlarmGroupMember) => {
            if (member.memberType == AlarmGroupMemberType.USER) {
              return (
                `<span class="toggle-button${
                  member.email ? ' active' : ''
                }">eMail</span>&nbsp;` +
                `<span class="toggle-button${
                  member.sms ? ' active' : ''
                }">SMS</span>&nbsp;` +
                `<span class="toggle-button${
                  member.voice ? ' active' : ''
                }">Voice</span>&nbsp;` +
                `<span class="toggle-button${
                  member.phone ? ' active' : ''
                }">Phone</span>`
              );
            }
            return;
          },
          //actionFn: (member: AlarmGroupMember) => this.editMessengerAlarmGroupMember( member)
        },
        {
          key: 'active',
          displayName: 'alarmGroup.member.table.columns.active',
          width: '10%',
          valueTransform: (member: AlarmGroupMember) =>
            member.active
              ? 'alarmGroup.member.state.active'
              : 'alarmGroup.member.state.inactive',
          filterType: TableFilterType.SELECT,
          filterValues: [
            {
              label: 'alarmGroup.member.state.active',
              value: true,
            },
            {
              label: 'alarmGroup.member.state.inactive',
              value: false,
            },
          ],
        },
      ],
    };
  }

  private getMemberTypes(): any[] {
    const types = [];
    for (const memberType of Object.values(AlarmGroupMemberType)) {
      types.push({
        label: 'alarmGroup.member.memberType.' + memberType,
        value: memberType,
      });
    }
    return types;
  }

  private dataToForm() {
    this.crudDeviceForm.patchValue({
      name: this.alarmGroupDetails.name,
      description: this.alarmGroupDetails.description,
      active: this.alarmGroupDetails.active,
      alarming: this.alarmGroupDetails.alarming,
      clearing: this.alarmGroupDetails.clearing,
    });
    this.initializeMemberTable();
  }

  async loadAlarmGroup() {
    const url = this.route.snapshot.params;
    // this.alarmGroupDetails = await 
    this.alarmGroupService.getDetail(url.id).then( result => {
		this.alarmGroupDetails = result;
	    this.dataToForm();
    	this.initializeMemberTable();
	});
  }

  get f() {
    return this.crudDeviceForm.controls;
  }

  toggleActive() {
    this.alarmGroupDetails.active = !this.alarmGroupDetails.active;
    this.crudDeviceForm.patchValue({
      active: this.alarmGroupDetails.active,
    });
  }
  toggleAlarming() {
    this.alarmGroupDetails.alarming = !this.alarmGroupDetails.alarming;
    this.crudDeviceForm.patchValue({
      alarming: this.alarmGroupDetails.alarming,
    });
  }
  toggleClearing() {
    this.alarmGroupDetails.clearing = !this.alarmGroupDetails.clearing;
    this.crudDeviceForm.patchValue({
      clearing: this.alarmGroupDetails.clearing,
    });
  }

  saveAlarmGroup() {
	
    this.submitted = true;

    if (this.crudDeviceForm.invalid) {
      return;
    }

    this.alarmGroupDetails.name = this.f.name.value;
    this.alarmGroupDetails.description = this.f.description.value;
    // switches are already set

    try {
      this.alarmGroupService
        .saveDetail(this.alarmGroupDetails)
        .then((result) => {
          this.alarmGroupDetails = result;
          this.dataToForm();
          this.toastHelper.openSnackBar('Alarmgruppe gespeichert!', 'SUCCESS');
        });
    } catch (e) {
      if (this.mode === 'add') {
      } else {
        this.toastHelper.openCrudChangeError();
      }
    }
  }

  deleteAlarmGroup() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diese Alarmgruppe wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.alarmGroupService.delete(this.alarmGroupDetails.id).then(() => {
            this.alarmGroupService.getList().then(() => {
              this.toastHelper.openSnackBar('Alarmgruppe gelöscht!', 'SUCCESS');
            });
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  addAlarmGroupMember() {
    const dialogOptions: MatDialogConfig = {
      data: {
        alarmGroupId: this.alarmGroupDetails.id,
        alarmGroupName: this.alarmGroupDetails.name,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddAlarmGroupMemberComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then(() => {
        this.loadAlarmGroup();
      });
  }

  editMessengerAlarmGroupMember(alarmGroupMember: AlarmGroupMember) {
    const dialogOptions: MatDialogConfig = {
      data: {
        alarmGroupId: this.alarmGroupDetails.id,
        alarmGroupName: this.alarmGroupDetails.name,
        alarmGroupMember: alarmGroupMember,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddAlarmGroupMemberComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then(() => {
        this.loadAlarmGroup();
      });
  }

  removeAlarmGroupMember(memberId: number) {
    this.alarmGroupService
      .removeMember(this.alarmGroupDetails.id, memberId)
      .then(() => {
        this.alarmGroupService.getList().then(() => {
          this.loadAlarmGroup();
          this.toastHelper.openSnackBar('Teilnehmer entfernt!', 'SUCCESS');
        });
      });
  }

  async toggleEmail(member: AlarmGroupMember) {
    if (this.isEditable) {
      member.email = !member.email;
      await this.alarmGroupService.saveMember(
        this.alarmGroupDetails.id,
        member,
      );
      await this.loadAlarmGroup();
    }
  }

  async toggleSms(member: AlarmGroupMember) {
    if (this.isEditable) {
      member.sms = !member.sms;
      await this.alarmGroupService.saveMember(
        this.alarmGroupDetails.id,
        member,
      );
      await this.loadAlarmGroup();
    }
  }

  async togglePhone(member: AlarmGroupMember) {
    if (this.isEditable) {
      member.phone = !member.phone;
      await this.alarmGroupService.saveMember(
        this.alarmGroupDetails.id,
        member,
      );
      await this.loadAlarmGroup();
    }
  }

  async toggleVoice(member: AlarmGroupMember) {
    if (this.isEditable) {
      member.voice = !member.voice;
      await this.alarmGroupService.saveMember(
        this.alarmGroupDetails.id,
        member,
      );
      await this.loadAlarmGroup();
    }
  }

  gotoGroupMember(alarmGroupMember: AlarmGroupMember) {
    switch (alarmGroupMember.memberType) {
      case AlarmGroupMemberType.USER:
        this.router.navigate([
          '/dashboard',
          'users',
          alarmGroupMember.userId,
          'edit-user',
        ]);
        break;
    }
  }

  back() {
    this.location.back();
  }
}
