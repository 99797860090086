import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { GridState } from '../../../../../shared/data-table/data-table.model';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import {
  NoyseeDevice,
  DeviceState,
} from '../../../../../models/noyseeDevice';
import { LookupEntry } from '../../../../../models/apiResults';
import { DevicesService } from '../../../../../services/devices.service';
import { InstallDeviceDialogComponent } from '../installDevice-modal/installDeviceDialog.component';
import * as moment from 'moment';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-sensors-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class NoyseeDeviceDetailComponent implements OnInit, OnDestroy {
  DeviceState = DeviceState;
  routerSubscription: Subscription;
  deviceDetails: NoyseeDevice;
  deviceDetails$: Observable<NoyseeDevice>;
  deviceDetailsSubscription: Subscription;

  crudDeviceForm: FormGroup;

  templateList: LookupEntry[];

  mode: String;

  ctx: GridState = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private toastHelper: ToastHelper,
    private dataTableStateService: DataTableStateService,
    private deviceService: DevicesService,
    private lookupService: LookupService,

  ) {
    this.deviceDetails = new NoyseeDevice();

    this.crudDeviceForm = this.formBuilder.group({
      noyseeSN: new FormControl('', []),
      loraDevEui: new FormControl('', [Validators.required]),
      loraAppEui: new FormControl('', []),
      loraAppKey: new FormControl('', []),
      simICCID: new FormControl('', []),
      imei: new FormControl('', []),
      btMac: new FormControl('', [Validators.required]),
      hwType: new FormControl('', [Validators.required]),
      stateText: new FormControl('', []),
      state: new FormControl('', []),
      remark: new FormControl('', []),
      software: new FormControl('', []),
      deliveryDate: new FormControl('', []),
      testDate: new FormControl('', []),
      productionDate: new FormControl('', []),
      qsBy: new FormControl('', []),
      pcbSN: new FormControl('', [Validators.maxLength(10)]),
      description: new FormControl('', []),
      defaultSensorBoxTemplateId: new FormControl('', []),
      predefinedAppEUI: new FormControl('', []),
      batterySN: new FormControl('', [ Validators.maxLength(10)]),
    	loraModule: new FormControl('', [ Validators.maxLength(20)]),
      pcieModule: new FormControl('', [ Validators.maxLength(20)]),
      maueInventoryNumber: new FormControl('', [ Validators.maxLength(25)]),
    });

    //this.dataToForm();

    this.mode = 'edit';
  }

  ngOnInit(): void {
    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    this.lookupService.getSensorBoxTemplateList().then((result) => {
      this.templateList = result;
    });

    this.loadDevice();

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadDevice();
      });
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private parseDate(date: string | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  private dataToForm() {
    this.crudDeviceForm.patchValue({
      noyseeSN: this.deviceDetails.noyseeSN,
      simICCID: this.deviceDetails.simICCID,
      imei: this.deviceDetails.imei,
      btMac: this.deviceDetails.btMac,
      hwType: this.deviceDetails.hwType,
      stateText: this.deviceDetails.stateText,
      state: this.deviceDetails.state,
      remark: this.deviceDetails.remark,
      software: this.deviceDetails.software,
      deliveryDate: this.parseDate(this.deviceDetails.deliveryDate),
      testDate: this.parseDate(this.deviceDetails.testDate),
      productionDate: this.parseDate(this.deviceDetails.productionDate),
      qsBy: this.deviceDetails.qsBy,
      pcbSN: this.deviceDetails.pcbSN,
      description: this.deviceDetails.description,
      defaultSensorBoxTemplateId:
        this.deviceDetails.defaultSensorBoxTemplate?.id,
      loraDevEui: this.deviceDetails.loraDevEui,
      loraAppEui: this.deviceDetails.loraAppEui,
      loraAppKey: this.deviceDetails.loraAppKey,
      predefinedAppEUI: this.deviceDetails.loraAppEui,
      loraModule: this.deviceDetails.loraModule,
      pcieModule: this.deviceDetails.pcieModule,
      batterySN: this.deviceDetails.batterySN,
      maueInventoryNumber: this.deviceDetails.maueInventoryNumber,
    });
    this.f.noyseeSN.disable();
  }

  loadDevice() {
    const url = this.route.snapshot.params;
    // this.applicationContextService.currentSensorId = url.sno;

    this.deviceDetails$ = this.deviceService.getDeviceBySno(url.sno);
    this.deviceDetailsSubscription = this.deviceDetails$.subscribe((device) => {
      console.log(device);
      this.deviceDetails = device;
      this.dataToForm();
    });
  }

  get installable() {
    return (
      this.deviceDetails.state == DeviceState.TESTED ||
      this.deviceDetails.state == DeviceState.WAREHOUSE
    );
  }

  get f() {
    return this.crudDeviceForm.controls;
  }

  async saveDevice() {
    try {
      this.deviceDetails.noyseeSN = this.f.noyseeSN.value;
      this.deviceDetails.state = this.f.state.value;
      this.deviceDetails.defaultSensorBoxTemplate = {
        id: Number(this.f.defaultSensorBoxTemplateId.value),
        name: '',
      };
      this.deviceDetails.loraDevEui = this.f.loraDevEui.value;
      this.deviceDetails.loraAppEui = this.f.loraAppEui.value;
      this.deviceDetails.loraAppKey = this.f.loraAppKey.value;
      this.deviceDetails.btMac = this.f.btMac.value;
      this.deviceDetails.simICCID = this.f.simICCID.value;
      this.deviceDetails.imei = this.f.imei.value;
      this.deviceDetails.pcbSN = this.f.pcbSN.value;
      this.deviceDetails.software = this.f.software.value;
      this.deviceDetails.batterySN= this.f.batterySN.value;
      this.deviceDetails.loraModule= this.f.loraModule.value;
      this.deviceDetails.pcieModule= this.f.pcieModule.value;
      this.deviceDetails.maueInventoryNumber= this.f.maueInventoryNumber.value;
      // this.deviceDetails.productionDate =this.f.productionDate.value;
      // this.deviceDetails.testDate =this.f.testDate.value;
      // this.deviceDetails.qsBy = this.f.qsBy.value;
      // this.deviceDetails.deliveryDate =this.f.deliveryDate.value;
      this.deviceDetails.remark = this.f.remark.value;
      // this.deviceDetails.description = this.f.description.value;
      this.deviceDetails.defaultSensorBoxTemplate = {
        id: this.f.defaultSensorBoxTemplateId.value,
        name: '',
      };

      if (this.mode === 'add') {
        await this.deviceService.createDevice(this.deviceDetails);
        this.toastHelper.openSnackBar(
          'Erstellen des Gerätes erfolgreich!',
          'SUCCESS',
        );
      } else if (this.mode === 'edit') {
        await this.deviceService.updateDevice(this.deviceDetails);
        this.toastHelper.openSnackBar(
          'Ändern des Gerätes erfolgreich!',
          'SUCCESS',
        );
      }
      this.goBack();
    } catch (e) {
      if (this.mode === 'add') {
        // if (e.message === ErrorCodes.DUPLICATE_LOGIN_NAME) {
        //   this.f.username.setErrors({ 'duplicatedUsername': true });
        //   this.emailDuplicated = true;
        // }
        // this.toastHelper.openSnackBar(ErrorHandler.getText(e.message), 'ERROR');
      } else {
        this.toastHelper.openCrudChangeError();
      }
    }
  }

  installDevice() {
    const dialogOptions: MatDialogConfig = {
      data: {
        noyseeDevice: this.deviceDetails,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(InstallDeviceDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        this.loadDevice();
      });
  }

  async finishQS() {
    this.deviceDetails.state = DeviceState.TESTED;
    await this.deviceService.updateDevice(this.deviceDetails);
    this.loadDevice();
  }

  async startQS() {
    this.deviceDetails.state = DeviceState.IN_QS;
    await this.deviceService.updateDevice(this.deviceDetails);
    this.loadDevice();
  }

  async repair() {
    this.deviceDetails.state = DeviceState.IN_REPAIR;
    await this.deviceService.updateDevice(this.deviceDetails);
    this.loadDevice();
  }

  goBack() {
    let navRoute = ['/dashboard', 'admins', 'devices', 'overview'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  chooseAppEui() {
    const eui: string = this.f.predefinedAppEUI.value;
    this.crudDeviceForm.patchValue({
      loraAppEui: eui,
    });
  }

  maybePredefined() {
    const eui: string = this.f.loraAppEui.value;
    this.crudDeviceForm.patchValue({
      predefinedAppEUI: eui,
    });
  }

  showLocation() {
    window.open(
      'https://www.openstreetmap.org/search?query=' +
      this.deviceDetails.lat +
      '%2C' +
      this.deviceDetails.lon,
      '_blank',
    );
  }

  showM2MInfo() {
    window.open(
      'https://portal-m2m.telekom.de/m2m-customer-portal/user/simcard/detail/' +
      this.f.simICCID.value,
      'M2M_Portal',
    );
  }

  showSensorBox(id: number) {
    let navRoute = ['/dashboard', 'sensors', 'detail', id, 'values'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }
}
