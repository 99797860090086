import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { LookupService } from '../../../../../services/lookup.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { BillingService } from 'src/app/services/billing';
import { Product } from 'src/app/models/billing';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

@Component({
  selector: 'app-addDeliveryDialog',
  templateUrl: './addDeliveryDialog.component.html',
  styleUrls: ['./addDeliveryDialog.component.scss'],
})
export class AddDeliveryDialogComponent implements OnInit {
  isAdmin: boolean;
  isSuperAdmin: boolean;

  // templateList: LookupEntry[];
  customerList: LookupEntry[];
  productList: Product[];

  deliveryDate: Date;
  contractStart: Date;

  selectedProduct: Product;
  public delivery: Product;

  constructor(
    private store: Store,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private applicationContextService: ApplicationContextService,
    private lookupService: LookupService,
    private billingService: BillingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddDeliveryDialogComponent>,
  ) {
    this.isSuperAdmin = false;
    this.isAdmin = false;
  }

  ngOnInit() {
    this.delivery = new Product();
    this.delivery.amount = 1;
    this.delivery.billingInterval = 0;
    this.delivery.delivery = new Date();
    
    // this.delivery.customer= this.data?.customer;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.lookupService.getCustomerList().then((result) => {
      this.customerList = result;
      this.delivery.customer = this.customerList.find(
        (c) => c.id === this.store.selectSnapshot(GuiState.selectedCustomer).id,
      );
    });

    this.billingService.getProducts().then((result) => {
      this.productList = result;
    });
  }

  setDeliveryField(name: string, $event: any) {
    this.delivery[name] = $event;
  }

  selectProduct($event) {
    console.log(this.selectedProduct);
    this.delivery = Object.assign({}, this.selectedProduct);
    this.delivery.product = Object.assign({}, this.selectedProduct); 
    this.delivery.customer = this.customerList.find(
      (c) => c.id == this.store.selectSnapshot(GuiState.selectedCustomer).id,
    );
    this.delivery.amount = 1;
    this.delivery.delivery = new Date();
    this.delivery.billingInterval = this.selectedProduct.billingInterval;
  }

  addDeliveryAndClose(): void {
    this.billingService.addDelivery(this.delivery).then((data) => {
      this.toastHelper.openSnackBar('Gespeichert', 'SUCCESS');
      this.dialogRef.close();
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
