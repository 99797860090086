import {TranslateService} from '@ngx-translate/core';
import {DE} from './DE';
import {EN} from './EN';

export function initI18n(translateService: TranslateService) {
  translateService.setTranslation('de', DE);
  translateService.setTranslation('en', EN);

  translateService.setDefaultLang('de');
}
