import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { SensorActions } from 'src/app/state/noysee/sensor.action';

@Component({
  selector: 'app-sensor-minimal',
  templateUrl: './minimal.component.html',
  styleUrls: ['./minimal.component.scss'],
})
export class MinimalComponent implements OnInit {
  sensor$ = this.store.select(SensorState.currentSensor);
  showSensorId: boolean;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    // Set sensor ID
    this.store.dispatch(
      new SensorActions.Select(
        parseInt(this.activatedRoute.snapshot.params['uuid'], 10),
      ),
    );
    this.showSensorId = this.authenticationService.isSuperAdmin();
  }
}
