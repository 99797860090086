import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '@app/helpers/toast.helper';
import { AuthenticationService } from '@app/services/authentication.service';
import { GuiState } from '@app/state/dashboard/gui.state';
import { AlarmActionService } from '@app/state/noysee/alarmAction.service';
import { AlarmAction, AlarmActionExecutionType } from '@app/state/noysee/models/alarmAction';
import { Sensor } from '@app/state/noysee/models/sensor';
import { Store } from '@ngxs/store';
import { RenderedInfo } from 'src/app/state/noysee/models/sensorBox';

@Component({
	selector: 'app-execute-alarmAction-modal',
	templateUrl: './execute-alarmAction-modal.component.html',
	styleUrls: ['./execute-alarmAction-modal.component.scss'],
})
export class ExecuteAlarmActionModalComponent implements OnInit {
	sensorBox: Sensor;
	alarmAction: AlarmAction;
	password: string;
	superadmin: boolean;

	authError: boolean;
	errorMessage: string;

	mode: string;

	constructor(
		private dialogRef: MatDialogRef<ExecuteAlarmActionModalComponent>,
		private alarmActionService: AlarmActionService,
		private authService: AuthenticationService,
		private toastHelper: ToastHelper,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { }

	ngOnInit(): void {
		this.mode = this.data?.mode;
		this.sensorBox = this.data?.sensorBox;
		this.alarmAction = this.data?.alarmAction;

		this.superadmin = this.authService.isSuperAdmin();
		this.authError = false;
		this.errorMessage = null;
	}

	async startAction() {
		let customerId = this.store.selectSnapshot(GuiState.selectedCustomer).id;
		this.alarmActionService.executeAlarmActionStart(this.alarmAction.id, this.password, this.sensorBox?.id, customerId, null).then(result => {
			if (result.commandType == AlarmActionExecutionType.webLink) {
				window.open(result.link, this.alarmAction.name);
			}
			this.toastHelper.openSnackBar('Aktion ausgeführt!', 'SUCCESS');
			this.dialogRef.close();
		}).catch(e => {
			if (e.message == "PASSWORD_MISSMATCH") {
				this.authError = true;
				this.errorMessage = "PASSWORD_MISSMATCH";
				console.debug(this.errorMessage);
				this.toastHelper.openSnackBar('Zugriff verweigert!', 'ERROR');
			} else {
				this.toastHelper.openSnackBar('Aktion fehlgeschlagen!', 'ERROR');
			}
		});
	}

	async endAction() {
		let customerId = this.store.selectSnapshot(GuiState.selectedCustomer).id;
		this.alarmActionService.executeAlarmActionEnd(this.alarmAction.id, this.password, this.sensorBox?.id, customerId, null).then(result => {
			if (result.commandType == AlarmActionExecutionType.webLink) {
				window.open(result.link, this.alarmAction.name);
			}
			this.toastHelper.openSnackBar('Aktion ausgeführt!', 'SUCCESS');
			this.dialogRef.close();
		}).catch(e => {
			if (e.message == "PASSWORD_MISSMATCH") {
				this.authError = true;
				this.errorMessage = "PASSWORD_MISSMATCH";
				console.debug(this.errorMessage);
				this.toastHelper.openSnackBar('Zugriff verweigert!', 'ERROR');
			} else {
				this.toastHelper.openSnackBar('Aktion fehlgeschlagen!', 'ERROR');
			}
		});
	}

	cancel() {
		this.dialogRef.close();
	}
}
