import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SensorComponent } from './default/sensor/sensor.component';
import { CustomerComponent } from './default/customer/customer.component';
import { MinimalComponent } from './shared/sensor/minimal/minimal.component';
import { MapComponent } from './default/map/map.component';
import { HistoryComponent } from './shared/sensor/history/history.component';
import { ChartComponent } from './default/chart/chart.component';

const routes: Routes = [
  {
    path: 'map',
    component: MapComponent,
  },
  {
    path: 'sensor',
    children: [
      {
        path: ':uuid',
        component: SensorComponent,
      },
      {
        path: ':uuid/minimal',
        component: MinimalComponent,
      },
      {
        path: ':uuid/history',
        component: HistoryComponent,
      },
      {
        path: ':uuid/chart',
        component: ChartComponent,
      },
      {
        path: ':uuid/chart/:sensorName',
        component: ChartComponent,
      },
    ],
  },
  {
    path: 'overview',
    children: [
      {
        path: ':mode',
        component: CustomerComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'table',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MicrositesRoutingModule {}
