import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductListComponent } from './productList/productList.component';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { AddDeliveryDialogComponent } from './addDelivery-modal/addDeliveryDialog.component';
import { DeliveryDetailComponent } from './deliveryDetail/deliveryDetail.component';
import { ProductDetailComponent } from './productDetail/productDetail.component';

const routes: Routes = [
  {
    path: 'products',
    children: [
      {
        path: 'overview',
        data: {
          breadcrumbLabel: 'billing.products.headerLabel'
        },
        component: ProductListComponent
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            data: {
              mode: 'edit',
              breadcrumbLabel: 'billing.product.headerLabel',
            },
            component: ProductDetailComponent,
          },
          {
            path: 'view',
            data: {
              breadcrumbLabel: 'billing.product.headerLabel',
              mode: 'view',
            },
            component: ProductDetailComponent,
          },
        ],
      },

      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
    ]
  },

  {
    path: 'deliveries',
    children: [
      {
        path: 'overview',
        data: {
          breadcrumbLabel: 'billing.deliveries.headerLabel'
        },
        component: DeliveriesComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'add',
        data: {
          mode: 'add',
          breadcrumbLabel: 'billing.delivery.headerLabel',
        },
        component: DeliveryDetailComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            data: {
              mode: 'edit',
              breadcrumbLabel: 'billing.delivery.headerLabel',
            },
            component: DeliveryDetailComponent,
          },
          {
            path: 'view',
            data: {
              breadcrumbLabel: 'billing.delivery.headerLabel',
              mode: 'view',
            },
            component: DeliveryDetailComponent,
          },
        ],
      },
    ]
  },
  // {
  //   path: 'addDelivery',
  //   component: AddDeliveryDialogComponent
  // },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule {
}
