import {
  DomUtil,
  icon,
  LatLng,
  LatLngBounds,
  LayerGroup,
  MapOptions,
  Marker,
  Polyline,
  tileLayer,
} from 'leaflet';
import { AuthenticationService } from '../services/authentication.service';
import { Mutex } from 'async-mutex';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ChartHelper } from './chart.helper';
import * as moment from 'moment';
import { TranslatePipe } from '@ngx-translate/core';
import { Sensor } from '../state/noysee/models/sensor';
import { environment } from '../../environments/environment';
import { TopoService } from '../state/dashboard/topo.service';
import { Store } from '@ngxs/store';
import { SensorState } from '../state/noysee/sensor.state';
import { MapActions } from '../state/dashboard/map.action';
import { GuiState } from '../state/dashboard/gui.state';
import { GuiActions } from '../state/dashboard/gui.action';
import { filter, map } from 'rxjs/operators';
import { SensorActions } from '../state/noysee/sensor.action';
import { SensorService } from '../state/noysee/sensor.service';
import { Observable } from 'rxjs';
import { Waterway } from '../models/topo';
import { SensorBox } from '../state/noysee/models/sensorBox';

const markerSensorClasses = {
  '-4': 'marker marker-sensor marker-planned marker-grey selected',
  '-3': 'marker marker-sensor marker-disabled marker-grey selected',
  '-2': 'marker marker-sensor marker-defective marker-grey selected',
  '-1': 'marker marker-sensor marker-error marker-grey selected',
  '0': 'marker marker-sensor marker-normal marker-green selected',
  '1': 'marker marker-sensor marker-limit1 marker-orange selected',
  '2': 'marker marker-sensor marker-limit2 marker-red selected',
  '3': 'marker marker-sensor marker-limit3 marker-red selected',
  '4': 'marker marker-sensor marker-limit4 marker-red selected',
  '5': 'marker marker-sensor marker-limit5 marker-red selected',
};

@Injectable({
  providedIn: 'root',
})
export class LeafletHelper {
  constructor(
    private authenticationService: AuthenticationService,
    private topoService: TopoService,
    private zone: NgZone,
    private router: Router,
    private chartHelper: ChartHelper,
    private translatePipe: TranslatePipe,
    private store: Store,
    private sensorService: SensorService,
  ) {}

  sensorPopupMutex: any = new Mutex();
  currentlyHovering: boolean = false;
  markerSize: [number, number] = [
    environment.licensedFeatures.mapMarkerDiameter,
    environment.licensedFeatures.mapMarkerDiameter,
  ];

  static mapOptions(
    zoomLevel: number,
    mapCenter: LatLng,
    dragging: boolean = true,
  ): MapOptions {
    return {
      layers: [
        tileLayer(
          'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        ),
      ],
      zoom: zoomLevel,
      minZoom: 6,
      zoomControl: false,
      scrollWheelZoom: true,
      center: [mapCenter.lat, mapCenter.lng],
      dragging,
      loadingControl: true,
    } as any;
  }

  isCurrentlyHovering(): boolean {
    return this.currentlyHovering;
  }

  generateSensorMarker(
    sensor: SensorBox,
    containsChart: boolean = true,
    onClick?: any,
  ): Marker {
    const marker = new Marker([sensor.lat, sensor.lon], {
      icon: icon({
        iconSize: this.markerSize,
        iconUrl: 'assets/icons/filter/' + sensor.frontendType + '.svg',
        popupAnchor: [0, -40],
        className: markerSensorClasses[sensor.state],
      }),
    });
    let class_state = '';
    let class_label = 'sensor.popup.';

    const popupValues = this.extractSensorValuesForPopup(sensor);

    const primarySensor = sensor.primarySensor;
    if (!sensor.sensors[primarySensor]) {
      sensor.state = -4;
    }

    const noTimestamp = popupValues.currentTimestamp === '';
    const currentTimestampReadable = noTimestamp
      ? 'nicht vorhanden'
      : moment(popupValues.currentTimestamp).format('DD.MM.YYYY HH:mm');
    if (noTimestamp) {
      class_state = 'planned';
      class_label += 'planned';
    } else {
      switch (sensor.state) {
        case 0:
          class_state = 'normal';
          class_label += primarySensor + '.normal';
          break;
        case 1:
          class_state = 'limit1';
          class_label += primarySensor + '.limit1';
          break;
        case 2:
          class_state = 'limit2';
          class_label += primarySensor + '.limit2';
          break;
        case 3:
          class_state = 'limit3';
          class_label += primarySensor + '.limit3';
          break;
        case 4:
          class_state = 'limit4';
          class_label += primarySensor + '.limit4';
          break;
        case 5:
          class_state = 'limit5';
          class_label += primarySensor + '.limit5';
          break;
        case -1:
          class_state = 'error';
          class_label += 'error';
          break;
        case -2:
          class_state = 'defective';
          class_label += 'defective';
          break;
        case -3:
          class_state = 'disabled';
          class_label += 'disabled';
          break;
        case -4:
          class_state = 'planned';
          class_label += 'planned';
          break;
        default:
          // No known state is active
          break;
      }
    }

    const chartContainer = containsChart
      ? '   <div class="popup-chart-container">\n'
      : '   <div class="popup-chart-container disabled">\n';

    let labelTranslation;
    if (class_state.includes('limit')) {
      labelTranslation = this.translatePipe.transform(
        'sensor.popup.' + class_state + '_prefix',
      );
      const translation = this.translatePipe.transform(class_label);
      if (translation !== class_label) {
        labelTranslation += ': ' + this.translatePipe.transform(class_label);
      }
    } else if (class_state === 'normal') {
      labelTranslation =
        this.translatePipe.transform('sensor.type.' + primarySensor) +
        ' im Normalbereich';
    } else {
      labelTranslation = this.translatePipe.transform(class_label);
    }
    const content =
      '<div>\n ' +
      '   <div class="popup-label">\n' +
      '      <span class="sensor popup-' +
      class_state +
      ' active">\n' +
      '         <strong>' +
      labelTranslation +
      '</strong>\n' +
      '      </span>\n' +
      '    </div>\n' +
      '   <div class="popup-title">\n' +
      '    <div class="popup-title-left">\n' +
      '      <span class="popup-title-left-headline">' +
      sensor.name +
      '</span>\n' +
      '    </div>\n' +
      '   </div>\n' +
      '   <div class="popup-values-container" style="width: 300px;">\n' +
      '     <div class="popup-values-row">' +
      '</div>\n' +
      '     <div class="popup-values-row"><span class="' +
      class_state +
      '">' +
      '       <strong>Aktueller Wert:</strong> ' +
      (popupValues.currentValue ?? '-') +
      '&nbsp;' +
      sensor.sensors[primarySensor].unit +
      '</span><span id="popup-current-trend"></span></div>\n' +
      '     <div class="popup-values-row"><strong>Letzte Meldung:</strong> ' +
      currentTimestampReadable +
      '</div>\n' +
      '     <div class="popup-values-row"><strong>Datenquelle:</strong> ' +
      popupValues.dataSource +
      ' </div>\n' +
      '   </div>' +
      chartContainer +
      '       <div id="sensor-popup-chartdiv-' +
      sensor.id +
      '" class="popup-chartdiv"></div>\n' +
      '       <div id="sensor-popup-chart-placeholder-' +
      sensor.id +
      '" class="popup-chart-placeholder disabled">Keine Werte gefunden!</div>\n' +
      '   </div>\n';

    const _this = this;
    marker.bindPopup(content, { closeButton: false });
    marker.on('mouseover', async function (e) {
      const release = await _this.sensorPopupMutex.acquire();
      try {
        this.openPopup();
        _this.currentlyHovering = true;
      } finally {
        release();
      }
    });
    marker.on('mouseout', async function (e) {
      const release = await _this.sensorPopupMutex.acquire();
      try {
        this.closePopup();
        _this.currentlyHovering = false;
      } finally {
        release();
      }
    });
    let tempChart;
    marker.on('popupopen', async () => {
      if (containsChart) {
        const detail = (
          await this.sensorService
            .getSensorBoxData(sensor.id, moment().subtract(7, 'days').toDate())
            .toPromise()
        ).data;
        const levels = this.chartHelper.collectSensorData(
          detail as any, // TODO: fix type as soon as detail and meta have their own types
          detail.primarySensor,
          0,
        );

        if (levels.length === 0) {
          const placeholder = document.getElementById(
            'sensor-popup-chart-placeholder-' + sensor.id,
          );
          const chartdiv = document.getElementById(
            'sensor-popup-chartdiv-' + sensor.id,
          );

          if (placeholder) {
            placeholder.classList.remove('disabled');
          }
          if (chartdiv) {
            chartdiv.classList.add('disabled');
          }
        } else {
          if (document.getElementById('sensor-popup-chartdiv-' + sensor.id)) {
            const yMin = detail?.sensors[detail.primarySensor]?.displayMinY;
            const yMax = detail?.sensors[detail.primarySensor]?.displayMaxY;
            tempChart = _this.chartHelper.createSensorChart(
              levels,
              detail.primarySensor,
              'sensor-popup-chartdiv-' + sensor.id,
              true,
              yMin,
              yMax,
            );

            const trend = this.chartHelper.getTrend(
              this.chartHelper.calculateLinReg(levels, detail.primarySensor),
            );
            const valueElem = document.getElementById('popup-current-trend');
            valueElem.innerHTML =
              '<img src="assets/img/trend-' +
              trend.toLowerCase() +
              '.svg" alt="trend" />';
          }
        }
      }
    });
    marker.on('popupclose', () => {
      if (containsChart) {
        const placeholder = document.getElementById(
          'sensor-popup-chart-placeholder-' + sensor.id,
        );
        const chartdiv = document.getElementById(
          'sensor-popup-chartdiv-' + sensor.id,
        );
        const valueElem = document.getElementById('popup-current-trend');

        placeholder.classList.add('disabled');
        chartdiv.classList.remove('disabled');
        valueElem.innerHTML = '';
        _this.chartHelper.disposeChart(tempChart);
      }
    });
    marker.on('click', () => onClick());
    return marker;
  }

  extractSensorValuesForPopup(sensor): any {
    let currentValue = '';
    let currentTimestamp = '';
    let dataSource = '';
    const primarySensor = sensor.primarySensor;
    if (sensor?.sensors[primarySensor]) {
      if (typeof sensor?.sensors[primarySensor].currentDataValue === 'number') {
        currentValue =
          '' +
          Math.round(sensor.sensors[primarySensor].currentDataValue * 10) / 10;
      }
      currentTimestamp = sensor.sensors[primarySensor].currentDataTimestamp;
    }
    if (sensor.datasource) {
      dataSource = sensor.datasource;
    }
    return {
      currentValue: currentValue,
      currentTimestamp: currentTimestamp,
      dataSource: dataSource,
    };
  }

  openSensorDetailViewFor(sensorId: number, coordinates: LatLng) {
    this.store.dispatch(new MapActions.SetView(coordinates));

    if (!this.store.selectSnapshot(GuiState.primaryPanelOpen)) {
      this.store.dispatch(new GuiActions.SetPrimaryPanelVisibility(true));
    }

    // use NgZone to tell angular, that the navigation event, triggered by the map marker,
    // is relevant for angular components -> re-render!
    const _this = this;
    this.zone.run(() => {
      _this.router.navigate(['/dashboard', 'sensors', 'detail', sensorId]);
    });
  }

  generateWaterwayLayers(
    boundingBox: LatLngBounds,
    zoom: number,
    updateFn: () => any,
  ): Observable<LayerGroup<Waterway>> {
    return this.topoService.getWaterways(boundingBox, zoom).pipe(
      map((waterways) => {
        const groups = new LayerGroup();
        // Generate new layers for current view
        for (const waterway of waterways) {
          // Create new layers based on new waterway information
          if (waterway.visible || this.authenticationService.isSuperAdmin()) {
            for (const part of waterway.parts) {
              const layer = new Polyline(part.nodes, {
                color: waterway.hexColor,
                weight: waterway.width,
                smoothFactor: 1,
                opacity: waterway.visible ? 1 : 0.5,
              });
              if (this.authenticationService.isSuperAdmin()) {
                // Setup checkbox to toggle visibility
                const checkbox = DomUtil.create('input');
                checkbox.type = 'checkbox';
                checkbox.checked = waterway.visible;
                checkbox.addEventListener('click', async () => {
                  await this.topoService.setVisibilityForWaterway(
                    waterway.wayId,
                    waterway.nodeId,
                    !waterway.visible,
                  );
                  await updateFn();
                });
                // Add wrapper element for checkbox
                const content = DomUtil.create('div');
                content.setAttribute(
                  'style',
                  'min-width: 250px; height: auto;',
                );
                content.innerHTML = `<strong>Einstellungen für Wasserweg<br>(ID: ${waterway.wayId}, Node: ${waterway.nodeId})</strong>
            <p style="display: inline-block; margin-right: 5px;">Global Sichtbar:</p>`;
                content.appendChild(checkbox);
                // Bind content to new layer
                layer.bindPopup(content);
              }
              groups.addLayer(layer);
            }
          }
        }
        return groups;
      }),
    );
  }
}
