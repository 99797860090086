import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { GuiDetail } from './models/guiDetails';
import { Injectable } from '@angular/core';
import { GuiActions } from './gui.action';
import { MapActions } from './map.action';
import { Customer } from 'src/app/models/customerList';
import { CustomerService } from 'src/app/services/customer.service';

interface GuiStateModel {
  guiContext: GuiDetail;
}

const GUI_STATE_TOKEN = new StateToken<GuiStateModel>('guiContext');

@State<GuiStateModel>({
  name: GUI_STATE_TOKEN,
  defaults: {
    guiContext: undefined,
  },
})
@Injectable()
export class GuiState {
  constructor(private customerService: CustomerService) {}

  @Selector()
  static guiContext(state: GuiStateModel): GuiDetail {
    return state.guiContext;
  }

  @Selector()
  static zoomLevel(state: GuiStateModel): number {
    return state.guiContext.zoomLevel;
  }

  @Selector()
  static selectedCustomer(state: GuiStateModel): Customer {
    return state.guiContext.selectedCustomer;
  }

  @Selector()
  static primaryPanelOpen(state: GuiStateModel): boolean {
    return state.guiContext.primaryPanelActive;
  }

  @Selector()
  static showMenu(state: GuiStateModel): boolean {
    return state.guiContext.showMenu;
  }

  @Action(GuiActions.SetGuiContextFromUser)
  setGuiContextFromUser(
    ctx: StateContext<GuiStateModel>,
    action: GuiActions.SetGuiContextFromUser,
  ): void {
    ctx.patchState({
      guiContext: new GuiDetail(action.user),
    });
    // Initalize map from user settings
    const details = ctx.getState().guiContext;
    ctx.dispatch(new MapActions.InitMapOptions(details));
  }

  @Action(GuiActions.UpdateGuiContext)
  updateGuiContext(
    ctx: StateContext<GuiStateModel>,
    action: GuiActions.UpdateGuiContext,
  ): void {
    ctx.patchState({
      guiContext: {
        ...ctx.getState().guiContext,
        ...action.context,
      },
    });
  }

  @Action(GuiActions.ResetGuiContext)
  resetContext(ctx: StateContext<GuiStateModel>): void {
    ctx.patchState({
      guiContext: undefined,
    });
  }

  @Action(GuiActions.SetPrimaryPanelVisibility)
  setPrimaryPanelOpen(
    ctx: StateContext<GuiStateModel>,
    action: GuiActions.SetPrimaryPanelVisibility,
  ): void {
    ctx.patchState({
      guiContext: {
        ...ctx.getState().guiContext,
        primaryPanelActive: action.visible,
      },
    });
  }

  @Action(GuiActions.SetMenuVisibility)
  setMenuVisibility(
    ctx: StateContext<GuiStateModel>,
    action: GuiActions.SetMenuVisibility,
  ): void {
    ctx.patchState({
      guiContext: {
        ...ctx.getState().guiContext,
        showMenu: action.visible,
      },
    });
  }

  @Action(GuiActions.SetCustomer)
  async setCustomer(
    ctx: StateContext<GuiStateModel>,
    action: GuiActions.SetCustomer,
  ): Promise<void> {
    // Resolve customer from backend
    const customer = await this.customerService.load(action.id);
    ctx.patchState({
      guiContext: {
        ...ctx.getState().guiContext,
        selectedCustomer: customer,
      },
    });
  }
}
