import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ToastHelper} from '../../../../helpers/toast.helper';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApplicationContextService} from '../../../../services/application-context.service';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit, AfterViewInit {
  submitted: boolean;
  inputControl: FormControl;
  selectControl: FormControl;
  range: FormGroup;
  checkboxControl: FormControl;
  toggleControl: FormControl;

  // Autocomplete
  autocompleteControl: FormControl;
  @ViewChild('auto3') autocomplete: MatAutocomplete;
  options: string[] = ['Test 1', 'Test 2', 'Test 3'];
  filteredOptions: Observable<string[]>;

  constructor(private toastHelper: ToastHelper,
              private applicationContextService: ApplicationContextService) {
    this.submitted = false;
    this.inputControl = new FormControl('', [Validators.required, Validators.maxLength(10)]);
    this.selectControl = new FormControl('', [Validators.required]);
    this.autocompleteControl = new FormControl('', [Validators.required]);
    this.range = new FormGroup({
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });
    this.checkboxControl = new FormControl('', [Validators.required]);
    this.toggleControl = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.filteredOptions = this.autocompleteControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterAutocomplete(value))
      );
  }

  ngAfterViewInit() {
  }

  filterAutocomplete(value: string): string[] {
    return this.options.filter(option => option.toLowerCase().includes(value.toLowerCase()));
  }

  openToast(message: string, type: 'ERROR' | 'SUCCESS') {
    this.toastHelper.openSnackBar(message, type);
  }

  toggleSubmitted() {
    this.submitted = true;
  }

  toggleSelectedDay(target: any) {
    target.classList.toggle('selected');
  }
}
