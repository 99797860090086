<div *ngIf="sensorBox$ | async as sensorBox" class="row">
  <div class="col-lg-4 col-12 ch-margin-top-4">
    <app-sensors-information
      [currentSensor]="sensorBox"
    ></app-sensors-information>
  </div>
  <div class="col-lg-8 col-12">
    <ng-container *ngIf="!isWeatherStation(sensorBox); else weatherStation">
      <app-sensor-chart></app-sensor-chart>
    </ng-container>
    <ng-template #weatherStation>
      <app-weather-station [sensorBox]="sensorBox"></app-weather-station>
    </ng-template>
  </div>
</div>
