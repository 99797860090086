import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { latLng } from 'leaflet';
import { Sensor } from '../../../../state/noysee/models/sensor';
import { AuthenticationService } from '../../../../services/authentication.service';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReplaceDeviceDialogComponent } from '../../admin/noyseeDevices/replaceDevice-modal/replaceDeviceDialog.component';
import { AlarmLevelStates } from 'src/app/models/apiResults';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { GuiActions } from 'src/app/state/dashboard/gui.action';
import { MapActions } from 'src/app/state/dashboard/map.action';
import { CreateSensorBoxDialogComponent } from '../../admin/sensorBox/createSensorBoxDialog/createSensorBoxDialog.component';
import { GuiState } from '@app/state/dashboard/gui.state';
import { ApprovalDialogHelper } from '@app/helpers/approval-dialog.helper';
import { NoyseeService } from '@app/state/noysee/noysee.service';
import { ToastHelper } from '@app/helpers/toast.helper';
// import { GuiState } from '../dashboard/gui.state';

@Component({
  selector: 'app-sensors-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  dataTableOptions: DataTableOptions<Sensor>;
  sensors$ = this.store.select(SensorState.myList);

  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  constructor(
    private store: Store,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private matDialog: MatDialog,
    private router: Router,
    private approvalDialogHelper: ApprovalDialogHelper,
    private noyseeService: NoyseeService,
    private toastHelper: ToastHelper,
  ) {}

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.gridState = this.router.getCurrentNavigation().extras
        .state as GridState;
    }
    const isSuperAdmin = this.authenticationService.isSuperAdmin();
    const isAdmin = this.authenticationService.isAdmin();
    this.dataTableOptions = {
      title: 'sensor.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'sensor.table.actions.add',
          rule: () => this.authenticationService.isSuperAdmin(),
          actionFn: () => this.createBox(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'sensor.table.actions.open',
            icon: 'app-view',
            actionFn: (sensor: Sensor) => this.showSensorDetail(sensor),
          },
          {
            label: 'sensor.table.actions.edit',
            icon: 'app-pencil',
            actionFn: (sensor: Sensor) => this.showSensorEdit(sensor),
            rule: () => isAdmin,
          },
          {
            label: 'sensor.table.actions.iot-settings',
            icon: 'settings',
            rule: () => this.authenticationService.isSuperAdmin(),
            actionFn: (sensor: Sensor) => this.adminBox(sensor),
          },
          {
            label: 'sensor.table.actions.replace',
            icon: 'cached',
            rule: () => this.authenticationService.isSuperAdmin(),
            actionFn: (sensor: Sensor) => this.replaceBox(sensor),
          },
          {
            label: 'sensor.table.actions.commandQueue',
            icon: 'list',
            rule: () => this.authenticationService.isSuperAdmin(),
            actionFn: (sensor: Sensor) => this.showCommandQueue(sensor),
          },
          {
            label: 'sensor.table.actions.purgeSensor',
            icon: 'delete',
            rule: () => this.authenticationService.isSuperAdmin(),
            actionFn: (sensor: Sensor) => this.purgeSensorBox(sensor),
          },
        ],
      },
      rowStyleFn: (sensor: Sensor) => {
        return {
          'background-color': this.getStateColor(sensor.state),
          border: 'solid 1.5px white',
        };
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'sensor.table.columns.id',
          width: '10%',
          sortable: true,
          hidden: !isSuperAdmin,
        },
        {
          key: 'name',
          displayName: 'sensor.table.columns.name',
          width: isSuperAdmin ? '45%' : '55%',
          sortable: true,
          styleFn: (sensor: Sensor) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (sensor: Sensor) =>
            this.router.navigate([
              '/dashboard',
              'sensors',
              'detail',
              sensor.id,
            ]),
        },
        {
          key: 'value',
          displayName: 'sensor.table.columns.value',
          width: '10%',
          sortable: true,
          valueTransform: (sensor: Sensor) =>
            sensor.sensors[sensor.primarySensor]
              ? Math.round(
                  sensor.sensors[sensor.primarySensor]?.currentDataValue,
                ) +
                ' ' +
                sensor.sensors[sensor.primarySensor].unit
              : '',
        },
        {
          key: 'lastValue',
          displayName: 'sensor.table.columns.lastValue',
          width: '20%',
          sortable: true,
          valueTransform: (sensor: Sensor) =>
            sensor.sensors[sensor.primarySensor]
              ? moment(
                  sensor.sensors[sensor.primarySensor]?.currentDataTimestamp,
                ).format('DD.MM.YYYY HH:mm')
              : '',
        },
        {
          key: 'state',
          displayName: 'sensor.table.columns.state',
          width: '15%',
          sortable: true,
          valueTransform: (sensor: Sensor) => `sensor.state.${sensor.state}`,
          filterType: TableFilterType.SELECT,
          filterValues: this.getStates(),
        },
      ],
    };
  }

  getStates(): { label: string; value: string }[] {
    const states = [];
    for (const state of Object.values(AlarmLevelStates).filter(
      (value) => typeof value === 'number',
    )) {
      states.push({
        label: `sensor.state.${state}`,
        value: state,
      });
    }
    return states;
  }

  getStateColor(state: AlarmLevelStates) {
    switch (state) {
      case AlarmLevelStates.NORMAL:
        return '#c8d89c';
      case AlarmLevelStates.LIMIT_1:
      case AlarmLevelStates.ERROR:
      case AlarmLevelStates.DEFECTIVE:
      case AlarmLevelStates.DISABLED:
        return 'rgba(255, 171, 49, 0.4)';
      case AlarmLevelStates.LIMIT_2:
      case AlarmLevelStates.LIMIT_3:
      case AlarmLevelStates.LIMIT_4:
      case AlarmLevelStates.LIMIT_5:
        return '#ffc7cc';
      case AlarmLevelStates.PLANNED:
      default: // No known state is active
        return 'rgba(204, 204, 204, 0.6)';
    }
  }

  showSensorDetail(sensor: Sensor) {
    this.setView(sensor);
    this.router.navigate(['/dashboard', 'sensors', 'detail', sensor.id]);
  }

  showSensorEdit(sensor: Sensor) {
    this.setView(sensor);
    this.router.navigate([
      '/dashboard',
      'sensors',
      'detail',
      sensor.id,
      'edit-sensor',
    ]);
  }

  showCommandQueue(sensor: Sensor) {
    this.setView(sensor);
    this.router.navigate([
      '/dashboard',
      'sensors',
      'detail',
      sensor.id,
      'command-queue',
    ]);
  }

  adminBox(sensor: Sensor) {
    this.setView(sensor);
    this.router.navigate([
      '/dashboard',
      'sensors',
      'detail',
      sensor.id,
      'admin-sensor',
    ]);
  }

  private setView(sensor: Sensor) {
    this.store.dispatch(
      new MapActions.SetView(latLng(Number(sensor.lat), Number(sensor.lon))),
    );
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  replaceBox(sensorBox: Sensor) {
    const _this = this;
    const dialogOptions: MatDialogConfig = {
      data: {
        sensorBoxId: sensorBox.id,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(ReplaceDeviceDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        // _this.reloadAlarmGroups();
      });
  }

  createBox() {
    let id = this.store.selectSnapshot(GuiState.selectedCustomer).id;
    const dialogOptions: MatDialogConfig = {
      data: {
        customerId: id,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(CreateSensorBoxDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        // TODO: List of SensorBoxes should be reloaded (mybe by WebSocket-Event)
      });
  }

  purgeSensorBox(sensorBox: Sensor) {
    const _this = this;

    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie den Standort ' +
        sensorBox.name +
        ' (' +
        sensorBox.id +
        ') wirklich vollständig löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.noyseeService.purgeSensorBox(sensorBox.id).then((result) => {
            this.toastHelper.openSnackBar(
              'Standort ' + sensorBox.name + ' vollständig gelöscht!',
              'SUCCESS',
            );
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
