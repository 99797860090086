import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../../../../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductListComponent } from './productList/productList.component';
import { BillingRoutingModule } from './billing-routing.module';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { AddDeliveryDialogComponent } from './addDelivery-modal/addDeliveryDialog.component';
import { DeliveryDetailComponent } from './deliveryDetail/deliveryDetail.component';
import { ProductDetailComponent } from './productDetail/productDetail.component';

@NgModule({
  declarations: [
    ProductListComponent,
    DeliveriesComponent, 
    AddDeliveryDialogComponent,
    DeliveryDetailComponent,
    ProductDetailComponent,
  ],
  imports: [
    MatDialogModule,
    ImageCropperModule,
    CommonModule,
    BillingRoutingModule,
    MatButtonModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    MatTooltipModule,
    TranslateModule.forChild(),
  ],
  providers: [
    TranslatePipe
  ]
})
export class BillingModule {
}
