<div class="header">
  <img src="/assets/img/netzebw-logo.jpg" height="60">
</div>
<div class="stage-blue">
  <div class="content">
    <h1 class="headline" appTranslate="static.impressum.headerLabel"></h1>
    <strong class="subline-strong" appTranslate="static.impressum.subheader"></strong>
  </div>
</div>

<div class="module">
  <div class="content">
    <h3 appTranslate="static.impressum.content.headline"></h3>

    <div class="content-block">
      <p>
        <strong appTranslate="static.impressum.content.company.name"></strong><br>
        <span appTranslate="static.impressum.content.company.address">
        </span>
      </p>

      <p>
        <strong appTranslate="static.impressum.content.supervisor.headline"></strong><br>
        <span appTranslate="static.impressum.content.supervisor.name"></span>
      </p>

      <p>
        <strong appTranslate="static.impressum.content.managing-director.headline"></strong><br>
        <span appTranslate="static.impressum.content.managing-director.name"></span>
      </p>
      <p>
        <strong appTranslate="static.impressum.content.contact.headline"></strong><br>
        <span appTranslate="static.impressum.content.contact.information"></span>
      </p>
    </div>
  </div>
</div>
<div class="footer" appTranslate="general.copyright">
</div>
