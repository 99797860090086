import {
  LookupEntry,
  LookupEntryWithState,
  AlarmLevelStates,
} from '../../../models/apiResults';

export class SensorBoxAlarmGroup {
  id: number;
  alarmLevel: number;
  alarmGroup: LookupEntry;
  alarmRule: LookupEntry;
  active: boolean;
  boxName: string;
  groupName: string;
}

export enum AlarmGroupMemberType {
  USER = 'user',
  ALARMCHANNEL = 'alarmChannel',
}

export class AlarmGroupMember {
  id: number;
  name: string;
  active: boolean;

  memberType: AlarmGroupMemberType;

  email: boolean;
  sms: boolean;
  phone: boolean;
  voice: boolean;

  userId: number;
  alarmChannelId: number;
  alarmGroupId: number;

  alarmMessageTemplateId: number;
  clearMessageTemplateId: number;
}

export class AlarmGroup {
  id: number;
  name: string;
  active: boolean;
  description: string;
  alarming:boolean;
  clearing: boolean;

  members: AlarmGroupMember[];
  customerId: number;
}

export class AlarmRule {
  id: number;
  name: string;
  active: boolean;
  currentAlarmLevel: AlarmLevelStates;
  description: string;
  definition: string;

  alarmGroups: AssignedAlarmGroup[];

  alarmMessageTemplate: LookupEntry;
  clearMessageTemplate: LookupEntry;

  sourceSensorBoxes: LookupEntryWithState[];

  customerId: number;
}

export class AssignedAlarmGroup {
  id: number;
  alarmLevel: number;
  sensorBox: LookupEntry;
  active: boolean;
  alarmGroup: AlarmGroup;
  alarmRule: AlarmRule;
}

/*
Sensor Status:
public static final int SENSORSTATUS_LIMIT5 = 5; // ?
public static final int SENSORSTATUS_LIMIT4 = 4; // ?
public static final int SENSORSTATUS_LIMIT3 = 3; // ?
public static final int SENSORSTATUS_LIMIT2 = 2; // alarm
public static final int SENSORSTATUS_LIMIT1 = 1; // warnung
public static final int SENSORSTATUS_NORMAL = 0; // normal
public static final int SENSORSTATUS_ERROR = -1; // ?
public static final int SENSORSTATUS_SERVICE = -2; // ?
public static final int SENSORSTATUS_OFFLINE = -3; // ?
public static final int SENSORSTATUS_NEW = -4; // in planung
*/
