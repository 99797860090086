import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { LookupService } from '../../../../../services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Product } from 'src/app/models/billing';
import { BillingService } from 'src/app/services/billing';
import { GridState } from 'src/app/shared/data-table/data-table.model';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-product-detail',
  templateUrl: './productDetail.component.html',
  styleUrls: ['./productDetail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;

  delivery: Product;
  customerList: LookupEntry[];
  productList: Product[];

  submitted: boolean;
  crudForm: FormGroup;

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  ctx: GridState = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private store: Store,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private billingService: BillingService,
    private lookupService: LookupService,
  ) {
    this.delivery = new Product();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      dmat: new FormControl('', []),
      price: new FormControl('', []),
      billingInterval: new FormControl('', []),
      contractPeriodBase: new FormControl('', []),
      contractPeriodExtension: new FormControl('', []),
      noticePeriod: new FormControl('', []),
      billingMethod: new FormControl('', []),
      maueDeviceModel: new FormControl({ value: '', disabled: true }, []),
      mappingError: new FormControl({ value: '', disabled: true }, []),
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';
  }

  ngOnInit(): void {
    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit' || this.mode == 'view') {
      this.loadProduct();
    } else {
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadProduct();
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {
    this.crudForm.patchValue({
      name: this.delivery.name,
      dmat: this.delivery.dmat,
      description: this.delivery.description,
      price: this.delivery.price,
      billingInterval: this.delivery.billingInterval,
      contractPeriodBase: this.delivery.contractPeriodBase,
      contractPeriodExtension: this.delivery.contractPeriodExtension,
      noticePeriod: this.delivery.noticePeriod,
      maueDeviceModel: this.delivery.maueDeviceModel,
      billingMethod: this.delivery.billingMethod,
      mappingError: this.delivery.mappingError==null ? null : this.delivery.mappingError,
    });

    if (this.mode == 'view') {
      this.crudForm.disable();
    }
  }

  loadProduct() {
    const url = this.route.snapshot.params;

    this.billingService.getProduct(url.id).then((data) => {
      this.delivery = data;
      this.dataToForm();
    });
  }

  get f() {
    return this.crudForm.controls;
  }

  // toggleActive() {
  //   const _this = this;
  //   _this.exportFile.active = !_this.exportFile.active;
  //   _this.crudForm.patchValue({
  //     active: _this.exportFile.active,
  //   });
  // }

  async saveProduct() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.delivery.name = this.f.name.value;
    this.delivery.description = this.f.description.value;
    this.delivery.dmat = this.f.dmat.value;

    this.delivery.price = this.f.price.value;
    this.delivery.billingInterval = this.f.billingInterval.value;

    this.delivery.contractPeriodBase = this.f.contractPeriodBase.value;
    this.delivery.contractPeriodExtension =
      this.f.contractPeriodExtension.value;
    this.delivery.noticePeriod = this.f.noticePeriod.value;
    this.delivery.billingMethod= this.f.billingMethod.value;

    try {
      this.billingService
        .saveProduct(this.delivery)
        .then((result) => {
          this.delivery = result;
          this.dataToForm();
          this.toastHelper.openSnackBar('Produkt gespeichert!', 'SUCCESS');
          this.goBack();
        })
        .catch((error) => {
          this.toastHelper.openSnackBar(
            'Fehler beim Speichern des Produktes!',
            'ERROR',
          );
        });
    } catch (e) {
      this.toastHelper.openSnackBar(
        'Fehler beim Speichern des Produktes!',
        'ERROR',
      );
    }
  }

  goBack() {
    this.router.navigate([
      '/dashboard',
      'admins',
      'billing',
      'products',
      'overview',
    ]);
  }

  exportFileTypes(): { label: string; value: string }[] {
    return [];
  }

  setBillingInterval($event: any) {
    this.delivery.billingInterval = $event.target.value;
  }
}
