import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerDetailComponent} from './detail/detail.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from 'src/app/shared/shared.module';
import {CustomerListComponent} from './overview/customerList.component';
import {CustomersRoutingModule} from './customers-routing.module';
import { AddAgsDialogComponent } from './addAgs-modal/addAgsDialog.component';

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent,
    AddAgsDialogComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatInputModule,
    LeafletModule,
    MatDialogModule,
    TranslateModule,
    MatSelectModule,
    SharedModule,
  ]
})
export class CustomersModule {
}
