import { Component } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { omit } from 'lodash';
import { FormDebugHelper } from '../../../helpers/form-debug.helper';

type DateRange =
  | {
      begin: '*';
      end: '*';
    }
  | {
      begin: Date;
      end: Date;
    };

@Component({
  selector: 'date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeSelectComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DateRangeSelectComponent,
    },
  ],
})
export class DateRangeSelectComponent
  implements ControlValueAccessor, Validator
{
  maxDate = new Date();
  onTouched = () => {};
  onChanged: (date: DateRange) => void = () => {};

  formGroup = new FormGroup({
    beginDate: new FormControl('*', [Validators.required]),
    endDate: new FormControl('*', [Validators.required]),
  });

  writeValue(value: DateRange): void {
    this.formGroup.patchValue({
      beginDate: value.begin,
      endDate: value.end,
    });
    this.formGroup.updateValueAndValidity();
  }

  get isPermanent() {
    return (
      this.formGroup.get('beginDate').value == '*' &&
      this.formGroup.get('endDate').value == '*'
    );
  }

  dateFilter = (d: Date | null): boolean => {
    return true;
  };

  registerOnChange(onChange: (date: DateRange) => void): void {
    this.onChanged = onChange;
    this.formGroup.controls.endDate.valueChanges.subscribe((value) =>
      this.callOnChange(value),
    );
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  callOnChange(end: Date): void {
    this.onChanged({
      begin: this.formGroup.value.beginDate,
      end,
    });
  }

  markAsTouched(): void {
    this.formGroup.markAllAsTouched();
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    // FIXME: Implement custom date instead
    const errors = FormDebugHelper.findInvalidControls(this.formGroup);
    const mapped = errors
      .map((error) => omit(error, ['error.matDatepickerParse']))
      .filter((o) => Object.keys(o.error).length > 0);

    return mapped.length > 0 ? { invalid: mapped } : null;
  }
}
