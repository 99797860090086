import { Component, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import { AlarmGroupService } from '../../../../state/noysee/alarmGroup.service';
import { AlarmGroup } from '../../../../state/noysee/models/alarmGroup';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { Observable } from 'rxjs';
import { Role } from '../../../../models/role.enum';
import { first, map } from 'rxjs/operators';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-alarmGroups-overview',
  templateUrl: './groupList.component.html',
  styleUrls: ['./groupList.component.scss'],
})
export class GroupListComponent implements OnInit {
  dataTableOptions$: Observable<DataTableOptions<AlarmGroup>>;
  list: AlarmGroup[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  constructor(
    private alarmGroupService: AlarmGroupService,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.dataTableOptions$ = this.authenticationService
      .hasRole$(Role.SUPERADMIN)
      .pipe(
        first(),
        map((isSuperAdmin) => ({
          title: 'alarmGroup.headerLabel',
          titleSize: TitleSize.H1,
          filter: true,
          tableActions: [
            {
              label: 'alarmGroup.table.actions.add',
              rule: () =>
                this.authenticationService.isSuperAdmin() ||
                this.authenticationService.isAdmin(),
              actionFn: () =>
                this.router.navigate(['/dashboard', 'alarmGroups', 'add'], {
                  queryParams: { ctx: this.getGridState() },
                }),
            },
          ],
          rowActions: {
            type: TableActionType.MENU,
            actionList: [
              {
                label: 'alarmGroup.table.actions.edit',
                icon: 'app-pencil',
                actionFn: (alarmGroup: AlarmGroup) =>
                  this.editAlarmGroup(alarmGroup.id),
              },
              {
                label: 'alarmGroup.table.actions.delete',
                icon: 'app-view',
                actionFn: (alarmGroup: AlarmGroup) =>
                  this.deleteAlarmGroup(alarmGroup.id),
              },
            ],
          },
          // rowStyleFn: (alarmGroup: AlarmGroup) => {
          //   return {
          //     'background-color': this.getStateColor(sensor.state),
          //     'border': 'solid 1.5px white'
          //   };
          // },
          columnDef: [
            {
              key: 'id',
              displayName: 'alarmGroup.table.columns.id',
              width: '10%',
              sortable: true,
              hidden: !isSuperAdmin,
            },
            {
              key: 'name',
              displayName: 'alarmGroup.table.columns.name',
              width: '70%',
              sortable: true,
              styleFn: (alarmGroup: AlarmGroup) => {
                return {
                  cursor: 'pointer',
                  color: '#0e0ec4',
                  'font-weight': 'bold',
                };
              },
              actionFn: (alarmGroup: AlarmGroup) =>
                this.router.navigate([
                  '/dashboard',
                  'alarmGroups',
                  alarmGroup.id,
                  'edit',
                ]),
            },
            {
              key: 'state',
              displayName: 'alarmGroup.table.columns.state',
              width: '20%',
              sortable: true,
              valueTransform: (alarmGroup: AlarmGroup) => {
                if (alarmGroup.active) {
                  return (
                    `<span class="toggle-button wide` +
                    (alarmGroup.alarming ? ' active' : '') +
                    `">Warnung</span>&nbsp;` +
                    `<span class="toggle-button wide` +
                    (alarmGroup.clearing ? ' active' : '') +
                    `">Entwarnung</span>&nbsp;`
                  );
                } else {
                  return `alarmGroup.state.${alarmGroup.active}`;
                }
              },
            },
          ],
        })),
      );

    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      this.loadList();
    });

    this.loadList();
  }

  async loadList() {
    this.list = await this.alarmGroupService.getList();
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  editAlarmGroup(alarmGroupId: number) {
    this.router.navigate(['/dashboard', 'alarmGroups', alarmGroupId, 'edit']);
  }

  deleteAlarmGroup(alarmGroupId: number) {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'alarmGroup.modal.delete',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.alarmGroupService.delete(alarmGroupId);
          this.toastHelper.openSnackBar('Alarmgruppe gelöscht!', 'SUCCESS');
          this.list = await this.alarmGroupService.getList();
          this.toastHelper.openSnackBar(
            'Alarmregel erfolgreich gelöscht!',
            'SUCCESS',
          );
          this.alarmGroupService.delete(alarmGroupId).then((result) => {});
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
