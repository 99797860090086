import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatPaginator, MatPaginatorDefaultOptions, MatPaginatorIntl} from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges, AfterViewChecked {
  paginator: MatPaginator;

  currentPage = 0;
  pageSizeArray: any[] = [];

  @Input()
  pageSize = 10;

  constructor(private paginatorIntl: MatPaginatorIntl, private changeDetect: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    const paginatorOptions: MatPaginatorDefaultOptions = {
      pageSize: this.pageSize,
    };
    this.paginator = new MatPaginator(this.paginatorIntl, this.changeDetect, paginatorOptions);
    this.paginator.ngOnInit();
  }

  ngAfterViewChecked(): void {
    const pageCount = this.paginator.getNumberOfPages();
    if (pageCount !== this.pageSizeArray.length) {
      this.pageSizeArray = new Array(pageCount);
      this.changeDetect.detectChanges();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.paginator) {
      if ('pageSize' in changes) {
        this.paginator.pageSize = this.pageSize;
        if (this.paginator.pageIndex !== this.currentPage) {
          this.jumpToPage(this.currentPage);
        }
      }
    }
  }

  restorePage() {
    this.jumpToPage(this.currentPage);
  }

  jumpToPage(index) {
    const prevIndex = this.paginator.pageIndex;
    this.currentPage = index;
    this.paginator.pageIndex = index;
    this.paginator.page.emit({
      length: this.paginator.getNumberOfPages(),
      pageIndex: index,
      pageSize: this.paginator.pageSize,
      previousPageIndex: prevIndex
    });
  }

  nextPage() {
    this.currentPage++;
    this.paginator.nextPage();
  }

  previousPage() {
    this.currentPage--;
    this.paginator.previousPage();
  }

  firstPage() {
    this.currentPage = 0;
    this.paginator.firstPage();
  }

  lastPage() {
    this.currentPage = this.paginator.getNumberOfPages() - 1;
    this.paginator.lastPage();
  }
}
