<div class="table-footer">
  <app-pagination [gridApi]="gridApi"></app-pagination>
  <div>
    <table-export-button
      *ngIf="!!csvExportParams"
      text="table.export.csv"
      (export)="exportToCsv(csvExportParams)"
    ></table-export-button>
    <table-export-button
      *ngIf="!!pdfExportParams"
      text="table.export.pdf"
      (export)="exportToPdf(pdfExportParams)"
    ></table-export-button>
  </div>
</div>
