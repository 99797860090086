<div *ngIf="!hideHeader" id="microsite-header-container">
  <div class="header-block-left">
    <div class="customer-logo">
      <img [src]="customerLogoImage" alt="Wappen" />
    </div>
    <div class="customer-name">
      {{ customerName }}
    </div>
    <div *ngIf="isDev" class="is-dev">
      <img
        src="../../../../../assets/img/exclamation-triangle-solid.svg"
        alt="Is Dev environment"
      />
    </div>
  </div>
  <div class="header-block-right">
    <a class="header-logo" routerLink="">
      <img [src]="logoUrl" />
    </a>
  </div>
</div>
<div *ngIf="mode == 'table'" class="ch-margin-top-24">
  <app-data-table
    [dataTableOptions]="dataTableOptions"
    [state]="gridState"
    [data]="list$ | async"
  ></app-data-table>
</div>
<div *ngIf="mode == 'tiles'" class="ch-margin-top-24">
  <div class="tile-container">
    <div *ngFor="let item of list$ | async" class="tile">
      <div class="tile-label">
        <span class="state-{{ getStateText(item.state) }} active">
          <strong>{{ "sensor.state." + item.state | translate }}</strong>
        </span>
      </div>
      <div class="tile-title">
        <div class="tile-title-left">
          <span
            class="tile-title-left-headline linkToSensor"
            (click)="showSensorDetail(item)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="tile-values-container" style="width: 300px">
        <div class="tile-values-row"></div>
        <div class="tile-values-row">
          <span class="limit1">
            <strong appTranslate="sensor.values.current"></strong>:
            {{
              item.sensors[item.primarySensor]?.currentDataValue
                | number: "1.1-1"
            }}
            {{ item.sensors[item.primarySensor]?.unit }} </span
          ><span id="tile-current-trend"></span>
        </div>
        <div class="tile-values-row">
          <strong appTranslate="sensor.information.last-value"></strong>:
          {{
            item.sensors[item.primarySensor]?.currentDataTimestamp
              | date: "dd.MM.yyyy HH:mm"
          }}
        </div>
        <div class="tile-values-row">
          <strong appTranslate="sensor.information.source"></strong>:
          {{ item.datasource }}
        </div>
      </div>
    </div>
  </div>
</div>
