import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthenticationService,
  FeatureSet,
} from '../../../../services/authentication.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';
import { UsersService } from '../../../../services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpComponent } from '../help/help.component';
import { TableStateService } from '../../../../services/tableState.service';
import { SafeUrl } from '@angular/platform-browser';
import { PasswordModalComponent } from '../../users/crud-user/password-modal/password-modal.component';
import { ErrorHandler } from '../../../../models/errorHandler';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Customer } from '../../../../models/customerList';
import { LookupService } from '../../../../services/lookup.service';
import { ImageService } from '../../../../services/image.service';
import { LookupEntryWithState } from 'src/app/models/apiResults';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { GuiActions } from 'src/app/state/dashboard/gui.action';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  logoUrl: any;
  customerLogoUrl: any;
  hideLogo: boolean;
  isGuest: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isUserLoggedIn: boolean;
  currentCustomer: Customer;
  availableCustomers: LookupEntryWithState[];
  authUserSubscription: Subscription;
  isDev: boolean;
  openNotificationCount: number;
  openError: boolean;

  email: string;
  phone: string;

  profileImage: SafeUrl | string;

  featureSet$: Observable<FeatureSet> =
    this.authenticationService.getFeatureSet();
  featureSetSubscription: Subscription;

  isStatisticsVisible: boolean;
  isAlarmsVisible: boolean;
  isFeatureAlarmGroups: boolean;
  isFeatureAlarmRules: boolean;
  isFeatureAlarmChannels: boolean;

  statisticsVisible$: Observable<boolean> = this.featureSet$.pipe(
    map((features) =>
      features.hasAccess('featureReports', 'reports.base', null),
    ),
  );

  constructor(
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private tableStateService: TableStateService,
    private toastHelper: ToastHelper,
    private lookupService: LookupService,
    private imageService: ImageService,
  ) {
    this.logoUrl = environment.logoUrl;
    this.customerLogoUrl = environment.customerLogoUrl;
    this.hideLogo = false;

    this.isGuest = false;
    this.isAdmin = false;
    this.isSuperAdmin = false;
    this.isUserLoggedIn = false;
    this.isDev = !environment.production;

    this.openNotificationCount = 0;
    this.openError = false;

    if (!!environment?.support?.email) {
      this.email = environment.support.email;
    }
    if (!!environment?.support?.phone) {
      this.phone = environment.support.phone;
    }

    this.profileImage = environment.avatarPlaceholderUrl;
  }

  async ngOnInit() {
    this.authUserSubscription =
      this.authenticationService.authenticatedUserObservable.subscribe(
        async (user) => {
          if (user) {
            this.isSuperAdmin = this.authenticationService.isSuperAdmin();
            this.isAdmin = this.authenticationService.isAdmin();
            this.isGuest = this.authenticationService.isGuest();
            this.isUserLoggedIn = true;
            this.hideLogo = !!user?.externalId;

            if (this.isSuperAdmin) {
              this.availableCustomers =
                await this.lookupService.getCustomerList();
            }
          }
        },
      );
    this.userService.usersObservable.subscribe(async (users) => {
      this.profileImage = await this.imageService.getUserImage(
        this.authenticationService.authenticatedUser.id,
      );
    });
    this.notificationService.open.subscribe((notifications) => {
      const openNotifications = notifications;
      this.openNotificationCount = openNotifications
        ? openNotifications.length
        : 0;
      this.openError = openNotifications
        ? openNotifications.find(
            (notification) => notification.category === 'ERROR',
          )
        : false;
    });
    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      this.currentCustomer = customer;
    });

    this.featureSetSubscription = this.featureSet$.subscribe((featureSet) => {
      this.isFeatureAlarmGroups = featureSet.hasAccess(
        'featureAlarmGroups',
        'alarmGroups.base',
        null,
      );

      featureSet.hasPrivilege('alarmGroups.base', 'read');

      this.isFeatureAlarmRules = featureSet.hasAccess(
        'featureAlarmRules',
        'alarmRules.base',
        'read',
      );
      this.isFeatureAlarmChannels =
        featureSet.hasOneOfFeature([
          'featureAlarmActions',
          'featureAlarmGroups',
        ]) && featureSet.hasPrivilege('alarmChannels.base', 'read');

      this.isAlarmsVisible =
        this.isFeatureAlarmGroups || this.isFeatureAlarmRules;
    });
  }

  ngOnDestroy() {
    this.featureSetSubscription.unsubscribe();
  }

  openPrimaryPanel() {
    if (!this.store.selectSnapshot(GuiState.primaryPanelOpen)) {
      this.store.dispatch(new GuiActions.SetPrimaryPanelVisibility(true));
    }
  }

  logout() {
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: this.router.url },
    });
  }

  showHelp() {
    this.dialog.open(HelpComponent);
  }

  editUser() {
    this.tableStateService.getGridState().subscribe((val) => {
      if (val) {
        this.router.navigate(['/dashboard', 'users', 'edit-self'], {
          queryParams: { ctx: val },
        });
      } else {
        const routerUrl = this.router.url;
        if (routerUrl.startsWith('/users/edit-self')) {
          return;
        } else {
          const state = {
            url: routerUrl.includes('?ctx')
              ? routerUrl.split('?')[0]
              : routerUrl,
          };
          this.router.navigate(['/dashboard', 'users', 'edit-self'], {
            queryParams: { ctx: encodeURIComponent(JSON.stringify(state)) },
          });
        }
      }
    });
  }

  openPasswordDialog() {
    const dialogRef = this.dialog.open(PasswordModalComponent, {
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .subscribe(([oldPassword, password, confirmPassword]) => {
        if (oldPassword && password && confirmPassword) {
          this.authenticationService
            .updatePassword(oldPassword, password, confirmPassword)
            .then(() => {
              this.toastHelper.openSnackBar(
                'Passwort wurde erfolgreich geändert.',
                'SUCCESS',
              );
            })
            .catch((error) => {
              this.toastHelper.openSnackBar(
                ErrorHandler.getText(error.message),
                'ERROR',
              );
            });
        }
      });
  }

  selectCustomer(target: any): void {
    if (this.authenticationService.isSuperAdmin()) {
      this.store.dispatch(new GuiActions.SetCustomer(+target.value));
    }
  }
}
