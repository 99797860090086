<div class="primary-panel-small container-fluid">
  <div id="greeting-container" *ngIf="mode === 'start'">
    <div class="row">
      <div class="col-12">
        <h1>
          <span appTranslate="notification.welcome"></span>
          <span *ngIf="currentUser">, {{ currentUser.name }}</span>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="overview-last-login col-12">
        <p class="light-grey-text">
          <strong appTranslate="notification.lastLogin"></strong>
          {{ currentUser.lastLogin | date: "EEEE, d.M.yyyy, h:mm" }}
        </p>
      </div>
    </div>
  </div>

  <!-- Currently not required anymore
  <div id="superadmin-container" *ngIf="mode === 'start' && isSuperAdmin">
    <div class="row ch-margin-top-40 ">
      <div class="col-12">
        <h2>Superadmin Bereich</h2>
      </div>
    </div>
  </div>
  -->

  <div id="notification-container">
    <div *ngIf="mode !== 'start'" class="row">
      <div class="col-8">
        <h1 appTranslate="notification.headerLabel"></h1>
      </div>
    </div>
    <div
      *ngIf="mode === 'start' && notifications && notifications.length > 0"
      class="row ch-margin-top-40"
    >
      <div class="col-12">
        <h2 appTranslate="notification.headerLabel"></h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div *ngFor="let notification of notifications; index as i">
          <div
            *ngIf="notification.state !== 'RESOLVED'"
            (click)="goToNotification(notification)"
            class="notification-container"
            [class.notification-warning]="notification.category === 'WARNING'"
            [class.notification-error]="notification.category === 'ERROR'"
          >
            <div class="row">
              <div class="col-2 notification-icon-col">
                <mat-icon
                  class="blue-icon icon-notification"
                  [ngClass]="getClass(notification)"
                  >{{ this.getStateIcon(notification) }}</mat-icon
                >
              </div>
              <div class="col-6">
                <p *ngIf="!!notification.sensorBox" class="notification-name">
                  {{ notification.sensorBox.name }}
                </p>
                <p *ngIf="!!notification.user" class="notification-name">
                  {{ notification.user.name }}
                </p>
                <p class="notification-description ch-margin-top-4">
                  {{ notification.comment }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4">
                <p class="notification-date">
                  {{ notification.createdAt | date: "dd.MM.yyyy - HH:mm" }}
                </p>
              </div>
              <div class="col-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
