import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/alarmChannels.component';
import { AlarmChannelDetailComponent } from './detail/detail.component';

const menuEntries = [
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'add',
    component: AlarmChannelDetailComponent,
    data: {
      mode: 'add'
    }
  },
  {
    path: ':id',
    children: [
      {
        path: 'edit',
        component: AlarmChannelDetailComponent,
        data: {
          mode: 'edit'
        }
      },
    ],

  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlarmChannelsRoutingModule {
}
