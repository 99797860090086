import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { UsersService } from '../../../../../services/users.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GridState } from 'src/app/shared/data-table/data-table.model';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { RenderedInfo, SensorBox } from 'src/app/state/noysee/models/sensorBox';
import { EditRenderedInfoModalComponent } from '../edit-renderedInfo-modal/editRenderedInfo-modal.component';

@Component({
  selector: 'app-sensorBoxDescription',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  set sensor(sensor$: Observable<SensorBox>) {
    this.sensorSubscription?.unsubscribe();
    this.sensorSubscription = sensor$.subscribe(async (sensor) => {
      if (sensor) {
        this.currentSensor = sensor;

        this.sensorName = sensor.name;
        this.currentSensorId = sensor.id;

        if (sensor.sensors[sensor.primarySensor]) {
          this.limit1 = sensor.sensors[sensor.primarySensor].limit1;
          this.limit2 = sensor.sensors[sensor.primarySensor].limit2;
          this.unit = sensor.sensors[sensor.primarySensor].unit;
        }

        this.noyseeService
          .getRenderedInfo(this.currentSensor.id)
          .then((info) => {
            this.renderedInfo = info;
          });

        // this.reloadAlarmGroups();
      }
    });
  }

  currentSensorId: number;
  currentSensor: SensorBox;

  parent: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  sensorName: string;
  sensorSubscription: Subscription;

  gridState: GridState = null;

  limit1: number;
  limit2: number;
  unit: string;

  renderedInfo: RenderedInfo;

  constructor(
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private usersService: UsersService,
    private dataTableStateService: DataTableStateService,
    private noyseeService: NoyseeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.currentSensorId = null;
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.sensorSubscription?.unsubscribe();
  }

  editPublic() {
    const dialogOptions: MatDialogConfig = {
      data: {
        id: this.currentSensorId,
        field: 'description',
        renderedInfo: this.renderedInfo,
      },
      maxWidth: 600,
    };

    this.matDialog.open(EditRenderedInfoModalComponent, dialogOptions);
  }

  editInternal() {
    const dialogOptions: MatDialogConfig = {
      data: {
        id: this.currentSensorId,
        field: 'internalDescription',
        renderedInfo: this.renderedInfo,
      },
      maxWidth: 600,
    };

    this.matDialog.open(EditRenderedInfoModalComponent, dialogOptions);
  }
}
