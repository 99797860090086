import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import Timeout = NodeJS.Timeout;
import { ApplicationContextService } from './application-context.service';
import { AuthenticationService } from './authentication.service';
import { withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private readonly _dashboardWebSocket$: BehaviorSubject<WebSocket> =
    new BehaviorSubject<WebSocket>(null);
  private _disconnectTimeout: Timeout;
  private _reconnectOffset = 10;

  private webSocketUrl;

  constructor(
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
  ) {
    if (
      environment.dashboardWebSocketUrl &&
      environment.dashboardWebSocketUrl.length > 0
    ) {
      this.webSocketUrl = environment.dashboardWebSocketUrl + '/';
    } else {
      const location = window.location;
      this.webSocketUrl =
        (location.protocol === 'https:' ? 'wss://' : 'ws://') +
        location.host +
        '/api/v1/onChange/';
    }

    this.applicationContextService.isSyncActive
      .pipe(withLatestFrom(this.authenticationService.authTokenObservable))
      .subscribe(([active, token]) => {
        if (active) {
          let keepAliveInterval = null;
          this._dashboardWebSocket$.next(
            new WebSocket(this.webSocketUrl + token),
          );

          this.dashboardWebSocket.subscribe((socket) => {
            // console.log('Socket changed, value:', socket);
            if (socket) {
              socket.addEventListener('open', () => {
                // console.log('Websocket opened, clearing timeout:', this._disconnectTimeout);
                clearTimeout(this._disconnectTimeout);
                this._reconnectOffset = 10;

                // Send heartbeat to keep connection alive
                keepAliveInterval = setInterval(() => {
                  socket.send('heartbeat');
                }, 45000);
              });

              socket.addEventListener('error', (err) => {
                // Called if WebSocket API signals some kind of error
                // console.log('Websocket Error: ', err);
              });

              socket.addEventListener('close', (event) => {
                // console.log('Websocket closed. reason: ', event.reason);
                clearInterval(keepAliveInterval);
                this._reconnect(this._reconnectOffset);
              });
            }
          });
        } else {
          this._resetWebsocket();
        }
      });
  }

  private _reconnect(offset: number): void {
    // console.log('Socket is closed. Reconnect will be attempted in ' + offset + 's.');
    this._disconnectTimeout = setTimeout(() => {
      // console.log('Timeout running', this._disconnectTimeout);
      this._resetWebsocket();
      this._reconnectOffset += 5;
      var authToken = localStorage.getItem('authToken');

      this._dashboardWebSocket$.next(
        new WebSocket(this.webSocketUrl + authToken),
      );

      this._reconnect(this._reconnectOffset);
    }, 1000 * offset);
    // console.log('Current disconnect timeout:', this._disconnectTimeout);
  }

  private _resetWebsocket(): void {
    console.log('Websocket removed');
    const websocket = this._dashboardWebSocket$.value;
    if (websocket) {
      websocket.removeAllListeners();
      websocket.close();
      this._dashboardWebSocket$.next(null);
    }
  }

  get dashboardWebSocket(): Observable<WebSocket> {
    return this._dashboardWebSocket$.asObservable();
  }
}
