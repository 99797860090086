import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseCellRenderer } from '../util/interfaces';

export interface ActionMenuEntry {
  label: string;
  icon?: string;
  action: (args: any) => any;
  hide?: (args: any) => boolean;
}

export interface ActionMenuCellRendererParams
  extends ICellRendererParams,
    BaseCellRenderer {
  actions: ActionMenuEntry[];
}

@Component({
  template: `
    <div>
      <button
        *ngIf="show"
        mat-icon-button
        disableRipple
        [matMenuTriggerFor]="actionMenu"
        class="trigger"
        aria-label="open group menu"
      >
        <mat-icon color="primary">more_horiz</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu">
        <ng-container *ngFor="let entry of params.actions">
          <button
            *ngIf="!entry.hide || !entry.hide(params)"
            mat-menu-item
            (click)="entry.action(params)"
          >
            <mat-icon *ngIf="entry.icon" color="primary">{{
              entry.icon
            }}</mat-icon>
            <span [translate]="entry.label"></span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      div {
        display: flex;
        justify-content: end;
      }
      mat-icon {
        font-weight: bold;
        transform: scale(1.2);
      }
      button {
        display: flex;
        align-items: center;
      }
      button {
        justify-content: center;
      }
      button:not(.trigger) {
        justify-content: start;
      }
    `,
  ],
})
export class ActionMenuCellRenderer implements ICellRendererAngularComp {
  params: ActionMenuCellRendererParams;

  agInit(params: ActionMenuCellRendererParams): void {
    this.params = params;
  }

  refresh(params: ActionMenuCellRendererParams): boolean {
    this.params = params;
    return true;
  }

  get show(): boolean {
    return !!this.params.actions.length;
  }
}
