<div class="mat-dialog-title">
  <p>
    <strong>{{parentType + '.headerLabelSingular' | translate}} "{{parentName}}"
      {{'alarmGroup.dialog.headline.suffix' | translate}}</strong>
  </p>
</div>

<div class="form-field">
  <label for="parentName" [appTranslate]="parentType + '.headerLabelSingular'"></label>
  <input id="parentName" [(ngModel)]="parentName" disabled="true"/>
</div>

<div class="form-field" [class.error]="false">
  <label for="input-alarmLevel" appTranslate="alarmGroup.dialog.level.label"></label>
  <select [(ngModel)]="alarmLevel">
    <option [value]="null">-</option>
    <option value="-1" appTranslate="alarmGroup.dialog.level.-1"></option>
    <option value="1" appTranslate="alarmGroup.dialog.level.1"></option>
    <option value="2" appTranslate="alarmGroup.dialog.level.2"></option>
  </select>
</div>

<div class="form-field" [class.error]="false">
  <label for="input-alarmGroupId" appTranslate="alarmGroup.dialog.group"></label>
  <select [(ngModel)]="alarmGroupId">
    <option [value]="null">-</option>
    <option [ngValue]="entry.id" *ngFor="let entry of alarmGroupList">{{entry.name}}</option>
  </select>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="addAlarmGroup()" appTranslate="alarmGroup.dialog.actions.save"></button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()" appTranslate="alarmGroup.dialog.actions.cancel"></button>
</mat-dialog-actions>
