import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { User, UserType } from '../../../../models/user';
import { UsersService } from '../../../../services/users.service';
import { Router } from '@angular/router';
import { ApprovalDialogHelper } from '../../../../helpers/approval-dialog.helper';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ToastHelper } from '../../../../helpers/toast.helper';
import {
  DataTableColumnType,
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { Subscription } from 'rxjs';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { getRoles } from '../../../../models/role.enum';
import { TranslatePipe } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  dataTableOptions: DataTableOptions<User>;
  users: User[];
  private usersSubscription$: Subscription;

  params: any;

  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  constructor(
    private store: Store,
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private router: Router,
    private toastHelper: ToastHelper,
    private translatePipe: TranslatePipe,
    private approvalDialogHelper: ApprovalDialogHelper,
    private dataTableStateService: DataTableStateService,
  ) {}

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.gridState = this.router.getCurrentNavigation().extras
        .state as GridState;
    }
    this.dataTableOptions = {
      title: 'user.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'user.table.actions.invite',
          rule: () => this.authenticationService.isAdmin(),
          actionFn: () =>
            this.router.navigate(['/dashboard', 'users', 'invite-user'], {
              queryParams: { ctx: this.getGridState() },
            }),
        },
        {
          label: 'user.table.actions.add',
          rule: () => this.authenticationService.isSuperAdmin(),
          actionFn: () =>
            this.router.navigate(['/dashboard', 'users', 'add-user'], {
              queryParams: { ctx: this.getGridState() },
            }),
        },
        {
          label: 'user.table.actions.addApiKey',
          rule: () => this.authenticationService.isSuperAdmin(),
          actionFn: () =>
            this.router.navigate(['/dashboard', 'users', 'add-apiKey'], {
              queryParams: { ctx: this.getGridState() },
            }),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'user.table.actions.edit',
            icon: 'app-pencil',
            dataRule: (user: User) => user.userType === 'login',
            actionFn: (user: User) => this.showUserEdit(user),
          },
          {
            label: 'apiKey.table.actions.edit',
            dataRule: (user: User) => user.userType === 'apiKey',
            icon: 'app-pencil',
            actionFn: (user: User) => this.showApiKeyEdit(user),
          },
          {
            label: 'user.table.actions.delete',
            icon: 'app-delete',
            dataRule: (user: User) =>
              this.authenticationService.isSuperAdmin() ||
              (this.authenticationService.isAdmin() &&
                user.role &&
                !user.role.toLowerCase().includes('admin')),
            actionFn: (user: User) => this.deleteUser(user),
          },
        ],
      },
      columnDef: [
        {
          key: 'userType',
          displayName: 'user.table.columns.userType',
          width: '10%',
          type: DataTableColumnType.ICONLABEL,
          sortable: true,
          valueTransform: (user: User) => ({
            class: `user-type-${user.userType} user-state-${this.translatePipe.transform('user.state.' + user.state.toString(10))?.toLowerCase()}`,
            tooltip: `${this.translatePipe.transform('user.type.' + user.userType)} - ${this.translatePipe.transform('user.state.' + user.state.toString(10))}`,
          }),
          filterType: TableFilterType.SELECT,
          filterValues: this.getUserTypes(),
          filterFn: (user: User, arg) => user?.userType === arg,
        },
        {
          key: 'name',
          displayName: 'user.table.columns.name',
          width: '30%',
          sortable: true,
          filterFn: (user: User, arg) =>
            user.name.toLowerCase().includes(arg.toLowerCase()),
        },
        {
          key: 'role',
          displayName: 'user.table.columns.role',
          width: '20%',
          sortable: true,
          filterType: TableFilterType.SELECT,
          valueTransform: (user: User) => this.getRole(user),
          filterValues: getRoles(this.authenticationService),
          filterFn: (user: User, arg) => user?.role === arg,
        },
        {
          key: 'email',
          displayName: 'user.table.columns.email',
          width: '35%',
          sortable: true,
        },
      ],
    };
    // Add id column if superadmin
    if (this.authenticationService.isSuperAdmin()) {
      this.dataTableOptions.columnDef.push({
        key: 'id',
        displayName: 'user.table.columns.id',
        width: '5%',
        sortable: true,
      });
    }
    this.usersSubscription$ = this.userService.usersObservable.subscribe(
      (users: User[]) => {
        this.users = users.sort((a, b) => a.name?.localeCompare(b.name));
      },
    );

    this.store.select(GuiState.selectedCustomer).subscribe(() => {
      this.userService.changeFocusCustomer();
    });
  }

  ngOnDestroy() {
    this.usersSubscription$?.unsubscribe();
  }

  private getRole(user: User): string {
    if (user.role) {
      return this.translatePipe.transform(
        'user.roles.' + user.role.toLowerCase(),
      );
    } else {
      return '?';
    }
  }

  private getUserTypes(): any[] {
    const types = [];
    for (const userType of Object.values(UserType)) {
      types.push({
        label: this.translatePipe.transform('user.type.' + userType),
        value: userType,
      });
    }
    return types;
  }

  private showUserEdit(user: User): void {
    this.applicationContextService.selectedUserId = user.id;
    this.router.navigate(['/dashboard', 'users', user.id, 'edit-user'], {
      queryParams: { ctx: this.getGridState() },
    });
  }

  private showApiKeyEdit(user: User): void {
    this.applicationContextService.selectedUserId = user.id;
    this.router.navigate(['/dashboard', 'users', user.id, 'edit-apiKey'], {
      queryParams: { ctx: this.getGridState() },
    });
  }

  private deleteUser(user: User): void {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      this.translatePipe.transform('modals.delete-user.label'),
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.userService.deleteUser(user.id);
          this.toastHelper.openSnackBar(
            this.translatePipe.transform('modals.delete-user.success'),
            'SUCCESS',
          );
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }
}
