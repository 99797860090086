import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { CrudNoyseeDeviceComponent } from './crud-noyseeDevice/crud-noyseeDevice.component';
import { NoyseeDeviceDetailComponent } from './detail/detail.component';
import { DetailComponent } from '../../sensors/detail/detail/detail.component';

const menuEntries = [
  {
    icon: 'menu-icon-movements',
    path: 'values',
    tooltip: 'sensor.values.current'
  },
  {
    icon: 'menu-icon-schedule',
    path: 'history',
    tooltip: 'sensor.values.history',
    weatherStationOnly: true,
  },
  {
    icon: 'menu-icon-users',
    path: 'contacts',
    tooltip: 'sensor.contacts.headline'
  },
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'detail',
    children: [
      {
        path: ':sno',
        component: NoyseeDeviceDetailComponent,
        data: {
          breadcrumbLabel: 'noyseeDevice.table.actions.edit',
          mode: 'add'
        }
      }
    ]
  },
  {
    path: 'addNoyseeDevice',
    component: NoyseeDeviceDetailComponent,
    data: {
      breadcrumbLabel: 'noyseeDevice.table.actions.add',
      mode: 'add'
    }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoyseeDevicesRoutingModule {
}
