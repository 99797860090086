import { LookupEntry } from "@app/models/apiResults";

export enum AlarmChannelType {
  // USER = 'user',
  SLACK = 'slack',
  THREEMA = 'threema',
  WEBHOOK = 'webHook',
  TWITTER = 'twitter',
  DIVERA_NEWS = 'diveraNews',
  DIVERA_ALARM = 'diveraAlarm',
  ALAMOS_ALARM = 'alamosAlarm',
  C8Y_ALARM = 'c8yAlarm',
  C8Y_EVENT = 'c8yEvent',
  C8Y_OPERATION = 'c8yOperation',
}

export enum C8Y_Severity {
	CRITICAL = 'CRITICAL',
    MAJOR = 'MAJOR',
    MINOR = 'MINOR',
    WARNING = 'WARNING',
}

export class AlarmChannel implements LookupEntry {
  id: number;
  name: string;
  description: string;
  active: boolean;
  channelType: AlarmChannelType;
  channelProperties: any;
  customer: LookupEntry;
}
