import { LatLngBounds } from 'leaflet';
import * as moment from 'moment';
import { SensorBox } from './models/sensorBox';

export namespace SensorActions {
	export class FetchMyList {
		static readonly type = '[Sensor] Fetch My List';
	}
	export class FetchForMap {
		static readonly type = '[Sensor] Fetch For Map';
		constructor(public bounds: LatLngBounds) { }
	}
	export class Select {
		static readonly type = '[Sensor] Select';
		constructor(public id: number) { }
	}
	export class Unselect {
		static readonly type = '[Sensor] Unselect';
	}
	export class Create {
		static readonly type = '[Sensor] Create';
		constructor(public sensorBox: any) { } // TODO: Update crud and fix typing here (should be SensorBox)
	}
	export class Update {
		static readonly type = '[Sensor] Update';
		constructor(
			public id: number,
			public sensorBox: any, // TODO: Update crud and fix typing here (should be Partial<SensorBox>)
		) { }
	}
	export class Delete {
		static readonly type = '[Sensor] Delete';
		constructor(public id: number) { }
	}

}
