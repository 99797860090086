import {FormArray, FormGroup} from '@angular/forms';

export class FormDebugHelper {

    /*
       Returns an array of invalid control/group names with the respective errors, or a zero-length array if
       no invalid controls/groups where found
    */
    public static findInvalidControls(formToInvestigate: FormGroup | FormArray): string[] {
        const invalidControls: any[] = [];
        // In case we're dealing with the "top-level" FormGroup, add the errors manually
        if (formToInvestigate.errors) {
            invalidControls.push({
                name: 'FormGroup',
                error: formToInvestigate.errors
            });
        }
        // Add errors of all invalid fields contained within the form
        const recursiveFunc = (form: FormGroup | FormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);
                if (control.invalid) {
                    invalidControls.push({
                        name: field,
                        error: control.errors
                    });
                }
                if (control instanceof FormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof FormArray) {
                    recursiveFunc(control);
                }
            });
        };
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }

}
