<div class="primary-panel-large container-fluid">
	<div class="row">
		<div class="col-8">
			<h1 *ngIf="mode === 'view'" appTranslate="alarmGroup.headerLabelSingular"></h1>
			<h1 *ngIf="mode === 'add'" appTranslate="alarmGroup.table.actions.add"></h1>
			<h1 *ngIf="mode === 'edit'" appTranslate="alarmGroup.table.actions.edit"></h1>
		</div>
	</div>
	<form *ngIf="crudDeviceForm" class="form" [formGroup]="crudDeviceForm" (ngSubmit)="saveAlarmGroup()">
		<div class="row ch-margin-top-24">
			<div class="col-1 role">
				<strong>{{ "alarmGroup.member.state.active" | translate }}</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active"
							[class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
			<div class="col-4 role">
			</div>
			<div class="col-2 role">
				<strong>{{ "alarmGroup.label.alarming" | translate }}</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.alarming.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleAlarming()" id="input-alarming" formControlName="alarming"
							[class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
			<div class="col-2 role">
				<strong>{{ "alarmGroup.label.clearing" | translate }}</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.clearing.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleClearing()" id="input-clearing" formControlName="clearing"
							[class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-name">{{ "alarmGroup.table.columns.name" | translate }} *</label>
					<input placeholder="" id="input-name" formControlName="name" />
					<div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4"
						appTranslate="alarmGroup.crud.error.name"></div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.description.invalid">
					<label for="input-description"><span appTranslate="alarmGroup.crud.description"></span></label>
					<input placeholder="" id="input-description" formControlName="description" />
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<button class="btn-primary" *ngIf="!crudDeviceForm.disabled"
					(click)="saveAlarmGroup(); $event.preventDefault()"
					appTranslate="alarmGroup.member.crud.actions.save"></button>
			</div>
			<div class="col-3">
				<button class="btn-secondary" (click)="back()" *ngIf="!crudDeviceForm.disabled"
					appTranslate="alarmGroup.member.crud.actions.cancel"></button>
			</div>
			<div class="col-3"></div>
			<div *ngIf="mode === 'edit'" class="col-3">
				<button type="button" class="btn-secondary" (click)="deleteAlarmGroup()"
					*ngIf="!crudDeviceForm.disabled" appTranslate="alarmGroup.member.crud.actions.delete"></button>
			</div>
		</div>
	</form>

	<div *ngIf="alarmGroupDetails.id" class="row ch-margin-top-24">
		<div class="col-12">
			<app-data-table [dataTableOptions]="dataTableOptions" [state]="gridState"
				[data]="alarmGroupDetails?.members"></app-data-table>
		</div>
	</div>
</div>