import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { GridApi, IFloatingFilterParams } from 'ag-grid-community';
import moment from 'moment';

export function setDateRangeFilterModel(
  api: GridApi,
  columnName: string,
  from: Date,
  to: Date
): void {
  api.setFilterModel({
    [columnName]: {
      filterType: 'date',
      type: 'inRange',
      dateFrom: moment(from).format('YYYY-MM-DD'),
      // FIXME: https://github.com/ag-grid/ag-grid/issues/4046
      dateTo: moment(to).add(1, 'day').format('YYYY-MM-DD'),
    },
  });
}

interface DateRangeFilterParams extends IFloatingFilterParams {
  disableReset: boolean;
  max?: Date;
}

@Component({
  template: `
    <form class="date-picker" [formGroup]="dateForm">
      <mat-date-range-input [rangePicker]="picker1" [max]="params.max">
        <input
          readonly
          matStartDate
          placeholder="{{ 'time.from' | translate }}"
          formControlName="from"
        />
        <input
          readonly
          matEndDate
          placeholder="{{ 'time.until' | translate }}"
          formControlName="to"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-icon
        *ngIf="!params.disableReset && f.from.value !== '' && f.to.value !== ''"
        (click)="resetFilter()"
        >close</mat-icon
      >
      <mat-date-range-picker
        #picker1
        (closed)="filter()"
      ></mat-date-range-picker>
    </form>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
      form {
        display: flex;
        align-items: center;
        justify-content: end;
      }
      mat-datepicker-toggle {
        position: absolute;
        top: -6px;
        right: unset;
      }
      mat-icon {
        cursor: pointer;
        position: absolute;
        right: 40px;
      }
    `,
  ],
})
export class DateRangeFloatingFilter implements IFloatingFilterAngularComp {
  params: DateRangeFilterParams;
  dateForm: FormGroup = new FormGroup({
    from: new FormControl(''),
    to: new FormControl(''),
  });

  get f(): { [key: string]: AbstractControl } {
    return this.dateForm.controls;
  }

  agInit(params: DateRangeFilterParams) {
    this.params = params;
  }

  onParentModelChanged(parentModel: any): void {
    if (parentModel?.dateFrom && parentModel?.dateTo) {
      this.dateForm.patchValue({
        from: moment(parentModel.dateFrom).toDate(),
        // FIXME: https://github.com/ag-grid/ag-grid/issues/4046
        to: moment(parentModel.dateTo).subtract(1, 'day').toDate(),
      });
    }
  }

  filter() {
    if (this.dateForm.invalid) return;

    this.params.parentFilterInstance((instance) => {
      instance.setModel({
        filterType: 'date',
        type: 'inRange',
        dateFrom: moment(this.f.from.value).format('YYYY-MM-DD'),
        // FIXME: https://github.com/ag-grid/ag-grid/issues/4046
        dateTo: moment(this.f.to.value).add(1, 'day').format('YYYY-MM-DD'),
      });

      // This is used to actually update the table after the new filter model has been set
      this.params.api.onFilterChanged();
    });
  }

  resetFilter() {
    this.dateForm.patchValue({
      from: '',
      to: '',
    });

    this.params.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged(null, null);
    });
  }
}
