<form [formGroup]="formGroup">
  <div class="row" *ngIf="!isPermanent">
    <div class="form-field col-12">
      <mat-date-range-input
        [rangePicker]="picker1"
        [dateFilter]="dateFilter"
        [required]="false"
        [max]="maxDate"
      >
        <input
          matStartDate
          formControlName="beginDate"
          id="date-period-start"
          placeholder="{{ 'time.from' | translate }}"
        />
        <input
          matEndDate
          formControlName="endDate"
          id="date-period-end"
          placeholder="{{ 'time.until' | translate }}"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-date-range-picker #picker1></mat-date-range-picker>
    </div>
  </div>
</form>
