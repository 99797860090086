import { Injectable } from '@angular/core';
import {
	IoTSensorBoxSettings,
	SensorBoxCommandQueueEntry,
	CommandEnum,
} from './models/sensor';
import { AssignedAlarmGroup } from './models/alarmGroup';
import {
	IoTSensorBoxSettingsResult,
	AssignedAlarmGroupListResult,
	SensorBoxCommandQueueEntryResult,
	SensorBoxCommandQueueResult,
	SensorResult,
	ApiResult,
	PlainApiResult,
	AlarmActionListResult,
} from '../../models/apiResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { WebsocketService } from '../../services/websocket.service';
import { map } from 'rxjs/operators';
import { RenderedInfo, SensorBox } from './models/sensorBox';
import { LatLngBounds } from 'leaflet';
import { Store } from '@ngxs/store';
import { GuiState } from '../dashboard/gui.state';
import { SensorState } from './sensor.state';
import { AlarmAction } from './models/alarmAction';

@Injectable({
	providedIn: 'root',
})
export class NoyseeService {
	private webSocket: WebSocket;

	constructor(
		private http: HttpClient,
		private store: Store,
		private authenticationService: AuthenticationService,
		private readonly websocketHelper: WebsocketService,
	) {
		this.websocketHelper.dashboardWebSocket.subscribe((socket) => {
			if (!socket) {
				/*
				this._currentSensorDetailsSource.next(null);
				this._currentSensorMetaSource.next(null);
				this._mySensorList.next([]);
				this._mapSensorList.next([]);
				*/
				return;
			}
			this.webSocket = socket;

			socket.addEventListener('open', () => {
				/*
				this.reloadMySensorList();
		
				this.mapService.map
				  .pipe(first((dashboardMap) => !!dashboardMap))
				  .subscribe((dashboardMap) => {
					dashboardMap.getBounds();
					this.sendBoundingBox(this.mapService.boundingBoxValues);
					this.reloadSensorsInBoundingBox(this.mapService.boundingBoxValues);
				  });
				  */
			});
			// Update on websocket change if sync active
			socket.addEventListener('message', (msg) => {
				/*
				const message = new WebsocketMessage(msg.data);
				if (
				  message.topic === Topic.SENSOR_BOX_NEW_VALUE ||
				  message.topic === Topic.SENSOR_BOX_META
				) {
				  this.reloadSensorsInBoundingBox(this.mapService.boundingBoxValues);
				  this.reloadMySensorList();
		
				  const wsdata = message.data as SensorBox[];
				  for (const sensorBox of wsdata) {
					if (
					  sensorBox.sensorBoxId ===
					  applicationContextService.currentSensorIdValue
					) {
					  this.updateCurrentSensorInformation();
					}
				  }
				}
				*/
			});
		});
	}

	sendBoundingBox(boxValues: LatLngBounds) {
		if (this.webSocket.readyState === 1) {
			const msg = {
				boundingBox: {
					north: boxValues.getNorth(),
					south: boxValues.getSouth(),
					west: boxValues.getWest(),
					east: boxValues.getEast(),
				},
				customerId:
					this.authenticationService.authenticatedUser &&
						this.authenticationService.authenticatedUser.customer
						? this.authenticationService.authenticatedUser.customer.id
						: null,
				userId: this.authenticationService.authenticatedUser
					? this.authenticationService.authenticatedUser.id
					: null,
			};
			this.webSocket.send(JSON.stringify(msg));
		}
	}

	addAlarmReceiver(sensor: any) {
		const currentAlarmReceiver = {
			id: sensor.id,
			alarmReceiver: sensor.alarmReceiver,
		};
		// console.log(currentAlarmReceiver);
		this.http
			.post<any>(
				`${environment.dashboardApiUrl}/sensorBox/alarmReceiver`,
				currentAlarmReceiver,
			)
			.subscribe((answer) => {
				// console.log(answer);
			});
	}

	changeAlarmReceiver(currentAlarmReceiver: any) {
		for (let i = 0; i < currentAlarmReceiver.alarmReceiver.length; i++) {
			delete currentAlarmReceiver.alarmReceiver[i].hidden;
		}
		// console.log(currentAlarmReceiver);
		this.http
			.put<any>(
				`${environment.dashboardApiUrl}/sensorBox/alarmReceiver`,
				currentAlarmReceiver,
			)
			.subscribe((answer) => {
				// console.log(answer);
			});
	}

	getRenderedInfo(id: number): Promise<RenderedInfo> {
		return this.http
			.get<any>(
				`${environment.dashboardApiUrl}/sensorBox/` + id + `/renderedInfo`,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	saveRenderedInfoField(
		id: number,
		field: string,
		value: string,
	): Promise<RenderedInfo> {
		return this.http
			.put<any>(
				`${environment.dashboardApiUrl}/sensorBox/` +
				id +
				`/renderedInfo/` +
				field,
				value,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	prependZero(prependTo: number): string {
		return prependTo < 10 ? '0' + prependTo : prependTo.toString();
	}

	removeAlarmReceiver(currentAlarmReceiver: any) {
		for (let i = 0; i < currentAlarmReceiver.alarmReceiver.length; i++) {
			delete currentAlarmReceiver.alarmReceiver[i].hidden;
		}
		this.http
			.put<any>(
				`${environment.dashboardApiUrl}/sensorBox/alarmReceiver`,
				currentAlarmReceiver,
			)
			.subscribe((answer) => {
				// this.updateCurrentSensorInformation();
			});
	}

	changeAlarm(changedAlarm: any) {
		delete changedAlarm.hidden;
		return this.http.put<any>(
			`${environment.dashboardApiUrl}/sensorBox/alarmReceiver/entry`,
			changedAlarm,
		);
	}

	sendTestAlarm(sensorId: string, alarmLevel: string): Promise<any> {
		return this.http
			.post<any>(`${environment.dashboardApiUrl}/sensorBox/testAlarm`, {
				id: sensorId,
				alarmLevel,
			})
			.toPromise();
	}

	sendTestMessage(sensorId: string, message: string): Promise<any> {
		return this.http
			.post<any>(`${environment.dashboardApiUrl}/sensorBox/sendMessage`, {
				id: sensorId,
				message,
			})
			.toPromise();
	}

	loadAdminSettings(sensorBoxId: number): Observable<IoTSensorBoxSettings> {
		return this.http
			.get<IoTSensorBoxSettingsResult>(
				`${environment.dashboardApiUrl}/sensorBoxAdmin/` + sensorBoxId,
			)
			.pipe(
				map((result: IoTSensorBoxSettingsResult) => {
					if (result?.ok) {
						return result.data;
					} else {
						return null;
					}
				}),
			);
	}

	saveAdminSettings(
		settings: IoTSensorBoxSettings,
	): Observable<IoTSensorBoxSettings> {
		return this.http
			.put<IoTSensorBoxSettingsResult>(
				`${environment.dashboardApiUrl}/sensorBoxAdmin/` + settings.id,
				settings,
			)
			.pipe(
				map((result: IoTSensorBoxSettingsResult) => {
					if (result?.ok) {
						return result.data;
					} else {
						throw new Error(result.errorCode);
					}
				}),
			);
	}

	getSensorBoxAlarmGroups(sensorBoxId: number): Promise<AssignedAlarmGroup[]> {
		return this.http
			.get<AssignedAlarmGroupListResult>(
				`${environment.dashboardApiUrl}/sensorBox/alarmGroup/list?id=` +
				sensorBoxId,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	addAlarmGroup(
		sensorBoxId: number,
		alarmLevel: number,
		alarmGroupId: number,
	): Observable<AssignedAlarmGroup[]> {
		return this.http
			.post<AssignedAlarmGroupListResult>(
				`${environment.dashboardApiUrl}/sensorBox/alarmGroup/entry`,
				{
					sensorBoxId,
					alarmLevel,
					alarmGroupId,
				},
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			);
	}

	removeAlarmGroup(
		sensorBoxId: number,
		sensorBoxAlarmGroupId: number,
	): Promise<AssignedAlarmGroup[]> {
		// console.debug('Remove SensorBoxAlarmGroup ' + sensorBoxAlarmGroupId + ' from SensorBox ' + sensorBoxId);
		return this.http
			.delete<AssignedAlarmGroupListResult>(
				`${environment.dashboardApiUrl}/sensorBox/alarmGroup/entry?sensorBoxId=` +
				sensorBoxId +
				`&id=` +
				sensorBoxAlarmGroupId,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	scheduleCommand(
		sensorBoxId: number,
		cmd: CommandEnum,
	): Promise<SensorBoxCommandQueueEntry> {
		let cmdString = 'Config';
		switch (cmd) {
			case CommandEnum.LOCATE: {
				cmdString = 'scheduleLocate';
				break;
			}
			case CommandEnum.CONFIG: {
				cmdString = 'scheduleConfig';
				break;
			}
			case CommandEnum.DEEP_SLEEP: {
				cmdString = 'scheduleDeepSleep';
				break;
			}
			case CommandEnum.SYSINFO: {
				cmdString = 'scheduleSysInfo';
				break;
			}
			case CommandEnum.OTA: {
				cmdString = 'scheduleOta';
				break;
			}
			default:
				throw new Error('UNSUPPORTED_COMMAND');
		}

		return this.http
			.get<SensorBoxCommandQueueEntryResult>(
				`${environment.dashboardApiUrl}/sensorBox/` +
				cmdString +
				`?id=` +
				sensorBoxId,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	commandQueue(sensorBoxId: number): Promise<SensorBoxCommandQueueEntry[]> {
		return this.http
			.get<SensorBoxCommandQueueResult>(
				`${environment.dashboardApiUrl}/sensorBox/commandQueue?id=` +
				sensorBoxId,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	createSensorBoxByTemplate(customerId: number, templateId: number, lat: number, lon: number, name: string): Promise<SensorBox> {
		return this.http.post<SensorResult>(
			`${environment.dashboardApiUrl}/sensorBox/create`,
			{
				customerId: customerId,
				templateId: templateId,
				lat: lat,
				lon: lon,
				name: name,
				active: true,
				state: -4,
			}
		).pipe(
			map((result) => {
				if (!result.ok) {
					throw new Error(result.errorCode);
				}
				return result.data;
			}),
		).toPromise();

	}

	purgeSensorBox(id: number): Promise<boolean> {
		return this.http.delete<PlainApiResult>(
			`${environment.dashboardApiUrl}/sensorBox/` + id + `/purge`,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return true;
				}),
			).toPromise();
	}
	
}
