import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApprovalDialogHelper } from '../../../../helpers/approval-dialog.helper';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ToastHelper } from '../../../../helpers/toast.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { Subscription } from 'rxjs';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { Warning } from 'src/app/models/warningBroker';
import { WarningBrokerService } from 'src/app/services/warningBroker.service';
import moment from 'moment';
import { AgsLookup } from 'src/app/models/customerList';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddAgsDialogComponent } from '../../admin/customers/addAgs-modal/addAgsDialog.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  dataTableOptions: DataTableOptions<Warning>;
  warningList: Warning[];
  private usersSubscription$: Subscription;
  private customerSubscription$: Subscription;

  params: any;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  ctx: GridState = null;

  relevantAgsList: AgsLookup[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private warningBrokerService: WarningBrokerService,
    private router: Router,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private dataTableStateService: DataTableStateService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.ctx = this.router.getCurrentNavigation().extras.state as GridState;
    }
    this.dataTableOptions = {
      title: 'warningBroker.activeWarnings.table.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'warningBroker.activeWarnings.actions.resetStateDWD',
          rule: () => this.authenticationService.isAdmin(),
          actionFn: () => this.resetDWDWarnings(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'warningBroker.activeWarnings.table.actions.view',
            icon: 'app-view',
            dataRule: (w: Warning) => true,
            actionFn: (w: Warning) => this.showWarning(w),
          },
          {
            label: 'warningBroker.activeWarnings.table.actions.cancel',
            icon: 'app-delete',
            dataRule: (w: Warning) => this.authenticationService.isSuperAdmin(),
            actionFn: (w: Warning) => this.cancelWarning(w),
          },
          {
            label: 'warningBroker.activeWarnings.table.actions.delete',
            icon: 'app-delete',
            dataRule: (w: Warning) => this.authenticationService.isSuperAdmin(),
            actionFn: (w: Warning) => this.deleteWarning(w),
          },
        ],
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'warningBroker.activeWarnings.table.columns.id',
          width: '5%',
          sortable: true,
        },
        {
          key: 'warningSource',
          displayName:
            'warningBroker.activeWarnings.table.columns.warningSource',
          width: '10%',
          valueTransform: (w: Warning) => w.warningSource.name,
          sortable: true,
          filterFn: (w: Warning, arg) =>
            w.warningSource.name.toLowerCase().includes(arg.toLowerCase()),
        },
        {
          key: 'headline',
          displayName: 'warningBroker.activeWarnings.table.columns.headline',
          width: '50%',
          sortable: true,
          filterFn: (w: Warning, arg) =>
            w.headline.toLowerCase().includes(arg.toLowerCase()),
        },
        {
          key: 'effective',
          displayName: 'warningBroker.activeWarnings.table.columns.effective',
          width: '10%',
          sortable: true,
          valueTransform: (w: Warning) =>
            moment(w.effective).format('DD.MM.YYYY HH:mm'),
        },
        {
          key: 'expires',
          displayName: 'warningBroker.activeWarnings.table.columns.expires',
          width: '10%',
          sortable: true,
          valueTransform: (w: Warning) =>
            w.expires ? moment(w.expires).format('DD.MM.YYYY HH:mm') : '-',
        },
        {
          key: 'cancelTimestamp',
          displayName: 'warningBroker.activeWarnings.table.columns.canceled',
          width: '10%',
          sortable: true,
          valueTransform: (w: Warning) =>
            w.cancelTimestamp
              ? moment(w.cancelTimestamp).format('DD.MM.YYYY HH:mm')
              : '-',
        },
      ],
    };

    this.loadWarnings();
  }

  loadWarnings() {
    this.warningBrokerService
      .getActiveWarningsFromAgsList(this.relevantAgsList)
      .then((data) => {
        this.warningList = data;
      });
  }

  ngOnDestroy() {
    this.usersSubscription$?.unsubscribe();
    this.customerSubscription$?.unsubscribe();
  }

  private showWarning(w: Warning): void {
    let navRoute = ['/dashboard', 'warningBroker', 'warnings', w.id];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  private cancelWarning(warning: Warning): void {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie die folgende Warnung wirklich beenden?<br>' +
        warning.headline,
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.warningBrokerService.cancelWarning(warning.id).then((result) => {
            this.loadWarnings();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  private deleteWarning(warning: Warning): void {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie die folgende Warnung wirklich löschen?<br>' +
        warning.headline,
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.warningBrokerService.deleteWarning(warning.id).then((result) => {
            this.loadWarnings();
            this.toastHelper.openSnackBar(
              'Warnung ' + warning.id + ' gelöscht.',
              'SUCCESS',
            );
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  private resetDWDWarnings(): void {
    this.warningBrokerService.getReset('dwd').then((r) => {
      this.toastHelper.openSnackBar('Ausgeführt', 'SUCCESS');
      this.loadWarnings();
    });
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  addAgs() {
    const dialogOptions: MatDialogConfig = {
      data: {
        selectedAgsList: this.relevantAgsList,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddAgsDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result.selectedAgs) {
          this.relevantAgsList.push(result.selectedAgs);
          this.loadWarnings();
        }
      });
  }
}
