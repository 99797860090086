<button mat-icon-button disableRipple [matMenuTriggerFor]="menu" class='trigger' aria-label="open group menu">
  <app-icon>app-menu-horizontal</app-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let action of actionList">
    <ng-container *ngIf="(action.dataRule && action.dataRule(data)) || (action.rule$ && action.rule$ | async) || action.rule && action.rule() || !action.rule$ && !action.rule && !action.dataRule">
      <button *ngIf="action.routerPath" mat-menu-item routerLink="{{action.routerPath}}">
        <app-icon *ngIf="action.icon">{{action.icon}}</app-icon>
        <app-icon *ngIf="action.iconFn && action.iconFn(data)">{{action.iconFn(data)}}</app-icon>
        <span *ngIf="action.label">{{action.label | translate}}</span>
        <span *ngIf="action.labelFn && action.labelFn(data)">{{action.labelFn(data) | translate}}</span>
      </button>
      <ng-container *ngIf="action.linkFn && action.linkFn(data) as link">
        <button mat-menu-item [routerLink]="link.path" [queryParams]="link.param">
          <app-icon *ngIf="action.icon">{{action.icon}}</app-icon>
          <app-icon *ngIf="action.iconFn && action.iconFn(data)">{{action.iconFn(data)}}</app-icon>
          <span *ngIf="action.label">{{action.label | translate}}</span>
          <span *ngIf="action.labelFn && action.labelFn(data)">{{action.labelFn(data) | translate}}</span>
        </button>
      </ng-container>
      <button *ngIf="action.actionFn" mat-menu-item (click)="action.actionFn(data)">
        <app-icon *ngIf="action.icon">{{action.icon}}</app-icon>
        <app-icon *ngIf="action.iconFn && action.iconFn(data)">{{action.iconFn(data)}}</app-icon>
        <span *ngIf="action.label">{{action.label | translate}}</span>
        <span *ngIf="action.labelFn && action.labelFn(data)">{{action.labelFn(data) | translate}}</span>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
