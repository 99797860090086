import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

export interface SelectCellEditorOption {
  label: string;
  value: any;
}
export interface SelectCellEditorParams
  extends ICellEditorParams<any, any, any> {
  options: SelectCellEditorOption[];
}

@Component({
  template: `
    <select [(ngModel)]="this.value">
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
  `,
})
export class SelectCellEditor implements ICellEditorAngularComp {
  options: SelectCellEditorOption[] = [];
  value: any;

  agInit({ value, options }: SelectCellEditorParams): void {
    this.options = options;
    this.value = value;
  }

  getValue() {
    return this.value;
  }
}
