<app-date-value-chart
  *ngIf="data"
  [data]="data"
  [showPreview]="true"
  [customHeight]="height"
  [valuesType]="valueType"
  [secondaryValuesType]="secondaryValueType"
  [legendData]="
    getLegendData(translateService, sensorBox, sensorBox.primarySensor)
  "
></app-date-value-chart>
