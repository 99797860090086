import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { Router } from '@angular/router';
import { ApprovalDialogHelper } from '../../../../../helpers/approval-dialog.helper';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import {
  DataTableColumnType,
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize
} from '../../../../../shared/data-table/data-table.model';
import { Subscription } from 'rxjs';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';
import moment from 'moment';
import { BillingService } from 'src/app/services/billing';
import { Product } from 'src/app/models/billing';

@Component({
  selector: 'app-productList',
  templateUrl: './productList.component.html',
  styleUrls: ['./productList.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
  dataTableOptions: DataTableOptions<Product>;
  productList: Product[];
  private usersSubscription$: Subscription;
  private customerSubscription$: Subscription;

  params: any;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  ctx: GridState = null;

  constructor(private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private billingService: BillingService,
    private router: Router,
    private toastHelper: ToastHelper,
    private domSanitizer: DomSanitizer,
    private translatePipe: TranslatePipe,
    private approvalDialogHelper: ApprovalDialogHelper,
    private dataTableStateService: DataTableStateService) {
  }

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.ctx = this.router.getCurrentNavigation().extras.state as GridState;
    }
    this.dataTableOptions = {
      title: 'billing.products.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'billing.products.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () => this.addProduct(),
        },
        {
          label: 'billing.products.table.actions.maueSync',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () => this.maueSync(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [{
          label: 'billing.products.table.actions.view',
          icon: 'app-view',
          dataRule: (w: Product) => true,
          actionFn: (w: Product) => this.showProduct(w),
        },
        {
          label: 'billing.products.table.actions.edit',
          icon: 'app-pencil',
          dataRule: (w: Product) => true,
          actionFn: (w: Product) => this.editProduct(w),
        }]
      },
      rowStyleFn: (product: Product) => {
        return {
          'background-color': this.getStateColor(product),
          border: 'solid 1.5px white',
        };
      },
      
      columnDef: [
        {
          key: 'id',
          displayName: 'billing.products.table.columns.id',
          width: '5%',
          sortable: true,
        },
        {
          key: 'dmat',
          displayName: 'billing.products.table.columns.dmat',
          width: '15%',
          sortable: true,
          filterFn: (w: Product, arg) => w.dmat.toLowerCase().includes(arg.toLowerCase())
        },
        {
          key: 'name',
          displayName: 'billing.products.table.columns.name',
          width: '15%',
          sortable: true,
          filterFn: (w: Product, arg) => w.name.toLowerCase().includes(arg.toLowerCase()),
          styleFn: (product: Product) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold'
            };
          },
          actionFn: (product: Product) => this.showProduct(product),
        },
        {
          key: 'description',
          displayName: 'billing.products.table.columns.description',
          width: '50%',
          sortable: true,
          filterFn: (w: Product, arg) => w.description.toLowerCase().includes(arg.toLowerCase())
        },
        {
          key: 'price',
          displayName: 'billing.products.table.columns.price',
          width: '8%',
          sortable: true,
        },
        {
          key: 'billingInterval',
          displayName: 'billing.products.table.columns.billingInterval',
          width: '7%',
          sortable: true,
          valueTransform: (w: Product) => `general.billingInterval.${w.billingInterval}`,
        },
      ]
    };

    this.loadProducts();
  }

  private loadProducts() {
    this.billingService.getProducts().then(data => {
      this.productList = data;
    });

  }

  ngOnDestroy() {
    this.usersSubscription$?.unsubscribe();
    this.customerSubscription$?.unsubscribe();
  }

  getStateColor(product: Product) {
	if (product.mappingError!=null) {
		return 'rgba(255, 171, 49, 0.4)';
	} else {
		return '#c8d89c';
	}
  }


  private showProduct(w: Product): void {
    let navRoute = ['/dashboard', 'admins', 'products', w.id, 'view'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  private editProduct(w: Product): void {
    let navRoute = ['/dashboard', 'admins', 'products', w.id, 'edit'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(this.dataTable.getGridState());
  }

  private maueSync() {
    this.billingService.updateDMat().then(r => {
      this.toastHelper.openSnackBar('Sync durchgeführt!', 'SUCCESS');
    }).catch ((error) => {
      this.toastHelper.openSnackBar(
        'Fehler beim Sync!',
        'ERROR',
      );
    });
  }

  private addProduct() {

  }

}
