import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { CrudSensorComponent } from './crud-sensor/crud-sensor.component';
import { AdminBoxComponent } from './admin/admin-box.component';
import { ContactsComponent } from './detail/contacts/contacts.component';
import { DetailComponent } from './detail/detail/detail.component';
import { SensorBoxAlarmGroupsComponent } from './detail/alarmGroups/alarmGroups.component';
import { environment } from 'src/app/../environments/environment';
import { BoxCommandQueueComponent } from './box-command-queue/box-command-queue.component';
import { WeatherComponent } from './detail/weather/weather.component';
import { DescriptionComponent } from './detail/description/description.component';
import { AdminShortcutsComponent } from './detail/adminShortcuts/adminShortcuts.component';
import { SensorBoxAlarmActionsComponent } from './detail/alarmActions/alarmActions.component';
import { ValuesComponent } from './detail/values/values.component';
import { HistoryComponent } from './detail/history/history.component';

const menuEntries = [
  {
    icon: 'menu-icon-movements',
    path: 'values',
    tooltip: 'sensor.values.current',
  },
  {
    icon: 'menu-icon-schedule',
    path: 'history',
    tooltip: 'sensor.values.history',
    weatherStationOnly: true,
  },
  {
    icon: 'menu-icon-users',
    path: 'contacts',
    tooltip: 'sensor.contacts.headline',
    requiredPrivilege: 'box.alarmReceiver',
  },
  {
    icon: 'menu-icon-group',
    path: 'alarmGroups',
    tooltip: 'alarmGroup.headerLabel',
    requiredFeature: 'featureAlarmGroups',
    requiredPrivilege: 'box.alarmGroups',
  },
  {
    icon: 'menu-icon-description',
    path: 'description',
    tooltip: 'sensor.description.headerLabel',
  },
  {
    icon: 'menu-icon-action',
    path: 'actions',
    tooltip: 'alarmAction.headerLabel',
    requiredFeature: 'featureAlarmActions',
    requiredPrivilege: 'box.alarmAction',
  },
  {
    icon: 'menu-icon-admin',
    path: 'adminShortcuts',
    tooltip: 'sensor.adminShortcuts.headerLabel',
    requiredPrivilege: 'box.admin',
  },
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent,
  },
  {
    path: 'detail',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'edit-sensor',
            component: CrudSensorComponent,
            data: {
              breadcrumbLabel: 'sensor.crud.headlines.edit',
              mode: 'edit',
            },
          },
          {
            path: 'admin-sensor',
            component: AdminBoxComponent,
            data: {
              breadcrumbLabel: 'sensor.table.actions.iot-settings',
              mode: 'admin',
            },
          },
          {
            path: 'command-queue',
            component: BoxCommandQueueComponent,
            data: {
              breadcrumbLabel: 'sensor.table.actions.message-queue',
              mode: 'admin',
            },
          },
          {
            path: '',
            component: DetailComponent,
            children: [
              {
                path: 'values',
                component: ValuesComponent,
                data: { menuEntries },
              },
              {
                path: 'history',
                component: HistoryComponent,
                data: { menuEntries },
              },
              {
                path: 'contacts',
                component: ContactsComponent,
                data: { menuEntries },
              },
              {
                path: 'description',
                component: DescriptionComponent,
                data: { menuEntries },
              },
              {
                path: 'actions',
                component: SensorBoxAlarmActionsComponent,
                data: { menuEntries },
              },
              {
                path: 'adminShortcuts',
                component: AdminShortcutsComponent,
                data: { menuEntries },
              },
              {
                path: 'alarmGroups',
                component: SensorBoxAlarmGroupsComponent,
                data: { menuEntries },
              },
              {
                path: 'weather',
                component: WeatherComponent,
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'values',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'add-sensor',
    component: CrudSensorComponent,
    data: {
      breadcrumbLabel: 'sensor.table.actions.add',
      mode: 'add',
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SensorsRoutingModule {}
