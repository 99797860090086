<div *ngIf="currentSensor" class="row">
  <div class="col-12 ch-margin-top-16">
    <div class="container contacts-container">
      <div class="row ch-padding-top-8 header-row">
        <div class="col-6 contacts-header">
          <p appTranslate="sensor.description.headerLabel"></p>
        </div>
      </div>

      <div class="row ch-padding-top-8 header-row ch-margin-top-16">
        <div class="col-6 contacts-settings-header">
          <span
            >{{ "sensor.description.publicInfo" | translate }} &nbsp;<mat-icon
              class="editBtn"
              (click)="editPublic()"
              *ngIf="isAdmin || isSuperAdmin"
              >edit_note</mat-icon
            ></span
          >
        </div>
      </div>
      <div class="row ch-padding-top-8 header-row">
        <div
          class="col-12"
          [innerHtml]="this.renderedInfo?.htmlDescription"
        ></div>
      </div>
      <div
        class="row ch-padding-top-8 header-row"
        *ngIf="!this.renderedInfo?.htmlDescription"
      >
        <div class="col-12">
          Für diesen Standort sind keine öffentlichen Informationen hinterlegt.
        </div>
      </div>

      <div
        class="row ch-padding-top-8 header-row ch-margin-top-16"
        *ngIf="isAdmin || isSuperAdmin"
      >
        <div class="col-6 contacts-settings-header">
          <span
            >{{ "sensor.description.internalInfo" | translate }} &nbsp;<mat-icon
              class="editBtn"
              (click)="editInternal()"
              >edit_note</mat-icon
            ></span
          >
        </div>
      </div>
      <div
        class="row ch-padding-top-8 header-row"
        *ngIf="isAdmin || isSuperAdmin"
      >
        <div
          class="col-12"
          [innerHtml]="this.renderedInfo?.htmlInternalDescription"
        ></div>
      </div>
      <div
        class="row ch-padding-top-8 header-row"
        *ngIf="
          !this.renderedInfo?.htmlInternalDescription &&
          (isAdmin || isSuperAdmin)
        "
      >
        <div class="col-12">
          Für diesen Standort sind keine internen Informationen hinterlegt.
        </div>
      </div>
    </div>
  </div>
</div>
