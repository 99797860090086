<h1>Warnung</h1>
<mat-dialog-content>
  <p [innerHTML]="message"></p>
  <div [formGroup]="checkForm" *ngIf="approval">
    <div class="form-field">
      <label for="input-confirm" *ngIf="approval?.label">{{ approval?.label }} <span *ngIf="approval?.required">*</span></label>
      <input id="input-confirm" type="text" placeholder="" matInput [formControlName]="'check'">
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button class="btn-primary" *ngIf="approval" (click)="ClickYes()" [disabled]="checkForm.invalid"
          appTranslate="modals.approval.actions.continue"></button>
  <button mat-flat-button class="btn-primary" *ngIf="!approval" (click)="ClickYes()"
          appTranslate="modals.approval.actions.submit"></button>
  <button mat-flat-button class="btn-secondary" (click)="ClickNo()"
          appTranslate="modals.approval.actions.cancel"></button>
</mat-dialog-actions>
