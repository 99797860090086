import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { Waterway } from '../models/topo';
import { LatLngBounds } from 'leaflet';
import { Warning } from '../models/warningBroker';
import { AgsLookup } from '../models/customerList';

@Injectable({
  providedIn: 'root',
})
export class WarningBrokerService {
  constructor(private http: HttpClient) {}

  getActiveWarningsLike(agsLike: string): Promise<Warning[]> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/active?agsLike=` +
          agsLike,
      )
      .pipe(
        filter((result) => result.ok),
        map((result) => result.data),
      )
      .toPromise();
  }

  getActiveWarningsFromAgsList(relevantAgs: AgsLookup[]): Promise<Warning[]> {
    const agsListString = relevantAgs.map((e) => e.ags).join(';');
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/active/multiAgs?agsList=` +
          agsListString,
      )
      .pipe(
        filter((result) => result.ok),
        map((result) => result.data),
      )
      .toPromise();
  }

  getReset(source: string): Promise<boolean> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/management/sources/` +
          source +
          `/resetState`,
      )
      .pipe(map((result) => result.ok))
      .toPromise();
  }

  cancelWarning(warningId: number): Promise<boolean> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/` +
          warningId +
          `/cancel`,
      )
      .pipe(map((result) => result.ok))
      .toPromise();
  }

  deleteWarning(warningId: number): Promise<boolean> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/` +
          warningId +
          `/delete`,
      )
      .pipe(map((result) => result.ok))
      .toPromise();
  }

  getWarningFile(warningId: number): Promise<boolean> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/` +
          warningId +
          `/file`,
      )
      .pipe()
      .toPromise();
  }

  getWarningDetail(warningId: number): Promise<Warning> {
    return this.http
      .get<any>(
        `${environment.dashboardApiUrl}/warningBroker/warnings/` +
          warningId +
          `/detail`,
      )
      .pipe(map((result) => result.data))
      .toPromise();
  }

  getWaterways(boundingBox: LatLngBounds, zoom: number): Promise<Waterway[]> {
    return this.http
      .get<
        Waterway[]
      >(`${environment.dashboardApiUrl}/topo/waterways?lat1=${boundingBox.getNorthEast().lat}&lon1=${boundingBox.getNorthEast().lng}` + `&lat2=${boundingBox.getSouthWest().lat}&lon2=${boundingBox.getSouthWest().lng}&zoom=${zoom}`)
      .toPromise();
  }
}
