<div
  *ngIf="sensorBox$ | async as sensorBox"
  class="primary-panel-large container-fluid"
>
  <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
  <div class="row">
    <div class="col-md-4 col-12">
      <app-sensors-image
        [sensorId]="sensorBox.image ? sensorBox.id : undefined"
      ></app-sensors-image>
    </div>
    <div class="col-md-8 col-12">
      <app-sensors-map [currentSensor]="sensorBox"></app-sensors-map>
    </div>
  </div>
</div>
