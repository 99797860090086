<mat-dialog-content>
  <form [formGroup]="formGroup" class="form">
    <h1 appTranslate="password.crud.headline"></h1>
    <div class="row">
      <div class="col-12">
        <div class="form-field" [ngClass]="{error: this.formGroup.dirty && this.f.oldPassword.invalid}">
          <label for="input-password">{{'forms.user.oldPassword.label' | translate}} *</label>
          <input placeholder="{{'forms.user.oldPassword.placeholder' | translate}}" id="input-old-password" type="password" formControlName="oldPassword">
          <div *ngIf="this.formGroup.dirty && this.f.oldPassword.invalid" class="error-message ch-margin-top-4">
            <ng-container *ngIf="this.formGroup.get('oldPassword').hasError('required')"
                          appTranslate="forms.user.oldPassword.error"></ng-container>
          </div>
        </div>

        <div class="form-field ch-margin-top-24"
             [ngClass]="{error: this.formGroup.dirty && (this.formGroup?.hasError('noMatch') || this.formGroup?.get('password').invalid)}">
          <label for="input-password">{{'password.crud.label.newPassword' | translate}} *</label>
          <input placeholder="{{'forms.user.password.placeholder' | translate}}" id="input-password" type="password" formControlName="password">
          <div *ngIf="this.formGroup.dirty && (this.formGroup?.get('password').invalid)"
               class="error-message ch-margin-top-4">
            <ng-container *ngIf="this.formGroup.get('password').hasError('required')"
                          appTranslate="password.crud.error.newPassword"></ng-container>
            <br>
            <ng-container *ngIf="this.formGroup.get('password').hasError('passwordNotMatchingPolicies')"
                          appTranslate="forms.user.password.matchPolicies"></ng-container>
          </div>
        </div>

        <div class="form-field ch-margin-top-24"
             [ngClass]="{error: this.formGroup.dirty && (this.formGroup?.hasError('noMatch') || this.formGroup?.get('confirmPassword').invalid)}">
          <label for="input-confirmPassword">{{'forms.user.repeatPassword.label' | translate}} *</label>
          <input placeholder="{{'forms.user.repeatPassword.placeholder' | translate}}" id="input-confirmPassword"
                 type="password" formControlName="confirmPassword">
          <div
            *ngIf="this.formGroup.dirty && (this.formGroup?.get('confirmPassword').invalid || this.formGroup?.hasError('noMatch'))"
            class="error-message ch-margin-top-4">
            <ng-container *ngIf="this.formGroup.hasError('noMatch')"
                          appTranslate="forms.user.repeatPassword.notMatching"></ng-container>
          </div>
        </div>

        <div class="password-hint ch-margin-top-24" appTranslate="forms.user.passwordHint"></div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="this.formGroup.invalid" (click)="confirm()" mat-flat-button class="btn-primary"
          appTranslate="password.crud.actions.save"></button>
  <button mat-button (click)="cancel()" appTranslate="password.crud.actions.cancel"></button>
</mat-dialog-actions>
