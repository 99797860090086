import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApplicationContextService } from './application-context.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Role } from '../models/role.enum';
import { SessionFeatures, User } from '../models/user';
import { ApiResult, LoginResult, UserResult } from '../models/apiResults';
import { Customer } from '../models/customerList';
import { Store } from '@ngxs/store';
import { GuiActions } from '../state/dashboard/gui.action';
import { GuiState } from '@app/state/dashboard/gui.state';

export class FeatureSet {
	constructor(
		public sessionFeatures: SessionFeatures,
		public focusCustomer: Customer,
	) { }

	public hasPrivilege(pathExpression: string, level: string): boolean {
		var path = pathExpression.split('.', 2);
		var value = this.sessionFeatures.byRole;
		var i: number;
		for (i = 0; i < path.length && value; i++) {
			value = value[path[i]];
		}
		var grantedAccessLevel = value;
		if (
			!grantedAccessLevel ||
			grantedAccessLevel == undefined ||
			grantedAccessLevel == null ||
			grantedAccessLevel == 'deny'
		) {
			return false;
		}
		if (level) {
			return (
				grantedAccessLevel == level ||
				(grantedAccessLevel == 'write' && level == 'read') ||
				grantedAccessLevel == 'manage'
			);
		} else {
			return true;
		}
	}
	public hasFeature(featureExpression: string): boolean {
		return this.focusCustomer[featureExpression];
	}

	public hasOneOfFeature(featureList: string[]): boolean {
		var i: number;
		for (i = 0; i < featureList.length; i++) {
			if (this.sessionFeatures[featureList[i]]) {
				return true;
			}
		}
		return false;
	}

	public hasAccess(featureName: string, path: string, level: string): boolean {
		return this.hasFeature(featureName) && this.hasPrivilege(path, level);
	}

	public isAdmin(): boolean {
		return this.sessionFeatures.byRole?.customerAdmin;
	}

	public isSuperadmin(): boolean {
		return this.sessionFeatures.byRole?.superadmin;
	}

	public isGuest(): boolean {
		return this.sessionFeatures.byRole?.guest===true;
	}

}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private _authToken$: BehaviorSubject<string> = new BehaviorSubject<string>(
		'',
	);
	private _authUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	constructor(
		private http: HttpClient,
		private store: Store,
		private applicationContextService: ApplicationContextService,
	) {
		this.authToken = localStorage.getItem('authToken');
		this.authenticatedUser = JSON.parse(
			localStorage.getItem('authenticatedUser'),
		) as User;
		if (this.authenticatedUser) {
			this.applicationContextService.isSyncActiveValue = true;
		}
	}

	persistAuthenticatedUser(data: LoginResult): User {
		const user = data.sessionUser as User;
		// Role possibly not set yet, therefore add Guest as default
		user.role = user.role || Role.GUEST;
		// store user details and jwt token in local storage to keep user logged in between page refreshes
		this.authToken = data.jwt;
		this.authenticatedUser = user;
		// Persist auth data
		localStorage.setItem(
			'authenticatedUser',
			JSON.stringify(this.authenticatedUser),
		);
		localStorage.setItem('authToken', this.authToken);
		// Setup user gui context
		this.store.dispatch(
			new GuiActions.SetGuiContextFromUser(this.authenticatedUser),
		);
		// setup application context
		this.applicationContextService.isSyncActiveValue = true;
		return user;
	}

	private resetAuthenticatedUser(): void {
		this.applicationContextService.isSyncActiveValue = false;
		this.authToken = null;
		this.authenticatedUser = null;
		localStorage.removeItem('authToken');
		localStorage.removeItem('authenticatedUser');
		this.store.dispatch(new GuiActions.ResetGuiContext());
	}

	public get authTokenObservable(): Observable<string> {
		return this._authToken$.asObservable();
	}

	public get authToken(): string {
		return this._authToken$.value;
	}

	public set authToken(token: string) {
		this._authToken$.next(token);
	}

	public get authenticatedUserObservable(): Observable<User> {
		return this._authUser$.asObservable();
	}

	public get authenticatedUser(): User {
		return this._authUser$.value;
	}

	public set authenticatedUser(user) {
		this._authUser$.next(user);
	}

	public hasRole$(role: Role): Observable<boolean> {
		return this.authenticatedUserObservable.pipe(
			map((user) => user?.role === role),
		);
	}

	public getFeatureSet(): Observable<FeatureSet> {
		return combineLatest([
			this.authenticatedUserObservable,
			this.store.select(GuiState.selectedCustomer),
		]).pipe(
			filter(([user, customer]) => !!user && !!customer),
			map(([user, customer]) => new FeatureSet(user.sessionFeatures, customer)),
		);
	}

	public hasAccess$(featureName: string, privilegeName: string, accessType: string): Observable<boolean> {
		return this.getFeatureSet().pipe(
			map((features) =>
				features.hasAccess(featureName, privilegeName, accessType)
			),
		);
	}
	public hasPrivilege$(privilegeName: string, accessType: string): Observable<boolean> {
		return this.getFeatureSet().pipe(
			map((features) =>
				features.hasPrivilege( privilegeName, accessType)
			),
		);
	}
	public hasFeature$(featureName: string): Observable<boolean> {
		return this.getFeatureSet().pipe(
			map((features) =>
				features.hasFeature( featureName)
			),
		);
	}

	public isSuperAdmin(): boolean {
		// TODO nach Umstellung auf die RolePrivileges diese stelle berücksichtigen
		return this.authenticatedUser?.role === Role.SUPERADMIN;
	}

	public isAdmin(): boolean {
		// TODO nach Umstellung auf die RolePrivileges diese stelle berücksichtigen
		return (
			this.authenticatedUser?.role === Role.ADMIN ||
			this.authenticatedUser?.role === Role.RESCUE_COMMANDER ||
			this.isSuperAdmin()
		);
	}

	public customer(): Customer {
		return this.authenticatedUser?.customer;
	}

	public isUser(): boolean {
		// TODO nach Umstellung auf die RolePrivileges diese stelle berücksichtigen
		return (
			this.authenticatedUser?.role === Role.USER ||
			this.authenticatedUser?.role === Role.CITIZEN ||
			this.authenticatedUser?.role === Role.RESCUE_STAFF
		);
	}

	public isGuest(): boolean {
		return this.authenticatedUser?.role === Role.GUEST;
	}

	public isApiUser(): boolean {
		// TODO nach Umstellung auf die RolePrivileges diese stelle berücksichtigen
		return this.authenticatedUser?.role === Role.API_USER;
	}

	login(login: string, password: string): Observable<User> {
		return this.http
			.post<LoginResult>(`${environment.identityManagerApiUrl}/user/auth`, {
				login,
				password,
			})
			.pipe(
				map((data: LoginResult) => {
					// login successful if there's a jwt token in the response
					if (data?.ok) {
						return this.persistAuthenticatedUser(data);
					}
					return null;
				}),
			);
	}

	loginApiKey(apiKey: string): Observable<User> {
		return this.http
			.get<LoginResult>(`${environment.dashboardApiUrl}/apiKey/auth`, {
				params: {
					key: apiKey,
				},
			})
			.pipe(
				map((data: LoginResult) => {
					// login successful if there's a jwt token in the response
					if (data?.ok) {
						return this.persistAuthenticatedUser(data);
					}
					return null;
				}),
			);
	}

	loginPublic(): Observable<User> {
		return this.http
			.get<LoginResult>(`${environment.identityManagerApiUrl}/user/guest`)
			.pipe(
				map((data: LoginResult) => {
					// login successful if there's a jwt token in the response
					if (data?.ok) {
						return this.persistAuthenticatedUser(data);
					}
					return null;
				}),
			);
	}

	/**
	 * Only required for legacy SSO login method in login component
	 * @param token
	 * @returns
	 */
	loginSSOLegacy(token: string): Observable<User> {
		this.resetAuthenticatedUser();
		return this.http
			.post<LoginResult>(
				`${environment.dashboardApiUrl}/sso/auth`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.pipe(
				map((data: LoginResult) => {
					// login successful if there's a jwt token in the response
					if (data?.ok) {
						return this.persistAuthenticatedUser(data);
					} else {
						throw new Error(data?.errorCode);
					}
				}),
			);
	}

	loginSingleSignOn(system: string, token: string): Observable<User> {
		this.resetAuthenticatedUser();
		return this.http
			.post<LoginResult>(
				`${environment.identityManagerApiUrl}/sso/auth/${system}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.pipe(
				map((data: LoginResult) => {
					// login successful if there's a jwt token in the response
					if (data?.ok) {
						return this.persistAuthenticatedUser(data);
					} else {
						throw new Error(data?.errorCode);
					}
				}),
			);
	}

	logout() {
		this.resetAuthenticatedUser();
	}

	forgotPassword(username) {
		return this.http
			.post<any>(
				`${environment.identityManagerApiUrl}/user/reset?login=` + username,
				{},
			)
			.pipe((data) => {
				return data;
			});
	}

	resetPassword(token, password) {
		return this.http
			.put<any>(`${environment.identityManagerApiUrl}/user/token/` + token, {
				user: null,
				password,
			})
			.pipe((data) => {
				return data;
			});
	}

	registerUser(token, user, password): void {
		this.http
			.post<User>(`${environment.identityManagerApiUrl}/user/token/` + token, {
				user,
				password,
			})
			.pipe(first())
			.subscribe();
	}

	registerForSso(system: string, token: string) {
		return this.http
			.post<LoginResult>(
				`${environment.identityManagerApiUrl}/sso/register/${system}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.pipe(
				map((data: LoginResult) => {
					// Register will never allow the user to login directly
					// Instead, it will either return a success or an error message (which are both represented by the errorCode field)
					throw new Error(data?.errorCode);
				}),
			);
	}

	async inviteUser(
		email: string,
		role: string,
		customer: Customer,
	): Promise<User> {
		return this.http
			.post<UserResult>(`${environment.identityManagerApiUrl}/user/invite`, {
				email,
				role,
				customer,
			})
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	updatePassword(oldPW: string, pw1: string, pw2: string): Promise<void> {
		return this.http
			.post<ApiResult<User>>(
				`${environment.identityManagerApiUrl}/user/changePassword`,
				{ oldPW, pw1, pw2 },
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
				}),
			)
			.toPromise();
	}

	/* TODO: Reactivate when implemented
	resendInvite(uuid: string) {
	  return this.http.post<any>(`${environment.identityManagerApiUrl}/auth/resend-invite/`)
		.pipe(data => {
		  return data;
		});
	}
	 */
}
