import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'loading-overlay',
  template: `<div class="ch-margin-top-32">
    {{ 'table.overlay.loading' | translate }}
  </div>`,
})
export class LoadingOverlay implements ILoadingOverlayAngularComp {
  public params: any;

  agInit(params): void {
    this.params = params;
  }
}
