<div class="paginator-outer-container" *ngIf="paginator.length > paginator.pageSize">
  <div class="paginator-container">
    <div class="paginator-range-actions">
      <button mat-icon-button (click)="firstPage()"  [disabled]="paginator.pageIndex === 0">
        <app-icon>first_page</app-icon>
      </button>
      <button mat-icon-button (click)="previousPage()" [disabled]="!paginator.hasPreviousPage()">
        <app-icon>chevron_left</app-icon>
      </button>

      <div class="paginator-pages">
        <ng-container *ngIf="paginator.getNumberOfPages() <= 10">
          <ng-container *ngFor="let page of this.pageSizeArray; index as i">
            <button
                 [class.current-page]="paginator?.pageIndex === i"
                 (click)="jumpToPage(i)">{{i + 1}}</button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="paginator.getNumberOfPages() > 10">
          <span>{{'table.pagination' | translate: { current: (paginator.pageIndex + 1), total: paginator.getNumberOfPages()} }}</span>
        </ng-container>
      </div>


      <button mat-icon-button (click)="nextPage()" [disabled]="!paginator.hasNextPage()">
        <app-icon>chevron_right</app-icon>
      </button>
      <button mat-icon-button (click)="lastPage()" [disabled]="paginator.getNumberOfPages() === 0 || paginator.pageIndex === (paginator.getNumberOfPages()-1)">
        <app-icon>last_page</app-icon>
      </button>
    </div>
  </div>
</div>

