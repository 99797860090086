import { color, NumberFormatter, Root, Tooltip } from '@amcharts/amcharts5';
import {
  AxisRenderer,
  ColumnSeries,
  DateAxis,
  LineSeries,
  ValueAxis,
  XYChart,
} from '@amcharts/amcharts5/xy';
import { SensorService } from '../../state/noysee/sensor.service';
import {
  FutureSensorBoxValues,
  SensorBox,
  SensorBoxValues,
} from '../../state/noysee/models/sensorBox';
import {
  DateValueChartDataPoint,
  LegendDataItem,
} from './date-value-chart/date-value-chart.component';
import { isObject } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export const generateLineSeries = (
  root: Root,
  chart: XYChart,
  xAxis: DateAxis<AxisRenderer>,
  yAxis: ValueAxis<AxisRenderer>,
  lineColor: string,
  valueYField: string,
  dashed: boolean = false,
  interpolationField?: string,
) => {
  const series = chart.series.push(
    LineSeries.new(root, {
      xAxis,
      yAxis,
      valueXField: 'timestamp',
      valueYField: valueYField,
      tooltip: Tooltip.new(root, {
        labelText: '[bold]{valueY}[/]',
        pointerOrientation: 'horizontal',
      }),
      fill: color(lineColor),
      stroke: color(lineColor),
      openValueYField: interpolationField,
    }),
  );
  series.strokes.template.setAll({
    strokeWidth: 1.5,
    strokeDasharray: dashed ? [6, 3] : [],
  });
  series.fills.template.setAll({
    fillOpacity: 0.3,
    visible: !!interpolationField,
  });
  return series;
};

export const generateColumnSeries = (
  root: Root,
  chart: XYChart,
  xAxis: DateAxis<AxisRenderer>,
  yAxis: ValueAxis<AxisRenderer>,
  lineColor: string,
  valueYField: string,
  unfilled?: boolean,
) => {
  return chart.series.push(
    ColumnSeries.new(root, {
      xAxis,
      yAxis,
      valueXField: 'timestamp',
      valueYField: valueYField,
      tooltip: Tooltip.new(root, {
        labelText: '[bold]{valueY}[/]',
        pointerOrientation: 'horizontal',
      }),
      fill: !unfilled ? color(lineColor) : undefined,
      stroke: color(lineColor),
    }),
  );
};

export const getSensorBoxDataForDateRange = async (
  sensorService: SensorService,
  id: number,
  beginDate: Date,
  endDate?: Date,
  sensor?: string,
  secondarySensor?: string,
): Promise<{
  sensorBox: SensorBox;
  recordedData: DateValueChartDataPoint[];
  futureData: DateValueChartDataPoint[];
}> => {
  // Fetch sensor with history and future data from backend
  const { data: sensorBox } = await sensorService
    .getSensorBoxData(id, beginDate, endDate)
    .toPromise();

  const type = sensor || sensorBox.primarySensor;
  return {
    sensorBox,
    recordedData: sensorBox.history.map((h) =>
      mapToDataPoint(sensorBox, h, type, secondarySensor),
    ),
    futureData: sensorBox.future.map((f) =>
      mapToDataPoint(sensorBox, f, sensor),
    ),
  };
};

export const getLegendData = (
  translateService: TranslateService,
  sensorBox: SensorBox,
  selectedSensor: string,
): LegendDataItem[] => {
  if (sensorBox.secondarySensor) {
    return [
      {
        name: translateService.instant(`sensor.type.${selectedSensor}`),
        color: '#061671',
      },
      {
        name: translateService.instant(
          `sensor.type.${sensorBox.secondarySensor}`,
        ),
        color: '#ff9900',
      },
    ];
  }
  return [];
};

export const mapToDataPoint = (
  sensorBox: SensorBox,
  data: SensorBoxValues | FutureSensorBoxValues,
  type: string,
  secondaryType?: string,
): DateValueChartDataPoint => ({
  timestamp: data.timestamp,
  value: isObject(data[type]) ? undefined : formatNumber(data[type]),
  secondaryValue: isObject(data[secondaryType])
    ? undefined
    : formatNumber(data[secondaryType]),
  unit: sensorBox.sensors[type].unit,
  limit1: sensorBox.sensors[type].limit1,
  limit2: sensorBox.sensors[type].limit2,
  expectedValue: formatNumber(data[type]?.expected),
  expectedSecondaryValue: formatNumber(data[secondaryType]?.expected),
  futureMin: formatNumber(data[type]?.min),
  futureMax: formatNumber(data[type]?.max),
});

const formatNumber = (value: number | undefined): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  return +value.toFixed(1);
};
