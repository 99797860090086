import { Component, OnInit } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { NavigationEnd, Route, Router } from '@angular/router';
import { UsersService } from '../../../../services/users.service';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: any[];
  showBreadcrumb: boolean;

  constructor(
    private router: Router,
    private userService: UsersService,
    private store: Store,
  ) {
    this.breadcrumbs = [];
    this.showBreadcrumb = false;
  }

  ngOnInit(): void {
    // Set view on refresh / first page load
    this.updateView();

    // React to navigation
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateView();
      });
  }

  async updateView() {
    // Ignore account related pages, 404 page and sensor microsite
    const url = this.router.url;
    if (
      url.includes('login') ||
      url.includes('forgot-password') ||
      url.includes('complete-registration') ||
      url.includes('page-not-found') ||
      url.includes('sensor/')
    ) {
      return;
    }

    // Skip the first two layers, since they contain an empty path and just add components to the default view
    const appChildren = this.router.config.find(
      (route) => route.path === 'dashboard',
    )['_loadedConfig'].routes[0].children;
    const pathSegments = url.split('/');
    pathSegments.splice(0, 2);

    // Update breadcrumb
    this.breadcrumbs = await this.generateBreadcrumbs(
      appChildren,
      JSON.parse(JSON.stringify(pathSegments)),
      '/dashboard',
    );
    this.showBreadcrumb = this.breadcrumbs.length > 0;
  }

  private async generateBreadcrumbs(
    children: Route[],
    pathSegments: string[],
    url: string,
  ): Promise<any[]> {
    if (children.length === 0 || pathSegments.length === 0) {
      return [];
    }

    // Ignore url query parameters
    let segment = pathSegments.splice(0, 1)[0];
    if (segment.includes('?')) {
      segment = segment.split('?')[0];
    }
    // Get next child
    const child = children.find((current) => current.path === segment);

    url += '/' + segment;
    // If no label exists later, assign empty array
    let breadcrumb = [];
    if (!child) {
      let name = '';
      if (
        url.includes('/users') &&
        !url.includes('invite') &&
        !url.includes('add') &&
        !url.includes('edit-self')
      ) {
        // Check if user with current pathSegment as uuid exists
        const user = await this.userService.getById(+segment).toPromise();
        if (!!user) {
          name = user.name;
        } else {
          console.log(
            'Rerouting to page not found, no user with id (' +
              segment +
              ') exists',
          );
          console.log('Url so far:', url);
          await this.router.navigate(['/static', 'page-not-found']);
        }
      } else if (url.includes('/sensor')) {
        // Check if sensor exists
        const _this = this;
        name = await new Promise((resolve, reject) => {
          _this.store
            .select(SensorState.currentSensor)
            .pipe(first((sensor) => sensor?.id === +segment))
            .subscribe((sensor) => {
              if (sensor) {
                resolve(sensor.name);
              } else {
                console.log(
                  'Rerouting to page not found, no sensor with id (' +
                    segment +
                    ') exists',
                );
                console.log('Url so far:', url);
                this.router.navigate(['/static', 'page-not-found']);
                reject('Sensor not found');
              }
            });
        });
      }

      if (name !== '') {
        breadcrumb = [
          {
            label: name,
            url: url,
          },
        ];
      }
    } else if (child.data && child.data.breadcrumbLabel) {
      breadcrumb = [
        {
          label: child.data.breadcrumbLabel,
          url: url,
        },
      ];
    }

    // We have to distinguish between direct routes and routes that are loaded via a separate routing module
    // Direct routes contain a 'children' field, which contains all child routes
    // Routes from a separate routing module are lazy loaded and have a '_loadedConfig' field containing the child routes
    if (
      child &&
      !child.children &&
      child['_loadedConfig'] &&
      child['_loadedConfig'].routes
    ) {
      // Set 'children' field of lazy loaded route so that route behaves the same way a direct route would
      child.children = child['_loadedConfig'].routes;
    }

    if (child && child.children) {
      return breadcrumb.concat(
        await this.generateBreadcrumbs(child.children, pathSegments, url),
      );
    } else {
      return breadcrumb;
    }
  }
}
