<div class="primary-panel-extra-large container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode == 'add'">Benachrichtigungsvorlage anlegen</h1>
      <h1 *ngIf="mode == 'edit'">Benachrichtigungsvorlage bearbeiten</h1>
    </div>
  </div>
  <form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveMessageTemplate()">

    <div class="row ch-margin-top-24">
      <div class="col-1 role">
        <strong>Aktiv</strong>
      </div>
      <div class="col-4 role">
        <div class="form-field" [class.error]="submitted && f.active.invalid">
          <label class="switch">
            <input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">Name *</label>
          <input placeholder="" id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">Bitte einen Namen für die
            Vorlage wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field" [class.error]="submitted && f.templateClass.invalid">
          <label for="input-templateClass">Klasse</label>
          <select [attr.disabled]="null" id="select-templateClass" formControlName="templateClass">
            <option [ngValue]="null">-</option>
            <option [ngValue]="lookup.value" *ngFor="let lookup of templateClasses()">{{lookup.label | translate}}</option>
          </select>
          <div *ngIf="submitted && f.templateClass.invalid" class="error-message ch-margin-top-4">Bitte einen Namen für die
            Vorlage wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.description.invalid">
          <label for="input-description"><span>Beschreibung</span></label>
          <textarea placeholder="" id="input-description" formControlName="description" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.subject.invalid">
          <label for="input-subject"><span>Betreff</span></label>
          <textarea placeholder="" id="input-subject" formControlName="subject" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.body.invalid">
          <label for="input-body"><span>Nachricht</span></label>
          <textarea placeholder="" id="input-body" formControlName="body" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.instruction.invalid">
          <label for="input-instruction"><span>Anweisung</span></label>
          <textarea placeholder="" id="input-instruction" formControlName="instruction" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudForm.disabled">Speichern</button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled">Abbrechen</button>
      </div>
      <div class="col-3">
      </div>
      <div *ngIf="mode === 'edit'" class="col-3">
        <button type="button" class="btn-secondary" (click)="deleteMessageTemplate()"
          *ngIf="!crudForm.disabled">Löschen</button>
      </div>
    </div>
  </form>

</div>