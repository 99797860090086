import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { ApplicationContextService } from './services/application-context.service';
import { UsersService } from './services/users.service';
import { NotificationService } from './services/notification.service';
import { environment } from '../environments/environment';
import STATUS from '../assets/status.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TooltipComponent } from '@angular/material/tooltip';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsocketService } from './services/websocket.service';
import { addLicense } from '@amcharts/amcharts5';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private websocketService: WebsocketService,
    private applicationContextService: ApplicationContextService,
    private userService: UsersService,
    private notificationService: NotificationService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.setDocumentTitle();
    this.setFavicon();
    this.registerIcons();
    addLicense('AM5C453772101');
  }

  ngOnInit() {
    this.noCacheReloadOnNewVersion();
  }

  private registerIcons() {
    const icons = [
      { name: 'rainy', path: 'assets/icons/filter/rainy.svg' },
      { name: 'waterlevel', path: 'assets/icons/filter/waterlevel.svg' },
      { name: 'precip', path: 'assets/icons/filter/precip.svg' },
      { name: 'precipArea', path: 'assets/icons/filter/precipArea.svg' },
      { name: 'weather', path: 'assets/icons/filter/weather.svg' },
      { name: 'null', path: 'assets/icons/filter/null.svg' },
    ];
    icons.forEach((icon) => {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path),
      );
    });
  }

  private setDocumentTitle() {
    document.title = environment.appTitle;
  }

  private setFavicon() {
    const favIcon = document.querySelector('#appIcon');
    favIcon.setAttribute('href', environment.faviconUrl);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  noCacheReloadOnNewVersion() {
    console.log('Noysee frontend version: ', STATUS.version);
    console.log('Frontend commit hash: ', STATUS.commitHash);
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.httpClient
      .get<{ version: string; commitHash: string }>('./../assets/status.json', {
        headers,
      })
      .subscribe((version) => {
        console.log('Noysee server frontend version: ', version.version);

        if (STATUS.commitHash !== version.commitHash) {
          this.httpClient
            .get('', { headers, responseType: 'text' })
            .subscribe(() => location.reload());
        }
      });
  }
}

Object.defineProperty(TooltipComponent.prototype, 'message', {
  set(v: any) {
    const el = document.querySelectorAll('.mat-tooltip');

    if (el) {
      el[el.length - 1].innerHTML = v;
    }
  },
});
