import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import {
	GridState,
} from '../../../../shared/data-table/data-table.model';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { Location } from '@angular/common';
import { AlarmChannelService } from '@app/state/noysee/alarmChannel.service';
import { AlarmChannel, AlarmChannelType, C8Y_Severity } from '@app/state/noysee/models/alarmChannel';
import { AlarmGroupMemberType } from '@app/state/noysee/models/alarmGroup';

@Component({
	selector: 'app-alarmChannel-detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss'],
})
export class AlarmChannelDetailComponent implements OnInit, OnDestroy {
	C8Y_Severity = C8Y_Severity;
	severityList= Object.values(C8Y_Severity); 

	AlarmChannelType = AlarmChannelType;
	alarmChannelTypes = Object.values(AlarmChannelType);

	routerSubscription: Subscription;
	alarmChannelDetails: AlarmChannel;
	submitted: boolean;
	crudDeviceForm: FormGroup;
	mode: string;
	isSuperAdmin: boolean;
	isAdmin: boolean;

	isEditable: boolean;
	gridState: GridState;

	memberType: AlarmChannelType;

	constructor(
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastHelper: ToastHelper,
		private matDialog: MatDialog,
		private authenticationService: AuthenticationService,
		private approvalDialogHelper: ApprovalDialogHelper,
		private alarmChannelService: AlarmChannelService,
	) {
		this.alarmChannelDetails = new AlarmChannel();

		this.crudDeviceForm = this.formBuilder.group({
			name: new FormControl('', [Validators.required]),
			active: new FormControl('', []),
			description: new FormControl('', []),
			channelType: new FormControl('', [Validators.required]),

			twitterAccessToken: new FormControl('', []),
			twitterAccessTokenSecret: new FormControl('', []),
			twitterConsumerKey: new FormControl('', []),
			twitterConsumerSecret: new FormControl('', []),

			webHookUrl: new FormControl('', []),
			webHookAuthType: new FormControl('', []),
			webHookAuthUser: new FormControl('', []),
			webHookAuthPassword: new FormControl('', []),
			webHookAuthBearer: new FormControl('', []),

			slackUrl: new FormControl('', []),

			threemaIdFrom: new FormControl('', []),
			threemaIdTo: new FormControl('', []),
			threemaPrivateKey: new FormControl('', []),
			threemaSecret: new FormControl('', []),

			diveraFk: new FormControl('', []),
			diveraAddress: new FormControl('', []),
			diveraRic: new FormControl('', []),
			diveraPerson: new FormControl('', []),
			diveraAccesskey: new FormControl('', []),

			alamosFeIdentifikation: new FormControl('', []),
			alamosCustomerIdentifikation: new FormControl('', []),
			alamosPassword: new FormControl('', []),

			alamosSender: new FormControl('', []),
			alamosAuthorization: new FormControl('', []),

			alamosCallerName: new FormControl('', []),
			alamosCallerContact: new FormControl('', []),

			alamosKeyWord: new FormControl('', []),
			alamosKeyWordDescription: new FormControl('', []),

			alamosUnits: new FormControl('', []),

			c8yHost: new FormControl('', []),
			c8yUser: new FormControl('', []),
			c8yPassword: new FormControl('', []),
			c8yEventType: new FormControl('', []),
			c8yAlarmType: new FormControl('', []),
			c8ySourceId: new FormControl('', []),
			c8ySeverity: new FormControl('', []),
			c8yFragmentName: new FormControl('', []),
			c8yFragmentContent: new FormControl('', []),

		});
		this.submitted = false;
	}

	ngOnInit(): void {
		if (this.router.getCurrentNavigation()?.extras?.state) {
			this.gridState = this.router.getCurrentNavigation().extras
				.state as GridState;
		}

		const data = this.route.snapshot.data;
		if (data.mode) {
			this.mode = data.mode;
		}

		this.isSuperAdmin = this.authenticationService.isSuperAdmin();
		this.isAdmin = this.authenticationService.isAdmin();

		this.loadAlarmGroup();

		this.isEditable = true;
		if (this.mode === 'add') {
			this.alarmChannelDetails.active = true;
		} else if (this.mode === 'edit') {
		} else {
			this.isEditable = false;
			this.f.name.disable();
			this.f.description.disable();
			this.f.active.disable();
		}
		this.dataToForm();

	}

	ngOnDestroy() { }

	private dataToForm() {
		this.crudDeviceForm.patchValue({
			name: this.alarmChannelDetails.name,
			description: this.alarmChannelDetails.description,
			active: this.alarmChannelDetails.active,
			channelType: this.alarmChannelDetails.channelType,

			threemaIdFrom: this.alarmChannelDetails.channelProperties?.threemaIdFrom,
			threemaIdTo: this.alarmChannelDetails.channelProperties?.threemaIdTo,
			threemaSecret: this.alarmChannelDetails.channelProperties?.threemaSecret,
			threemaPrivaateKey: this.alarmChannelDetails.channelProperties?.threemaPrivaateKey,

			slackUrl: this.alarmChannelDetails.channelProperties?.slackUrl,

			webHookUrl: this.alarmChannelDetails.channelProperties?.webHookUrl,
			webHookAuthType: this.alarmChannelDetails.channelProperties?.webHookAuthType,
			webHookAuthUser: this.alarmChannelDetails.channelProperties?.webHookAuthUser,
			webHookAuthPassword: this.alarmChannelDetails.channelProperties?.webHookAuthPassword,
			webHookAuthBearer: this.alarmChannelDetails.channelProperties?.webHookAuthBearer,

			twitterConsumerKey: this.alarmChannelDetails.channelProperties?.twitterConsumerKey,
			twitterConsumerSecret: this.alarmChannelDetails.channelProperties?.twitterConsumerSecret,
			twitterAccessToken: this.alarmChannelDetails.channelProperties?.twitterAccessToken,
			twitterAccessTokenSecret: this.alarmChannelDetails.channelProperties?.twitterAccessTokenSecret,

			diveraAccesskey: this.alarmChannelDetails.channelProperties?.diveraAccesskey,
			diveraFk: this.alarmChannelDetails.channelProperties?.diveraFk,
			diveraAddress: this.alarmChannelDetails.channelProperties?.diveraAddress,
			diveraRic: this.alarmChannelDetails.channelProperties?.diveraRic,
			diveraPerson: this.alarmChannelDetails.channelProperties?.diveraPerson,

			alamosFeIdentifikation: this.alarmChannelDetails.channelProperties?.alamosFeIdentifikation,
			alamosCustomerIdentifikation: this.alarmChannelDetails.channelProperties?.alamosCustomerIdentifikation,
			alamosPassword: this.alarmChannelDetails.channelProperties?.alamosPassword,
			alamosSender: this.alarmChannelDetails.channelProperties?.alamosSender,
			alamosAuthorization: this.alarmChannelDetails.channelProperties?.alamosAuthorization,
			alamosCallerName: this.alarmChannelDetails.channelProperties?.alamosCallerName,
			alamosCallerContact: this.alarmChannelDetails.channelProperties?.alamosCallerContact,
			alamosKeyWord: this.alarmChannelDetails.channelProperties?.alamosKeyWord,
			alamosKeyWordDescription: this.alarmChannelDetails.channelProperties?.alamosKeyWordDescription,
			alamosUnits: this.alarmChannelDetails.channelProperties?.alamosUnits,

			c8yHost: this.alarmChannelDetails.channelProperties?.c8yHost,
			c8yUser: this.alarmChannelDetails.channelProperties?.c8yUser,
			c8yPassword: this.alarmChannelDetails.channelProperties?.c8yPassword,
			c8yEventType: this.alarmChannelDetails.channelProperties?.c8yEventType,
			c8yAlarmType: this.alarmChannelDetails.channelProperties?.c8yAlarmType,
			c8ySourceId: this.alarmChannelDetails.channelProperties?.c8ySourceId,
			c8ySeverity: this.alarmChannelDetails.channelProperties?.c8ySeverity,
			c8yFragmentName: this.alarmChannelDetails.channelProperties?.c8yFragmentName,
			c8yFragmentContent: this.alarmChannelDetails.channelProperties?.c8yFragmentContent,
		});
	}

	async loadAlarmGroup() {
		const url = this.route.snapshot.params;
		this.alarmChannelDetails = await this.alarmChannelService.getDetail(url.id);
		this.dataToForm();
	}

	get f() {
		return this.crudDeviceForm.controls;
	}

	toggleActive() {
		this.alarmChannelDetails.active = !this.alarmChannelDetails.active;
		this.crudDeviceForm.patchValue({
			active: this.alarmChannelDetails.active,
		});
	}

	saveAlarmChannel() {
		this.submitted = true;

		if (this.crudDeviceForm.invalid) {
			return;
		}

		this.alarmChannelDetails.name = this.f.name.value;
		this.alarmChannelDetails.description = this.f.description.value;
		this.alarmChannelDetails.channelType = this.f.channelType.value;

		console.debug(this.alarmChannelDetails.channelType);

		switch (this.alarmChannelDetails.channelType) {
			case AlarmChannelType.TWITTER:
				this.alarmChannelDetails.channelProperties = {
					twitterConsumerKey: this.f.twitterConsumerKey.value,
					twitterConsumerSecret: this.f.twitterConsumerSecret.value,
					twitterAccessToken: this.f.twitterAccessToken.value,
					twitterAccessTokenSecret: this.f.twitterAccessTokenSecret.value,
					name: this.f.name.value,
				};
				break;
			case AlarmChannelType.SLACK:
				this.alarmChannelDetails.channelProperties = {
					slackUrl: this.f.slackUrl.value,
					name: this.f.name.value,
				};
				break;
			case AlarmChannelType.THREEMA:
				this.alarmChannelDetails.channelProperties = {
					threemaIdTo: this.f.threemaIdTo.value,
					threemaIdFrom: this.f.threemaIdFrom.value,
					threemaPrivateKey: this.f.threemaPrivateKey.value,
					threemaSecret: this.f.threemaSecret.value,
					name: this.f.name.value,
				};
				break;
			case AlarmChannelType.WEBHOOK:
				this.alarmChannelDetails.channelProperties = {
					webHookUrl: this.f.webHookUrl.value,
					name: this.f.name.value,
					webHookAuthType: this.f.webHookAuthType.value,
					webHookAuthUser: this.f.webHookAuthUser.value,
					webHookAuthPassword: this.f.webHookAuthPassword.value,
					webHookAuthBearer: this.f.webHookAuthBearer.value,
				};
				break;
			case AlarmChannelType.DIVERA_ALARM:
			case AlarmChannelType.DIVERA_NEWS:
				this.alarmChannelDetails.channelProperties = {
					diveraFk: this.f.diveraFk.value,
					diveraAddress: this.f.diveraAddress.value,
					diveraRic: this.f.diveraRic.value,
					diveraPerson: this.f.diveraPerson.value,
					diveraAccesskey: this.f.diveraAccesskey.value,
					name: this.f.name.value,
				};
				break;
			case AlarmChannelType.ALAMOS_ALARM:
				this.alarmChannelDetails.channelProperties = {
					alamosFeIdentifikation: this.f.alamosFeIdentifikation.value,
					alamosCustomerIdentifikation: this.f.alamosCustomerIdentifikation.value,
					alamosPassword: this.f.alamosPassword.value,
					alamosSender: this.f.alamosSender.value,
					alamosAuthorization: this.f.alamosAuthorization.value,
					alamosCallerName: this.f.alamosCallerName.value,
					alamosCallerContact: this.f.alamosCallerContact.value,
					alamosKeyWord: this.f.alamosKeyWord.value,
					alamosKeyWordDescription: this.f.alamosKeyWordDescription.value,
					alamosUnits: this.f.alamosUnits.value,
				}
				break;
			case AlarmChannelType.C8Y_ALARM:
				this.alarmChannelDetails.channelProperties = {
					c8yHost: this.f.c8yHost.value,
					c8yUser: this.f.c8yUser.value,
					c8yPassword: this.f.c8yPassword.value,
					c8yAlarmType: this.f.c8yAlarmType.value,
					c8ySourceId: this.f.c8ySourceId.value,
					c8ySeverity: this.f.c8ySeverity.value
				}
				break;
			case AlarmChannelType.C8Y_EVENT:
				this.alarmChannelDetails.channelProperties = {
					c8yHost: this.f.c8yHost.value,
					c8yUser: this.f.c8yUser.value,
					c8yPassword: this.f.c8yPassword.value,
					c8ySourceId: this.f.c8ySourceId.value,
					c8yEventType: this.f.c8yEventType.value
				}
				break;
			case AlarmChannelType.C8Y_OPERATION:
				this.alarmChannelDetails.channelProperties = {
					c8yHost: this.f.c8yHost.value,
					c8yUser: this.f.c8yUser.value,
					c8yPassword: this.f.c8yPassword.value,
					c8ySourceId: this.f.c8ySourceId.value,
					c8yFragmentName: this.f.c8yFragmentName.value,
					c8yFragmentContent: this.f.c8yFragmentContent.value,
				}
				break;
			default:
		}

		try {
			this.alarmChannelService
				.saveDetail(this.alarmChannelDetails)
				.then((result) => {
					this.alarmChannelDetails = result;
					this.toastHelper.openSnackBar('Alarmgruppe gespeichert!', 'SUCCESS');
					this.location.back();
				});
		} catch (e) {
			if (this.mode === 'add') {
			} else {
				this.toastHelper.openCrudChangeError();
			}
		}
	}

	deleteAlarmChannel() {
		const dialogRef = this.approvalDialogHelper.openApprovalDialog(
			'Möchten Sie diese Alarmkanal wirklich löschen?',
		);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				try {
					this.alarmChannelService.delete(this.alarmChannelDetails.id).then(() => {
						this.toastHelper.openSnackBar('Alarmkanal gelöscht!', 'SUCCESS');
						this.back();
					});
				} catch (e) {
					this.toastHelper.openCrudDeleteError();
				}
			}
		});
	}

	back() {
		this.router.navigate(['/dashboard', 'alarmChannels']);
	}
}
