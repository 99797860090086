import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { ExportFile, ExportFileStation } from '../../../../../models/exportFile';
import { LookupService } from '../../../../../services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ExportFileService } from 'src/app/services/exportFile.service';
import { DataTableOptions, GridState, TableActionType, TitleSize } from 'src/app/shared/data-table/data-table.model';
import { formatNumber } from '@angular/common';
import { Firmware } from 'src/app/models/firmware';
import { FirmwareService } from 'src/app/services/firmware.service';

@Component({
  selector: 'app-firmware-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class FirmwareDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  firmware: Firmware;
  submitted: boolean;

  crudForm: FormGroup;

  templateList: LookupEntry[];

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  newCode: string;

  firmwareError: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private firmwareService: FirmwareService,
    private lookupService: LookupService) {

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      active: new FormControl('', []),
      firmwareVersion: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+')]),
      description: new FormControl('', []),
      sensorBoxTemplateId: new FormControl('', [Validators.required]),
      code: new FormControl('', []),
    });


    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';

    this.firmwareError = null;
  }

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadFirmware();
    } else {
      this.f.code.setValidators( [Validators.required]);
      this.firmware = {
        active: true,
        name: '',
        description: '',
        id: null,
        firmwareVersion: null,
        sensorBoxTemplate: null,
        code: null,
        displayText: null
      };
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadFirmware();
      });

    this.lookupService.getSensorBoxTemplateList().then(result => {
      this.templateList = result;
    });
    console.debug('3');

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {
    this.crudForm.patchValue({
      name: this.firmware.name,
      active: this.firmware.active,
      description: this.firmware.description,
      sensorBoxTemplateId: this.firmware.sensorBoxTemplate?.id,
      firmwareVersion: this.firmware.firmwareVersion,
    });

  }

  loadFirmware() {
    const url = this.route.snapshot.params;
    if (this.mode === "edit") {
      this.firmwareService.getFirmware(url.id).then(data => {
        this.firmware = data;
        this.dataToForm();
      });
    }
  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    const _this = this;
    _this.firmware.active = !_this.firmware.active;
    _this.crudForm.patchValue({
      active: _this.firmware.active,
    });
  }

  async saveExportFile() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.firmware.name = this.f.name.value;
    this.firmware.active = this.f.active.value;
    this.firmware.description = this.f.description.value;
    this.firmware.firmwareVersion = this.f.firmwareVersion.value;

    this.firmware.sensorBoxTemplate = { id: this.f.sensorBoxTemplateId.value, name: '' };

    console.debug(this.newCode);

    if (this.newCode) {
      this.firmware.code = this.newCode;
    }

    try {
      this.firmwareService.save(this.firmware).then(result => {
        this.firmware = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('Firmware gespeichert!', 'SUCCESS');
        this.goBack();
      }).catch(error => {
        this.toastHelper.openSnackBar('Fehler beim Speichern der Firmware!', 'ERROR');
      });

    } catch (e) {
      this.toastHelper.openSnackBar('Fehler beim Speichern der Firmware!', 'ERROR');
    }
  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'firmware', 'overview']);
  }

  exportFileTypes(): { label: string, value: string }[] {
    return [];
  }

  getNumberFromHexStr(str: string): number {
    const digit = '0123456789ABCDEF';
    let result = 0;
    for (let i = 0; i < str.length; i++) {
      let digitValue = digit.indexOf(str.charAt(i));
      if (digitValue < 0) {
        throw "String is not HEX";
      }
      result = result * 16 + digitValue;
    }
    return result;
  }

  async codeChanged(event: any) {
    const file: File = event.target.files[0];
    const fileContent = await file.text();

    if (!fileContent.startsWith('@11000')) {
      this.firmwareError = 'Datei beginnt nicht mit \'@11000\'';
    } else {
      var fileContentBytes = fileContent.substring(8, fileContent.indexOf('\n', 8) ).split(' ');
      console.debug(fileContentBytes);

      this.crudForm.patchValue({
        firmwareVersion: this.getNumberFromHexStr(fileContentBytes[6]),
      });

      this.newCode = fileContent;
    }


  }


}
