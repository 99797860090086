import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { AlarmLevelStates } from 'src/app/models/apiResults';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { Store } from '@ngxs/store';
import { AlarmChannelService } from '@app/state/noysee/alarmChannel.service';
import { AlarmChannel } from '@app/state/noysee/models/alarmChannel';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-alarmChannels-overview',
  templateUrl: './alarmChannels.component.html',
  styleUrls: ['./alarmChannels.component.scss'],
})
export class OverviewComponent implements OnInit, AfterViewInit {
  dataTableOptions: DataTableOptions<AlarmChannel>;
  list: AlarmChannel[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private alarmChannelService: AlarmChannelService,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private router: Router,
    private store: Store,
  ) {
    // this.noyseeDeviceSubscription = null;
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    const _this = this;
    _this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    _this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'alarmChannel.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'alarmChannel.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () =>
            this.router.navigate(['/dashboard', 'alarmChannels', 'add'], {
              queryParams: { ctx: this.getGridState() },
            }),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'general.crud.actions.edit',
            icon: 'app-pencil',
            actionFn: (alarmRule: AlarmChannel) =>
              this.editAlarmChannel(alarmRule.id),
          },
          {
            label: 'general.crud.actions.delete',
            icon: 'delete',
            actionFn: (alarmRule: AlarmChannel) =>
              this.deleteAlarmChannel(alarmRule.id),
          },
        ],
      },
      rowStyleFn: (alarmRule: AlarmChannel) => {
        return !alarmRule.active
          ? {
              'background-color': 'rgba(204, 204, 204, 0.6)',
              border: 'solid 1.5px white',
            }
          : {
              border: 'solid 1.5px white',
            };
      },

      columnDef: [
        {
          key: 'id',
          displayName: 'alarmChannel.table.columns.id',
          width: '10%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'name',
          displayName: 'alarmChannel.table.columns.name',
          width: this.isSuperAdmin ? '60%' : '70%',
          sortable: true,
          styleFn: (alarmRule: AlarmChannel) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (alarmRule: AlarmChannel) =>
            this.router.navigate([
              '/dashboard',
              'alarmChannels',
              alarmRule.id,
              'edit',
            ]),
        },
        {
          key: 'channelType',
          displayName: 'alarmChannel.table.columns.type',
          width: '10%',
          sortable: true,
          valueTransform: (alarmRule: AlarmChannel) =>
            `alarmChannel.channelType.${alarmRule.channelType}`,
        },
        {
          key: 'state',
          displayName: 'alarmChannel.table.columns.state',
          width: '10%',
          sortable: true,
          valueTransform: (alarmRule: AlarmChannel) =>
            `general.booleanState.${alarmRule.active}`,
        },
      ],
    };

    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      console.debug('Focus Customer changed');
      this.loadList();
    });
  }

  ngAfterViewInit(): void {}

  getStateColor(state: AlarmLevelStates) {
    switch (state) {
      case AlarmLevelStates.NORMAL:
        return '#c8d89c';
      case AlarmLevelStates.LIMIT_1:
      case AlarmLevelStates.ERROR:
      case AlarmLevelStates.DEFECTIVE:
      case AlarmLevelStates.DISABLED:
        return 'rgba(255, 171, 49, 0.4)';
      case AlarmLevelStates.LIMIT_2:
      case AlarmLevelStates.LIMIT_3:
      case AlarmLevelStates.LIMIT_4:
      case AlarmLevelStates.LIMIT_5:
        return '#ffc7cc';
      case AlarmLevelStates.PLANNED:
      default: // No known state is active
        return 'rgba(204, 204, 204, 0.6)';
    }
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  loadList() {
    this.alarmChannelService.getList().then((result) => {
      this.list = result;
    });
  }

  editAlarmChannel(alarmRuleId: number) {
    this.router.navigate(['/dashboard', 'alarmChannels', alarmRuleId, 'edit']);
  }

  deleteAlarmChannel(alarmRuleId: number) {
    const _this = this;
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diese Alarmregel wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.alarmChannelService.delete(alarmRuleId).then((result) => {
            _this.toastHelper.openSnackBar('Alarmregel gelöscht!', 'SUCCESS');
            this.loadList();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
