import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SensorBoxCommandQueueEntry } from '../../../../state/noysee/models/sensor';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import {
  GridState,
  DataTableOptions,
  TitleSize,
  TableActionType,
  TableFilterType,
} from '../../../../shared/data-table/data-table.model';
import { NoyseeService } from '../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import moment from 'moment';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { SensorActions } from 'src/app/state/noysee/sensor.action';

@Component({
  selector: 'app-box-command-queue',
  templateUrl: './box-command-queue.component.html',
  styleUrls: ['./box-command-queue.component.scss'],
})
export class BoxCommandQueueComponent implements OnInit {
  mode: string;

  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  dataTableOptions: DataTableOptions<SensorBoxCommandQueueEntry>;
  commandQueue: SensorBoxCommandQueueEntry[];

  destroy$ = new ReplaySubject<void>(1);
  currentSensorBox$ = this.store.select(SensorState.currentSensor).pipe(
    takeUntil(this.destroy$),
    filter((sensor) => sensor !== null),
    tap((sensor) => {
      this.loadSensorBoxCommandQueue(sensor.id);
    }),
  );

  isSuperAdmin: boolean;
  isAdmin: boolean;

  ctx: GridState = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private noyseeService: NoyseeService,
  ) {
    this.mode = '';

    this.isSuperAdmin = false;
    this.isAdmin = false;
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    // initial fetch of sensorBox
    this.store.dispatch(
      new SensorActions.Select(
        parseInt(this.route.snapshot.paramMap.get('id'), 10),
      ),
    );

    // Fetch new sensorBox if id changes
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(
          new SensorActions.Select(
            parseInt(this.route.snapshot.paramMap.get('id'), 10),
          ),
        );
      }
    });

    this.dataTableOptions = {
      title: 'commandQueue.headerLabel',
      titleSize: TitleSize.H2,
      filter: true,
      tableActions: [{}],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'commandQueue.table.actions.delete',
            icon: 'app-delete',
            actionFn: (commandQueue: SensorBoxCommandQueueEntry) =>
              this.removeCommand(commandQueue),
          },
        ],
      },
      rowStyleFn: (commandQueue: SensorBoxCommandQueueEntry) => {
        return {
          'background-color': this.getStateColor(),
          border: 'solid 1.5px white',
        };
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'commandQueue.table.columns.id',
          width: '10%',
          sortable: true,
        },
        {
          key: 'command',
          displayName: 'commandQueue.table.columns.command',
          width: '15%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            commandQueue.commandText,
        },
        {
          key: 'value',
          displayName: 'commandQueue.table.columns.status',
          width: '5%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            commandQueue.status,
        },
        {
          key: 'state',
          displayName: 'commandQueue.table.columns.statusText',
          width: '15%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            `commandQueue.state.${commandQueue.statusText}`,
          filterType: TableFilterType.SELECT,
          filterValues: this.getStates(),
        },
        {
          key: 'createTime',
          displayName: 'commandQueue.table.columns.createTime',
          width: '15%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            moment(commandQueue.createTimestamp).format('DD.MM.YYYY HH:mm'),
        },
        {
          key: 'transmitTime',
          displayName: 'commandQueue.table.columns.transmitTime',
          width: '15%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            moment(commandQueue.transmitTimestamp).format('DD.MM.YYYY HH:mm'),
        },
        {
          key: 'ackTime',
          displayName: 'commandQueue.table.columns.ackTime',
          width: '15%',
          sortable: true,
          valueTransform: (commandQueue: SensorBoxCommandQueueEntry) =>
            moment(commandQueue.ackTimestamp).format('DD.MM.YYYY HH:mm'),
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  getStateColor() {
    return 'rgba(204, 204, 204, 0.6)';
  }

  getStates(): { label: string; value: string }[] {
    const states = [];
    // for (const state of Object.values().filter(value => typeof value === 'number')) {
    //   states.push({
    //     label: `sensor.state.${state}`,
    //     value: state
    //   });
    // }
    return states;
  }

  loadSensorBoxCommandQueue(id: number) {
    this.noyseeService.commandQueue(id).then((result) => {
      this.commandQueue = result;
    });
  }

  gotoNoyseeDevice(sno: String) {
    this.router.navigate(['/dashboard', 'admin', 'devices', 'detail', sno]);
  }

  backToSensorDetail(id?: number) {
    if (id) {
      this.router.navigate(['/dashboard', 'sensors', 'detail', id]);
    } else {
      this.router.navigate(['/dashboard', 'sensors', 'overview']);
    }
  }

  removeCommand(commandQueue: SensorBoxCommandQueueEntry) {}
}
