import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService } from '../../../../../services/devices.service';
import { NoyseeDevice } from '../../../../../models/noyseeDevice';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { DataTableOptions, GridState, TableActionType, TableFilterType, TitleSize } from '../../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from 'src/app/helpers/toast.helper';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { FirmwareService } from 'src/app/services/firmware.service';
import { Firmware } from 'src/app/models/firmware';
import { LookupEntry } from 'src/app/models/apiResults';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-sensors-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  isSuperAdmin: boolean;
  isAdmin: boolean;

  dataTableOptions: DataTableOptions<Firmware>;
  list: Firmware[];
  gridState: GridState = null;
  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  sensorBoxTemplateList: any[];

  constructor(private firmwareService: FirmwareService,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private toastHelper: ToastHelper,
    private lookupService: LookupService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private router: Router) {
    this.isSuperAdmin = false;
  }

  async ngOnInit() {
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.loadFirmwareList();

    this.lookupService.getSensorBoxTemplateList().then(r => {
      this.sensorBoxTemplateList = r.map((entry) => {
        return { value: entry.id, label: entry.name };
      });
      console.debug(this.sensorBoxTemplateList);
    });

    this.initTable();

  }

  loadFirmwareList() {
    this.firmwareService.getList().then(data => {
      this.list = data;
    });
  }

  initTable() {
    this.dataTableOptions = {
      title: 'firmware.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'firmware.table.actions.add',
          rule: () => this.authenticationService.isSuperAdmin() || this.authenticationService.isAdmin(),
          actionFn: () => this.router.navigate(['/dashboard', 'admins', 'firmware', 'add'],
            { queryParams: { ctx: this.getGridState() } })
        }
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'firmware.table.actions.edit',
            icon: 'app-pencil',
            actionFn: (row: Firmware) => this.router.navigate(['/dashboard', 'admins', 'firmware', row.id, 'edit'])
          },
          {
            label: 'firmware.table.actions.delete',
            icon: 'app-view',
            actionFn: (row: Firmware) => this.deactivateFirmware(row)
          },
        ]
      },
      columnDef: [{
        key: 'id',
        displayName: 'firmware.table.columns.id',
        width: '5%',
        sortable: true,
        hidden: !this.isSuperAdmin,
      }, {
        key: 'name1',
        displayName: 'firmware.table.columns.sensorBoxTemplate',
        width: '15%',
        sortable: true,
        // filterType: TableFilterType.SELECT,
        // filterValues: this.sensorBoxTemplateList,
        valueTransform: (row: Firmware) => row.sensorBoxTemplate?.name,
      }, {
        key: 'name',
        displayName: 'firmware.table.columns.name',
        width: '20%',
        sortable: true,
        styleFn: (row: Firmware) => {
          return {
            cursor: 'pointer',
            color: '#0e0ec4',
            'font-weight': 'bold'
          };
        },
        actionFn: (row: Firmware) => this.router.navigate(['/dashboard', 'admins', 'firmware', row.id, 'edit'])
      }, {
        key: 'description',
        displayName: 'firmware.table.columns.description',
        width: '50%',
        sortable: true,
      }, {
        key: 'firmwareVersion',
        displayName: 'firmware.table.columns.firmwareVersion',
        width: '10%',
        sortable: true,
      }, {
        key: 'active',
        displayName: 'firmware.table.columns.state',
        width: '5%',
        sortable: true,
        valueTransform: (row: Firmware) => `general.booleanState.${row.active}`,
      }

      ]
    };

  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(this.dataTable.getGridState());
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  addNoyseeDevice() {
  }

  showFirmware(firmware: Firmware) {
    this.router.navigate(['/dashboard', 'admins', 'firmware', firmware.id, 'edit']);
  }

  deactivateFirmware(firmware: Firmware) {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie dieses Firmware wirklich deaktivieren?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.firmwareService.delete(firmware.id).then(result => {
            this.firmwareService.getList().then(data => {
              this.list = data;
              this.toastHelper.openSnackBar('Firmware erfolgreich deactiviert!', 'SUCCESS');
              this.loadFirmwareList();
            });
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }


}
