
export class WarningSource {
  id: string;
  name: string;
}

export class Warning {

  id: number;
  warningSource: WarningSource;
  senderName: string;

  identifier: string;

  agsList: string[];

  createTimestamp: Date;
  lastUpdateTimestamp: Date;
  cancelTimestamp: Date;

  code: string;
  msgType: string;
  responseType: string;

  urgency: string;
  severity: string;
  certainty: string;

  headline: string;
  description: string;
  instruction: string;
  web: string;
  contact: string;

  effective: Date;
  onset: Date;
  expires: Date;

  recipientMediaRegional: boolean;
  recipientMediaCrossRegional: boolean;
  recipientNina: boolean;
  recipientSirene: boolean;
  recipientWarningDevices: boolean;

  event: string;


}

