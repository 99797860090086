
    <p *ngIf="!config?.editable" [class.long-text]="value.length > 40">{{ value | translate }}</p>
    <div class="edit-comment-container" *ngIf="config.editable === true && data.edit === true" [formGroup]="editorForm">
      <span class="input-wrapper">
        <input id="input-comment" matInput type="text" [formControlName]="fieldName" [maxLength]="255">
        <mat-icon class="blue-icon ch-margin-left-4" (click)="submitValue()">done</mat-icon>
      </span>
      <label for="input-comment">{{f[fieldName]?.value?.length ?? 0}}/255</label>
    </div>
    <p *ngIf="config.editable === true && data.edit === false" [class.has-text]="value">{{ value }}</p>



