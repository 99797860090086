import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ActionMenuCellRenderer } from './cell-renderer/action-menu.cell-renderer';
import { LinkCellRenderer } from './cell-renderer/link.cell-renderer';
import { TranslateCellRenderer } from './cell-renderer/translate.cell-renderer';
import { TranslationHeaderComponent } from './cell-renderer/translation.header-component';
import { DateRangeFloatingFilter } from './filter/date-range.filter';
import { SelectionFloatingFilter } from './filter/selection.filter';
import { LoadingOverlay } from './overlay/loading-overlay.component';
import { NoRowsOverlay } from './overlay/no-rows-overlay.component';
import { ExportButtonComponent } from './table/footer/export-button/export-button.component';
import { FooterComponent } from './table/footer/footer.component';
import { PaginationComponent } from './table/footer/pagination/pagination.component';
import { HeaderComponent } from './table/header/header.component';
import { TableComponent } from './table/table.component';
import { DateCellEditorComponent } from './cell-editor/date.cell-editor';
import { SelectCellEditor } from './cell-editor/select.cell-editor';
import { CheckboxCellRenderer } from './cell-renderer/checkbox.cell-renderer';
import { MatCheckboxModule } from '@angular/material/checkbox';

const EXPOSED = [
  // Table Component
  TableComponent,
  // Cell Renderer
  ActionMenuCellRenderer,
  LinkCellRenderer,
  TranslationHeaderComponent,
  TranslateCellRenderer,
  CheckboxCellRenderer,
  // Filter
  SelectionFloatingFilter,
  DateRangeFloatingFilter,
  // Cell editor
  DateCellEditorComponent,
  SelectCellEditor,
];

const HIDDEN = [
  // Components
  FooterComponent,
  PaginationComponent,
  ExportButtonComponent,
  HeaderComponent,
  NoRowsOverlay,
  LoadingOverlay,
];

@NgModule({
  declarations: [...EXPOSED, ...HIDDEN],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    MatIconModule,
    MatDatepickerModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule.setLocale('de-DE'),
    TranslateModule,
  ],
  providers: [TranslatePipe],
  exports: [...EXPOSED],
})
export class SharedAgGridModule {}
