<div *ngIf="currentSensor" class="row">
  <div class="col-12 ch-margin-top-16">
    <div class="container contacts-container">

      <div class="row ch-padding-top-8 header-row">
        <div class="col-6 contacts-header">
          <p appTranslate="sensor.description.headerLabel"></p>
        </div>
      </div>

      <div class="row ch-padding-top-8 header-row ch-margin-top-16">
        <div class="col-6 contacts-settings-header">
          <p appTranslate="sensor.adminShortcuts.hardwareState"></p>
        </div>
      </div>

      <div class="row ch-padding-top-8 header-row ch-margin-top-4">
        <div class="col-6 contacts-settings-header">
            <button mat-flat-button class="btn-primary" (click)="setHardwareState( SensorBoxHardwareStates.NORMAL)"
              appTranslate="sensor.state.0">
            </button>
            &nbsp;
            <button mat-flat-button class="btn-primary" (click)="setHardwareState( SensorBoxHardwareStates.DEFECTIVE)"
              appTranslate="sensor.state.-2">
            </button>
            &nbsp;
            <button mat-flat-button class="btn-primary" (click)="setHardwareState( SensorBoxHardwareStates.DISABLED)"
              appTranslate="sensor.state.-3">
            </button>
        </div>
        <div class="col-6 contacts-settings-header" *ngIf="isAdmin || isSuperAdmin">
        </div>

      </div>



    </div>
  </div>
</div>