export const DE = {
	general: {
		show: 'anzeigen',
		from: 'Von',
		to: 'Bis',
		hour: 'Stunde',
		day: 'Tag',
		week: 'Woche',
		month: 'Monat',
		time: 'Uhrzeit',
		noFilter: 'Kein Filter',
		filters: {
			headerLabel: 'Filter',
			filterTypes: {
				waterways: 'Wasserwege',
			},
		},
		pages: 'Seite {{current}} von {{total}}',
		error: 'Fehler',
		warning: 'Warnung',
		ok: 'Okay',
		select: 'Auswählen',
		cancel: 'Abbrechen',
		loading: 'Bitte warten...',
		timeResolution: {
			DAYHOURS: '24 Stunden',
			WEEKDAYS: '7 Tage',
			MONTHDAYS: '30 Tage',
			MONTHWEEKS: '4 Wochen',
			YEARMONTHS: '12 Monate',
		},
		actions: {
			enable: 'aktivieren',
			disable: 'deaktivieren',
			edit: 'bearbeiten',
			delete: 'löschen',
		},
		booleanState: {
			true: 'aktiv',
			false: 'inaktiv',
			'0': 'inaktiv',
			'1': 'aktiv',
			undefined: '-',
		},
		frontendType: {
			standort: {
				detail: 'Standort',
				liste: 'Standorte',
			},
			weather: {
				detail: 'Wetterstation',
				liste: 'Wetterstationen',
			},
			waterlevel: {
				detail: 'Wasserpegel',
				liste: 'Wasserpegel',
			},
		},
		errorCodes: {
			EMAIL_MISSING: 'eMail-Adresse fehlt',
			DUPLICATE_LOGIN_NAME: 'Login bereits vergeben',
			CUSTOMER_UNKNOWN: 'Kunde unbekannt',
			USER_DOES_NOT_EXIST_OR_LOCKED:
				'Benutzer existiert nicht oder ist gesperrt',
			PASSWORD_TO_SHORT: 'Passwort entspicht nicht den Richtlinien',
			PASSWORDS_DONT_MATCH: 'Passworte stimmen nicht überein',
			PASSWORD_MISSING: 'Passwort fehlt',
			PASSWORD_WRONG: 'Falsches Passwort',
			ERROR_PARSING_RULE: 'Fehler beim parsen der Regelbeschreibung',
			NO_SENSORBOX_SPECIFIED: 'SensorBox wurde nicht angegeben',
			SENSORBOX_DOES_NOT_EXIST: 'SensorBox existiert nicht',
			INCOMPATIBLE_DEVICE: 'Gerät ist nicht kompatibel',
			DEVICE_NOT_READY_TO_INSTALL: 'Gerät kann nicht installiert werden',
			DEVICE_DOES_NOT_EXIST: 'Gerät existiert nicht',
			SENSORBOXTEMPLATE_DOES_NOT_EXIST: 'Gerätevorlage existiert nicht',
			ID_MISSING: 'Identifier fehlt',
			MESSAGETEMPLATE_DOES_NOT_EXIST: 'Nachrichtenvorlage existiert nicht',
			ACCESS_DENIED: 'Zugriff verweigert',
			APPLICATION_IN_PROGRESS: '',
			AGS_DOES_NOT_EXIST: 'AGS existiert nicht',
			INTERNAL_ERROR: 'Interner Fehler',
			NO_USER_SPECIFIED: 'Keine Benutzer angegeben',
		},
		billingInterval: {
			'0': 'einmalig',
			'1': 'monatlich',
			'12': 'jährlich',
		},
		crud: {
			actions: {
				view: 'Ansehen',
				edit: 'Bearbeiten',
				save: 'Speichern',
				cancel: 'Abbrechen',
				delete: 'Löschen',
			},
		},
		label: {
			active: 'Aktiv',
			name: 'Name',
			description: 'Beschreibung',
		},
		copyright: '© Netze BW GmbH 2023. Alle Rechte vorbehalten',
	},
	map: {
		filters: {
			sensors: {
				waterlevel: 'Wasserpegel',
				weather: 'Wetterstation',
				precipArea: 'Flächenniederschlag',
			},
			mapLayers: {
				waterways: 'Wasserwege',
				kachelmann_rain: 'Regenradar',
			},
		},
		controls: {
			timeslider: {
				disabled:
					'Das Regenradar ist für dieses Zoomlevel nicht verfügbar. Bitte rauszoomen.',
			},
		},
	},
	errors: {
		requiredInput: 'Bitte Wert angeben',
		requiredSelection: 'Bitte Wert auswählen',
		phoneNumberInvalid:
			'Bitte eine gültige Telefonnummer angeben (+49 30 123456)',
	},
	table: {
		actions: 'Aktionen',
		pagination: 'Seite {{current}} von {{total}}',
		overlay: {
			noRows: 'Keine Daten vorhanden',
			loading: 'Bitte warten...',
		},
		export: {
			pdf: 'PDF',
			csv: 'CSV',
		},
	},
	'page-not-found': {
		headline: 'Die gesuchte Seite wurde leider nicht gefunden',
		text: 'Es tut uns leid, die von Ihnen aufgerufene Seite existiert nicht oder ist kurzzeitig nicht verfügbar. Es könnte sein, dass Sie eine falsche oder veraltete URL aufgerufen haben. Bitte überprüfen Sie diese noch einmal. Oder versuchen Sie die gewünschte Seite über unsere Startseite zu finden.',
		back: 'Zur Startseite',
	},
	header: {
		'menu-entries': {
			admin: 'Admin',
			alarms: 'Alarme',
			help: 'Hilfe',
			'edit-self': 'Benutzer editieren',
			'change-password': 'Passwort ändern',
			logout: 'Ausloggen',
		},
		help: {
			title: 'Wie können wir Ihnen helfen?',
			text: 'Bitte nehmen Sie bevorzugt per E-Mail Kontakt auf!',
			emailaddress: 'E-Mail Adresse',
			phone: 'Telefonnummer',
			superadmin: 'Super-Admin Versionsinformationen',
			noyseeFrontend: 'Noysee Dashboard',
			noyseeBackend: 'Noysee Backend',
			dockerBuild: 'Docker-Build',
			identityManager: 'Identity-Manager',
		},
	},
	breadcrumb: {
		root: 'Home',
	},
	forms: {
		validationError: {
			maxSize40: 'Maximal 40 Zeichen zulässig',
			maxSize60: 'Maximal 60 Zeichen zulässig',
			maxSize80: 'Maximal 80 Zeichen zulässig',
			maxSize100: 'Maximal 100 Zeichen zulässig',
			lat: 'Breitengrad als Zahl in der Form 12.345 erwartet',
			lon: 'Längengrad als Zahl in der Form 12.345 erwartet',
			int: 'Ganzzahl erwartet.',
		},
		user: {
			passwordHint:
				'<p>Passwort-Kriterien:<br>Mindestens 10 Zeichen und davon</p><ul><li>Mindestens 2 Zahlen</li><li>Mindestens 2 Kleinbuchstaben</li><li>Mindestens 2 Großbuchstaben</li></ul>',
			customer: {
				label: 'Kunde',
				placeholder: '',
				error: '',
			},
			apiKey: {
				label: 'API-Key',
				placeholder: '',
				error: '',
			},
			name: {
				label: 'Name',
				placeholder: 'Bitte tragen Sie einen Namen ein',
				error: 'Bitte Namen angeben.',
			},
			username: {
				label: 'Benutzername',
				error: 'Gültiger Benutzername erforderlich',
				alreadyExists: 'Benutzername ist bereits registriert!',
			},
			oldPassword: {
				label: 'Bisheriges Passwort',
				placeholder: 'Bitte geben Sie Ihr bisheriges Passwort ein',
				invalid: 'Gültiges Passwort erforderlich',
				matchPolicies: 'Passwort erfüllt nicht die Kriterien.',
				error: 'Gültiges Password erforderlich',
			},
			password: {
				label: 'Passwort',
				placeholder: 'Bitte tragen Sie ein Passwort ein',
				invalid: 'Gültiges Passwort erforderlich',
				matchPolicies: 'Passwort erfüllt nicht die Kriterien.',
				error: 'Gültiges Password erforderlich',
			},
			repeatPassword: {
				label: 'Passwort wiederholen',
				placeholder: 'Bitte Passwort erneut eingeben',
				notMatching: 'Passwörter stimmen nicht überein.',
			},
			email: {
				label: 'E-Mail',
				error: 'Bitte gültige E-Mail angeben',
			},
			phone: {
				label: 'Telefon (für Anruf und VOICE)',
				placeholder: '+49 30 123456',
				error: 'Bitte auf Format achten: +49 30 123456',
			},
			mobile: {
				label: 'Mobil (für SMS)',
				placeholder: '+49 30 123456',
				error: 'Bitte auf Format achten: +49 30 123456',
			},
			role: {
				label: 'Rolle',
				tooltip:
					"<table class='role-tooltip'><tr><td>Standardbenutzer</td><td>Kann keine anderen Benutzer einladen oder bearbeiten.</td></tr><tr><td>Admin</td><td>Kann andere Benutzer einladen, deren Rolle und Rechte setzen und alle Standardbenutzer bearbeiten.</td></tr><tr><td>SuperAdmin</td><td>Wartungszugang für Netze-BW Sparte Dienstleistung.</td></tr><tr><td>Technischer Benutzer (API)</td><td>Technischer Benutzer für externen API-Zugriff.</td></tr></table>",
				error: 'Bitte Rolle auswählen',
			},
			'image-upload': {
				select: 'Bild auswählen',
				attach: 'Bild hochladen',
				success: 'Bild erfolgreich hochgeladen!',
				error: 'Upload fehlgeschlagen!',
			},
		},
		forgotPassword: {
			newPassword: {
				label: 'Neues Passwort',
			},
		},
		sensor: {
			name: {
				label: 'Name',
				error: 'Bitte Namen angeben',
			},
			address: 'Adresse',
			latitude: {
				label: 'Breitengrad',
				error: 'Bitte Breitengrad angeben',
			},
			longitude: {
				label: 'Längengrad',
				error: 'Bitte Längengrad angeben',
			},
			datasource: {
				label: 'Quelle',
				error: 'Bitte Quelle angeben',
			},
			limit1: 'Grenzwert 1',
			limit2: 'Grenzwert 2',
			limit3: 'Grenzwert 3',
			limit4: 'Grenzwert 4',
			limit5: 'Grenzwert 5',
			limitDelta: 'Delta',
			validationMin: 'Validierung Min',
			validationMax: 'Validierung Max',
			displayMin: 'Anzeige Min',
			displayMax: 'Anzeige Max',
			image: 'Bilddatei auswählen',
			maxGradientUp: 'Max. Anstieg',
			maxGradientDown: 'Max. Abfall',
			maueInventoryNumber: 'MAuE InventoryNumber',
		},
		customer: {
			name: {
				label: 'Name',
				error: 'Bitte Name angeben',
			},
			ags: {
				label: 'Gemeindeschlüssel (AGS)',
				error: 'Bitte AGS angeben',
			},
			mapCenterLat: {
				label: 'Kartenmitte Länge',
				error: '',
			},
			mapCenterLon: {
				label: 'Kartenmitte Breite',
				error: '',
			},
			zoomDashboard: {
				label: 'Zoom für Dashboard',
				error: '',
			},
			zoomApp: {
				label: 'Zoom für App',
				error: '',
			},
		},
	},
	modals: {
		approval: {
			actions: {
				submit: 'Weiter',
				continue: 'Weiter',
				cancel: 'Abbrechen',
			},
		},
		'delete-user': {
			label: 'Möchten Sie diesen Benutzer wirklich löschen?',
			success: 'Löschen des Benutzers erfolgreich!',
		},
	},
	auth: {
		login: {
			headline: 'Login',
			actions: {
				forgotPassword: {
					label: 'Passwort vergessen?',
					success:
						'Der Link zum Zurücksetzen Ihres Passworts geht Ihnen in den nächsten Minuten per Email zu.',
					error: 'Bitte geben Sie Ihren Benutzernamen ein.',
				},
				loginGuest: 'Als Gast anmelden',
				login: {
					label: 'Anmelden',
					error: 'Bitte überprüfen Sie Benutzername und Passwort.',
				},
			},
		},
		singleSignOn: {
			headline: 'Single Sign-On',
			register: {
				headline: 'Registrierung erforderlich',
				label: 'Möchten Sie sich für NOYSEE registrieren?',
				additionalInformation: {
					system: 'System',
				},
				confirm: 'Ja',
				cancel: 'Abbrechen',
				cancelled:
					'Registrierung wurde abgebrochen, Sie können das Fenster nun schließen.',
			},
			info: {
				AUTHORIZATION_PENDING:
					'Erster Login-Versuch: Der Administrator für Ihre Region wird informiert und Ihre Registrierung prüfen.',
				APPLICATION_IN_PROGRESS:
					'Benutzer wurde noch nicht freigeschaltet. Bitte versuchen Sie es später erneut.',
			},
			error: {
				MISSING_INFORMATION: {
					USERID: 'Benutzer-ID in Payload nicht vorhanden!',
					FULLNAME: 'Name in Payload nicht vorhanden!',
					EMAIL: 'E-Mail in Payload nicht vorhanden!',
					AGS: 'AGS in Payload nicht vorhanden!',
				},
				/* 'invalidRequest': 'Fehlende Benutzerinformationen in Anfrage.',
												'apiKey': {
												  'missing': 'Keinen API-Key gefunden.',
												  'invalid': 'API-Key inkorrekt.'
												},
												 */
				UNAUTHORIZED:
					'Fehler bei der Authorisierung, bitte wenden Sie sich an den Support.',
				AGS_DOES_NOT_EXIST:
					'Für die gewählte Gemeinde/Stadt ist in NOYSEE kein Kunde angelegt. Bei Interesse wenden Sie sich bitte an <a href="mailto:noysee@netze-bw.de">noysee@netze-bw.de.</a>',
				invalidCall:
					'Aufruf entspicht nicht der SSO-Spezifikation von NOYSEE (System oder Token fehlt)',
			},
		},
		forgotPassword: {
			headline: 'Passwort zurücksetzen',
			actions: {
				changePassword: {
					label: 'Passwort ändern',
					success:
						'Ihr Passwort wurde geändert. <br><br>Klicken Sie <a href="/login">hier</a>, um zum Login zu gelangen.',
					generalError: 'Ein Fehler ist aufgetreten!',
					notMatching: 'Passwörter stimmen nicht überein!',
				},
			},
		},
		completeRegistration: {
			headline: 'Einladung vervollständigen',
			invalidLink: 'Einladungslink ungültig!',
			actions: {
				register: {
					label: 'Bestätigen',
					success:
						'Registrierung erfolgreich durchgeführt. <a href="/login">Hier</a> kommen Sie zum Login.',
				},
			},
		},
	},
	admin: {
		headerLabel: 'Admin',
	},
	notification: {
		headerLabel: 'Mitteilungen',
		welcome: 'Herzlich Willkommen',
		lastLogin: 'Letzter Login:',
		actions: {
			unlockUser:
				'Möchten Sie den Benutzer {{name}} wirklich für Single-Sign-On freischalten?',
		},
		label: {
			alertType: {
				sensorBox: 'NOYSEE Standort',
				user: 'User',
				dwd: 'DWD',
				bbk: 'BBK',
				persistent: '',
				mowas: 'MoWaS',
				persistentAlert: 'NOYSEE Warnmeldung',
				unknown: '-',
				userInvited: 'Benutzer eingeladen',
				userRequest: 'Benutzer zu bestätigen',
			},
		},
	},
	sensor: {
		headerLabel: 'Sensoren',
		headerLabelSingular: 'Sensor',
		table: {
			actions: {
				add: 'Standort anlegen',
				edit: 'Bearbeiten',
				'iot-settings': 'IoT-Einstellungen',
				commandQueue: 'Command-Queue',
				replace: 'Gerät Ersetzen',
				open: 'Ansehen',
				purgeSensor: 'Standort endgültig löschen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				value: 'Wert',
				lastValue: 'Letzter Wert',
				state: 'Status',
			},
		},
		calculationType: {
			none: '-',
			'linear.pos': 'result= iotValue * scale + offset',
		},
		state: {
			'-4': 'In Planung',
			'-3': 'Deaktiviert',
			'-2': 'Defekt',
			'-1': 'Ausfall/Fehler',
			'0': 'Normal',
			'1': 'Stufe 1',
			'2': 'Stufe 2',
			'3': 'Stufe 3',
			'4': 'Stufe 4',
			'5': 'Stufe 5',
		},
		type: {
			wasserpegel: 'Wasserpegel',
			abflussPegel: 'Wasserpegel am Ablauf',
			precipDiff: 'Niederschlag (Differenz)',
			precipPer5min: 'Niederschlag (5min)',
			spot_precipPer5min: 'Niederschlag Hotspot (5min)',
			precipPerHour: 'Niederschlag (Stündlich)',
			precipPer6h: 'Niederschlag (6 Stunden)',
			precipPer12h: 'Niederschlag (12 Stunden)',
			precipAbs: 'Niederschlagsumme (Absolut)',
			precipAbs24h: 'Niederschlagsumme Tag',
			precipLast1h: 'Niederschlag (letzte Stunde)',
			precipLast6h: 'Niederschlag (letzte 6h)',
			precipType: 'Niederschlagsart',
			rainRate: 'Regenrate',
			rainRate1h: 'Regenrate (1h)',
			dewpoint: 'Taupunkt',
			humidity: 'Luftfeuchtigkeit',
			pressure: 'Druck',
			temperature: 'Temperatur',
			windAvg: 'Windgeschwindigkeit (Durchschnitt)',
			windChill: 'Gefühlte Temperatur',
			windDirection: 'Windrichtung',
			windSpeed: 'Windgeschwindigkeit (Absolut)',
			dataQuality: 'Datenqualität',
			batteryCap: 'Batterie-Kapazität',
			batteryV: 'Batteriespannung',
			solarPower: 'Solar',
			rssi: 'RSSI',
			voltage: 'Spannung',
		},
		popup: {
			limit1_prefix: 'Warnung',
			limit2_prefix: 'Alarm',
			limit3_prefix: 'Stufe 3',
			limit4_prefix: 'Stufe 4',
			limit5_prefix: 'Stufe 5',
			error: 'Störung im Betrieb.',
			disabled: 'Sensor deaktiviert.',
			defective: 'Sensor wird gewartet/ist gestört.',
			planned: 'Sensor in Planung.',
			wasserpegel: {
				limit1: 'Tendenz Hochwasser!',
				limit2: 'Hochwasser!',
			},
		},
		crud: {
			headlines: {
				add: 'Sensor anlegen',
				edit: 'Sensor bearbeiten',
			},
			actions: {
				save: 'Speichern',
				cancel: 'Abbrechen',
			},
			image: 'Ausschnitt für Sensorstandort bearbeiten',
		},
		information: {
			id: 'Sensor-ID',
			'last-value': 'Letzte Meldung',
			source: 'Datenquelle',
			'weather-prediction': 'Wettervorhersage',
			'weather-data': 'Wetterdaten',
			values: {
				precip6h: 'Regen (6h)',
				precipCurrent: 'Regen',
				wind: 'Wind',
				current: '24h-Ansicht',
				forecast: '3-Tagesansicht',
			},
			forecast: {
				date: {
					today: 'Heute',
					tomorrow: 'Morgen',
					monday: 'Montag',
					tuesday: 'Dienstag',
					wednesday: 'Mittwoch',
					thursday: 'Donnerstag',
					friday: 'Freitag',
					saturday: 'Samstag',
					sunday: 'Sonntag',
				},
				time: {
					morning: 'Morgens',
					afternoon: 'Mittags',
					evening: 'Abends',
					night: 'Nachts',
				},
			},
			'non-existent': 'nicht vorhanden',
			units: {
				temperatur: '°C',
				rain: 'mm/h',
				speed: 'km/h',
				time: 'Uhr',
			},
		},
		chart: {
			day: '1 Tag',
			week: '7 Tage',
			month: '30 Tage',
			'no-value': 'Keine Werte gefunden!',
		},
		values: {
			'weather-station': 'Wetterstation - ',
			overview: 'Übersicht',
			'name-prefix': 'Sensor: ',
			current: 'Aktuelle Messwerte',
			history: 'Messwert-Historie',
			trend: 'Tendenz',
			limit1: 'Grenzwert-Stufe 1',
			limit2: 'Grenzwert-Stufe 2',
			battery: 'Batterie',
		},
		description: {
			headerLabel: 'Informationen',
			limits: 'Grenzwerte',
			publicInfo: 'Öffentliche Informationen',
			internalInfo: 'Interne Informationen',
			'edit-publicInfo': 'ändern',
			'edit-internalInfo': 'ändern',
		},
		adminShortcuts: {
			headerLabel: 'Admin',
			settings: 'Einstellungen',
			hardwareState: 'Hardwarestatus',
			setHardwareState: 'setzen',
		},
		contacts: {
			headline: 'Alarmierungen',
			'settings-limit': 'Grenzwert-Einstellungen',
			'settings-contact': 'Einstellungen Kontaktpersonen',
			'search-placeholder': 'Nach Personen suchen',
			actions: {
				'test-alarm': 'Probealarm',
				'text-message': 'Textnachricht',
				'add-contact': 'Kontakt hinzufügen',
				'sort-alphabetically': 'Alphabetisch',
			},
			values: {
				normal: 'Normalwert',
				limit1: 'Stufe 1',
				limit2: 'Stufe 2',
				email: 'E-Mail',
				phone: {
					label: 'Tel',
					placeholder: 'Placeholder',
				},
				mobile: {
					label: 'Mobil',
					placeholder: 'Placeholder',
				},
				fail: 'Fail',
				call: 'Anruf',
				voice: 'Voice',
				text: 'SMS',
			},
			'not-found': 'Kontakt konnte nicht gefunden werden.',
			crud: {
				headline: 'Welche Kontakte möchten Sie hinzufügen?',
				available: 'Verfügbare Kontakte',
				selected: 'Ausgewählte Kontakte',
				actions: {
					add: 'Hinzufügen',
					cancel: 'Abbrechen',
				},
			},
		},
		'test-alarm': {
			headline:
				'Möchten Sie den eingestellten Probealarm für alle Alarmempfänger des ausgewählten Standortes auslösen?',
			'alarm-level': {
				label: 'Welche Alarmstufe soll getestet werden?',
				all: 'Alle',
				fail: 'Ausfall',
				limit1: 'Stufe 1',
				limit2: 'Stufe 2',
			},
			actions: {
				send: 'Senden',
				cancel: 'Abbrechen',
			},
		},
		'test-message': {
			headline:
				'Möchten Sie die eingegebene N                                                                                                                                                achricht an alle Alarmempfänger des ausgewählten Sensors senden?',
			message: 'Textnachricht',
			actions: {
				send: 'Senden',
				cancel: 'Abbrechen',
			},
		},
		editRenderedInfo: {
			headline: 'Beschreibung bearbeiten',
			label: 'Inhalt',
			actions: {
				save: 'Speichern',
				cancel: 'Abbrechen',
			},
		},
		addAction: {
			headline: {
				prefix: 'Aktion für ',
				suffix: 'festlegen.',
			},
			label: {
				sensorBox: 'Standort',
				actionType: 'Typ',
				channel: 'Alarmkanal',
				link: 'Link',
				additionalAuth: 'Password abfragen',
			},
		},
	},
	customer: {
		headerLabel: 'Kunden ',
		table: {
			actions: {
				add: 'Kunde anlegen',
				edit: 'bearbeiten',
				deactivate: 'deaktivieren',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				state: 'Status',
			},
		},
	},
	exportFile: {
		headerLabel: 'Export-Job',
		table: {
			actions: {
				add: 'Export-Job anlegen',
				export: 'Export Files',
				edit: 'bearbeiten',
				delete: 'Löschen',
			},
			columns: {
				id: 'ID',
				destination: 'Ziel',
				name: 'Name',
				state: 'Status',
				lastExport: 'Letzter Export',
			},
		},
		exportTypes: {
			FLIWAS: 'FLIWAS',
		},
	},
	exportFileStation: {
		headerLabel: 'Stationen',
		table: {
			actions: {},
			columns: {
				columnName: 'Spalte',
				sensorId: 'Sensor ID',
				externalId: 'ext. ID',
				externalName: 'ext. Name',
				externalWaters: 'ext. Gewässer',
				lastValue: 'letzter Wert',
				lastContact: 'letzter Export',
			},
		},
	},
	commandQueue: {
		headerLabel: 'Kommandos',
		table: {
			actions: {
				delete: 'Löschen',
			},
			columns: {
				command: 'Kommando',
				status: 'Status',
				statusText: 'Status Text',
				id: 'ID',
				createTime: 'Erstellt',
				transmitTime: 'Übermittelt',
				ackTime: 'Bestätigt',
			},
		},
		state: {
			PENDING: 'PENDING',
			SENT: 'SENT',
			ACK: 'ACK',
			ERROR: 'ERROR',
			CANCELED: 'CANCELED',
		},
	},
	messageTemplate: {
		headerLabel: 'Benachrichtigungsvorlagen',
		table: {
			actions: {
				add: 'Vorlage anlegen',
				edit: 'bearbeiten',
				delete: 'Löschen',
			},
			columns: {
				id: 'ID',
				templateClass: 'Klasse',
				name: 'Name',
				state: 'Status',
				owner: 'Kunde',
			},
		},
		templateClasses: {
			SEND_PASSWORD: 'Passwort verschicken',
			INVITE_WITH_PASSWORD: 'Mit Passwort einladen',
			RESET_BY_TOKEN: 'Passwortreset per Token',
			WELLCOME: 'Willkommen',
			INVITE_BY_TOKEN: 'Einlaundung per Token',
			ADMIN_TEST_ALARM: '(Admin-Alarm)',
			SENSOR_TEST_ALARM: 'Probealarm',
			SENSOR_CLEAR: 'Entwarnung',
			SENSOR_ALARM: 'Alarmierung',
			SENSOR_FOUND: 'Fehler behoben',
			SENSOR_LOST: 'Fehler aufgetreten',
			SYSTEM: 'Systembenachrichtigung',
			ACTION_START: 'Aktion aktiviert',
			ACTION_END: 'Aktion beendet',
		},
	},
	loraWanConfig: {
		headerLabel: 'LoRaWAN Konfigurationen',
		serverLabel: 'LoRaWAN Server',
		table: {
			actions: {
				add: 'Server anlegen',
				edit: 'bearbeiten',
				delete: 'Löschen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				state: 'Status',
			},
		},
		mode: {
			element: {
				reading: 'element.reading',
				packet: 'element.packet',
			},
		},
	},
	billing: {
		billingInterval: {
			0: 'Einmalig',
			1: 'Monat',
			12: 'Jahr',
			36: '3 Jahre',
		},
		billingState: {
			OPEN: 'offen',
			BILLED: 'in Abrechnung',
			PAYED: 'bezahlt',
			CLOSED: 'geschlossen',
		},
		billingMethod: {
			MAuE: 'MAuE (automatisch)',
			NOYSEE: 'NOYSEE (über MAuE)',
			MANUAL: 'manuell',
			NONE: 'keine Abrechnung',
		},
		products: {
			headerLabel: 'Produkte',
			table: {
				actions: {
					add: 'neu...',
					maueSync: 'MAuE sync',
					view: 'ansehen',
					edit: 'bearbeiten',
				},
				columns: {
					id: 'ID',
					name: 'Name',
					description: 'Beschreibung',
					price: 'Preis',
					billingInterval: 'Intervall',
					dmat: 'DMAT',
				},
			},
		},
		delivery: {
			headerLabel: 'Lieferung',
		},
		deliveries: {
			headerLabel: 'Lieferungen',
			table: {
				actions: {
					add: 'erfassen...',
					view: 'anzeigen',
					edit: 'ändern',
					delete: 'löschen...',
				},
				columns: {
					id: 'ID',
					state: 'Status',
					name: 'Name',
					description: 'Beschreibung',
					amount: 'Stück',
					price: 'Preis',
					billingInterval: 'Intervall',
					dmat: 'DMAT',
					lastBilling: 'letzte Abrechnung',
				},
			},
		},
	},
	warningBroker: {
		headerLabel: 'Warnungen',
		activeWarnings: {
			headerLabel: 'Aktive Warnungen',
			actions: {
				cleanupWarnings: 'neu abfragen',
				resetStateDWD: 'DWD Reset',
			},
			filter: {
				headerLabel: 'Filter anhand AGS',
			},
			table: {
				headerLabel: 'Treffer',
				actions: {
					view: 'anzeigen',
					cancel: 'beenden...',
					delete: 'löschen...',
				},
				columns: {
					id: 'ID',
					warningSource: 'Warnquelle',
					headline: 'Meldung',
					state: 'Status',
					effective: 'Gültig von',
					expires: 'Gültig bis',
					canceled: 'Beendet',
				},
			},
		},
	},
	noyseeDevice: {
		headerLabel: 'Geräteadministration',
		table: {
			actions: {
				add: 'Gerät anlegen',
				edit: 'Gerät bearbeiten',
				delete: 'Gerät löschen',
				sensorBox: 'Standort ansehen',
			},
			columns: {
				id: 'ID',
				noyseeSN: 'Seriennummer',
				uuid: 'UUID',
				hwType: 'Hardware',
				btMac: 'BT-Mac',
				sim: 'SIM',
				imei: 'IMEI',
				state: 'Status',
			},
		},
		state: {
			0: 'Verbaut',
			1: 'Im Test',
			2: 'Getested',
			10: 'Abgebaut',
			11: 'Reparatur',
			50: 'Lager',
			100: 'Verbaut',
			'-1': 'Entsorgt',
			'-2': 'Gestohlen',
		},
	},
	importConfig: {
		headerLabel: 'Importkonfigurationen',
		table: {
			actions: {
				add: 'Konfiguration anlegen',
				edit: 'Konfiguration bearbeiten',
				delete: 'Konfiguration löschen',
				sensorBox: 'Standort ansehen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				type: 'Typ',
				dataSource: 'Quelle',
				lastDuration: 'Laufzeit',
				lastRun: 'letzter Import',
				lastResult: 'Ergebnis',
				state: 'Status',
				sensorBox: 'Standort',
			},
		},
	},
	geoPolygone: {
		headerLabel: 'Gebiete',
		table: {
			actions: {
				add: 'Gebiet anlegen',
				edit: 'Gebiet bearbeiten',
				delete: 'Gebiet löschen',
				sensorBox: 'Standort ansehen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				type: 'Typ',
				polygoneType: 'Gebietstyp',
				state: 'Status',
			},
		},
	},
	alarmRule: {
		headerLabel: 'Alarmregeln',
		headerLabelSingular: 'Alarmregel',
		table: {
			actions: {
				add: 'Alarmregel anlegen',
				edit: 'Alarmregel bearbeiten',
				delete: 'Alarmregel löschen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				alarmLevel: 'Stufe',
				state: 'Status',
			},
		},
		crud: {
			rule: 'Regeldefinition',
			description: 'Beschreibung',
			error: {
				name: 'Bitte einen Namen für die Gruppe wählen!',
			},
			actions: {
				save: 'Speichern',
				cancel: 'Abbrechen',
				delete: 'Löschen',
			},
		},
		modal: {
			delete: 'Möchten Sie diese Alarmregel wirklich löschen?',
		},
		template: {
			alarm: 'Template für Eskalation',
			allClear: 'Template für Deeskalation',
		},
		members: {
			headerLabel: 'zugeordnete Gruppen',
			table: {
				actions: {
					add: 'Gruppe hinzufügen',
					view: 'Mitglieder ansehen',
					delete: 'Löschen',
				},
				columns: {
					id: 'ID',
					name: 'Name',
					alarmLevel: 'Stufe',
					state: 'Status',
				},
			},
		},
	},
	alarmGroup: {
		headerLabel: 'Alarmgruppen',
		headerLabelSingular: 'Alarmgruppe',
		assigned: 'Zugeordnete Alarmgruppen',
		table: {
			actions: {
				add: 'Alarmgruppe anlegen',
				edit: 'Alarmgruppe bearbeiten',
				delete: 'Alarmgruppe löschen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				state: 'Status',
			},
		},
		modal: {
			delete: 'Möchten Sie diese Alarmgruppe wirklich löschen?',
		},
		label: {
			alarming: 'bei Warnung',
			clearing: 'bei Entwarnung',
		},

		actions: {
			add: 'hinzufügen',
			testAlarm: 'Probelalarm',
			testMessage: 'Textnachricht',
		},
		state: {
			true: 'aktiv',
			false: 'inaktiv',
		},
		crud: {
			description: 'Beschreibung',
			error: {
				name: 'Bitte einen Namen für die Gruppe wählen!',
			},
		},
		dialog: {
			headline: {
				suffix: 'eine Alarmgruppe hinzufügen',
			},
			level: {
				label: 'Alarmstufe',
				'-1': 'Ausfall/Fehler',
				'1': 'Stufe 1',
				'2': 'Stufe 2',
			},
			group: 'Gruppe',
			actions: {
				save: 'Senden',
				cancel: 'Abbrechen',
			},
			events: {
				add: 'Gruppe hinzugefügt',
			},
		},

		member: {
			headerLabel: 'Alarmempfänger',
			dialog: {
				headline: {
					prefix: 'Alarmgruppe',
					suffix: 'ein Alarmziel hinzufügen',
				},
				user: 'Benutzer',
				alarmChannel: 'Alarmkanal',
				template: {
					default: 'STANDARD',
					alarm: 'Template für Alarmierung',
					allClear: 'Template für Entwarnung',
				},
				success: {
					add: 'Teilnehmer hinzugefügt',
					edit: 'Teilnehmer gespeichert',
				},
			},
			crud: {
				actions: {
					save: 'Speichern',
					cancel: 'Abbrechen',
					delete: 'Löschen',
				},
			},
			table: {
				actions: {
					add: 'Empfänger hinzufügen',
					edit: 'Bearbeiten',
					delete: 'Löschen',
					email: {
						enable: 'E-Mail aktivieren',
						disable: 'E-Mail deaktivieren',
					},
					sms: {
						enable: 'SMS aktivieren',
						disable: 'SMS deaktivieren',
					},
					voice: {
						enable: 'Voicenachricht aktivieren',
						disable: 'Voicenachricht deaktivieren',
					},
					phone: {
						enable: 'Phone aktivieren',
						disable: 'Phone deaktivieren',
					},
				},
				columns: {
					id: 'ID',
					name: 'Name',
					memberType: 'Typ',
					settings: 'Einstellungen',
					active: 'Status',
				},
			},
			memberType: {
				user: 'Benutzer',
				alarmChannel: 'Alarmkanal',
			},
			state: {
				active: 'Aktiv',
				inactive: 'Inaktiv',
			},
		},
	},
	alarmAction: {
		headerLabel: 'Aktionen',
		headerLabelSingular: 'Aktion',
		actions: {
			add: 'Aktion zuordnen',
		},
		executable: {
			true: 'Ausführbar',
			false: 'nicht relevant',
		},

		table: {
			headerLabel: 'Zugeordnete Aktionen',
			columns: {
				id: 'ID',
				alarmLevel: 'Stufe',
				name: 'Name',
				type: 'Typ',
				state: 'Status',
			},
			actions: {
				view: 'Anzeigen',
				edit: 'Aktion bearbeiten',
				execute: 'Ausführen',
				delete: 'Aktion löschen...',
				add: 'Aktion hinzufügen...',
			},
			alarmLevel: {
				null: 'Alle',
				'-1': 'Ausfall/Störung',
				'1': 'Limit 1',
				'2': 'Limit 2',
				'3': 'Limit 3',
				'4': 'Limit 4',
				'5': 'Limit 5',
			},
		},
		executeModal: {
			label: {
				start: 'Aktion ausführen',
				end: 'Aktion beenden',
			},
			actions: {
				cancel: 'Abbrechen',
				execute: 'Ausführen',
			},
			actionName: 'Name',
			actionType: 'Typ',
			password: 'Password',
			sensorBoxName: 'Standort',
			error: {
				PASSWORD_MISSMATCH: 'Bitte Passwort erneut eingeben.',
			},
		},
		type: {
			staticLink: 'Statischer Link',
			dynamicLink: 'Dynamischer Link',
			noAction: 'Keine Aktion',
			messageAlert: 'Meldung erstellen',
			channelAlert: 'Alarmkanal',
			authChannelAlert: 'Alarmkanal mit Auth.',
			noAuthChannelAlert: 'Alarmkanal ohne Auth.',
		},
		state: {
			startPending: 'im Start',
			started: 'Ausgeführt',
			endPending: 'im Abschluss',
			done: 'Abgeschlossen',
			archived: 'Archiviert',
			error: 'Fehler',
			disabled: 'Inaktiv',
			available: 'Ausführbar',
			idle: 'nicht relevant',
		},
	},
	alarmChannel: {
		headerLabel: 'Alarmkanäle',
		headerLabelSingular: 'Alarmkanal',
		headerLabelEdit: 'Alarmkanal ändern',
		headerLabelAdd: 'Alarmkanal erstellen',
		actions: {
			add: 'Kanal erstellen',
		},
		table: {
			actions: {
				add: 'Kanal anlegen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				type: 'Typ',
				state: 'Status',
			},
		},
		channelType: {
			slack: 'Slack Nachricht',
			threema: 'Threema Nachricht',
			twitter: 'X (Twitter) Nachricht',
			webHook: 'Web-Hook',
			diveraNews: 'Divera 24/7 Nachricht',
			diveraAlarm: 'Divera 24/7 Alarmierung',
			alamosNews: 'Alamos Nachricht',
			alamosAlarm: 'Alamos Alarmierung',
			c8yEvent: 'Cumulocity Event',
			c8yAlarm: 'Cumulocity Alarm',
			c8yOperation: 'Cumulocity Device Operation',
		},
		authType: {
			none: 'keine',
			basic: 'Basic Auth',
			bearer: 'Bearer/Token',
		},
		crud: {
			actions: {
				save: 'Speichern',
				cancel: 'Abbruch',
				delete: 'Löschen',
			},
		},
		label: {
			channelType: 'Typ',
			webhook: {
				url: 'URL',
				authType: 'Berechtigung',
				authUser: 'Benutzer',
				authPassword: 'Passwort',
				bearer: 'Bearer/Token',
			},
			slack: {
				url: 'URL',
			},
			threema: {
				fromId: 'ID Absender',
				toId: 'ID Empfänger',
				secret: 'Secret',
				privateKey: 'Private Key',
			},
			divera: {
				accesskey: 'Access Key',
				fk: 'Einsatznummer/Fremdschlüssel',
				address: 'Adresse',
				ric: 'RIC',
				person: 'Person',
			},
			alamos: {
				feIdentifikation: 'feIdentifikation',
				customerIdentifikation: 'customerIdentifikation',
				password: 'Passwort',
				sender: 'Sender',
				authorization: 'Authorization',
				callerName: 'Caller Name',
				callerContact: 'Caller Kontakt',
				keyWord: 'Stichwort',
				keyWordDescription: 'Stichwort Beschreibung',
				units: 'Einheiten',
			},
			c8y: {
				host: 'Server (URL)',
				user: 'Benutzername',
				password: 'Passwort',
				sourceId: 'ManagedObject ID',
				alarmType: 'Alarmtyp',
				severity: 'Schweregrad',
				eventType: 'Eventtyp',
				fragmentName: 'Fragment Name',
				fragmentContent: 'Fragment Inhalt',
				severityValue: {
					CRITICAL: 'Kritisch',
					MAJOR: 'Hoch',
					MINOR: 'Gering',
					WARNING: 'Warnung',
				},
			},
		},
	},
	device: {
		headerLabel: 'Geräte',
	},
	firmware: {
		headerLabel: 'Firmware',
		table: {
			actions: {
				add: 'Firmware anlegen',
				edit: 'Firmware bearbeiten',
				delete: 'Firmware deaktivieren/löschen',
			},
			columns: {
				id: 'ID',
				name: 'Name',
				state: 'Status',
				sensorBoxTemplate: 'Template',
				description: 'Beschreibung',
				firmwareVersion: 'Version',
			},
		},
		state: {},
	},
	apiKey: {
		crud: {
			headlines: {
				invite: 'API-Key einladen',
				add: 'API-Key anlegen',
				edit: 'API-Key bearbeiten',
				'edit-self': 'API-Key editieren',
			},
			actions: {
				save: 'Speichern',
				back: 'Zurück',
				cancel: 'Abbrechen',
			},
			success: {
				add: 'Erstellen des API-Keys erfolgreich!',
				edit: 'Ändern des API-Keys erfolgreich!',
			},
		},
		table: {
			actions: {
				edit: 'API-Key bearbeiten',
			},
		},
	},
	user: {
		headerLabel: 'Benutzeradministration',
		state: {
			'-100': 'Gelöscht',
			'-2': 'Eingeladen',
			'-1': 'Pending',
			'0': 'Gesperrt',
			'1': 'Aktiv',
		},
		type: {
			login: 'Interner Benutzer',
			external: 'Externer Benutzer',
			apiKey: 'API-Key',
		},
		roles: {
			user: 'Standardbenutzer',
			admin: 'Admin',
			superadmin: 'Superadmin',
			api_user: 'API-Benutzer',
			guest: 'Gast',
			citizen: 'Bürger',
			staff: 'Mitarbeiter',
			rescue_staff: 'Helfer Rettung',
			rescue_commander:'Einsatzleiter',
			n_sales: 'NOYSEE Einkauf',
			n_installer: 'NOYSEE Monteur',
			n_service: 'NOYSEE Betrieb',

			USER: 'Standardbenutzer',
			ADMIN: 'Admin',
			SUPERADMIN: 'Superadmin',
			API_USER: 'API-Benutzer',
			GUEST: 'Gast',
			CITIZEN: 'Bürger',
			STAFF: 'Mitarbeiter',
			RESCUE_STAFF: 'Helfer Rettung',
			RESCUE_COMMANDER: 'Einsatzleiter',
			N_SALES: 'NOYSEE Einkauf',
			N_INSTALLER: 'NOYSEE Monteur',
			N_SERVICE: 'NOYSEE Betrieb',
		},
		table: {
			actions: {
				invite: 'Benutzer einladen',
				add: 'Benutzer anlegen',
				addApiKey: 'API-Key anlegen',
				edit: 'Benutzer bearbeiten',
				editApiKey: 'API-Key bearbeiten',
				delete: 'Benutzer löschen',
			},
			columns: {
				userType: 'Typ',
				name: 'Name',
				role: 'Rolle',
				email: 'E-Mail',
				id: 'ID',
			},
		},
		crud: {
			headlines: {
				invite: 'Benutzer einladen',
				add: 'Benutzer anlegen',
				edit: 'Benutzer bearbeiten',
				'edit-self': 'Benutzer editieren',
			},
			actions: {
				save: 'Speichern',
				back: 'Zurück',
				cancel: 'Abbrechen',
				'upload-profile-picture': 'Profilbild hochladen',
			},
			success: {
				invite: 'Benutzer erfolgreich eingeladen!',
				add: 'Erstellen des Benutzers erfolgreich!',
				edit: 'Ändern des Benutzers erfolgreich!',
				'edit-self': 'Daten wurden erfolgreich aktualisiert!',
			},
		},
		activateModal: {
			label: 'Benutzer aktivieren',
			name: 'Name',
			forwardUser: 'Auf bestehenden Benutzer weiterleiten (optional)',
			actions: {
				activate: 'Aktivieren',
				deny: 'Ablehnen',
				cancel: 'Abbrechen',
			},
		},
	},
	'profile-image': {
		headline: 'Profilbild bearbeiten',
		crud: {
			actions: {
				save: 'Übernehmen',
				cancel: 'Abbrechen',
			},
		},
	},
	password: {
		crud: {
			headline: 'Passwort ändern',
			label: {
				oldPassword: 'Altes Passwort',
				newPassword: 'Neues Passwort',
				repeatPassword: 'Passwort wiederholen',
			},
			error: {
				oldPassword: 'Bitte altes Passwort angeben',
				newPassword: 'Bitte Passwort angeben',
				repeatPassword: '',
			},
			actions: {
				save: 'Speichern',
				cancel: 'Abbrechen',
			},
		},
	},
	microsite: {
		customer: {
			headerLabel: 'Standorte',
			table: {
				actions: {
					view: 'Details anzeigen',
				},
				columns: {
					name: 'Name',
					value: 'Wert',
					lastContact: 'Letzte Meldung',
					state: 'Status',
				},
			},
		},
	},
	static: {
		datenschutz: {
			headerLabel: 'Datenschutz',
			subheader: 'Datenschutzhinweise',
			content: {
				headline: 'Datenschutzhinweise',
				company: {
					name: 'Netze BW GmbH',
					address: `Schelmenwasenstraße 15<br>
            70567 Stuttgart<br>
            Sitz der Gesellschaft: Stuttgart<br>
            Amtsgericht Stuttgart HRB 747734<br>
            Umsatzsteueridentifikationsnummer: DE 200 335 418<br>`,
				},
				supervisor: {
					headline: 'Vorsitzender des Aufsichtsrats:',
					name: 'Dirk Güsewell',
				},
				'managing-director': {
					headline: 'Geschäftsführung:',
					name: `Dirk Güsewell<br>
            Dr. Martin Konermann<br>
            Bodo Moray<br>
            Steffen Ringwald<br>`,
				},
				contact: {
					headline: 'Kontaktmöglichkeiten:',
					information: `Telefon: 0800 3629-900<br>
            E-Mail: kontakt@netze-bw.de<br>`,
				},
			},
		},
		impressum: {
			headerLabel: 'Impressum',
			subheader:
				'Impressum und Anbieterkennzeichnung gemäß § 5 Telemediengesetz (TMG)',
			content: {
				headline: 'Impressum Netze BW GmbH',
				company: {
					name: 'Netze BW GmbH',
					address: `Schelmenwasenstraße 15<br>
            70567 Stuttgart<br>
            Sitz der Gesellschaft: Stuttgart<br>
            Amtsgericht Stuttgart HRB 747734<br>
            Umsatzsteueridentifikationsnummer: DE 200 335 418<br>`,
				},
				supervisor: {
					headline: 'Vorsitzender des Aufsichtsrats:',
					name: 'Dirk Güsewell',
				},
				'managing-director': {
					headline: 'Geschäftsführung:',
					name: `Dirk Güsewell<br>
            Dr. Martin Konermann<br>
            Bodo Moray<br>
            Steffen Ringwald<br>`,
				},
				contact: {
					headline: 'Kontaktmöglichkeiten:',
					information: `Telefon: 0800 3629-900<br>
            E-Mail: kontakt@netze-bw.de<br>`,
				},
			},
		},
		faq: {
			headerLabel: 'FAQ',
			subheader: 'Häufig gestellte Fragen',
			content: {
				'1': {
					q: '',
					a: '',
				},
				'2': {
					q: '',
					a: '',
				},
				'3': {
					q: '',
					a: '',
				},
			},
		},
		appInstruction: {
			headerLabel: 'NOYSEE APP',
			subheader: 'Alle Pegelinformationen der Region auf einen Blick',
			footer: '',
			content: {
				headline: 'Installationsanleitung',
				precondition:
					'Wenn in Ihrer Region NOYSEE zur Pegelüberwachung genutzt wird, können Sie diese APP nutzen, um diese Pegel im Blick zu behalten. Sie erhalten ggf. Push-Benachrichtigungen sobald bei einem dieser Pegeln zu einer Hochwassergefahr erkannt wird. Des weiteren kann der jeweilige Betreiber (meißt Gemeinde, Stadt, Kreis, Stadtwerk, ...) zusätzliche Meldungen über die APP veröffentlichen umd auf Gefahren hinzuweisen.',
				step1android: {
					name: 'Schritt 1 für Android',
					description:
						'Bei Google Play haben Sie folgende Möglichkeiten die App zu installieren:',
					'option-a': 'Sie suchen im APP-Store mit dem Begriff NOYSEE.',
					'option-b': 'Sie klicken auf das folgenden Symbol:',
					'option-c':
						'Sie scannen den hier angegebenen Barcode mit Ihrem Smartphone.',
					complete:
						'Um die Installation abzuschließen, folgen Sie den Schritten gemäß ihres Gerätes.',
				},
				step1ios: {
					name: 'Schritt 1 für Apple Geräte (iPhone und iPad)',
					description:
						'Um die NOYSEE APP auf iOS zu installieren haben Sie folgende Möglichkeiten:',
					'option-a': 'Sie klicken auf das folgenden Symbol:',
					'option-b':
						'Sie scannen den hier angegebenen Barcode mit Ihrem Smartphone.',
					complete:
						'Um die Installation abzuschließen, folgen Sie den Schritten gemäß ihres Gerätes.',
				},
				step2: {
					name: 'Schritt 2: erster Aufruf der NOYSEE APP',
					description:
						'Nun können Sie die APP starten und sehen vorerst eine Karte ohne Pegel.',
				},
				step3: {
					name: 'Schritt 3:  Registrierung für ihre Region',
					description1:
						'Um die Pegel Ihrer Region abrufen zu können, müssen Sie sich für die gewünschte Region registrieren. Das können auch mehrere Regionen Sein, wenn sie die Pegel von Freunden und Angehörigen einsehen möchten, die in einer anderen Region wohnen, in der NOYSEE verfügbar ist.',
					description2:
						'Für die Registrierung benötigen Sie einen Link oder weiteren QRCode, der für Ihre Region veröffentlicht wurde. Möglicherweise erhalten Sie diesen über Plakate, regionale Zeitungen oder vielleicht eine Infotafel am Rathaus.',
					description3:
						'Vom Startbildschirm der APP erreichen Sie über das Zahnrad (rechts oben) die Einstellungen.',
					description4:
						'Wenn sie die Registrierung über einen QRCode durchführen möchten, drücken Sie auf den Knopf "Registrierungscode scannen" und nehmen den QRCode mit Ihrer Kamera auf. Drücken Sie nun Speichern, dann erscheint Ihre Region in der Liste der für Sie aktivierten Regionen.',
					description4region: 'Für Ihre Region nutzen Sie folgenden Barcode:',
					description5:
						'Diese Funktion können Sie auch mehrfach wiederholen, wenn Sie die Pegel mehrerer Regionen beobachten wollen.',
				},
				step4: {
					name: 'Schritt 4:  Nutzung',
					description1:
						'Wenn Sie die NOYSEE APP starten, sehen Sie eine Landkarte. Abhängig vom Kartenausschnitt sind dort die Stationen ihrer Region vermerkt.',
					description2:
						'Neben dem Logo der Netze-BW sehen Sie die aktuell ausgewählte Region. Haben Sie sich für mehrere Regionen registriert, tippen Sie auf diesen Namen und erhalten eine Liste der anderen Regionen, für die sie sich registriert haben. Aus dieser Liste können Sie nun die Region auswählen, die SIe jetzt betrachten möchten.',
					description3:
						'Drücken Sie auf einen Pegelstandort, so erscheint am unteren Bildschirmrand ein Informationsbox in dem der aktuelle Pegel und die Grenzwerte dargestellt werden. Der aktuelle Messwert wird entsprechend des Pegelzustandes farblich hinterlegt. ',
					description4:
						'Über "Details" gelangen Sie auf eine neue Seite, auf der der Verlauf des Pegels in den letzten 24 Stunden oder 7 Tagen dargestellt wird.',
					description5: '',
				},
			},
		},
	},
	statistic: {
		headerLabel: 'Auswertungen',
		detail: {
			label: 'Detailansicht',
			new: 'Neue Auswertung',
			save: 'Speichern',
			export: 'Exportieren',
			name: {
				placeholder: 'Bitte namen eingeben',
			},
			charts: {
				selection: {
					placeholder: 'Sensor auswählen...',
					add: 'Sensor hinzufügen',
				},
				header: {
					tooltips: {
						zoom: 'Modus: Zoom',
						marker: 'Modus: Marker setzen',
						remove: 'Chart löschen',
					},
				},
				noData: 'Keine Daten verfügbar',
			},
			table: {
				headline: 'Marker',
				actions: {
					delete: 'Marker löschen',
				},
				columns: {
					id: 'ID',
					date: 'Datum',
					value: 'Wert',
					timeDifference: 'Zeitdifferenz',
					name: 'Sensor Name',
					global: 'Global',
				},
			},
			pdfExport: {
				headline: 'Charts',
				dateRange: 'Ausgewählter Zeitraum:',
			},
		},
		overview: {
			table: {
				actions: {
					create: 'Auswertung erstellen',
					delete: 'Auswertung löschen',
				},
				columns: {
					name: 'Name',
					sensorBoxes: 'Sensorboxen',
				},
			},
		},
	},
};
