import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './base/dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { StyleguideComponent } from './base/styleguide/styleguide.component';
import { DatenschutzComponent } from '../static/datenschutz/datenschutz.component';
import { ImpressumComponent } from '../static/impressum/impressum.component';
import { FaqComponent } from '../static/faq/faq.component';
import { AdminGuard } from 'src/app/guards/admin.guard';
import { SuperAdminGuard } from 'src/app/guards/superadmin.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: NotificationsComponent,
        data: {
          mode: 'start',
        },
      },
      {
        path: 'datenschutz',
        component: DatenschutzComponent,
      },
      {
        path: 'impressum',
        component: ImpressumComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'styleguide',
        component: StyleguideComponent,
      },
      {
        // canActivate: [AdminGuard], 
        path: 'users',
        data: {
          breadcrumbLabel: 'user.headerLabel',
        },
        loadChildren: () =>
          import('./users/users.module').then((mod) => mod.UsersModule),
      },
      {
        canActivate: [AdminGuard],
        path: 'alarmRules',
        data: {
          breadcrumbLabel: 'alarmRule.headerLabel',
        },
        loadChildren: () =>
          import('./alarmRules/alarmRules.module').then(
            (mod) => mod.AlarmRulesModule,
          ),
      },
      {
        canActivate: [AdminGuard],
        path: 'alarmGroups',
        data: {
          breadcrumbLabel: 'alarmGroup.headerLabel',
        },
        loadChildren: () =>
          import('./alarmGroups/alarmGroups.module').then(
            (mod) => mod.AlarmGroupsModule,
          ),
      },
      {
        canActivate: [AdminGuard],
        path: 'alarmChannels',
        data: {
          breadcrumbLabel: 'alarmChannel.headerLabel',
        },
        loadChildren: () =>
          import('./alarmChannels/alarmChannels.module').then(
            (mod) => mod.AlarmChannelsModule,
          ),
      },
      {
        canActivate: [AdminGuard],
        path: 'alarmActions',
        data: {
          breadcrumbLabel: 'alarmAction.headerLabel',
        },
        loadChildren: () =>
          import('./alarmActions/alarmActions.module').then(
            (mod) => mod.AlarmActionsModule,
          ),
      },
      {
        path: 'sensors',
        data: {
          breadcrumbLabel: 'sensor.headerLabel',
        },
        loadChildren: () =>
          import('./sensors/sensors.module').then((mod) => mod.SensorsModule),
      },
      {
        canActivate: [SuperAdminGuard],
        path: 'admins',
        loadChildren: () =>
          import('./admin/admin.module').then((mod) => mod.AdminModule),
      },
      {
        canActivate: [SuperAdminGuard],
        path: 'warningBrokers',
        loadChildren: () =>
          import('./warningBroker/warningBroker.module').then(
            (mod) => mod.WarningBrokerModule,
          ),
      },
      {
        path: 'statistics',
        data: {
          breadcrumbLabel: 'statistic.headerLabel',
        },
        loadChildren: () =>
          import('./statistics/statistics.module').then(
            (mod) => mod.StatisticsModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
