import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Observable } from 'rxjs';
import { AlarmLevelStates } from 'src/app/models/apiResults';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import {
  DataTableOptions,
  GridState,
  TableFilterType,
  TitleSize,
  TableActionType,
} from 'src/app/shared/data-table/data-table.model';
import { ImageService } from 'src/app/services/image.service';
import { SafeUrl } from '@angular/platform-browser';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { map } from 'rxjs/operators';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-customer-microsite',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  logoUrl: any;
  customerLogoImage: SafeUrl | string;
  isDev: boolean;
  openNotificationCount: number;
  openError: boolean;
  customerName: String;

  dataTableOptions: DataTableOptions<SensorBox>;
  list$: Observable<SensorBox[]> = this.store
    .select(SensorState.myList)
    .pipe(
      map((result) =>
        result.filter(
          (box) => !this.filterType || box.frontendType === this.filterType,
        ),
      ),
    );
  gridState: GridState = null;
  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  mode: string;

  hideHeader: boolean;
  hideState: boolean;
  hideLastContact: boolean;
  pageSize: number;

  filterType: string;

  listTitleKey: string;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private imageService: ImageService,
    private dataTableStateService: DataTableStateService,
    private router: Router,
  ) {
    this.logoUrl = environment.logoUrl;
    this.isDev = environment.production;
    this.customerName =
      this.authenticationService.authenticatedUser.customer.name;

    this.customerLogoImage = 'assets/img/common-logo.svg';
  }

  async ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    if (params.mode) {
      this.mode = params.mode;
    }

    this.hideHeader = this.activatedRoute.snapshot.queryParams['hideHeader'];
    this.hideState = this.activatedRoute.snapshot.queryParams['hideState'];
    this.hideLastContact =
      this.activatedRoute.snapshot.queryParams['hideLastContact'];

    if (this.activatedRoute.snapshot.queryParams['pageSize']) {
      this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'];
    }

    this.filterType = this.activatedRoute.snapshot.queryParams['filterType'];

    if (this.filterType) {
      this.listTitleKey = 'general.frontendType.' + this.filterType + '.liste';
    } else {
      this.listTitleKey = 'general.frontendType.standort.liste';
    }

    this.imageService.getCustomerImage().then((logo) => {
      this.customerLogoImage = logo;
    });

    this.dataTableOptions = {
      // title: 'microsite.customer.headerLabel',
      title: this.listTitleKey,
      titleSize: TitleSize.H1,
      pageSize: this.pageSize,
      filter: true,
      tableActions: [{}],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [],
      },
      rowStyleFn: (sensor: SensorBox) => {
        return {
          'background-color': this.getStateColor(sensor.state),
          border: 'solid 1.5px white',
        };
      },
      columnDef: [
        {
          key: 'name',
          displayName: 'microsite.customer.table.columns.name',
          width: '45%',
          sortable: true,
          styleFn: () => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (sensor: SensorBox) =>
            this.router.navigate(['/micro', 'sensor', sensor.id]),
        },
        {
          key: 'value',
          displayName: 'microsite.customer.table.columns.value',
          width: '20%',
          sortable: true,
          valueTransform: (sensor: SensorBox) =>
            Math.round(sensor.sensors[sensor.primarySensor]?.currentDataValue) +
            ' ' +
            sensor.sensors[sensor.primarySensor].unit,
        },
        {
          key: 'lastContact',
          displayName: 'microsite.customer.table.columns.lastContact',
          width: '15%',
          sortable: true,
          hidden: this.hideLastContact,
          valueTransform: (sensor: SensorBox) =>
            moment(
              sensor.sensors[sensor.primarySensor]?.currentDataTimestamp,
            ).format('DD.MM.YYYY HH:mm'),
        },
        {
          key: 'state',
          displayName: 'microsite.customer.table.columns.state',
          width: '15%',
          sortable: true,
          hidden: this.hideState,
          valueTransform: (sensor: SensorBox) => `sensor.state.${sensor.state}`,
          filterType: TableFilterType.SELECT,
          filterValues: this.getStates(),
        },
      ],
    };
  }

  getStates(): { label: string; value: string }[] {
    const states = [];
    for (const state of Object.values(AlarmLevelStates).filter(
      (value) => typeof value === 'number',
    )) {
      states.push({
        label: `sensor.state.${state}`,
        value: state,
      });
    }
    return states;
  }

  getStateColor(state: AlarmLevelStates) {
    switch (state) {
      case AlarmLevelStates.NORMAL:
        return '#c8d89c';
      case AlarmLevelStates.LIMIT_1:
      case AlarmLevelStates.ERROR:
      case AlarmLevelStates.DEFECTIVE:
      case AlarmLevelStates.DISABLED:
        return 'rgba(255, 171, 49, 0.4)';
      case AlarmLevelStates.LIMIT_2:
      case AlarmLevelStates.LIMIT_3:
      case AlarmLevelStates.LIMIT_4:
      case AlarmLevelStates.LIMIT_5:
        return '#ffc7cc';
      case AlarmLevelStates.PLANNED:
      default: // No known state is active
        return 'rgba(204, 204, 204, 0.6)';
    }
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  showSensorDetail(sensor: SensorBox) {
    this.router.navigate(['/micro', 'sensor', sensor.id], {
      queryParams: { showBack: true },
    });
  }

  getStateText(state: AlarmLevelStates): string {
    return AlarmLevelStates[state].toLowerCase();
  }
}
