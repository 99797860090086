import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import { AlarmRuleService } from '../../../../state/noysee/alarmRule.service';
import { AlarmRule } from '../../../../state/noysee/models/alarmGroup';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { AlarmLevelStates } from 'src/app/models/apiResults';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { Store } from '@ngxs/store';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-alarmRules-overview',
  templateUrl: './alarmRules.component.html',
  styleUrls: ['./alarmRules.component.scss'],
})
export class OverviewComponent implements OnInit, AfterViewInit {
  dataTableOptions: DataTableOptions<AlarmRule>;
  list: AlarmRule[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private alarmRuleService: AlarmRuleService,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private router: Router,
    private store: Store,
  ) {
    // this.noyseeDeviceSubscription = null;
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    const _this = this;
    _this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    _this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'alarmRule.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'alarmRule.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () =>
            this.router.navigate(['/dashboard', 'alarmRules', 'add'], {
              queryParams: { ctx: this.getGridState() },
            }),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'alarmRule.table.actions.edit',
            icon: 'app-pencil',
            actionFn: (alarmRule: AlarmRule) =>
              this.editAlarmRule(alarmRule.id),
          },
          {
            label: 'alarmGroup.table.actions.delete',
            icon: 'delete',
            actionFn: (alarmRule: AlarmRule) =>
              this.deleteAlarmRule(alarmRule.id),
          },
        ],
      },
      rowStyleFn: (alarmRule: AlarmRule) => {
        return alarmRule.active
          ? {
              'background-color': this.getStateColor(
                alarmRule.currentAlarmLevel,
              ),
              border: 'solid 1.5px white',
            }
          : {
              border: 'solid 1.5px white',
            };
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'alarmRule.table.columns.id',
          width: '10%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'name',
          displayName: 'alarmRule.table.columns.name',
          width: this.isSuperAdmin ? '60%' : '70%',
          sortable: true,
          styleFn: (alarmRule: AlarmRule) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (alarmRule: AlarmRule) =>
            this.router.navigate([
              '/dashboard',
              'alarmRules',
              alarmRule.id,
              'edit',
            ]),
        },
        {
          key: 'alarmLevel',
          displayName: 'alarmRule.table.columns.alarmLevel',
          width: '10%',
          sortable: true,
          valueTransform: (alarmRule: AlarmRule) =>
            `sensor.state.${alarmRule.currentAlarmLevel}`,
        },
        {
          key: 'state',
          displayName: 'alarmRule.table.columns.state',
          width: '10%',
          sortable: true,
          valueTransform: (alarmRule: AlarmRule) =>
            `alarmGroup.state.${alarmRule.active}`,
        },
      ],
    };

    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      console.debug('Focus Customer changed');
      this.loadList();
    });
  }

  ngAfterViewInit(): void {}

  getStateColor(state: AlarmLevelStates) {
    switch (state) {
      case AlarmLevelStates.NORMAL:
        return '#c8d89c';
      case AlarmLevelStates.LIMIT_1:
      case AlarmLevelStates.ERROR:
      case AlarmLevelStates.DEFECTIVE:
      case AlarmLevelStates.DISABLED:
        return 'rgba(255, 171, 49, 0.4)';
      case AlarmLevelStates.LIMIT_2:
      case AlarmLevelStates.LIMIT_3:
      case AlarmLevelStates.LIMIT_4:
      case AlarmLevelStates.LIMIT_5:
        return '#ffc7cc';
      case AlarmLevelStates.PLANNED:
      default: // No known state is active
        return 'rgba(204, 204, 204, 0.6)';
    }
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  loadList() {
    this.alarmRuleService.getList().then((result) => {
      this.list = result;
    });
  }

  editAlarmRule(alarmRuleId: number) {
    this.router.navigate(['/dashboard', 'alarmRules', alarmRuleId, 'edit']);
  }

  deleteAlarmRule(alarmRuleId: number) {
    const _this = this;
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diese Alarmregel wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.alarmRuleService.delete(alarmRuleId).then((result) => {
            _this.toastHelper.openSnackBar('Alarmregel gelöscht!', 'SUCCESS');
            _this.alarmRuleService.getList().then((result) => {
              _this.list = result;
              this.toastHelper.openSnackBar(
                'Alarmregel erfolgreich gelöscht!',
                'SUCCESS',
              );
            });
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
