<div id="primary-panel-wrapper" [class.hidden]="!(panelActive$ | async)">
  <div
    id="primary-panel"
    class="ch-padding-top-24 ch-padding-bottom-24 ch-padding-left-24"
    [class.ch-padding-right-24]="!(showMenu$ | async)"
  >
    <span
      (click)="togglePanel(false)"
      class="ch-action close primary-panel-close"
    ></span>
    <div class="primary-panel-content">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-navigation-menu></app-navigation-menu>
</div>
<div
  (click)="togglePanel(true)"
  id="closed-primary-panel"
  class="ch-padding-24"
  [class.hidden]="panelActive$ | async"
>
  <mat-icon class="ch-action">chevron_left</mat-icon>
  <p>{{ headline | translate }} {{ "general.show" | translate }}</p>
</div>
