import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';

import {Observable, Subject} from 'rxjs';

import * as pdfMake from 'pdfmake/build/pdfmake';

import {Content, ContentColumns, ContentTable, StyleDictionary, TDocumentDefinitions} from 'pdfmake/interfaces';

import {TranslatePipe} from '@ngx-translate/core';
import {DateHelper} from '../../helpers/date.helper';
import {environment} from '../../../environments/environment';
import {isMoment} from 'moment';
import {PdfExportTableOptions, PdfFilter} from './pdf.model';

enum LogoType {
  SVG = 'SVG',
  IMAGE = 'IMAGE'
}

@Injectable()
export class PdfService {

  constructor(private http: HttpClient, private translatePipe: TranslatePipe) {
  }

  initializeStyles(): StyleDictionary {
    // @ts-ignore
    pdfMake.fonts = {
      DINPro: {
        normal: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/assets/font/dinpro/regular/DINPro-Regular.ttf',
        medium: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/assets/font/dinpro/medium/DINPro-Medium.ttf',
        bold: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/assets/font/dinpro/bold/DINPro-Bold.ttf'
      }
    };

    return {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      date: {
        fontSize: 10,
        bold: false,
      },
      title: {
        fontSize: 21,
        bold: true,
        margin: [0, 15, 0, 15]
      },
      tableHeader: {
        bold: true
      },
      filterHeader: {
        bold: true,
        fontSize: 12,
        margin: [0, 0, 0, 5]
      },
      filter: {
        fontSize: 10,
        margin: [0, 0, 0, 10]
      },
      filterKey: {
        bold: true,
        margin: [0, 0, 0, 0]
      },
      filterValue: {
        margin: [5, 0, 0, 0]
      },
      footer: {
        fontSize: 12,
        margin: [0, 0, 40, 0]
      }
    };
  }

  initializeOrientation(columnLength: number): 'landscape' | 'portrait' {
    return (columnLength > 4) ? 'landscape' : 'portrait';
  }

  preparePageHeader(date: Date, logo: string, logoType: LogoType): ContentColumns {
    return {
      style: 'header',
      columns: [
        {columns: [logoType === LogoType.SVG ? {svg: logo} : {image: logo, width: 100}], width: 100},
        {text: 'BarrierSystems', width: '*'},
        {text: DateHelper.generateGermanTimestamp(date), style: 'date', width: 'auto'}
      ]
    };
  }

  prepareExportImage(exportImage: string): Content {

    if (exportImage.startsWith('data:image/svg+xml')) {
      const urlEncoded = exportImage.split(',')[1];
      /*.replace(/%3D/g, '=')
      .replace(/%3F/g, '?')
      .replace(/%2F/g, '/')
      .replace(/%23/g, '#');*/
      return {
        alignment: 'center',
        columns: [{
          svg: decodeURIComponent(urlEncoded),
          fit: [400, 300]
        }]
      };
    }

    return {
      image: exportImage,
      height: 200
    };
  }

  prepareTable(header: string[], dataList: string[][]): ContentTable {
    return {
      table: {
        widths: ['auto', ...new Array<string>(header.length - 1).fill('*')], // prevent table from overflowing page
        headerRows: 1,
        body: [
          header.map((col) => ({text: col, style: 'tableHeader'})),
          ...dataList.map((row) => row.map((col) => this.prepareCell(col)))
        ]
      }
    };
  }

  prepareCell(cell: any) {
    if (cell?.text) {
      return cell;
    }
    return {text: cell};
  }

  prepareFilter(filter: PdfFilter[]): Content[] {
    let content = [];

    if (filter.length > 0) {
      const filterGroups: PdfFilter[][] = [];
      while (filter.length > 0) {
        filterGroups.push(filter.splice(0, 3));
      }

      content = [
        {
          style: 'filterHeader',
          text: this.translatePipe.transform('general.filter')
        },
        ...filterGroups.map((grp) => ({
          style: 'filter',
          columns: grp.map(({name, value}) => ({
            columns: [
              {
                text: name + ': ',
                style: 'filterKey',
                width: 'auto'
              },
              {
                text: isMoment(value) ? value.format('L LTS') : value,
                style: 'filterValue',
                width: '*'
              }]
          }))
        }))
      ];
    }

    return content;
  }

  exportTable(options: PdfExportTableOptions) {
    const {title, header, data, filter, filename, exportImage} = options;

    const download$ = new Subject();

    const logoUrl = environment.logoUrl;
    const logoType = logoUrl.split('.').pop() === 'svg' ? LogoType.SVG : LogoType.IMAGE;

    const logo$ = logoType === LogoType.SVG ? this.loadSvg(logoUrl) : this.loadImage(logoUrl);

    return logo$.pipe(
      map((logo) => {
        const docDefinition: TDocumentDefinitions = {
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
          pageMargins: [40, 60, 40, 40],
          pageOrientation: this.initializeOrientation(header.length),
          content: [
            {
              columns: [
                this.preparePageHeader(new Date(), logo, logoType)
              ]
            },
            {text: title, style: 'title'},
            filter ? this.prepareFilter(filter) : [],
            exportImage ? this.prepareExportImage(exportImage) : [],
            this.prepareTable(header, data as string[][])
          ],
          footer: (current, total) => [{
            text: this.translatePipe.transform('general.pages', {current, total}),
            alignment: 'right',
            style: 'footer'
          }],
          styles: this.initializeStyles(),
          defaultStyle: {
            font: 'DINPro',
            fontSize: 6
          }
        };

        pdfMake.createPdf(docDefinition).download(filename, () => {
          download$.next();
          download$.complete();
        }, {});
      }),
      switchMap(() => download$)
    );
  }

  loadSvg(path: string): Observable<string> {
    return this.http.get(path, {responseType: 'text' as const}).pipe(
      map((data) => data.replace(/\r?\n|\r/g, ''))
    );
  }

  loadImage(path: string): Observable<string> {
    return this.http.get(path, {responseType: 'blob'}).pipe(
      switchMap((blob) => {
        const loaded$ = new Subject<string>();
        const reader = new FileReader();

        reader.onloadend = () => {
          loaded$.next(<string>reader.result);
          loaded$.complete();
        };

        reader.readAsDataURL(blob);

        return loaded$.asObservable();
      })
    );
  }
}
