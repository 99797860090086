import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NoyseeDevice } from '../models/noyseeDevice';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NoyseeDeviceListResult, NoyseeDeviceResult, LookupListResult, LookupEntry, FirmwareResult, FirmwareListResult } from '../models/apiResults';
import { map } from 'rxjs/operators';
import { Firmware } from '../models/firmware';

@Injectable({ providedIn: 'root' })
export class FirmwareService {

  constructor(private http: HttpClient) {
  }

  getList(): Promise<Firmware[]> {
    return this.http.get<FirmwareListResult>(`${environment.dashboardApiUrl}/firmware/list`).pipe(map(result => {
      if (!result.ok) {
        throw new Error(result.errorCode);
      }
      return result.data;
    })).toPromise();
  }

  getFirmware(id: number): Promise<Firmware> {
    return this.http.get<FirmwareResult>(`${environment.dashboardApiUrl}/firmware/detail?id=` + id)
      .pipe(map((result: FirmwareResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  delete(id: number): Promise<any> {
    return this.http.delete<FirmwareResult>(`${environment.dashboardApiUrl}/firmware/` + id)
      .pipe(map((result: FirmwareResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  upgradeOnSensorBox(sensorBoxId: number, firmwareId: number): Promise<Firmware> {
    return this.http.get<FirmwareResult>(`${environment.dashboardApiUrl}/sensorBox/scheduleOta?id=` + sensorBoxId + `&firmwareId=` + firmwareId)
      .pipe(map((result: FirmwareResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  save(firmware: Firmware): Promise<Firmware> {
    return this.http.put<FirmwareResult>(`${environment.dashboardApiUrl}/firmware/detail`, firmware)
      .pipe(map((result: FirmwareResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}
