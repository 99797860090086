/** GENERATED FILE: run /tools/update-icons.ts with node **/
import { Icon } from './icon.model';

export const icons: Icon[] = [
    { name: 'app-menu-horizontal', hidden: false, content: '<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg   xmlns="http://www.w3.org/2000/svg"   aria-hidden="true"   focusable="false"   data-prefix="fas"   data-icon="ellipsis-h"   class="svg-inline--fa fa-ellipsis-h fa-w-16"   role="img"   viewBox="0 0 512 512">  <g>  <path     fill="currentColor"     d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"     id="path61"     style="fill:#020280;fill-opacity:1" />  </g></svg>'},
    { name: 'app-pencil', hidden: false, content: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil-alt"     class="svg-inline--fa fa-pencil-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">  <path fill="currentColor"        d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>'},
    { name: 'app-plus', hidden: false, content: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#FFA500" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>'},
    { name: 'app-view', hidden: false, content: '<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg  aria-hidden="true"  focusable="false"  data-prefix="far"  data-icon="eye"  class="svg-inline--fa fa-eye fa-w-18"  role="img"  viewBox="0 0 576 512"  xmlns="http://www.w3.org/2000/svg">  <g>    <path      fill="currentColor"      d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"      id="path49"      style="fill:#020280;fill-opacity:1" />  </g></svg>'},
    { name: 'app-delete', hidden: false, content: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>'},
];
