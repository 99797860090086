<div *ngIf="currentSensor" class="row">
  <div class="col-12 ch-margin-top-16">
    <div class="container contacts-container">
      <div class="row ch-padding-top-8 header-row">
        <div class="col-10 contacts-header">
          <p appTranslate="alarmAction.headerLabel"></p>
        </div>
      </div>

      <div class="col-12 ch-margin-top-24">
        <app-data-table [dataTableOptions]="dataTableOptions" [state]="gridState"
                        [data]="alarmActionList"></app-data-table>
      </div>
    </div>
  </div>
</div>
