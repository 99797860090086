import { AlarmAction } from './alarmAction';
import { FrontendType } from './sensor';
import { Firmware } from 'src/app/models/firmware';

export enum CHART_TYPE {
  LINE = 'LINE',
  COLUMN = 'COLUMN',
  PrecipType = 'PrecipType',
  Label = 'Label',
  OpenedClosed='OpenedClosed', 
  OnOff='OnOff'
}

export type SensorBoxValues = {
  [value in SensorType]?: number;
} & {
  chartType: CHART_TYPE;
  alarm: number;
  timestamp: number;
  limit1: number;
  limit2: number;
};

export type FutureSensorBoxValues = {
  [value in SensorType]?: {
    chartType: CHART_TYPE;
    expected: number;
    min: number;
    max: number;
  };
} & {
  timestamp: number;
};

class Sensor {
  id: number;
  name: string;
  iotName: string;
  shortname: string;
  description: string;
  unit: string; // z.B. "cm"
  currentDataTimestamp: number;
  currentDataValue: number;
  currentDataRaw: any | null; // TODO: Figure out what the typing is supposed to be
  trend: number;
  limit1: number | null;
  limit2: number | null;
  limit3: number | null;
  limit4: number | null;
  limit5: number | null;
  limitDelta: number | null;
  hysteresis: number | null;
  displayMaxY: number | null;
  displayMinY: number | null;
  validationMin: number | null;
  validationMax: number | null;
  maxGradientUp: number | null;
  maxGradientDown: number | null;
  state: number; // z.B. 0 -> TODO: Figure out what the values mean
  chartType: CHART_TYPE;
  
  alarmActions: AlarmAction[];
}

export enum SensorType {
  WASSERPEGEL = 'wasserpegel',
  BATTERIE = 'batterie', // Used in current history and value component, unsure if still valid
  BATTERIE_CAP = 'batterieCap',
  BATTERIE_V = 'batterieV',
  DATA_QUALITY = 'dataQuality',
}

export type SensorBoxData = SensorBox & {
  history: SensorBoxValues[];
  future: FutureSensorBoxValues[];
};

export class SensorBox {
  id: number;
  name: string;
  lat: number;
  lon: number;
  state: number;
  address: string;
  description: string;
  datasource: string; // normalerweise "NOYSEE"
  secondarySensor: SensorType;
  installationState: number;
  limitsEditable: boolean;
  alarmReceiverEditable: boolean;
  alarmReceiverVisible: boolean;
  lastContact: number;
  frontendType: FrontendType;
  alarmReceiver: any[]; // TODO: Type alarm receivers correctly
  alarmGroups: any[] | null; // TODO: Type alarm groups correctly
  image: string | null;
  internalDescription: string | null;
  statusInfo: string; // z.B. "normal"
  public: boolean;
  hardwareState: number;

  installedFirmware?: Firmware;
  firmwareToInstall?: Firmware;

  maueInventoryNumber?: string;

  alarmActions: AlarmAction[];
  
  constructor(
    public primarySensor: string = 'wasserpegel',
    public sensors: { [key: string]: Sensor } = { wasserpegel: new Sensor() },
  ) {}
}

export interface WebsocketSensorBox {
  sensorBoxId: number;
}

export class RenderedInfo {
  description: string;
  internalDescription: string;
  htmlDescription: string;
  htmlInternalDescription: string;
}
