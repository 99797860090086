import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { AlarmGroupService } from '../../../../state/noysee/alarmGroup.service';
import {
  AlarmGroupMemberType,
  AlarmGroupMember,
} from '../../../../state/noysee/models/alarmGroup';
import { UsersService } from '../../../../services/users.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { User } from 'src/app/models/user';
import { LookupService } from 'src/app/services/lookup.service';
import { AlarmChannel } from '@app/state/noysee/models/alarmChannel';
import { AlarmChannelService } from '@app/state/noysee/alarmChannel.service';
import { Subscription } from 'rxjs';
import { GuiState } from '@app/state/dashboard/gui.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-addAlarmGroupMember',
  templateUrl: './addAlarmGroupMember.component.html',
  styleUrls: ['./addAlarmGroupMember.component.scss'],
})
export class AddAlarmGroupMemberComponent implements OnInit {
  AlarmGroupMemberType = AlarmGroupMemberType;
  alarmGroupMemberTypes = Object.values(AlarmGroupMemberType);

  private usersSubscription$: Subscription;

  alarmGroupId: number;
  alarmGroupName: string;
  memberType: AlarmGroupMemberType;
  name: string;

  userId: number;
  alarmChannelId: number;
  sms: boolean;
  email: boolean;
  voice: boolean;
  phone: boolean;

  templateList: LookupEntry[];
  userList: User[];
  alarmChannelList: AlarmChannel[];

  isSuperAdmin: boolean;
  isAdmin: boolean;

  alarmMessageTemplateId: number;
  clearMessageTemplateId: number;

  newMember: AlarmGroupMember;

  extendedProperties: boolean;

  modeEdit: boolean;

  constructor(
    private store: Store,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private lookupService: LookupService,
    private alarmChannelService: AlarmChannelService,
    private alarmGroupService: AlarmGroupService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddAlarmGroupMemberComponent>,
  ) {
    this.isSuperAdmin = false;
    this.isAdmin = false;

    this.alarmMessageTemplateId = null;
    this.clearMessageTemplateId = null;

    this.extendedProperties = true;

    this.newMember = new AlarmGroupMember();
    this.newMember.sms = false;
    this.newMember.email = false;
    this.newMember.phone = false;
    this.newMember.voice = false;
    this.newMember.active = true;
  }

  async ngOnInit() {
    this.alarmGroupId = this.data?.alarmGroupId;
    this.alarmGroupName = this.data?.alarmGroupName;

    if (this.data && this.data.alarmGroupMember) {
      this.modeEdit = true;

      this.newMember = this.data.alarmGroupMember;

      this.newMember = this.data.alarmGroupMember;
      this.userId = this.newMember.userId;
      this.alarmChannelId = this.newMember.alarmChannelId;
      this.email = this.newMember.email;
      this.sms = this.newMember.sms;
      this.voice = this.newMember.voice;
      this.phone = this.newMember.phone;
      this.name = this.newMember.name;

      this.alarmGroupId = this.newMember.alarmGroupId;
      this.memberType = this.newMember.memberType;
    } else {
      this.modeEdit = false;
    }

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.usersSubscription$ = this.usersService.usersObservable.subscribe(
      (users: User[]) => {
        this.userList = users.sort((a, b) => a.name?.localeCompare(b.name));
      },
    );

    //    this.usersService.getList().then((data) => {
    //      this.userList = data;
    //    });

    this.alarmChannelList = await this.alarmChannelService.getList();
	this.templateList = await this.lookupService.getMessageTemplateList();

    this.store.select(GuiState.selectedCustomer).subscribe(() => {
      this.usersService.changeFocusCustomer();
    });
  }

  ngOnDestroy() {
    this.usersSubscription$?.unsubscribe();
  }

  addAlarmGroupMember(): void {
    this.newMember.memberType = this.memberType;
    this.newMember.active = true;

    this.newMember.alarmMessageTemplateId = this.alarmMessageTemplateId;
    this.newMember.clearMessageTemplateId = this.clearMessageTemplateId;

    switch (this.memberType) {
      case AlarmGroupMemberType.USER:
        if (this.userId == null) {
          return;
        }
        this.newMember.userId = this.userId;
        this.newMember.email = this.email;
        this.newMember.sms = this.sms;
        this.newMember.voice = this.voice;
        this.newMember.phone = this.phone;
        break;
      case AlarmGroupMemberType.ALARMCHANNEL:
        if (this.alarmChannelId == null) {
          return;
        }
        this.newMember.alarmChannelId = this.alarmChannelId;
        break;
      default:
        return;
    }

    if (this.modeEdit) {
      this.alarmGroupService
        .saveMember(this.alarmGroupId, this.newMember)
        .then((data) => {
          this.toastHelper.openSnackBar('Teilnehmer gespeichert', 'SUCCESS');
          this.dialogRef.close();
        });
    } else {
      this.alarmGroupService
        .addMember(this.alarmGroupId, this.newMember)
        .subscribe((data) => {
          this.toastHelper.openSnackBar('Teilnehmer hinzugefügt', 'SUCCESS');
          this.dialogRef.close();
        });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleEMail(): void {
    this.email = !this.email;
  }
  toggleSMS(): void {
    this.sms = !this.sms;
  }
  toggleVOICE(): void {
    this.voice = !this.voice;
  }
  togglePhone(): void {
    this.phone = !this.phone;
  }
}
