<div class="mat-dialog-title">
	<p><strong appTranslate="user.activateModal.label"></strong></p>
</div>

<div class="row ch-margin-top-24">
	<div class=" col-12">
		<div class="form-field">
			<label for="actionName" appTranslate="user.activateModal.name"></label>
			<input id="actionName" [(ngModel)]="user.name" disabled="true" />
		</div>
	</div>
</div>

<div class="row ch-margin-top-24">
	<div class="col-12">
		<div class="form-field">
			<label for="forwardUserId" appTranslate="user.activateModal.forwardUser"></label>
			<select id="forwardUserId" [(ngModel)]="forwardUserId">
				<option [value]="null">-</option>
				<option [ngValue]="entry.id" *ngFor="let entry of userList">
					{{ entry.name }}
				</option>
			</select>
		</div>
	</div>
</div>

<mat-dialog-actions class="dialog-actions">
	<button mat-flat-button class="btn-primary" (click)="execute()"
		appTranslate="user.activateModal.actions.activate"></button>
	<button mat-flat-button class="btn-secondary" (click)="cancel()"
		appTranslate="user.activateModal.actions.deny"></button>
	<button mat-flat-button class="btn-secondary" (click)="cancel()"
		appTranslate="user.activateModal.actions.cancel"></button>
</mat-dialog-actions>