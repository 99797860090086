import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {TranslatePipe} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ImageService {
  private readonly uploadSensorUrl: string = environment.dashboardApiUrl + '/sensorBox/image?id=';
  private readonly uploadUserUrl: string = environment.identityManagerApiUrl + '/user/$ID/image';

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly http: HttpClient,
              private readonly translatePipe: TranslatePipe,
              private readonly domSanitizer: DomSanitizer) {
  }

  async getUserImage(userId: number): Promise<SafeResourceUrl | string> {
    try {
      const result = await this.http.get(`${environment.identityManagerApiUrl}/user/${userId}/image`, {
        responseType: 'blob',
      }).toPromise();
      return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result));
    } catch (e) {
      return environment.avatarPlaceholderUrl;
    }
  }

  async getCustomerImage(): Promise<SafeResourceUrl | string> {
    try {
      const result = await this.http.get(`${environment.dashboardApiUrl}/customer/image`, {
        responseType: 'blob',
      }).toPromise();
      return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result as Blob));
    } catch (e) {
      return environment.avatarPlaceholderUrl;
    }
  }

  async getQRCodeImage(userId: number): Promise<SafeResourceUrl | string> {
    try {
      const result = await this.http.get(`${environment.dashboardApiUrl}/apiKey/${userId}/qrcode?size=200`, {
        responseType: 'blob',
      }).toPromise();
      return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result));
    } catch (e) {
      return environment.avatarPlaceholderUrl;
    }
  }

  async getAPPQRCodeImage(apiKey: string): Promise<SafeResourceUrl | string> {
    try {
      const result = await this.http.get(`${environment.dashboardApiUrl}/apiKey/${apiKey}/app?size=200`, {
        responseType: 'blob',
      }).toPromise();
      return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result));
    } catch (e) {
      return environment.avatarPlaceholderUrl;
    }
  }


  uploadUserImage(image: string, id: number) {
    const formData: FormData = new FormData();
    formData.append('image', image !== '' ? this.dataURLtoFile(image, id) : null);
    return this.http.post<any>(this.uploadUserUrl.replace('$ID', id.toString(10)), formData, {
      headers: new HttpHeaders().set('Accept', '*/*')
    }).toPromise();
  }

  uploadSensorImage(image: string, id: number) {
    const formData: FormData = new FormData();
    formData.append('image', image !== '' ? this.dataURLtoFile(image, id) : null);
    return this.http.post<any>(this.uploadSensorUrl + id, formData, {
      headers: new HttpHeaders().set('Accept', '*/*')
    }).toPromise();
  }

  private dataURLtoFile(dataUrl: string, imageName: string | number): File {
    const splittedData: string[] = dataUrl.split(',');
    const mime: string = splittedData[0].match(/:(.*?);/)[1];
    const biteStream: string = atob(splittedData[1]);
    let length = biteStream.length;
    const u8arr: Uint8Array = new Uint8Array(length);

    while (length--) {
      u8arr[length] = biteStream.charCodeAt(length);
    }

    return new File([u8arr], imageName.toString(), {type: mime});
  }
}
