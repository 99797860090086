import { Component, Input } from '@angular/core';
import { AgGridService } from '@app/services/ag-grid.service';
import {
  ColumnApi,
  CsvExportParams,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import { PdfExport } from '../../util/export';

@Component({
  selector: 'app-table-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input()
  gridApi!: GridApi;

  @Input()
  columnApi!: ColumnApi;

  @Input()
  csvExportParams?: () => CsvExportParams;

  @Input()
  pdfExportParams?: PdfExport.PdfExportParams;

  @Input()
  gridOptions?: GridOptions;

  constructor(private agGridService: AgGridService) {}

  exportToCsv(exportParams: () => CsvExportParams): void {
    this.gridApi.exportDataAsCsv(exportParams());
  }

  exportToPdf(exportParams: PdfExport.PdfExportParams): void {
    this.agGridService.exportToPdf(
      exportParams,
      this.gridApi,
      this.columnApi,
      this.gridOptions
    );
  }
}
