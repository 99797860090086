import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CellEditRequestEvent,
  CellEditingStoppedEvent,
  ColumnApi,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';
import { LoadingOverlay } from '../overlay/loading-overlay.component';
import { NoRowsOverlay } from '../overlay/no-rows-overlay.component';
import { DEFAULT_GRID_OPTIONS } from '../util/constants';
import { TableConfiguration } from './table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input()
  rowData: { [key: string]: any }[] | undefined;

  @Input()
  tableConfiguration!: TableConfiguration;

  @Output()
  gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();

  @Output()
  cellEditRequest: EventEmitter<CellEditRequestEvent> =
    new EventEmitter<CellEditRequestEvent>();

  @Output()
  cellEditingStopped: EventEmitter<CellEditingStoppedEvent> =
    new EventEmitter<CellEditingStoppedEvent>();

  apis$: ReplaySubject<[GridApi, ColumnApi]> = new ReplaySubject<
    [GridApi, ColumnApi]
  >();

  noRowsOverlayComponent = NoRowsOverlay;
  loadingOverlayComponent = LoadingOverlay;

  onGridReady($event: GridReadyEvent) {
    this.apis$.next([$event.api, $event.columnApi]);
    this.gridReady.emit($event);
  }

  get defaultGridOptions() {
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...this.tableConfiguration?.gridOptions,
    };
  }
}
