import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ToastHelper } from '../helpers/toast.helper';

@Injectable({ providedIn: 'root' })
export class ApiKeyGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly toastHelper: ToastHelper,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // Check if api key exists, log in using route params otherwise
    const apiKey = this.authenticationService.authToken;
    if (apiKey) {
      return true;
    }

    // Try login with given key
    const params = route.queryParams;
    if (params?.apiKey) {
      try {
        const user = await this.authenticationService
          .loginApiKey(params.apiKey)
          .toPromise();
        return !!user;
      } catch (e) {
        this.toastHelper.openSnackBar(
          'API-Key konnte nicht validiert werden',
          'ERROR',
        );
        await this.router.navigate(['/page-not-found']);
        return false;
      }
    } else {
      this.toastHelper.openSnackBar('Kein API-Key vorhanden', 'ERROR');
      await this.router.navigate(['/page-not-found']);
      return false;
    }
  }
}
