<ng-container *ngIf="_sensorBox && _selectedSensor">
  <div
    *ngIf="!showSensorSelection; else sensorSelection"
    class="row header-row ch-padding-top-8"
  >
    <div class="col-12 values-header">
      <p [appTranslate]="'sensor.type.' + _selectedSensor"></p>
      <p appTranslate="sensor.values.current"></p>
    </div>
  </div>
  <ng-template #sensorSelection>
    <div class="row header-row ch-padding-top-8">
      <div class="col-12 values-header ch-padding-top-4">
        <mat-select
          (selectionChange)="selectionChanged.emit($event.value)"
          [value]="_selectedSensor"
        >
          <mat-option *ngFor="let sensor of availableSensors" [value]="sensor">
            <p [appTranslate]="'sensor.type.' + sensor"></p>
          </mat-option>
        </mat-select>
        <p class="ch-margin-top-4" appTranslate="sensor.values.current"></p>
      </div>
    </div>
  </ng-template>
  <div class="row values-row">
    <div class="col-3 values-col values-current">
      <p class="values-text status-{{ _sensorBox.statusInfo.toLowerCase() }}">
        {{ currentValue }}
        <span class="values-text-unit">
          {{
            _sensorBox.sensors[_selectedSensor]
              ? _sensorBox.sensors[_selectedSensor].unit
              : ""
          }}
        </span>
      </p>
      <p
        class="values-category"
        [appTranslate]="'sensor.type.' + _selectedSensor"
      ></p>
    </div>
    <div class="col-2 values-col values-trend">
      <div>
        <img
          class="trend-image"
          src="assets/img/trend-{{ trend.toLowerCase() }}.svg"
          alt="Tendenz von Messwert"
        />
      </div>
      <div>
        <p class="values-category" appTranslate="sensor.values.trend"></p>
      </div>
    </div>
    <div
      *ngIf="_sensorBox.sensors[_selectedSensor]?.limit1"
      class="col-3 values-col values-warnung"
    >
      <p class="values-text sensor-warnung">
        {{ _sensorBox.sensors[_selectedSensor].limit1 }}
        <span class="values-text-unit">
          {{ _sensorBox.sensors[_selectedSensor].unit }}
        </span>
      </p>
      <p class="values-category" appTranslate="sensor.values.limit1"></p>
    </div>
    <div
      *ngIf="_sensorBox.sensors[_selectedSensor]?.limit2"
      class="col-3 values-col values-alarm"
    >
      <p class="values-text sensor-alarm">
        {{ _sensorBox.sensors[_selectedSensor].limit2 }}
        <span class="values-text-unit">
          {{ _sensorBox.sensors[_selectedSensor].unit }}
        </span>
      </p>
      <p class="values-category" appTranslate="sensor.values.limit2"></p>
    </div>
    <div
      *ngIf="_sensorBox.sensors?.batterie"
      class="col-2 values-col values-battery"
    >
      <p class="values-text">
        {{ _sensorBox.sensors.batterie.currentDataValue }}
        <span class="values-text-unit"> % </span>
      </p>
      <p class="values-category" appTranslate="sensor.values.battery"></p>
    </div>
  </div>
</ng-container>
