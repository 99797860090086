import { Component, Input } from '@angular/core';
import { TableAction } from '../table.model';

@Component({
  selector: 'app-table-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title!: string;
  @Input() compact?: boolean;
  @Input() dataLength = 0;
  @Input() tableActions: TableAction[] = [];
}
