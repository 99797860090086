import { LookupEntry, LookupEntryWithState, AlarmLevelStates } from './apiResults';

export enum ExportFileType {
  FLIWAS = 'fliwas'
}

export class ExportFile {
  id: number;
  active: boolean;
  name: string;
  description: string;
  template: LookupEntry;
  destination: LookupEntry;
  columnMap: {[key: string]: ExportFileStation};
  lastContact: Date;
}


export class ExportFileStation {
  externalId: string;
  externalName: string;
  externalWaters: string;

  sensorId: number;
  sensor: LookupEntry;

  unit: string;
  lastValue: number;
  lastContact: Date;

  typeName: string;
}