import {Injectable} from '@angular/core';
import {ExportFileResult, ExportFileListResult} from '../models/apiResults';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ExportFile} from '../models/exportFile';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor(private http: HttpClient) {
  }

  getList(): Promise<ExportFile[]> {
    return this.http.get<ExportFileListResult>(`${environment.dashboardApiUrl}/exportFile/list`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }

  delete(id: number): Promise<ExportFile> {
    return this.http.delete<ExportFileResult>(`${environment.dashboardApiUrl}/exportFile/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  load(id: number): Promise<ExportFile> {
    return this.http.get<ExportFileResult>(`${environment.dashboardApiUrl}/exportFile/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  save(messageTemplate: ExportFile): Promise<ExportFile> {
    return this.http.put<ExportFileResult>(`${environment.dashboardApiUrl}/exportFile/detail`, messageTemplate)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}

