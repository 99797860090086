<div class="sensor-image-wrapper">
  <div *ngIf="sensorImageSrc; else placeholderImage">
    <img class="sensor-image-background-blur" [src]="sensorImageSrc" />
    <img class="sensor-image" [src]="sensorImageSrc" />
  </div>
  <ng-template #placeholderImage>
    <img
      class="sensor-image-background-blur"
      src="../../../../../../assets/img/sensor/placeholder_image.jpg"
    />
    <img
      class="sensor-image"
      src="../../../../../../assets/img/sensor/placeholder_image.jpg"
    />
  </ng-template>
</div>
