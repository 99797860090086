<div
  class="weather-station ch-padding-left-16 ch-padding-right-16 ch-padding-top-8"
>
  <div class="row header-row">
    <div class="col-12 values-header">
      <p>
        <span appTranslate="sensor.values.weather-station"></span>
        <span appTranslate="sensor.values.overview"></span>
      </p>
      <p appTranslate="sensor.values.current"></p>
    </div>
  </div>
  <div
    *ngIf="getAvailableSensors() as sensors"
    class="row values-row ch-margin-top-24"
  >
    <div
      *ngIf="sensorBox.sensors['windDirection'] as windDirection"
      class="col-md-4 col-12 values-col value-windSpeed ch-margin-top-24 values-clickable"
    >
      <app-weather-station-wind
        [windSpeed]="sensorBox.sensors['windSpeed']"
        [windDirection]="windDirection.currentDataValue"
      ></app-weather-station-wind>
    </div>
    <div class="col-md-8 col-12 ch-margin-top-24">
      <div class="container-fluid">
        <div class="row container-row">
          <div
            *ngFor="let sensorName of sensors.slice(0, 4); index as i"
            class="col-sm-6 col-12 values-col value-{{
              sensorName
            }} values-clickable mt-3 mt-md-0"
          >
            <app-weather-station-sensor
              (click)="navigateToSensor(sensorName)"
              [sensorName]="sensorName"
              [sensorData]="sensorBox.sensors[sensorName]"
            ></app-weather-station-sensor>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let sensorName of sensors.slice(4)"
      class="col-md-4 col-sm-6 col-12 values-col value-{{
        sensorName
      }} mt-md-5 mt-3 values-clickable"
    >
      <app-weather-station-sensor
        (click)="navigateToSensor(sensorName)"
        [sensorName]="sensorName"
        [sensorData]="sensorBox.sensors[sensorName]"
      ></app-weather-station-sensor>
    </div>
  </div>
</div>
