import { Component, Input, Optional } from '@angular/core';
import { KachelmannService } from 'src/app/state/dashboard/kachelmann.service';

@Component({
  selector: 'app-symbol',
  templateUrl: './symbol.component.html',
  styleUrls: ['./symbol.component.scss'],
})
export class SymbolComponent {
  @Input()
  temperature!: string | number;

  @Input()
  symbol: string;

  @Optional()
  @Input()
  largeText?: boolean;

  @Optional()
  @Input()
  isNight?: boolean;

  @Optional()
  @Input()
  fontSize?: string;

  constructor(private kachelmannService: KachelmannService) {}

  getSymbolUrl(symbol: string): string {
    return this.kachelmannService.getWeatherSymbolUrl(symbol, this.isNight);
  }
}
