import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import {
  GridState,
  DataTableOptions,
  TableActionType,
  TitleSize,
} from '../../../../shared/data-table/data-table.model';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import {
  AlarmRule,
  SensorBoxAlarmGroup,
  AssignedAlarmGroup,
} from '../../../../state/noysee/models/alarmGroup';
import { LookupEntry } from '../../../../models/apiResults';
import { AlarmRuleService } from '../../../../state/noysee/alarmRule.service';
import { LookupService } from '../../../../services/lookup.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddSensorBoxAlarmGroupComponent } from '../../sensors/detail/addAlarmGroup-modal/addAlarmGroup.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-alarmGroup-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class AlarmRuleDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  alarmRuleDetails: AlarmRule;
  alarmRuleDetails$: Observable<AlarmRule>;
  alarmRuleDetailsSubscription: Subscription;
  submitted: boolean;

  crudForm: FormGroup;

  templateList: LookupEntry[];

  groupMemberList: AssignedAlarmGroup[];
  dataTableOptions: DataTableOptions<AssignedAlarmGroup>;
  gridState: GridState = null;
  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private matDialog: MatDialog,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private alarmRuleService: AlarmRuleService,
    private lookupService: LookupService,
  ) {
    this.alarmRuleDetails = new AlarmRule();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      description: new FormControl('', []),
      alarmMessageTemplateId: new FormControl('', []),
      clearMessageTemplateId: new FormControl('', []),
      definition: new FormControl('', []),
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';
  }

  ngOnInit(): void {
    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadAlarmRule();
    } else {
      this.alarmRuleDetails.active = true;
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadAlarmRule();
      });

    this.lookupService.getMessageTemplateList().then((result) => {
      this.templateList = result;
    });

    this.dataTableOptions = {
      title: 'alarmRule.members.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'alarmRule.members.table.actions.add',
          rule: () => this.authenticationService.isSuperAdmin(),
          actionFn: () => this.addAlarmGroup(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'alarmRule.members.table.actions.view',
            icon: 'app-view',
            actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
              this.showGroupMembers(assignedAlarmGroup.id),
          },
          {
            label: 'alarmRule.members.table.actions.delete',
            icon: 'app-delete',
            actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
              this.removeAlarmGroup(assignedAlarmGroup.id),
          },
        ],
      },
      // rowStyleFn: (alarmGroup: AlarmGroup) => {
      //   return {
      //     'background-color': this.getStateColor(sensor.state),
      //     'border': 'solid 1.5px white'
      //   };
      // },
      columnDef: [
        {
          key: 'id',
          displayName: 'alarmRule.members.table.columns.id',
          width: '5%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'alarmLevel',
          displayName: 'alarmRule.members.table.columns.alarmLevel',
          width: '15%',
          sortable: true,
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `sensor.state.${assignedAlarmGroup.alarmLevel}`,
        },
        {
          key: 'name',
          displayName: 'alarmRule.members.table.columns.name',
          width: this.isSuperAdmin ? '70%' : '75%',
          sortable: true,
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `${assignedAlarmGroup.alarmGroup.name}`,
          styleFn: (assignedAlarmGroup: AssignedAlarmGroup) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
            this.router.navigate([
              '/dashboard',
              'alarmGroups',
              assignedAlarmGroup.id,
              'edit',
            ]),
        },
        {
          key: 'state',
          displayName: 'alarmRule.members.table.columns.state',
          width: '20%',
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `alarmGroup.state.${assignedAlarmGroup.active}`,
        },
      ],
    };
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  async reloadAlarmGroups() {
    this.groupMemberList = this.alarmRuleDetails.alarmGroups;
  }

  private parseDate(date: string | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  private dataToForm() {
    if (this.alarmRuleDetails.definition) {
      var obj = JSON.parse(this.alarmRuleDetails.definition);
      var pretty = JSON.stringify(obj, undefined, 4);
    }

    this.crudForm.patchValue({
      name: this.alarmRuleDetails.name,
      description: this.alarmRuleDetails.description,
      active: this.alarmRuleDetails.active,
      alarmMessageTemplateId: this.alarmRuleDetails.alarmMessageTemplate?.id,
      clearMessageTemplateId: this.alarmRuleDetails.clearMessageTemplate?.id,
      definition: pretty,
    });
    // this.f.noyseeSN.disable();
  }

  loadAlarmRule() {
    const url = this.route.snapshot.params;

    this.alarmRuleDetails$ = this.alarmRuleService.getDetail(url.id);
    this.alarmRuleDetailsSubscription = this.alarmRuleDetails$.subscribe(
      (alarmGroup) => {
        console.log(alarmGroup);
        this.alarmRuleDetails = alarmGroup;
        this.dataToForm();
        this.reloadAlarmGroups();
      },
    );
  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    const _this = this;
    _this.alarmRuleDetails.active = !_this.alarmRuleDetails.active;
    _this.crudForm.patchValue({
      active: _this.alarmRuleDetails.active,
    });
    console.debug(_this.alarmRuleDetails.active);
  }

  async saveAlarmRule() {
    this.submitted = true;
    const _this = this;

    if (this.crudForm.invalid) {
      return;
    }

    _this.alarmRuleDetails.name = _this.f.name.value;
    _this.alarmRuleDetails.description = _this.f.description.value;
    _this.alarmRuleDetails.definition = _this.f.definition.value;
    _this.alarmRuleDetails.alarmMessageTemplate = {
      id: _this.f.alarmMessageTemplateId.value,
      name: null,
    };
    _this.alarmRuleDetails.clearMessageTemplate = {
      id: _this.f.clearMessageTemplateId.value,
      name: null,
    };

    try {
      this.alarmRuleService
        .saveDetail(_this.alarmRuleDetails)
        .then((result) => {
          this.alarmRuleDetails = result;
          this.toastHelper.openSnackBar('Alarmregel gespeichert!', 'SUCCESS');
          if (this.mode === 'edit') {
            this.goBack();
          }
          _this.alarmRuleDetails = result;
          _this.dataToForm();
          console.debug(_this.alarmRuleDetails);
        })
        .catch((error) => {
          this.toastHelper.openSnackBar(
            'Fehler beim Speichern der Alarmregel!',
            'SUCCESS',
          );
        });

      // if (this.mode === 'add') {
      //   await this.alarmGroupService.createDevice(this.alarmGroupDetails);
      //   this.toastHelper.openSnackBar('Erstellen des Gerätes erfolgreich!', 'SUCCESS');
      // } else if (this.mode === 'edit') {
      //   await this.alarmGroupService.updateDevice(this.alarmGroupDetails);
      //   this.toastHelper.openSnackBar('Ändern des Gerätes erfolgreich!', 'SUCCESS');
      // }
    } catch (e) {
      if (this.mode === 'add') {
        // if (e.message === ErrorCodes.DUPLICATE_LOGIN_NAME) {
        //   this.f.username.setErrors({ 'duplicatedUsername': true });
        //   this.emailDuplicated = true;
        // }
        // this.toastHelper.openSnackBar(ErrorHandler.getText(e.message), 'ERROR');
      } else {
        this.toastHelper.openCrudChangeError();
      }
    }
  }

  deleteAlarmRule() {
    const _this = this;

    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diese Alarmregel wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.alarmRuleService
            .delete(_this.alarmRuleDetails.id)
            .then((result) => {
              _this.alarmRuleService.getList().then((result) => {
                this.goBack();
                _this.toastHelper.openSnackBar(
                  'Alarmregel gelöscht!',
                  'SUCCESS',
                );
              });
            });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  goBack() {
    this.router.navigate(['/dashboard', 'alarmRules', 'overview']);
  }

  showGroupMembers(alarmGroupId: number) {
    this.router.navigate(['/dashboard', 'alarmGroups', alarmGroupId, 'view']);
  }

  removeAlarmGroup(memberId: number) {
    this.alarmRuleService
      .removeAlarmGroup(this.alarmRuleDetails.id, memberId)
      .then((data) => {
        this.loadAlarmRule();
      });
  }

  addAlarmGroup() {
    const _this = this;
    const dialogOptions: MatDialogConfig = {
      data: {
        parentType: 'alarmRule',
        name: this.alarmRuleDetails.name,
        id: this.alarmRuleDetails.id,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddSensorBoxAlarmGroupComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        _this.loadAlarmRule();
      });
  }
}
