import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/users.service';
import { User } from '../../../models/user';
import { AlertType, Notification, NotificationState } from '../../../models/notification';
import { NotificationService } from '../../../services/notification.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ApprovalDialogHelper } from '../../../helpers/approval-dialog.helper';
import { TranslateService } from '@ngx-translate/core';
import { ActivateSsoUserModalComponent } from '../users/activate-ssoUser-modal/activate-ssoUser-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
	selector: 'app-overview',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
	notificationSubscription: Subscription;
	notifications: Notification[] = [];
	mode: String;
	currentUser: User;
	currentUserSubscription: Subscription;
	isSuperAdmin: boolean;
	public options: Intl.DateTimeFormatOptions = {
		weekday: 'long',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
	};

	constructor(
		private notificationService: NotificationService,
		private authenticationService: AuthenticationService,
		private userService: UsersService,
		private route: ActivatedRoute,
		private router: Router,
		private approvalDialogHelper: ApprovalDialogHelper,
		private translateService: TranslateService,
		private matDialog: MatDialog,
	) {
		this.mode = '';
		this.notificationSubscription = null;
		this.isSuperAdmin = false;
	}

	ngOnInit(): void {
		const data = this.route.snapshot.data;
		if (data.mode) {
			this.mode = data.mode;
		}

		this.isSuperAdmin = this.authenticationService.isSuperAdmin();

		this.currentUserSubscription =
			this.authenticationService.authenticatedUserObservable.subscribe(
				(user) => {
					if (user) {
						this.currentUser = user;
					}
				},
			);

		this.notificationSubscription = this.notificationService.open.subscribe(
			(notifications) => {
				this.notifications = notifications;
			},
		);
	}

	ngOnDestroy() {
		this.currentUser = null;

		this.notificationSubscription?.unsubscribe();
		this.currentUserSubscription?.unsubscribe();
	}

	goToNotification(notification) {
		if (!!notification.sensorBox) {
			this.router.navigate([
				'/dashboard',
				'sensors',
				'detail',
				notification.sensorBox.id,
				'values',
			]);
		} else if (notification.alertType===AlertType.userRequest) {
			// Allow user to "unlock" pending users
			this.activateUserDlg( notification.user);
		}
	}

	public getStateIcon(notification: Notification): string {
		if (notification.state === NotificationState.NEW) {
			return 'priority_high';
		}
		if (notification.state === NotificationState.IN_PROGRESS) {
			return 'build';
		}
		if (notification.state === NotificationState.RESOLVED) {
			return 'done';
		}

		return '';
	}
	
	public getAlertTypeIcon( notification: Notification) {
		return 'alert-'+notification.alertType+'.svg';
	}

	public getClass(notification: Notification): string {
		if (notification.state === NotificationState.NEW) {
			return 'defective';
		}
		if (notification.state === NotificationState.IN_PROGRESS) {
			return 'disabled';
		}
		if (notification.state === NotificationState.RESOLVED) {
			return 'open';
		}

		return '';
	}

	public activateUserDlg(user: User) {
		const dialogOptions: MatDialogConfig = {
			data: {
				user: user,
			},
			maxWidth: 600,
		};
		return this.matDialog
			.open(ActivateSsoUserModalComponent, dialogOptions)
			.afterClosed()
			.toPromise();

	}

}
