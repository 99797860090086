<div class="mat-dialog-title" *ngIf="customer">
  <p><strong>AGS für Alarmierung zu Kunde {{customer.name}} hinzufügen</strong></p>
</div>
<div class="mat-dialog-title" *ngIf="!customer">
  <p><strong>AGS für Filter auswählen</strong></p>
</div>

<div class="form-field">
  <label for="agsFilter">Filter (ab 5 Zeichen)</label>
  <input id="agsFilter" [(ngModel)]="agsFilter" (input)="searchAgs()"/>
</div>

<div class="form-field" [class.error]="false">
<label for="input-customer">Treffer ({{agsList ? agsList.length : 0}})</label>
  <select [(ngModel)]="ags">
    <option [value]="null">-</option>
    <option [ngValue]="entry" *ngFor="let entry of agsList">{{entry.ags}} {{entry.name}}</option>
  </select>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-secondary" (click)="addAgs()">Hinzufügen</button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>