import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { DevicesService } from '../../../../../services/devices.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { NoyseeDevice } from 'src/app/models/noyseeDevice';
import { IoTSensorBoxSettings } from 'src/app/state/noysee/models/sensor';

export class ReplaceDeviceDialogParameters {
  sensorBoxId: number;
}

@Component({
  selector: 'app-replaceDeviceDialog',
  templateUrl: './replaceDeviceDialog.component.html',
  styleUrls: ['./replaceDeviceDialog.component.scss'],
})
export class ReplaceDeviceDialogComponent implements OnInit {
  sensorBoxDetails: IoTSensorBoxSettings;
  isAdmin: boolean;
  isSuperAdmin: boolean;

  sensorBoxId: number;
  name: string;
  oldNoyseeSN: string;
  newNoyseeSN: string;

  deviceList: NoyseeDevice[];

  constructor(
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private noyseeService: NoyseeService,
    private deviceService: DevicesService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReplaceDeviceDialogComponent>
  ) {
    this.isSuperAdmin = false;
    this.isAdmin = false;

    this.oldNoyseeSN = '';
    this.newNoyseeSN = '';
  }

  ngOnInit(): void {
    this.sensorBoxId = this.data?.sensorBoxId;

    this.noyseeService
      .loadAdminSettings(this.sensorBoxId)
      .toPromise()
      .then((data) => {
        this.sensorBoxDetails = data;
        this.oldNoyseeSN = this.sensorBoxDetails.noyseeSN;
        this.name = this.sensorBoxDetails.name;
      });

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.deviceService.getInstallableDeviceList().then((result) => {
      this.deviceList = result;
    });
  }

  replace(): void {
    this.deviceService
      .replaceDevice(this.sensorBoxId, this.newNoyseeSN)
      .then((data) => {
        this.toastHelper.openSnackBar('Gerät aktiviert', 'SUCCESS');
        this.dialogRef.close();
        this.router.navigate([
          '/dashboard',
          'sensors',
          'detail',
          this.sensorBoxId,
          'admin-sensor',
        ]);
      });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
