import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseDataTableColumnDef,
  DataTableOptions,
  ExportType,
  GridState,
  TableAction,
  TableFilterType,
} from './data-table.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PaginatorComponent } from '../paginator/paginator.component';
import { TranslatePipe } from '@ngx-translate/core';
import { DownloadService } from '../download/download.service';
import { isEqual } from 'lodash';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PdfFilter } from '../pdf/pdf.model';
import { TableStateService } from '../../services/tableState.service';
import { DataTableStateService } from '../data-table-state/data-table-state.service';
import { isMoment } from 'moment';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements AfterViewInit, OnDestroy {
  private data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private afterViewInit$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private dataTableOptions$: BehaviorSubject<DataTableOptions<any>> =
    new BehaviorSubject<DataTableOptions<any>>(null);
  private state$: BehaviorSubject<GridState> = new BehaviorSubject<any>(null);
  private destroy$: Subject<void> = new Subject<void>();
  private gridParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tableInit$: BehaviorSubject<boolean> = new BehaviorSubject<any>(
    false,
  );
  private filterChange$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  now = new Date();

  @Input()
  public set data(data: any[]) {
    this.data$.next(data);
  }

  public get data() {
    return this.data$.value;
  }

  public set isAfterViewInit(flag: boolean) {
    this.afterViewInit$.next(true);
  }

  public get isAfterViewInit() {
    return this.afterViewInit$.value;
  }

  @Input()
  public set dataTableOptions(dataTableOptions: DataTableOptions<any>) {
    this.dataTableOptions$.next(dataTableOptions);
  }

  public get dataTableOptions() {
    return this.dataTableOptions$.value;
  }

  @Input()
  public set state(state: GridState) {
    this.state$.next(state);
  }

  public get state(): GridState {
    return this.state$.value;
  }

  set gridParams(params: any) {
    this.gridParams$.next(params);
  }

  get gridParams() {
    return this.gridParams$.value;
  }

  columns: string[] = [];
  filterColumns: string[] = [];
  pageCounter: any[] = [];

  filterGroup: FormGroup = new FormGroup({});

  @ViewChild(MatSort)
  sorter: MatSort;
  @ViewChild(PaginatorComponent)
  paginatorComponent: PaginatorComponent;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @Output() rowChange = new EventEmitter<any>();


  get visibleColumnDefinitions() {
    return this.dataTableOptions.columnDef
      .filter(({ hidden, onlyExport }) => !hidden && !onlyExport)
      .map((col) => ({
        ...col,
        key: col.key as string,
      }));
  }

  @Output()
  filter: Subject<any> = new Subject();

  constructor(
    private downloadService: DownloadService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tableStateService: TableStateService,
    private dataTableStateService: DataTableStateService,
  ) {
    combineLatest([this.afterViewInit$, this.dataTableOptions$, this.state$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([init, dataTableOptions, state]) => {
        if (init === false && dataTableOptions) {
          this.columns = this.visibleColumnDefinitions.map(
            ({ key }) => <string>key,
          );
          if (
            dataTableOptions.rowActions?.actionList?.length > 0 &&
            this.columns.lastIndexOf('dataActions') === -1 &&
            this.hasVisibleMenuItems() &&
            this.columns
          ) {
            this.columns.push('dataActions');
          }
          this.filterColumns = this.columns.map((key) => key + '_filter');
          this.initializeFilter();
          this.dataSource.sortingDataAccessor = (item, prop) => {
            const columnDef = this.dataTableOptions.columnDef.find(
              ({ key }) => key === prop,
            );
            if (columnDef && columnDef.sortValueFn) {
              return columnDef.sortValueFn(item);
            }
            return item[prop];
          };
          this.setFilterDefaults();
        }
        if (init === true && dataTableOptions) {
          this.dataSource.paginator = this.paginatorComponent.paginator;
          this.dataSource.sort = this.sorter;
          this.pageCounter = new Array(
            this.paginatorComponent.paginator.getNumberOfPages(),
          );
          this.tableInit$.next(true);
        }
        if (init === true && state) {
          if (state?.sorter && state?.sorter?.direction) {
            this.dataSource.sort.sort({
              id: state?.sorter?.active,
              start: state?.sorter?.direction,
              disableClear: true,
            });
          }
          if (state?.page && this.pageCounter) {
            setTimeout(() => {
              this.paginatorComponent.jumpToPage(state?.page);
            }, 2);
          }
        }
      });
    let filters;
    combineLatest([this.tableInit$, this.data$, this.filterChange$])
      .pipe(
        takeUntil(this.destroy$),
        map(([init, data, filterValues]) => {
          if (init === false) {
            return [];
          }
          const clearedFilter = this.prepareFilterValues(filterValues);
          if (data) {
            if (
              Object.keys(clearedFilter).length !== 0 &&
              (!filters || !isEqual(filters, clearedFilter))
            ) {
              // console.log('Filter exists and has changed', clearedFilter, 'old filter', filters);
              // Reset page index when filtering
              this.paginatorComponent.firstPage();
            }
            filters = clearedFilter;

            return data.filter((elem) => {
              return this.filterData(elem, clearedFilter);
            });
          }
          return [];
        }),
        filter((filtered) => !isEqual(this.dataSource.data, filtered)),
      )
      .subscribe((filtered) => {
        this.dataSource.data = filtered;
        setTimeout(() => {
          this.paginatorComponent?.restorePage();
        });
      });
    this.tableStateService.isGrid.next(true);
    this.tableStateService.triggerGridState
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.tableStateService.setState.next(
          this.dataTableStateService.serializeState(this.getGridState()),
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.tableStateService.isGrid.next(false);
  }

  ngAfterViewInit(): void {
    this.isAfterViewInit = true;
  }

  changeTableColumnDef(columnKey: string, property: string, value: any) {
    setTimeout(() => {
      const columnDef = this.dataTableOptions.columnDef.find(
        ({ key }) => key === columnKey,
      );

      if (columnDef) {
        columnDef[property] = value;
      }

      if (property.includes('filter')) {
        const { filterType, key } = columnDef;
        const control = this.filterGroup.get(`${key as string}_${filterType}`);

        const ctrlValue = control.value;

        control.setValue('', { onlySelf: true, emitEvent: false });
        setTimeout(() => {
          control.setValue(ctrlValue);
        });
      }
    });
  }

  setSorterDefaults() {
    if (this.state?.sorter) {
      this.sorter.sortChange.emit(this.state.sorter);
    }
  }

  setFilterDefaults() {
    if ('filterDefaults' in this.dataTableOptions) {
      Object.entries(this.dataTableOptions.filterDefaults).forEach(
        ([key, val]) => {
          const col = this.dataTableOptions.columnDef.find(
            (elem) => elem.key === key,
          );
          if (typeof val === 'string') {
            this.filterGroup
              .get(key + '_' + (col?.filterType || TableFilterType.TEXT))
              .setValue(val, { silent: true });
          } else {
            this.filterGroup
              .get(key + '_' + TableFilterType.DATE + '_from')
              .setValue(val.from, { silent: true });
            this.filterGroup
              .get(key + '_' + TableFilterType.DATE + '_to')
              .setValue(val.to, { silent: true });
          }
          if (col.filterChange) {
            if (typeof val === 'string') {
              this.filterGroup
                .get(key + '_' + (col?.filterType || TableFilterType.TEXT))
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value) => col.filterChange(value));
            } else {
              this.setCustomDateFilter(key, col);
            }
          }
        },
      );
    }
    if (this.state?.filter) {
      this.filterGroup.patchValue(this.state.filter);
    }
  }

  setCustomDateFilter(key, col) {
    this.filterGroup
      .get(key + '_' + TableFilterType.DATE + '_to')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        filter((val) => !!val),
        withLatestFrom(
          this.filterGroup.get(key + '_' + TableFilterType.DATE + '_from')
            .valueChanges,
        ),
        map(([to, from]) => ({ from, to })),
      )
      .subscribe(({ from, to }) =>
        col.filterChange({
          timestamp_DATE_from: from,
          timestamp_DATE_to: to,
        }),
      );
  }

  resetFilter(
    key: string | number | symbol,
    tableFilterType: string | TableFilterType,
  ) {
    const filterGrpKey = `${<string>key}_${tableFilterType}`;
    if (tableFilterType === TableFilterType.DATE) {
      this.filterGroup.get(`${filterGrpKey}_from`).setValue('');
      this.filterGroup.get(`${filterGrpKey}_to`).setValue('');
    } else {
      this.filterGroup.get(filterGrpKey).setValue('');
    }
  }

  initializeFilter() {
    const formControls: { [key: string]: FormControl } = {};
    this.dataTableOptions.columnDef.forEach((col) => {
      const formControlKey =
        <string>col.key + '_' + (col.filterType ?? TableFilterType.TEXT);

      if (col.filterType === TableFilterType.DATE) {
        formControls[formControlKey + '_from'] = new FormControl('');
        formControls[formControlKey + '_to'] = new FormControl('');
      } else {
        formControls[formControlKey] = new FormControl('');
      }
    });

    this.filterGroup = new FormGroup(formControls);
    this.filterGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterParam) => {
        this.filterChange$.next(filterParam);
      });
  }

  prepareFilterValues(filterValues: { [key: string]: string }) {
    return Object.entries(filterValues)
      .filter(([_, v]) => v ?? false)
      .reduce((agg, [key, val]) => {
        const [columnName, type, fromTo] = key.split('_');

        if (type !== TableFilterType.DATE) {
          agg[columnName] = val;
        } else {
          if (!(columnName in agg)) {
            agg[columnName] = {};
          }

          agg[columnName][fromTo] = val;
        }

        return agg;
      }, {});
  }

  filterData(dataItem: any, clearedFilter: { [key: string]: any }) {
    let filtered = true;

    // tslint:disable-next-line:forin
    for (const filterKey in clearedFilter) {
      const colDef = this.dataTableOptions.columnDef.find(
        (col) => col.key === filterKey,
      );

      if (!colDef.filterChange) {
        if (colDef.filterFn) {
          if (
            !this.filterFn(colDef.filterFn, dataItem, clearedFilter[filterKey])
          ) {
            filtered = false;
          }
        } else {
          if (
            filterKey in dataItem &&
            !this.baseFilter(
              dataItem[filterKey],
              clearedFilter[filterKey],
              colDef.filterType,
            )
          ) {
            filtered = false;
          }
        }
      }
    }
    return filtered;
  }

  filterFn(fn, dataItem, filterVal) {
    let filtered = true;
    if (typeof filterVal === 'string') {
      if (!fn(dataItem, filterVal)) {
        filtered = false;
      }
    } else {
      if ('from' in filterVal && 'to' in filterVal) {
        if (!fn(dataItem, filterVal.from, filterVal.to)) {
          filtered = false;
        }
      }
    }

    return filtered;
  }

  baseFilter(dataValue, filterVal, filterType: TableFilterType) {
    let filtered = true;
    if (filterType === TableFilterType.SELECT) {
      if (
        dataValue.toString().toLowerCase().split('_').slice(-1)[0] !==
          filterVal.toLowerCase() &&
        dataValue.toString().toLowerCase() !== filterVal.toLowerCase()
      ) {
        filtered = false;
      }
    } else if (typeof filterVal === 'string' && !Array.isArray(dataValue)) {
      if (
        !dataValue.toString().toLowerCase().includes(filterVal.toLowerCase())
      ) {
        filtered = false;
      }
    } else if (typeof filterVal === 'string') {
      const names = dataValue.map((val) => val.name.toLowerCase());
      if (!names.toString().includes(filterVal.toLowerCase())) {
        filtered = false;
      }
    } else {
      if ('from' in filterVal && 'to' in filterVal) {
        const date = Date.parse(dataValue);
        const from = isMoment(filterVal.from)
          ? filterVal.from.isSameOrBefore(date, 'day')
          : date >= Date.parse(filterVal.from);
        const to = isMoment(filterVal.to)
          ? filterVal.to.isSameOrAfter(date, 'day')
          : date <= Date.parse(filterVal.to);

        if (!(from && to)) {
          filtered = false;
        }
      }
    }

    return filtered;
  }

  prepareExportHeader(exportType: ExportType) {
    return this.dataTableOptions.columnDef
      .filter(
        (col) =>
          col?.hidden !== true &&
          (!col.onlyExport ||
            (col.onlyExport && col.exports.lastIndexOf(exportType) !== -1)),
      )
      .map((col) => {
        let name = col.displayName;
        if (
          col.exportName &&
          (!col.exportOverride || col.exportOverride === exportType)
        ) {
          name = col.exportName;
        }
        return this.translatePipe.transform(name);
      });
  }

  prepareDownloadData(exportType: ExportType, removeLineBreaks?: boolean) {
    const sorter = this.dataSource.sort;
    const data = this.dataSource.data;
    const rowStyle =
      removeLineBreaks !== true && !!this.dataTableOptions?.rowStyleFn;

    return this.dataSource.sortData(data, sorter).map((elem) => {
      const transformedElement = {};
      this.dataTableOptions.columnDef.forEach((col) => {
        if (
          col?.hidden === true ||
          (col.onlyExport && col.exports.lastIndexOf(exportType) === -1)
        ) {
          return;
        }
        const cellStyle = !!col?.styleFn;

        if (
          'exportValue' in col &&
          (!col.exportOverride || col.exportOverride === exportType)
        ) {
          transformedElement[col.key] = this.translatePipe.transform(
            col.exportValue(elem),
          );
        } else if ('valueTransform' in col) {
          transformedElement[col.key] = this.translatePipe.transform(
            col.valueTransform(elem),
          );
        } else {
          transformedElement[col.key] = this.translatePipe.transform(
            elem[col.key],
          );
        }

        if (removeLineBreaks) {
          if (typeof transformedElement[col.key] === 'string') {
            transformedElement[col.key] = transformedElement[col.key].replace(
              /\n/gm,
              '',
            );
          }
        }
        if (rowStyle) {
          const style = this.dataTableOptions.rowStyleFn(elem);
          if (style && !!style['background-color']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              fillColor: style['background-color'],
            };
          }
          if (style && !!style['font-weight']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              bold: style['font-weight'] === 'bold',
            };
          }
          if (style && !!style['color']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              color: style['color'],
            };
          }
        }
        if (cellStyle) {
          const style = col.styleFn(elem);
          if (style && !!style['background-color']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              fillColor: style['background-color'],
            };
          }
          if (style && !!style['font-weight']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              bold: style['font-weight'] === 'bold',
            };
          }
          if (style && !!style['color']) {
            transformedElement[col.key] = {
              ...transformedElement[col.key],
              text:
                transformedElement[col.key] === ''
                  ? ' '
                  : transformedElement[col.key],
              color: style['color'],
            };
          }
        }
      });
      return transformedElement;
    });
  }

  prepareTitle() {
    let title = this.translatePipe.transform(this.dataTableOptions.title);
    if (this.dataTableOptions.export.exportHeadline) {
      title = this.dataTableOptions.export.exportHeadline + ' - ' + title;
    }

    return title;
  }

  prepareFilterList(): PdfFilter[] {
    return Object.entries(this.filterGroup.value)
      .filter(([_, v]) => v ?? false)
      .map(([k, v]) => {
        const key = k.split('_').splice(0, 1)[0];
        const colDef = this.dataTableOptions.columnDef.find(
          (col) => <string>col.key === key,
        );

        let value = v;
        if (colDef.filterType === TableFilterType.SELECT) {
          value =
            colDef.filterValues.find((elem) => elem.value === value)?.label ||
            v;
        }

        return {
          name: this.translatePipe.transform(
            colDef.exportName ?? colDef.displayName,
          ),
          value: this.translatePipe.transform(<string>value),
        };
      });
  }

  downloadCsv() {
    const columnHeader = this.prepareExportHeader(ExportType.CSV);
    const data = this.prepareDownloadData(ExportType.CSV, true);
    const title = this.prepareTitle();

    this.downloadService.exportToCsv(title, data, columnHeader);
  }

  downloadPdf() {
    this.downloadService
      .exportToPdf({
        title: this.prepareTitle(),
        data: this.prepareDownloadData(ExportType.PDF),
        header: this.prepareExportHeader(ExportType.PDF),
        filter: this.prepareFilterList(),
      })
      .subscribe();
  }

  public dataChange(row: any) {
    this.rowChange.emit(row);
  }

  private hasVisibleMenuItems(): boolean {
    if (
      this.dataTableOptions?.rowActions?.rule &&
      this.dataTableOptions?.rowActions?.rule() === false
    ) {
      return false;
    }
    return !!this.dataTableOptions?.rowActions?.actionList.find(
      (actionElement: TableAction) => {
        if (!actionElement.rule && !actionElement.rule$) {
          return true;
        }
        if (
          actionElement.rule instanceof Function &&
          actionElement.rule() === true
        ) {
          return true;
        }

        return actionElement?.rule$?.value === true;
      },
    );
  }

  getGridState(): GridState {
    return {
      filter: this.filterGroup.value,
      page: this.paginatorComponent.paginator.pageIndex,
      url: this.router.url,
      sorter: {
        active: this.sorter.active,
        direction: this.sorter.direction,
      },
    };
  }

  getCellStyle(colDef: BaseDataTableColumnDef<any>, data: any): SafeStyle {
    const styleObj: { [key: string]: string } = colDef?.styleFn
      ? colDef.styleFn(data)
      : undefined;
    if (styleObj && Object.keys(styleObj).length) {
      const stylesAsString: string = Object.entries(styleObj).reduce(
        (stylePartialString, [styleKey, styleValue]) => {
          styleKey = styleKey.replace(
            /([A-Z])/g,
            (matches) => `-${matches[0].toLowerCase()}`,
          );
          return `${stylePartialString}${styleKey}:${styleValue};`;
        },
        '',
      );
      return this.sanitizer.bypassSecurityTrustStyle(stylesAsString);
    }
    return styleObj;
  }

  getRowStyle(data: any): SafeStyle {
    const styleObj: { [key: string]: string } = this.dataTableOptions
      ?.rowStyleFn
      ? this.dataTableOptions.rowStyleFn(data)
      : undefined;
    if (styleObj && Object.keys(styleObj).length) {
      const stylesAsString: string = Object.entries(styleObj).reduce(
        (stylePartialString, [styleKey, styleValue]) => {
          styleKey = styleKey.replace(
            /([A-Z])/g,
            (matches) => `-${matches[0].toLowerCase()}`,
          );
          return `${stylePartialString}${styleKey}:${styleValue};`;
        },
        '',
      );
      return this.sanitizer.bypassSecurityTrustStyle(stylesAsString);
    }
    return styleObj;
  }
}
