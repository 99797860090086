import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../../services/users.service';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { User, UserState } from '../../../../models/user';
import { first } from 'rxjs/operators';
import { AuthenticationService, FeatureSet } from '../../../../services/authentication.service';
import { environment } from '../../../../../environments/environment';
import { PasswordValidators } from '../../../../helpers/password-validators';
import { ToastHelper } from '../../../../helpers/toast.helper';
import { getRoles, Role } from '../../../../models/role.enum';
import { GridState } from '../../../../shared/data-table/data-table.model';
import { DataTableStateService } from '../../../../shared/data-table-state/data-table-state.service';
import { Observable, Subscription } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import { ErrorCodes, ErrorHandler } from '../../../../models/errorHandler';
import { ImageService } from '../../../../services/image.service';
import { TranslatePipe } from '@ngx-translate/core';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { Customer } from 'src/app/models/customerList';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';

@Component({
	selector: 'app-crud-user',
	templateUrl: './crud-user.component.html',
	styleUrls: ['./crud-user.component.scss'],
})
export class CrudUserComponent implements OnInit, OnDestroy {
	private readonly PHONE_PATTERN: RegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
	Role = Role;
	mode: string;
	user: User;
	crudUsersForm: FormGroup;
	submitted: boolean;
	range: FormGroup;
	isSuperAdmin: boolean;
	isAdmin: boolean;
	isGuest: boolean;
	ctx: GridState = null;
	profileImage: SafeUrl | string;
	userSubscription: Subscription;
	roles: { label: string; value: string; disabled: boolean }[] = [];
	selectedCustomer: Customer;

	featureSet$: Observable<FeatureSet> = this.authenticationService.getFeatureSet();
	featureSetSubscription: Subscription;

	allowEdit = false;

	phoneRequired = false;

	emailDuplicated = false;

	get disableReinvite() {
		return !(this.user.state == UserState.INVITED);
	}

	get problemAdministration() {
		return this.crudUsersForm.get('claimProblemAdministration').value;
	}

	@ViewChild('imageUpload') imageUpload: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private applicationContextService: ApplicationContextService,
		private authenticationService: AuthenticationService,
		private userService: UsersService,
		private toastHelper: ToastHelper,
		private router: Router,
		private dialog: MatDialog,
		private dataTableStateService: DataTableStateService,
		private readonly translatePipe: TranslatePipe,
		private store: Store,
		private imageService: ImageService,
		private approvalDialogHelper: ApprovalDialogHelper,
	) {
		this.user = new User();
		this.crudUsersForm = this.formBuilder.group({
			image: new FormControl('', []),
			username: new FormControl('', [Validators.maxLength(40)]),
			name: new FormControl('', [
				Validators.required,
				Validators.maxLength(80),
			]),
			email: new FormControl('', [
				Validators.required,
				Validators.maxLength(80),
			]),
			phone: new FormControl('', [
				Validators.pattern(this.PHONE_PATTERN),
				Validators.maxLength(40),
			]),
			mobile: new FormControl('', [
				Validators.pattern(this.PHONE_PATTERN),
				Validators.maxLength(40),
			]),
			password: new FormControl('', [
				Validators.required,
				PasswordValidators.passwordMatchesPolicies,
				Validators.maxLength(80),
			]),
			role: new FormControl('', [Validators.required]),
		});
		this.mode = '';
		this.submitted = false;
		this.profileImage = environment.avatarPlaceholderUrl;
		this.isSuperAdmin = false;
		this.isAdmin = false;
		this.isGuest = false;
		this.roles = getRoles(this.authenticationService);

		this.allowEdit = false;
	}

	ngOnInit(): void {
		console.debug("edit user");

		const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
		if (ctx) {
			this.ctx = this.dataTableStateService.deserializeState(ctx);
		}

		const data = this.route.snapshot.data;
		if (data.mode) {
			this.mode = data.mode;
		}

		this.store
			.select(GuiState.selectedCustomer)
			.pipe(first((customer) => !!customer))
			.subscribe((customer) => {
				this.selectedCustomer = customer;
			});

		const url = this.route.parent.snapshot.url;
		this.applicationContextService.selectedUserId = +url[url.length - 1].path;
		this.removeControls(this.mode);

		if (this.mode === 'edit') {
			// get correct values here
			this.userService
				.getById(this.applicationContextService.selectedUserId)
				.pipe(first((user) => !!user))
				.subscribe((user) => {
					this.user = user;
					this.crudUsersForm.patchValue({
						username: this.user.login,
						name: this.user.name,
						email: this.user.email,
						phone: this.user.phone,
						mobile: this.user.mobile,
						role: this.user.role,
					});
					this.f.username.disable();

					if (this.isGuest) {
						this.allowEdit = false;
						this.crudUsersForm.disable();
					} else if (
						this.authenticationService.isAdmin() ||
						this.authenticationService.isSuperAdmin
					) {
						this.allowEdit = true;
						this.crudUsersForm.enable();
					} else if (
						this.authenticationService.authenticatedUser.id === this.user.id
					) {
						this.allowEdit = true;
						this.crudUsersForm.enable();
					} else {
						this.allowEdit = false;
						this.crudUsersForm.disable();
					}

				});
		} else if (this.mode === 'edit-self') {
			this.userSubscription =
				this.authenticationService.authenticatedUserObservable.subscribe(
					(user) => {
						if (user) {
							this.user = user;
							this.imageService.getUserImage(this.user.id).then((imageUrl) => {
								this.profileImage = imageUrl;
							});
						}

						this.crudUsersForm.patchValue({
							username: this.user.login,
							name: this.user.name,
							email: this.user.email,
							phone: this.user.phone,
							mobile: this.user.mobile,
							role: this.user.role,
						});
						this.f.username.disable();
						if (this.user.role === Role.ADMIN) {
							this.crudUsersForm.get('role').disable();
						}
					},
				);
			// Disable all other fields if user is guest
			if (this.isGuest) {
				this.crudUsersForm.disable();
			}
		} else if (this.mode === 'invite') {
			this.user.customer = this.selectedCustomer;
		} else if (this.mode === 'add') {
			this.user.customer = this.selectedCustomer;
		}
		
		this.featureSetSubscription = this.featureSet$.subscribe(featureSet => {
			console.debug( featureSet);

			this.isSuperAdmin = featureSet.isSuperadmin();
			this.isAdmin = featureSet.isAdmin();
			this.isGuest = featureSet.isGuest();
			
			// Disbale when Guest
			if (this.mode === 'edit-self' && !featureSet.hasPrivilege( "user.me", null)) {
				this.crudUsersForm.disable();
			}

			// Disable editing username if user is not super admin
			if (!featureSet.isSuperadmin) {
				this.f.username.disable();
			}

		});
		
		
	}

	get f() {
		return this.crudUsersForm.controls;
	}

	ngOnDestroy() {
		this.applicationContextService.selectedUserId = 0;
		this.userSubscription?.unsubscribe();
		this.featureSetSubscription?.unsubscribe();
	}

	async saveUser() {
		this.submitted = true;
		this.emailDuplicated = false;

		if (this.crudUsersForm.invalid) {
			return;
		}

		if (this.mode === 'invite') {
			try {
				await this.authenticationService.inviteUser(
					this.f.email.value,
					this.f.role.value,
					this.user.customer,
				);
				this.toastHelper.openSnackBar(
					this.translatePipe.transform('user.crud.success.invite'),
					'SUCCESS',
				);
				await this.router.navigate(['/dashboard', 'users', 'overview'], {
					state: this.ctx,
				});
			} catch (e) {
				if (e.message === ErrorCodes.DUPLICATE_LOGIN_NAME) {
					this.f.email.setErrors({ duplicatedEmail: true });
					this.emailDuplicated = true;
				}
				this.toastHelper.openSnackBar(ErrorHandler.getText(e.message), 'ERROR');
			}
		} else {
			this.user.name = this.f.name.value;
			this.user.login = this.f.username.value;
			this.user.email = this.f.email.value;
			this.user.phone = this.f.phone.value.replace(/ /g, '');
			this.user.mobile = this.f.mobile.value.replace(/ /g, '');
			this.user.role = this.f.role.value;

			try {
				if (this.mode === 'add') {
					await this.userService.createUser(this.user, this.f.password.value);
					this.toastHelper.openSnackBar('user.crud.success.add', 'SUCCESS');
				} else if (this.mode === 'edit') {
					await this.userService.updateUser(this.user);
					this.toastHelper.openSnackBar('user.crud.success.edit', 'SUCCESS');
				} else if (this.mode === 'edit-self') {
					await this.userService.updateUser(this.user, true);
					this.toastHelper.openSnackBar(
						'user.crud.success.edit-self',
						'SUCCESS',
					);
				}
				let navRoute = ['/users', 'overview'];
				if (this.ctx?.url) {
					navRoute = [this.ctx?.url];
				}

				await this.router.navigate(navRoute, { state: this.ctx });
			} catch (e) {
				if (this.mode === 'add') {
					if (e.message === ErrorCodes.DUPLICATE_LOGIN_NAME) {
						this.f.username.setErrors({ duplicatedUsername: true });
						this.emailDuplicated = true;
					}
					this.toastHelper.openSnackBar(
						ErrorHandler.getText(e.message),
						'ERROR',
					);
				} else {
					this.toastHelper.openCrudChangeError();
				}
			}
		}
	}

	isEditUserSuperadmin() {
		return this.user.role === Role.SUPERADMIN;
	}

	goBack() {
		let navRoute = ['/users', 'overview'];
		if (this.ctx?.url) {
			navRoute = [this.ctx?.url];
		}
		this.router.navigate(navRoute, { state: this.ctx });
	}

	private removeControls(mode: string): void {
		switch (mode) {
			case 'invite':
				this.crudUsersForm.removeControl('username');
				this.crudUsersForm.removeControl('name');
				this.crudUsersForm.removeControl('phone');
				this.crudUsersForm.removeControl('password');
				break;
			case 'edit':
				this.crudUsersForm.removeControl('password');
				break;
			case 'edit-self':
				this.crudUsersForm.removeControl('password');
				break;
		}
	}

	selectImage(): void {
		this.imageUpload.nativeElement.click();
	}

	openImageEditor(image: any) {
		const dialogRef = this.dialog.open(ImageEditorComponent, {
			height: '80%',
			width: '80%',
			data: {
				title: 'profile-image.headline',
				image,
			},
		});

		dialogRef.afterClosed().subscribe((imageResponse: string) => {
			if (!!imageResponse) {
				this.imageService.uploadUserImage(imageResponse, this.user.id);
			}
		});
	}

	resetPassword() {
		const dialogRef = this.approvalDialogHelper.openApprovalDialog(
			'Möchten Sie das Passwort des Benutzers wirklich zurücksetzen?',
		);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				try {
					this.userService.resetPassword(this.user.login).then((result) => {
						this.toastHelper.openSnackBar(
							'Passwort für Benutzer ' + this.user.login + ' zurückgesetzt.',
							'SUCCESS',
						);
					});
				} catch (e) {
					this.toastHelper.openCrudTextError(
						'Das Rücksetzen des Passwortes für Benutzer ' +
						this.user.login +
						' ist fehlgeschlagen!',
					);
				}
			}
		});
	}

	reinvite() {
		const dialogRef = this.approvalDialogHelper.openApprovalDialog(
			'Möchten Sie die Einladung des Benutzers wirklich erneut versenden?',
		);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				try {
					this.userService.reinvite(this.user.id).then((result) => {
						this.toastHelper.openSnackBar(
							'Einladungs eMail des Benutzers ' +
							this.user.login +
							' ist erneut versandt worden.',
							'SUCCESS',
						);
					});
				} catch (e) {
					this.toastHelper.openCrudTextError(
						'Das erneute versenden der Einladung ist fehlgeschlagen!',
					);
				}
			}
		});
	}

	lockUser() {
		const dialogRef = this.approvalDialogHelper.openApprovalDialog(
			'Möchten Sie den Benutzer wirklich sperren?',
		);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				try {
				} catch (e) {
					this.toastHelper.openCrudTextError(
						'Das erneute versenden der Einladung ist fehlgeschlagen!',
					);
				}
			}
		});
	}
}
