import { LatLng, LatLngBounds, Map } from 'leaflet';
import { GuiDetail } from 'src/app/state/dashboard/models/guiDetails';
import { LayerType } from 'src/app/state/dashboard/models/mapLayer';

export namespace MapActions {
  export class InitMapOptions {
    static readonly type = '[Map] Init Map Options';
    constructor(public context: GuiDetail) {}
  }
  export class SetMap {
    static readonly type = '[Map] Set Map';
    constructor(public map: Map) {}
  }
  export class SetView {
    static readonly type = '[Map] Set View';
    constructor(
      public center: LatLng,
      public zoom?: number,
      public offset: boolean = true,
    ) {}
  }
  export class FetchAvailableLayers {
    static readonly type = '[Map] Fetch Available Layers';
  }
  export class SetLayerVisibility {
    static readonly type = '[Map] Set Layer Visibility';
    constructor(
      public name: string,
      public visible: boolean,
    ) {}
  }
  export class SetLayerVisibilityByType {
    static readonly type = '[Map] Set Layer Visibility By Type';
    constructor(
      public type: LayerType,
      public visible: boolean,
    ) {}
  }
}
