import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {
  ColumnSeries,
  DateAxis,
  Legend,
  LineSeries,
  PieChart,
  PieSeries,
  ValueAxis,
  XYChart,
} from '@amcharts/amcharts4/charts';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
  hexToRgb,
  hslToRgb,
  rgbToHsl,
} from '@amcharts/amcharts4/.internal/core/utils/Colors';
import am4themes_microchart from '@amcharts/amcharts4/themes/microchart';
import { Sensor } from '../state/noysee/models/sensor';
import { first } from 'rxjs/operators';
import { ExportToCsv } from 'export-to-csv';
import { TranslatePipe } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SensorState } from '../state/noysee/sensor.state';
import { SensorBox, SensorBoxData } from '../state/noysee/models/sensorBox';
import * as am5 from '@amcharts/amcharts5';

export type Trend = 'RISING' | 'FALLING' | 'STAGNANT';

@Injectable({
  providedIn: 'root',
})
export class ChartHelper {
  trendThreshold: number;

  constructor(
    private store: Store,
    private translatePipe: TranslatePipe,
  ) {
    // Add amCharts 4 license
    am4core.addLicense('CH453772101');

    this.trendThreshold = 0.2;
  }

  createBarChart(
    data: any[],
    legend: { type: string; label: string }[],
    divName: string,
    category: string,
    baseColor: string,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet(baseColor, legend.length);

    legend.forEach(({ type, label }) => {
      this.setupColumnSeries(chart, type, label, category);
    });

    return chart;
  }

  createBarrierMovementsChart(
    data: any[],
    barriers: { type: string; label: string }[],
    divName: string,
    category: string,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet('#020280', barriers.length);

    barriers.forEach(({ type, label }) => {
      this.setupColumnSeries(chart, type, label, category);
    });

    return chart;
  }

  createHoldersCategoryChart(
    data: any[],
    holderCategories: { type: string; label: string }[],
    divName: string,
    category: string,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet(
      '#2c3a08',
      holderCategories.length,
    );

    holderCategories.forEach(({ type, label }) => {
      this.setupColumnSeries(chart, type, label, category);
    });

    return chart;
  }

  createAccessMediumChart(
    data: any[],
    accessMediumTypes: any[],
    divName: string,
    category: string,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet(
      '#2c3a08',
      accessMediumTypes.length,
    );

    accessMediumTypes.forEach((current) => {
      this.setupColumnSeries(chart, current.type, current.label, category);
    });

    return chart;
  }

  createWarningsChart(data: any[], divName: string, category: string): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet('#440008', 4);

    this.setupColumnSeries(chart, 'new_warnings', 'Neue Störungen', category);
    this.setupColumnSeries(chart, 'unresolved', 'Unbearbeitet', category);
    this.setupColumnSeries(chart, 'in_repair', 'In Bearbeitung', category);
    this.setupColumnSeries(chart, 'resolved', 'Behoben', category);

    return chart;
  }

  createWarningsCategoryChart(
    data: any[],
    divName: string,
    category: string,
  ): PieChart {
    const chart = this.setupBasicPieChart(data, divName);

    // bind chartData to axes
    this.setupPieSeries(chart, 'amount', category);

    return chart;
  }

  createAverageRepairTimeChart(
    data: any[],
    divName: string,
    category: string,
  ): XYChart {
    const chart = this.setupBasicBarChart(data, divName, category);

    chart.colors.list = this.generateColorSet('#440008', 4);

    // bind chartData to axes
    this.setupBarSeries(
      chart,
      'system_error_repair_average',
      'Anlagestörung',
      category,
    );
    this.setupBarSeries(
      chart,
      'lights_defective_repair_average',
      'Kopfbeleuchtung kaputt',
      category,
    );
    this.setupBarSeries(
      chart,
      'system_blocked_repair_average',
      'Schranke blockiert',
      category,
    );
    this.setupBarSeries(chart, 'other_repair_average', 'Sonstige', category);

    return chart;
  }

  createVehiclesOnPremiseChart(
    data: any[],
    divName: string,
    category: string,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(data, divName, category);

    chart.colors.list = this.generateColorSet('#664414', 3).reverse();

    const xAxis = chart.xAxes.getIndex(0);
    const series = this.setupLineSeries(chart, 'output', 'Ausfahrt', category);
    series.baseAxis = xAxis;
    series.strokeOpacity = 0.6;
    series.fillOpacity = 0.6;
    const series2 = this.setupLineSeries(chart, 'input', 'Einfahrt', category);
    series2.baseAxis = xAxis;
    series2.strokeOpacity = 0.6;
    series2.fillOpacity = 0.6;
    this.setupLineSeries(chart, 'current', 'Fahrzeugbilanz', category);

    return chart;
  }

  createBarrierNoiseChart(
    data: any[],
    barriers: { key: string; label: string }[],
    divName: string,
    category: string,
    axisLabel,
  ): XYChart {
    const chart = this.setupBasicCategoryValueChart(
      data,
      divName,
      category,
      axisLabel,
    );

    chart.colors.list = this.generateColorSet('#020280', 2).reverse();

    barriers
      .filter(({ key }) => key !== 'average')
      .forEach(({ key, label }) => {
        const series = this.setupLineSeries(chart, key, label, category);
        series.baseAxis = chart.xAxes.getIndex(0);
        series.strokeOpacity = 0.6;
        series.fillOpacity = 0.6;
      });

    const average = barriers.find(({ key }) => key === 'average');
    if (average) {
      this.setupLineSeries(chart, average.key, average.label, category);
    }
    return chart;
  }

  createSensorChart(
    data: any[],
    primarySensor: string,
    divName: string,
    inMapPopup: boolean,
    yMin?: number,
    yMax?: number,
    showLinreg: boolean = false,
  ): XYChart {
    this.roundValues(data, primarySensor);

    const chart = this.setupBasicDateValueChart(
      data,
      primarySensor,
      divName,
      inMapPopup,
      yMin,
      yMax,
      showLinreg,
    );

    // Draw recorded values
    this.setupLineSeriesNoysee(chart, primarySensor, '#061671');

    // Draw limits
    if (data.some((item) => item.limit1)) {
      this.setupLineSeriesNoysee(chart, 'limit1', '#f90');
    }
    if (data.some((item) => item.limit2)) {
      this.setupLineSeriesNoysee(chart, 'limit2', '#E2001A');
    }

    // Draw future
    if (data.some((item) => item.expected)) {
      // Add interpolated min/max values "around" actual values
      this.setupLineSeriesNoysee(chart, 'max', '#4eb9ef', false, true, 'min');
      this.setupLineSeriesNoysee(chart, 'min', '#4eb9ef', false, true, 'min');
      // Add future values
      this.setupLineSeriesNoysee(chart, 'expected', '#061671', true);
    }

    return chart;
  }

  private setupBasicDateValueChart(
    data: any[],
    primarySensor: string,
    divName: string,
    inMapPopup: boolean,
    yMin?: number,
    yMax?: number,
    linreg: boolean = false,
  ): XYChart {
    let chart;
    if (inMapPopup) {
      am4core.useTheme(am4themes_microchart);
      chart = am4core.create(divName, am4charts.XYChart);
      am4core.unuseTheme(am4themes_microchart);
    } else {
      chart = am4core.create(divName, am4charts.XYChart);
    }

    // Calculate maximum value
    const pegel = data.map(
      (current) => current[primarySensor] ?? current.expected,
    );
    const maxValue = Math.max(...pegel);
    const minValue = Math.min(...pegel);

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = false;
    chart.cursor.lineY.disabled = true;

    // Setup axes
    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.cursorTooltipEnabled = true;
    dateAxis.max = moment(data[data.length - 1].timestamp)
      .add(30, 'minute')
      .toDate();
    const valueAxis = chart.yAxes.push(new ValueAxis());
    const minMaxPadding = maxValue < 10 ? 2 : 10;
    // Set yMin default value if none was given
    if (!yMin) {
      yMin = 0;
    }
    // If chart contains value that is smaller than the given minimum, determine it from lowest value with padding
    if (minValue < yMin && minValue >= 0) {
      valueAxis.min =
        Math.min(minValue, data[0].limit1, data[0].limit2) - minMaxPadding;
    } else {
      valueAxis.min = yMin;
    }
    // If no yMax was given, determine it automatically from the largest value/limit with padding
    if (!yMax) {
      yMax = Math.max(maxValue, data[0].limit1, data[0].limit2) + minMaxPadding;
    }
    // Only add maximum to chart if maxValue is smaller than given maximum
    if (yMax && maxValue <= yMax) {
      valueAxis.max = yMax;
    }
    valueAxis.strictMinMax = true;
    valueAxis.cursorTooltipEnabled = false;

    // Add linreg if requested
    if (linreg) {
      const tempLinreg = this.calculateLinReg(data, primarySensor);
      const newData = data.map((item, index) => {
        item['linreg'] =
          typeof item[primarySensor] === 'number'
            ? tempLinreg.intercept + (index + 1) * tempLinreg.slope
            : undefined;
        return item;
      });
      this.setupLineSeriesNoysee(chart, 'linreg', '#000');
    }

    // Pass chartData to chart
    chart.data = data;

    return chart;
  }

  private setupBasicCategoryValueChart(
    data,
    divName,
    category,
    valueAxisTitle?: string,
  ): XYChart {
    const chart = am4core.create(divName, am4charts.XYChart);

    // Pass chartData to chart
    chart.data = data;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = valueAxisTitle;
    chart.legend = this.setupDefaultLegend('top');

    return chart;
  }

  private setupBasicPieChart(data, divName): PieChart {
    const chart = am4core.create(divName, am4charts.PieChart);
    chart.paddingTop = 24;

    // Pass chartData to chart
    chart.data = data;

    chart.legend = this.setupDefaultLegend('absolute');
    chart.legend.x = 0;
    chart.legend.y = 0;
    chart.legend.parent = chart.tooltipContainer;
    chart.legend.width = 1000;
    chart.legend.valueLabels.template.text = '';

    return chart;
  }

  private setupBasicBarChart(data, divName, category): XYChart {
    const chart = am4core.create(divName, am4charts.XYChart);

    // Pass chartData to chart
    chart.reverseOrder = true;
    chart.data = data;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    return chart;
  }

  private setupDefaultLegend(
    position: 'top' | 'bottom' | 'left' | 'right' | 'absolute',
  ): Legend {
    const legend = new am4charts.Legend();

    legend.position = position;
    legend.contentAlign = 'left';
    legend.labels.template.fontSize = 14;
    legend.useDefaultMarker = true;
    legend.markers.template.width = 20;
    legend.markers.template.height = 10;
    legend.marginBottom = 20;

    return legend;
  }

  setupLineSeriesNoysee(
    chart: XYChart,
    dataField: string,
    color: string,
    dashed: boolean = false,
    interpolation: boolean = false,
    interpolationField: string = '',
  ) {
    const series = chart.series.push(new LineSeries());

    series.dataFields.dateX = 'timestamp';
    series.dataFields.valueY = dataField;
    series.strokeWidth = 2;
    series.stroke = am4core.color(color);
    series.fill = series.stroke;
    series.tooltipText = '[bold]{valueY}[/]';
    if (dashed) {
      series.strokeDasharray = '6,3';
    }
    if (interpolation) {
      series.strokeWidth = 1.5;
      series.dataFields.openValueY = interpolationField;
      series.sequencedInterpolation = true;
      series.fillOpacity = 0.3;
      series.strokeOpacity = 0.6;
    }
  }

  private setupLineSeries(
    chart: any,
    dataField: string,
    name: string,
    category: string,
  ): LineSeries {
    const series = chart.series.push(new LineSeries());

    series.name = name;
    series.dataFields.categoryX = category;
    series.dataFields.valueY = dataField;
    series.strokeWidth = 2;
    series.tooltipText = '[bold]{valueY}[/]';
    series.tensionX = 0.7;

    return series;
  }

  private setupColumnSeries(
    chart: any,
    dataField: string,
    name: string,
    category: string,
  ): ColumnSeries {
    // Set up series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = dataField;
    series.dataFields.categoryX = category;
    series.sequencedInterpolation = true;
    series.cursorTooltipEnabled = false;

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.maxWidth = 20;
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText =
      '[bold]{name}[/]\n[font-size:14px]{valueY}';

    return series;
  }

  private setupPieSeries(
    chart: any,
    dataField: string,
    category: string,
  ): PieSeries {
    // Set up series
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = dataField;
    series.dataFields.category = category;

    // Configure slices
    series.ticks.template.disabled = true;
    series.slices.template.tooltipText = '';

    series.alignLabels = false;
    series.labels.template.text = '{value}';
    series.labels.template.fontWeight = 'bold';
    series.labels.template.radius = am4core.percent(-40);
    series.labels.template.fill = am4core.color('white');

    // Configure colors
    series.colors.list = this.generateColorSet('#440008', 4);

    return series;
  }

  private setupBarSeries(
    chart: any,
    dataField: string,
    name: string,
    category: string,
  ): ColumnSeries {
    // Setup series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = name;

    series.dataFields.categoryY = category;
    series.dataFields.valueX = dataField;
    series.sequencedInterpolation = true;
    series.cursorTooltipEnabled = false;

    // Configure bars
    series.columns.template.height = 20;
    series.columns.template.tooltipText = '';

    return series;
  }

  /**
   * Returns a color set with a base color and colorSetSize - 1 lighter shades of the base color
   *
   * @param baseColor - Hex-Code of the base color to be use
   * @param colorSetSize - Amount of colors to be included in the set (including base color)
   */
  private generateColorSet(baseColor: string, colorSetSize: number): any[] {
    const rgb = hexToRgb(baseColor);
    const set = new Array(colorSetSize);
    set[0] = am4core.color(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);

    // Transform rgb color to HSL color space to easily change the lightness of the color
    const hsl = rgbToHsl(rgb);
    let step = (1 - hsl.l) / (colorSetSize - 1);
    step = step - 0.2 * step;

    for (let i = 1; i < colorSetSize; i++) {
      hsl.l += step;
      set[i] = am4core.color(hslToRgb(hsl));
    }

    return set;
  }

  disposeChart(chart: am4charts.Chart) {
    chart?.dispose();
  }

  updateChart(
    chart: am4charts.Chart,
    data: any[],
    selectedSensor: string,
    divName: string,
  ): [am4charts.Chart, Trend] {
    this.disposeChart(chart);

    if (selectedSensor && data.length > 0) {
      const sensorBox = this.store.selectSnapshot(SensorState.currentSensor);
      const yMin = sensorBox.sensors[selectedSensor]?.displayMinY;
      const yMax = sensorBox.sensors[selectedSensor]?.displayMaxY;

      return [
        this.createSensorChart(
          data,
          selectedSensor,
          divName,
          false,
          yMin,
          yMax,
        ),
        this.getTrend(this.calculateLinReg(data, selectedSensor)),
      ];
    }
    return [null, 'STAGNANT'];
  }

  roundValues(data, primarySensor: string): any {
    return data.map(function (val) {
      if (val[primarySensor] !== undefined) {
        val[primarySensor] = Math.round(val[primarySensor] * 100) / 100;
      }
    });
  }

  collectSensorData(
    sensor: SensorBoxData,
    sensorType: string,
    delta: number = 0,
  ): any[] {
    if (sensor?.history) {
      const history = sensor.history
        .map((item) => {
          if (
            typeof item[sensorType] === 'number' &&
            moment().diff(moment(item.timestamp), 'days') <= Math.abs(delta)
          ) {
            return {
              timestamp: item.timestamp,
              [sensorType]: item[sensorType],
              expected: undefined,
              min: undefined,
              max: undefined,
              limit1: sensor.sensors[sensorType]?.limit1,
              limit2: sensor.sensors[sensorType]?.limit2,
            };
          }
        })
        .filter((item) => !!item);

      if (sensor?.future && sensor.future.length > 0) {
        return history.concat(
          sensor.future
            .map((item) => {
              if (typeof item[sensorType]?.expected === 'number') {
                return {
                  timestamp: item.timestamp,
                  [sensorType]: undefined,
                  expected: item[sensorType]?.expected,
                  min: item[sensorType]?.min,
                  max: item[sensorType]?.max,
                  limit1: sensor.sensors[sensorType]?.limit1,
                  limit2: sensor.sensors[sensorType]?.limit2,
                };
              }
            })
            .filter((item) => !!item),
        );
      }
      return history;
    }
    return [];
  }

  exportToCsv(sensor: SensorBox, selectedSensor: string, data: any[]) {
    const sensorTypeLabel = this.translatePipe.transform(
      'sensor.type.' + selectedSensor,
    );
    const exporter = new ExportToCsv({
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: ',',
      showLabels: true,
      showTitle: true,
      title: sensor.name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename:
        moment().format('YYYY_MM_DD') +
        '_' +
        sensor.name.replace(/[^a-zA-Z0-9ÄÖÜäöüß_-]/g, '_') +
        '_' +
        sensorTypeLabel, // Remove special characters from sensor name before saving
    });
    const unit = sensor.sensors[selectedSensor].unit;
    const updatedValues = data.map((value) => {
      return {
        Datum: moment(value.timestamp).format('DD.MM.YYYY HH:mm:ss'),
        [sensorTypeLabel + ' (' + unit + ')']:
          typeof value.value === 'number'
            ? Math.round(value.value * 100) / 100
            : '',
        ['Limit 1 (' + unit + ')']: value.limit1,
        ['Limit 2 (' + unit + ')']: value.limit2,
        ['Minimum (' + unit + ')']: value.min ?? '',
        ['Beste Schätzung (' + unit + ')']: value.expected ?? '',
        ['Maximum (' + unit + ')']: value.max ?? '',
      };
    });
    exporter.generateCsv(updatedValues);
  }

  getTrend(linreg): Trend {
    let trend;
    if (linreg.slope > this.trendThreshold) {
      trend = 'RISING';
    } else if (linreg.slope < -1 * this.trendThreshold) {
      trend = 'FALLING';
    } else {
      trend = 'STAGNANT';
    }

    return trend;
  }

  // Calculates linear regression through the values of a given field
  calculateLinReg(data: any[], field: string) {
    const sums = {
      x: 0,
      y: 0,
      xy: 0,
      xx: 0,
      yy: 0,
    };

    let noValueCount = 0;
    data
      .filter((item) => {
        if (!(typeof item[field] === 'number')) {
          noValueCount++;
          return false;
        }
        return true;
      })
      .forEach((item, index) => {
        sums.x += index + 1;
        sums.y += item[field];
        sums.xy += item[field] * (index + 1);
        sums.xx += (index + 1) * (index + 1);
        sums.yy += item[field] * item[field];
      });

    const length = data.length - noValueCount;
    const slope =
      (length * sums.xy - sums.x * sums.y) /
      (length * sums.xx - sums.x * sums.x);
    const intercept = (sums.y - slope * sums.x) / length;

    return {
      slope: slope,
      intercept: intercept,
    };
  }
}
