import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserType } from '../models/user';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notification.service';
import { WebsocketService } from './websocket.service';
import { UserListResult, UserResult } from '../models/apiResults';
import { Topic, WebsocketMessage } from '../models/websocketMessageData';
import { first, map } from 'rxjs/operators';
import { GuiState } from '../state/dashboard/gui.state';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private _usersSource: any = new BehaviorSubject([]);
  private _users: User[];

  constructor(
    private http: HttpClient,
    private store: Store,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private websocketHelper: WebsocketService,
  ) {
    this._users = [];

    this._usersSource.asObservable().subscribe((data) => {
      this._users = data;
    });

    // Setup websocket handlers
    this.websocketHelper.dashboardWebSocket.subscribe((socket) => {
      if (!socket) {
        this.users = [];
        return;
      }

      socket.addEventListener('open', () => {
        if (this.authenticationService.isAdmin()) {
          this.getAll();
        }
      });
      // Always register websocket
      socket.addEventListener('message', (msg) => {
        const message = new WebsocketMessage(msg.data);
        if (message.topic === Topic.USER_CHANGED) {
          if (this.authenticationService.isAdmin()) {
            this.getAll();
          }
        }
      });
    });
  }

  private set users(data: User[]) {
    this._usersSource.next(data);
  }

  get usersObservable(): Observable<User[]> {
    return this._usersSource.asObservable();
  }

  changeFocusCustomer() {
    if (this.authenticationService.isAdmin()) {
      this.getAll();
    }
  }

  private getAll() {
    this.store
      .select(GuiState.selectedCustomer)
      .pipe(first((customer) => !!customer))
      .subscribe((customer) => {
        this.http
          .get<UserListResult>(
            `${environment.identityManagerApiUrl}/user/list?customerId=${customer.id}`,
          )
          .subscribe((result) => {
            if (result?.ok) {
              this.users = result.data as User[];
            }
          });
      });
  }

  getByToken(token: string): Observable<User> {
    return this.http
      .get<UserResult>(
        `${environment.identityManagerApiUrl}/user/token/${token}`,
      )
      .pipe(
        map((result: UserResult) => {
          if (result?.ok) {
            return result.data;
          }
        }),
      );
  }

  getById(id: number): Observable<User> {
    return this.http
      .get<UserResult>(`${environment.identityManagerApiUrl}/user/${id}`)
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      );
  }

  getNameById(id: number): string {
    const user = this._users.find((current) => current.id === id);
    return user?.name || '';
  }

  async createUser(user, password): Promise<User> {
    const newUser = await this.http
      .post<UserResult>(`${environment.identityManagerApiUrl}/user/create`, {
        user,
        password,
      })
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    this.notificationService.updateOpenNotifications();
    return newUser;
  }

  async deleteUser(id: number): Promise<User> {
    const deletedUser = await this.http
      .delete<UserResult>(
        `${environment.identityManagerApiUrl}/user/detail?id=${id}`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    this.notificationService.updateOpenNotifications();
    return deletedUser;
  }

  async updateUser(user: User, updateSelf: boolean = false): Promise<User> {
    const newUser = await this.http
      .put<UserResult>(
        `${environment.identityManagerApiUrl}/user/${user.id}`,
        user,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    if (updateSelf) {
      this.authenticationService.persistAuthenticatedUser({
        ok: true,
        message: '',
        jwt: this.authenticationService.authToken,
        sessionUser: user,
        data: user,
      });
    }
    this.notificationService.updateOpenNotifications();
    return newUser;
  }

  getList( userType: UserType = null): Promise<User[]> {
    return this.http
      .get<UserListResult>(`${environment.identityManagerApiUrl}/user/list`+(userType ? '?userType='+userType : ''))
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  activateUser(user: User): Promise<User> {
    return this.http
      .post<UserResult>(
        `${environment.identityManagerApiUrl}/user/activate`,
        user,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  async saveApiKey(apiKey: User): Promise<User> {
    const savedApiKey = this.http
      .put<UserResult>(`${environment.dashboardApiUrl}/apiKey/detail`, apiKey)
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    this.notificationService.updateOpenNotifications();
    return savedApiKey;
  }

  async reinvite(
    id: number,
  ): Promise<User> {
    const user = this.http
      .get<UserResult>(`${environment.identityManagerApiUrl}/user/reinvite?id=`+id)
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    return user;
  }

  async resetPassword(
    login: string,
  ): Promise<User> {
    const user = this.http
      .get<UserResult>(`${environment.identityManagerApiUrl}/user/reset?login=`+login)
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    return user;
  }


  async createApiKey(
    customerId: number,
    name: string,
    role: string,
  ): Promise<User> {
    const newApiKey = this.http
      .post<UserResult>(`${environment.dashboardApiUrl}/apiKey/create`, {
        customerId,
        name,
        role,
      })
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
    this.notificationService.updateOpenNotifications();
    return newApiKey;
  }
}
