<div *ngIf="currentSensor" class="container-fluid sensor-information-container">
  <div class="information-header">
    <h1 id="sensorName">{{ currentSensor.name }}</h1>
  </div>
  <div *ngIf="currentSensor" class="information-sub-header">
    <div class="sub-header-col">
      <p *ngIf="showSensorId">
        <strong appTranslate="sensor.information.id"></strong>:
        {{ currentSensor.id }}
      </p>
    </div>
    <div class="last-timestamp-col">
      <p>
        <strong appTranslate="sensor.information.last-value"></strong>:
        {{
          currentSensor.sensors[currentSensor.primarySensor]
            ? (currentSensor.sensors[currentSensor.primarySensor]
                .currentDataTimestamp | date: "dd.MM.yyyy HH:mm")
            : ("sensor.information.non-existent" | translate)
        }}
      </p>
    </div>
    <div class="data-source-col">
      <p>
        <strong appTranslate="sensor.information.source"></strong>:
        {{ currentSensor.datasource }}
      </p>
    </div>
  </div>
  <app-legacy-weather
    *ngIf="false"
    [isWeatherStation]="isWeatherStation"
  ></app-legacy-weather>
  <app-weather
    *ngIf="true"
    [isWeatherStation$]="isWeatherStation$"
    [lat]="currentSensor.lat"
    [lng]="currentSensor.lon"
  ></app-weather>
</div>
