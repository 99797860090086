export const environment = {
  production: false,
  // baseUrl: '.',
  dashboardApiHost: '',
  identityManagerApiUrl: '/api/v2',
  dashboardApiUrl: '/api/v1',
  dashboardWebSocketUrl: '',
  appTitle: 'NOYSEE - Dashboard - DEV',
  faviconUrl: 'assets/img/favicon_netzebw.ico',
  logoUrl: 'assets/img/netzebw-logo.jpg', // Noysee
  customerLogoUrl: 'assets/img/logo_ka.svg',
  avatarPlaceholderUrl: 'assets/img/avatar-placeholder.png',
  licensedFeatures: {
    mapMarkerDiameter: 30,
    publicAccess: false
  },
  guiDefaults: {
    mapCoordinates: {
      lat: 49.0253,
      lng: 8.3485
    }
  },
  support: {
    email: 'noysee@netze-bw.de',
    phone: '+49 711 289 88880'
  },
};
