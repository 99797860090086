import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'no-rows-overlay',
  template: `{{ 'table.overlay.noRows' | translate }}`,
})
export class NoRowsOverlay implements INoRowsOverlayAngularComp {
  public params: any;

  agInit(params): void {
    this.params = params;
  }
}
