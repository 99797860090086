import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import { ExportFile, ExportFileStation } from '../../../../../models/exportFile';
import { LookupService } from '../../../../../services/lookup.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ExportFileService } from 'src/app/services/exportFile.service';
import { DataTableOptions, GridState, TableActionType, TitleSize } from 'src/app/shared/data-table/data-table.model';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-exportFiles-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ExportFileDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  exportFile: ExportFile;
  submitted: boolean;

  crudForm: FormGroup;

  templateList: LookupEntry[];
  destinationList: LookupEntry[];

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  dataTableOptions: DataTableOptions<any>;
  list: ExportFile[];
  gridState: GridState = null;
  exportFileStations: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private matDialog: MatDialog,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private exportFileService: ExportFileService,
    private lookupService: LookupService) {

    this.exportFile = new ExportFile();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      description: new FormControl('', []),
      exportDestinationId: new FormControl('', []),
      exportTemplateId: new FormControl('', []),
      fileNameTemplate: new FormControl('', [])
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';

  }

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadExportFile();
    } else {
      this.exportFile.active = true;
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadExportFile();
      });

    this.lookupService.getExportTemplateList().then(result => {
      this.templateList = result;
    });

    this.lookupService.getDestinationList().then(result => {
      this.destinationList = result;
    });

    this.dataTableOptions = {
      title: 'exportFileStation.headerLabel',
      titleSize: TitleSize.H2,
      filter: true,
      tableActions: [],
      rowActions: {
        type: TableActionType.MENU,
        actionList: []
      },
      columnDef: [{
        key: 'columnName',
        displayName: 'exportFileStation.table.columns.columnName',
        width: '5%',
        sortable: true,
      },{
        key: 'externalId',
        displayName: 'exportFileStation.table.columns.externalId',
        width: '5%',
        sortable: true,
      },{
        key: 'externalName',
        displayName: 'exportFileStation.table.columns.externalName',
        width: '15%',
        sortable: true,
      },{
        key: 'externalWaters',
        displayName: 'exportFileStation.table.columns.externalWaters',
        width: '15%',
        sortable: true,
      },{
        key: 'sensorId',
        displayName: 'exportFileStation.table.columns.sensorId',
        width: '5%',
        sortable: true,
      }, {
        key: 'lastValue',
        displayName: 'exportFileStation.table.columns.lastValue',
        width: '8%',
        sortable: true,
        valueTransform: (station: ExportFileStation) => formatNumber(station.lastValue, 'de_DE', '1.1-1') + ' ' + station.unit,
      }]
    };

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {

    this.crudForm.patchValue({
      name: this.exportFile.name,
      active: this.exportFile.active,
      description: this.exportFile.description,
      exportDestinationId: this.exportFile.destination?.id,
      exportTemplateId: this.exportFile.template?.id,
    });

    console.debug(Object.entries(this.exportFile.columnMap));
    this.exportFileStations = Object.entries(this.exportFile.columnMap).map(([key, value]) => {
      return {
        columnName: key,
        externalId: value.externalId,
        externalName: value.externalName,
        externalWaters: value.externalWaters,
        unit: value.unit,
        lastContact: value.lastContact,
        lastValue: value.lastValue,
        sensorId: value.sensorId
      }
    });
    console.debug(this.exportFileStations);
  }

  loadExportFile() {
    const url = this.route.snapshot.params;

    this.exportFileService.load(url.id).then(data => {
      this.exportFile = data;
      this.dataToForm();
    });

  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    const _this = this;
    _this.exportFile.active = !_this.exportFile.active;
    _this.crudForm.patchValue({
      active: _this.exportFile.active,
    });
  }

  async saveExportFile() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.exportFile.name = this.f.name.value;
    this.exportFile.active = this.f.active.value;
    this.exportFile.description = this.f.description.value;

    this.exportFile.template = { id: this.f.exportTemplateId.value, name: '' };
    this.exportFile.destination = { id: this.f.exportDestinationId.value, name: '' };

    try {
      this.exportFileService.save(this.exportFile).then(result => {
        this.exportFile = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('Vorlage gespeichert!', 'SUCCESS');
        this.goBack();
      }).catch(error => {
        this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
      });

    } catch (e) {
      this.toastHelper.openSnackBar('Fehler beim Speichern der Vorlage!', 'ERROR');
    }
  }

  deleteExportFile() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie diesen Export-Job wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.exportFileService.delete(this.exportFile.id).then(result => {
            this.goBack();
            this.toastHelper.openSnackBar('Export-Job gelöscht!', 'SUCCESS');
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'exportFiles', 'overview']);
  }

  exportFileTypes(): { label: string, value: string }[] {
    return [];
  }

}
