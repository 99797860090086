import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Prevent interceptor from interfering with unprotected routes
    const isApiUrl = (request.url.startsWith(environment.identityManagerApiUrl) && !request.url.includes('sso'))
      || request.url.startsWith(environment.dashboardApiUrl);

    // DEBUG
    // if (request.url.startsWith(environment.noyseeHandlerApiUrl)) {
    //   console.log('authUser: ', authenticatedUser);
    //   console.log('login status: ', isLoggedIn);
    //   console.log('requestUrl: ', request.url);
    //   console.log('noyseeApiUrl: ', environment.noyseeHandlerApiUrl);
    // }

    // add auth header with jwt if user is logged in and request is to api urls
    if (this.authenticationService.authToken && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.authToken}`
        }
      });
    }

    return next.handle(request);
  }
}
