import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { AlarmGroupService } from '../../../../../state/noysee/alarmGroup.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { AlarmRuleService } from 'src/app/state/noysee/alarmRule.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-addSensorBoxAlarmGroup',
  templateUrl: './addAlarmGroup.component.html',
  styleUrls: ['./addAlarmGroup.component.scss'],
})
export class AddSensorBoxAlarmGroupComponent implements OnInit {
  parentId: number;
  parentName: string;
  alarmLevel: number;
  alarmGroupId: number;

  parentType: string;

  alarmGroupList: LookupEntry[];

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private noyseeService: NoyseeService,
    private alarmGroupService: AlarmGroupService,
    private translatePipe: TranslatePipe,
    private alarmRuleService: AlarmRuleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddSensorBoxAlarmGroupComponent>
  ) {
    this.isSuperAdmin = false;
    this.isAdmin = false;
  }

  ngOnInit(): void {
    this.parentId = this.data?.id;
    this.parentName = this.data?.name;
    this.parentType = this.data?.parentType;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.alarmGroupService.getList().then((result) => {
      this.alarmGroupList = result;
    });
  }

  addAlarmGroup(): void {
    const _this = this;

    if (
      _this.parentId == null ||
      _this.alarmLevel == null ||
      _this.alarmGroupId == null
    ) {
      return;
    }

    if (this.parentType === 'sensorBox') {
      _this.noyseeService
        .addAlarmGroup(_this.parentId, _this.alarmLevel, _this.alarmGroupId)
        .toPromise()
        .then((data) => {
          _this.toastHelper.openSnackBar(
            this.translatePipe.transform('alarmGroup.dialog.events.add'),
            'SUCCESS'
          );
          this.dialogRef.close();
        });
    } else if (this.parentType === 'alarmRule') {
      // TODO

      _this.alarmRuleService
        .addAlarmGroup(_this.parentId, _this.alarmLevel, _this.alarmGroupId)
        .then((data) => {
          _this.toastHelper.openSnackBar(
            this.translatePipe.transform('alarmGroup.dialog.events.add'),
            'SUCCESS'
          );
          this.dialogRef.close();
        });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
