import { LookupEntry, ApiResult } from './apiResults';

export enum DeliveryState {
  CANCELED = -100,
  SINGLE_BILL = -1,
  TO_BILL = 0,
  REPEATED_BILLING = 1,
}

export enum BillingMethod {
  MAuE = 'MAuE',
  NOYSEE = 'NOYSEE',
  NONE = 'NONE',
  MANUAL ='MANUAL',
}

export enum BillingState {
  OPEN = 'OPEN',
  BILLED ='BILLED',
  PAYED = 'PAYED',
  CLOSED = 'CLOSED',
}

export class Product {

  id: number;

  editable: boolean = true;

  dmat: string;
  name: string;
  description: string;
  billingInterval: number;
  price: number;
  amount: number;
  contractPeriodBase: number;
  contractPeriodExtension: number;
  noticePeriod: number;

  delivery: Date;

  contractStart: Date;
  contractEnd: Date;
  lastBilling: Date;

  sensorBox: LookupEntry;
  customer: LookupEntry;
  product: Product;

  trackingNumber: string;

  state: DeliveryState

  maueOrderNumber?: string;
  maueInventoryNumber?: string;
  maueDeviceModel? : string;
  mappingError?: string;

  billingMethod: BillingMethod;
  billingState: BillingState;

  isMAuE(): boolean {
    console.debug( this.billingMethod+' '+BillingMethod.MAuE);

    return this.billingMethod==BillingMethod.MAuE || this.billingMethod==BillingMethod.NOYSEE;
  }
}

export type ProductResult = ApiResult<Product>;

