import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';
import { GuiActions } from 'src/app/state/dashboard/gui.action';

@Component({
  selector: 'app-primary-panel',
  templateUrl: './primary-panel.component.html',
  styleUrls: ['./primary-panel.component.scss'],
})
export class PrimaryPanelComponent implements OnInit {
  panelActive$ = this.store.select(GuiState.primaryPanelOpen);
  showMenu$ = this.store
    .select(GuiState.showMenu)
    .pipe(tap(() => this.changeDetector.detectChanges()));
  headline: string;

  constructor(
    private router: Router,
    private store: Store,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Set headline on load
    this.updateHeadline(this.router.url);

    // Update headline on navigation
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) =>
          this.updateHeadline((event as NavigationEnd).urlAfterRedirects),
        ),
      )
      .subscribe();
  }

  updateHeadline(path: string) {
    const pathSegments = path.split('/');
    // Routes are plural, while localization labels are singular, therefore remove the last s
    this.headline =
      (pathSegments[2]
        ? pathSegments[2].substring(0, pathSegments[2].length - 1)
        : 'notification') + '.headerLabel';
  }

  togglePanel(active) {
    this.store.dispatch(new GuiActions.SetPrimaryPanelVisibility(active));
  }
}
