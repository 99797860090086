import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ApiKeyGuard} from './guards/api-key.guard';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./view/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'micro',
    canActivate: [ApiKeyGuard],
    loadChildren: () =>
      import('./view/microsites/microsites.module').then(mod => mod.MicrositesModule)
  },
  {
    path: 'static',
    loadChildren: () =>
      import('./view/static/static.module').then(mod => mod.StaticModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./view/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'static'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
