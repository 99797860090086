import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { debug } from 'console';

@Component({
  selector: 'app-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss'],
})
export class TileListComponent implements OnChanges, OnDestroy {
  @Output()
  addItem: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled = false;

  @Input()
  value: any[];

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  keyField: string;

  @Input()
  descriptiveField: any;

  onChange: (value: any) => {};
  onBlur: (value: any) => {};
  destroy$: Subject<void> = new Subject();

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Optional() @Self() private _elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.value = [
      { ags: 'ags1', name: 'name1 ' },
      { ags: 'ags2', name: 'name2 ' },
    ];
    console.debug(this.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('disabled' in changes) {
      this.toggleDisable();
    }
  }

  toggleDisable() {
    if (this.disabled !== false) {
    } else {
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  removeItem(itemToDelete: any) {
    this.value = this.value.filter(
      (item) => item[this.keyField] != itemToDelete[this.keyField],
    );
    this.valueChange.emit(this.value);
    this.change.emit();
  }

  addButtonPressed() {
    this.addItem.emit();
  }
}
