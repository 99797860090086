<div class="primary-panel-extra-large container-fluid">
  <div class="row">
    <div class="col-6">
      <h1>Warnung</h1>
    </div>
    <div class="col-6  button-col">
      <button mat-menu-item (click)="this.cancelWarning()">
        <span>Beenden...</span>
      </button>
      &nbsp;
      <button mat-menu-item (click)="this.deleteWarning()">
        <span>Löschen...</span>
      </button>
    </div>
  </div>
  <form class="form">


    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-name">Quelle</label>
          <input placeholder="" id="input-name" [value]="this.warningDetail.warningSource.name" readonly="true">
        </div>
      </div>
      <div class="col-8">
        <div class="form-field">
          <label for="input-remark"><span>Identifier</span></label>
          <input placeholder="" id="input-remark" [value]="warningDetail.identifier" readonly="true">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-createTimestamp">Create</label>
          <input placeholder="" id="input-createTimestamp" [value]="getDateString(warningDetail.createTimestamp)"
            readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-lastUpdateTimestamp">Update</label>
          <input placeholder="" id="input-lastUpdateTimestamp"
            [value]="getDateString(warningDetail.lastUpdateTimestamp)" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-cancelTimestamp">Cancel</label>
          <input placeholder="" id="input-cancelTimestamp" [value]="getDateString(warningDetail.cancelTimestamp)"
            readonly="true">
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-msgType">msgType</label>
          <input placeholder="" id="input-msgType" [value]="warningDetail.msgType" readonly="true">
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-responseType">responseType</label>
          <input placeholder="" id="input-responseType" [value]="warningDetail.responseType" readonly="true">
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field">
          <label for="input-code">Code</label>
          <input placeholder="" id="input-code" [value]="warningDetail.code" readonly="true">
        </div>
      </div>
      <div class="col-1">
        <div class="form-field">
          <label for="input-code1">Medien Regional</label>
          <label class="switch">
            <input type="checkbox" readonly="true" id="input-code1" [checked]="warningDetail.recipientMediaRegional" disabled>
            <span class="slider round disabled"></span>
          </label>
        </div>
      </div>
      <div class="col-1">
        <div class="form-field">
          <label for="input-code">Medien Überregional</label>
          <label class="switch">
            <input type="checkbox" readonly="true" id="input-code1" [checked]="warningDetail.recipientMediaCrossRegional" disabled>
            <span class="slider round disabled"></span>
          </label>
        </div>
      </div>
      <div class="col-1">
        <div class="form-field">
          <label for="input-code">NINA<br>&nbsp;</label>
          <label class="switch">
            <input type="checkbox" readonly="true" id="input-code1" [checked]="warningDetail.recipientNina" disabled>
            <span class="slider round disabled"></span>
          </label>
        </div>
      </div>
      <div class="col-1">
        <div class="form-field">
          <label for="input-code">Sirenen<br>&nbsp;</label>
          <label class="switch">
            <input type="checkbox" readonly="true" id="input-code1" [checked]="warningDetail.recipientSirene" disabled>
            <span class="slider round disabled"></span>
          </label>
        </div>
      </div>
      <div class="col-1">
        <div class="form-field">
          <label for="input-code">Warnmittel<br>&nbsp;</label>
          <label class="switch">
            <input type="checkbox" readonly="true" id="input-code1" [checked]="warningDetail.recipientWarningDevices"  disabled>
            <span class="slider round disabled"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-urgency">Dringlichkeit</label>
          <input placeholder="" id="input-urgency" [value]="this.warningDetail.urgency" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-severity">Auswirkung</label>
          <input placeholder="" id="input-severity" [value]="warningDetail.severity" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-certainty">Wahrscheinlichkeit</label>
          <input placeholder="" id="input-certainty" [value]="warningDetail.certainty" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-effective">Gültig ab</label>
          <input placeholder="" id="input-effective" [value]="getDateString(warningDetail.effective)" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-onset">On Set</label>
          <input placeholder="" id="input-onset" [value]="getDateString(warningDetail.onset)" readonly="true">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-expires">Gültig bis</label>
          <input placeholder="" id="input-expires" [value]="getDateString(warningDetail.expires)" readonly="true">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-headline"><span>Überschrift</span></label>
          <input placeholder="" id="input-headline" [value]="warningDetail.headline" readonly="true">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-description"><span>Inhalt</span></label>
          <div id="input-description" class="textarea">{{warningDetail.description}}</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-instruction"><span>Anweisung</span></label>
          <div id="input-description" class="textarea">{{warningDetail.instruction}}</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-web"><span>Web</span></label>
          <input placeholder="" id="input-web" [value]="warningDetail.web" readonly="true">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-contact"><span>Kontakt</span></label>
          <input placeholder="" id="input-contact" [value]="warningDetail.contact" readonly="true">
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()">Zurück</button>
      </div>
    </div>
  </form>
</div>