import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UnifiedWeather, WeatherData } from '../../models/kachelmann';
import moment from 'moment';
import { first, map, shareReplay, tap } from 'rxjs/operators';

const KACHELMANN_API: string = `${environment.dashboardApiUrl}/weather`;

@Injectable({
  providedIn: 'root',
})
export class KachelmannService {
  private httpClient = inject(HttpClient);

  weather(lat: number, lon: number, days: number = 4): Observable<WeatherData> {
    return this.httpClient
      .get<UnifiedWeather>(
        `${KACHELMANN_API}/unified/${lat}/${lon}?days=${days}`,
      )
      .pipe(
        first((result) => result.ok),
        map((result) => result.data!),
        shareReplay(1),
      );
  }

  getWeatherSymbolUrl(symbol: string, isNight: boolean): string {
    return `${KACHELMANN_API}/kachelmann/weatherSymbol/${symbol}${
      isNight ? '_night' : ''
    }`;
  }

  static getRelativeTileLayerUrl(offset: number = 0): string {
    return `${KACHELMANN_API}/kachelmann/rainRadar/rel/${offset}/tile/{z}/{x}/{y}`;
  }

  static getAbsoluteTileLayerUrl(date: Date = new Date()): string {
    return `${KACHELMANN_API}/kachelmann/rainRadar/abs/${moment(date).format(
      'YYYY/MM/DD/HHmm',
    )}/tile/{z}/{x}/{y}`;
  }
}
