import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Legend, percent, Root, Tooltip } from '@amcharts/amcharts5';
import {
  AxisRenderer,
  AxisRendererX,
  AxisRendererY,
  ColumnSeries,
  DateAxis,
  LineSeries,
  ValueAxis,
  XYChart,
  XYChartScrollbar,
  XYCursor,
  XYSeries,
} from '@amcharts/amcharts5/xy';
import { generateColumnSeries, generateLineSeries } from '../util';
import { CHART_TYPE } from '../../../state/noysee/models/sensorBox';
import { range } from 'lodash';

export type LegendDataItem = {
  name: string;
  color: string;
};

type ChartSeries = {
  values: LineSeries | ColumnSeries;
  secondaryValues: LineSeries | ColumnSeries;
  limit1: LineSeries;
  limit2: LineSeries;
  expectedValue: LineSeries;
  expectedSecondaryValue: ColumnSeries;
  futureMin: LineSeries;
  futureMax: LineSeries;
};

export interface ChartReadyEvent {
  root: Root;
  chart: XYChart;
  ref: string;
  sensorName: string;
  cursor: XYCursor;
  axes: {
    xAxis: DateAxis<AxisRenderer>;
    yAxis: ValueAxis<AxisRenderer>;
  };
  series: ChartSeries;
}

export interface DateValueChartDataPoint {
  timestamp: number;
  value: number; // Value of selected sensor type (e.g. wasserpegel)
  secondaryValue?: number; // Value of secondary sensor type (e.g. wassertemperatur)
  unit: string; // Unit of value
  limit1?: number; // Used to display limit line if set
  limit2?: number; // Used to display limit line if set
  expectedValue?: number; // Expected value of forecast
  expectedSecondaryValue?: number; // Expected value of forecast
  futureMin?: number; // Used when displaying future values
  futureMax?: number; // Used when displaying future values
}

@Component({
  selector: 'app-date-value-chart',
  templateUrl: './date-value-chart.component.html',
  styleUrls: ['./date-value-chart.component.scss'],
})
export class DateValueChartComponent implements AfterViewInit, OnDestroy {
  _displayMinY?: number;
  @Input() set displayMinY(value: number) {
    // Set internal value
    this._displayMinY = value;
    // Only update if chart is already initialized
    if (this.chart?.yAxes.getIndex(0)) {
      console.log('Updating displayMinY', value, this.chart.yAxes.getIndex(0));
      (this.chart.yAxes.getIndex(0) as ValueAxis<AxisRenderer>).set(
        'min',
        value,
      );
    }
  }
  _displayMaxY?: number;
  @Input() set displayMaxY(value: number) {
    // Set internal value
    this._displayMaxY = value;
    // Only update if chart is already initialized
    if (this.chart?.yAxes.getIndex(0)) {
      console.log('Updating displayMaxY', value, this.chart.yAxes.getIndex(0));
      (this.chart.yAxes.getIndex(0) as ValueAxis<AxisRenderer>).set(
        'max',
        value,
      );
    }
  }
  @Input() sensorName: string;
  @Input() showPreview?: boolean;
  _legendData?: LegendDataItem[];
  @Input() set legendData(data: LegendDataItem[]) {
    this._legendData = data;
    // Only update if chart is already initialized
    if (this.chart?.children.getIndex(0)) {
      const legend = this.chart.children.getIndex(0) as Legend;
      if (legend.data) {
        legend.data.setAll(data);
      }
    }
  }
  @Input() customHeight?: number;
  @Input() valuesType: CHART_TYPE = CHART_TYPE.LINE;
  @Input() secondaryValuesType: CHART_TYPE = CHART_TYPE.LINE;

  @Input() hidden: boolean = false;
  _data: DateValueChartDataPoint[];
  _dataMin: number;
  _dataMax: number;
  @Input() set data(data: DateValueChartDataPoint[]) {
    this._data = data;
    // Determine min and max values
    const values = data.map((d) => d.value);
    this._dataMin = Math.min(...values);
    this._dataMax = Math.max(...values);
    this.hidden = data.length === 0;
    // Only update if chart is already initialized
    if (this.chart?.series.getIndex(0)) {
      const series: XYSeries[] = range(0, 8).map((i) =>
        this.chart.series.getIndex(i),
      );
      // Only append scrollbar series if preview is enabled
      if (this.showPreview) {
        series.push(
          (
            this.chart.get('scrollbarX') as XYChartScrollbar
          ).chart.series.getIndex(0),
        );
      }

      const axis: ValueAxis<AxisRenderer> = this.chart.yAxes.getIndex(
        0,
      ) as ValueAxis<AxisRenderer>;
      series.map((series) => {
        // Set data for each series
        series.data.setAll(data);
        // Reset zoom when data is updated
        // FIXME: For some reason the axis only updates it's range when the new data only
        // contains values that are lower than the current max of the axis. If the new data
        // contains higher values than the current max, the chart switches to "zoom" mode, instead of readjusting the axis.
        series.events.once('datavalidated', () => {
          axis.setAll({ start: 0, end: 1 });
        });
      });
    }
  }

  chartContainerRef = 'amchart5-chartdiv' + Math.floor(Math.random() * 1000);
  // All of these values are generated on component creation
  root?: Root;
  chart?: XYChart;
  xAxis?: DateAxis<AxisRenderer>;

  @Output() chartReady = new EventEmitter<ChartReadyEvent>();

  ngAfterViewInit(): void {
    // Create this.root
    this.root = Root.new(this.chartContainerRef);
    // Add XY Chart
    this.chart = this.root.container.children.push(
      XYChart.new(this.root, {
        pinchZoomX: true,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 0,
        paddingTop: 20,
        layout: this.root.verticalLayout,
      }),
    );
    // Setup axes
    const { xAxis, yAxis } = this.setupAxes();
    // Generate series and set data
    const series = this.setupSeries(xAxis, yAxis);
    // Add preview if requested
    if (this.showPreview) {
      this.chart.set('scrollbarX', this.setupPreview());
    }
    // Add legend if requested
    if (this._legendData?.length) {
      const legend = this.chart.children.unshift(
        Legend.new(this.root, {
          nameField: 'name',
          fillField: 'color',
          strokeField: 'color',
          marginBottom: 20,
        }),
      );
      legend.data.setAll(this._legendData);
    }
    this.chartReady.emit({
      root: this.root,
      chart: this.chart,
      ref: this.chartContainerRef,
      sensorName: this.sensorName,
      // Setup cursor sync
      cursor: this.setupCursorHandling([series.values, series.expectedValue]),
      axes: { xAxis, yAxis },
      series,
    });
    // Set data if already available
    if (this._data) {
      this.data = this._data;
    }
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
    this.chart = undefined;
  }

  private setupAxes(): {
    xAxis: DateAxis<AxisRenderer>;
    yAxis: ValueAxis<AxisRenderer>;
  } {
    // Setup date-value axes
    const xAxis = this.chart.xAxes.push(
      DateAxis.new(this.root, {
        renderer: AxisRendererX.new(this.root, {}),
        baseInterval: { timeUnit: 'minute', count: 1 },
        tooltipDateFormat: 'dd. MMM - HH:mm',
        tooltip: Tooltip.new(this.root, {}),
      }),
    );
    const yAxis = this.chart.yAxes.push(
      ValueAxis.new(this.root, {
        renderer: AxisRendererY.new(this.root, {}),
        min: this._displayMinY ? Math.min(this._displayMinY, this._dataMin) : 0,
        max: this._displayMaxY
          ? Math.max(this._displayMaxY, this._dataMax)
          : this._displayMaxY,
        strictMinMax: false,
      }),
    );

    return { xAxis, yAxis };
  }

  private setupSeries(
    xAxis: DateAxis<AxisRenderer>,
    yAxis: ValueAxis<AxisRenderer>,
  ): ChartSeries {
    return {
      values:
        this.valuesType === CHART_TYPE.LINE
          ? generateLineSeries(
              this.root,
              this.chart,
              xAxis,
              yAxis,
              '#061671',
              'value',
            )
          : generateColumnSeries(
              this.root,
              this.chart,
              xAxis,
              yAxis,
              '#061671',
              'value',
            ),
      secondaryValues:
        this.secondaryValuesType === CHART_TYPE.LINE
          ? generateLineSeries(
              this.root,
              this.chart,
              xAxis,
              yAxis,
              '#ff9900',
              'secondaryValue',
            )
          : generateColumnSeries(
              this.root,
              this.chart,
              xAxis,
              yAxis,
              '#ff9900',
              'secondaryValue',
            ),
      limit1: generateLineSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#ff9900',
        'limit1',
      ),
      limit2: generateLineSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#E2001A',
        'limit2',
      ),
      expectedValue: generateLineSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#061671',
        'expectedValue',
        true,
      ),
      expectedSecondaryValue: generateColumnSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#ff9900',
        'expectedSecondaryValue',
        true,
      ),
      futureMax: generateLineSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#4eb9ef',
        'futureMax',
        true,
      ),
      futureMin: generateLineSeries(
        this.root,
        this.chart,
        xAxis,
        yAxis,
        '#4eb9ef',
        'futureMin',
        true,
        'futureMax',
      ),
    };
  }

  private setupCursorHandling(series: (LineSeries | ColumnSeries)[]): XYCursor {
    // Register cursor
    const cursor = XYCursor.new(this.root, {
      behavior: 'zoomX',
      snapToSeries: series,
      snapToSeriesBy: 'x',
    });
    cursor.lineY.setAll({
      visible: false,
    });
    this.chart.set('cursor', cursor);

    // Register mouse wheel (zoom) events
    this.chart.plotContainer.events.on('wheel', (ev) => {
      // Only enable zooming when ctrl is pressed
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        this.chart.set('wheelY', 'zoomX');
      } else {
        this.chart.set('wheelY', 'none');
      }
    });

    return cursor;
  }

  private setupPreview(): XYChartScrollbar {
    const scrollBarX = XYChartScrollbar.new(this.root, {
      orientation: 'horizontal',
      height: 50,
    });
    const xAxis = scrollBarX.chart.xAxes.push(
      DateAxis.new(this.root, {
        baseInterval: { timeUnit: 'minute', count: 1 },
        renderer: AxisRendererX.new(this.root, {
          opposite: false,
          strokeOpacity: 0,
        }),
      }),
    );
    const yAxis = scrollBarX.chart.yAxes.push(
      ValueAxis.new(this.root, {
        renderer: AxisRendererY.new(this.root, {}),
      }),
    );
    const { values } = this.setupSeries(xAxis, yAxis);
    scrollBarX.chart.series.push(values);
    scrollBarX.set('marginBottom', 40);

    return scrollBarX;
  }
}
