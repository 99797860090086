import { Component } from '@angular/core';
import {
  LayerType,
  MapLayer,
} from '../../../../../state/dashboard/models/mapLayer';
import { TopoType } from 'src/app/models/topo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MapState } from 'src/app/state/dashboard/map.state';
import { MapActions } from 'src/app/state/dashboard/map.action';

@Component({
  selector: 'leaflet-layer-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  LayerType = LayerType;
  TopoType = TopoType;
  isAdmin: boolean;
  layers$: Observable<MapLayer[]> = this.store.select(MapState.layers);

  constructor(private store: Store) {}

  getMapLayersForType$(type: LayerType): Observable<MapLayer[]> {
    return this.layers$.pipe(
      map((layers) => layers.filter((_) => _.mainType === type)),
    );
  }

  allActive$(layerType: LayerType): Observable<boolean> {
    return this.store.select(MapState.allLayersActiveOfType(layerType));
  }

  noneActive$(layerType: LayerType): Observable<boolean> {
    return this.store.select(MapState.noLayerActiveOfType(layerType));
  }

  activateAll(layerType: LayerType) {
    this.store.dispatch(
      new MapActions.SetLayerVisibilityByType(layerType, true),
    );
  }

  deactivateAll(layerType: LayerType) {
    this.store.dispatch(
      new MapActions.SetLayerVisibilityByType(layerType, false),
    );
  }

  isActive$(layer: MapLayer): Observable<boolean> {
    return this.store.select(
      MapState.layerActive(layer.mainType, layer.subType),
    );
  }

  activate(layer: MapLayer) {
    this.store.dispatch(
      new MapActions.SetLayerVisibility(layer.layerName, true),
    );
  }

  deactivate(layer: MapLayer) {
    this.store.dispatch(
      new MapActions.SetLayerVisibility(layer.layerName, false),
    );
  }
}
