import { Customer } from 'src/app/models/customerList';
import { User } from 'src/app/models/user';
import { GuiDetail } from './models/guiDetails';

export namespace GuiActions {
  export class SetGuiContextFromUser {
    static readonly type = '[Gui] Set gui context from user';
    constructor(public user: User) {}
  }
  export class UpdateGuiContext {
    static readonly type = '[Gui] Update gui context';
    constructor(public context: Partial<GuiDetail>) {}
  }
  export class ResetGuiContext {
    static readonly type = '[Gui] Reset gui context';
  }
  export class SetCustomer {
    static readonly type = '[Gui] Set customer';
    constructor(public id: number) {}
  }
  export class SetPrimaryPanelVisibility {
    static readonly type = '[Gui] Set primary panel visibility';
    constructor(public visible: boolean) {}
  }
  export class SetMenuVisibility {
    static readonly type = '[Gui] Set menu visibility';
    constructor(public visible: boolean) {}
  }
}
