import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'table-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent {
  @Input()
  text!: string;

  @Output()
  export: EventEmitter<void> = new EventEmitter<void>();
}
