import { Injectable } from '@angular/core';
import { ExtendedColDef, PdfExport } from '@app/shared/ag-grid';
import { TranslateService } from '@ngx-translate/core';
import { Column, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root',
})
export class AgGridService {
  constructor(
    private pdfService: PdfService,
    private translateService: TranslateService
  ) {}

  async exportToPdf(
    exportParams: PdfExport.PdfExportParams,
    gridApi: GridApi,
    columnApi: ColumnApi,
    gridOptions: GridOptions
  ) {
    const docDefinition = await this.pdfService.getDocDefinition(
      exportParams,
      gridApi,
      columnApi,
      gridOptions
    );
    pdfMake
      .createPdf(docDefinition)
      .download(exportParams.getFileName(this.translateService));
  }

  static getCompleteColDef(column: Column): ExtendedColDef {
    return {
      ...column.getColDef(),
      ...column.getUserProvidedColDef(),
    };
  }
}
