import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';
import { of } from 'rxjs';

@Component({
  selector: 'app-sensors-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent {
  private _currentSensor: SensorBox;

  get currentSensor(): SensorBox {
    return this._currentSensor;
  }

  @Input()
  set currentSensor(sensor: SensorBox) {
    this._currentSensor = sensor;

    this.isWeatherStation = sensor?.frontendType === 'weather';
  }

  showSensorId: boolean;
  isWeatherStation: boolean;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.showSensorId = this.authenticationService.isSuperAdmin();
  }

  get isWeatherStation$() {
    return of(this.isWeatherStation);
  }
}
