import { Component, Input } from '@angular/core';
import { MapLayerComponent } from '../layer.util';
import { Map, LayerGroup } from 'leaflet';
import { LayerType } from 'src/app/state/dashboard/models/mapLayer';
import { TopoType, Waterway } from 'src/app/models/topo';
import { LeafletHelper } from 'src/app/helpers/leaflet.helper';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'leaflet-waterways-layer',
  template: `<div [leafletLayer]="layer"></div>`,
})
export class WaterwaysComponent extends MapLayerComponent<Waterway> {
  @Input() set leafletMap(map: Map) {
    if (map) {
      this.registerMapAndFilterChange(map, this.update);
    }
  }

  constructor(
    store: Store,
    private leafletHelper: LeafletHelper,
  ) {
    super(store, LayerType.TOPO, TopoType.WATERWAYS);
  }

  activate() {
    return this.leafletHelper.generateWaterwayLayers(
      this._map.getBounds(),
      this._map.getZoom(),
      this.activate,
    );
  }

  update() {
    return this.activate().pipe(tap((layer) => (this.layer = layer)));
  }
}
