import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { Product, ProductResult } from '../models/billing';
import { ApprovalDialogHelper } from '../helpers/approval-dialog.helper';
import { ToastHelper } from '../helpers/toast.helper';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient,
    private approvalDialogHelper: ApprovalDialogHelper,
    private toastHelper: ToastHelper,
  ) {
  }

  getProducts(): Promise<Product[]> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/billing/products`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  getDeliveries(customerId: number): Promise<Product[]> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/billing/deliveries?customerId=` + customerId)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  getDelivery(deliveryId: number): Promise<Product> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/billing/delivery/` + deliveryId)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  getProduct(productId: number): Promise<Product> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/billing/product/` + productId)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  saveProduct(product: Product): Promise<Product> {
    return this.http.put<any>(`${environment.dashboardApiUrl}/billing/product/` + product.id, product)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  saveDelivery(delivery: Product): Promise<Product> {
    return this.http.put<any>(`${environment.dashboardApiUrl}/billing/delivery/` + delivery.id, delivery)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  addDelivery(p: Product): Promise<Product> {
    return this.http.post<any>(`${environment.dashboardApiUrl}/billing/delivery`, p)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  deleteDelivery(p: Product): Promise<boolean> {
    return this.http.delete<any>(`${environment.dashboardApiUrl}/billing/delivery/` + p.id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.ok;
      })).toPromise();
  }

  updateDMat(): Promise<boolean> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/billing/maue/updateDMat`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.ok;
      })).toPromise();
  }

  startBilling(d: Product): Promise<Product> {
    return this.http.get<ProductResult>(`${environment.dashboardApiUrl}/billing/delivery/` + d.id + `/startBilling`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  workflowMaueStart(delivery: Product): Promise<Product> {
    return new Promise<Product>((resolve, reject) => {
      const dialogRef = this.approvalDialogHelper.openApprovalDialog(
        'Möchten Sie die Abrechnung wirklich starten?<br>' +
        delivery.id +
        ' ' +
        delivery.name,
      );
      dialogRef.afterClosed().subscribe(async (dialogResult) => {
        if (dialogResult) {
          try {
            this.startBilling(delivery).then((result) => {
              this.toastHelper.openSnackBar(
                'Position ' + delivery.name + ' wird abgerechnet.',
                'SUCCESS',
              );
              resolve(result);
            });
          } catch (e) {
            this.toastHelper.openCrudDeleteError();
            reject(null);
          }
        } else {
          reject(null);
        }
      })
    });
  }

}