<div class="mat-dialog-title">
	<p>
		<strong>{{ "alarmGroup.member.dialog.headline.prefix" | translate }} "{{
			alarmGroupName
			}}" {{ "alarmGroup.member.dialog.headline.suffix" | translate }}</strong>
	</p>
</div>

<div class="form-field">
	<label for="alarmGroupId" appTranslate="alarmGroup.member.dialog.headline.prefix"></label>
	<input id="alarmGroupId" [(ngModel)]="alarmGroupName" disabled="true" />
</div>

<div class="ch-margin-top-24">
	<div class="row">
		<div class="form-field col-4" [class.error]="false">
			<label for="input-memberType" appTranslate="alarmGroup.member.table.columns.memberType"></label>
			<select [(ngModel)]="memberType" [disabled]="this.modeEdit">
				<option [value]="null">-</option>
				<option *ngFor="let memberType of alarmGroupMemberTypes" [value]="memberType"
					[appTranslate]="'alarmGroup.member.memberType.' + memberType"></option>
			</select>
		</div>
		
		<div class="form-field" [class.error]="false" class="col-8" *ngIf="memberType == AlarmGroupMemberType.USER">
			<label for="input-userId" appTranslate="alarmGroup.member.dialog.user"></label>
			<select [(ngModel)]="userId" [disabled]="this.modeEdit">
				<option [value]="null">-</option>
				<option [ngValue]="entry.id" *ngFor="let entry of userList">
					{{ entry.name }}
				</option>
			</select>
		</div>
		
		<div class="form-field" [class.error]="false" class="col-8" *ngIf="memberType == AlarmGroupMemberType.ALARMCHANNEL">
			<label for="input-alarmChannelId" appTranslate="alarmGroup.member.dialog.alarmChannel"></label>
			<select id="alarmChannelId" [(ngModel)]="alarmChannelId" [disabled]="this.modeEdit">
				<option [value]="null">-</option>
				<option [ngValue]="entry.id" *ngFor="let entry of alarmChannelList">
					{{ entry.name }}
				</option>
			</select>
		</div>
		
		
	</div>
</div>

<div *ngIf="memberType" class="ch-margin-top-24">
	<div *ngIf="memberType == AlarmGroupMemberType.USER">
		<div class="row ch-margin-top-24">
			<div class="col-4">
			</div>

			<div class="form-field" [class.error]="false" class="col-2">
				<span class="toggle-button{{ this.email ? ' active' : '' }}" (click)="toggleEMail()">eMail</span>
			</div>
			<div class="form-field" [class.error]="false" class="col-2">
				<span class="toggle-button{{ this.sms ? ' active' : '' }}" (click)="toggleSMS()">SMS</span>
			</div>
			<div class="form-field" [class.error]="false" class="col-2">
				<span class="toggle-button{{ this.voice ? ' active' : '' }}" (click)="toggleVOICE()">VOICE</span>
			</div>
			<div class="form-field" [class.error]="false" class="col-2">
				<span class="toggle-button{{ this.phone ? ' active' : '' }}" (click)="togglePhone()">Anruf</span>
			</div>
		</div>
	</div>

</div>

<div *ngIf="extendedProperties" class="row ch-margin-top-24">
	<div class="col-6">
		<label for="input-userId" appTranslate="{{ 'alarmGroup.member.dialog.template.alarm' | translate }}"></label>
		<select [(ngModel)]="alarmMessageTemplateId">
			<option [value]="null" [selected]="!alarmMessageTemplateId">
				-
				{{ "alarmGroup.member.dialog.template.default" | translate }}
				-
			</option>
			<option [ngValue]="entry.id" *ngFor="let entry of templateList">
				{{ entry.name }}
			</option>
		</select>
	</div>

	<div class="col-6">
		<label for="input-userId">{{
			"alarmGroup.member.dialog.template.allClear" | translate
			}}</label>
		<select [(ngModel)]="clearMessageTemplateId">
			<option [value]="null" [selected]="!clearMessageTemplateId">
				-
				{{ "alarmGroup.member.dialog.template.default" | translate }}
				-
			</option>
			<option [ngValue]="entry.id" *ngFor="let entry of templateList">
				{{ entry.name }}
			</option>
		</select>
	</div>
</div>

<mat-dialog-actions class="dialog-actions">
	<button mat-flat-button class="btn-primary" (click)="addAlarmGroupMember()"
		appTranslate="alarmGroup.member.crud.actions.save"></button>
	<button mat-flat-button class="btn-secondary" (click)="cancel()"
		appTranslate="alarmGroup.member.crud.actions.cancel"></button>
</mat-dialog-actions>