import {Component} from '@angular/core';
import {BaseColumnComponent} from '../base-column.component';

@Component({
  selector: 'app-column-iconlabel',
  templateUrl: './column-iconlabel.component.html',
  styleUrls: ['./column-iconlabel.component.scss']
})
export class ColumnIconlabelComponent extends BaseColumnComponent {
  constructor() {
    super();
  }
}
