import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { CrudUserComponent } from './crud-user/crud-user.component';
import { CrudApiKeyComponent } from './crud-apiKey/crud-apiKey.component';
import { RegisteredGuard } from '@app/guards/registered.guard';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'edit-self',
    component: CrudUserComponent,
    data: {
      //breadcrumbLabel: 'user.table.actions.invite',
      mode: 'edit-self'
    }
  },
  {
    path: 'invite-user',
    component: CrudUserComponent,
    data: {
      breadcrumbLabel: 'user.table.actions.invite',
      mode: 'invite'
    }
  },
  {
    path: 'add-user',
    component: CrudUserComponent,
    data: {
      breadcrumbLabel: 'user.table.actions.add',
      mode: 'add'
    }
  },
  {
    path: 'add-apiKey',
    component: CrudApiKeyComponent,
    data: {
      breadcrumbLabel: 'user.table.actions.addApiKey',
      mode: 'add'
    }
  },
  {
    path: ':id',
    children: [
      {
        path: 'edit-user',
        component: CrudUserComponent,
        data: {
          breadcrumbLabel: 'user.table.actions.edit',
          mode: 'edit'
        }
      },
      {
        path: 'edit-apiKey',
        component: CrudApiKeyComponent,
        data: {
          breadcrumbLabel: 'user.table.actions.editApiKey',
          mode: 'edit'
        }
      }

    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
