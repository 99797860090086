<div
  *ngIf="gridApi.paginationGetTotalPages() > 1"
  class="paginator-outer-container"
>
  <div class="paginator-container">
    <div class="paginator-range-actions">
      <button
        mat-icon-button
        (click)="gridApi.paginationGoToFirstPage()"
        [disabled]="gridApi.paginationGetCurrentPage() === 0"
      >
        <mat-icon>first_page</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="gridApi.paginationGoToPreviousPage()"
        [disabled]="gridApi.paginationGetCurrentPage() === 0"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>

      <div class="paginator-pages ch-margin-left-8">
        <ng-container *ngIf="gridApi.paginationGetTotalPages() <= 10">
          <ng-container
            *ngFor="let page of totalPagesArray(gridApi); index as i"
          >
            <button
              [class.current-page]="gridApi.paginationGetCurrentPage() === i"
              (click)="gridApi.paginationGoToPage(i)"
            >
              {{ i + 1 }}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="gridApi.paginationGetTotalPages() > 10">
          <span>{{
            "table.pagination"
              | translate
                : {
                    current: gridApi.paginationGetCurrentPage() + 1,
                    total: gridApi.paginationGetTotalPages()
                  }
          }}</span>
        </ng-container>
      </div>

      <button
        mat-icon-button
        (click)="gridApi.paginationGoToNextPage()"
        [disabled]="
          gridApi.paginationGetCurrentPage() ===
          gridApi.paginationGetTotalPages() - 1
        "
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="gridApi.paginationGoToLastPage()"
        [disabled]="
          gridApi.paginationGetCurrentPage() ===
          gridApi.paginationGetTotalPages() - 1
        "
      >
        <mat-icon>last_page</mat-icon>
      </button>
    </div>
  </div>
</div>
