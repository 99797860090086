import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApprovalDialogConfirmation} from '../../../../models/approvalDialogConfirmation';

@Component({
    selector: 'app-approval-modal',
    templateUrl: './approval-modal.component.html',
    styleUrls: ['./approval-modal.component.scss']
})
export class ApprovalModalComponent {
  message: String;

  approval: ApprovalDialogConfirmation;

  value: string;

  disabled = false;

  checkForm = new FormGroup({
    check: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<ApprovalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    if (!!data?.approval) {
      this.approval = data.approval;
      this.disabled = true;
      this.checkForm.get('check').setValidators([Validators.required, Validators.pattern(this.approval.approvalText)]);
    }
  }

  ClickYes(): void {
    this.dialogRef.close(true);
  }
  ClickNo(): void {
    this.dialogRef.close(false);
  }
}
