import { ApiResult, LookupEntry } from './apiResults';

export class AgsLookup {
  ags: string;
  name: string;
}

export interface Customer {
  id: number;
  name: string;
  active: boolean;

  mapCenterLat: number;
  mapCenterLon: number;
  mapZoomDashboard: number;
  mapZoomApp: number;

  clearMessageTemplate: LookupEntry;
  alarmMessageTemplate: LookupEntry;

  featureApp: boolean;
  featureNina: boolean;
  featureWeather: boolean;
  featureVoiceMessage: boolean;
  featurePhoneCall: boolean;
  featureRainRadar: boolean;
  featureAlarmRules: boolean;
  featureAlarmGroups: boolean;
  featureAlarmActions: boolean;
  featureReports: boolean;

  ags: string;
  bigRegistrationDate: Date;

  sensorBoxes?: any;
  users?: any;

  alertingAgsList: AgsLookup[];

  maueCostCenter?: string;
}

export type CustomerResult = ApiResult<Customer>;
export type AgsLookupListResult = ApiResult<AgsLookup[]>;

export function createEmptyCustomer(): Customer {
  return {
    active: true,
    bigRegistrationDate: null,
  } as Customer;
};

