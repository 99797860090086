import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SensorBox } from '../../../../../../../state/noysee/models/sensorBox';
import { Trend } from '../../../../../../../helpers/chart.helper';

@Component({
  selector: 'app-sensor-chart-header',
  templateUrl: './sensor-chart-header.component.html',
  styleUrls: ['./sensor-chart-header.component.scss'],
})
export class SensorChartHeaderComponent implements AfterViewInit {
  _sensorBox: SensorBox;
  @Input()
  set sensorBox(sensorBox: SensorBox) {
    this._sensorBox = sensorBox;
    this.currentValue = this.getCurrentValue();
  }
  _selectedSensor: string;
  @Input()
  set selectedSensor(sensor: string) {
    this._selectedSensor = sensor;
    this.currentValue = this.getCurrentValue();
  }
  @Input()
  showSensorSelection: boolean = false;
  @Input()
  trend: Trend = 'STAGNANT';

  @Output()
  selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  currentValue: number | string = '-';
  availableSensors: string[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.currentValue = this.getCurrentValue();
    this.availableSensors = Object.keys(this._sensorBox.sensors).filter(
      (key) => !['windDirection', 'battery', 'voltage'].includes(key),
    );
    this.cdr.detectChanges();
  }

  private getCurrentValue(): number | string {
    if (
      typeof this._sensorBox?.sensors[this._selectedSensor]
        ?.currentDataValue === 'number'
    ) {
      return (
        Math.round(
          this._sensorBox.sensors[this._selectedSensor].currentDataValue * 10,
        ) / 10
      );
    }
    return '-';
  }
}
