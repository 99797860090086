import {
  LookupEntry,
} from '../../../models/apiResults';

export enum AlarmActionExecutionType {
	backendAction= 'backendAction',
	webLink = 'webLink',
	noAction= 'noAction'
}

export enum AlarmActionType {
	staticLink= 'staticLink', 
	dynamicLink= 'dynamicLink',
	noAction= 'noAction', 
	messageAlert= 'messageAlert',
//	authChannelAlert= 'authChannelAlert',
//	noAuthChannelAlert= 'noAuthChannelAlert',
	channelAlert = 'channelAlert',
}

export enum ActionStateType {
	startPending='startPending', 
	endPending='endPending', 
	started='started', 
	done='done', 
	archived='archived', 
	error='error', 
	disabled='disabled', 
	available ='available', 
	idle='idel'
}


export class AlarmAction {
  id: number;
  alarmLevel: number;
  name: string;
  actionType: AlarmActionType;
  active: boolean;
  executable: boolean;
  // actionFrontendCommand: AlarmActionExecution;
  additionalAuth: boolean;
  properties: any;
  state: ActionStateType;
}

export class AlarmActionExecution {
	actionId: number;
	commandType: AlarmActionExecutionType;
	link: string;
}

export class AlarmActionExecutionRequest {
    customerId: number;
	sensorBoxId: number;
	password: string;
	executionValues: any;
}