import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { LookupService } from '../../../../../services/lookup.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { FirmwareService } from 'src/app/services/firmware.service';
import { Firmware } from 'src/app/models/firmware';

@Component({
  selector: 'app-upgradeFirmwareDialog',
  templateUrl: './upgradeFirmwareDialog.component.html',
  styleUrls: ['./upgradeFirmwareDialog.component.scss']
})
export class UpgradeFirmwareDialogComponent implements OnInit {
  isAdmin: boolean;
  isSuperAdmin: boolean;

  firmwareList: Firmware[];

  sensorBoxId: number;
  sensorBoxName: string;
  installedFirmwareName: string;
  selectedFirmware: Firmware;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private firmwareService: FirmwareService,
    
    private dialogRef: MatDialogRef<UpgradeFirmwareDialogComponent>) {

    this.isSuperAdmin = false;
    this.isAdmin = false;

  }

  ngOnInit(): void {
    var installedFirmware: Firmware;
    if (this.data?.sensorBox) {
      this.sensorBoxId = this.data?.sensorBox.id;
      this.sensorBoxName = this.data?.sensorBox.name;
      installedFirmware=  this.data?.sensorBox.installedFirmware;
    } else {
      this.sensorBoxId = this.data?.sensorBoxId;
      this.sensorBoxName = this.data?.sensorBoxName;
      installedFirmware=  this.data?.installedFirmware;
    }
    if (installedFirmware) {
      this.installedFirmwareName= installedFirmware.name + `(` + installedFirmware.id +`)`; 
    } else {
      this.installedFirmwareName= "- unknown -";
    }


    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.lookupService.getFirmwareList(this.sensorBoxId).then(result => {
      this.firmwareList = result;
    });

  }

  installAndClose(): void {
    this.firmwareService.upgradeOnSensorBox(
      this.sensorBoxId,
      this.selectedFirmware.id,
    ).then(data => {
      this.toastHelper.openSnackBar('Firmwareaktualisierung angestoßen', 'SUCCESS');
      this.dialogRef.close();
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
