import { LayerGroup, Map } from 'leaflet';
import { LayerType } from 'src/app/state/dashboard/models/mapLayer';
import { FrontendType } from 'src/app/state/noysee/models/sensor';
import { TopoType } from 'src/app/models/topo';
import { Store } from '@ngxs/store';
import { MapState } from 'src/app/state/dashboard/map.state';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

export abstract class MapLayerComponent<T> {
  // TODO: Inject store using inject() after updating angular version
  _map: Map;
  layer: LayerGroup<T> = new LayerGroup<T>([]);
  protected activateSubscription: Subscription;
  protected active: boolean;
  protected layerType: LayerType;
  protected subType: FrontendType | TopoType;

  constructor(
    protected store: Store,
    layerType: LayerType,
    subType: FrontendType | TopoType,
  ) {
    this.layerType = layerType;
    this.subType = subType;
  }

  // @Input
  protected registerMapAndFilterChange(
    map: Map,
    update?: (map: Map) => Observable<any>,
  ) {
    this._map = map;
    // Register activation/deactivation
    this.store
      .select(MapState.layerActive(this.layerType, this.subType))
      .subscribe((active) => {
        if (active) {
          // Fire event to show loading spinner
          this._map.fireEvent('dataloading');
          this.activateSubscription = this.activate().subscribe((layer) => {
            this.layer = layer || new LayerGroup([]);
            this._map.fireEvent('dataload');
          });
        } else {
          this.activateSubscription?.unsubscribe();
          this.deactivate();
        }
        this.active = active;
      });
    // Register update on moveend
    if (update) {
      map.on('moveend', () => {
        if (this.active) {
          this._map.fireEvent('dataloading');
          update
            .bind(this)(map)
            .pipe(first())
            .subscribe(() => this._map.fireEvent('dataload'));
        }
      });
    }
  }

  protected abstract activate(): Observable<LayerGroup<T>>;
  protected deactivate(): void {
    this.layer = new LayerGroup([]);
  }
}
