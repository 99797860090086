import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { UsersService } from '../../../../../services/users.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TestAlarmModalComponent } from '../test-alarm-modal/test-alarm-modal.component';
import { TestMessageModalComponent } from '../test-message-modal/test-message-modal.component';
import { AssignedAlarmGroup } from '../../../../../state/noysee/models/alarmGroup';
import { AddSensorBoxAlarmGroupComponent } from '../addAlarmGroup-modal/addAlarmGroup.component';
import {
  DataTableOptions,
  GridState,
  TitleSize,
  TableActionType,
} from 'src/app/shared/data-table/data-table.model';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';

@Component({
  selector: 'app-alarmGroups',
  templateUrl: './alarmGroups.component.html',
  styleUrls: ['./alarmGroups.component.scss'],
})
export class SensorBoxAlarmGroupsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  set sensor(sensor$: Observable<SensorBox>) {
    this.sensorSubscription?.unsubscribe();
    this.sensorSubscription = sensor$.subscribe(async (sensor) => {
      if (sensor) {
        this.currentSensor = sensor;

        this.sensorName = sensor.name;
        this.currentSensorId = sensor.id;
        this.alarmReceiverVisible = (sensor as any).alarmReceiverVisible;
        this.alarmReceiverEditable = (sensor as any).alarmReceiverEditable;

        this.reloadAlarmGroups();
      }
    });
  }

  currentSensorId: number;
  currentSensor: SensorBox;
  currentRoleId: number;

  parent: string;

  alarmReceiverVisible: boolean;
  alarmReceiverEditable: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  sensorName: string;
  sensorSubscription: Subscription;

  alarmGroupList: AssignedAlarmGroup[];
  dataTableOptions: DataTableOptions<AssignedAlarmGroup>;
  gridState: GridState = null;
  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  constructor(
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private usersService: UsersService,
    private dataTableStateService: DataTableStateService,
    private noyseeService: NoyseeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.currentSensorId = null;
    this.alarmReceiverVisible = false;
    this.alarmReceiverEditable = false;
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();
  }

  ngOnInit(): void {
    this.dataTableOptions = {
      title: 'alarmRule.members.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'alarmRule.members.table.actions.add',
          rule: () => this.authenticationService.isSuperAdmin(),
          actionFn: () => this.addAlarmGroup(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'alarmRule.members.table.actions.view',
            icon: 'app-view',
            actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
              this.showGroupMembers(assignedAlarmGroup.alarmGroup.id),
          },
          {
            label: 'alarmRule.members.table.actions.delete',
            icon: 'app-delete',
            actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
              this.removeAlarmGroup(assignedAlarmGroup.id),
          },
        ],
      },
      // rowStyleFn: (alarmGroup: AlarmGroup) => {
      //   return {
      //     'background-color': this.getStateColor(sensor.state),
      //     'border': 'solid 1.5px white'
      //   };
      // },
      columnDef: [
        {
          key: 'id',
          displayName: 'alarmRule.members.table.columns.id',
          width: '5%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'alarmLevel',
          displayName: 'alarmRule.members.table.columns.alarmLevel',
          width: '15%',
          sortable: true,
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `sensor.state.${assignedAlarmGroup.alarmLevel}`,
        },
        {
          key: 'name',
          displayName: 'alarmRule.members.table.columns.name',
          width: this.isSuperAdmin ? '70%' : '75%',
          sortable: true,
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `${assignedAlarmGroup.alarmGroup.name}`,
          styleFn: (assignedAlarmGroup: AssignedAlarmGroup) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (assignedAlarmGroup: AssignedAlarmGroup) =>
            this.router.navigate([
              '/dashboard',
              'alarmGroups',
              assignedAlarmGroup.alarmGroup.id,
              'edit',
            ]),
        },
        {
          key: 'state',
          displayName: 'alarmRule.members.table.columns.state',
          width: '20%',
          valueTransform: (assignedAlarmGroup: AssignedAlarmGroup) =>
            `alarmGroup.state.${assignedAlarmGroup.active}`,
        },
      ],
    };
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.sensorSubscription?.unsubscribe();
  }

  async reloadAlarmGroups() {
    this.alarmGroupList = await this.noyseeService.getSensorBoxAlarmGroups(
      this.currentSensorId,
    );
  }

  showGroupMembers(alarmGroupId: number) {
    this.router.navigate(['/dashboard', 'alarmGroups', alarmGroupId, 'view']);
  }

  removeAlarmGroup(memberId: number) {
    this.noyseeService
      .removeAlarmGroup(this.currentSensorId, memberId)
      .then((result) => {
        this.alarmGroupList = result;
      });
  }

  addAlarmGroup() {
    const _this = this;
    const dialogOptions: MatDialogConfig = {
      data: {
        parentType: 'sensorBox',
        name: this.sensorName,
        id: this.currentSensorId,
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddSensorBoxAlarmGroupComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        _this.reloadAlarmGroups();
      });
  }

  testAlarm() {
    const dialogOptions: MatDialogConfig = {
      data: {
        name: this.sensorName,
        id: this.currentSensorId,
      },
      maxWidth: 600,
    };

    this.matDialog.open(TestAlarmModalComponent, dialogOptions);
  }

  testMessage() {
    const dialogOptions: MatDialogConfig = {
      data: {
        name: this.sensorName,
        id: this.currentSensorId,
      },
      maxWidth: 600,
    };
    this.matDialog.open(TestMessageModalComponent, dialogOptions);
  }
}
