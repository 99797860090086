import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseCellRenderer } from '../util/interfaces';

export interface LinkCellRendererParams extends ICellRendererParams {
  link?: (params: LinkCellRendererParams) => string[];
}

@Component({
  template: `
    <strong>
      <a *ngIf="params.link; else nolink" [routerLink]="params.link(params)">{{
        params.value
      }}</a>
      <ng-template #nolink>{{ params.value }}</ng-template>
    </strong>
  `,
  styles: [
    `
      a {
        font-size: 15px !important;
      }
    `,
  ],
})
export class LinkCellRenderer
  implements ICellRendererAngularComp, BaseCellRenderer
{
  params: LinkCellRendererParams;

  agInit(params: LinkCellRendererParams): void {
    this.params = params;
  }

  refresh(params: LinkCellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
