import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';

@Component({
  selector: 'app-test-message-modal',
  templateUrl: './test-message-modal.component.html',
  styleUrls: ['./test-message-modal.component.scss'],
})
export class TestMessageModalComponent implements OnInit {
  message: string;
  sensorId: string;
  sensorName: string;

  constructor(
    private dialogRef: MatDialogRef<TestMessageModalComponent>,
    private toastHelper: ToastHelper,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noyseeService: NoyseeService
  ) {
    this.message = '';
  }

  ngOnInit(): void {
    this.sensorId = this.data?.id;
    this.sensorName = this.data?.name;
  }

  async sendTestMessage() {
    const _this = this;
    await this.noyseeService
      .sendTestMessage(this.sensorId, this.message)
      .catch((e) => {
        console.log('Fehler beim senden der Nachricht: ', e);
      });
    this.cancel();
  }

  cancel() {
    this.dialogRef.close();
  }
}
