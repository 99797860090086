import { EventEmitter } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import {BehaviorSubject, Observable} from 'rxjs';

export enum DataTableColumnType {
  DEFAULT = '',
  DATE = 'DATE',
  TIME = 'TIME',
  NUMBER = 'NUMBER',
  ICON = 'ICON',
  LINK = 'LINK',
  ICONLABEL = 'ICONLABEL',
  EDITOR = 'EDITOR',
}

export enum ExportType {
  CSV= 'CSV',
  PDF= 'PDF'
}

export enum TableActionType {
  BUTTON = 'BUTTON',
  MENU = 'MENU'
}

export enum TableFilterType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  DATE = 'DATE'
}

export enum TitleSize {
  H1 = 'H1',
  H2 = 'H2'
}

export interface DataTableOptions<T> {
  title: string;
  titleSize?: TitleSize;
  columnDef: ColumnDef<T>[];
  export?: {
    pdf?: boolean;
    csv?: boolean;
    exportHeadline?: string;
  };
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc';
  filter?: boolean;
  filterDefaults?: {[key: string]: string | {from: any, to: any }};
  pageSize?: number;
  tableActions?: TableAction[];
  rowActions?: RowActions;
  rowStyleFn?: (data?: T) => { [key: string]: string; };
}

export type ColumnDef<T> = DataTableColumnDef<T> | IconLabelColumnDef<T> | CellEditorColumnDef<T>;

export interface DataTableColumnDef<T> extends BaseDataTableColumnDef<T> {
}

export interface CellEditorColumnDef<T> extends BaseDataTableColumnDef<T> {
  type: DataTableColumnType.EDITOR;
  editable?: boolean;
  dataChange?: EventEmitter<any>;
}

export interface IconLabelColumnDef<T> extends BaseDataTableColumnDef<T> {
  type: DataTableColumnType.ICONLABEL;
  icon?: string;
  label?: string;
  valueTransform: (data: T) => {icon: string, label: string};
}

export interface TablePaginatorData {
  page: number;
  pageSize: number;
  totalItems: number;
}

export interface RowActions {
  rule?: () => boolean;
  type: TableActionType;
  actionList: TableAction[];
}

export interface TableAction {
  iconFn?: (data?: any) => string;
  icon?: string;
  labelFn?: (data?: any) => string;
  label?: string;
  dataRule?: (data?: any) => boolean;
  rule?: () => boolean;
  rule$?: BehaviorSubject<boolean>;
  enabled$?: Observable<boolean>;
  routerPath?: string;
  linkFn?: (...args) => {path: string[], param?: any};
  actionFn?: (...args) => void;
}

export interface FilterOption {
  label: string;
  value: any;
}

export interface BaseDataTableColumnDef<T> extends Sortable<T>, Filterable<T>, Exportable<T> {
  type?: DataTableColumnType;
  hidden?: boolean;
  key: (keyof T) | string;
  displayName: string;
  valueTransform?: (data: T) => any;
  link?: (data?: T) => string[];
  actionFn?: (data?: T) => any | void;
  width?: number | string;
  styleFn?: (data?: T) => { [key: string]: string; };
  wrap?: boolean;
}

export interface Sortable<T> {
  sortable?: boolean;
  sortValueFn?: (data: T) => any;
}

export interface Filterable<T> {
  filterType?: TableFilterType;
  filterValues?: FilterOption[];
  disableReset?: boolean;
  filterFn?: (data: T, arg1, arg2?) => boolean;
  filterChange?: (data: any) => void;
}

export interface Exportable<T> {
  onlyExport?: boolean;
  exports?: ExportType[];
  exportOverride?: ExportType;
  exportName?: string;
  exportValue?: (data: T) => any;
}

export interface GridState {
  filter: {[key: string]: string};
  page: number;
  url: string;
  sorter: {
    active: string,
    direction: SortDirection
  };
}
