import { LookupEntry } from './apiResults';

export enum DeviceState {
  ASSEMBLED = 0,
  IN_QS = 1,
  TESTED = 2,
  TAKEN_OFF = 10,
  IN_REPAIR = 11,
  WAREHOUSE = 50,
  IN_USE = 100,
  TRASH = -1,
  STOLEN = -2
}


export class NoyseeDevice {
  id: number;
  uuid: string;
  state: DeviceState;
  hwType: string;
  noyseeSN: string;
  pcbSN: string;
  imei: string;
  simICCID: string;
  btMac: string;
  loraDevEui: string;
  loraAppEui: string;
  loraAppKey: string;
  productionDate: Date;
  testDate: Date;
  deliveryDate: Date;
  qsBy: string;
  description: string;
  remark: string;
  stateText: string;
  software: string;

  lat: number;
  lon: number;

  defaultSensorBoxTemplate: LookupEntry;

  sensorBox: LookupEntry;

  batterySN: string;
	loraModule: string;
	pcieModule: string;

  maueInventoryNumber: string;
}
