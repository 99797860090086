import { Injectable } from '@angular/core';
import { SensorService } from './sensor.service';
import { FrontendType, Sensor } from 'src/app/state/noysee/models/sensor';
import {
	Action,
	NgxsOnInit,
	Selector,
	State,
	StateContext,
	StateToken,
	Store,
	createSelector,
} from '@ngxs/store';
import { SensorActions } from './sensor.action';
import { filter, map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { GuiState } from '../dashboard/gui.state';
import { WebsocketService } from 'src/app/services/websocket.service';
import { Topic, WebsocketMessage } from 'src/app/models/websocketMessageData';
import { SensorBox, WebsocketSensorBox } from './models/sensorBox';
import { AlarmActionService } from './alarmAction.service';

interface SensorStateModel {
	mapList: SensorBox[];
	myList: SensorBox[];
	currentSensor: SensorBox;
}

const SENSOR_STATE_TOKEN = new StateToken<SensorStateModel>('sensor');

@State<SensorStateModel>({
	name: SENSOR_STATE_TOKEN,
	defaults: {
		mapList: [],
		myList: [],
		currentSensor: null,
	},
})
@Injectable()
export class SensorState implements NgxsOnInit {
	constructor(
		private sensorService: SensorService,
		private websocketService: WebsocketService,
		private alarmActionService: AlarmActionService,
		private store: Store,
	) { }

	ngxsOnInit(ctx: StateContext<any>): void {
		// Fetch public list and sensors visible on map
		this.store
			.select(GuiState.selectedCustomer)
			.pipe(filter((customer) => !!customer))
			.subscribe((_) => {
				ctx.dispatch(new SensorActions.FetchMyList());
			});
		// Register websocket listener for sensor updates
		this.websocketService.dashboardWebSocket.subscribe((socket) => {
			if (!socket) {
				return;
			}

			socket.addEventListener('open', () => {
				// Fetch list when websocket available
				console.log('SensorState: Websocket opened');
				ctx.dispatch(new SensorActions.FetchMyList());
			});

			socket.addEventListener('message', (event) => {
				const message = new WebsocketMessage<WebsocketSensorBox[]>(event.data);

				// React to sensor box updates
				if (
					message.topic === Topic.SENSOR_BOX_NEW_VALUE ||
					message.topic === Topic.SENSOR_BOX_META
				) {
					for (const sensorBox of message.data) {
						// If current sensor is updated, dispatch update event
						if (ctx.getState().currentSensor?.id === sensorBox.sensorBoxId) {
							console.log('SensorState: Websocket update for current sensor');
							ctx.dispatch(new SensorActions.Select(sensorBox.sensorBoxId));
						}

						// If changed sensor is included in my list, update list
						if (
							ctx.getState().myList.find((s) => s.id === sensorBox.sensorBoxId)
						) {
							console.log('SensorState: Websocket update for my list');
							ctx.dispatch(new SensorActions.FetchMyList());
						}
					}
				} else if (message.topic == Topic.ACTION_CHANGED) {
					console.log('WebSocket: alarmAction ' + message);
					for (const alarmAction of message.data) {
						var sensorBox = ctx.getState().currentSensor;
						// If current sensor is updated, dispatch update event
						if (sensorBox?.id === alarmAction.sensorBoxId) {
							console.log('SensorState: Websocket update caused by alarmAction');
							ctx.dispatch(new SensorActions.Select(alarmAction.sensorBoxId));
						}
					}
				}
			});
		});
	}

	@Selector()
	static mapList(state: SensorStateModel) {
		return state.mapList;
	}

	static mapListForType(type: FrontendType) {
		return createSelector([SensorState], (state: SensorStateModel) =>
			state.mapList.filter((sensor) => sensor.frontendType === type),
		);
	}

	@Selector()
	static myList(state: SensorStateModel) {
		return state.myList;
	}

	@Selector()
	static currentSensor(state: SensorStateModel) {
		return state.currentSensor;
	}

	@Action(SensorActions.FetchMyList)
	fetchMyList(ctx: StateContext<SensorStateModel>) {
		const customer = this.store.selectSnapshot(GuiState.selectedCustomer);
		return this.sensorService.getMySensorList(customer.id).pipe(
			map((result) => result.data),
			tap((sensors) =>
				ctx.patchState({
					myList: sensors,
				}),
			),
		);
	}

	@Action(SensorActions.FetchForMap)
	fetchForMap(
		ctx: StateContext<SensorStateModel>,
		action: SensorActions.FetchForMap,
	) {
		return this.sensorService.getSensorsInBoundingBox(action.bounds).pipe(
			map((result) => result.data),
			tap((sensors) =>
				ctx.patchState({
					mapList: sensors,
				}),
			),
		);
	}

	@Action(SensorActions.Unselect)
	unselect(ctx: StateContext<SensorStateModel>) {
		return ctx.patchState({
			currentSensor: null,
		});
	}

	@Action(SensorActions.Select)
	select(ctx: StateContext<SensorStateModel>, action: SensorActions.Select) {
		return this.sensorService.getSensorBox(action.id).pipe(
			map((result) => result.data),
			tap((sensorBox) => {
				return ctx.patchState({
					currentSensor: sensorBox,
				});
			}),
		);
	}

	@Action(SensorActions.Create)
	create(ctx: StateContext<SensorStateModel>, action: SensorActions.Create) {
		const currentMeta = {
			...action.sensorBox,
			sensors: {
				[action.sensorBox.primarySensor]: {
					limitDelta: null,
					limit1: action.sensorBox.limit1,
					limit2: action.sensorBox.limit2,
					limit3: null,
					limit4: null,
					limit5: null,
				},
			},
		};
		return this.sensorService.createSensor(currentMeta).subscribe(() => {
			ctx.dispatch(new SensorActions.FetchMyList());
		});
	}

	@Action(SensorActions.Update)
	update(ctx: StateContext<SensorStateModel>, action: SensorActions.Update) {
		return this.sensorService
			.updateSensor(action.sensorBox)
			.subscribe(() => ctx.dispatch(new SensorActions.FetchMyList()));
	}


}
