<div *ngIf="currentInformation$ | async as info" class="ch-margin-top-16">
  <div class="today">
    <strong
      appTranslate="sensor.information.{{
        !(isWeatherStation$ | async) ? 'weather-data' : 'weather-prediction'
      }}"
    >
    </strong>
    <div class="current-info">
      <div>
        <p>{{ weekday$ | async }},</p>
        <p>{{ dateString$ | async }}</p>
        <p>{{ time$ | async }}</p>
      </div>
      <app-symbol
        [temperature]="info.temp"
        [symbol]="info.weatherSymbol"
        [largeText]="true"
        fontSize="30px"
      ></app-symbol>
    </div>
    <div class="general-info ch-margin-top-16">
      <div>
        <strong
          >{{ info.precipCurrent }}
          {{ "sensor.information.units.rain" | translate }}</strong
        >
        <p appTranslate="sensor.information.values.precipCurrent"></p>
      </div>
      <div>
        <strong
          >{{ info.precip6h }}
          {{ "sensor.information.units.rain" | translate }}</strong
        >
        <p appTranslate="sensor.information.values.precip6h"></p>
      </div>
      <div>
        <strong
          >{{ info.windGust }}
          {{ "sensor.information.units.speed" | translate }}</strong
        >
        <p appTranslate="sensor.information.values.wind"></p>
      </div>
    </div>
  </div>
</div>
<div class="control-wrapper ch-margin-top-16">
  <span
    class="controls-field"
    [class.selected]="!entireForecast"
    (click)="entireForecast = false"
    appTranslate="sensor.information.values.current"
  ></span>
  <span
    class="controls-field"
    [class.selected]="entireForecast"
    (click)="entireForecast = true"
    appTranslate="sensor.information.values.forecast"
  ></span>
</div>
<ng-container *ngIf="entireForecast">
  <div
    *ngIf="reducedForecast$ | async as reducedForecast"
    class="forecast reduced"
  >
    <div *ngFor="let day of reducedForecast" class="forecast-day">
      <p appTranslate="sensor.information.forecast.date.{{ day.dayName }}"></p>
      <app-symbol
        temperature="{{ day.tempMin }} - {{ day.tempMax }}"
        [symbol]="day.weatherSymbol"
        [largeText]="true"
      ></app-symbol>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!entireForecast">
  <div *ngIf="twentyFourHourForecast$ | async as forecast" class="forecast">
    <div *ngFor="let period of forecast; index as i" class="forecast-day">
      <p
        appTranslate="sensor.information.forecast.time.{{ period.timeslotRef }}"
      ></p>
      <app-symbol
        [temperature]="period.temp"
        [symbol]="period.weatherSymbol"
        [isNight]="!period.day"
      ></app-symbol>
    </div>
  </div>
</ng-container>
