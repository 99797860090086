import { LookupEntry } from './apiResults';

export class MessageTemplate {
	id: number;
	name: string;
	description: string;
	subject: string;
	body: string;
	instruction: string;
	customer: LookupEntry;
	active: boolean;
	templateClass: string;
}

export enum MessageTemplateClass {
	SEND_PASSWORD = 'SEND_PASSWORD',
	INVITE_WITH_PASSWORD = 'INVITE_WITH_PASSWORD',
	RESET_BY_TOKEN = 'RESET_BY_TOKEN',
	WELLCOME = 'WELLCOME',
	INVITE_BY_TOKEN = 'INVITE_BY_TOKEN',
	ADMIN_TEST_ALARM = 'ADMIN_TEST_ALARM',
	SENSOR_TEST_ALARM = 'SENSOR_TEST_ALARM',
	SENSOR_CLEAR = 'SENSOR_CLEAR',
	SENSOR_ALARM = 'SENSOR_ALARM',
	SENSOR_FOUND = 'SENSOR_FOUND',
	SENSOR_LOST = 'SENSOR_LOST',
	SYSTEM = 'SYSTEM',
	ACTION_START = 'ACTION_START',
	ACTION_END = 'ACTION_END',
}

export function getTemplateClasses(): { label: string, value: string }[] {
	const templateClasss = [];
	for (const templateClass of Object.values(MessageTemplateClass)) {
		templateClasss.push({
			label: `messageTemplate.templateClasses.${templateClass}`,
			value: templateClass
		});
	}
	return templateClasss;
}
