<ng-container *ngIf="sensor$ | async as sensorDetails">
  <div class="col-12">
    <h1 id="sensorName">{{ sensorDetails.name }}</h1>
  </div>
  <div class="col-12">
    <div class="sub-header-col">
      <p *ngIf="showSensorId">
        <strong appTranslate="sensor.information.id"></strong>:
        {{ sensorDetails.id }}
      </p>
    </div>
    <div class="last-timestamp-col">
      <p>
        <strong appTranslate="sensor.information.last-value"></strong>:
        {{
          sensorDetails.sensors[sensorDetails.primarySensor]
            ? (sensorDetails.sensors[sensorDetails.primarySensor]
                .currentDataTimestamp | date: "dd.MM.yyyy HH:mm")
            : "nicht vorhanden"
        }}
      </p>
    </div>
    <div class="data-source-col">
      <p>
        <strong appTranslate="sensor.information.source"></strong>:
        {{ sensorDetails.datasource }}
      </p>
    </div>
  </div>
  <app-sensor-chart></app-sensor-chart>
</ng-container>
