import { User } from './user';
import { NoyseeDevice } from './noyseeDevice';
import {
  IoTSensorBoxSettings,
  SensorBoxCommandQueueEntry,
} from '../state/noysee/models/sensor';
import {
  SensorBoxAlarmGroup,
  AlarmGroup,
  AlarmRule,
  AssignedAlarmGroup,
} from '../state/noysee/models/alarmGroup';
import { ErrorCodes } from './errorHandler';
import { Sensor } from '../state/noysee/models/sensor';
import { Customer } from './customerList';
import { Firmware } from './firmware';
import { MessageTemplate } from './messageTemplate';
import { LoRaWanConfig } from './loraWanConfig';
import { ExportFileStation, ExportFile } from './exportFile';
import { SensorBox, SensorBoxData } from '../state/noysee/models/sensorBox';
import { Statistic } from '@app/services/statistics.service';
import { ImportConfig } from './importConfig';
import { GeoPolygone } from './topo';
import { AlarmAction, AlarmActionExecution } from '@app/state/noysee/models/alarmAction';
import { AlarmChannel } from '@app/state/noysee/models/alarmChannel';

export enum AlarmLevelStates {
  PLANNED = -4,
  DISABLED = -3,
  DEFECTIVE = -2,
  ERROR = -1,
  NORMAL = 0,
  LIMIT_1 = 1,
  LIMIT_2 = 2,
  LIMIT_3 = 3,
  LIMIT_4 = 4,
  LIMIT_5 = 5,
}

export interface LookupEntry {
  id: number;
  name: string;
}

export interface LookupEntryWithState extends LookupEntry {
  active: boolean;
}

export interface ApiResult<T> {
  ok: boolean;
  data: T;
  message: string;
  errorCode?: ErrorCodes; // Only exists if ok === false
}

export type LoginResult = ApiResult<User> & {
  jwt: string;
  sessionUser: User;
};

export type PlainApiResult = ApiResult<any>;

export type StatisticsResult = ApiResult<Statistic[]>;
export type StatisticResult = ApiResult<Statistic>;
export type UserListResult = ApiResult<User[]>;
export type UserResult = ApiResult<User>;
export type SensorResult = ApiResult<SensorBox>;
export type SensorDataResult = ApiResult<SensorBoxData>;
export type SensorListResult = ApiResult<SensorBox[]>;
export type NoyseeDeviceResult = ApiResult<NoyseeDevice>;
export type NoyseeDeviceListResult = ApiResult<NoyseeDevice[]>;
export type FirmwareResult = ApiResult<Firmware>;
export type FirmwareListResult = ApiResult<Firmware[]>;
export type IoTSensorBoxSettingsResult = ApiResult<IoTSensorBoxSettings>;
export type CustomerListResult = ApiResult<Customer[]>;
export type LookupListResult = ApiResult<LookupEntry[]>;
export type LookupWithStateListResult = ApiResult<LookupEntryWithState[]>;
export type SensorBoxAlarmGroupListResult = ApiResult<SensorBoxAlarmGroup[]>;
export type AlarmGroupListResult = ApiResult<AlarmGroup[]>;
export type AlarmGroupResult = ApiResult<AlarmGroup>;
export type AlarmRuleResult = ApiResult<AlarmRule>;
export type AlarmRuleListResult = ApiResult<AlarmRule[]>;
export type AssignedAlarmGroupListResult = ApiResult<AssignedAlarmGroup[]>;
export type MessageTemplateListResult = ApiResult<MessageTemplate[]>;
export type MessageTemplateResult = ApiResult<MessageTemplate>;
export type ExportFileListResult = ApiResult<ExportFile[]>;
export type ExportFileResult = ApiResult<ExportFile>;
export type ImportConfigResult = ApiResult<ImportConfig>;
export type ImportConfigListResult = ApiResult<ImportConfig[]>;
export type GeoPolygoneResult = ApiResult<GeoPolygone>;
export type GeoPolygoneListResult = ApiResult<GeoPolygone[]>;
export type ExportFileStationResult = ApiResult<ExportFileStation>;
export type LoRaWanConfigListResult = ApiResult<LoRaWanConfig[]>;
export type LoRaWanConfigResult = ApiResult<LoRaWanConfig>;
export type SensorBoxCommandQueueEntryResult =
  ApiResult<SensorBoxCommandQueueEntry>;
export type SensorBoxCommandQueueResult = ApiResult<
  SensorBoxCommandQueueEntry[]
>;
export type AlarmActionResult = ApiResult<AlarmAction>;
export type AlarmActionListResult = ApiResult<AlarmAction[]>;
export type AlarmActionExecutionResult = ApiResult<AlarmActionExecution>;

export type AlarmChannelResult = ApiResult<AlarmChannel>;
export type AlarmChannelListResult = ApiResult<AlarmChannel[]>;


export interface BackendStatusResult {
  status: boolean;
  serviceName: string;
  dockerImageTag: string;
  subsystem: {
    'backend-database': {
      release: string;
      'maven-build-date': string;
    };
  };
}
