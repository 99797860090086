import {AbstractControl} from '@angular/forms';

export class PasswordValidators {

  // to deactivate password length check - set to 0
  public static MIN_PASSWORD_LENGTH = 10;

  // to deactivate numbers in password check - set to 0
  public static MIN_AMOUNT_NUMBERS = 2;

  // to deactivate small letters in password check - set to 0
  public static MIN_AMOUNT_SMALL_LETTERS = 2;

  // to deactivate Captial letters in password check - set to 0
  public static MIN_AMOUNT_CAPITAL_LETTERS = 2;

  public static passwordsMatching(control: AbstractControl) {
    const password = control.get('password').value;
    const repeated = control.get('repeatedPassword').value;

    if (password !== repeated) {
      return {passwordsNotMatching: true};
    } else {
      return null;
    }
  }

  public static passwordMatchesPolicies(control: AbstractControl) {
    const password = control.value;

    if (password === null
      || !PasswordValidators.passwordPolicyLength(password)
      || !PasswordValidators.passwordPolicyNumbers(password)
      || !PasswordValidators.passwordPolicySmallLetters(password)
      || !PasswordValidators.passwordPolicyCapitalLetters(password)) {
      return {passwordNotMatchingPolicies: true};
    } else {
      return null;
    }
  }

  static passwordPolicyLength(password: string) {
    return password.length >= this.MIN_PASSWORD_LENGTH;
  }

  static passwordPolicyNumbers(password: string) {
    const amountNumbers = (password.match(/[0-9]/g) || []).length;
    return amountNumbers >= this.MIN_AMOUNT_NUMBERS;
  }

  static passwordPolicySmallLetters(password: string) {
    const amountSmallLetters = (password.match(/[a-z]/g) || []).length;
    return amountSmallLetters >= this.MIN_AMOUNT_SMALL_LETTERS;
  }

  static passwordPolicyCapitalLetters(password: string) {
    const amountCapitalLetters = (password.match(/[A-Z]/g) || []).length;
    return amountCapitalLetters >= this.MIN_AMOUNT_CAPITAL_LETTERS;
  }
}
