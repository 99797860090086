import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';
import { SensorState } from '../../../../../state/noysee/sensor.state';
import { FrontendType } from '../../../../../state/noysee/models/sensor';

@Component({
  selector: 'app-values',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.scss'],
})
export class ValuesComponent {
  sensorBox$: Observable<SensorBox> = this.store.select(
    SensorState.currentSensor,
  );

  constructor(private store: Store) {}

  isWeatherStation(sensorBox: SensorBox): boolean {
    return sensorBox.frontendType === FrontendType.WEATHER;
  }
}
