<div class="title">
  <h1 *ngIf="!compact; else isCompact">
    {{ title | translate }}
  </h1>
  <ng-template #isCompact>
    <h2>
      {{ title | translate }}
    </h2>
  </ng-template>
  <span class="table-small"> ({{ dataLength || 0 }}) </span>
</div>
<div class="table-actions">
  <ng-container *ngFor="let action of tableActions">
    <button
      *ngIf="!action.hide"
      mat-flat-button
      [disabled]="action.disabled"
      class="btn-primary"
      (click)="action.action()"
    >
      {{ action.label | translate }}
    </button>
  </ng-container>
</div>
