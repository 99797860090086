import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sensor-chart-controls',
  templateUrl: './sensor-chart-controls.component.html',
  styleUrls: ['./sensor-chart-controls.component.scss'],
})
export class SensorChartControlsComponent {
  @Input()
  canExport?: boolean = false;

  @Input()
  delta: number = 0;

  @Output()
  export: EventEmitter<void> = new EventEmitter();

  @Output()
  changeDelta: EventEmitter<number> = new EventEmitter();

  constructor() {}

  deltaChange(delta: number): void {
    if (delta === this.delta) {
      return;
    }
    this.changeDelta.emit(delta);
  }
}
