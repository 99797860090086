import {Role} from './role.enum';
import {Customer} from './customerList';
import { LookupEntry } from './apiResults';

export enum UserState  {
  DELETED = -100,
  INVITED = -2,
  PENDING = -1,
  LOCKED = 0,
  ACTIVE = 1
}

export enum UserType {
  LOGIN = 'login',
  EXTERNAL = 'external',
  API_KEY = 'apiKey'
}


export class User {
  id: number;
  name: string;
  login: string;
  anrede: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  description: string;
  titel: string;
  role: Role;
  customer: Customer;
  customerAdmin: boolean;
  pending: boolean;
  invited: boolean;
  locked: boolean;
  lastLogin: Date;
  currentLogin: Date;
  ags: string;
  externalId: string;
  state: UserState;
  userType: UserType;
  key: string;

  sessionFeatures: SessionFeatures;
  
  forwardUser: LookupEntry; 
}

export class ByRole {
	guest: boolean;
	customerAdmin: boolean;
	superadmin: boolean;
}

export interface SessionFeatures {
 	// superadmin: boolean,
 	// customerAdmin: boolean,
 	byRole: ByRole, 
}

