<div class="weather-container ch-margin-bottom-24">
    <div class="today-info-header-row">
        <div class="today-info-header">
            <p *ngIf="isWeatherStation" appTranslate="sensor.information.weather-prediction"></p>
            <p *ngIf="!isWeatherStation" appTranslate="sensor.information.weather-data"></p>
        </div>
    </div>
    <div *ngIf="!isWeatherStation" class="row today-info-middle-row">
        <div class="col-7 current-date-details">
            <p *ngIf="dateString">{{dateString.substr(0, dateString.indexOf(' '))}}</p>
            <p *ngIf="dateString">{{dateString.substr(dateString.indexOf(' ') + 1)}}</p>
            <p *ngIf="date && timezone">
                {{date.getHours()}}:{{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}}
                    {{'sensor.information.units.time' | translate}}, {{timezone}} </p>
        </div>
        <div class="col-5 current-temperature">
            <p *ngIf="weatherCurrent">{{weatherCurrent.temp}}<span class="temperature-unit">°C</span></p>
            <img *ngIf="weatherCurrent" src="{{iconUrlPrefix}}{{weatherCurrent.weather[0].icon}}.png">
        </div>
    </div>

    <div *ngIf="!isWeatherStation" class="row today-info-bottom-row">
        <div class="col-4 temperature-min">
            <div *ngIf="weatherCurrent" class="today-info-value">
                {{weatherCurrent.tempMin}}{{'sensor.information.units.temperatur' | translate}}
            </div>
            <div class="today-info-category">
                <p appTranslate="sensor.information.values.min"></p>
            </div>
        </div>
        <div *ngIf="weatherCurrent" class="col-4 temperature-max">
            <div class="today-info-value">
                {{weatherCurrent.tempMax}}{{'sensor.information.units.temperatur' | translate}}
            </div>
            <div class="today-info-category">
                <p appTranslate="sensor.information.values.max"></p>
            </div>
        </div>
        <div class="col-4 wind">
            <div *ngIf="weatherCurrent" class="today-info-value">
                <p>{{weatherCurrent.wind_speed}} {{'sensor.information.units.speed' | translate}}</p>
            </div>
            <div class="today-info-category">
                <p appTranslate="sensor.information.values.wind"></p>
            </div>
        </div>
    </div>
</div>
<div class="row toggle-forecast-row">
    <div class="col-6 day-view">
        <span [class.selected]="weatherView === 'DAY'" class="controls-field controls-day-field"
            (click)="weatherView = 'DAY'" appTranslate="sensor.information.values.today"></span>
    </div>
    <div class="col-6 week-view">
        <span [class.selected]="weatherView === 'WEEK'" class="controls-field controls-week-field"
            (click)="weatherView = 'WEEK'" appTranslate="sensor.information.values.forecast"></span>
    </div>
</div>
<div *ngIf="(weatherToday && weatherView === 'DAY') || (weatherNextDays && weatherView === 'WEEK')"
    class="col-12 forecast-col">
    <div class="forecast-nav-icon icon-prev" (click)="navigateBackward()">
        <img src="../../../../../../assets/img/chevron-left-solid-black.svg" alt="Vorheriges Wetter" />
    </div>
    <div *ngIf="weatherView === 'DAY'" id="forecast-container" class="forecast-slider">
        <div *ngFor="let weather of weatherToday; let i = index"
            class="weather-detail-container weather-index-{{i}}{{weather['current']}}">
            <div class="weather-date">{{weather.dt}}</div>
            <img class="weather-icon" src="{{iconUrlPrefix}}{{weather.weather[0].icon}}@2x.png" alt="Wetter Icon">
            <div class="weather-temperature">{{weather.temp}}{{'sensor.information.units.temperatur' | translate}}</div>
        </div>
    </div>
    <div *ngIf="weatherView === 'WEEK'" id="forecast-container" class="forecast-slider">
        <div *ngFor="let weather of weatherNextDays; let i = index"
            class="weather-detail-container weather-index-{{i}}{{weather['current']}}">
            <div class="weather-date">{{weather.dt}}</div>
            <img class="weather-icon" src="{{iconUrlPrefix}}{{weather.weather[0].icon}}@2x.png" alt="Wetter Icon">
            <div class="weather-temperature">{{weather.temp.avg}}{{'sensor.information.units.temperatur' | translate}}
            </div>
        </div>
    </div>
    <div class="forecast-nav-icon icon-next" (click)="navigateForward()">
        <img src="../../../../../../assets/img/chevron-right-solid-black.svg" alt="Zukünftiges Wetter" />
    </div>
</div>