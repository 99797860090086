<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 *ngIf="mode === 'add'">Sensor anlegen</h1>
      <h1 *ngIf="mode === 'edit'">Sensor bearbeiten</h1>
    </div>
  </div>
  <form class="form" [formGroup]="crudNoyseeDeviceForm" (ngSubmit)="saveNoyseeDevice()">
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">Name *</label>
          <input id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid
                            && f.name.errors.required" class="error-message ch-margin-top-4">Bitte Namen angeben
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" (click)="saveNoyseeDevice()">Speichern</button>
      </div>
      <div class="col-3">
        <button *ngIf="mode === 'edit'" class="btn-secondary" (click)="backToSensorDetail()">Abbrechen</button>
        <button *ngIf="mode === 'add'" class="btn-secondary" [routerLink]="['/dashboard', 'sensors', 'overview']">Abbrechen</button>
      </div>
    </div>
  </form>
</div>
