<div *ngIf="sensor$ | async as sensorDetails" id="sensor-microsite">
  <div
    *ngIf="!hideBack"
    class="back controls-field controls-day-field"
    onclick="history.back()"
  >
    <span class="back">Zurück</span>
  </div>
  <app-values></app-values>
  <div *ngIf="!hideExtra" class="row">
    <div class="col-md-4 col-12">
      <app-sensors-image
        [sensorId]="sensorDetails.image ? sensorDetails.id : undefined"
      ></app-sensors-image>
    </div>
    <div class="col-md-8 col-12">
      <app-sensors-map [currentSensor]="sensorDetails"></app-sensors-map>
    </div>
  </div>
</div>
