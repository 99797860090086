import { Component, Input } from '@angular/core';
import { MapLayerComponent } from '../layer.util';
import { LayerType } from 'src/app/state/dashboard/models/mapLayer';
import { FrontendType, Sensor } from 'src/app/state/noysee/models/sensor';
import { LatLng, LayerGroup, Map } from 'leaflet';
import { map } from 'rxjs/operators';
import { LeafletHelper } from 'src/app/helpers/leaflet.helper';
import { Store } from '@ngxs/store';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'leaflet-precip-sensor-layer',
  template: `<div [leafletLayer]="layer"></div>`,
})
export class PrecipSensorsComponent extends MapLayerComponent<
  Partial<Sensor>
> {
  @Input() set leafletMap(map: Map) {
    this.registerMapAndFilterChange(map, this.update);
  }

  sensorSubscription: Subscription;
  sensors$ = this.store.select(
    SensorState.mapListForType(FrontendType.PRECIP),
  );

  constructor(
    store: Store,
    private leafletHelper: LeafletHelper,
  ) {
    super(store, LayerType.FRONTEND, FrontendType.PRECIP);
  }

  activate() {
    return this.sensors$.pipe(
      map((sensors) => {
        return new LayerGroup<Partial<Sensor>>(
          sensors.map((sensor) =>
            this.leafletHelper.generateSensorMarker(sensor, true, () =>
              this.leafletHelper.openSensorDetailViewFor(
                sensor.id,
                new LatLng(+sensor.lat, +sensor.lon),
              ),
            ),
          ),
        );
      }),
    );
  }

  update(map: Map): Observable<any> {
    return this.store.dispatch(new SensorActions.FetchForMap(map.getBounds()));
  }
}
