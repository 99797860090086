import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService, FeatureSet } from '../../../../../services/authentication.service';
import { UsersService } from '../../../../../services/users.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
	DataTableOptions,
	GridState,
	TitleSize,
	TableActionType,
} from 'src/app/shared/data-table/data-table.model';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { ActionStateType, AlarmAction } from '@app/state/noysee/models/alarmAction';
import { ExecuteAlarmActionModalComponent } from '@app/view/dashboard/alarmActions/execute-alarmAction-modal/execute-alarmAction-modal.component';
import { AlarmActionService } from '@app/state/noysee/alarmAction.service';
import { ToastHelper } from '@app/helpers/toast.helper';
import { ApprovalDialogHelper } from '@app/helpers/approval-dialog.helper';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';
import { AddSensorBoxAlarmActionComponent } from '../addAlarmAction-modal/addAlarmAction.component';
import { AlarmChannelService } from '@app/state/noysee/alarmChannel.service';

@Component({
	selector: 'app-alarmActions',
	templateUrl: './alarmActions.component.html',
	styleUrls: ['./alarmActions.component.scss'],
})
export class SensorBoxAlarmActionsComponent
	implements OnInit, OnDestroy {
	@Input()
	set sensor(sensor$: Observable<SensorBox>) {
		this.sensorSubscription?.unsubscribe();
		this.sensorSubscription = sensor$.subscribe(async (sensor) => {
			if (sensor) {
				this.currentSensor = sensor;

				this.sensorName = sensor.name;
				this.currentSensorId = sensor.id;
				this.alarmReceiverVisible = (sensor as any).alarmReceiverVisible;
				this.alarmReceiverEditable = (sensor as any).alarmReceiverEditable;

				this.reloadAlarmActions();
			}
		});
	}

	currentSensorId: number;
	currentSensor: SensorBox;
	currentRoleId: number;

	parent: string;

	alarmReceiverVisible: boolean;
	alarmReceiverEditable: boolean;
	isSuperAdmin: boolean;
	isAdmin: boolean;
	sensorName: string;
	sensorSubscription: Subscription;

	alarmActionList: AlarmAction[];
	dataTableOptions: DataTableOptions<AlarmAction>;
	gridState: GridState = null;
	@ViewChild(DataTableComponent)
	dataTable: DataTableComponent;

	featureSet$: Observable<FeatureSet> = this.authenticationService.getFeatureSet();
	featureSetSubscription: Subscription;

	grantedExecute: boolean; // = this.authenticationService.hasPrivielge$('box.alarmAction','write');
	grantedManage: boolean;  // = this.authenticationService.hasPrivielge$('box.alarmAction','manage');

	constructor(
		private applicationContextService: ApplicationContextService,
		private authenticationService: AuthenticationService,
		private approvalDialogHelper: ApprovalDialogHelper,
		private toastHelper: ToastHelper,
		private matDialog: MatDialog,
		private usersService: UsersService,
		private dataTableStateService: DataTableStateService,
		private alarmActionService: AlarmActionService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.currentSensorId = null;
		this.alarmReceiverVisible = false;
		this.alarmReceiverEditable = false;
		this.isSuperAdmin = this.authenticationService.isSuperAdmin();
		this.isAdmin = this.authenticationService.isAdmin();
	}

	ngOnInit(): void {
		this.featureSetSubscription = this.featureSet$.subscribe(featureSet => {
			this.grantedExecute = featureSet.hasPrivilege('box.alarmAction', 'write');
			this.grantedManage = featureSet.hasPrivilege('box.alarmAction', 'manage');
			this.initTable();
		});

	}

	ngOnDestroy() {
		this.sensorSubscription?.unsubscribe();
		this.featureSetSubscription?.unsubscribe();
	}

	initTable(): void {
		this.dataTableOptions = {
			title: 'alarmAction.table.headerLabel',
			titleSize: TitleSize.H1,
			filter: true,
			tableActions: [
				{
					label: 'alarmAction.table.actions.add',
					rule: () => this.grantedManage,
					actionFn: () => this.addAlarmAction(),
				},
			],
			rowActions: {
				type: TableActionType.MENU,
				actionList: [
					{
						label: 'alarmAction.table.actions.view',
						icon: 'app-view',
						actionFn: (assignedAlarmGroup: AlarmAction) =>
							this.showAlarmAction(assignedAlarmGroup),
					},
					{
						label: 'alarmAction.table.actions.edit',
						icon: 'app-pencil',
						actionFn: (assignedAlarmGroup: AlarmAction) =>
							this.editAlarmAction(assignedAlarmGroup),
						rule: () => this.grantedManage,
					},
					{
						label: 'alarmAction.table.actions.execute',
						icon: 'cell_tower',
						dataRule: (alarmAction: AlarmAction) => alarmAction.executable && this.grantedExecute,
						actionFn: (alarmAction: AlarmAction) =>
							this.executeAlarmAction(alarmAction),
					},
					{
						label: 'alarmAction.table.actions.delete',
						icon: 'app-delete',
						rule: () => this.grantedManage,
						actionFn: (assignedAlarmGroup: AlarmAction) =>
							this.removeAlarmAction(assignedAlarmGroup.id),
					},
				],
			},
			rowStyleFn: (action: AlarmAction) => {
				return {
					'background-color': this.getRowColor(action),
					'border': 'solid 1.5px white'
				};
			},
			columnDef: [
				{
					key: 'id',
					displayName: 'alarmAction.table.columns.id',
					width: '5%',
					sortable: true,
					hidden: !this.isSuperAdmin,
				},
				{
					key: 'alarmLevel',
					displayName: 'alarmAction.table.columns.alarmLevel',
					width: '15%',
					sortable: true,
					valueTransform: (assignedAlarmGroup: AlarmAction) =>
						`alarmAction.table.alarmLevel.${assignedAlarmGroup.alarmLevel}`,
				},
				{
					key: 'name',
					displayName: 'alarmAction.table.columns.name',
					width: this.isSuperAdmin ? '60%' : '65%',
					sortable: true,
					valueTransform: (assignedAlarmGroup: AlarmAction) =>
						`${assignedAlarmGroup.name}`,
					styleFn: (action: AlarmAction) => {
						return {
							cursor: 'pointer',
							color: '#0e0ec4',
							'font-weight': 'bold',
						};
					},
					actionFn: (action: AlarmAction) => {
						this.showAlarmAction(action);
					}
				},
				{
					key: 'type',
					displayName: 'alarmAction.table.columns.type',
					width: '10%',
					valueTransform: (alarmAction: AlarmAction) =>
						`alarmAction.type.${alarmAction.actionType}`,
				},
				{
					key: 'state',
					displayName: 'alarmAction.table.columns.state',
					width: '20%',
					valueTransform: (alarmAction: AlarmAction) =>
						`alarmAction.state.${alarmAction.state}`,

					styleFn: (alarmAction: AlarmAction) => {
						if (this.grantedExecute && alarmAction.executable) {
							return {
								cursor: 'pointer',
								color: '#0e0ec4',
								'font-weight': 'bold',
							}
						} else {
							return {
							};
						}
					},
					actionFn: (alarmAction: AlarmAction) => {
						if (this.grantedExecute) {
							if (alarmAction.state == ActionStateType.available) {
								this.executeAlarmAction(alarmAction);
							} else if (alarmAction.state == ActionStateType.started) {
								this.executeCloseAlarmAction(alarmAction);
							}
						}
					}

				},
			],
		};

	}

	public getRowColor(action: AlarmAction): string {
		switch (action.state) {
			case ActionStateType.started:
				return "#FFA0A0";
			case ActionStateType.startPending:
				return "khaki";
			case ActionStateType.available:
				return "#D0FFD0";
			case ActionStateType.idle:
				return "#D0D0D0";
			default:
				return "";
		}
	}

	async reloadAlarmActions() {
		this.alarmActionList =
			await this.alarmActionService.getSensorBoxAlarmActions(
				this.currentSensorId,
			);
	}

	executeAlarmAction(alarmAction: AlarmAction) {
		const _this = this;
		const dialogOptions: MatDialogConfig = {
			data: {
				mode: 'start',
				parentType: 'sensorBox',
				alarmAction: alarmAction,
				sensorBox: this.currentSensor,
			},
			minWidth: 600,
			maxWidth: 800,
		};
		this.matDialog
			.open(ExecuteAlarmActionModalComponent, dialogOptions)
			.afterClosed()
			.toPromise()
			.then((result) => {
				_this.reloadAlarmActions();
			});
	}

	executeCloseAlarmAction(alarmAction: AlarmAction) {
		const _this = this;
		const dialogOptions: MatDialogConfig = {
			data: {
				mode: 'end',
				parentType: 'sensorBox',
				alarmAction: alarmAction,
				sensorBox: this.currentSensor,
			},
			minWidth: 600,
			maxWidth: 800,
		};
		this.matDialog
			.open(ExecuteAlarmActionModalComponent, dialogOptions)
			.afterClosed()
			.toPromise()
			.then((result) => {
				_this.reloadAlarmActions();
			});
	}

	removeAlarmAction(id: number) {
		const _this = this;
		const dialogRef = this.approvalDialogHelper.openApprovalDialog(
			'Möchten Sie diese Aktion wirklich löschen?',
		);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				try {
					_this.alarmActionService.delete(id).then((result) => {
						this.toastHelper.openSnackBar(
							'Aktion erfolgreich gelöscht!',
							'SUCCESS',
						);
						this.reloadAlarmActions();
					});
				} catch (e) {
					this.toastHelper.openCrudDeleteError();
				}
			}
		});
	}

	showAlarmAction(alarmAction: AlarmAction) {
		this.showAlarmActionDialog(alarmAction, 'view').then((result) => {
			this.reloadAlarmActions();
		});
	}

	addAlarmAction() {
		this.showAlarmActionDialog(null, 'add').then((result) => {
			this.reloadAlarmActions();
		});
	}

	editAlarmAction(action: AlarmAction) {
		this.showAlarmActionDialog(action, 'edit').then((result) => {
			this.reloadAlarmActions();
		});
	}

	showAlarmActionDialog(action: AlarmAction, mode: string): Promise<AlarmAction> {
		const dialogOptions: MatDialogConfig = {
			data: {
				parentType: 'sensorBox',
				parentId: this.currentSensorId,
				parentName: this.sensorName,
				id: action ? action.id : null,
				mode: mode,
			},
			maxWidth: 600,
		};
		return this.matDialog
			.open(AddSensorBoxAlarmActionComponent, dialogOptions)
			.afterClosed()
			.toPromise();
	}

}
