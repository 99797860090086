<div class="ab-list-component">
	<div class="options">
		<div class="header">
			<span>Verfügbar</span>
		</div>
		<div class="filter">
			<p>
				<input id="filter-id" [(ngModel)]="availableFilter" maxlength="40"
					(ngModelChange)="updateAvailableOptions()" class="field"/>
				<a (click)="availableFilter='';updateAvailableOptions()"><mat-icon
						class="icon">backspace</mat-icon></a>
			</p>
		</div>
		<div class="entries-list">
			<div class="item" *ngFor="let item of availableOptions">
				<p>{{item[descriptiveField]}}<a (click)="addItem(item)"><mat-icon
							class="inline-icon">person_add</mat-icon></a></p>
			</div>
		</div>
	</div>
	<div class="options">
		<div class="header">
			<span>Ausgewählt</span>
		</div>
		<div class="entries-list">
			<div class="item" *ngFor="let item of value">
				<p>{{item[descriptiveField]}} <a (click)="removeItem(item)"><mat-icon
							class="inline-icon">person_remove</mat-icon></a></p>
			</div>
		</div>
	</div>
</div>