<div class="mat-dialog-title">
  <p><strong>Gerät {{noyseeSN}} aktivieren</strong></p>
</div>

<div class="form-field">
  <label for="noyseeSN">Seriennummer</label>
  <input id="noyseeSN" [(ngModel)]="noyseeSN" disabled="true" />
</div>

<div class="form-field" [class.error]="false">
  <label for="input-customer">SensorBox Template</label>
  <select [(ngModel)]="sensorBoxTemplateId">
    <option [value]="null">-</option>
    <option [ngValue]="entry.id" *ngFor="let entry of templateList">{{entry.name}} (ID: {{entry.id}})</option>
  </select>
</div>

<div class="form-field ch-margin-top-24" [class.error]="false">
  <label for="input-customer">Kunde</label>
  <select [(ngModel)]="customerId">
    <option [value]="null">-</option>
    <option [ngValue]="entry.id" *ngFor="let entry of customerList">{{entry.name}} (ID: {{entry.id}})</option>
  </select>
</div>

<div class="form-field ch-margin-top-24">
  <label for="name">Standortname</label>
  <input id="name" [(ngModel)]="name" />
</div>

<div class="row ">
  <div class="col-6">
    <div class="form-field ">
      <label for="name">Länge</label>
      <input id="name" [(ngModel)]="lat" />
    </div>
  </div>
  <div class="col-6">
    <div class="form-field">
      <label for="name">Breite</label>
      <input id="name" [(ngModel)]="lon" />
    </div>
  </div>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="installAndClose( true)">Speichern und aktivieren</button>
  <button mat-flat-button class="btn-secondary" (click)="installAndClose( false)">Speichern</button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>