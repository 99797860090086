import { Component } from '@angular/core';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams } from 'ag-grid-community';
import { CustomExportFloatingFilter } from '../util/interfaces';

export type SelectionFilterOption = {
  i18nLabel: string;
  value: any;
};

export interface SelectionFilterParams extends IFloatingFilterParams {
  options: SelectionFilterOption[];
}

@Component({
  template: `
    <select
      [(ngModel)]="currentValue"
      (ngModelChange)="filter()"
      class="filter-select"
    >
      <option value="" translate="general.noFilter"></option>
      <option
        *ngFor="let option of params.options"
        [value]="option.value"
        [translate]="option.i18nLabel"
      ></option>
    </select>
  `,
  styles: [':host { width: 100% }'],
})
export class SelectionFloatingFilter
  implements IFloatingFilterAngularComp, CustomExportFloatingFilter
{
  currentValue = '';
  params: SelectionFilterParams;

  agInit(params: SelectionFilterParams): void {
    this.params = params;
  }

  onParentModelChanged(parentModel: any): void {
    if (!parentModel) return;

    this.currentValue = parentModel.filter;
  }

  pdfExportCallback(
    params: SelectionFilterParams,
    filterValue: string
  ): string {
    const option = params.options.find(({ value }) => value === filterValue);
    return option?.i18nLabel || '';
  }

  filter() {
    this.params.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged(
        this.currentValue ? 'equals' : null,
        this.currentValue || null
      );
    });
  }
}
