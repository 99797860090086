import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { ApplicationContextService } from './application-context.service';
import { Topic, WebsocketMessage } from '../models/websocketMessageData';
import { GuiState } from '@app/state/dashboard/gui.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _openNotificationSource: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);

  constructor(
    private http: HttpClient,
    private store: Store,
    private applicationContextService: ApplicationContextService,
    private websocketHelper: WebsocketService,
  ) {
    this.websocketHelper.dashboardWebSocket.subscribe((socket) => {
      if (!socket) {
        this._openNotificationSource.next([]);
        return;
      }
      socket.addEventListener('open', () => {
        this.updateOpenNotifications();
      });
      socket.addEventListener('message', (msg) => {
        const message = new WebsocketMessage(msg.data);
        if (message.topic === Topic.NOTIFICATIONS_CHANGED) {
          this.updateOpenNotifications();
        }
      });
    });

    // ToDo wo muss dieser code hin, damit als superadmin von anfang auf die änderungen
    // der dropdownliste für demn kunden funktioniert
    // bisher braucht es einen refresh
    this.store.select(GuiState.selectedCustomer).subscribe(() => {
      this.updateOpenNotifications();
    });
  }

  updateOpenNotifications(): void {
    let focusCustomerId = this.store.selectSnapshot(
      GuiState.selectedCustomer,
    )?.id;

    if (focusCustomerId) {
      this.http
        .get<any>(
          `${environment.dashboardApiUrl}/notification/list?customerId=` +
            focusCustomerId,
        )
        .pipe(first((notifications) => !!notifications))
        .subscribe((notifications) => {
          if (!!notifications.data) {
            this._openNotificationSource.next(notifications.data);
          }
        });
    }
  }

  // Returns an array that has two sub-arrays 'noysee' and 'barriersystems' that have to be concatenated if messages from both products
  // are required
  get open(): Observable<any[]> {
    return this._openNotificationSource.asObservable();
  }
}
