<div id="general-filter" class="vert-menu">
  <div id="filter-sensors" class="filter-entry active">
    <img alt="enbw" src="../../../../../assets/img/filter-filled-circle.svg" />
    <div class="filter-submenu">
      <div
        class="filter-submenu-entry"
        [class.inactive]="!(allActive$(LayerType.FRONTEND) | async)"
        (click)="activateAll(LayerType.FRONTEND)"
      >
        <span>Alle</span>
      </div>
      <ng-container
        *ngFor="let layer of getMapLayersForType$(LayerType.FRONTEND) | async"
      >
        <div
          *ngIf="isActive$(layer) | async as active; else inactive"
          class="filter-submenu-entry single-entry"
          (click)="deactivate(layer)"
        >
          <div class="icon-wrapper">
            <mat-icon
              [svgIcon]="layer.subType"
              [matTooltip]="'map.filters.sensors.' + layer.subType | translate"
              matTooltipPosition="right"
            ></mat-icon>
          </div>
        </div>
        <ng-template #inactive>
          <div
            class="filter-submenu-entry single-entry inactive"
            (click)="activate(layer)"
          >
            <div class="icon-wrapper">
              <mat-icon
                [matTooltip]="
                  'map.filters.sensors.' + layer.subType | translate
                "
                matTooltipPosition="right"
                [svgIcon]="layer.subType"
              ></mat-icon>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <div
        class="filter-submenu-entry"
        [class.inactive]="!(noneActive$(LayerType.FRONTEND) | async)"
        (click)="deactivateAll(LayerType.FRONTEND)"
      >
        <span>Keine</span>
      </div>
    </div>
  </div>
  <div id="filter-map" class="filter-entry active">
    <mat-icon>map</mat-icon>
    <div class="filter-submenu">
      <div
        class="filter-submenu-entry"
        [class.inactive]="!(allActive$(LayerType.TOPO) | async)"
        (click)="activateAll(LayerType.TOPO)"
      >
        <span>Alle</span>
      </div>
      <ng-container
        *ngFor="let layer of getMapLayersForType$(LayerType.TOPO) | async"
      >
        <div
          *ngIf="isActive$(layer) | async as active; else inactive"
          class="filter-submenu-entry single-entry"
          (click)="deactivate(layer)"
        >
          <ng-container [ngSwitch]="layer.subType">
            <mat-icon
              *ngSwitchCase="TopoType.WATERWAYS"
              [matTooltip]="
                'map.filters.mapLayers.' + layer.subType | translate
              "
              matTooltipPosition="right"
              >water</mat-icon
            >
            <mat-icon
              *ngSwitchCase="TopoType.KACHELMANN_RAIN"
              [matTooltip]="
                'map.filters.mapLayers.' + layer.subType | translate
              "
              matTooltipPosition="right"
              svgIcon="rainy"
            ></mat-icon>
          </ng-container>
        </div>
        <ng-template #inactive>
          <div
            class="filter-submenu-entry single-entry inactive"
            (click)="activate(layer)"
          >
            <ng-container [ngSwitch]="layer.subType">
              <mat-icon
                *ngSwitchCase="TopoType.WATERWAYS"
                [matTooltip]="
                  'map.filters.mapLayers.' + layer.subType | translate
                "
                matTooltipPosition="right"
                >water</mat-icon
              >
              <mat-icon
                *ngSwitchCase="TopoType.KACHELMANN_RAIN"
                [matTooltip]="
                  'map.filters.mapLayers.' + layer.subType | translate
                "
                matTooltipPosition="right"
                svgIcon="rainy"
              ></mat-icon>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
      <div
        class="filter-submenu-entry"
        [class.inactive]="!(noneActive$(LayerType.TOPO) | async)"
        (click)="deactivateAll(LayerType.TOPO)"
      >
        <span>Keine</span>
      </div>
    </div>
  </div>
</div>
