import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent {
  loadedImage: string = '';
  title: string;
  croppedImage: string = '';

  constructor(
    private dialogRef: MatDialogRef<ImageEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      image: string;
      title: string;
    },
  ) {
    this.loadedImage = this.data.image;
    this.title = this.data.title;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  confirm() {
    this.dialogRef.close(this.croppedImage);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
