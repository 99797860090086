import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SensorActions } from 'src/app/state/noysee/sensor.action';

@Component({
  selector: 'app-history-micro',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  selectedSensor: string = this.activatedRoute.snapshot.queryParams.sensor;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Set sensor ID
    this.store.dispatch(
      new SensorActions.Select(
        parseInt(this.activatedRoute.snapshot.params['uuid'], 10),
      ),
    );
  }
}
