import {Injectable} from '@angular/core';
import {MessageTemplateListResult, MessageTemplateResult} from '../models/apiResults';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {MessageTemplate} from '../models/messageTemplate';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {

  constructor(private http: HttpClient) {
  }

  getList(): Promise<MessageTemplate[]> {
    return this.http.get<MessageTemplateListResult>(`${environment.dashboardApiUrl}/messageTemplate/list`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }

  delete(id: number): Promise<MessageTemplate> {
    return this.http.delete<MessageTemplateResult>(`${environment.dashboardApiUrl}/messageTemplate/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  load(id: number): Promise<MessageTemplate> {
    return this.http.get<MessageTemplateResult>(`${environment.dashboardApiUrl}/messageTemplate/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  save(messageTemplate: MessageTemplate): Promise<MessageTemplate> {
    return this.http.put<MessageTemplateResult>(`${environment.dashboardApiUrl}/messageTemplate/detail`, messageTemplate)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}

