import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Warning } from 'src/app/models/warningBroker';
import { GridState } from 'src/app/shared/data-table/data-table.model';
import { ToastHelper } from 'src/app/helpers/toast.helper';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { WarningBrokerService } from 'src/app/services/warningBroker.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';

@Component({
  selector: 'app-warning-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class WarningDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  warningDetail: Warning;

  mode: String;

  ctx: GridState = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastHelper: ToastHelper,
    private dataTableStateService: DataTableStateService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private warningBrokerService: WarningBrokerService) {

    this.warningDetail = new Warning();

  }

  ngOnInit(): void {
    const ctx = this.router.routerState.snapshot.root.queryParams['ctx'];
    if (ctx) {
      this.ctx = this.dataTableStateService.deserializeState(ctx);
    }

    const url = this.route.snapshot.params;
    this.warningBrokerService.getWarningDetail(url.id).then(result => {
      this.warningDetail = result;
    });

  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  noOp() {
  }

  getDateString(date: Date) {
    return date ? moment(date).format('DD.MM.YYYY HH:mm') : '-';
  }

  goBack() {
    let navRoute = ['/dashboard', 'warningBroker', 'warnings', 'activeWarnings'];
    if (this.ctx?.url) {
      navRoute = [this.ctx?.url];
    }
    this.router.navigate(navRoute, { state: this.ctx });
  }

  cancelWarning(): void {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie diese Warnung wirklich beenden?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.warningBrokerService.cancelWarning(this.warningDetail.id).then(result => {
            this.warningBrokerService.getWarningDetail(this.warningDetail.id).then(result => {
              this.warningDetail = result;
            });

          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  deleteWarning(): void {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie die diese Warnung wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.warningBrokerService.deleteWarning(this.warningDetail.id).then(result => {
            this.toastHelper.openSnackBar("Warnung " + this.warningDetail.id + " gelöscht.", "SUCCESS");
            this.goBack();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }


}
