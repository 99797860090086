import { Injectable } from '@angular/core';
import { AlarmRule } from './models/alarmGroup';
import { AlarmRuleResult, AlarmRuleListResult } from '../../models/apiResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GuiState } from '../dashboard/gui.state';

@Injectable({
  providedIn: 'root',
})
export class AlarmRuleService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getList(): Promise<AlarmRule[]> {
    let id = this.store.selectSnapshot(GuiState.selectedCustomer).id;

    return this.http
      .get<AlarmRuleListResult>(
        `${environment.dashboardApiUrl}/alarmRule/list?customerId=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getDetail(id: number): Observable<AlarmRule> {
    return this.http
      .get<AlarmRuleResult>(
        `${environment.dashboardApiUrl}/alarmRule/detail?id=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      );
  }

  saveDetail(group: AlarmRule): Promise<AlarmRule> {
    return this.http
      .post<AlarmRuleResult>(
        `${environment.dashboardApiUrl}/alarmRule/detail`,
        group,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  delete(id: number): Promise<AlarmRule> {
    return this.http
      .delete<AlarmRuleResult>(
        `${environment.dashboardApiUrl}/alarmRule/detail?id=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  removeAlarmGroup(alarmRuleId: number, memberId: number): Promise<AlarmRule> {
    return this.http
      .delete<AlarmRuleResult>(
        `${environment.dashboardApiUrl}/alarmRule/alarmGroup/entry?ruleId=` +
          alarmRuleId +
          `&id=` +
          memberId,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  addAlarmGroup(
    alarmRuleId: number,
    alarmLevel: number,
    alarmGroupId: number,
  ): Promise<AlarmRule> {
    return this.http
      .post<AlarmRuleResult>(
        `${environment.dashboardApiUrl}/alarmRule/alarmGroup/entry`,
        {
          alarmRuleId,
          alarmLevel,
          active: true,
          alarmGroupId,
        },
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }
}
