import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && this.authenticationService.authenticatedUser) {

        // auto logout if 401 response returned from api
        this.authenticationService.logout();

        // DEBUG
        // console.log(this.router.url);
        // console.log(this.router.url.indexOf('/login'));

        if (!(this.router.url.indexOf('/login') === 0)) {
          location.reload();
        }
      }

      // const error = err.error.message || err.statusText;
      return throwError(err);
    }));
  }
}
