import { Firmware } from '../../../models/firmware';
import {
  LookupEntry,
  AlarmLevelStates,
  ApiResult,
} from '../../../models/apiResults';

export enum FrontendType {
  WATERLEVEL = 'waterlevel',
  WEATHER = 'weather',
  PRECIP_AREA = 'precipArea',
  PRECIP = 'precip',
}

export enum SensorBoxHardwareStates {
  PLANNED = -4,
  DISABLED = -3,
  DEFECTIVE = -2,
  ERROR = -1,
  NORMAL = 0,
}

export enum ComMode {
  KEEP_COMM_MODE = 0, // 000000b
  GSM_ONLY = 1, // 000001b use only GSM
  LORA_ONLY = 4, // 000100b use only LoRa
  LORA_FIRST = 6, // 000110b try LoRa first, use GSM as fallback
  GSM_FIRST = 9, // 001001b try GSM first, use LoRa as fallback
  NB_GSM = 18, // 010010b nbIOT first then GSM
  LORA_NB = 36, // 100100b LoRa first then NB
  ALL = 255, // Send All
}

export enum CommandEnum {
  OK = 0, // normal fortfahren default
  AGAIN = 1, // Messung wiederholen und erneut übertragen
  OTA = 2, // Trigger Over The Air Update via GSM
  CONFIG = 3, // Aktualisiere die Konfiguration via GSM
  DOWNSTREAM = 4, // Force DownStream packet via Lora
  LOCATE = 100, // Geografische Position erfassen und in einem zweiten DataUpstreamRequest melden
  SYSINFO = 101, // Anforderung der Systeminformationen via GSM
  DEEP_SLEEP = 102, // Sensor in den Lagermodus versetzen
  TIME = 103, // Zeitinformation neu zu beziehen (per GPS oder GSM)
}

export class Sensor {
  // Generated on creation
  id: number;
  createdAt: Date;
  updatedAt: Date;
  sensors: any;

  // Entered by User when creating
  name: string;
  address: string;
  lat: string;
  lon: string;
  datasourceId: number;
  datasource: string;
  normalValue: number;
  limit1: string | number;
  limit2: string | number;

  limitDelta: number;
  hysteresis: number;

  lastContact: Date;

  displayMinY: string | number;
  displayMaxY: string | number;
  validationMin: string | number;
  validationMax: string | number;
  maxGradientUp: string | number;
  maxGradientDown: string | number;

  image: string;
  primarySensor: string;
  secondarySensor: string;

  frontendType: FrontendType;

  // Accumulated or changed during use
  history: any[];
  future: any[];
  hardwareState: AlarmLevelStates;
  state: AlarmLevelStates;
  statusInfo: string;

  installedFirmware: Firmware;
  firmwareToInstall: Firmware;

  description: string;
  internalDescription: string;
  alarmReceiver: any[];
}

export class IoTSensorBoxSettings extends Sensor {
  id: number;
  uuid: string;
  datasource: string;

  hardwareState: number;

  // name: String;
  noyseeSN: string;

  imei: String;
  simICCID: string;

  lastContact: Date;

  loraAppSKey: string;
  loraAppeui: string;
  loraAppkey: string;
  loraDevAdr: string;
  loraNetSKey: string;
  loraMode: number;
  comMode: number;
  public: boolean;

  measurementInterval: number;
  aliveInterval: number;
  timeoutInterval: number;

  sensorBoxTemplate: LookupEntry;

  loraServer: LookupEntry;
  alarmCoolDownHours: number;

  authMethod: string;
  authSecret: string;
  authSecretAvailable: boolean;
}

export class IotSensorSettings {
  id: number;
  sensorType: number;
  typeName: String;

  sensorOffset: number;
  sensorScale: number;

  calcualtionType: string;
  offset: number;
  scale: number;

  active: boolean;
  prognosis: boolean;

  specificIotName: String;

  lastContact: Date;

  unit: string;
  iotUnit: string;

  limit1: number;
  limit2: number;
  limit3: number;
  limit4: number;
  limit5: number;
  limitDelta: number;
  hysteresis: number;
}

export class SensorBoxCommandQueueEntry {
  id: number;
  createTimestamp: Date;
  transmitTimestamp: Date;
  ackTimestamp: Date;
  sensorBox: LookupEntry;
  command: CommandEnum;
  status: number;
  statusText: string;
  commandText: string;
}

/*
Sensor Status:
public static final int SENSORSTATUS_LIMIT5 = 5; // ?
public static final int SENSORSTATUS_LIMIT4 = 4; // ?
public static final int SENSORSTATUS_LIMIT3 = 3; // ?
public static final int SENSORSTATUS_LIMIT2 = 2; // alarm
public static final int SENSORSTATUS_LIMIT1 = 1; // warnung
public static final int SENSORSTATUS_NORMAL = 0; // normal
public static final int SENSORSTATUS_ERROR = -1; // ?
public static final int SENSORSTATUS_SERVICE = -2; // ?
public static final int SENSORSTATUS_OFFLINE = -3; // ?
public static final int SENSORSTATUS_NEW = -4; // in planung
*/
