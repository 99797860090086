export enum AlertType {
	sensorBox ='sensorBox', 
	user ='user',
	userInvited ='userInvited',
	userRequest ='userRequest',
	dwd ='dwd',
	bbk ='bbk',
	persistent ='persistent',
	mowas ='mowas', 
	persistentAlert ='persistentAlert', 
	unknown ='unknown'
}

export enum NotificationState {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED'
}

export enum NotificationCategory {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export class Notification {
  uuid: number;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  category: NotificationCategory;
  state: NotificationState;
  comment: string;
  index: number | null;
  sensorBox: any;
  user: any;
  edit?: boolean;
  alertType?: AlertType;
}
