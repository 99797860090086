import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/messageTemplates.component';
import { MessageTemplateDetailComponent } from './detail/detail.component';

const menuEntries = [
  {
    icon: 'menu-icon-movements',
    path: 'values',
    tooltip: 'sensor.values.current'
  },
  {
    icon: 'menu-icon-schedule',
    path: 'history',
    tooltip: 'sensor.values.history',
    weatherStationOnly: true,
  },
  {
    icon: 'menu-icon-users',
    path: 'contacts',
    tooltip: 'sensor.contacts.headline'
  },
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'add',
    component: MessageTemplateDetailComponent,
    data: {
      mode: 'add'
    }
  },
  {
    path: ':id',
    children: [
      {
        path: 'edit',
        component: MessageTemplateDetailComponent,
        data: {
          mode: 'edit'
        }
      },
    ],

  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessageTemplatesRoutingModule {
}
