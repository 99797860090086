<div class="mat-dialog-title">
  <p><strong>{{ "alarmAction.executeModal.label."+mode | translate }}</strong></p>
</div>
<div class="form-field">
  <label for="actionName" appTranslate="alarmAction.executeModal.actionName"></label>
  <input id="actionName" [(ngModel)]="alarmAction.name" disabled="true"/>
</div>

<div class="form-field">
  <label for="sensorBoxName" appTranslate="alarmAction.executeModal.sensorBoxName"></label>
  <input id="sensorBoxName" [(ngModel)]="sensorBox.name" disabled="true"/>
</div>

<div class="form-field" *ngIf="superadmin">
  <label for="actionType" appTranslate="alarmAction.executeModal.actionType"></label>
  <input id="actionType" [(ngModel)]="alarmAction.actionType" disabled="true"/>
</div>

<div class="form-field" *ngIf="alarmAction.additionalAuth" [class.error]="authError">
  <label for="actionPassword" appTranslate="alarmAction.executeModal.password"></label>
  <input type="password" id="actionPassword" [(ngModel)]="password"/>
  <div
              *ngIf="authError"
              class="error-message ch-margin-top-4"
              [appTranslate]="'alarmAction.executeModal.error.'+errorMessage"
            ></div>
</div>

<mat-dialog-actions class="dialog-actions">
  <button *ngIf="mode=='start'" mat-flat-button class="btn-primary" (click)="startAction()"
    appTranslate="alarmAction.executeModal.actions.execute"></button>
  <button *ngIf="mode=='end'" mat-flat-button class="btn-primary" (click)="endAction()"
	  appTranslate="alarmAction.executeModal.actions.execute"></button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()"
    appTranslate="alarmAction.executeModal.actions.cancel"></button>
</mat-dialog-actions>