<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-6">
      <h1>IoT-Einstellungen ({{iotDetails.id}})</h1>
    </div>
    <div *ngIf="isSuperAdmin" class="col-6 button-col">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open group menu">
        <img src="../../../../../assets/img/three-dots-blue.svg" alt="Ausklappmenü" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="upgradeFirmware()">
          Firmware ersetzen ...
        </button>
        <button mat-menu-item (click)="replaceBox()">
          Gerät ersetzen ...
        </button>
        <button mat-menu-item (click)="showCommandQueue()">
          Command-Queue anzeigen
        </button>
        <button mat-menu-item (click)="scheduleSysInfo()">
          Kommando "SysInfo" senden
        </button>
        <button mat-menu-item (click)="scheduleCfg()">
          Kommando "Konfiguration" senden
        </button>
        <button mat-menu-item (click)="scheduleLocate()">
          Kommando "Ortung" senden
        </button>
        <button mat-menu-item (click)="scheduleOta()">
          Kommando "Firmware Upgrade" senden
        </button>
        <button mat-menu-item (click)="scheduleDeepSleep()">
          Kommando "DEEP-SLEEP" senden ...
        </button>
      </mat-menu>
    </div>
  </div>
  <form class="form" [formGroup]="adminBoxForm" (ngSubmit)="saveSensorBox()">
    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field" [class.error]="f.name.invalid">
          <label for="input-name">Interne ID</label>
          <input id="input-name" disabled="true" [value]="iotDetails.id">
        </div>
      </div>
      <div class="col-4">
        <div class="form-field" [class.error]="f.sensorBoxTemplateName.invalid">
          <label for="input-sensorBoxTemplateName">SensorBoxTemplate</label>
          <input id="input-sensorBoxTemplateName" disabled="true"
            [value]="iotDetails.sensorBoxTemplate?.name +' (ID: '+iotDetails.sensorBoxTemplate?.id+')'">
        </div>
      </div>

      <div class="col-3">
        <div class="form-field">
          <label for="input-hardwareState">Hardwarestatus</label>
          <select [attr.disabled]="null" id="select-hardwareState" formControlName="hardwareState">
            <option [value]="SensorBoxHardwareStates.NORMAL">
              {{'sensor.state.'+SensorBoxHardwareStates.NORMAL | translate}}</option>
            <option [value]="SensorBoxHardwareStates.ERROR">
              {{'sensor.state.'+SensorBoxHardwareStates.ERROR | translate}}</option>
            <option [value]="SensorBoxHardwareStates.DEFECTIVE">
              {{'sensor.state.'+SensorBoxHardwareStates.DEFECTIVE | translate}}</option>
            <option [value]="SensorBoxHardwareStates.DISABLED">
              {{'sensor.state.'+SensorBoxHardwareStates.DISABLED | translate}}</option>
            <option [value]="SensorBoxHardwareStates.PLANNED">
              {{'sensor.state.'+SensorBoxHardwareStates.PLANNED | translate}}</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.name.invalid">
          <label for="input-name">Name *</label>
          <input id="input-name" formControlName="name">
          <div *ngIf="f.name.invalid
                            && f.name.errors.required" class="error-message ch-margin-top-4">Bitte Namen angeben
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-uuid">UUID</label>
          <input id="input-uuid" formControlName="uuid">
          <div *ngIf="f.uuid.invalid
                            && f.uuid.errors.required" class="error-message ch-margin-top-4">Bitte UUID angeben
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="input-datasource">Quelle</label>
          <input id="input-datasource" formControlName="datasource">
          <div *ngIf="f.datasource.invalid
                            && f.datasource.errors.required" class="error-message ch-margin-top-4">
          </div>
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.noyseeSN.invalid">
          <label for="input-noyseeSN">Seriennummer *</label>
          <div>
            <input placeholder="" id="input-noyseeSN" formControlName="noyseeSN">
            <a (click)="gotoNoyseeDevice( iotDetails.noyseeSN)" class="btn-link">
              <mat-icon class="material-icons-outline">launch</mat-icon>
            </a>
          </div>
          <div *ngIf="f.noyseeSN.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-installedFirmware">Installierte Firmware</label>
          <input id="input-installedFirmware" formControlName="installedFirmware">
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="input-firmwareToInstall">geplante Firmware</label>
          <input id="input-firmwareToInstall" formControlName="firmwareToInstall">
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-comMode">Kommunikationsmodus</label>
          <select [attr.disabled]="null" id="select-comMode" formControlName="comMode">
            <option [value]="ComMode.KEEP_COMM_MODE">Beibehalten/Default</option>
            <option [value]="ComMode.GSM_ONLY">GSM/LTE</option>
            <option [value]="ComMode.LORA_ONLY">LoRaWAN</option>
            <option [value]="ComMode.GSM_FIRST">GSM/LTE - LoRaWAN</option>
            <option [value]="ComMode.LORA_FIRST">LoRaWAN - GSM/LTE</option>
            <option [value]="ComMode.NB_GSM">NB-IoT - GSM/LTE</option>
            <option [value]="ComMode.ALL">Alle</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="input-loraMode">LoRaWAN Betriebsart</label>
          <select [attr.disabled]="null" id="select-loraMode" formControlName="loraMode">
            <option [value]="null">-</option>
            <option value="OTAA">OTAA</option>
            <option value="ABP">ABP</option>
            <option value="OFF">OFF</option>
          </select>
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraServer.invalid">
          <label for="input-loraServer">LoRaWAN Konfiguration</label>
          <select [attr.disabled]="null" id="select-loraServer" formControlName="loraServer">
            <option [ngValue]="null" selected="true">-</option>
            <option [ngValue]="entry.id" *ngFor="let entry of loraConfigList">{{entry.name}}
              ({{entry.id}})</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.simICCID.invalid">
          <label for="input-simICCID">SIM-Karte</label>
          <input placeholder="" id="input-simICCID" formControlName="simICCID">
          <div *ngIf="f.simICCID.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.imei.invalid">
          <label for="input-imei">IMEI</label>
          <input placeholder="" id="input-imei" formControlName="imei">
          <div *ngIf="f.imei.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraAppkey.invalid">
          <label for="input-loraAppkey">Lora AppKey</label>
          <input placeholder="" id="input-loraAppkey" formControlName="loraAppkey">
          <div *ngIf="f.loraAppkey.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.loraAppeui.invalid">
          <label for="input-loraAppeui">Lora AppEUI</label>
          <input placeholder="" id="input-loraAppeui" formControlName="loraAppeui" (change)="maybePredefined()">
          <div *ngIf="f.loraAppeui.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>

      <div class="col-4 role">
        <div class="form-field">
          <label for="select-predefinedAppEui">Predefined AppEUIs</label>
          <select [attr.disabled]="null" id="select-predefinedAppEui" formControlName="predefinedAppEui"
            (change)="chooseAppEui()">
            <option selected disabled hidden>Auswählen...</option>
            <option value="4865EEB04E4F5901">NOYSEE Premium</option>
            <option value="4865EEB04E4F5903">NOYSEE ECO</option>
            <option value="4865EEB04E4F5904">NOYSEE Wetterstation</option>
            <option value="4865EEB04E4F5902">P&F WILSEN</option>
            <option value="4865EEB04E4F5905">NOYSEE 05</option>
            <option value="4865EEB04E4F5906">NOYSEE 06</option>
            <option value="4865EEB04E4F5907">NOYSEE 07</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraAppSKey.invalid">
          <label for="input-loraAppSKey">Lora AppSKey</label>
          <input placeholder="" id="input-loraAppSKey" formControlName="loraAppSKey">
          <div *ngIf="f.loraAppSKey.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraNetSKey.invalid">
          <label for="input-loraNetSKey">Lora NetSKey</label>
          <input placeholder="" id="input-loraNetSKey" formControlName="loraNetSKey">
          <div *ngIf="f.loraNetSKey.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraDevAdr.invalid">
          <label for="input-loraDevAdr">Lora DevAdr</label>
          <input placeholder="" id="input-loraDevAdr" formControlName="loraDevAdr">
          <div *ngIf="f.loraDevAdr.invalid" class="error-message ch-margin-top-4">Fehler</div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-measurementInterval">Messintervall</label>
          <input id="input-measurementInterval" formControlName="measurementInterval">
        </div>
      </div>
      <div class="col-1 unit-col">
        s
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-aliveInterval">Übertragungsintervall</label>
          <input id="input-aliveInterval" formControlName="aliveInterval">
        </div>
      </div>
      <div class="col-1 unit-col">
        s
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-timeoutInterval">Timeoutintervall</label>
          <input id="input-timeoutInterval" formControlName="timeoutInterval">
        </div>
      </div>
      <div class="col-1 unit-col">
        s
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-alarmCoolDownHours">Alarm Cooldown</label>
          <input id="input-alarmCoolDownHours" formControlName="alarmCoolDownHours">
        </div>
      </div>
      <div class="col-1 unit-col">
        h
      </div>
    </div>


    <div class="row" class="row ch-margin-top-24" *ngIf="iotDetails.primarySensor">
      <div class="col-10">
        <h5>Primärer Sensor ({{iotDetails.sensors[iotDetails.primarySensor].iotName}} -> {{iotDetails.primarySensor}})</h5>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_limit1">Limit 1 ({{getUnit()}})</label>
          <input id="input-primary_limit1" formControlName="primary_limit1">
          <div *ngIf="f.primary_limit1.invalid
                            && f.primary_limit1.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit1 wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_limit2">Limit 2
            ({{getUnit()}})</label>
          <input id="input-primary_limit2" formControlName="primary_limit2">
          <div *ngIf="f.primary_limit2.invalid
                            && f.primary_limit2.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit2 wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_sensorOffset">Limit 3
            ({{getUnit()}})</label>
          <input id="input-primary_limit3" formControlName="primary_limit3">
          <div *ngIf="f.primary_limit3.invalid
                            && f.primary_limit3.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit3 wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-primary-limitDelta">Limit Delta
            ({{getUnit()}})</label>
          <input id="input-primary-limitDelta" formControlName="primary_limitDelta">
          <div *ngIf="f.primary_limitDelta.invalid
                            && f.primray_limitDelta.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit Delta 
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-primary-hysteresis">Hysterese({{getUnit()}})</label>
          <input id="input-primary-hysteresis" formControlName="primary_hysteresis">
          <div *ngIf="f.primary_hysteresis.invalid
                            && f.primray_hysteresis.errors.required" class="error-message ch-margin-top-4">Bitte
            Hysterese definieren 
          </div>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_maxGradientUp">Max. Anstieg ({{getUnit()}}/h)</label>
          <input id="input-primary_maxGradientUp" formControlName="primary_maxGradientUp">
          <div *ngIf="f.primary_maxGradientUp.invalid
                            && f.primary_maxGradientUp.errors.required" class="error-message ch-margin-top-4">Bitte
            maximalen Gradienten für Anstieg wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_maxGradientDown">Max. Abfall ({{getUnit()}}/h)</label>
          <input id="input-primary_maxGradientDown" formControlName="primary_maxGradientDown">
          <div *ngIf="f.primary_maxGradientDown.invalid
                            && f.primary_maxGradientDown.errors.required" class="error-message ch-margin-top-4">Bitte
            maximalen Gradienten für Abfall wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_gradientRetries">Wiederholungen</label>
          <input id="input-primary_gradientRetries" formControlName="primary_gradientRetries">
          <div *ngIf="f.primary_gradientRetries.invalid
                            && f.primary_gradientRetries.errors.required" class="error-message ch-margin-top-4">Anzahl der Versuche
          </div>
        </div>
      </div>

      <div class="col-2">
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_minQuality">min. Qualität (0-100%)</label>
          <input id="input-primary_minQuality" formControlName="primary_minQuality">
          <div *ngIf="f.primary_minQuality.invalid
                            && f.primary_minQuality.errors.required" class="error-message ch-margin-top-4">Mindestqualität wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_qualityRetries">Wiederholungen</label>
          <input id="input-primary_qualityRetries" formControlName="primary_qualityRetries">
          <div *ngIf="f.primary_qualityRetries.invalid
                            && f.primary_qualityRetries.errors.required" class="error-message ch-margin-top-4">Anzahl der Versuche
          </div>
        </div>
      </div>


    </div>


    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_sensorOffset">Sensor Offset (iot-Units)</label>
          <input id="input-primary_sensorOffset" formControlName="primary_sensorOffset">
          <div *ngIf="f.primary_sensorOffset.invalid
                            && f.primary_sensorOffset.errors.required" class="error-message ch-margin-top-4">Bitte
            Sensor Offset wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_sensorScale">Sensor Scale (iot-Units)</label>
          <input id="input-primary_sensorScale" formControlName="primary_sensorScale">
          <div *ngIf="f.primary_sensorScale.invalid
                            && f.primary_sensorScale.errors.required" class="error-message ch-margin-top-4">Bitte
            Sensor Scale wählen
          </div>
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-calculationType">Formel (Sensortyp)</label>
          <input id="input-calculationType" disabled="true"
            value="{{ 'sensor.calculationType.'+this.getCalculationType() | translate}}">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_offset">Offset ({{getUnit()}})</label>
          <input id="input-primary_offset" formControlName="primary_offset">
          <div *ngIf="f.primary_offset.invalid
                            && f.primary_offset.errors.required" class="error-message ch-margin-top-4">Bitte
            Offset wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-primary_scale">Scale (Backend)</label>
          <input id="input-primary_scale" formControlName="primary_scale">
          <div *ngIf="f.primary_limit3.invalid
                            && f.primary_limit3.errors.required" class="error-message ch-margin-top-4">Bitte
            Scale wählen
          </div>
        </div>
      </div>
    </div>

    <div class="row" class="row ch-margin-top-24" *ngIf="iotDetails.secondarySensor">
      <div class="col-10">
        <h5>Secondary Sensor ({{iotDetails.sensors[iotDetails.secondarySensor].iotName}} -> {{iotDetails.secondarySensor}})</h5>
      </div>
    </div>

    <div class="row ch-margin-top-24" *ngIf="iotDetails.secondarySensor">
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_limit1">Limit 1 ({{getSecondaryUnit()}})</label>
          <input id="input-secondary_limit1" formControlName="secondary_limit1">
          <div *ngIf="f.secondary_limit1.invalid
                            && f.secondary_limit1.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit1 wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_limit2">Limit 2
            ({{getSecondaryUnit()}})</label>
          <input id="input-secondary_limit2" formControlName="secondary_limit2">
          <div *ngIf="f.secondary_limit2.invalid
                            && f.secondary_limit2.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit2 wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_sensorOffset">Limit 3
            ({{getSecondaryUnit()}})</label>
          <input id="input-secondary_limit3" formControlName="secondary_limit3">
          <div *ngIf="f.secondary_limit3.invalid
                            && f.secondary_limit3.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit3 wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary-limitDelta">Limit Delta
            ({{getSecondaryUnit()}})</label>
          <input id="input-secondary-limitDelta" formControlName="secondary_limitDelta">
          <div *ngIf="f.secondary_limitDelta.invalid
                            && f.secondary_limitDelta.errors.required" class="error-message ch-margin-top-4">Bitte
            Limit Delta 
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary-hysteresis">Hysterese({{getSecondaryUnit()}})</label>
          <input id="input-secondary-hysteresis" formControlName="secondary_hysteresis">
          <div *ngIf="f.secondary_hysteresis.invalid
                            && f.secondary_hysteresis.errors.required" class="error-message ch-margin-top-4">Bitte
            Hysterese definieren 
          </div>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24" *ngIf="iotDetails.secondarySensor">
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_maxGradientUp">Max. Anstieg ({{getSecondaryUnit()}}/h)</label>
          <input id="input-secondary_maxGradientUp" formControlName="secondary_maxGradientUp">
          <div *ngIf="f.secondary_maxGradientUp.invalid
                            && f.secondary_maxGradientUp.errors.required" class="error-message ch-margin-top-4">Bitte
            maximalen Gradienten für Anstieg wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_maxGradientDown">Max. Abfall ({{getSecondaryUnit()}}/h)</label>
          <input id="input-secondary_maxGradientDown" formControlName="secondary_maxGradientDown">
          <div *ngIf="f.secondary_maxGradientDown.invalid
                            && f.secondary_maxGradientDown.errors.required" class="error-message ch-margin-top-4">Bitte
            maximalen Gradienten für Abfall wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_gradientRetries">Wiederholungen</label>
          <input id="input-secondary_gradientRetries" formControlName="secondary_gradientRetries">
          <div *ngIf="f.secondary_gradientRetries.invalid
                            && f.secondary_gradientRetries.errors.required" class="error-message ch-margin-top-4">Anzahl der Versuche
          </div>
        </div>
      </div>

      <div class="col-2">
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_minQuality">min. Qualität (0-100%)</label>
          <input id="input-secondary_minQuality" formControlName="secondary_minQuality">
          <div *ngIf="f.secondary_minQuality.invalid
                            && f.secondary_minQuality.errors.required" class="error-message ch-margin-top-4">Mindestqualität wählen
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_qualityRetries">Wiederholungen</label>
          <input id="input-secondary_qualityRetries" formControlName="secondary_qualityRetries">
          <div *ngIf="f.secondary_qualityRetries.invalid
                            && f.secondary_qualityRetries.errors.required" class="error-message ch-margin-top-4">Anzahl der Versuche
          </div>
        </div>
      </div>


    </div>

    <div class="row ch-margin-top-24" *ngIf="iotDetails.secondarySensor">
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_sensorOffset">Sensor Offset</label>
          <input id="input-secondary_sensorOffset" formControlName="secondary_sensorOffset">
          <div *ngIf="f.secondary_sensorOffset.invalid
                            && f.secondary_sensorOffset.errors.required" class="error-message ch-margin-top-4">Bitte
            Sensor Offset wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_sensorScale">Sensor Scale</label>
          <input id="input-secondary_sensorScale" formControlName="secondary_sensorScale">
          <div *ngIf="f.secondary_sensorScale.invalid
                            && f.secondary_sensorScale.errors.required" class="error-message ch-margin-top-4">Bitte
            Sensor Scale wählen
          </div>
        </div>
      </div>
      <div class="col-1 unit-col">
      </div>

    </div>


    <div class="row ch-margin-top-24" *ngIf="iotDetails.secondarySensor">
      <div class="col-4">
        <div class="form-field">
          <label for="input-secondary-calculationType">Formel (Sensortyp)</label>
          <input id="input-secondary-calculationType" disabled="true"
            value="{{ 'sensor.calculationType.'+this.getSecondaryCalculationType() | translate}}">
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_offset">Offset ({{getSecondaryUnit()}})</label>
          <input id="input-secondary_offset" formControlName="secondary_offset">
          <div *ngIf="f.secondary_offset.invalid
                            && f.secondary_offset.errors.required" class="error-message ch-margin-top-4">Bitte
            Offset wählen
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field">
          <label for="input-secondary_scale">Scale (Backend)</label>
          <input id="input-secondary_scale" formControlName="secondary_scale">
          <div *ngIf="f.secondary_scale.invalid
                            && f.secondary_scale.errors.required" class="error-message ch-margin-top-4">Bitte
            Scale wählen
          </div>
        </div>
      </div>
    </div>

    <div class="row" class="row ch-margin-top-24">
      <div class="col-10">
        <h5>IoT-Authentifizierung</h5>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3 role">
        <div class="form-field">
          <label for="select-authMethod">Methode</label>
          <select [attr.disabled]="null" id="select-authMethod" formControlName="authMethod">
            <option selected value="none">keine</option>
            <option value="password">Password</option>
            <option value="bearer">Bearer (Token)</option>
            <option value="plain">Plain</option>
          </select>
        </div>
      </div>

      <div class="col-9" [hidden]="hideSecret()">
        <div class="form-field">
          <label for="input-authSecret">Geheimnis <span *ngIf="iotDetails.authSecretAvailable">(in der Datenbank
              gesetzt, zum Überschreiben bitte neu eingeben)</span></label>
          <input id="input-authSecret" formControlName="authSecret">
        </div>
      </div>

    </div>


    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" (click)="saveSensorBox()">Speichern</button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" [routerLink]="['/dashboard', 'sensors', 'overview']">Abbrechen</button>
      </div>
      <div class="col-3">
      </div>
    </div>
  </form>
</div>