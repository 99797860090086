<div class="primary-panel-large container-fluid">
	<div class="row">
		<div class="col-8">
			<h1 *ngIf="mode === 'view'" appTranslate="alarmChannel.headerLabelSingular"></h1>
			<h1 *ngIf="mode === 'add'" appTranslate="alarmChannel.headerLabelAdd"></h1>
			<h1 *ngIf="mode === 'edit'" appTranslate="alarmChannel.headerLabelEdit"></h1>
		</div>
	</div>
	<form *ngIf="crudDeviceForm" class="form" [formGroup]="crudDeviceForm" (ngSubmit)="saveAlarmChannel()">
		<div class="row ch-margin-top-24">
			<div class="col-1 role">
				<strong>{{ "general.label.active" | translate }}</strong>
			</div>
			<div class="col-4 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active"
							[class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-name">{{ "general.label.name" | translate }} *</label>
					<input placeholder="" id="input-name" formControlName="name" />
					<div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4"
						appTranslate="alarmChannel.crud.error.name"></div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.description.invalid">
					<label for="input-description"><span appTranslate="general.label.description"></span></label>
					<input placeholder="" id="input-description" formControlName="description" />
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<div class="form-field">
					<label for="input-channelType" appTranslate="alarmChannel.label.channelType"></label>
					<select formControlName="channelType">
						<option [value]="null">-</option>
						<option *ngFor="let element of alarmChannelTypes" [value]="element"
							[appTranslate]="'alarmChannel.channelType.' + element"></option>
					</select>
				</div>
			</div>
		</div>

		<div *ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.THREEMA">
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="threemaIdFrom" appTranslate="alarmChannel.label.threema.fromId"></label>
						<input id="threemaIdFrom" formControlName="threemaIdFrom" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="threemaIdTo" appTranslate="alarmChannel.label.threema.toId"></label>
						<input id="threemaIdTo" formControlName="threemaIdTo" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="threemaSecret" appTranslate="alarmChannel.label.threema.secret"></label>
						<input id="threemaSecret" formControlName="threemaSecret" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="threemaPrivateKey" appTranslate="alarmChannel.label.threema.privateKey"></label>
						<input id="threemaPrivateKey" formControlName="threemaPrivateKey" />
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.SLACK">
			<div class="row ch-margin-top-24">
				<div class="col-12">
					<div class="form-field" [class.error]="false">
						<label for="slackUrl" appTranslate="alarmChannel.label.slack.url"></label>
						<input id="slackUrl" formControlName="slackUrl" />
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.WEBHOOK">
			<div class="row ch-margin-top-24">
				<div class="col-12">
					<div class="form-field" [class.error]="false">
						<label for="webHookUrl" appTranslate="alarmChannel.label.webhook.url"></label>
						<input id="webHookUrl" formControlName="webHookUrl" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-2">
					<div class="form-field">
						<label for="input-webHookAuthType" appTranslate="alarmChannel.label.webhook.authType"></label>
						<select id="input-webHookAuthType" formControlName="webHookAuthType">
							<option value="" appTranslate="alarmChannel.authType.none"
								[selected]="crudDeviceForm.controls.webHookAuthType.value==null || crudDeviceForm.controls.webHookAuthType.value==''">
							</option>
							<option value="basic" appTranslate="alarmChannel.authType.basic"></option>
							<option value="bearer" appTranslate="alarmChannel.authType.bearer"></option>
						</select>
					</div>
				</div>

				<div class="col-5" *ngIf="crudDeviceForm.controls.webHookAuthType.value=='basic'">
					<div class="form-field" [class.error]="false">
						<label for="webHookAuthUser-id" appTranslate="alarmChannel.label.webhook.authUser"></label>
						<input id="webHookAuthUser-id" formControlName="webHookAuthUser" />
					</div>
				</div>
				<div class="col-5" *ngIf="crudDeviceForm.controls.webHookAuthType.value=='basic'">
					<div class="form-field" [class.error]="false">
						<label for="webHookAuthPassword-id"
							appTranslate="alarmChannel.label.webhook.authPassword"></label>
						<input type="password" id="webHookAuthPassword-id" formControlName="webHookAuthPassword" />
					</div>
				</div>

				<div class="col-10" *ngIf="crudDeviceForm.controls.webHookAuthType.value=='bearer'">
					<div class="form-field" [class.error]="false">
						<label for="webHookAuthBearer-id" appTranslate="alarmChannel.label.webhook.bearer"></label>
						<input id="webHookAuthBearer-id" formControlName="webHookAuthBearer" />
					</div>
				</div>


			</div>

		</div>

		<div *ngIf="crudDeviceForm.controls.channelType.value == AlarmChannelType.TWITTER">
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="twitterConsumerKey" appTranslate="alarmChannel.label.twitter.consumerKey"></label>
						<input id="twitterConsumerKey" formControlName="twitterConsumerKey" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false"> <label for="twitterConsumerSecret"
							appTranslate="alarmChannel.label.twitter.consumerSecret"></label>
						<input id="twitterConsumerSecret" formControlName="twitterConsumerSecret" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="twitterAccessToken" appTranslate="alarmChannel.label.twitter.accessToken"></label>
						<input id="twitterAccessToken" formControlName="twitterAccessToken" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="twitterAccessTokenSecret"
							appTranslate="alarmChannel.label.twitter.accessTokenSecret"></label>
						<input id="twitterAccessTokenSecret" formControlName="twitterAccessTokenSecret" />
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="crudDeviceForm.controls.channelType.value == AlarmChannelType.DIVERA_NEWS || crudDeviceForm.controls.channelType.value == AlarmChannelType.DIVERA_ALARM">
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="diveraAccesskey" appTranslate="alarmChannel.label.divera.accesskey"></label>
						<input id="diveraAccesskey" formControlName="diveraAccesskey" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="diveraNewsFk" appTranslate="alarmChannel.label.divera.fk"></label>
						<input id="diveraNewsFk" formControlName="diveraFk" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="diveraNewsAddress" appTranslate="alarmChannel.label.divera.address"></label>
						<input id="diveraNewsAddress" formControlName="diveraAddress" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="diveraNewsRic" appTranslate="alarmChannel.label.divera.ric"></label>
						<input id="diveraNewsRic" formControlName="diveraRic" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="diveraNewsPerson" appTranslate="alarmChannel.label.divera.person"></label>
						<input id="diveraNewsPerson" formControlName="diveraPerson" />
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.ALAMOS_ALARM">
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosFeIdentifikation"
							appTranslate="alarmChannel.label.alamos.feIdentifikation"></label>
						<input id="input-alamosFeIdentifikation" formControlName="alamosFeIdentifikation" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosCustomerIdentifikation"
							appTranslate="alarmChannel.label.alamos.customerIdentifikation"></label>
						<input id="input-alamosCustomerIdentifikation" formControlName="alamosCustomerIdentifikation" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosPassword" appTranslate="alarmChannel.label.alamos.password"></label>
						<input type="password" id="input-alamosPassword" formControlName="alamosPassword" />
					</div>
				</div>
			</div>
			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosSender" appTranslate="alarmChannel.label.alamos.sender"></label>
						<input id="input-alamosSender" formControlName="alamosSender" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosAuthorization"
							appTranslate="alarmChannel.label.alamos.authorization"></label>
						<input id="input-alamosAuthorization" formControlName="alamosAuthorization" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosCallerName" appTranslate="alarmChannel.label.alamos.callerName"></label>
						<input id="input-alamosCallerName" formControlName="alamosCallerName" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosCallerContact"
							appTranslate="alarmChannel.label.alamos.callerContact"></label>
						<input id="input-alamosCallerContact" formControlName="alamosCallerContact" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosKeyWord" appTranslate="alarmChannel.label.alamos.keyWord"></label>
						<input id="input-alamosKeyWord" formControlName="alamosKeyWord" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosKeyWordDescription"
							appTranslate="alarmChannel.label.alamos.keyWordDescription"></label>
						<input id="input-alamosKeyWordDescription" formControlName="alamosKeyWordDescription" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-12">
					<div class="form-field" [class.error]="false">
						<label for="input-alamosUnits" appTranslate="alarmChannel.label.alamos.units"></label>
						<input id="input-alamosUnits" formControlName="alamosUnits" />
					</div>
				</div>
			</div>

		</div>

		<div
			*ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_ALARM || crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_EVENT || crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_OPERATION">
			<div class="row ch-margin-top-24">
				<div class="col-12">
					<div class="form-field" [class.error]="false">
						<label for="c8yHost-id" appTranslate="alarmChannel.label.c8y.host"></label>
						<input id="c8yHost-id" formControlName="c8yHost" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="c8yUser-id" appTranslate="alarmChannel.label.c8y.user"></label>
						<input id="c8yUser-id" formControlName="c8yUser" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="c8yPassword-id" appTranslate="alarmChannel.label.c8y.password"></label>
						<input type="password" id="c8yPassword-id" formControlName="c8yPassword" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24">
				<div class="col-4">
					<div class="form-field" [class.error]="false">
						<label for="c8ySourceId-id" appTranslate="alarmChannel.label.c8y.sourceId"></label>
						<input id="c8ySourceId-id" formControlName="c8ySourceId" />
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24"
				*ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_ALARM">
				<div class="col-4">
					<div class="form-field" [class.error]="false">
						<label for="c8yAlarmType-id" appTranslate="alarmChannel.label.c8y.alarmType"></label>
						<input id="c8yAlarmType-id" formControlName="c8yAlarmType" />
					</div>
				</div>
				<div class="col-6">
					<div class="form-field" [class.error]="false">
						<label for="c8ySeverity-id" appTranslate="alarmChannel.label.c8y.severity"></label>
						<select id="c8ySeverity-id" formControlName="c8ySeverity">
							<option [value]="null">-</option>
							<option *ngFor="let element of severityList" [value]="element"
								[appTranslate]="'alarmChannel.label.c8y.severityValue.' + element"></option>
						</select>
					</div>
				</div>
			</div>

			<div class="row ch-margin-top-24"
				*ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_OPERATION">
				<div class="col-4">
					<div class="form-field" [class.error]="false">
						<label for="c8yFragmentName-id" appTranslate="alarmChannel.label.c8y.fragmentName"></label>
						<input id="c8yFragmentName-id" formControlName="c8yFragmentName" />
					</div>
				</div>
				<div class="col-8">
					<div class="form-field" [class.error]="false">
						<label for="c8yFragmentContent-id" appTranslate="alarmChannel.label.c8y.fragmentContent"></label>
						<textarea id="c8yFragmentContent-id" formControlName="c8yFragmentContent" rows="10"></textarea>
					</div>
				</div>
			</div>
			
			
			<div class="row ch-margin-top-24"
				*ngIf="crudDeviceForm.controls.channelType.value==AlarmChannelType.C8Y_EVENT">
				<div class="col-4">
					<div class="form-field" [class.error]="false">
						<label for="c8yEventType-id" appTranslate="alarmChannel.label.c8y.eventType"></label>
						<input id="c8yEventType-id" formControlName="c8yEventType" />
					</div>
				</div>
			</div>

		</div>


		<div class="row ch-margin-top-24">
			<div class="col-3">
				<button class="btn-primary" *ngIf="!crudDeviceForm.disabled"
					(click)="saveAlarmChannel(); $event.preventDefault()"
					appTranslate="general.crud.actions.save"></button>
			</div>
			<div class="col-3">
				<button class="btn-secondary" (click)="back()" *ngIf="!crudDeviceForm.disabled"
					appTranslate="general.crud.actions.cancel"></button>
			</div>
			<div class="col-3"></div>
			<div *ngIf="mode === 'edit'" class="col-3">
				<button type="button" class="btn-secondary" (click)="deleteAlarmChannel()"
					*ngIf="!crudDeviceForm.disabled" appTranslate="general.crud.actions.delete"></button>
			</div>
		</div>
	</form>

</div>