import { Injectable } from '@angular/core';
import { AlarmActionListResult, AlarmActionResult, AlarmActionExecutionResult } from '../../models/apiResults';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AlarmAction, AlarmActionExecution, AlarmActionExecutionRequest } from './models/alarmAction';

@Injectable({
	providedIn: 'root',
})
export class AlarmActionService {
	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	getSensorBoxAlarmActions(sensorBoxId: number): Promise<AlarmAction[]> {
		return this.http
			.get<AlarmActionListResult>(
				`${environment.dashboardApiUrl}/actions/list?sensorBoxId=` +
				sensorBoxId,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}
	
	saveAlarmAction( sensorBoxId: number,saveActionRequest: AlarmAction): Promise<AlarmAction> {
		return this.http
			.post<AlarmActionResult>(
				`${environment.dashboardApiUrl}/sensorBox/` + sensorBoxId + `/action`, 
				saveActionRequest
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	getAlarmAction(alarmActionId: number): Promise<AlarmAction> {
		return this.http
			.get<AlarmActionResult>(
				`${environment.dashboardApiUrl}/actions/` +
				alarmActionId + `/detail`,
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

	delete(alarmActionId: number): Promise<boolean> {
		return this.http
			.delete<AlarmActionResult>(
				`${environment.dashboardApiUrl}/actions/` +
				alarmActionId + `/detail`,
			)
			.pipe(
				map((result) => {
					return result.ok;
				}),
			)
			.toPromise();
	}
   
    // eine AlarmAction starten 
	executeAlarmActionStart(alarmActionId: number, password: string, sensorBoxId: number, customerId: number, parameters: any): Promise<AlarmActionExecution> {
		var req: AlarmActionExecutionRequest;
		req = {
			sensorBoxId: sensorBoxId,
			customerId: customerId,
			password: password,
			executionValues: parameters
		}
		return this.http
			.post<AlarmActionExecutionResult>(
				`${environment.dashboardApiUrl}/actions/` +
				alarmActionId + `/executeStart`, req
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}
	
    // eine AlarmAction beenden 
	executeAlarmActionEnd(alarmActionId: number, password: string, sensorBoxId: number, customerId: number, parameters: any): Promise<AlarmActionExecution> {
		var req: AlarmActionExecutionRequest;
		req = {
			sensorBoxId: sensorBoxId,
			customerId: customerId,
			password: password,
			executionValues: parameters
		}
		return this.http
			.post<AlarmActionExecutionResult>(
				`${environment.dashboardApiUrl}/actions/` +
				alarmActionId + `/executeEnd`, req
			)
			.pipe(
				map((result) => {
					if (!result.ok) {
						throw new Error(result.errorCode);
					}
					return result.data;
				}),
			)
			.toPromise();
	}

}
