import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastHelper {
  defaultConfig: any = {
    panelClass: 'snackbar-error',
    duration: 2000,
  };

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  // Opens a snackbar with given message and duration (defaults to 5 seconds)
  openSnackBar(
    message: string,
    type: 'ERROR' | 'SUCCESS',
    duration: number = 2000,
  ) {
    const config = {
      panelClass: 'snackbar-' + type.toLowerCase(),
    };

    if (duration) {
      config['duration'] = duration;
    }

    this.snackBar.open(this.translateService.instant(message), '', config);
  }

  openCrudDeleteError() {
    this.snackBar.open('Fehler beim Löschen!', '', this.defaultConfig);
  }

  openCrudChangeError() {
    this.snackBar.open('Fehler beim Ändern!', '', this.defaultConfig);
  }

  openCrudCreateError() {
    this.snackBar.open('Fehler beim Anlegen!', '', this.defaultConfig);
  }

  openCrudEmailExistsError() {
    this.snackBar.open(
      'E-Mail-Adresse ist bereits registriert!',
      '',
      this.defaultConfig,
    );
  }

  openCrudTextError(msg: string) {
    this.snackBar.open(msg, '', this.defaultConfig);
  }
}
