import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService } from '../../../../../services/devices.service';
import { NoyseeDevice } from '../../../../../models/noyseeDevice';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { DataTableOptions, GridState, TableActionType, TableFilterType, TitleSize } from '../../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from 'src/app/helpers/toast.helper';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';

@Component({
  selector: 'app-sensors-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  isSuperAdmin: boolean;
  isAdmin: boolean;

  dataTableOptions: DataTableOptions<NoyseeDevice>;
  list: NoyseeDevice[];
  gridState: GridState = null;
  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;


  constructor(private deviceService: DevicesService,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private router: Router) {
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'noyseeDevice.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [{
        label: 'noyseeDevice.table.actions.add',
        rule: () => this.authenticationService.isSuperAdmin() || this.authenticationService.isAdmin(),
        actionFn: () => this.router.navigate(['/dashboard', 'admins', 'devices', 'addNoyseeDevice'],
          { queryParams: { ctx: this.getGridState() } })
      }],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [{
          label: 'noyseeDevice.table.actions.edit',
          icon: 'app-pencil',
          actionFn: (noyseeDevice: NoyseeDevice) => this.showNoyseeDeviceEdit(noyseeDevice)
        },
        {
          label: 'noyseeDevice.table.actions.sensorBox',
          icon: 'app-view',
          actionFn: (noyseeDevice: NoyseeDevice) => this.showSensorBox(noyseeDevice)
        },        
        {
          label: 'noyseeDevice.table.actions.delete',
          icon: 'app-delete',
          actionFn: (noyseeDevice: NoyseeDevice) => this.deleteNoyseeDevice(noyseeDevice)
        }]
      },
      // rowStyleFn: (alarmGroup: AlarmGroup) => {
      //   return {
      //     'background-color': this.getStateColor(sensor.state),
      //     'border': 'solid 1.5px white'
      //   };
      // },
      columnDef: [{
        key: 'id',
        displayName: 'noyseeDevice.table.columns.id',
        width: '5%',
        sortable: true,
        hidden: !this.isSuperAdmin,
      }, {
        key: 'noyseeSN',
        displayName: 'noyseeDevice.table.columns.noyseeSN',
        width: '10%',
        sortable: true,
        styleFn: (noyseeDevice: NoyseeDevice) => {
          return {
            cursor: 'pointer',
            color: '#0e0ec4',
            'font-weight': 'bold'
          };
        },
        actionFn: (noyseeDevice: NoyseeDevice) => this.router.navigate(['/dashboard', 'admins', 'devices', 'detail', noyseeDevice.noyseeSN])
      }, {
        key: 'loraDevEui',
        displayName: 'noyseeDevice.table.columns.uuid',
        width: '15%',
        sortable: true,
      }, {
        key: 'hwType',
        displayName: 'noyseeDevice.table.columns.hwType',
        width: '10%',
        sortable: true,
      }, {
        key: 'btMac',
        displayName: 'noyseeDevice.table.columns.btMac',
        width: '15%',
        sortable: true,
      }, {
        key: 'sim',
        displayName: 'noyseeDevice.table.columns.sim',
        width: '15%',
        sortable: true,
        valueTransform: (noyseeDevice: NoyseeDevice) => `${noyseeDevice.simICCID}`,
        
        styleFn: (noyseeDevice: NoyseeDevice) => {
          return {
            cursor: 'pointer',
            color: '#0e0ec4',
            'font-weight': 'bold'
          };
        },
        actionFn: (noyseeDevice: NoyseeDevice) => this.showM2MInfo( noyseeDevice.simICCID),

      }, {
        key: 'imei',
        displayName: 'noyseeDevice.table.columns.imei',
        width: '15%',
        sortable: true,
        valueTransform: (noyseeDevice: NoyseeDevice) => `${noyseeDevice.imei}`,
      }, {
        key: 'state',
        displayName: 'noyseeDevice.table.columns.state',
        width: '10%',
        sortable: true,
        valueTransform: (noyseeDevice: NoyseeDevice) => `noyseeDevice.state.${noyseeDevice.state}`,
      }

      ]
    };

    this.deviceService.getNoyseeDeviceList().then(data => {
      this.list = data;
    });

  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(this.dataTable.getGridState());
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  addNoyseeDevice() {
  }

  showNoyseeDeviceDetail(noyseeDevice: NoyseeDevice) {
    this.router.navigate(['/dashboard', 'admins', 'devices', 'detail', noyseeDevice.noyseeSN]);
  }

  showSensorBox(noyseeDevice: NoyseeDevice) {
    this.router.navigate(['/dashboard', 'sensors', 'detail', noyseeDevice.sensorBox.id, 'values']);
  }

  showNoyseeDeviceEdit(noyseeDevice: NoyseeDevice) {
    this.router.navigate(['/dashboard', 'admins', 'devices', 'detail', noyseeDevice.noyseeSN]);
  }

  deleteNoyseeDevice(noyseeDevice: NoyseeDevice) {
    const _this = this;

    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie dieses Gerät wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.deviceService.deleteDeviceBySno(noyseeDevice.noyseeSN).then(result => {
            this.deviceService.getNoyseeDeviceList().then(data => {
              this.list = data;
              this.toastHelper.openSnackBar('Gerät erfolgreich gelöscht!', 'SUCCESS');
            });
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }


  showM2MInfo( simICCID: string) {
    window.open(
      'https://portal-m2m.telekom.de/m2m-customer-portal/user/simcard/detail/' +
      simICCID,
      'M2M_Portal',
    );
  }

}
