import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationContextService} from '../../../../../services/application-context.service';
import {NoyseeDevice} from '../../../../../models/noyseeDevice';
import {DevicesService} from '../../../../../services/devices.service';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {pipe} from 'rxjs';

@Component({
  selector: 'app-crud-noyseeDevice',
  templateUrl: './crud-noyseeDevice.component.html',
  styleUrls: ['./crud-noyseeDevice.component.scss']
})
export class CrudNoyseeDeviceComponent implements OnInit, OnDestroy {
  mode: string;
  noyseeDevice: NoyseeDevice;
  crudNoyseeDeviceForm: FormGroup;
  submitted: boolean;
  uploadConfig: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private applicationContextService: ApplicationContextService,
              private authenticationService: AuthenticationService,
              private devicesService: DevicesService) {
    this.mode = '';
    this.noyseeDevice = new NoyseeDevice();
    this.crudNoyseeDeviceForm = null;
    this.submitted = false;
  }

  ngOnInit(): void {
    const _this = this;

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    this.authenticationService.authTokenObservable
      .pipe(first(token => !!token))
      .subscribe(token => this.uploadConfig.uploadAPI['headers'] = {Authorization: `Bearer ${token}`});

    this.crudNoyseeDeviceForm = this.formBuilder.group({
      noyseeSN: new FormControl('', [Validators.required]),
      loraDevEui: new FormControl('', [Validators.required]),
      hwType: new FormControl(''),
      imei: new FormControl(''),
      simICCID: new FormControl(''),
    });

    // if (this.mode === 'edit') {
    //   const url = this.route.parent.snapshot.url;
    //   const currentSensorId = parseInt(url[0].path, 10);
    //   this.applicationContextService.currentSensorId = currentSensorId;
    //   this.uploadConfig.uploadAPI.url += currentSensorId;

    //   this.noyseeService.getDetails()
    //     .pipe(first(sensor => !!sensor))
    //     .subscribe(sensor => {
    //       _this.sensor = sensor;
    //       _this.uploadConfig.uploadAPI.url += _this.sensor.id;

    //       _this.f.name.setValue(_this.sensor.name);
    //       _this.f.address.setValue(_this.sensor.address);
    //       _this.f.lng.setValue(_this.sensor.lon);
    //       _this.f.lat.setValue(_this.sensor.lat);
    //       _this.f.datasource.setValue(_this.sensor.datasource);

    //       if (_this.sensor.sensors[sensor.primarySensor]) {
    //         _this.f.limit1.setValue(_this.sensor.sensors[sensor.primarySensor].limit1);
    //         _this.f.limit2.setValue(_this.sensor.sensors[sensor.primarySensor].limit2);
    //       }
    //     });
    // }
  }

  ngOnDestroy() {
    // this.applicationContextService.currentNoyseeDeviceId = '';
  }

  get f() {
    return this.crudNoyseeDeviceForm.controls;
  }

  saveNoyseeDevice() {
    this.submitted = true;
    if (this.crudNoyseeDeviceForm.invalid) {
      return;
    }
    this.noyseeDevice.loraDevEui = this.f.loraDevEui.value;
    this.noyseeDevice.noyseeSN = this.f.noyseeSN.value;

    if (this.mode === 'add') {
      // this.sensor.statusInfo = 'normal';
      // this.noyseeService.createSensor(this.sensor);
      // this.router.navigate(['/dashboard', 'sensors']);
    } else { /*mode === edit*/
      // this.noyseeService.changeSensor(this.sensor);
      // this.router.navigate(['/dashboard', 'sensors', 'detail', this.sensor.id]);
    }
  }

  backToSensorDetail() {
    if (this.noyseeDevice) {
      this.router.navigate(['/dashboard', 'admins', 'devices', 'detail', this.noyseeDevice.id]);
    } else {
      this.router.navigate(['/dashboard', 'admins', 'devices', 'overview']);
    }
  }

}
