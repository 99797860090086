import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { Waterway } from '../../models/topo';
import { MapLayer } from './models/mapLayer';
import { LatLngBounds } from 'leaflet';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopoService {
  constructor(private http: HttpClient) {}

  getAvailableMapLayers(): Observable<MapLayer[]> {
    return this.http.get<any>(`${environment.dashboardApiUrl}/mapLayers`).pipe(
      filter((result) => result.ok),
      map((result) => result.data),
    );
  }

  getWaterways(
    boundingBox: LatLngBounds,
    zoom: number,
  ): Observable<Waterway[]> {
    return this.http.get<Waterway[]>(
      `${environment.dashboardApiUrl}/topo/waterways?lat1=${
        boundingBox.getNorthEast().lat
      }&lon1=${boundingBox.getNorthEast().lng}` +
        `&lat2=${boundingBox.getSouthWest().lat}&lon2=${
          boundingBox.getSouthWest().lng
        }&zoom=${zoom}`,
    );
  }

  setVisibilityForWaterway(wayId: number, nodeId: number, visible: boolean) {
    const visibility = visible ? 1 : 0;
    return this.http
      .put<any>(
        `${environment.dashboardApiUrl}/topo/waterway/visibility?way=${wayId}&node=${nodeId}&visible=${visibility}`,
        {},
      )
      .toPromise();
  }
}
