
export class LoRaWanConfig {
  id: number;
  name: string;
  description: string;
  contentType: string;
  apiKey: string;
  mode: string;
  password: string;
  tag: string;
  url: string;
  active: boolean;
}

export enum LoRaWanConfigType {
  ELEMENT_READING = 'element.reading',
  ELEMENT_PACKET = 'element.packet',
}

export function getLoRaWanConfigTypes(): { label: string, value: string }[] {
  const templateClasss = [];
  for (const entry of Object.values(LoRaWanConfigType)) {
    templateClasss.push({
      label: `loraWanConfig.mode.${entry}`,
      value: entry
    });
  }
  return templateClasss;
}
