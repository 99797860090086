<div id="header-container">
	<div class="header-block-left">
		<a *ngIf="!hideLogo" class="header-logo" routerLink="/dashboard">
			<img [src]="logoUrl" />
		</a>
		<div *ngIf="isSuperAdmin && availableCustomers?.length">
			<select (change)="selectCustomer($event.target)">
				<option *ngFor="let customer of availableCustomers" [value]="customer.id"
					[selected]="customer.id === currentCustomer?.id">
					{{ customer.name }}
				</option>
			</select>
		</div>
		<div *ngIf="isDev" class="is-dev">
			<img src="../../../../../assets/img/exclamation-triangle-solid.svg" alt="Is Dev environment" />
		</div>
	</div>
	<div class="header-block-right">
		<div *ngIf="statisticsVisible$ | async as statisticsVisible">
			<a class="ch-margin-right-40" routerLink="/dashboard/statistics" routerLinkActive="active"
				(click)="openPrimaryPanel()" appTranslate="statistic.headerLabel"></a>
		</div>
		<a class="ch-margin-right-40" routerLink="/dashboard/sensors" routerLinkActive="active"
			(click)="openPrimaryPanel()" appTranslate="sensor.headerLabel"></a>
		<div *ngIf="isAlarmsVisible">
			<a class="ch-margin-right-40" [matMenuTriggerFor]="alarmMenu" appTranslate="header.menu-entries.alarms"></a>
			<mat-menu #alarmMenu="matMenu">
				<button *ngIf="isFeatureAlarmRules" mat-menu-item routerLink="/dashboard/alarmRules"
					routerLinkActive="active" (click)="openPrimaryPanel()">
					<span appTranslate="alarmRule.headerLabel"></span>
				</button>
				<button *ngIf="isFeatureAlarmGroups" mat-menu-item routerLink="/dashboard/alarmGroups"
					routerLinkActive="active" (click)="openPrimaryPanel()">
					<span appTranslate="alarmGroup.headerLabel"></span>
				</button>
			</mat-menu>
		</div>

		<div *ngIf="isAdmin || isSuperAdmin">
			<a *ngIf="isAdmin || isSuperAdmin" class="ch-margin-right-40" [matMenuTriggerFor]="adminMenu"
				appTranslate="header.menu-entries.admin"></a>
			<mat-menu #adminMenu="matMenu">
				<button mat-menu-item *ngIf="isAdmin || isSuperAdmin" routerLink="/dashboard/users"
					routerLinkActive="active" (click)="openPrimaryPanel()">
					<span appTranslate="user.headerLabel"></span>
				</button>
				<button *ngIf="isFeatureAlarmChannels" mat-menu-item routerLink="/dashboard/alarmChannels"
					routerLinkActive="active" (click)="openPrimaryPanel()">
					<span appTranslate="alarmChannel.headerLabel"></span>
				</button>
				<ng-container *ngIf="isSuperAdmin">
					<button mat-menu-item routerLink="/dashboard/warningBrokers/warnings/activeWarnings"
						routerLinkActive="active" class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="warningBroker.activeWarnings.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/devices" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="device.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/customers" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="customer.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/messageTemplates" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="messageTemplate.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/exportFiles" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="exportFile.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/importConfig" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="importConfig.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/geoPolygone" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="geoPolygone.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/loraWanConfigs" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="loraWanConfig.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/firmware" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="firmware.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/billing/products" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="billing.products.headerLabel"></span>
					</button>
					<button mat-menu-item routerLink="/dashboard/admins/billing/deliveries" routerLinkActive="active"
						class="admin-menu-item" (click)="openPrimaryPanel()">
						<span appTranslate="billing.deliveries.headerLabel"></span>
					</button>
				</ng-container>
			</mat-menu>
		</div>

		<a routerLink="/dashboard/notifications" routerLinkActive="active" class="ch-margin-right-32 notification-bell"
			(click)="openPrimaryPanel()">
			<img src="../../../../../assets/img/notification-bell.svg" alt="Benachrichtigungen" class="notification" />
			<span class="notification-counter" [class.open-error]="openError">{{
				openNotificationCount
				}}</span>
		</a>

		<button [matMenuTriggerFor]="menu" aria-label="open menu">
			<img [src]="profileImage" alt="Avatar" class="profile-image" />
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="showHelp()" *ngIf="email || phone">
				<mat-icon class="blue-icon">help_outline</mat-icon>
				<span appTranslate="header.menu-entries.help"></span>
			</button>
			<button mat-menu-item *ngIf="!isGuest" (click)="editUser()">
				<mat-icon class="blue-icon">manage_accounts</mat-icon>
				<span appTranslate="header.menu-entries.edit-self"></span>
			</button>
			<button *ngIf="!isGuest" mat-menu-item (click)="openPasswordDialog()">
				<mat-icon class="blue-icon">lock_open</mat-icon>
				<span appTranslate="header.menu-entries.change-password"></span>
			</button>
			<button mat-menu-item (click)="logout()">
				<mat-icon class="blue-icon">logout</mat-icon>
				<span appTranslate="header.menu-entries.logout"></span>
			</button>
		</mat-menu>
	</div>
</div>