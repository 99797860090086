import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TableStateService {

    triggerGridState: Subject<void> = new Subject<void>();

    setState: ReplaySubject<string> = new ReplaySubject<string>();

    isGrid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
    }


    getGridState(): Observable<string> {
        if (this.isGrid.value === false) {
            return of(null);
        }
        this.triggerGridState.next();
        return this.setState.pipe(first());
    }
}
