<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-6">
      <h1 *ngIf="mode === 'add'">Device anlegen</h1>
      <h1 *ngIf="mode === 'edit'">Device bearbeiten</h1>
    </div>
    <div *ngIf="mode === 'edit'" class="col-6 button-col">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open group menu">
        <img src="../../../../../../../assets/img/three-dots-blue.svg" alt="Ausklappmenü" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="installDevice()" [disabled]="!installable"
          [class.action-menu-disabled]="!installable">
          <span>Installieren</span>
        </button>
        <button mat-menu-item (click)="finishQS()">
          <span>QS abgeschlossen</span>
        </button>
        <button mat-menu-item (click)="repair()">
          <span>Reparatur</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <form *ngIf="crudDeviceForm" class="form" [formGroup]="crudDeviceForm" (ngSubmit)="saveDevice()">
    <div *ngIf="mode === 'edit' || mode === 'add' || mode === 'edit-self'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.noyseeSN.invalid">
          <label for="input-noyseeSN">Seriennummer *</label>
          <input placeholder="" id="input-noyseeSN" formControlName="noyseeSN" />
          <div *ngIf="f.noyseeSN.invalid" class="error-message ch-margin-top-4">
            Seriennummer existiert bereits!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24" *ngIf="deviceDetails.sensorBox">
      <div class="col-2 role">
        <div class="form-field">
          <label for="boxId">ID</label>
          <input type="text" value="{{ deviceDetails.sensorBox.id }}" name="boxId" disabled />
        </div>
      </div>
      <div class="col-9 role">
        <div class="form-field">
          <label for="boxName">Name</label>
          <input type="text" value="{{ deviceDetails.sensorBox.name }}" name="boxName" disabled />
        </div>
      </div>
      <div class="col-1 role">
        <div class="form-field">
          <label for="boxLink">&nbsp;</label>
          <a (click)="showSensorBox(deviceDetails.sensorBox.id)" class="btn-link">
            <mat-icon class="material-icons-outline">launch</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4 role">
        <div class="form-field" [class.error]="f.state.invalid">
          <label for="select-state">Status</label>
          <select [attr.disabled]="null" id="select-state" formControlName="state">
            <option selected disabled hidden>Auswählen...</option>
            <option [value]="DeviceState.ASSEMBLED">Zusammengebaut</option>
            <option [value]="DeviceState.IN_QS">im Test</option>
            <option [value]="DeviceState.TESTED">Getestet</option>
            <option [value]="DeviceState.WAREHOUSE">Lager</option>
            <option [value]="DeviceState.IN_USE">Verbaut</option>
            <option [value]="DeviceState.TAKEN_OFF">Abgebaut</option>
            <option [value]="DeviceState.IN_REPAIR">Reparatur</option>
            <option [value]="DeviceState.TRASH">Entsorgt</option>
            <option [value]="DeviceState.STOLEN">Gestohlen</option>
            <option [value]="-1">Unbekannt</option>
          </select>
          <div *ngIf="f.state.invalid && f.state.errors?.required" class="error-message ch-margin-top-4">
            Bitte Wert auswählen
          </div>
        </div>
      </div>
      <div class="col-4 role" *ngIf="deviceDetails.lat || deviceDetails.lon">
        <div class="form-field" (click)="showLocation()">
          <label for="input-defaultSensorBoxTemplateId">Position</label>
          <input type="text" value="{{ deviceDetails.lat }} / {{ deviceDetails.lon }}" name="location" disabled />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-defaultSensorBoxTemplateId">Default SensorBoxTemplate</label>
          <select [attr.disabled]="null" id="select-defaultSensorBoxTemplateId"
            formControlName="defaultSensorBoxTemplateId">
            <option [ngValue]="null" selected="true">-</option>
            <option [ngValue]="entry.id" *ngFor="let entry of templateList">
              {{ entry.name }} (ID: {{ entry.id }})
            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'edit' || mode === 'add'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraDevEui?.invalid">
          <label for="input-loraDevEui">LoRa DevEui *</label>
          <input placeholder="" id="input-loraDevEui" formControlName="loraDevEui" />
          <div *ngIf="f.loraDevEui.invalid && f.loraDevEui.errors.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.loraDevEui.invalid && f.loraDevEui.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'edit' || mode === 'add'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.loraAppKey?.invalid">
          <label for="input-loraAppKey">LoRa AppKey</label>
          <input placeholder="" id="input-loraAppKey" formControlName="loraAppKey" />
          <div *ngIf="f.loraAppKey.invalid && f.loraAppKey.errors?.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.loraAppKey.invalid && f.loraDevEui.errors?.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'edit' || mode === 'add'" class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.loraDevEui?.invalid">
          <label for="input-loraAppEui">LoRa AppEui</label>
          <input placeholder="" id="input-loraAppEui" formControlName="loraAppEui" (change)="maybePredefined()" />
          <div *ngIf="f.loraAppEui.invalid && f.loraAppEui.errors?.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.loraDevEui.invalid && f.loraAppEui.errors?.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>

      <div class="col-4 role">
        <div class="form-field" [class.error]="f.state.invalid">
          <label for="select-state">Predefined AppEUIs</label>
          <select [attr.disabled]="null" id="select-state" formControlName="predefinedAppEUI" (change)="chooseAppEui()">
            <option selected disabled hidden>Auswählen...</option>
            <option value="4865EEB04E4F5901">NOYSEE Premium</option>
            <option value="4865EEB04E4F5903">NOYSEE ECO</option>
            <option value="4865EEB04E4F5904">NOYSEE Wetterstation</option>
            <option value="4865EEB04E4F5902">P&F WILSEN</option>
            <option value="4865EEB04E4F5905">NOYSEE 05</option>
            <option value="4865EEB04E4F5906">NOYSEE 06</option>
            <option value="4865EEB04E4F5907">NOYSEE 07</option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'edit' || mode === 'add'" class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.btMac.invalid">
          <label for="input-btMac">BT-Mac *</label>
          <input placeholder="" id="input-btMac" formControlName="btMac" />
          <div *ngIf="f.btMac.invalid && f.btMac.errors?.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.btMac.invalid && f.btMac.errors?.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.simICCID.invalid">
          <label for="input-simICCID"><span>SIM *</span></label>

          <input placeholder="" id="input-simICCID" formControlName="simICCID" />
          <div *ngIf="f.simICCID.invalid && f.simICCID.errors.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.simICCID.invalid && f.simICCID.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-field">
          <label>M2M-Portal</label>
          <a (click)="showM2MInfo()" class="btn-link">
            <mat-icon class="material-icons-outline">launch</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.imei.invalid">
          <label for="input-imei"><span>IMEI</span></label>
          <input placeholder="" id="input-imei" formControlName="imei" />
          <div *ngIf="f.imei.invalid && f.imei.errors.required" class="error-message ch-margin-top-4">
            Bitte Wert angeben
          </div>
          <div *ngIf="f.simICCID.invalid && f.imei.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field" [class.error]="f.pcbSN.invalid">
          <label for="input-pcbSN"><span>PCB Nr</span></label>
          <input placeholder="" id="input-pcbSN" formControlName="pcbSN" />
          <div *ngIf="f.pcbSN.invalid && f.pcbSN.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-field" [class.error]="f.pcbSN.invalid">
          <label for="input-batterySN"><span>Akku Nr</span></label>
          <input placeholder="" id="input-batterySN" formControlName="batterySN" />
          <div *ngIf="f.batterySN.invalid && f.batterySN.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 10 Zeichen angeben
          </div>
        </div>
      </div>

      <div class="col-3 role">
        <div class="form-field" [class.error]="f.state.invalid">
          <label for="select-loraModule">LoRa-Modul</label>
          <select [attr.disabled]="null" id="select-loraModule" formControlName="loraModule">
            <option selected disabled hidden>Auswählen...</option>
            <option>RN2483</option>
            <option>LoRa-E5</option>
            <option value="-">nicht bestückt</option>
          </select>
        </div>
      </div>

      <div class="col-4 role">
        <div class="form-field" [class.error]="f.state.invalid">
          <label for="select-pcieModule">PCIE-Modul</label>
          <select [attr.disabled]="null" id="select-pcieModule" formControlName="pcieModule">
            <option selected disabled hidden>Auswählen...</option>
            <option>SIM7000G B04</option>
            <option>SIM7000G B05</option>
            <option>Sony altair 1250</option>
            <option>Sony altair 1350</option>
            <option value="-">nicht bestückt</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.software.invalid">
          <label for="input-software"><span>Software</span></label>
          <input placeholder="" id="input-software" formControlName="software" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.productionDate.invalid">
          <label for="input-productionDate"><span>Produktion</span></label>
          <input type="date" placeholder="" id="input-productionDate" formControlName="productionDate" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.testDate.invalid">
          <label for="input-software"><span>Test</span></label>
          <input type="date" placeholder="" id="input-testDate" formControlName="testDate" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-field" [class.error]="f.testDate.invalid">
          <label for="input-software"><span>Tester</span></label>
          <input placeholder="" id="input-qsBy" formControlName="qsBy" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-6">
        <div class="form-field" [class.error]="f.deliveryDate.invalid">
          <label for="input-deliveryDate"><span>Auslieferung</span></label>
          <input type="date" placeholder="" id="input-deliveryDate" formControlName="deliveryDate" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-field" [class.error]="f.deliveryDate.invalid">
          <label for="input-maueInventoryNumber"><span>MAuE-InventoryNumber</span></label>
          <input placeholder="" id="input-maueInventoryNumber" formControlName="maueInventoryNumber" />
          <div *ngIf="f.maueInventoryNumber.invalid && f.maueInventoryNumber.errors.maxlength" class="error-message ch-margin-top-4">
            Bitte maximal 25 Zeichen angeben
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="f.remark.invalid">
          <label for="input-remark"><span>Bemerkung</span></label>
          <input placeholder="" id="input-remark" formControlName="remark" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudDeviceForm.disabled">
          Speichern
        </button>
        <button class="btn-secondary" (click)="goBack()" *ngIf="crudDeviceForm.disabled">
          Zurück
        </button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudDeviceForm.disabled">
          Abbrechen
        </button>
      </div>
    </div>
  </form>
</div>