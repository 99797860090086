import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import STATUS from '../../../../../assets/status.json';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../../services/authentication.service';
import { LookupService } from 'src/app/services/lookup.service';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  email: string;
  phone: string;
  frontendVersion: string;
  frontendGitHash: string;
  isSuperAdmin: boolean;

  backendVersion: string;
  backendBuildDate: string;

  constructor(private readonly httpClient: HttpClient,
    private lookupService: LookupService,
    private readonly authenticationService: AuthenticationService) {
    this.isSuperAdmin = false;
    this.email = environment.support?.email;
    this.phone = environment.support?.phone;

    this.backendVersion = "?";
    this.backendBuildDate = "?";

    if (this.authenticationService.isSuperAdmin()) {
      this.isSuperAdmin = true;
      this.frontendVersion = STATUS.version;
      this.frontendGitHash = STATUS.commitHash;
    }

  }

  async ngOnInit() {
    this.lookupService.getBackendStatus().then(data => {
      console.debug(data);
      this.backendVersion = data.subsystem["backend-database"].release;
      this.backendBuildDate = data.subsystem["backend-database"]["maven-build-date"];
    });
  }


}
