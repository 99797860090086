import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { SensorState } from '../noysee/sensor.state';

@Injectable({ providedIn: 'root' })
export class WeatherService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  async getWeatherData(): Promise<any> {
    const sensor = this.store.selectSnapshot(SensorState.currentSensor);
    return this.http
      .get(environment.dashboardApiUrl + '/sensorBox/weather?id=' + sensor.id)
      .toPromise();
  }
}
