<div class="primary-panel-small container-fluid">
	<div class="row">
		<div class="col-8">
			<h1 *ngIf="mode == 'view'">Produkt</h1>
			<h1 *ngIf="mode == 'edit'">Produkt bearbeiten</h1>
		</div>
	</div>
	<form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveProduct()">
		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-name">Name *</label>
					<input placeholder="" id="input-name" formControlName="name" />
					<div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">
						Bitte einen Namen für die Lieferung wählen!
					</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.description.invalid">
					<label for="input-description"><span>Beschreibung</span></label>
					<textarea placeholder="" id="input-description" formControlName="description" rows="5"></textarea>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<div class="form-field">
					<label for="name">Preis ( € )</label>
					<input id="name" formControlName="price" />
				</div>
			</div>

			<div class="col-6">
				<div class="form-field" [class.error]="false">
					<label for="input-customer">Abrechnungsintervall</label>
					<select formControlName="billingInterval" (change)="setBillingInterval($event)">
						<option [value]="0">Einmalig</option>
						<option [value]="1">Monatlich</option>
						<option [value]="12">Jährlich</option>
						<option [value]="36">3 Jahre</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24" *ngIf="delivery.billingInterval != 0">
			<div class="col-3">
				<div class="form-field">
					<label for="contractPeriodBase">Initiale Laufzeit (Monate)</label>
					<select id="contractPeriodBase" formControlName="contractPeriodBase">
						<option [value]="null">-</option>
						<option [value]="24">2 Jahre</option>
						<option [value]="36">3 Jahre</option>
					</select>
				</div>
			</div>

			<div class="col-3">
				<div class="form-field">
					<label for="contractPeriodExtension">Verlängerung (Monate)</label>
					<select id="contractPeriodExtension" formControlName="contractPeriodExtension">
						<option [value]="null">-</option>
						<option [value]="6">6 Monate</option>
						<option [value]="12">1 Jahre</option>
						<option [value]="24">2 Jahre</option>
					</select>
				</div>
			</div>

			<div class="col-3">
				<div class="form-field">
					<label for="noticePeriod">Kündigungsfrist (Monate)</label>
					<select id="noticePeriod" formControlName="noticePeriod">
						<option [value]="null">-</option>
						<option [value]="1">1 Monate</option>
						<option [value]="2">2 Monate</option>
						<option [value]="3">3 Monate</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-4">
				<div class="form-field">
					<label for="input-billingMethod">Abrechnungsart</label>
					<select formControlName="billingMethod">
						<option value="MAuE">MAuE (automatisch)</option>
						<option value="NOYSEE">NOYSEE (über MAuE)</option>
						<option value="MANUAL">manuell</option>
						<option value="NONE">keine Abrechnung</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<div class="form-field">
					<label for="input-dmat">DMat</label>
					<input placeholder="" id="input-dmat" formControlName="dmat" />
				</div>
			</div>
			<div class="col-9">
				<div class="form-field">
					<label for="input-maueDeviceModel">MAuE DeviceModel</label>
					<input placeholder="" id="input-maueDeviceModel" formControlName="maueDeviceModel" />
					<div *ngIf="f.mappingError.value!=null" class="error-message ch-margin-top-4">{{f.mappingError.value}}</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<button class="btn-primary" *ngIf="!crudForm.disabled">
					Speichern
				</button>
			</div>
			<div class="col-3">
				<button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled">
					Abbrechen
				</button>
			</div>
			<div class="col-3"></div>
		</div>
	</form>
</div>