<div class="mat-dialog-title">
  <p><strong appTranslate="sensor.test-message.headline"></strong></p>
</div>
<div class="form-field">
  <label for="test-message" appTranslate="sensor.test-message.message"></label>
  <input id="test-message" [(ngModel)]="message"/>
</div>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="sendTestMessage()"
          appTranslate="sensor.test-message.actions.send"></button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()"
          appTranslate="sensor.test-message.actions.cancel"></button>
</mat-dialog-actions>
