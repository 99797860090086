<mat-dialog-content>
  <h1 [appTranslate]="title"></h1>
  <image-cropper
    [imageChangedEvent]="loadedImage"
    [maintainAspectRatio]="false"
    format="jpeg"
    [resizeToHeight]="600"
    [resizeToWidth]="600"
    [onlyScaleDown]="true"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  >
  </image-cropper>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    (click)="confirm()"
    appTranslate="profile-image.crud.actions.save"
  ></button>
  <button
    mat-button
    (click)="cancel()"
    appTranslate="profile-image.crud.actions.cancel"
  ></button>
</mat-dialog-actions>
