import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ContactsComponent } from '../contacts/contacts.component';
import { Store } from '@ngxs/store';
import { SensorActions } from 'src/app/state/noysee/sensor.action';
import { SensorState } from 'src/app/state/noysee/sensor.state';
import { ValuesComponent } from '../values/values.component';
import { HistoryComponent } from '../history/history.component';
import { DescriptionComponent } from '../description/description.component';
import { SensorBoxAlarmActionsComponent } from '../alarmActions/alarmActions.component';
import { AdminShortcutsComponent } from '../adminShortcuts/adminShortcuts.component';
import { SensorBoxAlarmGroupsComponent } from '../alarmGroups/alarmGroups.component';
import { WeatherComponent } from '../weather/weather.component';

@Component({
  selector: 'app-sensors-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnDestroy {
  sensorBox$ = this.store.select(SensorState.currentSensor);
  destroy$ = new ReplaySubject<void>(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    // Fetch sensor initally
    this.store.dispatch(new SensorActions.Select(id));
    // Fetch new sensor if id changes
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const eventId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        if (id !== eventId) {
          id = eventId;
          this.store.dispatch(new SensorActions.Select(id));
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SensorActions.Unselect());
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOutletLoaded(
    component:
      | ValuesComponent
      | HistoryComponent
      | ContactsComponent
      | DescriptionComponent
      | SensorBoxAlarmActionsComponent
      | AdminShortcutsComponent
      | SensorBoxAlarmGroupsComponent
      | WeatherComponent,
  ) {
    switch (component.constructor) {
      case ValuesComponent:
      case HistoryComponent:
      case WeatherComponent:
        // Do nothing and do not provide selected sensor
        break;
      default:
        // FIXME: This is old and stupid logic, just fetch the selected sensor using the store instead
        (component as any).sensor = this.sensorBox$;
    }
    // TODO: Figure out if this is required after history and values become the same component
  }
}
