import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { formatDate, formatNumber, registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import { latLng } from 'leaflet';
import { AlarmRuleService } from '../../../../../state/noysee/alarmRule.service';
import {
  AlarmGroup,
  AlarmRule,
} from '../../../../../state/noysee/models/alarmGroup';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize,
} from '../../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { AlarmLevelStates, LookupEntry } from 'src/app/models/apiResults';
import {
  MessageTemplate,
  getTemplateClasses,
} from 'src/app/models/messageTemplate';
import { LookupService } from 'src/app/services/lookup.service';
import { MessageTemplateService } from 'src/app/services/messageTemplate.service';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-messageTemplates-overview',
  templateUrl: './messageTemplates.component.html',
  styleUrls: ['./messageTemplates.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  customerSubscription: Subscription;

  dataTableOptions: DataTableOptions<MessageTemplate>;
  list: MessageTemplate[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private messageTemplateService: MessageTemplateService,
    private applicationContextService: ApplicationContextService,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private router: Router
  ) {
    // this.noyseeDeviceSubscription = null;
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    const _this = this;
    _this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    _this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'messageTemplate.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'messageTemplate.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () =>
            this.router.navigate(
              ['/dashboard', 'admins', 'messageTemplates', 'add'],
              { queryParams: { ctx: this.getGridState() } }
            ),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'messageTemplate.table.actions.edit',
            icon: 'app-pencil',
            actionFn: (messageTemplate: MessageTemplate) =>
              this.editAlarmRule(messageTemplate.id),
          },
          {
            label: 'messageTemplate.table.actions.delete',
            icon: 'delete',
            actionFn: (messageTemplate: MessageTemplate) =>
              this.deleteAlarmRule(messageTemplate.id),
            dataRule: (messageTemplate: MessageTemplate) =>
              messageTemplate.customer != null,
          },
        ],
      },
      // rowStyleFn: (alarmRule: MessageTemplate) => {
      //    return alarmRule.active ? {
      //      'background-color': this.getStateColor(alarmRule.currentAlarmLevel),
      //      'border': 'solid 1.5px white'
      //    } : {
      //      'border': 'solid 1.5px white'
      //    };
      //  },
      columnDef: [
        {
          key: 'id',
          displayName: 'messageTemplate.table.columns.id',
          width: '5%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'templateClass',
          displayName: 'messageTemplate.table.columns.templateClass',
          width: '20%',
          sortable: true,
          valueTransform: (messageTemplate: MessageTemplate) =>
            `messageTemplate.templateClasses.${messageTemplate.templateClass}`,
          filterType: TableFilterType.SELECT,
          filterValues: getTemplateClasses(),
        },
        {
          key: 'name',
          displayName: 'messageTemplate.table.columns.name',
          width: this.isSuperAdmin ? '45%' : '55%',
          sortable: true,
          styleFn: (messageTemplate: MessageTemplate) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (messageTemplate: MessageTemplate) =>
            this.router.navigate([
              '/dashboard',
              'admins',
              'messageTemplates',
              messageTemplate.id,
              'edit',
            ]),
        },
        {
          key: 'owner',
          displayName: 'messageTemplate.table.columns.owner',
          width: '20%',
          sortable: true,
          valueTransform: (messageTemplate: MessageTemplate) =>
            `${
              messageTemplate.customer
                ? messageTemplate.customer.name
                : '- STANDARD -'
            }`,
        },
        {
          key: 'state',
          displayName: 'messageTemplate.table.columns.state',
          width: '10%',
          sortable: true,
          valueTransform: (messageTemplate: MessageTemplate) =>
            `alarmGroup.state.${messageTemplate.active}`,
        },
      ],
    };

    // TODO
    // this.customerSubscription = this.applicationContextService.selectedCustomer
    //   .subscribe(shared => {
    //     console.debug('Focus Customer changed');
    //     this.loadList();
    //   });

    this.loadList();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }

  getStateColor(state: AlarmLevelStates) {
    switch (state) {
      case AlarmLevelStates.NORMAL:
        return '#c8d89c';
      case AlarmLevelStates.LIMIT_1:
      case AlarmLevelStates.ERROR:
      case AlarmLevelStates.DEFECTIVE:
      case AlarmLevelStates.DISABLED:
        return 'rgba(255, 171, 49, 0.4)';
      case AlarmLevelStates.LIMIT_2:
      case AlarmLevelStates.LIMIT_3:
      case AlarmLevelStates.LIMIT_4:
      case AlarmLevelStates.LIMIT_5:
        return '#ffc7cc';
      case AlarmLevelStates.PLANNED:
      default: // No known state is active
        return 'rgba(204, 204, 204, 0.6)';
    }
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState()
    );
  }

  getTemplateClasses(): LookupEntry[] {
    return [];
  }

  loadList() {
    this.messageTemplateService.getList().then((result) => {
      this.list = result;
    });
  }

  editAlarmRule(id: number) {
    this.router.navigate([
      '/dashboard',
      'admins',
      'messageTemplates',
      id,
      'edit',
    ]);
  }

  deleteAlarmRule(id: number) {
    if (id <= 0) {
      return;
    }
    const _this = this;
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie dieses Template wirklich löschen?'
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.messageTemplateService.delete(id).then((result) => {
            this.toastHelper.openSnackBar(
              'Alarmregel erfolgreich gelöscht!',
              'SUCCESS'
            );
            this.loadList();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
