import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { UsersService } from '../../../../../services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { SensorBoxHardwareStates } from '../../../../../state/noysee/models/sensor';
import { GridState } from 'src/app/shared/data-table/data-table.model';
import { DataTableStateService } from 'src/app/shared/data-table-state/data-table-state.service';
import { SensorBox } from '../../../../../state/noysee/models/sensorBox';

@Component({
  selector: 'app-adminShortcuts',
  templateUrl: './adminShortcuts.component.html',
  styleUrls: ['./adminShortcuts.component.scss'],
})
export class AdminShortcutsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  set sensor(sensor$: Observable<SensorBox>) {
    this.sensorSubscription?.unsubscribe();
    this.sensorSubscription = sensor$.subscribe(async (sensor) => {
      if (sensor) {
        this.currentSensor = sensor;

        this.sensorName = sensor.name;
        this.currentSensorId = sensor.id;
        this.hardwareState = this.currentSensor.hardwareState;

        if (sensor.sensors[sensor.primarySensor]) {
          this.limit1 = sensor.sensors[sensor.primarySensor].limit1;
          this.limit2 = sensor.sensors[sensor.primarySensor].limit2;
          this.unit = sensor.sensors[sensor.primarySensor].unit;
        }

        // this.reloadAlarmGroups();
      }
    });
  }

  SensorBoxHardwareStates = SensorBoxHardwareStates;

  currentSensorId: number;
  currentSensor: SensorBox;

  parent: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  sensorName: string;
  sensorSubscription: Subscription;

  gridState: GridState = null;

  limit1: number;
  limit2: number;
  unit: string;

  hardwareState: number;

  constructor(
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private usersService: UsersService,
    private dataTableStateService: DataTableStateService,
    private noyseeService: NoyseeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.currentSensorId = null;
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.sensorSubscription?.unsubscribe();
  }

  setHardwareState(state: SensorBoxHardwareStates) {}
}
