<div class="values-wind bordered">
  <div class="values-wind-content">
    <div class="indicator north">
      <div class="indicator-text bordered">N</div>
    </div>
    <div class="indicator east">
      <div class="indicator-text bordered">O</div>
    </div>
    <div class="indicator south">
      <div class="indicator-text bordered">S</div>
    </div>
    <div class="indicator west">
      <div class="indicator-text bordered">W</div>
    </div>
    <!-- TODO: figure out whtat status-x class was used for-->
    <p *ngIf="windSpeed" class="values-text bordered status-{{ 'normal' }}">
      {{ windSpeed.currentDataValue }}&nbsp;{{ windSpeed.unit }}
    </p>
    <div class="wind-direction">
      <img
        src="../../../../../../../assets/img/arrow_long_right.svg"
        alt="Indicator for wind direction"
        [ngStyle]="{
          transform: 'rotate(' + (windDirection - 90) + 'deg)'
        }"
      />
    </div>
  </div>
</div>
