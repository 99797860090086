<div class="primary-panel-large container-fluid">
	<div class="row">
		<div class="col-8">
			<h1 *ngIf="mode == 'add'">Gebiet anlegen</h1>
			<h1 *ngIf="mode == 'edit'">Gebiet bearbeiten
				({{geoPolygone.id}})</h1>
		</div>
	</div>
	<form *ngIf="crudForm" class="form" [formGroup]="crudForm"
		(ngSubmit)="save()">

		<div *ngIf="false" class="row ch-margin-top-24">
			<div class="col-1 role">
				<strong>Aktiv</strong>
			</div>
			<div class="col-4 role">
				<div class="form-field"
					[class.error]="submitted && f.active.invalid">
					<label class="switch"> <input type="checkbox"
						(click)="toggleActive()" id="input-active"
						formControlName="active"> <span class="slider round"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-name">Name *</label> <input placeholder=""
						id="input-name" formControlName="name">
					<div *ngIf="submitted && f.name.invalid"
						class="error-message ch-margin-top-4">Bitte einen Namen für
						die Vorlage wählen!</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<div class="form-field" [class.error]="false">
					<label for="select-polygoneType">Gebietstyp</label> <select
						formControlName="polygoneType">
						<option [value]="PolygoneType.EG_std">Einzugsgebiet</option>
						<option [value]="PolygoneType.EG_custom">Einzugsgebiet
							(individuell)</option>
						<option [value]="PolygoneType.VG_kreis">VG250 Kreis</option>
						<option [value]="PolygoneType.VG_gem">VG250 Gemeinde</option>
					</select>
				</div>
			</div>
			<div class="col-9">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-externalId">Externe ID</label> <input
						placeholder="" id="input-externalId" formControlName="externalId">
					<div *ngIf="submitted && f.externalId.invalid"
						class="error-message ch-margin-top-4">Bitte eine externe ID für
						das Gebiet wählen!</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field"
					[class.error]="submitted && f.description.invalid">
					<label for="input-description"><span>Beschreibung</span></label>
					<textarea placeholder="" id="input-description"
						formControlName="description" rows="5"></textarea>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3"></div>
			<div class="col-2">
				<div class="form-field">
					<label for="input-latNorth">Nord</label> 
					<input placeholder="" id="input-latNorth" value="{{geoPolygone.latNorth}}" disabled/>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-2">
				<div class="form-field">
					<label for="input-lonWest">West</label> <input
						placeholder="" id="input-lonWest" value="{{geoPolygone.lonWest}}" disabled/>
				</div>
			</div>
			<div class="col-2">
				<div class="form-field" [class.error]="submitted && f.latCenter.invalid">
					<label for="input-latCenter">Länge (Zentrum)</label> <input
						placeholder="" id="input-latCenter" formControlName="latCenter">
					<div *ngIf="submitted && f.latCenter.invalid"
						class="error-message ch-margin-top-4">Bitte eine Längengrad für
						die Gebietsmitte definieren!</div>
				</div>
			</div>
			<div class="col-2">
				<div class="form-field" [class.error]="submitted && f.lonCenter.invalid">
					<label for="input-lonCenter">Länge (Zentrum)</label> <input
						placeholder="" id="input-lonCenter" formControlName="lonCenter">
					<div *ngIf="submitted && f.lonCenter.invalid"
						class="error-message ch-margin-top-4">Bitte eine externe ID für
						das Gebiet wählen!</div>
				</div>
			</div>
			<div class="col-2">
				<div class="form-field">
					<label for="input-lonEast">Ost</label> <input
						placeholder="" id="input-lonEast" value="{{geoPolygone.lonEast}}" disabled/>
				</div>
			</div>
		</div>

		<div class="row	ch-margin-top-24">
			<div class="col-3">
			</div>
			<div class="col-2">
				<div class="form-field">
					<label for="input-latSouth">Süd</label> <input
						placeholder="" id="input-latSouth" value="{{geoPolygone.latSouth}}" disabled/>
				</div>
			</div>
		</div>



		<div class="row ch-margin-top-24">
			<div class="col-3">
				<button class="btn-primary" *ngIf="!crudForm.disabled">Speichern</button>
			</div>
			<div class="col-3">
				<button class="btn-secondary" (click)="goBack()"
					*ngIf="!crudForm.disabled">Abbrechen</button>
			</div>
			<div class="col-3"></div>
			<div *ngIf="mode === 'edit'" class="col-3">
				<button type="button" class="btn-secondary"
					(click)="deleteGeoPolygone()" *ngIf="!crudForm.disabled">Löschen</button>
			</div>
		</div>
	</form>

</div>