<div class="primary-panel-large container-fluid">
  <div class="row">
    <div class="col-12">
      <h2>Colors</h2>
    </div>
  </div>
  <div class="row ch-padding-left-24">
    <div class="col-1 color-box color-white">
      <p>#ffffff</p>
    </div>
    <div class="col-1 color-box color-grey-light4">
      <p>#fafafa</p>
    </div>
    <div class="col-1 color-box color-grey-light3">
      <p>#f2f2f2</p>
    </div>
    <div class="col-1 color-box color-grey-light2">
      <p>#e6e6e6</p>
    </div>
    <div class="col-1 color-box color-grey-light1">
      <p>#cccccc</p>
    </div>
    <div class="col-1 color-box color-grey">
      <p>#999999</p>
    </div>
    <div class="col-1 color-box color-dark">
      <p>#333333</p>
    </div>
  </div>
  <div class="row ch-padding-left-24 ch-margin-top-32">
    <div class="col-1 color-box color-enbw-blue-light">
      <p>#eef8fe</p>
    </div>
    <div class="col-1 color-box color-enbw-blue-light2">
      <p>#4eb9ef</p>
    </div>
    <div class="col-1 color-box color-enbw-blue">
      <p>#0e0ec4</p>
    </div>
    <div class="col-1 color-box color-enbw-blue-dark">
      <p>#020280</p>
    </div>
  </div>
  <div class="row ch-padding-left-24 ch-margin-top-32">
    <div class="col-1 color-box color-enbw-orange-light">
      <p>#fff9ef</p>
    </div>
    <div class="col-1 color-box color-enbw-orange">
      <p>#ffab31</p>
    </div>
    <div class="col-1 color-box color-enbw-orange-dark">
      <p>#ffab31</p>
    </div>
  </div>
  <div class="row ch-padding-left-24 ch-margin-top-32">
    <div class="col-1 color-box color-red-light">
      <p>#ffc7cc</p>
    </div>
    <div class="col-1 color-box color-red">
      <p>#e2001a</p>
    </div>
  </div>
  <div class="row ch-padding-left-24 ch-margin-top-32">
    <div class="col-1 color-box color-green-light">
      <p>#c8d89c</p>
    </div>
    <div class="col-1 color-box color-green">
      <p>#94c11c</p>
    </div>
  </div>
  <hr class="ch-margin-top-40"/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Fonts</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <h1>Title H1</h1>
      <p>Font size: 32px</p>
    </div>
    <div class="col-3">
      <h2>Title H2</h2>
      <p>Font size: 24px</p>
    </div>
    <div class="col-3">
      <p>Paragraph</p>
      <p>Font size: 16px</p>
    </div>
    <div class="col-3">
      <div class="ch-blue-box ch-margin-bottom-24 ch-padding-8">
        <h1 class="ch-white">H1 White</h1>
        <p class="ch-white">Paragraph White</p>
      </div>
      <div class="links">
        <a href="/">Link</a>
        <p>Font size: 16px</p>
      </div>
    </div>
  </div>
  <hr/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Buttons</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <button mat-flat-button class="btn-primary">Speichern</button>
    </div>
    <div class="col-2">
      <button mat-flat-button class="btn-secondary">Abbrechen</button>
    </div>
    <div class="col-1">
      <button mat-flat-button class="btn-primary btn-small">
        <img src="../../../../../assets/img/three-dots-white.svg" alt="Ausklappmenü"/>
      </button>
    </div>
    <div class="col-1">
      <button mat-flat-button class="btn-secondary btn-small">
        <img src="../../../../../assets/img/three-dots-blue.svg" alt="Ausklappmenü"/>
      </button>
    </div>
    <div class="col-1">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
        <img src="../../../../../assets/img/three-dots-blue.svg" alt="Ausklappmenü"/>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <img class="edit-icon" src="../../../../../assets/img/pencil-alt-blue.svg" alt="edit-bollard"/>
          <span>Test bearbeiten</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="blue-icon">not_interested</mat-icon>
          <span>Test löschen</span>
        </button>
      </mat-menu>
    </div>
    <div class="col-1">
      <button mat-flat-button class="btn-secondary btn-small">
        <span class="ch-action close"></span>
      </button>
    </div>
    <div class="col-2">
      <button mat-flat-button class="btn-tertiary">
        <img src="../../../../../assets/img/pencil-alt-blue.svg" alt="Bearbeiten"/>
        <p>bearbeiten</p>
      </button>
    </div>
    <div class="col-2">
      <span class="ch-action close">Bereich schließen</span>
    </div>
  </div>
  <hr/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Meldungen</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <button mat-flat-button class="btn-primary"
              (click)="openToast('Speichern erfolgreich', 'SUCCESS')">Success
      </button>
    </div>
    <div class="col-6">
      <button mat-flat-button class="btn-primary"
              (click)="openToast('Das Formular konnte nicht gespeichert werden', 'ERROR')">Error
      </button>
    </div>
  </div>
  <hr/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Formulare</h2>
    </div>
  </div>
  <div class="form">
    <div class="row">
      <div class="col-4">
        <div class="form-field">
          <label for="input-1">Feld 1</label>
          <input placeholder="Platzhalter" id="input-1">
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="input-2">Feld 2</label>
          <input placeholder="Deaktiviert" id="input-2" disabled>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field" [class.error]="submitted && inputControl.invalid">
          <label for="input-3">Feld 3 *</label>
          <input placeholder="Platzhalter" id="input-3" [formControl]="inputControl">
          <div *ngIf="submitted && inputControl.invalid
                            && inputControl.errors.required" class="error-message ch-margin-top-4">Bitte Wert angeben
          </div>
          <div *ngIf="submitted && inputControl.invalid
                            && inputControl.errors.maxlength" class="error-message ch-margin-top-4">Bitte maximal 10
            Zeichen angeben
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="select-1">Auswahl 1</label>
          <select id="select-1">
            <option selected disabled hidden></option>
            <option>Feld 1</option>
            <option>Feld 2</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="select-2" class="ch-margin-bottom-4">Auswahl 2</label>
          <select id="select-2" disabled>
            <option>Deaktiviert</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field" [class.error]="submitted && selectControl.invalid">
          <label for="select-3">Auswahl 3 *</label>
          <select id="select-3" [formControl]="selectControl">
            <option selected disabled hidden>Auswählen...</option>
            <option>Test 1</option>
            <option>Test 2</option>
            <option>Test 3</option>
          </select>
          <div *ngIf="submitted && selectControl.invalid
                            && selectControl.errors.required" class="error-message ch-margin-top-4">Bitte Wert auswählen
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="autocomplete-1" class="ch-margin-bottom-4">Autocomplete 1</label>
          <input id="autocomplete-1" type="text" placeholder="Bitte auswählen" matInput [matAutocomplete]="auto1"
                 disabled>
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option>Deaktiviert</mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field" [class.error]="submitted && autocompleteControl.invalid">
          <label for="autocomplete-2" class="ch-margin-bottom-4">Autocomplete 2 *</label>
          <input id="autocomplete-2" type="text" placeholder="Bitte auswählen" matInput [matAutocomplete]="auto2"
                 [formControl]="autocompleteControl">
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <div *ngIf="submitted && autocompleteControl.invalid
                            && autocompleteControl.errors.required" class="error-message ch-margin-top-4">Bitte Wert
            auswählen
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="date-1-start">Zeitraum 1</label>
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate id="date-1-start" placeholder="Von">
            <input matEndDate id="date-1-end" placeholder="Bis">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field">
          <label for="date-2-start">Zeitraum 2</label>
          <mat-date-range-input [rangePicker]="picker2" disabled>
            <input matStartDate id="date-2-start" placeholder="Von">
            <input matEndDate id="date-2-end" placeholder="Bis">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </div>
      </div>
      <div class="col-4">
        <div class="form-field" [class.error]="submitted && range.invalid">
          <label for="date-3-start">Zeitraum 3 *</label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker3">
            <input matStartDate formControlName="start" id="date-3-start" placeholder="Von">
            <input matEndDate formControlName="end" id="date-3-end" placeholder="Bis">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-date-range-picker #picker3></mat-date-range-picker>
          <div *ngIf="submitted && range.invalid
                            && (range.controls.start.errors && range.controls.start.errors.required)
                            && (range.controls.end.errors && range.controls.end.errors.required)"
               class="error-message ch-margin-top-4">
            Bitte Datum auswählen
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="repeat">Wiederholen am *</label>
          <select id="repeat">
            <option selected>Personalisiert</option>
          </select>
        </div>
      </div>
      <div class="col-8">
        <div class="form-field">
          <div class="select-day-container">
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Mo</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Di</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Mi</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Do</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Fr</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">Sa</div>
            <div class="select-day" (click)="toggleSelectedDay($event.target)">So</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <div class="form-field">
          <mat-checkbox>Checkbox</mat-checkbox>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field">
          <mat-checkbox checked>Checkbox</mat-checkbox>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field">
          <mat-checkbox disabled>Checkbox</mat-checkbox>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field" [class.error]="submitted && checkboxControl.invalid">
          <mat-checkbox [formControl]="checkboxControl">Checkbox *</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <div class="form-field">
          <label for="slide-toggle-1">Toggle 1</label>
          <mat-slide-toggle id="slide-toggle-1"></mat-slide-toggle>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field">
          <label for="slide-toggle-2">Deaktiviert</label>
          <mat-slide-toggle id="slide-toggle-2" disabled></mat-slide-toggle>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field">
          <label for="slide-toggle-3">Deaktiviert</label>
          <mat-slide-toggle id="slide-toggle-3" disabled checked></mat-slide-toggle>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field" [class.error]="submitted && toggleControl.invalid">
          <label for="slide-toggle-4">Toggle 2 *</label>
          <mat-slide-toggle id="slide-toggle-4" [formControl]="toggleControl"></mat-slide-toggle>
          <div *ngIf="submitted && toggleControl.invalid
                            && toggleControl.errors.required" class="error-message ch-margin-top-4">Bitte aktivieren
          </div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12 text-center">
        <button mat-flat-button class="btn-primary" (click)="toggleSubmitted()">Absenden</button>
      </div>
    </div>
  </div>
  <hr/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Fehlermeldungen für Forms</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <p><strong>Input</strong></p>
      <p>Pflichtfeld leer</p>
      <p>Maximale Länge</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie einen Wert ein</p>
      <p>Bitte maximal [x] Zeichen eingeben</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Input number</strong></p>
      <p>Format falsch</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie einen numerischen Wert ein</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Dropdown multiselect</strong></p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte wählen Sie einen Eintrag aus</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Dropdown einfach</strong></p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte wählen Sie mind. einen Eintrag aus</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Datum</strong></p>
      <p>Format falsch</p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie ein gültiges Datum (TT.MM.JJJJ) ein</p>
      <p>Bitte geben Sie ein Datum ein</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Uhrzeit</strong></p>
      <p>Format falsch</p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie eine gültige Uhrzeit (hh.mm.ss) ein</p>
      <p>Bitte geben Sie eine Uhrzeit ein</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>E-Mail</strong></p>
      <p>Format falsch</p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie eine gültige E-Mail-Adresse ein (z.B. m.mustermann@enbw.de)</p>
      <p>Bitte geben Sie eine E-Mail-Adresse ein</p>
    </div>
  </div>
  <div class="row ch-margin-top-8">
    <div class="col-3">
      <p><strong>Telefonnummer</strong></p>
      <p>Format falsch</p>
      <p>Pflichtfeld leer</p>
    </div>
    <div class="col-9">
      <p>&nbsp;</p>
      <p>Bitte geben Sie eine gültige Telefonnummer ein (z.B. 07111234567)</p>
      <p>Bitte geben Sie eine Telefonnummer ein</p>
    </div>
  </div>
  <hr/>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <h2>Mitteilungen</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="notification-container notification-warning">
        <div class="row">
          <div class="col-2 notification-icon-col">
            <img class="notification-icon" src="assets/img/barrier-defective.svg">
            <mat-icon class="blue-icon">priority_high</mat-icon>
          </div>
          <div class="col-5">
            <p class="notification-name">Beispielpoller</p>
            <h2 class="notification-type">Füllstand Kartenspender niedrig</h2>
            <p class="notification-description ch-margin-top-4">Hier könnte Ihr Kommentar stehen!</p>
          </div>
          <div class="col-5">
            <p class="notification-date">11.05.2021 15:30:00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="notification-container notification-error">
        <div class="row">
          <div class="col-2 notification-icon-col">
            <img class="notification-icon" src="../../../../../assets/img/barrier-open.svg">
            <mat-icon class="blue-icon">done</mat-icon>
          </div>
          <div class="col-5">
            <p class="notification-name">Beispielpoller</p>
            <h2 class="notification-type">Fehler</h2>
            <p class="notification-description ch-margin-top-4">Hier könnte Ihr Kommentar stehen!</p>
          </div>
          <div class="col-5">
            <p class="notification-date">11.05.2021 15:30:00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="notification-container notification-error">
        <div class="row">
          <div class="col-2 notification-icon-col">
            <img class="notification-icon" src="assets/img/barrier-disabled.svg">
            <mat-icon class="blue-icon">build</mat-icon>
          </div>
          <div class="col-5">
            <p class="notification-name">Beispielpoller</p>
            <h2 class="notification-type">Fehler</h2>
            <p class="notification-description ch-margin-top-4">Hier könnte Ihr Kommentar stehen!</p>
          </div>
          <div class="col-5">
            <p class="notification-date">11.05.2021 15:30:00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
