<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode === 'add'" appTranslate="user.crud.headlines.add"></h1>
      <h1
        *ngIf="mode === 'invite'"
        appTranslate="user.crud.headlines.invite"
      ></h1>
      <h1 *ngIf="mode === 'edit'" appTranslate="user.crud.headlines.edit"></h1>
      <h1
        *ngIf="mode === 'edit-self'"
        appTranslate="user.crud.headlines.edit-self"
      ></h1>
    </div>
    <div
      *ngIf="mode === 'edit' && (isAdmin || isSuperAdmin)"
      class="col-4 button-col"
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="open group menu"
      >
        <img
          src="../../../../../../../assets/img/three-dots-blue.svg"
          alt="Ausklappmenü"
        />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="resetPassword()">
          <span>Passwort zurücksetzen</span>
        </button>
        <button
          mat-menu-item
          (click)="reinvite()"
          [disabled]="disableReinvite"
          [class.action-menu-disabled]="disableReinvite"
        >
          <span>Einladung erneut versenden</span>
        </button>
        <button mat-menu-item (click)="lockUser()">
          <span>Login sperren</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <form
    *ngIf="crudUsersForm"
    class="form"
    [formGroup]="crudUsersForm"
    (ngSubmit)="saveUser()"
  >
    <div *ngIf="mode === 'edit-self' && !isGuest" class="row ch-margin-top-24">
      <div class="col-12 image-wrapper">
        <span class="image-viewer ch-margin-right-16">
          <img [src]="profileImage" class="profile-image" alt="Profilbild" />
        </span>
        <input
          type="file"
          id="image-upload"
          name="image-upload"
          accept="image/*"
          (change)="openImageEditor($event)"
          #imageUpload
        />
        <div class="profile-image-control">
          <button
            type="button"
            (click)="selectImage()"
            mat-flat-button
            class="btn-primary"
            appTranslate="user.crud.actions.upload-profile-picture"
          ></button>
        </div>
      </div>
    </div>

    <div
      *ngIf="(mode === 'add' || mode === 'invite') && isSuperAdmin"
      class="row ch-margin-top-24"
    >
      <div class="col-12">
        <div class="form-field">
          <label
            for="input-customer"
            appTranslate="forms.user.customer.label"
          ></label>
          <input
            placeholder=""
            id="input-customer"
            disabled
            value="{{ user.customer.name }}"
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="mode === 'edit' || mode === 'add' || mode === 'edit-self'"
      class="row ch-margin-top-24"
    >
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.username.invalid">
          <label
            for="input-username"
            appTranslate="forms.user.username.label"
            class="important"
          ></label>
          <input
            placeholder=""
            id="input-username"
            formControlName="username"
          />
          <div
            *ngIf="submitted && f.username.invalid && emailDuplicated"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.username.alreadyExists"
          ></div>
          <div
            *ngIf="f.username.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize40"
          ></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="mode === 'edit' || mode === 'add' || mode === 'edit-self'"
      class="row ch-margin-top-24"
    >
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label
            for="input-name"
            appTranslate="forms.user.name.label"
            class="important"
          ></label>
          <input placeholder="" id="input-name" formControlName="name" />
          <div
            *ngIf="submitted && f.name.invalid && f.name.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.name.error"
          ></div>
          <div
            *ngIf="f.name.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize80"
          ></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.email.invalid">
          <label
            for="input-email"
            appTranslate="forms.user.email.label"
            class="important"
          ></label>
          <input placeholder="" id="input-email" formControlName="email" />
          <div
            *ngIf="submitted && f.email.invalid && f.email.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.email.error"
          ></div>
          <div
            *ngIf="f.email.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize80"
          ></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="mode === 'edit' || mode === 'add' || mode === 'edit-self'"
      class="row ch-margin-top-24"
    >
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.mobile.invalid">
          <label
            for="input-mobile"
            appTranslate="forms.user.mobile.label"
            [class.important]="phoneRequired"
          ></label>
          <input
            placeholder="+49 30 123456"
            id="input-phone"
            formControlName="mobile"
          />
          <div
            *ngIf="submitted && f.mobile.invalid && f.mobile.errors.pattern"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.mobile.error"
          ></div>
          <div
            *ngIf="f.mobile.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize40"
          ></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="mode === 'edit' || mode === 'add' || mode === 'edit-self'"
      class="row ch-margin-top-24"
    >
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.phone.invalid">
          <label
            for="input-phone"
            appTranslate="forms.user.phone.label"
            [class.important]="phoneRequired"
          ></label>
          <input
            placeholder="+49 30 123456"
            id="input-phone"
            formControlName="phone"
          />
          <div
            *ngIf="submitted && f.phone.invalid && f.phone.errors.pattern"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.phone.error"
          ></div>
          <div
            *ngIf="f.phone.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize40"
          ></div>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'add'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.password.invalid">
          <label
            for="input-password"
            appTranslate="forms.user.password.label"
            class="important"
          ></label>
          <input
            placeholder=""
            id="input-password"
            formControlName="password"
            type="password"
          />
          <div
            *ngIf="submitted && f.password.invalid"
            class="error-message ch-margin-top-4"
          >
            <ng-container
              *ngIf="f.password.errors.required"
              appTranslate="forms.user.password.error"
            ></ng-container>
            <br />
            <ng-container
              *ngIf="f.password.errors.passwordNotMatchingPolicies"
              appTranslate="forms.user.password.matchPolicies"
            ></ng-container>
          </div>
          <div
            *ngIf="f.password.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize80"
          ></div>
          <div
            class="password-hint ch-margin-top-8"
            appTranslate="forms.user.passwordHint"
          ></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12 role">
        <div class="form-field" [class.error]="submitted && f.role.invalid">
          <label
            for="select-role"
            appTranslate="forms.user.role.label"
            class="imporant"
          ></label>
          <select
            [attr.disabled]="
              (!isSuperAdmin && !isAdmin) || (isAdmin && isEditUserSuperadmin())
                ? 'disabled'
                : null
            "
            id="select-role"
            formControlName="role"
          >
            <option selected disabled hidden>Auswählen...</option>
			<option *ngFor="let r of roles" [value]="r.value" [disabled]="r.disabled" [hidden]="r.disabled">{{r.label|translate}}</option>
          </select>
          <div
            *ngIf="submitted && f.role.invalid && f.role.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.user.role.error"
          ></div>
        </div>
        <mat-icon
          [inline]="true"
          [matTooltip]="'forms.user.role.tooltip' | translate"
          >info
        </mat-icon>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button
          class="btn-primary"
          *ngIf="!crudUsersForm.disabled"
          appTranslate="user.crud.actions.save"
        ></button>
        <button
          class="btn-secondary"
          (click)="goBack()"
          *ngIf="crudUsersForm.disabled"
          appTranslate="user.crud.actions.back"
        ></button>
      </div>
      <div class="col-3">
        <button
          class="btn-secondary"
          (click)="goBack()"
          *ngIf="!crudUsersForm.disabled"
          appTranslate="user.crud.actions.cancel"
        ></button>
      </div>
    </div>
  </form>
</div>
