import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-station-wind',
  templateUrl: './weather-station-wind.component.html',
  styleUrls: ['./weather-station-wind.component.scss'],
})
export class WeatherStationWindComponent {
  @Input() windSpeed: {
    currentDataValue: number;
    unit: string;
  };
  @Input() windDirection: number;
}
