import {Injectable} from '@angular/core';
import {LoRaWanConfigResult, LoRaWanConfigListResult} from '../models/apiResults';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {LoRaWanConfig} from '../models/loraWanConfig';

@Injectable({
  providedIn: 'root'
})
export class LoRaWanConfigService {

  constructor(private http: HttpClient) {
  }

  getList(): Promise<LoRaWanConfig[]> {
    return this.http.get<LoRaWanConfigListResult>(`${environment.dashboardApiUrl}/admin/loraWanServer/list`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }

  delete(id: number): Promise<LoRaWanConfig> {
    return this.http.delete<LoRaWanConfigResult>(`${environment.dashboardApiUrl}/admin/loraWanServer/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  load(id: number): Promise<LoRaWanConfig> {
    return this.http.get<LoRaWanConfigResult>(`${environment.dashboardApiUrl}/admin/loraWanServer/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  save(config: LoRaWanConfig): Promise<LoRaWanConfig> {
    return this.http.put<LoRaWanConfigResult>(`${environment.dashboardApiUrl}/admin/loraWanServer/detail`, config)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}

