import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { RenderedInfo } from 'src/app/state/noysee/models/sensorBox';

@Component({
  selector: 'app-edit-renderedInfo-modal',
  templateUrl: './edit-renderedInfo-modal.component.html',
  styleUrls: ['./edit-renderedInfo-modal.component.scss'],
})
export class EditRenderedInfoModalComponent implements OnInit {
  sensorId: number;
  field: string;
  content: string;
  renderedInfo: RenderedInfo;

  constructor(
    private dialogRef: MatDialogRef<EditRenderedInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noyseeService: NoyseeService
  ) {}

  ngOnInit(): void {
    this.sensorId = this.data?.id;
    this.field = this.data?.field;
    this.content = this.data?.renderedInfo[this.field];
    this.renderedInfo = this.data?.renderedInfo;
  }

  async saveRenderedInfo() {
    this.renderedInfo[this.field] = this.content;
    this.noyseeService
      .saveRenderedInfoField(this.sensorId, this.field, this.content)
      .then((result) => {
        this.renderedInfo.htmlDescription = result.htmlDescription;
        this.renderedInfo.htmlInternalDescription =
          result.htmlInternalDescription;
      });
    this.cancel();
  }

  cancel() {
    this.dialogRef.close();
  }
}
