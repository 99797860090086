import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ab-list',
  templateUrl: './ab-list.component.html',
  styleUrls: ['./ab-list.component.scss'],
})
export class AbListComponent implements OnChanges, OnDestroy {
  //  @Output()
  //  addItem: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled = false;

  _allOptions: any[];

  availableOptions: any[];

  availableFilter: string;

  @Input()
  value: any[];

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  keyField: string;

  @Input()
  descriptiveField: any;

  onChange: (value: any) => {};
  onBlur: (value: any) => {};

  destroy$: Subject<void> = new Subject();

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  _excludeList: any[];

  @Input()
  excludeListKeyField: string;

  constructor(
    @Optional() @Self() private _elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('disabled' in changes) {
      this.toggleDisable();
    }
  }

  @Input() public set allOptions(v: any[]) {
    this._allOptions = v;
    this.updateAvailableOptions();
    console.debug(v);
  }

  get allOptions() {
    return this._allOptions;
  }

  @Input() public set excludeList(v: any[]) {
    this._excludeList = v;
    this.updateAvailableOptions();
  }

  get excludeList() {
    return this._excludeList;
  }

  toggleDisable() {
    if (this.disabled !== false) {
    } else {
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  updateAvailableOptions(): void {
    if (!this.value) {
      this.value = [];
    }
    if (this.allOptions) {
      this.availableOptions = this._allOptions.filter((entry) => {
        if (this._excludeList && this.excludeListKeyField) {
          return (
            !this.value.some(
              (alreadyAssignedEntry) =>
                alreadyAssignedEntry[this.keyField] === entry[this.keyField],
            ) &&
            !this._excludeList.some(
              (entryToExclude) =>
                entryToExclude[this.excludeListKeyField] ===
                entry[this.keyField],
            )
          );
        } else {
          return !this.value.some(
            (selectedEntry) =>
              selectedEntry[this.keyField] === entry[this.keyField],
          );
        }
      });
    }
    console.debug(this.availableFilter);
    if (
      this.availableOptions &&
      this.availableFilter &&
      this.descriptiveField
    ) {
      this.availableOptions = this.availableOptions.filter((entry) => {
        return entry[this.descriptiveField].includes(this.availableFilter);
      });
    }
  }

  removeItem(itemToDelete: any) {
    this.value = this.value.filter(
      (item) => item[this.keyField] != itemToDelete[this.keyField],
    );
    this.valueChange.emit(this.value);
    this.change.emit();
    this.updateAvailableOptions();
  }

  addItem(itemToAdd: any) {
    this.value.push(itemToAdd);
    this.valueChange.emit(this.value);
    this.change.emit();
    this.updateAvailableOptions();
  }
}
