<div
  *ngIf="currentSensorBox$ | async as sensorBox"
  class="primary-panel-medium container-fluid"
>
  <div class="row">
    <div class="col-6">
      <h1>Command-Queue ({{ sensorBox.id }})</h1>
    </div>
  </div>
  <div class="row ch-margin-top-24">
    <div class="col-1">
      <label for="input-name">Name</label>
    </div>
    <div class="col-10">
      <strong>{{ sensorBox.name }}</strong>
    </div>
  </div>
  <div class="row ch-margin-top-24">
    <app-data-table
      [dataTableOptions]="dataTableOptions"
      [state]="gridState"
      [data]="commandQueue"
    ></app-data-table>
  </div>
</div>
