<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode == 'view'">Lieferung ({{delivery.id}})</h1>
      <h1 *ngIf="mode == 'edit'">Lieferung bearbeiten ({{delivery.id}})</h1>
      <h1 *ngIf="mode == 'add'">Lieferung erfassen</h1>
    </div>
    <div *ngIf="mode == 'view'" class="col-4 button-col">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open group menu">
        <img src="../../../../../../../assets/img/three-dots-blue.svg" alt="Ausklappmenü" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="maueStart()" [disabled]="!billable"
          [class.action-menu-disabled]="!billable">
          <span class="material-icons-outlined">euro_symbol</span>
          <span> Abrechnen</span>
        </button>
        <button mat-menu-item (click)="maueCancel()" [disabled]="delivery.billingInterval==0">
          <span class="material-icons-outlined">cancel</span>
          <span>Beenden</span>
        </button>
      </mat-menu>
    </div>

  </div>

  <form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveDelivery()">
    <div class="form-field ch-margin-top-24" [class.error]="false">
      <label for="input-customer">Kunde</label>
      <select id="input-customer" formControlName="customerId">
        <option [value]="null">-</option>
        <option [ngValue]="entry.id" *ngFor="let entry of customerList">
          {{ entry.name }} (ID: {{ entry.id }})
        </option>
      </select>
    </div>

    <div class="form-field ch-margin-top-24" [class.error]="false">
      <label for="input-selectedProduct">Produkt (Vorlage)</label>
      <select id="selectedProduct" formControlName="selectedProductId" (change)="selectProduct($event)">
        <option [value]="null">-</option>
        <option [ngValue]="entry.id" *ngFor="let entry of productList">
          {{ entry.name }}: {{ entry.price }} €
          {{ "billing.billingInterval." + entry.billingInterval | translate }}
        </option>
      </select>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-4">
        <div class="form-field">
          <label for="input-billingMethod">Abrechnungsart</label>
          <select formControlName="billingMethod" (change)="selectBillingMethod($event)">
            <option value="MAuE">MAuE (automatisch)</option>
            <option value="NOYSEE">NOYSEE (über MAuE)</option>
            <option value="MANUAL">manuell</option>
            <option value="NONE">keine Abrechnung</option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="form-field">
          <label for="input-billingMethod">Bearbeitungsstand</label>
          <select formControlName="billingState">
            <option value="OPEN">offen</option>
            <option value="BILLED">abgerechnet</option>
            <option value="PAYED">bezahlt</option>
            <option value="CLOSED">geschlossen</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">Name</label>
          <input placeholder="" id="input-name" formControlName="name" />
          <div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">
            Bitte einen Namen für die Lieferung wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div
          class="form-field"
          [class.error]="submitted && f.description.invalid"
        >
          <label for="input-description"><span>Beschreibung</span></label>
          <textarea
            placeholder=""
            id="input-description"
            formControlName="description"
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-2">
        <div class="form-field">
          <label for="amount">Anzahl</label>
          <input id="amount" formControlName="amount" />
        </div>
      </div>

      <div class="col-4">
        <div class="form-field">
          <label for="name">Preis ( € )</label>
          <input id="name" formControlName="price" />
        </div>
      </div>

      <div class="col-6">
        <div class="form-field" [class.error]="false">
          <label for="input-customer">Abrechnungsintervall</label>
          <select formControlName="billingInterval" (change)="setBillingInterval($event)">
            <option [value]="0">Einmalig</option>
            <option [value]="1">Monatlich</option>
            <option [value]="12">Jährlich</option>
            <option [value]="36">3 Jahre</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row ch-margin-top-24" *ngIf="delivery.billingInterval != 0">
      <div class="col-3">
        <div class="form-field">
          <label for="contractPeriodBase">Initiale Laufzeit (Monate)</label>
          <select id="contractPeriodBase" formControlName="contractPeriodBase">
            <option [value]="null">-</option>
            <option [value]="24">2 Jahre</option>
            <option [value]="36">3 Jahre</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div class="form-field">
          <label for="contractPeriodExtension">Verlängerung (Monate)</label>
          <select
            id="contractPeriodExtension"
            formControlName="contractPeriodExtension"
          >
            <option [value]="null">-</option>
            <option [value]="6">6 Monate</option>
            <option [value]="12">1 Jahre</option>
            <option [value]="24">2 Jahre</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div class="form-field">
          <label for="noticePeriod">Kündigungsfrist (Monate)</label>
          <select id="noticePeriod" formControlName="noticePeriod">
            <option [value]="null">-</option>
            <option [value]="1">1 Monate</option>
            <option [value]="2">2 Monate</option>
            <option [value]="3">3 Monate</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <div class="form-field">
          <label for="deliveryDate">Lieferdatum</label>
          <input type="date" formControlName="delivery" placeholder="dd.MM.yyyy" id="deliveryDate" />
        </div>
      </div>

      <div class="col-3" *ngIf="delivery.billingInterval != 0">
        <div class="form-field" [class.error]="crudForm.errors?.contractStartMissing">
          <label for="contractStart">Vertragsbeginn *</label>
          <input type="date" placeholder="dd.MM.yyyy" formControlName="contractStart" id="contractStart" />
          <div *ngIf="submitted && crudForm.errors?.contractStartMissing" class="error-message ch-margin-top-4">
            Vertragsbeginn angeben
          </div>
        </div>
      </div>

      <div class="col-3" *ngIf="delivery.billingInterval != 0">
        <div class="form-field">
          <label for="contractEnd">Vertragsende</label>
          <input type="date" formControlName="contractEnd" id="contractEnd" />
        </div>
      </div>

      <div class="col-9" *ngIf="delivery.billingInterval == 0">
        <div class="form-field">
          <label for="amount">Sendungsnummer</label>
          <input id="trackingNumber" formControlName="trackingNumber" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-8">
        <h3>MAuE</h3>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <div class="form-field">
          <label for="input-dmat">DMat</label>
          <input placeholder="" id="input-dmat" formControlName="dmat" />
        </div>
      </div>
      <div class="col-9">
        <div class="form-field">
          <label for="input-maueDeviceModel">MAuE DeviceModel</label>
          <input placeholder="" id="input-maueDeviceModel" formControlName="maueDeviceModel" />
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div *ngIf="f.maueOrderNumber.value!=null" class="col-6">
        <div class="form-field">
          <label for="input-maueOrderNumber">MAuE Order Nr</label>
          <input placeholder="" id="input-maueOrderNumber" formControlName="maueOrderNumber" />
        </div>
      </div>
      <div *ngIf="f.maueInventoryNumber.value!=null" class="col-6">
        <div class="form-field">
          <label for="input-maueInventoryNumber">MAuE Inventory Nr</label>
          <input placeholder="" id="input-maueInventoryNumber" formControlName="maueInventoryNumber" />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudForm.disabled">
          Speichern
        </button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled">
          Abbrechen
        </button>
      </div>
      <div class="col-3"></div>
      <div *ngIf="mode === 'edit'" class="col-3">
        <button type="button" class="btn-secondary" (click)="deleteDelivery()" *ngIf="!crudForm.disabled">
          Löschen
        </button>
      </div>
    </div>
  </form>
</div>
