<div *ngIf="currentSensor" class="row">
  <div class="col-12 ch-margin-top-16">
    <div class="container contacts-container">
      <div *ngIf="limit1 || limit2" class="row ch-padding-top-8 header-row">
        <div class="col-8 contacts-header">
          <p appTranslate="sensor.contacts.headline"></p>
          <p appTranslate="sensor.contacts.settings-limit"></p>
        </div>
        <div class="col-2 test-alarm">
          <button
            *ngIf="isAdmin || isSuperAdmin"
            mat-flat-button
            class="btn-primary"
            (click)="testAlarm()"
            appTranslate="sensor.contacts.actions.test-alarm"
          ></button>
        </div>
        <div class="col-2 test-message">
          <button
            *ngIf="isAdmin || isSuperAdmin"
            mat-flat-button
            class="btn-primary"
            (click)="testMessage()"
            appTranslate="sensor.contacts.actions.text-message"
          ></button>
        </div>
      </div>
      <div *ngIf="limit1 || limit2" class="row limits-row">
        <div *ngIf="limit1" class="col-4 col-limit">
          <div class="limit-container">
            <p appTranslate="sensor.contacts.values.normal"></p>
            <p class="normal-text">
              &lt;{{ limit1 }}
              <span class="contacts-limit-unit">
                {{ unit }}
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="limit1 && limit2" class="col-4 col-limit">
          <div class="limit-container">
            <p appTranslate="sensor.contacts.values.limit1"></p>
            <p class="limit1-text">
              {{ limit1 }}-{{ limit2 }}
              <span class="contacts-limit-unit">
                {{ unit }}
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="limit2" class="col-4 col-limit">
          <div class="limit-container">
            <p appTranslate="sensor.contacts.values.limit2"></p>
            <p class="limit2-text">
              &gt;{{ limit2 }}
              <span class="contacts-limit-unit">
                {{ unit }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        [class.no-margin]="!limit1 && !limit2"
        class="row ch-padding-top-8 settings-header-row"
      >
        <div class="col-12 contacts-settings-header">
          <p appTranslate="sensor.contacts.settings-contact"></p>
        </div>
      </div>
      <div class="row settings-row">
        <div class="col-4 contacts-search">
          <div class="input-field-search">
            <input
              name="alarmfilter"
              placeholder="{{
                'sensor.contacts.search-placeholder' | translate
              }}"
              type="text"
              [(ngModel)]="filterString"
              (keyup)="filterAlarms()"
            />
          </div>
        </div>
        <div class="col-4 contacts-add" *ngIf="alarmReceiverEditable">
          <span class="contacts-add-field" (click)="addAlarm()">
            <img
              src="../../../../../../assets/img/plus-solid.svg"
              width="15"
              height="15"
            />
            <p appTranslate="sensor.contacts.actions.add-contact"></p>
          </span>
        </div>
        <div class="col-2 contacts-filter">
          <div class="layer-sort">
            <select [(ngModel)]="sortTopic" (change)="sortAlarms()">
              <option
                value="sort-abc"
                appTranslate="sensor.contacts.actions.sort-alphabetically"
              ></option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="alarmUsers" class="row contacts-row">
        <div
          *ngFor="let alarmUser of alarmUsers; index as i"
          [class.hidden]="alarmUser.hidden"
          class="col-4"
        >
          <div class="contact-info">
            <div
              *ngIf="alarmReceiverEditable"
              class="contact-info-remove"
              (click)="removeAlarm(alarmUser.userId)"
            >
              <img src="../../../../../../assets/img/times-solid.svg" />
            </div>
            <div class="contact-info-header">
              <p class="contact-info-name">
                <strong>{{ alarmUser.name }}</strong>
              </p>
              <p class="contact-info-mail">
                <strong appTranslate="sensor.contacts.values.email"></strong
                >{{ alarmUser.email }}
              </p>
              <p class="contact-info-phone" *ngIf="alarmUser.mobile">
                <strong
                  appTranslate="sensor.contacts.values.mobile.label"
                ></strong
                >: {{ alarmUser.mobile }}
              </p>
              <p class="contact-info-phone" *ngIf="alarmUser.phone">
                <strong
                  appTranslate="sensor.contacts.values.phone.label"
                ></strong
                >: {{ alarmUser.phone }}
              </p>
              <p
                class="contact-info-phone-placeholder"
                *ngIf="!alarmUser.phone"
              >
                <strong
                  appTranslate="sensor.contacts.values.phone.placeholder"
                ></strong>
              </p>
              <p
                class="contact-info-phone-placeholder"
                *ngIf="!alarmUser.mobile"
              >
                <strong
                  appTranslate="sensor.contacts.values.mobile.placeholder"
                ></strong>
              </p>
            </div>
            <ng-container *ngIf="!!alarmUser.fail">
              <div
                [class.enabled]="alarmUser.fail.enabled"
                class="contact-info-fail"
              >
                <label class="switch">
                  <input
                    [disabled]="!alarmUser.editable"
                    [(ngModel)]="alarmUser.fail.enabled"
                    type="checkbox"
                    (click)="
                      toggleDetailForAlarm(alarmUser.userId, 'fail', 'enabled')
                    "
                  />
                  <span class="contact-info-fail-slider slider round"></span>
                </label>
                <strong appTranslate="sensor.contacts.values.fail"></strong>
                <div class="fail-alarms">
                  <span
                    [class.active]="alarmUser.fail.phone"
                    *ngIf="alarmUser.phone && featurePhoneCall"
                    class="fail-phone"
                    (click)="
                      alarmUser.fail.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(alarmUser.userId, 'fail', 'phone')
                    "
                    appTranslate="sensor.contacts.values.call"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.fail.voiceMessage"
                    *ngIf="alarmUser.phone && featureVoiceMessage"
                    class="fail-voice"
                    (click)="
                      alarmUser.fail.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'fail',
                          'voiceMessage'
                        )
                    "
                    appTranslate="sensor.contacts.values.voice"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.fail.sms"
                    *ngIf="alarmUser.phone"
                    class="fail-sms"
                    (click)="
                      alarmUser.fail.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(alarmUser.userId, 'fail', 'sms')
                    "
                    appTranslate="sensor.contacts.values.text"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.fail.email"
                    class="fail-email"
                    (click)="
                      alarmUser.fail.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(alarmUser.userId, 'fail', 'email')
                    "
                    appTranslate="sensor.contacts.values.email"
                  >
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="alarmUser.limit1">
              <div
                [class.enabled]="alarmUser.limit1.enabled"
                class="contact-info-limit1"
              >
                <label class="switch">
                  <input
                    [disabled]="!alarmUser.editable"
                    [(ngModel)]="alarmUser.limit1.enabled"
                    type="checkbox"
                    (click)="
                      toggleDetailForAlarm(
                        alarmUser.userId,
                        'limit1',
                        'enabled'
                      )
                    "
                  />
                  <span class="contact-info-limit1-slider slider round"></span>
                </label>
                <strong appTranslate="sensor.values.limit1"></strong>
                <div class="limit1-alarms">
                  <span
                    [class.active]="alarmUser.limit1.phone"
                    *ngIf="alarmUser.phone && featurePhoneCall"
                    class="limit1-phone"
                    (click)="
                      alarmUser.limit1.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit1',
                          'phone'
                        )
                    "
                    appTranslate="sensor.contacts.values.call"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit1.voiceMessage"
                    *ngIf="alarmUser.phone && featureVoiceMessage"
                    class="limit1-voice"
                    (click)="
                      alarmUser.limit1.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit1',
                          'voiceMessage'
                        )
                    "
                    appTranslate="sensor.contacts.values.voice"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit1.sms"
                    *ngIf="alarmUser.phone"
                    class="limit1-sms"
                    (click)="
                      alarmUser.limit1.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(alarmUser.userId, 'limit1', 'sms')
                    "
                    appTranslate="sensor.contacts.values.text"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit1.email"
                    class="limit1-email"
                    (click)="
                      alarmUser.limit1.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit1',
                          'email'
                        )
                    "
                    appTranslate="sensor.contacts.values.email"
                  >
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="alarmUser.limit2">
              <div
                [class.enabled]="alarmUser.limit2.enabled"
                class="contact-info-limit2"
              >
                <label class="switch">
                  <input
                    [disabled]="!alarmUser.editable"
                    [(ngModel)]="alarmUser.limit2.enabled"
                    type="checkbox"
                    (click)="
                      toggleDetailForAlarm(
                        alarmUser.userId,
                        'limit2',
                        'enabled'
                      )
                    "
                  />
                  <span class="contact-info-limit2-slider slider round"></span>
                </label>
                <strong appTranslate="sensor.values.limit2"></strong>
                <div class="limit2-alarms">
                  <span
                    [class.active]="alarmUser.limit2.phone"
                    *ngIf="alarmUser.phone && featurePhoneCall"
                    class="limit2-phone"
                    (click)="
                      alarmUser.limit2.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit2',
                          'phone'
                        )
                    "
                    appTranslate="sensor.contacts.values.call"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit2.voiceMessage"
                    *ngIf="alarmUser.phone && featureVoiceMessage"
                    class="limit2-voice"
                    (click)="
                      alarmUser.limit2.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit2',
                          'voiceMessage'
                        )
                    "
                    appTranslate="sensor.contacts.values.voice"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit2.sms"
                    *ngIf="alarmUser.phone"
                    class="limit2-sms"
                    (click)="
                      alarmUser.limit2.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(alarmUser.userId, 'limit2', 'sms')
                    "
                    appTranslate="sensor.contacts.values.text"
                  >
                  </span>
                  <span
                    [class.active]="alarmUser.limit2.email"
                    class="limit2-email"
                    (click)="
                      alarmUser.limit2.enabled &&
                        alarmUser.editable &&
                        toggleDetailForAlarm(
                          alarmUser.userId,
                          'limit2',
                          'email'
                        )
                    "
                    appTranslate="sensor.contacts.values.email"
                  >
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="!alarmUser" class="contact-info-not-found">
            <p
              class="alert alert-danger"
              appTranslate="sensor.contacts.not-found"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
