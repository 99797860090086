import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import moment from 'moment';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { DataTableOptions, GridState, TitleSize, TableActionType, TableFilterType, FilterOption } from 'src/app/shared/data-table/data-table.model';
import { LookupService } from 'src/app/services/lookup.service';
import { ImportConfigService } from '@app/services/importConfig.service';
import { ImportConfig } from '@app/models/importConfig';
import { GeoPolygone } from '@app/models/topo';
import { TopoService } from '@app/state/dashboard/topo.service';
import { GeoPolygonService } from '@app/services/geo.service';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-importConfig-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  customerSubscription: Subscription;

  dataTableOptions: DataTableOptions<GeoPolygone>;
  list: GeoPolygone[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  destinationFilter: FilterOption[];

  constructor(private geoPolygoneService: GeoPolygonService,
    private lookupService: LookupService,
    private applicationContextService: ApplicationContextService,
    private toastHelper: ToastHelper,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private router: Router) {

    // this.noyseeDeviceSubscription = null;
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    const _this = this;
    _this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    _this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'geoPolygone.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [{
        label: 'geoPolygone.table.actions.add',
        rule: () => this.authenticationService.isSuperAdmin() || this.authenticationService.isAdmin(),
        actionFn: () => this.router.navigate(['/dashboard', 'admins', 'geoPolygone', 'add'],
          { queryParams: { ctx: this.getGridState() } })
      }],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [{
          label: 'geoPolygone.table.actions.edit',
          icon: 'app-pencil',
          actionFn: (entry: ImportConfig) => this.editImportConfig(entry.id)
        },
        {
          label: 'geoPolygone.table.actions.delete',
          icon: 'delete',
          actionFn: (entry: ImportConfig) => this.deleteImportConfig(entry.id),
        }]
      },
      columnDef: [{
        key: 'id',
        displayName: 'geoPolygone.table.columns.id',
        width: '5%',
        sortable: true,
      }, {
        key: 'type',
        displayName: 'geoPolygone.table.columns.type',
        width: '10%',
        sortable: true,
      }, {
        key: 'polygoneType',
        displayName: 'geoPolygone.table.columns.polygoneType',
        width: '10%',
        sortable: true,
      }, {
        key: 'name',
        displayName: 'geoPolygone.table.columns.name',
        width: '80%',
        sortable: true,
        styleFn: (entry: GeoPolygone) => {
          return {
            cursor: 'pointer',
            color: '#0e0ec4',
            'font-weight': 'bold'
          };
        },
        actionFn: (entry: GeoPolygone) => this.router.navigate(['/dashboard', 'admins', 'geoPolygone', entry.id, 'edit'])
      }]
    };

    this.lookupService.getDestinationList().then(result => {
      this.destinationFilter = result.map((e) => { return { label: e.name, value: e.id + '' }; });
      this.dataTableOptions.columnDef[2].filterValues= this.destinationFilter;
    });

    this.loadList();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(this.dataTable.getGridState());
  }

  loadList() {
    this.geoPolygoneService.list().then(result => {
      this.list = result;
    });
  }

  editImportConfig(id: number) {
    this.router.navigate(['/dashboard', 'admins', 'geoPolygone', id, 'edit']);
  }

  deleteImportConfig(id: number) {
    if (id <= 0) {
      return;
    }
    const _this = this;
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie dies Gebiet wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.geoPolygoneService.delete(id).then(result => {
            this.toastHelper.openSnackBar('Gebiet erfolgreich gelöscht!', 'SUCCESS');
            this.loadList();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }


}
