import { Component, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { Router } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import {
  DataTableOptions,
  GridState,
  TableActionType,
  TitleSize,
} from '../../../../../shared/data-table/data-table.model';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { Customer } from 'src/app/models/customerList';
import { CustomerService } from 'src/app/services/customer.service';
import { LookupEntryWithState } from 'src/app/models/apiResults';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-customers-overview',
  templateUrl: './customerList.component.html',
  styleUrls: ['./customerList.component.scss'],
})
export class CustomerListComponent implements OnInit {
  dataTableOptions: DataTableOptions<LookupEntryWithState>;
  list: LookupEntryWithState[];
  gridState: GridState = null;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private customerService: CustomerService,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private dataTableStateService: DataTableStateService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private store: Store,
    private router: Router,
  ) {
    this.isSuperAdmin = false;
  }

  ngOnInit(): void {
    const _this = this;
    _this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    _this.isAdmin = this.authenticationService.isAdmin();

    this.dataTableOptions = {
      title: 'customer.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'customer.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () => this.createCustomer(),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'customer.table.actions.edit',
            icon: 'app-pencil',
            actionFn: (entry: Customer) => this.editCustomer(entry.id),
          },
          // {
          //   label: 'shared.table.actions.delete',
          //   icon: 'app-view',
          //   actionFn: (entry: Customer) => this.deleteCustomer(entry.id)
          // }
        ],
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'customer.table.columns.id',
          width: '10%',
          sortable: true,
          hidden: !this.isSuperAdmin,
        },
        {
          key: 'name',
          displayName: 'customer.table.columns.name',
          width: '70%',
          sortable: true,
          styleFn: (entry: Customer) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold',
            };
          },
          actionFn: (entry: Customer) => this.editCustomer(entry.id),
        },
        {
          key: 'state',
          displayName: 'customer.table.columns.state',
          width: '20%',
          sortable: true,
          valueTransform: (entry: Customer) =>
            `general.booleanState.${entry.active}`,
        },
      ],
    };

    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      this.loadList();
    });

    this.loadList();
  }

  loadList() {
    this.customerService.getList().then((result) => {
      this.list = result;
    });
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }

  createCustomer() {
    this.router.navigate(['/dashboard', 'admins', 'customers', 'add'], {
      queryParams: { ctx: this.getGridState() },
    });
  }

  editCustomer(id: number) {
    this.router.navigate(['/dashboard', 'admins', 'customers', id, 'edit']);
  }

  deleteCustomer(id: number) {
    const _this = this;

    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie diese Alarmregel wirklich löschen?',
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          _this.customerService.delete(id).then((result) => {
            _this.toastHelper.openSnackBar('Alarmgruppe gelöscht!', 'SUCCESS');
            this.loadList();
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }
}
