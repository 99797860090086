export enum Topic {
  SENSOR_BOX_NEW_VALUE = 'sensorBox.newValue',
  SENSOR_BOX_META = 'sensorBox.meta',
  USER_CHANGED = 'user.changed',
  NOTIFICATIONS_CHANGED = 'notifications.changed',
  ACTION_CHANGED = 'alarmAction.changed',
  UNDEFINED = '',
}

export class WebsocketMessage<T> {
  topic: Topic;
  data: T;

  constructor(originalMessage: string) {
    // The message contains a "type" (= "topic") and data depending on the topic
    const message = JSON.parse(originalMessage);

    if (!message) {
      throw new Error('Invalid websocket message');
    }

    this.topic = message.type;

    switch (this.topic) {
      case Topic.SENSOR_BOX_NEW_VALUE:
      case Topic.SENSOR_BOX_META:
        this.data = message.sensorBoxes as T;
        break;
      case Topic.NOTIFICATIONS_CHANGED:
        this.data = message.notifications as T;
        break;
      case Topic.USER_CHANGED:
        this.data = message.users as T;
        break;
      case Topic.ACTION_CHANGED:
        this.data = message.actions as T;
        break;
      case Topic.UNDEFINED:
      default:
        this.data = null;
        break;
    }
  }
}
