import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLngBounds } from 'leaflet';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  SensorDataResult,
  SensorListResult,
  SensorResult,
} from 'src/app/models/apiResults';
import { SensorBox } from './models/sensorBox';

@Injectable({ providedIn: 'root' })
export class SensorService {
  constructor(private http: HttpClient) {}

  createSensor(sensor: SensorBox): Observable<SensorResult> {
    return this.http.post<SensorResult>(
      `${environment.dashboardApiUrl}/sensorBox/meta`,
      sensor,
    );
  }

  updateSensor(sensor: SensorBox): Observable<SensorResult> {
    return this.http.put<SensorResult>(
      `${environment.dashboardApiUrl}/sensorBox/meta?id=` + sensor.id,
      sensor,
    );
  }

  deleteSensor(uuid: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.dashboardApiUrl}/sensorBox/meta?id=` + uuid,
    );
  }

  getSensorBoxData(
    id: number,
    begin: Date,
    end?: Date,
  ): Observable<SensorDataResult> {
    return this.http.get<SensorDataResult>(
      `${environment.dashboardApiUrl}/sensorBox/detail?id=` +
        id +
        `&dateFrom=` +
        begin.toISOString().replace(/\.\d+/, '') +
        `&dateTo=` +
        (end || new Date()).toISOString().replace(/\.\d+/, ''),
    );
  }

  getSensorBox(id: number): Observable<SensorResult> {
    return this.http.get<SensorResult>(
      `${environment.dashboardApiUrl}/sensorBox/meta?id=` + id,
    );
  }

  getMySensorList(customerId: number): Observable<SensorListResult> {
    return this.http.get<SensorListResult>(
      `${environment.dashboardApiUrl}/sensorBox/myList`,
      {
        params: {
          customerId,
        },
      },
    );
  }

  getSensorsInBoundingBox(
    boundingBox: LatLngBounds,
  ): Observable<SensorListResult> {
    return this.http.get<SensorListResult>(
      `${environment.dashboardApiUrl}/sensorBox/` +
        `publicList?` +
        `lat1=` +
        boundingBox.getNorth() +
        `&lat2=` +
        boundingBox.getSouth() +
        `&lon1=` +
        boundingBox.getWest() +
        `&lon2=` +
        boundingBox.getEast(),
    );
  }
  
}
