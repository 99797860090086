<div *ngIf="currentSensor" class="row">
  <div class="col-12 ch-margin-top-16">
    <div class="container contacts-container">
      <div class="row ch-padding-top-8 header-row">
        <div class="col-6 contacts-header">
          <p appTranslate="alarmGroup.headerLabel"></p>
        </div>
        <div class="col-2 add-alarmGroup">
          <button *ngIf="isAdmin || isSuperAdmin" mat-flat-button class="btn-primary" (click)="addAlarmGroup()"
                  appTranslate="alarmGroup.actions.add"></button>
        </div>
        <div class="col-2 test-alarm">
          <button *ngIf="isAdmin || isSuperAdmin" mat-flat-button class="btn-primary" (click)="testAlarm()"
                  appTranslate="alarmGroup.actions.testAlarm"></button>
        </div>
        <div class="col-2 test-message">
          <button *ngIf="isAdmin || isSuperAdmin" mat-flat-button class="btn-primary" (click)="testMessage()"
                  appTranslate="alarmGroup.actions.testMessage"></button>
        </div>
      </div>
      <div class="row settings-header-row ch-margin-top-8">
        <div class="col-12 contacts-settings-header">
          <p appTranslate="alarmGroup.assigned"></p>
        </div>
      </div>

      <div class="col-12 ch-margin-top-24">
        <app-data-table [dataTableOptions]="dataTableOptions" [state]="gridState"
                        [data]="alarmGroupList"></app-data-table>
      </div>
    </div>
  </div>
</div>
