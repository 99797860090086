import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { Router } from '@angular/router';
import { ApprovalDialogHelper } from '../../../../../helpers/approval-dialog.helper';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import {
  DataTableColumnType,
  DataTableOptions,
  GridState,
  TableActionType,
  TableFilterType,
  TitleSize,
} from '../../../../../shared/data-table/data-table.model';
import { Subscription } from 'rxjs';
import { DataTableComponent } from '../../../../../shared/data-table/data-table.component';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';
import moment from 'moment';
import { BillingService } from 'src/app/services/billing';
import { Product, BillingState } from 'src/app/models/billing';
import { AddDeliveryDialogComponent } from '../addDelivery-modal/addDeliveryDialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { formatNumber } from '@angular/common';
import { Store } from '@ngxs/store';
import { GuiState } from 'src/app/state/dashboard/gui.state';

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss'],
})
export class DeliveriesComponent implements OnInit, OnDestroy {
  dataTableOptions: DataTableOptions<Product>;
  productList: Product[];
  private usersSubscription$: Subscription;
  private customerSubscription$: Subscription;

  params: any;

  @ViewChild(DataTableComponent)
  dataTable: DataTableComponent;

  ctx: GridState = null;

  customerSubscription: Subscription;

  customerName: string;

  constructor(
    private applicationContextService: ApplicationContextService,
    private authenticationService: AuthenticationService,
    private billingService: BillingService,
    private router: Router,
    private toastHelper: ToastHelper,
    private store: Store,
    private matDialog: MatDialog,
    private approvalDialogHelper: ApprovalDialogHelper,
    private dataTableStateService: DataTableStateService,
  ) { }

  ngOnInit(): void {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.ctx = this.router.getCurrentNavigation().extras.state as GridState;
    }
    this.dataTableOptions = {
      title: 'billing.deliveries.headerLabel',
      titleSize: TitleSize.H1,
      filter: true,
      tableActions: [
        {
          label: 'billing.deliveries.table.actions.add',
          rule: () =>
            this.authenticationService.isSuperAdmin() ||
            this.authenticationService.isAdmin(),
          actionFn: () => this.router.navigate([
            '/dashboard',
            'admins',
            'deliveries',
            'add',
          ]),
        },
      ],
      rowActions: {
        type: TableActionType.MENU,
        actionList: [
          {
            label: 'billing.deliveries.table.actions.view',
            icon: 'app-view',
            dataRule: (w: Product) => true,
            actionFn: (w: Product) =>
              this.showDelivery(w)
          },
          {
            label: 'billing.deliveries.table.actions.bill',
            icon: 'euro_symbol',
            dataRule: (p: Product) => p.billingState == BillingState.OPEN,
            actionFn: (p: Product) => this.billDelivery(p),
          },
          {
            label: 'billing.deliveries.table.actions.delete',
            icon: 'app-delete',
            dataRule: (w: Product) => w.billingState == BillingState.OPEN,
            actionFn: (w: Product) => this.deleteDelivery(w),
          },
        ],
      },
      columnDef: [
        {
          key: 'id',
          displayName: 'billing.deliveries.table.columns.id',
          width: '5%',
          sortable: true,
        },
        {
          key: 'dmat',
          displayName: 'billing.deliveries.table.columns.dmat',
          width: '15%',
          sortable: true,
          filterFn: (w: Product, arg) =>
            w.dmat.toLowerCase().includes(arg.toLowerCase()),
        },
        {
          key: 'name',
          displayName: 'billing.deliveries.table.columns.name',
          width: '15%',
          sortable: true,
          filterFn: (w: Product, arg) => w.name.toLowerCase().includes(arg.toLowerCase()),
          styleFn: (product: Product) => {
            return {
              cursor: 'pointer',
              color: '#0e0ec4',
              'font-weight': 'bold'
            };
          },
          actionFn: (product: Product) => this.showDelivery(product),

        },
        {
          key: 'description',
          displayName: 'billing.deliveries.table.columns.description',
          width: '40%',
          sortable: true,
          filterFn: (w: Product, arg) =>
            w.description.toLowerCase().includes(arg.toLowerCase()),
        },
        {
          key: 'amount',
          displayName: 'billing.deliveries.table.columns.amount',
          width: '8%',
          sortable: true,
        },
        {
          key: 'price',
          displayName: 'billing.deliveries.table.columns.price',
          width: '8%',
          sortable: true,
          valueTransform: (w: Product) =>
            formatNumber(w.price, 'de-DE', '1.2') + ' €',
        },
        {
          key: 'billingInterval',
          displayName: 'billing.deliveries.table.columns.billingInterval',
          width: '7%',
          sortable: true,
          valueTransform: (w: Product) =>
            `billing.billingInterval.${w.billingInterval}`,
        },
        {
          key: 'state',
          displayName: 'billing.deliveries.table.columns.state',
          width: '5%',
          sortable: true,
          valueTransform: (p: Product) =>
            `billing.billingState.${p.billingState}`,
        },
        {
          key: 'lastBilling',
          displayName: 'billing.deliveries.table.columns.lastBilling',
          width: '7%',
          sortable: true,
          valueTransform: (w: Product) =>
            w.lastBilling ? moment(w.lastBilling).format('DD.MM.YYYY') : '-',
        },
      ],
    };

    this.store.select(GuiState.selectedCustomer).subscribe((customer) => {
      console.debug('Focus Customer changed');
      this.loadProducts();
    });

    // this.loadProducts();
  }

  private loadProducts() {
    const customer = this.store.selectSnapshot(GuiState.selectedCustomer);
    let customerId = customer.id;
    this.customerName = customer.name;
    this.billingService.getDeliveries(customerId).then((data) => {
      this.productList = data;
    });
  }

  private addDelivery() {
    const dialogOptions: MatDialogConfig = {
      data: {
        customer: this.store.selectSnapshot(GuiState.selectedCustomer),
      },
      maxWidth: 600,
    };

    this.matDialog
      .open(AddDeliveryDialogComponent, dialogOptions)
      .afterClosed()
      .toPromise()
      .then((result) => {
        this.loadProducts();
      });
  }

  private deleteDelivery(product: Product) {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog(
      'Möchten Sie die Position wirklich löschen?<br>' +
      product.id +
      ' ' +
      product.name,
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.billingService.deleteDelivery(product).then((result) => {
            this.loadProducts();
            this.toastHelper.openSnackBar(
              'Position ' + product.name + ' gelöscht.',
              'SUCCESS',
            );
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });
  }

  showDelivery(delivery: Product) {
    this.router.navigate([
      '/dashboard',
      'admins',
      'deliveries',
      delivery.id,
      'view',
    ]);
  }

  billDelivery(delivery: Product) {
    this.billingService.workflowMaueStart(delivery).then((result) => {
      this.loadProducts();
    });

  }

  ngOnDestroy() {
    this.usersSubscription$?.unsubscribe();
    this.customerSubscription$?.unsubscribe();
  }

  getGridState(): string {
    return this.dataTableStateService.serializeState(
      this.dataTable.getGridState(),
    );
  }
}
