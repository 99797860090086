import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MicrositesRoutingModule } from './microsites-routing.module';
import { SensorComponent } from './default/sensor/sensor.component';
import { CustomerComponent } from './default/customer/customer.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SensorsModule } from '../dashboard/sensors/sensors.module';
import { MinimalComponent } from './shared/sensor/minimal/minimal.component';
import { MapComponent } from './default/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HistoryComponent } from './shared/sensor/history/history.component';
import { ChartComponent } from './default/chart/chart.component';


@NgModule({
  declarations: [
    SensorComponent,
    CustomerComponent,
    MinimalComponent,
    MapComponent,
    HistoryComponent,
    ChartComponent
  ],
  imports: [
    CommonModule,
    MicrositesRoutingModule,
    SharedModule,
    TranslateModule,
    SensorsModule,
    LeafletModule
  ]
})
export class MicrositesModule {
}
