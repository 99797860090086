import {Component} from '@angular/core';
import {BaseColumnComponent} from '../base-column.component';

@Component({
  selector: 'app-column-text',
  templateUrl: './column-text.component.html',
  styleUrls: ['./column-text.component.scss']
})
export class ColumnTextComponent extends BaseColumnComponent {
  constructor() {
    super();
  }
}
