import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Control, DomEvent, DomUtil, Map } from 'leaflet';
import moment from 'moment';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TopoType } from 'src/app/models/topo';
import { isEqual } from 'lodash';
import { FrontendType } from 'src/app/state/noysee/models/sensor';
import { Store } from '@ngxs/store';
import { MapState } from 'src/app/state/dashboard/map.state';
import { GuiState } from '../../../../../../state/dashboard/gui.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss'],
})
export class TimeSliderComponent {
  TimeControl = Control.extend({
    onAdd: () => DomUtil.get('time-control'),
  });
  public control: Control;
  hidden: boolean = false;
  playing: NodeJS.Timeout = undefined; // index of the interval (if playing)

  offset: number = 0;
  currentTime: string = this.time;

  zoomLevel$: Observable<number> = this.store.select(GuiState.zoomLevel);

  @Input() layerSubType: TopoType | FrontendType;
  @Input() set loading(isLoading: boolean) {
    if (!isLoading) {
      this.play();
    } else {
      this.pause();
    }
  }

  @Input() set map(leafletMap: Map) {
    if (leafletMap) {
      // Instantiate control
      this.control = new this.TimeControl({ position: 'bottomleft' }).addTo(
        leafletMap,
      );

      // Disable drag handler
      this.control.getContainer().addEventListener('mousedown', (e) => {
        DomEvent.stopPropagation(e);
      });
      this.control.getContainer().addEventListener('dblclick', (e) => {
        DomEvent.stopPropagation(e);
      });

      // Setup visibility toggle based on filter selection
      this.store
        .select(MapState.layers)
        .pipe(
          map(
            (filters) =>
              filters.find((filter) => filter.subType === this.layerSubType)
                ?.selected,
          ),
          distinctUntilChanged(isEqual),
        )
        .subscribe((rainFilter) => {
          if (rainFilter) {
            this.hidden = false;
          } else {
            this.hidden = true;
            this.stop.emit();
          }
        });

      leafletMap.on('zoomstart', () => {
        this.stop.emit();
      });
    }
  }

  @Output() offsetChanged: EventEmitter<number> = new EventEmitter();

  @Output() start: EventEmitter<number> = new EventEmitter();
  @Output() stop: EventEmitter<number> = new EventEmitter();

  constructor(private store: Store) {
    setInterval(() => {
      this.currentTime = this.time;
    }, 1000);
  }

  get time(): string {
    return moment().add(this.offset, 'minutes').format('HH:mm');
  }

  onSliderChange(event: any) {
    this.offset = event.value;
    this.offsetChanged.emit(event.value);
  }

  play() {
    this.playing = setInterval(() => this.jump(15), 500);
  }

  pause() {
    clearInterval(this.playing);
    this.playing = undefined;
  }

  jump(offset: number) {
    if (this.offset + offset < -120) {
      this.offset = 0;
    } else if (this.offset + offset > 0) {
      this.offset = -120;
    } else {
      this.offset += offset;
    }
    this.offsetChanged.emit(this.offset);
  }

  displayWith(value: number): string {
    return `${value} Minuten`;
  }
}
