import { Injectable } from '@angular/core';
import { AlarmGroup, AlarmGroupMember } from './models/alarmGroup';
import {
  AlarmGroupResult,
  AlarmGroupListResult,
} from '../../models/apiResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GuiState } from '../dashboard/gui.state';

@Injectable({
  providedIn: 'root',
})
export class AlarmGroupService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getList(): Promise<AlarmGroup[]> {
    let id = this.store.selectSnapshot(GuiState.selectedCustomer).id;

    return this.http
      .get<AlarmGroupListResult>(
        `${environment.dashboardApiUrl}/alarmGroup/list?customerId=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getDetail(id: number): Promise<AlarmGroup> {
    return this.http
      .get<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/detail?id=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  saveDetail(group: AlarmGroup): Promise<AlarmGroup> {
	let customerId = this.store.selectSnapshot(GuiState.selectedCustomer).id;
	if (!group.customerId) {
		group.customerId= customerId;
	}    

    return this.http
      .post<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/detail`,
        group,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  delete(id: number): Promise<AlarmGroup> {
    return this.http
      .delete<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/detail?id=` + id,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  addMember(
    alarmGroupId: number,
    member: AlarmGroupMember,
  ): Observable<AlarmGroup> {
    member.alarmGroupId = alarmGroupId;
    return this.http
      .post<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/member`,
        member,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      );
  }

  saveMember(
    alarmGroupId: number,
    member: AlarmGroupMember,
  ): Promise<AlarmGroup> {
    member.alarmGroupId = alarmGroupId;
    return this.http
      .put<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/member`,
        member,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  removeMember(alarmGroupId: number, memberId: number): Promise<AlarmGroup> {
    return this.http
      .delete<AlarmGroupResult>(
        `${environment.dashboardApiUrl}/alarmGroup/member?groupId=` +
          alarmGroupId +
          `&id=` +
          memberId,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }
}
