<div class="primary-panel-extra-large container-fluid ch-margin-top-24">
  <div class="row">
    <div class="col-8">
       <h1 appTranslate="warningBroker.activeWarnings.headerLabel"></h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p appTranslate="warningBroker.activeWarnings.filter.headerLabel"></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-tile-list [(value)]="relevantAgsList" keyField="ags" descriptiveField="name" (addItem)="addAgs()"
        (change)="loadWarnings()"></app-tile-list>
    </div>
  </div>
  <div class="row ch-margin-top-24">
    <div class="col-12">
      <app-data-table [dataTableOptions]="dataTableOptions" [state]="ctx" [data]="warningList"></app-data-table>
    </div>
  </div>
</div>