export class DateHelper {
  static generateDateStringParts(d: Date) {
    const month = this.addZero(d.getMonth() + 1);
    const day = this.addZero(d.getDate());

    const hour = this.addZero(d.getHours());
    const minute = this.addZero(d.getMinutes());
    const second = this.addZero(d.getSeconds());

    return {year: d.getFullYear(), month, day, hour, minute, second};
  }
  static generateDateString(date: Date) {
    const {year, month, day, hour, minute, second} = this.generateDateStringParts(date);
    return `${year}${month}${day}_${hour}_${minute}_${second}`;
  }

  static generateGermanTimestamp(date: Date) {
    const {year, month, day, hour, minute, second} = this.generateDateStringParts(date);
    return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
  }

  static addZero(value: number) {
    return value > 9 ? value.toString(10) : '0' + value;
  }
}
