<div
  *ngIf="sensorBox$ | async as sensorBox"
  class="primary-panel-small container-fluid"
>
  <div class="row">
    <div class="col-12">
      <h1 *ngIf="mode === 'add'" appTranslate="sensor.crud.headlines.add"></h1>
      <h1
        *ngIf="mode === 'edit'"
        appTranslate="sensor.crud.headlines.edit"
      ></h1>
    </div>
  </div>
  <form
    class="form"
    [formGroup]="crudSensorForm"
    (ngSubmit)="saveSensor(sensorBox)"
  >
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label
            for="input-name"
            appTranslate="forms.sensor.name.label"
            class="important"
          ></label>
          <input id="input-name" formControlName="name" />
          <div
            *ngIf="submitted && f.name.invalid && f.name.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.sensor.name.error"
          ></div>
          <div
            *ngIf="f.name.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize60"
          ></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <label for="input-address" appTranslate="forms.sensor.address"
            >Adresse</label
          >
          <input id="input-address" formControlName="address" />
          <div
            *ngIf="f.address.errors?.maxlength"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.maxSize100"
          ></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-3">
        <div class="form-field" [class.error]="submitted && f.lat.invalid">
          <label
            for="input-coordinates-lat"
            appTranslate="forms.sensor.latitude.label"
            class="important"
          ></label>
          <input
            formControlName="lat"
            id="input-coordinates-lat"
            type="number"
          />
          <div
            *ngIf="submitted && f.lat.invalid && f.lat.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.sensor.latitude.error"
          ></div>
          <div
            *ngIf="f.lat.errors?.pattern"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.lat"
          ></div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-field" [class.error]="submitted && f.lon.invalid">
          <label
            for="input-coordinates-lon"
            appTranslate="forms.sensor.longitude.label"
            class="important"
          ></label>
          <input
            formControlName="lon"
            id="input-coordinates-lon"
            type="number"
          />
          <div
            *ngIf="submitted && f.lon.invalid && f.lon.errors.required"
            class="error-message ch-margin-top-4"
            appTranslate="forms.sensor.longitude.error"
          ></div>
          <div
            *ngIf="f.lon.errors?.pattern"
            class="error-message ch-margin-top-4"
            appTranslate="forms.validationError.lon"
          ></div>
        </div>
      </div>
    </div>
    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div
          class="form-field"
          [class.error]="submitted && f.datasource.invalid"
        >
          <label
            for="input-datasource"
            appTranslate="forms.sensor.datasource.label"
            class="important"
          ></label>
          <input formControlName="datasource" id="input-datasource" />
          <div
            *ngIf="
              submitted && f.datasource.invalid && f.datasource.errors.required
            "
            class="error-message ch-margin-top-4"
            appTranslate="forms.sensor.datasource.error"
          ></div>
        </div>
      </div>
    </div>
    <div formGroupName="sensors">
      <div [formGroupName]="sensorBox.primarySensor">
        <div class="row ch-margin-top-24">
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-sensorLimit1"
                appTranslate="forms.sensor.limit1"
              ></label>
              <input
                id="input-sensorLimit1"
                formControlName="limit1"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-sensorLimit2"
                appTranslate="forms.sensor.limit2"
              ></label>
              <input
                id="input-sensorLimit2"
                formControlName="limit2"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>

          <div class="col-2">
            <div class="form-field">
              <label
                for="input-sensorLimitDelta"
                appTranslate="forms.sensor.limitDelta"
              ></label>
              <input
                id="input-sensorLimitDelta"
                formControlName="limitDelta"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
        </div>

        <div *ngIf="isSuperAdmin" class="row ch-margin-top-24">
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-validationMin"
                appTranslate="forms.sensor.validationMin"
              ></label>
              <input
                id="input-validationMin"
                formControlName="validationMin"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-validationMax"
                appTranslate="forms.sensor.validationMax"
                >Validierung Max</label
              >
              <input
                id="input-validationMax"
                formControlName="validationMax"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
        </div>

        <div *ngIf="isSuperAdmin" class="row ch-margin-top-24">
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-displayMinY"
                appTranslate="forms.sensor.displayMin"
              ></label>
              <input
                id="input-displayMinY"
                formControlName="displayMinY"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
          <div class="col-2">
            <div class="form-field">
              <label
                for="input-displayMaxY"
                appTranslate="forms.sensor.displayMax"
              ></label>
              <input
                id="input-displayMaxY"
                formControlName="displayMaxY"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="sensorBox.primarySensor" class="col-1 unit-col">
            <p>{{ sensorBox.sensors[sensorBox.primarySensor]?.unit }}</p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'edit'" class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field">
          <input
            type="file"
            id="image-upload"
            name="image-upload"
            accept="image/*"
            (change)="openImageEditor($event, sensorBox)"
            #imageUpload
          />
          <div class="profile-image-control">
            <button
              type="button"
              (click)="selectImage()"
              mat-flat-button
              class="btn-primary"
            >
              Sensorbild hochladen
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isSuperAdmin" class="row ch-margin-top-24">
      <div class="col-6">
        <div
          class="form-field"
          [class.error]="submitted && f.datasource.invalid"
        >
          <label
            for="input-maueInventoryNumber"
            appTranslate="forms.sensor.maueInventoryNumber"
          ></label>
          <input
            formControlName="maueInventoryNumber"
            id="input-maueInventoryNumber"
          />
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button
          class="btn-primary"
          type="submit"
          appTranslate="sensor.crud.actions.save"
        ></button>
      </div>
      <div class="col-3">
        <button
          *ngIf="mode === 'edit'"
          class="btn-secondary"
          (click)="backToSensorDetail(sensorBox)"
          appTranslate="sensor.crud.actions.cancel"
        ></button>
        <button
          *ngIf="mode === 'add'"
          class="btn-secondary"
          [routerLink]="['/dashboard', 'sensors', 'overview']"
          appTranslate="sensor.crud.actions.cancel"
        ></button>
      </div>
    </div>
  </form>
</div>
