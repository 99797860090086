import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../../../../../services/users.service';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';
import { Store } from '@ngxs/store';
import { User } from 'src/app/models/user';
import { SensorBox } from 'src/app/state/noysee/models/sensorBox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sensors-crud-contacts',
  templateUrl: './addAlarmContacts.component.html',
  styleUrls: ['./addAlarmContacts.component.scss'],
})
export class AddAlarmContactsComponent {
  sensorBox: SensorBox;

  users: User[] = [];
  allUsers: User[] = [];
  assigedReceivers: any[] = [];

  constructor(
    private userService: UsersService,
    private noyseeService: NoyseeService,
    private router: Router,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddAlarmContactsComponent>,
  ) {
    console.log('CrudContactsComponent constructor');
  }

  ngOnInit(): void {
    console.log('CrudContactsComponent ngOnInit');
    this.sensorBox = this.data?.sensorBox;

    this.assigedReceivers = this.sensorBox?.alarmReceiver;

    this.userService.usersObservable.pipe().subscribe((l) => {
      this.allUsers = l;
    });
  }

  backToSensorDetail(id: number) {
    this.dialogRef.close();
  }

  toggleUserSelected(id: number) {
    this.users.forEach((user) => {
      if (user.id === id) {
        user['selected'] = !user['selected'];
      }
    });
  }

  async addContacts(sensor: SensorBox) {
    this.users.forEach((user) => {
      // create new default alarm
      const newAlarm = {
        userId: user.id,
        userName: user.name,
        fail: {
          enabled: true,
          sms: false,
          email: false,
          phone: false,
        },
        limit1: {
          enabled: true,
          sms: false,
          email: false,
          phone: false,
        },
        limit2: {
          enabled: true,
          sms: false,
          email: false,
          phone: false,
        },
      };

      this.noyseeService.addAlarmReceiver({
        id: sensor.id,
        alarmReceiver: [...sensor.alarmReceiver, newAlarm],
      });
    });

    this.backToSensorDetail(sensor.id);
  }
}
