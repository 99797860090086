import { AuthenticationService } from '../services/authentication.service';

export enum Role {
	GUEST = 'GUEST',
	USER = 'USER',
	CITIZEN = 'CITIZEN',
	STAFF = 'STAFF',
	RESCUE_STAFF = 'RESCUE_STAFF',
	RESCUE_COMMANDER = 'RESCUE_COMMANDER',
	ADMIN = 'ADMIN',
	SUPERADMIN = 'SUPERADMIN',
	N_SALES = 'N_SALES',
	N_INSTALLER = 'N_INSTALLER',
	N_SERVICE = 'N_SERVICE',
	API_USER = 'API_USER'
}

export function getRoles(authenticationService: AuthenticationService): { label: string, value: string, disabled: boolean }[] {
	let grantableRoles = [];
	let disabledRoles = [];
	if (!authenticationService) {
		// in case of readonly
		grantableRoles = Object.values(Role);
	} else if (authenticationService.isSuperAdmin()) {
		grantableRoles = Object.values(Role);
	} else if (authenticationService.isAdmin()) {
		grantableRoles = [Role.CITIZEN, Role.STAFF, Role.RESCUE_COMMANDER, Role.ADMIN];
		disabledRoles = [Role.GUEST, Role.USER, Role.RESCUE_STAFF, Role.SUPERADMIN, Role.N_SALES, Role.N_INSTALLER, Role.N_SERVICE, Role.API_USER];

		// grantableRoles = [Role.GUEST, Role.USER, Role.CITIZEN, Role.STAFF, Role.RESCUE_STAFF, Role.RESCUE_COMMANDER, Role.ADMIN];
		// disabledRoles = [Role.SUPERADMIN, Role.N_SALES, Role.N_INSTALLER, Role.API_USER];
	} else {
		// no grantable rules
		disabledRoles = Object.values(Role);
	}

	const roles = [];
	for (const role of grantableRoles) {
		roles.push({
			label: `user.roles.${role.toLowerCase()}`,
			value: role,
			disabled: false
		});
	}
	for (const role of disabledRoles) {
		roles.push({
			label: `user.roles.${role.toLowerCase()}`,
			value: role,
			disabled: true
		});
	}
	return roles;
}
