<div class="row controls-row">
  <div class="controls-col controls-day ch-margin-right-16 col-3 col-md-2">
    <span
      [class.selected]="delta === 0"
      class="controls-field controls-day-field"
      (click)="deltaChange(0)"
      appTranslate="sensor.chart.day"
    ></span>
  </div>
  <div class="controls-col controls-week ch-margin-right-16 col-3 col-md-2">
    <span
      [class.selected]="delta === -7"
      class="controls-field controls-week-field"
      (click)="deltaChange(-7)"
      appTranslate="sensor.chart.week"
    ></span>
  </div>
  <div class="controls-col controls-week ch-margin-right-16 col-3 col-md-2">
    <span
      [class.selected]="delta === -30"
      class="controls-field controls-week-field"
      (click)="deltaChange(-30)"
      appTranslate="sensor.chart.month"
    ></span>
  </div>
  <div *ngIf="canExport" class="controls-col controls-export col-3 col-md-1">
    <span (click)="export.emit()" class="controls-icon controls-export-field">
      <img src="../../../../../../assets/img/export-csv.svg" />
    </span>
  </div>
</div>
