<ng-container *ngIf="sensorBox$ | async as sensorBox">
  <div
    *ngIf="selectedSensor$ | async as selectedSensor"
    class="sensor-chart ch-padding-left-16 ch-padding-right-16"
  >
    <app-sensor-chart-header
      [sensorBox]="sensorBox"
      [selectedSensor]="selectedSensor"
      [showSensorSelection]="displaySensorSelection"
      [trend]="trend"
      (selectionChanged)="onSelectionChange($event)"
    ></app-sensor-chart-header>
    <div *ngIf="chartData$ | async as chartData">
      <app-sensor-chart-controls
        (changeDelta)="onDeltaChange($event)"
        [delta]="delta$ | async"
        (export)="onExport(sensorBox, selectedSensor, chartData)"
        [canExport]="chartData.length > 0"
      ></app-sensor-chart-controls>
      <div class="ch-margin-top-16 ch-margin-bottom-16">
        <app-date-value-chart
          *ngIf="chartData.length"
          [displayMinY]="sensorBox.sensors[selectedSensor].displayMinY"
          [displayMaxY]="sensorBox.sensors[selectedSensor].displayMaxY"
          [sensorName]="sensorBox.name"
          [valuesType]="sensorBox.sensors[selectedSensor].chartType"
          [secondaryValuesType]="
            sensorBox.sensors[sensorBox.secondarySensor]?.chartType
          "
          [legendData]="
            getLegendData(translateService, sensorBox, selectedSensor)
          "
          [data]="chartData"
        >
        </app-date-value-chart>
        <div *ngIf="!chartData.length" id="chartdiv-placeholder">
          <p appTranslate="sensor.chart.no-value"></p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
