import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { DevicesService } from '../../../../../services/devices.service';
import { LookupService } from '../../../../../services/lookup.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { NoyseeDevice } from 'src/app/models/noyseeDevice';
import { Customer, AgsLookup } from 'src/app/models/customerList';

@Component({
  selector: 'app-addAgsDialog',
  templateUrl: './addAgsDialog.component.html',
  styleUrls: ['./addAgsDialog.component.scss']
})
export class AddAgsDialogComponent implements OnInit {
  customer: Customer;
  selectedAgsList: AgsLookup[];

  isAdmin: boolean;
  isSuperAdmin: boolean;

  ags: AgsLookup;
  agsFilter: string;

  agsList: AgsLookup[];

  constructor(
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private deviceService: DevicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddAgsDialogComponent>) {

    this.isSuperAdmin = false;
    this.isAdmin = false;

    this.agsFilter = '';
    this.ags = null;

  }

  ngOnInit(): void {
    this.customer = this.data?.customer;
    this.selectedAgsList = this.data?.selectedAgsList;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    // this.lookupService.getCustomerList().then(result => {
    //   this.customerList = result;
    // });

  }

  searchAgs(): void {
    if (this.agsFilter.length > 4) {
      this.lookupService.getAgsList(this.agsFilter).then(r => {
        this.agsList = r;
        if (this.agsList.length === 1) {
          this.ags = this.agsList[0];
        }
      });
    }
  }

  addAgs(): void {
    this.dialogRef.close({ selectedAgs: this.ags });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
