import { Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { DataTableStateService } from '../../../../../shared/data-table-state/data-table-state.service';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LookupEntry } from '../../../../../models/apiResults';
import * as moment from 'moment';
import { ApprovalDialogHelper } from 'src/app/helpers/approval-dialog.helper';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoRaWanConfigService} from 'src/app/services/loRaWanConfig.service';
import { LoRaWanConfig, getLoRaWanConfigTypes } from 'src/app/models/loraWanConfig';

@Component({
  selector: 'app-loraWanConfig-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class LoraWanConfigDetailComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  loRaWanConfig: LoRaWanConfig;
  submitted: boolean;

  crudForm: FormGroup;

  mode: string;

  isSuperAdmin: boolean;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastHelper: ToastHelper,
    private applicationContextService: ApplicationContextService,
    private approvalDialogHelper: ApprovalDialogHelper,
    private authenticationService: AuthenticationService,
    private loRaWanConfigService: LoRaWanConfigService ) {

    this.loRaWanConfig = new LoRaWanConfig();

    this.crudForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      active: new FormControl('', []),
      description: new FormControl('', []),
      apiKey: new FormControl('', []),
      password: new FormControl('', []),
      tag: new FormControl('', []),
      url: new FormControl('', []),
      mode: new FormControl('', []),
    });
    this.submitted = false;

    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.mode = '';
  }

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    if (data.mode) {
      this.mode = data.mode;
    }

    if (this.mode == 'edit') {
      this.loadLoRaWanConfig();
    } else {
      this.loRaWanConfig.active = true;
      this.dataToForm();
    }

    // React to navigation
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadLoRaWanConfig();
      });

    // this.lookupService.getMessageTemplateList().then(result => {
    //   this.templateList = result;
    // });

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

  private dataToForm() {

    this.crudForm.patchValue({
      name: this.loRaWanConfig.name,
      description: this.loRaWanConfig.description,
      active: this.loRaWanConfig.active,
      mode: this.loRaWanConfig.mode,
      apiKey: this.loRaWanConfig.apiKey,
      tag: this.loRaWanConfig.tag,
      url: this.loRaWanConfig.url,
    });
    // this.f.noyseeSN.disable();
  }

  loadLoRaWanConfig() {
    const url = this.route.snapshot.params;

    this.loRaWanConfigService.load(url.id).then(data => {
      this.loRaWanConfig = data;
      this.dataToForm();
    });

  }

  get f() {
    return this.crudForm.controls;
  }

  toggleActive() {
    const _this = this;
    _this.loRaWanConfig.active = !_this.loRaWanConfig.active;
    _this.crudForm.patchValue({
      active: _this.loRaWanConfig.active,
    });
  }

  async saveLoRaWanConfig() {
    this.submitted = true;

    if (this.crudForm.invalid) {
      return;
    }

    this.loRaWanConfig.name = this.f.name.value;
    this.loRaWanConfig.description = this.f.description.value;
    this.loRaWanConfig.active= this.f.active.value;
    this.loRaWanConfig.apiKey= this.f.apiKey.value;
    this.loRaWanConfig.mode= this.f.mode.value;
    this.loRaWanConfig.tag= this.f.tag.value;
    this.loRaWanConfig.url= this.f.url.value;

    try {
      this.loRaWanConfigService.save(this.loRaWanConfig).then(result => {
        this.loRaWanConfig = result;
        this.dataToForm();
        this.toastHelper.openSnackBar('LoRaWAN Konfiguration gespeichert!', 'SUCCESS');
        this.goBack();
      }).catch(error => {
        this.toastHelper.openSnackBar('Fehler beim Speichern der LoRaWAN Konfiguration!', 'ERROR');
      });

    } catch (e) {
        this.toastHelper.openSnackBar('Fehler beim Speichern der LoRaWAN Konfiguration!', 'ERROR');
    }
  }

  deleteLoRaWanConfig() {
    const dialogRef = this.approvalDialogHelper.openApprovalDialog('Möchten Sie diese LoRaWAN Konfiguration wirklich löschen?');
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.loRaWanConfigService.delete(this.loRaWanConfig.id).then(result => {
            this.goBack();
            this.toastHelper.openSnackBar('LoRaWAN Konfiguration gelöscht!', 'SUCCESS');
          });
        } catch (e) {
          this.toastHelper.openCrudDeleteError();
        }
      }
    });

  }

  goBack() {
    this.router.navigate(['/dashboard', 'admins', 'loraWanConfigs', 'overview']);
  }

  loraWanConfigModes(): { label: string, value: string }[] {
    return getLoRaWanConfigTypes();
  }

}
