import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoyseeService } from '../../../../../state/noysee/noysee.service';

@Component({
  selector: 'app-test-alarm-modal',
  templateUrl: './test-alarm-modal.component.html',
  styleUrls: ['./test-alarm-modal.component.scss'],
})
export class TestAlarmModalComponent implements OnInit {
  sensorId: string;
  sensorName: string;
  alarmLevel: string;

  constructor(
    private dialogRef: MatDialogRef<TestAlarmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noyseeService: NoyseeService
  ) {
    this.alarmLevel = '';
  }

  ngOnInit(): void {
    this.sensorId = this.data?.id;
    this.sensorName = this.data?.name;
  }

  async sendTestAlarm() {
    await this.noyseeService
      .sendTestAlarm(this.sensorId, this.alarmLevel)
      .catch((e) => {
        console.log('Fehler beim senden des Alarms: ', e);
      });
    this.cancel();
  }

  cancel() {
    this.dialogRef.close();
  }
}
