import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  getLegendData,
  getSensorBoxDataForDateRange,
} from '../../../../shared/chart/util';
import { SensorService } from '../../../../state/noysee/sensor.service';
import * as moment from 'moment';
import { DateValueChartDataPoint } from '../../../../shared/chart/date-value-chart/date-value-chart.component';
import { Store } from '@ngxs/store';
import { SensorActions } from '../../../../state/noysee/sensor.action';
import { SensorState } from '../../../../state/noysee/sensor.state';
import {
  CHART_TYPE,
  SensorBox,
} from '../../../../state/noysee/models/sensorBox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  sensorBox: SensorBox;
  data: DateValueChartDataPoint[];
  height: number;
  valueType: CHART_TYPE;
  secondaryValueType: CHART_TYPE;
  getLegendData = getLegendData;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sensorService: SensorService,
    private store: Store,
    public translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Fetch url parameters
    const { uuid, sensorName } = this.activatedRoute.snapshot.params;
    const { h, dateFrom, dateTo } = this.activatedRoute.snapshot.queryParams;
    this.height = h;
    // Fetch sensor based on uuid
    await this.store.dispatch(new SensorActions.Select(uuid)).toPromise();

    // Get selected sensor
    this.sensorBox = this.store.selectSnapshot(SensorState.currentSensor);
    // Read chart types for values and secondary values
    this.valueType =
      this.sensorBox.sensors[
        sensorName || this.sensorBox.primarySensor
      ].chartType;
    this.secondaryValueType =
      this.sensorBox.sensors[this.sensorBox.secondarySensor]?.chartType;
    // Get formatted data for chart
    const { recordedData: chartData } = await getSensorBoxDataForDateRange(
      this.sensorService,
      uuid,
      dateFrom
        ? moment(dateFrom).toDate()
        : moment().subtract(7, 'days').toDate(),
      dateTo ? moment(dateTo).toDate() : undefined,
      sensorName || this.sensorBox.primarySensor,
      !sensorName ? this.sensorBox.secondarySensor : undefined,
    );
    // Parse values to fixed float
    this.data = chartData.map((dataPoint) => ({
      ...dataPoint,
      value:
        typeof dataPoint?.value === 'string'
          ? parseFloat(parseFloat(dataPoint?.value).toFixed(1))
          : parseFloat(dataPoint.value?.toFixed(1)),
    }));
  }
}
