import {Component, Input} from '@angular/core';
import {DataTableColumnDef} from './data-table.model';

@Component({
  selector: 'app-base-column',
  template: ''
})
export class BaseColumnComponent {
  value: any;

  @Input()
  config: DataTableColumnDef<any>;

  @Input()
  public set data(data: any) {
    this._data = data;
    if (this.config.valueTransform) {
      this.value = this.config.valueTransform(data) || '';
    } else {
      this.value = data[this.config.key] || '';
    }
  }

  public get data(): any {
    return this._data;
  }

  protected _data: any;

}
