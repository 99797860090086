import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { DateHelper } from 'src/app/helpers/date.helper';
import {
  CurrentWeather,
  ForecastPeriod,
  ReducedDay,
  WeatherData,
} from 'src/app/models/kachelmann';
import { KachelmannService } from 'src/app/state/dashboard/kachelmann.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit {
  @Input()
  isWeatherStation$!: Observable<boolean>;
  @Input()
  lat!: number;
  @Input()
  lng!: number;

  // Weather related information
  weather$: Observable<WeatherData>;
  currentInformation$: Observable<CurrentWeather>;
  threeDayForecast$: Observable<ForecastPeriod[][]>;
  twentyFourHourForecast$: Observable<ForecastPeriod[]>;
  reducedForecast$: Observable<ReducedDay[]>;
  // Current date related information
  date$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  weekday$: Observable<string> = this.date$.pipe(
    map((date) => date.toLocaleDateString('de-DE', { weekday: 'long' })),
  );
  dateString$: Observable<string> = this.date$.pipe(
    map((date) =>
      date.toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    ),
  );
  time$: Observable<string> = this.date$.pipe(
    map((date) => {
      return `${date.getHours()}:${DateHelper.addZero(
        date.getMinutes(),
      )} ${this.translatePipe.transform('sensor.information.units.time')}`;
    }),
  );

  entireForecast: boolean = false;

  constructor(
    private kachelmannService: KachelmannService,
    private translatePipe: TranslatePipe,
  ) {}

  ngOnInit(): void {
    this.weather$ = this.kachelmannService.weather(this.lat, this.lng);
    this.currentInformation$ = this.currentInformation;
    this.twentyFourHourForecast$ = this.twentyFourHourForecast;
    this.reducedForecast$ = this.threeDayForecast;
  }

  get currentInformation(): Observable<CurrentWeather> {
    return this.weather$.pipe(map((weather) => weather.current));
  }

  get twentyFourHourForecast(): Observable<ForecastPeriod[]> {
    return this.weather$.pipe(map((weather) => weather.nextHours.slice(0, 4)));
  }

  get threeDayForecast(): Observable<ReducedDay[]> {
    return this.weather$.pipe(
      map((weather) => weather.nextDays),
      withLatestFrom(this.weather$.pipe(map((weather) => weather.nextHours))),
      map(([nextDays, nextHours]) => [
        nextHours
          .filter((hour) => hour.dayRef === nextDays[0].dayRef)
          .slice(0, 4),
        nextHours
          .filter((hour) => hour.dayRef === nextDays[1].dayRef)
          .slice(0, 4),
        nextHours
          .filter((hour) => hour.dayRef === nextDays[2].dayRef)
          .slice(0, 4),
        nextHours
          .filter((hour) => hour.dayRef === nextDays[3].dayRef)
          .slice(0, 4),
      ]),
      map((forecast) =>
        forecast.map((day) =>
          day.reduce(
            (prev, current) => ({
              dayName: current.dayRef,
              tempMin: Math.min(current.temp, prev.tempMin),
              tempMax: Math.max(current.temp, prev.tempMax),
              weatherSymbol:
                current.timeslotRef === 'afternoon'
                  ? current.weatherSymbol
                  : prev.weatherSymbol,
            }),
            {
              dayName: '',
              tempMin: 100,
              tempMax: 0,
              weatherSymbol: '',
            },
          ),
        ),
      ),
    );
  }
}
