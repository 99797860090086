import { Injectable } from '@angular/core';
import { ExportOptions, ExtendedColDef, PdfExport } from '@app/shared/ag-grid';
import { TranslateService } from '@ngx-translate/core';
import {
  Column,
  ColumnApi,
  GridApi,
  GridOptions,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community';
import { chain } from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import {
  Alignment,
  ContentStack,
  ContentText,
  Margins,
  Size,
  TDocumentDefinitions,
  TFontDictionary,
  TableCell,
} from 'pdfmake/interfaces';
import { AgGridService } from './ag-grid.service';
pdfMake.fonts = {
  DINPro: {
    normal: location.origin + '/assets/font/dinpro/regular/DINPro-Regular.ttf',
    bold: location.origin + '/assets/font/dinpro/bold/DINPro-Bold.ttf',
  },
} as TFontDictionary;

type HeaderCell = TableCell & {
  colId: string;
};

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private translateService: TranslateService) {}

  async getDocDefinition(
    exportParams: PdfExport.PdfExportParams,
    gridApi: GridApi,
    columnApi: ColumnApi,
    gridOptions?: GridOptions,
  ): Promise<TDocumentDefinitions> {
    // Prepare Body
    const columnsToExport = this.getColumnsToExport(columnApi);
    const widths = this.getExportedColumnsWidths(columnApi, columnsToExport);

    const body = [
      columnsToExport,
      ...this.getRowsToExport(gridApi, columnApi, columnsToExport, gridOptions),
    ] as TableCell[][];

    // Prepare Header
    const header = {
      stack: [
        {
          columns: [
            {
              text: moment().format('DD.MM.YYYY HH:mm:ss'),
              fontSize: 12,
              width: '30%',
              alignment: 'right' as Alignment,
            },
          ],
        },
      ],
      margin: [40, 60, 40, 0] as Margins,
    };

    // Get and display active filters
    const filters = this.getFilters(gridApi, columnApi);

    // Prepare Footer
    const footer = (currentPage, pageCount) =>
      ({
        text: `Page ${currentPage.toString()} of ${pageCount}`,
        alignment: 'right',
        fontSize: 12,
        margin: [40, 40, 40, 20],
      }) as ContentText;

    // Return DocDefinition
    return {
      pageOrientation: exportParams.orientation,
      pageMargins: [40, 80, 40, 80] as Margins,
      header,
      footer,
      content: [
        {
          text: this.translateService.instant(
            exportParams.exportFileName?.translateString ||
              exportParams.tableName,
            exportParams.exportFileName?.params,
          ),
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 5],
        } as ContentText,
        filters,
        {
          table: {
            headerRows: 1,
            widths,
            body,
            heights: 10,
          },
          layout: {
            fillColor: () => '#ffffff',
            hLineWidth: () => 1,
            vLineWidth: () => 1,
            hLineColor: '#000000',
            vLineColor: '#000000',
          },
        },
      ],
      styles: {
        'red-background': {
          fillColor: '#ffc9bd',
        },
        'text-bold': {
          bold: true,
        },
      },
      defaultStyle: {
        font: 'DINPro',
        fontSize: 7,
      },
    };
  }

  private getColumnsToExport(columnApi: ColumnApi): HeaderCell[] {
    return columnApi
      .getAllDisplayedColumns()
      .filter((col) => !this.getPdfExportOptions(col)?.skipColumn)
      .map((col) => this.createHeaderCell(col));
  }

  private getRowsToExport(
    gridApi: GridApi,
    columnApi: ColumnApi,
    columnsToExport: HeaderCell[],
    gridOptions?: GridOptions,
  ): TableCell[] {
    const rowsToExport = [];

    gridApi.forEachNodeAfterFilterAndSort((node) => {
      let classesToApply: string[] = [];
      // Apply row classes from ag grid definition to all cells inside that same row in the pdf export as well
      if (gridOptions?.rowClassRules) {
        classesToApply = Object.entries(gridOptions.rowClassRules).map(
          ([key, fn]) => {
            console.log(node, key, fn, (fn as any)(node));
            if (typeof fn !== 'string' && (fn as any)(node)) {
              return key;
            } else if (typeof fn === 'string') {
              return key;
            }
            return '';
          },
        );
      }
      const rowToExport = columnsToExport.map(({ colId }) => {
        return this.createTableCell(
          columnApi.getColumn(colId),
          {
            data: node.data,
            value: gridApi.getValue(colId, node),
          },
          classesToApply,
        );
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  private createHeaderCell(col: Column): HeaderCell {
    const colDef = col.getColDef();
    const text = colDef.headerName
      ? this.translateService.instant(colDef.headerName)
      : colDef.field[0].toUpperCase() + colDef.field.substring(1);

    return {
      text,
      bold: true,
      colId: col.getColId(),
    };
  }

  private createTableCell(
    column: Column,
    cellValue: {
      data: any;
      value: any;
    },
    classesToApply: string[],
  ): TableCell {
    const colDef = AgGridService.getCompleteColDef(column);
    // Set value to inital value
    let value = cellValue.value;
    // TODO: Readd this when AgGrid can be updated to version 30
    //if (colDef.useValueFormatterForExport && colDef.valueFormatter) {
    if (colDef.valueFormatter) {
      // If value formatter is set in params, use the value formatter for export
      const valueFormatted = this.applyValueFormatter(
        colDef.valueFormatter,
        cellValue,
      );
      if (valueFormatted) {
        value = this.translateService.instant(valueFormatted);
      }
    }
    const tableCell: TableCell = {
      text: value || '',
      style: classesToApply,
      // noWrap: PDF_PAGE_ORITENTATION === "landscape",
    };

    const pdfExportOptions = this.getPdfExportOptions(column);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      // TODO: Implement case for style function
      if (styles && !(typeof styles === 'function')) {
        Object.entries(styles).forEach(
          ([key, value]) => (tableCell[key] = value),
        );
      }

      if (createURL) {
        tableCell['link'] = createURL(cellValue.value);
        tableCell['color'] = 'blue';
        tableCell['decoration'] = 'underline';
      }
    }

    return tableCell;
  }

  private getExportedColumnsWidths(
    columnApi: ColumnApi,
    columnsToExport: HeaderCell[],
  ): Size[] {
    return columnsToExport.map(({ colId }) => {
      const exportOptions = this.getPdfExportOptions(
        columnApi.getColumn(colId),
      );
      return exportOptions?.width ?? '*';
    });
  }

  private getFilters(gridApi: GridApi, columnApi: ColumnApi): ContentStack {
    const filterModel = gridApi.getFilterModel();

    if (!Object.keys(filterModel).length) {
      return null;
    }

    return {
      stack: [
        {
          text: 'Aktive Filter',
          bold: true,
          fontSize: 12,
          margin: [0, 0, 0, 5],
        },
        {
          columns: chain(
            Object.entries(filterModel).map(([colId, filter]) => {
              const colDef = AgGridService.getCompleteColDef(
                columnApi.getColumn(colId),
              );
              const floatingFilterCallback =
                colDef.floatingFilterComponent?.prototype?.pdfExportCallback;
              if (floatingFilterCallback) {
                filter.filter = this.translateService.instant(
                  floatingFilterCallback(
                    colDef.floatingFilterComponentParams,
                    filter.filter,
                  ),
                );
              }

              if (filter.filterType === 'date') {
                return [
                  {
                    text: this.generateFilterText(
                      colDef.headerName,
                      moment(filter.dateFrom).format('DD.MM.YYYY HH:mm:ss'),
                    ),
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text: this.generateFilterText(
                      colDef.headerName,
                      moment(filter.dateTo)
                        // FIXME: https://github.com/ag-grid/ag-grid/issues/4046
                        .subtract(1, 'day')
                        .format('DD.MM.YYYY 23:59:59'),
                    ),
                    margin: [0, 0, 0, 5],
                  },
                ];
              }
              return [
                {
                  text: this.generateFilterText(
                    colDef.headerName,
                    filter.filter,
                  ),
                  margin: [0, 0, 0, 5],
                },
              ];
            }),
          )
            .flatten()
            .sortBy((o) => o.text[0].text)
            .value(),
        },
      ],
    };
  }

  private applyValueFormatter(
    // See: colDef.d.ts for typings
    formatter: string | ValueFormatterFunc,
    params: { data: any; value: any },
  ): string {
    if (!(typeof formatter === 'string')) {
      return formatter(params as ValueFormatterParams);
    }
    return formatter;
  }

  private getPdfExportOptions(column: Column): ExportOptions {
    return (column.getColDef() as ExtendedColDef).exportOptions;
  }

  private generateFilterText(translationKey: string, filterValue: any): any[] {
    return [
      {
        text: `${this.translateService.instant(translationKey)}: `,
        bold: true,
        fontSize: 10,
      },
      { text: filterValue, fontSize: 10 },
    ];
  }

  /**
   * @param url Relative file path to the desired image file
   * @returns An image encoded in base64
   */
  private async getBase64ImageFromURL(url: string): Promise<string> {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data as string);
      };
      reader.onerror = reject;
    });
  }
}
