import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomersModule} from './customers/customers.module';
import {ExportFilesModule} from './exportFiles/exportFiles.module';
import {LoraWanConfigsModule} from './loraWanConfigs/loraWanConfigs.module';
import {MessageTemplatesModule} from './messageTemplates/messageTemplates.module';
import {NoyseeDevicesModule} from './noyseeDevices/noyseeDevices.module';
import {AdminRoutingModule} from './admin-routing.module';
import { BillingModule } from './billing/billing.module';
import { FirmwareModule } from './firmware/firmware.module';
import { GeoPolygoneModule } from './geoPolygone/geoPolygone.module';
import { SensorBoxModule } from './sensorBox/sensorBox.module';

@NgModule({
  declarations: [],
  imports: [
    AdminRoutingModule,
    CommonModule,
    CustomersModule,
    ExportFilesModule,
    LoraWanConfigsModule,
    MessageTemplatesModule,
    NoyseeDevicesModule,
    BillingModule,
    FirmwareModule,
    GeoPolygoneModule,
    SensorBoxModule,
  ]
})
export class AdminModule {
}
