import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApprovalModalComponent } from '../view/dashboard/base/approval-modal/approval-modal.component';
import { ApprovalDialogConfirmation } from '../models/approvalDialogConfirmation';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApprovalDialogHelper {
  constructor(
    private matDialog: MatDialog,
    private translateService: TranslateService,
  ) {}

  // Opens a modal to approve deletion
  openApprovalDialog(
    message: string,
    approval?: ApprovalDialogConfirmation,
  ): MatDialogRef<any> {
    let modalData: any = { message: this.translateService.instant(message) };
    if (!!approval) {
      modalData = {
        ...modalData,
        approval: approval,
      };
    }
    return this.matDialog.open(ApprovalModalComponent, {
      width: '600px',
      data: modalData,
    });
  }
}
