<div class="mat-dialog-title">
  <p><strong>Gerät am Standort ersetzen</strong></p>
</div>

<div class="form-field">
  <label for="noyseeSN">Standort</label>
  <input id="noyseeSN" [(ngModel)]="name" disabled="true" />
</div>

<div class="form-field">
  <label for="noyseeSN">Altes Gerät</label>
  <input id="noyseeSN" [(ngModel)]="oldNoyseeSN" disabled="true" />
</div>

<div class="form-field" [class.error]="false">
  <label for="input-customer">Neues Gerät</label>
  <select [(ngModel)]="newNoyseeSN">
    <option [value]="null">-</option>
    <option [ngValue]="entry.noyseeSN" *ngFor="let entry of deviceList">{{entry.noyseeSN}} (ID: {{entry.id}})</option>
  </select>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="replace()">Speichern</button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>