<div class="row" *ngIf="dataTableOptions">
  <div class="title">
    <h2
      *ngIf="!dataTableOptions.titleSize || dataTableOptions.titleSize === 'H2'"
      class="ch-margin-top-40"
    >
      {{ dataTableOptions.title | translate }}
      <span class="table-small">
        <ng-container *ngIf="dataSource?.data?.length === data?.length"
          >({{ data?.length || 0 }})</ng-container
        >
        <ng-container *ngIf="dataSource?.data?.length !== data?.length"
          >({{ dataSource.data.length }} von
          {{ data?.length || 0 }})</ng-container
        >
      </span>
    </h2>
    <h1 *ngIf="dataTableOptions.titleSize === 'H1'">
      {{ dataTableOptions.title | translate }}
      <span class="table-small">
        <ng-container
          *ngIf="
            dataSource?.data?.length === data?.length;
            else differingLengths
          "
        >
          ({{ data?.length || 0 }})
        </ng-container>
        <ng-template #differingLengths
          >({{ dataSource.data.length }} von
          {{ data?.length || 0 }})</ng-template
        >
      </span>
    </h1>
  </div>
  <div class="table-actions" *ngIf="dataTableOptions.tableActions">
    <ng-container *ngFor="let action of dataTableOptions.tableActions">
      <button
        *ngIf="
          ((action.rule$ && action.rule$ | async) ||
            (action.rule && action.rule()) ||
            (!action.rule$ && !action.rule)) &&
          action.actionFn
        "
        mat-flat-button
        [disabled]="action.enabled$ && !(action.enabled$ | async)"
        class="btn-primary"
        (click)="action.actionFn()"
      >
        {{ action.label | translate }}
      </button>
      <button
        *ngIf="
          ((action.rule$ && action.rule$ | async) ||
            (action.rule && action.rule()) ||
            (!action.rule$ && !action.rule)) &&
          action.linkFn
        "
        mat-flat-button
        class="btn-primary"
        [routerLink]="action.linkFn().path"
      >
        {{ action.label | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div class="row" *ngIf="dataTableOptions">
  <div class="col-12 table-container">
    <table
      attr.aria-label="{{ dataTableOptions.title }}"
      mat-table
      matSort
      class="data-table"
      [matSortActive]="dataTableOptions.sortColumn"
      [matSortDirection]="dataTableOptions.sortDirection"
      [dataSource]="dataSource"
    >
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let rowData; columns: columns"
        [attr.style]="getRowStyle(rowData)"
      ></tr>
      <ng-container
        *ngFor="let col of visibleColumnDefinitions"
        [matColumnDef]="col.key"
      >
        <ng-container *ngIf="col.sortable; else noSorting">
          <th
            scope="col"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            [style.width]="col.width ? col.width : ''"
          >
            {{ col.displayName | translate }}
          </th>
        </ng-container>
        <ng-template #noSorting>
          <th
            scope="col"
            mat-header-cell
            *matHeaderCellDef
            [style.width]="col.width ? col.width : ''"
          >
            {{ col.displayName | translate }}
          </th>
        </ng-template>
        <td
          mat-cell
          *matCellDef="let value"
          [attr.style]="getCellStyle(col, value)"
        >
          <ng-container [ngSwitch]="col.type">
            <app-column-editor
              *ngSwitchCase="'EDITOR'"
              [config]="$any(col)"
              [data]="value"
              (dataChange)="dataChange($event)"
            ></app-column-editor>
            <app-column-iconlabel
              *ngSwitchCase="'ICONLABEL'"
              [config]="col"
              [data]="value"
            ></app-column-iconlabel>
            <app-column-text
              *ngSwitchDefault
              [config]="col"
              [data]="value"
            ></app-column-text>
          </ng-container>
        </td>
      </ng-container>
      <ng-container
        *ngIf="dataTableOptions.rowActions?.actionList?.length > 0"
        matColumnDef="dataActions"
      >
        <th scope="col" class="row-actions" mat-header-cell *matHeaderCellDef>
          {{ "table.actions" | translate }}
        </th>
        <td class="row-actions" mat-cell *matCellDef="let value">
          <ng-container [ngSwitch]="dataTableOptions.rowActions.type">
            <app-row-action-menu
              *ngSwitchCase="'MENU'"
              [data]="value"
              [actionList]="dataTableOptions.rowActions.actionList"
            ></app-row-action-menu>
            <app-row-action-buttons
              *ngSwitchCase="'BUTTON'"
              [data]="value"
              [actionList]="dataTableOptions.rowActions.actionList"
            ></app-row-action-buttons>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="dataTableOptions.filter">
        <form [formGroup]="filterGroup">
          <tr
            class="filter-row"
            mat-header-row
            *matHeaderRowDef="filterColumns"
          ></tr>
          <ng-container
            *ngFor="let col of visibleColumnDefinitions"
            [matColumnDef]="col.key + '_filter'"
          >
            <th scope="col" mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="col.filterType === 'SELECT'">
                <select [formControlName]="col.key + '_SELECT'">
                  <option value="">Kein Filter</option>
                  <option
                    *ngFor="let option of col.filterValues"
                    [value]="option.value ?? option"
                  >
                    {{ option.label ?? $any(option) | translate }}
                  </option>
                </select>
              </ng-container>
              <ng-container *ngIf="col.filterType === 'DATE'">
                <div class="date-picker">
                  <mat-date-range-input [rangePicker]="picker1" [max]="now">
                    <input
                      #from
                      matStartDate
                      placeholder="{{ 'general.from' | translate }}"
                      [formControlName]="col.key + '_DATE_from'"
                    />
                    <input
                      #to
                      matEndDate
                      placeholder="{{ 'general.to' | translate }}"
                      [formControlName]="col.key + '_DATE_to'"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <span
                    *ngIf="
                      !col.disableReset && (from.value != '' || to.value != '')
                    "
                    (click)="resetFilter(col.key, 'DATE')"
                    class="ch-action close-timetoggle close"
                  ></span>
                  <mat-date-range-picker #picker1></mat-date-range-picker>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!col.filterType || col.filterType === 'TEXT'"
              >
                <input type="text" [formControlName]="col.key + '_TEXT'" />
              </ng-container>
            </th>
          </ng-container>
        </form>
        <ng-container
          *ngIf="dataTableOptions.rowActions?.actionList?.length > 0"
          matColumnDef="dataActions_filter"
        >
          <th scope="col" mat-header-cell *matHeaderCellDef></th>
        </ng-container>
      </ng-container>
    </table>
  </div>
  <div class="col-12 bottom-row">
    <div>
      <app-paginator
        [pageSize]="
          dataTableOptions?.pageSize ? dataTableOptions?.pageSize : 13
        "
      ></app-paginator>
    </div>
    <div *ngIf="dataTableOptions.export" class="export-actions">
      <button
        *ngIf="dataTableOptions.export.csv"
        mat-stroked-button
        (click)="downloadCsv()"
      >
        CSV
        <app-icon>download</app-icon>
      </button>
      <button
        *ngIf="dataTableOptions.export.pdf"
        mat-stroked-button
        (click)="downloadPdf()"
      >
        PDF
        <app-icon>download</app-icon>
      </button>
    </div>
  </div>
</div>
