<div class="form-field">
  <label for="sensorBox">Standort</label>
  <input id="sensorBox" value="{{sensorBoxName}}" disabled="true" />
</div>

<div class="form-field ch-margin-top-24">
  <label for="name">Installierte Firmware</label>
  <input id="name" value="{{installedFirmwareName}}" disabled="true"/>
</div>

<div class="form-field" [class.error]="false">
  <label for="input-customer">Neue Firmware</label>
  <select [(ngModel)]="selectedFirmware">
    <option [value]="null">-</option>
    <option [ngValue]="entry" *ngFor="let entry of firmwareList">{{entry.name}} (ID: {{entry.id}})</option>
  </select>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="installAndClose()">Installieren</button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()">Abbrechen</button>
</mat-dialog-actions>