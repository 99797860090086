import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/alarmRules.component';
import { AlarmRuleDetailComponent } from './detail/detail.component';

const menuEntries = [
  {
    icon: 'menu-icon-movements',
    path: 'values',
    tooltip: 'sensor.values.current'
  },
  {
    icon: 'menu-icon-schedule',
    path: 'history',
    tooltip: 'sensor.values.history',
    weatherStationOnly: true,
  },
  {
    icon: 'menu-icon-users',
    path: 'contacts',
    tooltip: 'sensor.contacts.headline'
  },
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'add',
    component: AlarmRuleDetailComponent,
    data: {
      mode: 'add'
    }
  },
  {
    path: ':id',
    children: [
      {
        path: 'edit',
        component: AlarmRuleDetailComponent,
        data: {
          mode: 'edit'
        }
      },
    ],

  },
  // {
  //   path: 'detail',
  //   children: [
  //     {
  //       path: ':sno',
  //       component: NoyseeDeviceDetailComponent,
  //       children: [
  //         {
  //           path: 'edit-noyseeDevice',
  //           component: CrudNoyseeDeviceComponent,
  //           data: {
  //             breadcrumbLabel: 'noyseeDevice.table.actions.edit',
  //             mode: 'edit'
  //           }
  //         },
  //       ],
  //     }
  //   ]
  // },

  // {
  //   path: 'add-sensor',
  //   component: CrudNoyseeDeviceComponent,
  //   data: {
  //     breadcrumbLabel: 'noyseeDevice.table.actions.add',
  //     mode: 'add'
  //   }
  // },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlarmRulesRoutingModule {
}
