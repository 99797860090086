<div class="header">
	<img src="/assets/img/netzebw-logo.jpg" height="60">
</div>
<div class="stage-blue">
	<div class="content">
		<h1 class="headline" appTranslate="static.datenschutz.headerLabel"></h1>
		<strong class="subline-strong" appTranslate="static.datenschutz.subheader"></strong>
	</div>
</div>

<div class="module">
	<div class="content">
		<div class="content-block">
			<h1>Wer ist verantwortlich für die Verarbeitung meiner Daten?</h1>
			<p>
				<span>Netze BW GmbH<br>
					Sparte Dienstleistung<br>
					NETZ KDM <br>
					Durlacher Allee 93<br>
				</span>
				<a href="mailto:noysee@netze-bw.de">noysee@netze-bw.de</a>
			</p>

			<h1>Wie kann ich den Datenschutzbeauftragten erreichen? </h1>
			<p>
				<span>Unseren Datenschutzbeauftragten erreichen Sie unter <a
						href="mailto:datenschutz@netze-bw.de">datenschutz@netze-bw.de</a>. Er steht Ihnen für Fragen zum
					Datenschutz gerne zur Verfügung.</span>
			</p>

			<h1>Wie werden meine Daten verarbeitet, wenn ich die App herunterlade und nutze? </h1>
			<p>
				<span>Wir erheben und verarbeiten Ihre Daten ausschließlich dann, wenn wir entweder Ihre Einwilligung
					zur Datenverarbeitung erhalten haben oder die Verarbeitung gesetzlich erlaubt ist. </span>
			</p>

			<h2>Datenverarbeitung bei Herunterladen der App</h2>
			<p>
				<span>Wenn Sie unsere App aus einem App Store herunterladen, werden die erforderlichen Informationen an
					den App Store, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Accounts,
					Zeitpunkt des Downloads, Zahlungsinformationen und die individuelle Gerätekennziffer übertragen.
					Dies erfolgt nicht durch uns, sondern im Rahmen Ihrer Nutzungsbeziehung mit dem jeweiligen App
					Store. Wir haben keinen Einfluss auf diese Datenübertragung. Nähere Informationen können sie den
					Datenschutzhinweisen des jeweiligen App Stores entnehmen.</span>
			</p>

			<h2>Datenverarbeitung beim Nutzen der App </h2>
			<p>
				<span>Über die Funktion „Ereignis melden“ werden folgende Informationen erfasst:</span>
			</p>
			<ul>
				<li>Textuelle Beschreibung des Ereignisses</li>
				<li>Ein oder mehrere Fotos die über die Kamera des Smartphones aufgenommen wurden</li>
				<li>Optional der Standort des Benutzers zum Zeitpunkt des</li>
				<li>Optional die durch den APP-Benutzer angegebenen Kontaktdaten (z.B. Telefon oder eMail)</li>
			</ul>
			<p>
				<span>
					Die über diese Funktion erfassten Daten, sind zur Einsatzdokumentation vorgesehen und können von der
					Verwaltung (z.B. Stadt, Gemeinde, Landkreis, kreisfreie Stadt), der mittels QR-Code freigeschalteten
					Region, ausgewertet werden. Die Eingegebenen Informationen werden auf dem NOYSEE-System so lange
					gespeichert, bis die für die Region zuständige Verwaltung die Daten löscht. </span>
			</p>

			<h3>Erhebung von technisch notwendigen Daten und Logfiles</h3>
			<p>
				<span>Wenn Sie die App installieren, werden folgende Kennungen Ihres mobilen Endgeräts automatisch an
					uns übertragen: </span>
			</p>
			<ul>
				<li>Geräte-Id. (ID der App (iOS Vendor-Id bzw. Android Werbe-ID [GAID])</li>
				<li>App-Version (clientApplicationVersion) · App-Betriebssystemversion (clientOsVersion)</li>
				<li>IP-Adresse</li>
				<li>Datum und Uhrzeit der Anfrage </li>
				<li>Zeitzonendifferenz zur Greenwich Mean Time (GMT) </li>
				<li>Zugriffsstatus/HTTP-Statuscode </li>
				<li>jeweils übertragene Datenmenge </li>
				<li>Betriebssystem und dessen Oberfläche </li>
			</ul>
			<p>
				<span>Die Erhebung und Verarbeitung dieser Daten erfolgt, um die Funktionsfähigkeit der App
					sicherzustellen, die Stabilität zu gewährleisten und zu verbessern, sowie aus Sicherheitsgründen.
					Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 b) bzw. f) DSGVO. Die Daten werden
					gelöscht, sobald sie für die zuvor genannten Zwecke nicht mehr erforderlich sind. Im Falle der
					Speicherung einer IP-Adresse erfolgt eine Löschung bzw. Anonymisierung nach spätestens 7 Tagen. Die
					Erhebung dieser Daten sowie die Speicherung der Daten in Logfiles ist für die Nutzung der App
					zwingend erforderlich. Eine Widerspruchsmöglichkeit des Nutzers besteht daher nicht.
				</span>
			</p>

			<h3>Berechtigungen</h3>
			<p><span>Die App fordert folgende Berechtigungen an und nutzt sie für die nachfolgend dargestellten
					Zwecke:</span></p>
			<table>
				<tr>
					<td>Berechtigung</td>
					<td>Warum wird die Berechtigung benötigt?</td>
				</tr>
				<tr>
					<td>Kamera</td>
					<td>Scannen eines Tokens zur Registrierung für eine Region, Aufnahme von Bildern für die Funktion
						„Ereignis Melden“</td>
				</tr>
				<tr>
					<td>Standort</td>
					<td>Verschieben der Karte auf den Aktuellen Standort des Nutzers. Zuordnung der
						Ereignisdokumentation zu einem Standort</td>
				</tr>
				<tr>
					<td>Fotos und Videos</td>
					<td>Wird zum Anhängen von Bildern an eine Ereignisdokumentation benötigt</td>
				</tr>
			</table>
			<p></p>

			<h3>Verwendung von Cookies oder ähnlichen Technologien</h3>
			<p>
				<strong>Push-Benachrichtigungen</strong><br>
				<span>Unsere App informiert Sie mittels Push-Benachrichtigungen über Warnung und Entwarnungen zu
					besonderen Gefahrensituationen (Hochwasser, Unwetter, Informationen des Bundesamtes für
					Bevölkerungsschutz und Katastrophenhilfe). Rechtgrundlage für die Datenverarbeitung ist Art. 6 Abs.
					1 a) DSGVO.

					Push-Benachrichtigungen zu werblichen Zwecken werden Ihnen nur dann zugesandt, wenn Sie hierfür
					zuvor Ihre Einwilligung erteilt haben. Rechtsgrundlage für das Zusenden werblicher
					Push-Benachrichtigungen ist Art. 6 Abs. 1 a) DSGVO. </span>
			</p>

			<h1>An welche Kategorien von Empfängern werden meine Daten übermittelt? </h1>
			<p>
				<span>Die über die Funktion „Ereignis Melden“ erfassten Informationen werden an die Verwaltung der per
					QR-Code freigeschalteten Region im NOYSEE-System gespeichert und weitergeleitet. </span>
			</p>

			<h1>Werden meine Daten auch an Empfänger in Staaten außerhalb des Europäischen Wirtschaftsraumes
				übermittelt? Wie wird ein angemessenes Datenschutzniveau sichergestellt?</h1>
			<p>
				<span></span>
			</p>

			<h1>Was muss ich bei der Nutzung von Links aus Datenschutzsicht beachten? </h1>
			<p>
				<span>Unsere App kann Links zu anderen Anbietern enthalten, auf die sich unsere Datenschutzbestimmungen
					nicht erstrecken. </span>
			</p>

			<h1>Wie steht es um die Sicherheit meiner Daten? </h1>
			<p>
				<span>Wir setzen technische und organisatorische Sicherheitsmaßnahmen nach dem derzeitigen Stand der
					Technik ein, um Ihre uns zur Verfügung gestellten Daten vor zufälliger oder vorsätzlicher
					Manipulation, Verlust, Zerstörung oder dem Zugriff unberechtigter Personen zu schützen. Unsere
					Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
				</span>
			</p>

			<h1>Was gilt für Daten von Kindern? </h1>
			<p>
				<span>Wir ermuntern Erziehungsberechtigte nachdrücklich, die Online-Aktivitäten ihrer Kinder zu
					begleiten. Kinder sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine
					personenbezogenen Daten an uns übermitteln oder unsere Apps nutzen. Wir fordern wissentlich keine
					personenbezogenen Daten von Kindern an und verarbeiten diese nicht wissentlich.
				</span>
			</p>

			<h1>Welche Rechte habe ich in Bezug auf meine Daten? </h1>
			<div class="withFrame">
				<p>
					WIDERSPRUCHSRECHT ART. 21 DSGVO</p>
				<p><span>
						Sofern wir Ihre Daten aufgrund berechtigter Interessen (Art. 6 Abs. 1 f) DSGVO) oder zur
						Wahrnehmung
						einer öffentlichen Aufgabe (Art. 6 Abs. 1 e) DSGVO) verarbeiten und wenn sich aus Ihrer
						besonderen
						Situation Gründe gegen diese Verarbeitung ergeben, haben Sie gemäß Art. 21 Abs. 1 DSGVO das
						Recht
						auf Widerspruch gegen diese Verarbeitung. Im Falle eines Widerspruchs verarbeiten wir Ihre Daten
						nicht mehr zu diesen Zwecken, es sei denn wir können zwingende schutzwürdige Gründe für die
						Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die
						Verarbeitung
						dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</span></p>
				<p><span>

						Ein Recht auf Widerspruch steht Ihnen – ohne Einschränkung - gemäß Art. 21 Abs. 2 und 3 DSGVO
						gegen
						jede Art der Verarbeitung zu Zwecken der Direktwerbung zu.

					</span>
				</p>
			</div>

			<h1>Kann ich erteilte Einwilligungen widerrufen? </h1>
			<p>
				<span>Sofern wir Ihre Daten auf Basis einer von Ihnen erteilten Einwilligung verarbeiten, haben Sie das
					Recht, Ihre Einwilligung jederzeit zu widerrufen. Ihre Daten werden dann nicht mehr zu den von der
					Einwilligung umfassten Zwecken verarbeitet. Bitte beachten Sie, dass die Rechtmäßigkeit der
					Datenverarbeitung, welche vor dem Widerruf erfolgt ist, durch den Widerruf nicht berührt wird. Wie
					Sie Ihren Widerruf im Einzelnen erklären können, entnehmen Sie bitte den vorangegangenen
					Informationen bzw. der Information in der jeweiligen Einwilligung. Richten Sie Ihren Widerruf gerne
					an:</span>
			</p>
			<p>
				<a href="mailto:noysee@netze-bw.de">noysee@netze-bw.de</a>
			</p>
			<p>
				<span>
					Hier wird ihr Widerruf – sofern technisch möglich – direkt zentral umgesetzt oder Ihnen wird
					erläutert wie Sie selbst den Widerruf umsetzen können, da eine zentrale Umsetzung durch uns bei
					manchen technischen Verfahren nicht möglich ist. </span>
			</p>

			<h1>Habe ich ein Beschwerderecht bei einer Aufsichtsbehörde?</h1>
			<p>
				<span>Sofern Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen geltendes
					Recht verstößt, können Sie sich gemäß Art. 77 DSGVO jederzeit mit einer Beschwerde an eine
					Datenschutzaufsichtsbehörde wenden. Dies gilt unbeschadet anderweitiger verwaltungsrechtlicher oder
					gerichtlicher Rechtsbehelfe. </span>
			</p>

			<h1>Muss ich die Daten bereitstellen oder ist die Bereitstellung für den Vertragsschluss erforderlich? </h1>
			<p>
				<span>Mit Ausnahme der zur Nutzung unserer App technisch notwendigen Daten ist jegliche
					Datenbereitstellung durch Sie freiwillig. Es kann jedoch sein, dass einzelne Funktionen der App
					nicht funktionieren sofern sie uns die dafür benötigten Daten nicht bereitstellen. Sollte dies
					ausnahmsweise anders sein, so ist dies an der entsprechenden Stelle in dieser Erklärung explizit
					erwähnt.
				</span>
			</p>

			<h1>Wird anhand meiner Daten eine automatisierte Entscheidungsfindung durchgeführt? Und wenn ja, wie wird
				das gemacht und welche Auswirkungen hat dies auf mich? </h1>
			<p>
				<span>Nein, die durch „Ereignis Erfassen“ erhobenen Daten werden durch die Verwaltung manuell gesichtet,
					verarbeitet und ggf. aus dem NOYSEE-System gelöscht. </span>
			</p>

			<h1>Können diese Informationen geändert werden? Und wenn ja, wie erfahre ich hiervon? </h1>
			<p>
				<span>Die über „Ereignis erfassen“ erfassten Daten können nach absenden durch den APP-Benutzer nicht
					mehr geändert werden. Die Verwaltung der Region hat die Möglichkeit die Daten zu löschen, wenn sie
					nicht weiter benötigt werden </span>
			</p>

		</div>
	</div>
</div>
<div class="footer" appTranslate="general.copyright">
</div>