<div class="mat-dialog-title">
  <p><strong appTranslate="sensor.test-alarm.headline"></strong></p>
</div>
<div class="form-field">
  <label for="alarm-level" appTranslate="sensor.test-alarm.alarm-level.label"></label>
  <select id="alarm-level" [(ngModel)]="alarmLevel">
    <option selected value="" appTranslate="sensor.test-alarm.alarm-level.all"></option>
    <option value="-1" appTranslate="sensor.test-alarm.alarm-level.fail"></option>
    <option value="1" appTranslate="sensor.test-alarm.alarm-level.limit1"></option>
    <option value="2" appTranslate="sensor.test-alarm.alarm-level.limit2"></option>
  </select>
</div>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="sendTestAlarm()"
          appTranslate="sensor.test-alarm.actions.send"></button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()"
          appTranslate="sensor.test-alarm.actions.cancel"></button>
</mat-dialog-actions>
