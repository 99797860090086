import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '@app/helpers/toast.helper';
import { User, UserType } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { NotificationService } from '@app/services/notification.service';
import { UsersService } from '@app/services/users.service';
import { GuiState } from '@app/state/dashboard/gui.state';
import { AlarmActionService } from '@app/state/noysee/alarmAction.service';
import { AlarmAction, AlarmActionExecutionType } from '@app/state/noysee/models/alarmAction';
import { Sensor } from '@app/state/noysee/models/sensor';
import { Store } from '@ngxs/store';
import { RenderedInfo } from 'src/app/state/noysee/models/sensorBox';

@Component({
	selector: 'app-activate-sssoUser-modal',
	templateUrl: './activate-ssoUser-modal.component.html',
	styleUrls: ['./activate-ssoUser-modal.component.scss'],
})
export class ActivateSsoUserModalComponent implements OnInit {
	superadmin: boolean;
	user: User;

	userList: User[];
	forwardUserId: number;

	constructor(
		private dialogRef: MatDialogRef<ActivateSsoUserModalComponent>,
		private userService: UsersService,
		private authService: AuthenticationService,
		private usersService: UsersService,
		private notificationService: NotificationService,
		private toastHelper: ToastHelper,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { }

	ngOnInit(): void {
		this.user = this.data?.user;
		this.superadmin = this.authService.isSuperAdmin();

		this.usersService.getList( UserType.LOGIN).then((data) => {
			this.userList = data;
		});

	}

	async execute() {
		// let customerId = this.store.selectSnapshot(GuiState.selectedCustomer).id;

		if (this.forwardUserId) {
			this.user.forwardUser = { id: this.forwardUserId, name: '' }
		} else {
			this.user.forwardUser = null;
		}

		this.userService.activateUser(this.user).then((result) => {
			this.toastHelper.openSnackBar('Benutzer aktiviert!', 'SUCCESS');
			this.notificationService.updateOpenNotifications();
			this.dialogRef.close();
		}).catch(e => {
			console.debug(e.message);
			this.toastHelper.openSnackBar('Benutzer konnte nicht aktiviert werden!', 'ERROR');
		});
	}

	cancel() {
		this.dialogRef.close();
	}
}
