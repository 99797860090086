import { FrontendType } from '../../noysee/models/sensor';
import { TopoType } from '../../../models/topo';

export enum LayerType {
  FRONTEND = 'frontendType',
  TOPO = 'topo',
}

export class MapLayer {
  layerName: string;
  enabled: boolean;
  selected: boolean;
  mainType: LayerType;
  subType: FrontendType | TopoType;
}
