import { Injectable } from '@angular/core';
import { LookupEntryWithState } from '../models/apiResults';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ApplicationContextService } from './application-context.service';
import { Customer, CustomerResult, AgsLookup, AgsLookupListResult } from '../models/customerList';
import { LookupService } from './lookup.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    private lookupService: LookupService,
  ) {

  }

  getList(): Promise<LookupEntryWithState[]> {
    return this.lookupService.getCustomerList();
  }

  load(id: number): Promise<Customer> {
    return this.http.get<CustomerResult>(`${environment.dashboardApiUrl}/customer/detail?id=` + id)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  save(customer: Customer): Promise<Customer> {
    const { sensorBoxes, users, ...postCustomer } = customer;
    return this.http.put<CustomerResult>(`${environment.dashboardApiUrl}/customer/detail`, postCustomer)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  createCustomer(customer: Customer): Promise<Customer> {
    return this.http.post<CustomerResult>(`${environment.dashboardApiUrl}/customer/detail`, customer)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  delete(id: number): Promise<Customer> {
    throw new Error('Method not implemented.');
  }

  removeAgs(customerId: number, ags: string): Promise<AgsLookup[]> {
    return this.http.delete<AgsLookupListResult>(`${environment.dashboardApiUrl}/customer/`+customerId+`/ags/`+ags)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

  addAgs(customerId: number, ags: string): Promise<AgsLookup[]> {
    return this.http.post<AgsLookupListResult>(`${environment.dashboardApiUrl}/customer/`+customerId+`/ags/`+ags, null)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}