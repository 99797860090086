<div class="primary-panel-large container-fluid">
	<div class="row">
		<div class="col-8">
			<h1 *ngIf="mode === 'view'">Kunde</h1>
			<h1 *ngIf="mode === 'add'">Kunde anlegen</h1>
			<h1 *ngIf="mode === 'edit'">Kunde bearbeiten</h1>
		</div>
	</div>
	<form *ngIf="crudDeviceForm" class="form" [formGroup]="crudDeviceForm" (ngSubmit)="saveCustomer()">
		<div class="row ch-margin-top-24">
			<div class="col-1 role">
				<strong>Aktiv</strong>
			</div>
			<div class="col-4 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active"
							[class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-12">
				<div class="form-field" [class.error]="submitted && f.name.invalid">
					<label for="input-name">Name *</label>
					<input placeholder="" id="input-name" formControlName="name" />
					<div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">
						Bitte einen Namen für den Kunden wählen!
					</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-6">
				<div class="form-field" [class.error]="submitted && f.ags.invalid">
					<label for="input-ags">Gemeindeschlüssel (AGS) *</label>
					<input placeholder="" id="input-ags" formControlName="ags" maxlength="8" />
					<div *ngIf="submitted && f.ags.invalid" class="error-message ch-margin-top-4">
						Bitte einen Gemeindeschlüssel für den Kunden wählen!
					</div>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-4">
				<div class="form-field" [class.error]="submitted && f.mapCenterLat.invalid">
					<label for="input-mapCenterLat" appTranslate="forms.customer.mapCenterLat.label"
						class="important"></label>
					<input formControlName="mapCenterLat" id="input-mapCenterLat" />
					<div *ngIf="
              submitted &&
              f.mapCenterLat.invalid &&
              f.mapCenterLat.errors.required
            " class="error-message ch-margin-top-4" appTranslate="forms.customer.mapCenterLat.error"></div>
				</div>
			</div>
			<div class="col-4">
				<div class="form-field" [class.error]="submitted && f.mapCenterLon.invalid">
					<label for="input-mapCenterLon" appTranslate="forms.customer.mapCenterLon.label"
						class="important"></label>
					<input formControlName="mapCenterLon" id="input-mapCenterLon" />
					<div *ngIf="
              submitted &&
              f.mapCenterLon.invalid &&
              f.mapCenterLon.errors.required
            " class="error-message ch-margin-top-4" appTranslate="forms.customer.mapCenterLon.error"></div>
				</div>
			</div>
			<div class="col-4">
				<button type="button" class="btn-secondary" (click)="getMapCenter()" *ngIf="!crudDeviceForm.disabled">
					Aus Karte übernehmen
				</button>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-4">
				<div class="form-field" [class.error]="submitted && f.zoomDashboard.invalid">
					<label for="input-zoomDashboard" appTranslate="forms.customer.zoomDashboard.label"
						class="important"></label>
					<select [attr.disabled]="null" id="select-zoomDashboard" formControlName="zoomDashboard">
						<option [ngValue]="10">10</option>
						<option [ngValue]="11">11</option>
						<option [ngValue]="12">12</option>
						<option [ngValue]="13">13</option>
						<option [ngValue]="14">14</option>
						<option [ngValue]="15">15</option>
					</select>
				</div>
			</div>
			<div class="col-4">
				<div class="form-field" [class.error]="submitted && f.zoomApp.invalid">
					<label for="input-zoomApp" appTranslate="forms.customer.zoomApp.label" class="important"></label>
					<select [attr.disabled]="null" id="select-zoomApp" formControlName="zoomApp">
						<option [ngValue]="10">10</option>
						<option [ngValue]="11">11</option>
						<option [ngValue]="12">12</option>
						<option [ngValue]="13">13</option>
						<option [ngValue]="14">14</option>
						<option [ngValue]="15">15</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-4">
				<div class="form-field">
					<label for="input-alarmMessageTemplateId">Template für Eskalation</label>
					<select [attr.disabled]="null" id="select-alarmMessageTemplateId"
						formControlName="alarmMessageTemplateId">
						<option [ngValue]="null" selected="true">-</option>
						<option [ngValue]="lookup.id" *ngFor="let lookup of templateList">
							{{ lookup.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="col-4">
				<div class="form-field">
					<label for="input-clearMessageTemplateId">Template für Deeskalation</label>
					<select [attr.disabled]="null" id="select-clearMessageTemplateId"
						formControlName="clearMessageTemplateId">
						<option [ngValue]="null" selected="true">-</option>
						<option [ngValue]="lookup.id" *ngFor="let lookup of templateList">
							{{ lookup.name }}
						</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-4">
				<div class="form-field" [class.error]="submitted && f.bigRegistrationDate.invalid">
					<label for="input-bigRegistrationDate">Registrierungszeitpunkt bei BIG</label>
					<input type="datetime-local" placeholder="" id="input-bigRegistrationDate"
						formControlName="bigRegistrationDate" [disabled]="true" />
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-10">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label>AGS für Warnungen</label>
					<app-tile-list [(value)]="customerDetails.alertingAgsList" keyField="ags" descriptiveField="name"
						(addItem)="addAgs()"></app-tile-list>
				</div>
			</div>
		</div>

		<div class="row ch-margin-top-24">
			<div class="col-2 role">
				<strong>PhoneCall</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featurePhoneCall')" id="input-active"
							formControlName="featurePhoneCall" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

			<div class="col-2 role">
				<strong>Voice</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureVoiceMessage')" id="input-active"
							formControlName="featureVoiceMessage" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>
		</div>
		<div class="row ch-margin-top-24">
			<div class="col-2 role">
				<strong>Regenradar</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureRainRadar')" id="input-active"
							formControlName="featureRainRadar" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

			<div class="col-2 role">
				<strong>Bürger-APP</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureApp')" id="input-active"
							formControlName="featureApp" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

			<div class="col-2 role">
				<strong>Auswertungen</strong>
			</div>

			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureReports')" id="input-active"
							formControlName="featureReports" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

		</div>

		<div class="row ch-margin-top-24">
			<div class="col-2 role">
				<strong>Alarmgruppen</strong>
			</div>
			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureAlarmGroups')" id="input-active"
							formControlName="featureAlarmGroups" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

			<div class="col-2 role">
				<strong>Alarmregeln</strong>
			</div>

			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureAlarmRules')" id="input-active"
							formControlName="featureAlarmRules" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

			<div class="col-2 role">
				<strong>Alarmaktionen</strong>
			</div>

			<div class="col-1 role">
				<div class="form-field" [class.error]="submitted && f.active.invalid">
					<label class="switch">
						<input type="checkbox" (click)="toggleFeature('featureAlarmActions')" id="input-active"
							formControlName="featureAlarmActions" [class.disabled]="mode == 'view'" />
						<span class="slider round" [class.disabled]="mode == 'view'"></span>
					</label>
				</div>
			</div>

		</div>

		<div class="row ch-margin-top-24">
			<div class="col-6">
				<div class="form-field" [class.error]="submitted && f.ags.invalid">
					<label for="input-maueCostCenter">MAuE CostCenter</label>
					<input placeholder="" id="input-maueCostCenter" formControlName="maueCostCenter" maxlength="20" />
					<div *ngIf="submitted && f.maueCostCenter.invalid" class="error-message ch-margin-top-4">
						Bitte den MAuE CostCenter Schlüssel für den Kunden wählen!
					</div>
					<div *ngIf="f.maueCostCenter.invalid && f.maueCostCenter.errors.maxlength"
						class="error-message ch-margin-top-4">
						Bitte maximal 20 Zeichen angeben
					</div>
				</div>
			</div>


		</div>

		<div class="row ch-margin-top-24">
			<div class="col-3">
				<button class="btn-primary" *ngIf="!crudDeviceForm.disabled">
					Speichern
				</button>
			</div>
			<div class="col-3">
				<button class="btn-secondary" (click)="goBack()" *ngIf="!crudDeviceForm.disabled">
					Abbrechen
				</button>
			</div>
			<div class="col-3"></div>
		</div>
	</form>
</div>