import {AfterContentChecked, Component, ElementRef, Input} from '@angular/core';
import {icons} from './icon.data';
import {ThemePalette} from '@angular/material/core';


@Component({
  selector: 'app-icon',
  template: `
    <span class="name"><ng-content></ng-content></span>
    <span class="icon">
      <mat-icon *ngIf="!svg" [color]="color">{{name}}</mat-icon>
      <mat-icon *ngIf="svg" [color]="color" svgIcon="{{name}}"></mat-icon>
    </span>
  `,
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements AfterContentChecked {
  name: string | null;

  svg = false;

  @Input()
  color: ThemePalette;

  static isSvg(name: string) {
    return name.startsWith('app-') && !!icons.find((icon) => icon.name === name);
  }

  static trimContent(content: string) {
    const regex = /^\s+|\s+$/g;
    return content.replace(regex, '');
  }

  constructor(private element: ElementRef<HTMLElement>) {
  }

  ngAfterContentChecked(): void {
    if (this.element) {
      const htmlElement = this.element.nativeElement.querySelector('.name');
      if (htmlElement) {
        this.name = IconComponent.trimContent(htmlElement.textContent);
        if (this.name) {
          this.svg = IconComponent.isSvg(this.name);
        }

      }
    }
  }


}
