import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as _ from 'lodash';
import { Role } from '../models/role.enum';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  alwaysAllowedUrls: string[] = ['/sensors*'];
  adminUrls: string[] = ['/alarmRules*', '/alarmGroups*', '/users*'];
  superAdminUrls: string[] = ['/admin*', '/warningBroker*'];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authenticationService.authenticatedUserObservable.pipe(
      map(
        (authenticatedUser) =>
          !!authenticatedUser || this.router.parseUrl('/login'),
      ),
    );
  }
}
