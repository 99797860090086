import { Injectable } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
  createSelector,
} from '@ngxs/store';
import { LatLng, LatLngBounds, Map, MapOptions } from 'leaflet';
import { LeafletHelper } from 'src/app/helpers/leaflet.helper';
import { LayerType, MapLayer } from 'src/app/state/dashboard/models/mapLayer';
import { MapActions } from './map.action';
import { TopoService } from './topo.service';
import { first } from 'rxjs/operators';
import { FrontendType } from 'src/app/state/noysee/models/sensor';
import { TopoType } from 'src/app/models/topo';
import { GuiState } from './gui.state';

interface MapStateModel {
  layers: MapLayer[];
  controls: {
    timeSliderOffset: number;
  };
}

const MAP_STATE_TOKEN = new StateToken<MapStateModel>('map');

@State<MapStateModel>({
  name: MAP_STATE_TOKEN,
  defaults: {
    layers: [],
    controls: {
      timeSliderOffset: 0,
    },
  },
})
@Injectable()
export class MapState implements NgxsOnInit {
  constructor(
    private topoService: TopoService,
    private store: Store,
  ) {}

  ngxsOnInit(ctx: StateContext<any>): void {
    // Get active filters from backend when authenticated
    this.store
      .select(GuiState.selectedCustomer)
      .pipe(first((customer) => !!customer))
      .subscribe((_) => {
        ctx.dispatch(new MapActions.FetchAvailableLayers());
      });
  }

  @Selector()
  static layers(state: MapStateModel): MapLayer[] {
    return state.layers;
  }

  static layer(mainType: LayerType, subType: FrontendType | TopoType) {
    return createSelector([MapState], (state: MapStateModel): MapLayer => {
      return state.layers.find(
        (layer) => layer.mainType === mainType && layer.subType === subType,
      );
    });
  }

  static layerActive(mainType: LayerType, subType: FrontendType | TopoType) {
    return createSelector([MapState], (state: MapStateModel): boolean => {
      return (
        state.layers.find(
          (layer) => layer.mainType === mainType && layer.subType === subType,
        )?.selected ?? false
      );
    });
  }

  static allLayersActiveOfType(mainType: LayerType) {
    return createSelector([MapState], (state: MapStateModel): boolean => {
      return state.layers
        .filter((layer) => layer.mainType === mainType)
        .every((layer) => layer.selected);
    });
  }

  static noLayerActiveOfType(mainType: LayerType) {
    return createSelector([MapState], (state: MapStateModel): boolean => {
      return state.layers
        .filter((layer) => layer.mainType === mainType)
        .every((layer) => !layer.selected);
    });
  }

  @Selector()
  static controls(state: MapStateModel): MapStateModel['controls'] {
    return state.controls;
  }

  @Selector()
  static timeSliderOffset(state: MapStateModel): number {
    return state.controls.timeSliderOffset;
  }

  @Action(MapActions.FetchAvailableLayers)
  fetchAvailableLayers(ctx: StateContext<MapStateModel>) {
    this.topoService.getAvailableMapLayers().subscribe((layers) => {
      ctx.patchState({
        layers,
      });
    });
  }

  @Action(MapActions.SetLayerVisibility)
  setLayerVisibility(
    ctx: StateContext<MapStateModel>,
    action: MapActions.SetLayerVisibility,
  ) {
    ctx.patchState({
      layers: ctx
        .getState()
        .layers.map((layer) =>
          layer.layerName === action.name
            ? { ...layer, selected: action.visible }
            : layer,
        ),
    });
  }

  @Action(MapActions.SetLayerVisibilityByType)
  setLayerVisibilityByType(
    ctx: StateContext<MapStateModel>,
    action: MapActions.SetLayerVisibilityByType,
  ) {
    ctx.patchState({
      layers: ctx
        .getState()
        .layers.map((layer) =>
          layer.mainType === action.type
            ? { ...layer, selected: action.visible }
            : layer,
        ),
    });
  }

  private calulateLngMapOffset(boundingBox: LatLngBounds) {
    return (boundingBox.getEast() - boundingBox.getWest()) * (3 / 8);
  }
}
