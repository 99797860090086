import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NoyseeDevice} from '../models/noyseeDevice';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {GeoPolygoneListResult, GeoPolygoneResult, ImportConfigListResult, ImportConfigResult} from '../models/apiResults';
import {map} from 'rxjs/operators';
import { ImportConfig } from '@app/models/importConfig';
import { GeoPolygone } from '@app/models/topo';

@Injectable({providedIn: 'root'})
export class GeoPolygonService {

  constructor(private http: HttpClient) {
  }

  list(): Promise<GeoPolygone[]> {
    return this.http.get<GeoPolygoneListResult>(`${environment.dashboardApiUrl}/topo/geoPolygone/list`).pipe(map(result => {
      if (!result.ok) {
        throw new Error(result.errorCode);
      }
      return result.data;
    })).toPromise();
  }

  save( importConfig: GeoPolygone): Promise<GeoPolygone> {
    return this.http.put<GeoPolygoneResult>(`${environment.dashboardApiUrl}/topo/geoPolygone`, importConfig).pipe(map(result => {
      if (!result.ok) {
        throw new Error(result.errorCode);
      }
      return result.data;
    })).toPromise();
  }

  load( id: number): Promise<GeoPolygone> {
    return this.http.get<GeoPolygoneResult>(`${environment.dashboardApiUrl}/topo/geoPolygone/`+id).pipe(map(result => {
      if (!result.ok) {
        throw new Error(result.errorCode);
      }
      return result.data;
    })).toPromise();
  }

  delete(id: number): Promise<GeoPolygone> {
    return this.http.delete<GeoPolygoneResult>(`${environment.dashboardApiUrl}/topo/geoPolygone/` + id)
      .pipe(map((result: GeoPolygoneResult) => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      })).toPromise();
  }

}
