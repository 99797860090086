import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { CrudUserComponent } from './crud-user/crud-user.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../../../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { PasswordModalComponent } from './crud-user/password-modal/password-modal.component';
import { CrudApiKeyComponent } from './crud-apiKey/crud-apiKey.component';
import { ActivateSsoUserModalComponent } from './activate-ssoUser-modal/activate-ssoUser-modal.component';

@NgModule({
	declarations: [
		OverviewComponent, 
		CrudUserComponent, 
		ImageEditorComponent, 
		PasswordModalComponent, 
		CrudApiKeyComponent, 
		ActivateSsoUserModalComponent,
	],
	imports: [
		MatDialogModule,
		ImageCropperModule,
		CommonModule,
		UsersRoutingModule,
		MatButtonModule,
		MatTableModule,
		FormsModule,
		MatPaginatorModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatSlideToggleModule,
		MatSortModule,
		MatIconModule,
		MatMenuModule,
		SharedModule,
		MatTooltipModule,
		TranslateModule.forChild(),
	],
	providers: [
		TranslatePipe
	]
})
export class UsersModule {
}
