import { Injectable } from '@angular/core';
import {
  LookupWithStateListResult,
  LookupEntryWithState,
  LookupEntry,
  FirmwareListResult,
  BackendStatusResult,
  LookupListResult,
} from '../models/apiResults';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AgsLookup, AgsLookupListResult } from '../models/customerList';
import { Firmware } from '../models/firmware';
import { MessageTemplateClass } from '@app/models/messageTemplate';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private http: HttpClient) {}

  getBackendStatus(): Promise<BackendStatusResult> {
    return this.http
      .get<BackendStatusResult>(`${environment.dashboardApiHost}/api/status`)
      .pipe(
        map((result) => {
          return result;
        }),
      )
      .toPromise();
  }

  getMessageTemplateList( templateClass: MessageTemplateClass = null): Promise<LookupEntryWithState[]> {
    return this.http
      .get<LookupWithStateListResult>(
        `${environment.dashboardApiUrl}/messageTemplate/list`+( templateClass ? '?class='+templateClass : ""),
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getCustomerList(): Promise<LookupEntryWithState[]> {
    return this.http
      .get<LookupWithStateListResult>(
        `${environment.dashboardApiUrl}/customer/list`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getFirmwareList(sensorBoxId: number): Promise<Firmware[]> {
    return this.http
      .get<FirmwareListResult>(
        `${environment.dashboardApiUrl}/firmware/list?sensorBoxId=` +
          sensorBoxId,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getLoraConfigList(): Promise<LookupEntry[]> {
    return this.http
      .get<LookupWithStateListResult>(
        `${environment.dashboardApiUrl}/admin/loraWanServer/list`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getDestinationList(): Promise<LookupEntry[]> {
    return this.http
      .get<LookupWithStateListResult>(
        `${environment.dashboardApiUrl}/exportFileAdmin/destination/list`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getExportTemplateList(): Promise<LookupEntry[]> {
    return this.http
      .get<LookupWithStateListResult>(
        `${environment.dashboardApiUrl}/exportFileAdmin/template/list`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getAgsList(search: string): Promise<AgsLookup[]> {
    return this.http
      .get<AgsLookupListResult>(
        `${environment.dashboardApiUrl}/ags/find?search=` + search,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getSensorBoxTemplateList(): Promise<LookupEntry[]> {
    return this.http.get<LookupListResult>(`${environment.dashboardApiUrl}/sensorBox/templateList`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }

  getGeoPolygoneList( nameLike: string): Promise<LookupEntry[]> {
    return this.http.get<LookupListResult>(`${environment.dashboardApiUrl}/topo/geoPolygone/list`)
      .pipe(map(result => {
        if (!result.ok) {
          throw new Error(result.errorCode);
        }
        return result.data;
      }))
      .toPromise();
  }

}
