<div *ngIf="sensorBox$ | async as sensorBox" class="row">
  <div class="col-lg-4 col-12 ch-margin-top-4">
    <app-sensors-information
      [currentSensor]="sensorBox"
    ></app-sensors-information>
  </div>
  <div class="col-lg-8 col-12">
    <app-sensor-chart
      [displaySensorSelection]="true"
      [selectedSensorInput]="selectedSensor"
    ></app-sensor-chart>
  </div>
</div>
