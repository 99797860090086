<div class="mat-dialog-title">
  <p><strong>Geliefertes Produkte erfassen</strong></p>
</div>

<div class="form-field ch-margin-top-24" [class.error]="false">
  <label for="input-customer">Kunde</label>
  <select [(ngModel)]="delivery.customer">
    <option [value]="null">-</option>
    <option [ngValue]="entry" *ngFor="let entry of customerList">
      {{ entry.name }} (ID: {{ entry.id }})
    </option>
  </select>
</div>

<div class="form-field ch-margin-top-24" [class.error]="false">
  <label for="input-customer">Product</label>
  <select [(ngModel)]="selectedProduct" (change)="selectProduct($event)">
    <option [value]="null">-</option>
    <option [ngValue]="entry" *ngFor="let entry of productList">
      {{ entry.name }}: {{ entry.price }} €
      {{ "billing.billingInterval." + entry.billingInterval | translate }}
    </option>
  </select>
</div>

<div class="form-field ch-margin-top-24">
  <label for="name">Name</label>
  <input id="name" [(ngModel)]="delivery.name" />
</div>

<div class="form-field">
  <label for="description">Description</label>
  <input id="description" [(ngModel)]="delivery.description" />
</div>

<div class="row ch-margin-top-24">
  <div class="col-2">
    <div class="form-field">
      <label for="amount">Anzahl</label>
      <input id="amount" [(ngModel)]="delivery.amount" />
    </div>
  </div>

  <div class="col-4">
    <div class="form-field">
      <label for="name">Preis ( € )</label>
      <input id="name" [(ngModel)]="delivery.price" />
    </div>
  </div>

  <div class="col-6">
    <div class="form-field" [class.error]="false">
      <label for="input-customer">Abrechnungsintervall</label>
      <select [(ngModel)]="delivery.billingInterval">
        <option [value]="0">Einmalig</option>
        <option [value]="1">Monatlich</option>
        <option [value]="12">Jährlich</option>
        <option [value]="36">3 Jahre</option>
      </select>
    </div>
  </div>
</div>

<div class="row ch-margin-top-24" *ngIf="delivery.billingInterval != 0">
  <div class="col-3">
    <div class="form-field">
      <label for="contractPeriodBase">Initiale Laufzeit (Monate)</label>
      <select id="contractPeriodBase" [(ngModel)]="delivery.contractPeriodBase">
        <option [value]="null">-</option>
        <option [value]="24">2 Jahre</option>
        <option [value]="36">3 Jahre</option>
      </select>
    </div>
  </div>

  <div class="col-3">
    <div class="form-field">
      <label for="contractPeriodExtension">Verlängerung (Monate)</label>
      <select
        id="contractPeriodExtension"
        [(ngModel)]="delivery.contractPeriodExtension"
      >
        <option [value]="null">-</option>
        <option [value]="6">6 Monate</option>
        <option [value]="12">1 Jahre</option>
        <option [value]="24">2 Jahre</option>
      </select>
    </div>
  </div>

  <div class="col-3">
    <div class="form-field">
      <label for="noticePeriod">Kündigungsfrist (Monate)</label>
      <select id="noticePeriod" [(ngModel)]="delivery.noticePeriod">
        <option [value]="null">-</option>
        <option [value]="1">1 Monate</option>
        <option [value]="2">2 Monate</option>
        <option [value]="3">3 Monate</option>
      </select>
    </div>
  </div>
</div>

<div class="row ch-margin-top-24">
  <div class="col-3">
    <div class="form-field">
      <label for="deliveryDate">Lieferdatum</label>
      <input
        type="date"
        [valueAsDate]="delivery.delivery"
        (change)="setDeliveryField('delivery', $event)"
        placeholder="dd.MM.yyyy"
        id="deliveryDate"
      />
    </div>
  </div>

  <div class="col-3" *ngIf="delivery.billingInterval != 0">
    <div class="form-field">
      <label for="contractStart">Vertragsbeginn</label>
      <input
        type="date"
        [valueAsDate]="delivery.contractStart"
        (change)="setDeliveryField('contractStart', $event)"
        id="contractStart"
      />
    </div>
  </div>

  <div class="col-3" *ngIf="delivery.billingInterval != 0">
    <div class="form-field">
      <label for="contractEnd">Vertragsende</label>
      <input
        type="date"
        [valueAsDate]="delivery.contractEnd"
        (change)="setDeliveryField('contractEnd', $event)"
        id="contractEnd"
      />
    </div>
  </div>

  <div class="col-9" *ngIf="delivery.billingInterval == 0">
    <div class="form-field">
      <label for="amount">Sendungsnummer</label>
      <input id="trackingNumber" [(ngModel)]="delivery.trackingNumber" />
    </div>
  </div>
</div>

<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button class="btn-primary" (click)="addDeliveryAndClose()">
    Speichern
  </button>
  <button mat-flat-button class="btn-secondary" (click)="cancel()">
    Abbrechen
  </button>
</mat-dialog-actions>
