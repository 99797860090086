import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {icons} from './icon.data';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrationService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
  }

  initializeIcons() {
    for (const icon of icons) {
      if (icon.content) {
        this.iconRegistry.addSvgIconLiteral(icon.name, this.sanitizer.bypassSecurityTrustHtml(icon.content));
      }
    }
  }
}
