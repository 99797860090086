import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastHelper } from '../../../../../helpers/toast.helper';
import { DevicesService } from '../../../../../services/devices.service';
import { LookupService } from '../../../../../services/lookup.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { LookupEntry } from 'src/app/models/apiResults';
import { NoyseeDevice } from 'src/app/models/noyseeDevice';
import { NoyseeService } from '@app/state/noysee/noysee.service';

@Component({
  selector: 'app-createSensorBoxDialog',
  templateUrl: './createSensorBoxDialog.component.html',
  styleUrls: ['./createSensorBoxDialog.component.scss']
})
export class CreateSensorBoxDialogComponent implements OnInit {
  isAdmin: boolean;
  isSuperAdmin: boolean;

  noyseeSN: string;
  customerId: number;
  lat: number;
  lon: number;
  sensorBoxTemplateId: number;
  name: string;

  templateList: LookupEntry[];
  customerList: LookupEntry[];

  constructor(
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private noyseeService: NoyseeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateSensorBoxDialogComponent>) {

    this.isSuperAdmin = false;
    this.isAdmin = false;

    this.noyseeSN = '';
    this.customerId = null;
  }

  ngOnInit(): void {
	
    this.isSuperAdmin = this.authenticationService.isSuperAdmin();
    this.isAdmin = this.authenticationService.isAdmin();

    this.name = this.noyseeSN;
    
    this.sensorBoxTemplateId = null;
    this.customerId = this.data?.customerId;

    this.lookupService.getSensorBoxTemplateList().then(result => {
      this.templateList = result;
    });

    this.lookupService.getCustomerList().then(result => {
      this.customerList = result;
    });

  }

  installAndClose(): void {
    this.noyseeService.createSensorBoxByTemplate(
      this.customerId,
      this.sensorBoxTemplateId,
      this.lat,
      this.lon,
      this.name,
    ).then(data => {
      this.toastHelper.openSnackBar('SensorBox erstellt', 'SUCCESS');
      this.dialogRef.close();
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
