<div class="primary-panel-small container-fluid">
  <div class="row">
    <div class="col-12">
      <h1 appTranslate="sensor.contacts.crud.headline"></h1>
    </div>
  </div>
  <div class="row ch-margin-top-24">
    <div class="col-12">
		<app-ab-list [(value)]="users" [allOptions]="allUsers" keyField="id" descriptiveField="name" [excludeList]="assigedReceivers" excludeListKeyField="userId"></app-ab-list>
    </div>
  </div>
  <div *ngIf="sensorBox" class="row ch-margin-top-24">
    <div class="col-3">
      <button
        class="btn-primary"
        (click)="addContacts(sensorBox)"
        appTranslate="sensor.contacts.crud.actions.add"
      ></button>
    </div>
    <div class="col-3">
      <button
        class="btn-secondary"
        (click)="backToSensorDetail(sensorBox.id)"
        appTranslate="sensor.contacts.crud.actions.cancel"
      ></button>
    </div>
  </div>
</div>
