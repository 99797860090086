<div class="primary-panel-large container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 *ngIf="mode == 'add'">Export-Job anlegen</h1>
      <h1 *ngIf="mode == 'edit'">Export-Job bearbeiten</h1>
    </div>
  </div>
  <form *ngIf="crudForm" class="form" [formGroup]="crudForm" (ngSubmit)="saveExportFile()">

    <div class="row ch-margin-top-24">
      <div class="col-1 role">
        <strong>Aktiv</strong>
      </div>
      <div class="col-4 role">
        <div class="form-field" [class.error]="submitted && f.active.invalid">
          <label class="switch">
            <input type="checkbox" (click)="toggleActive()" id="input-active" formControlName="active">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.name.invalid">
          <label for="input-name">Name *</label>
          <input placeholder="" id="input-name" formControlName="name">
          <div *ngIf="submitted && f.name.invalid" class="error-message ch-margin-top-4">Bitte einen Namen für die
            Vorlage wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.description.invalid">
          <label for="input-description"><span>Beschreibung</span></label>
          <textarea placeholder="" id="input-description" formControlName="description" rows="5"></textarea>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.exportDestinationId.invalid">
          <label for="input-exportDestinationId">Ziel</label>
          <select [attr.disabled]="null" id="select-exportDestinationId" formControlName="exportDestinationId">
            <option [ngValue]="null">-</option>
            <option [ngValue]="lookup.id" *ngFor="let lookup of this.destinationList">{{lookup.name}}</option>
          </select>
          <div *ngIf="submitted && f.exportDestinationId.invalid" class="error-message ch-margin-top-4">Bitte einen
            Namen für die
            Vorlage wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-12">
        <div class="form-field" [class.error]="submitted && f.exportTemplateId.invalid">
          <label for="input-exportTemplateId">Export Template</label>
          <select [attr.disabled]="null" id="select-exportTemplateId" formControlName="exportTemplateId">
            <option [ngValue]="null">-</option>
            <option [ngValue]="lookup.id" *ngFor="let lookup of this.templateList">{{lookup.name}}</option>
          </select>
          <div *ngIf="submitted && f.exportTemplateId.invalid" class="error-message ch-margin-top-4">Bitte einen Namen
            für die
            Vorlage wählen!
          </div>
        </div>
      </div>
    </div>

    <div class="row ch-margin-top-24">
      <div class="col-3">
        <button class="btn-primary" *ngIf="!crudForm.disabled">Speichern</button>
      </div>
      <div class="col-3">
        <button class="btn-secondary" (click)="goBack()" *ngIf="!crudForm.disabled">Abbrechen</button>
      </div>
      <div class="col-3">
      </div>
      <div *ngIf="mode === 'edit'" class="col-3">
        <button type="button" class="btn-secondary" (click)="deleteExportFile()"
          *ngIf="!crudForm.disabled">Löschen</button>
      </div>
    </div>
  </form>

  <div class="row ch-margin-top-24">
    <div class="col-12">
      <app-data-table [dataTableOptions]="dataTableOptions" [state]="gridState" [data]="exportFileStations"></app-data-table>
    </div>
  </div>

</div>