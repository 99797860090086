<div
  id="time-control"
  class="leaflet-control-time-slider leaflet-bar leaflet-control"
  [class.hidden]="hidden"
>
  <div
    *ngIf="(zoomLevel$ | async) > 14; else showSlider"
    class="disabled-overlay"
  >
    <p>{{ "map.controls.timeslider.disabled" | translate }}</p>
  </div>
  <ng-template #showSlider>
    <mat-icon
      *ngIf="offset > -120 && !playing; else jumpBackDisabled"
      (click)="jump(-15)"
      >skip_previous</mat-icon
    >
    <mat-icon *ngIf="playing; else playDisabled" (click)="stop.emit(offset)"
      >pause</mat-icon
    >
    <ng-template #playDisabled>
      <mat-icon (click)="start.emit(offset)">play_arrow</mat-icon>
    </ng-template>
    <ng-template #jumpBackDisabled>
      <mat-icon class="disabled">skip_previous</mat-icon>
    </ng-template>
    <mat-slider
      max="0"
      min="-120"
      step="-15"
      [value]="offset"
      (change)="onSliderChange($event)"
    ></mat-slider>
    <span>{{ currentTime }}</span>
    <mat-icon
      *ngIf="offset < 0 && !playing; else jumpForwardDisabled"
      (click)="jump(15)"
      >skip_next</mat-icon
    >
    <ng-template #jumpForwardDisabled>
      <mat-icon class="disabled">skip_next</mat-icon>
    </ng-template>
  </ng-template>
</div>
