import { Injectable } from '@angular/core';
import {
  AlarmChannelListResult,
  AlarmChannelResult,
} from '../../models/apiResults';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GuiState } from '../dashboard/gui.state';
import { AlarmChannel } from './models/alarmChannel';

@Injectable({
  providedIn: 'root',
})
export class AlarmChannelService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getList(): Promise<AlarmChannel[]> {
    let id = this.store.selectSnapshot(GuiState.selectedCustomer).id;

    return this.http
      .get<AlarmChannelListResult>(
        `${environment.dashboardApiUrl}/alarmChannel/list?customerId=` + id ,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  getDetail(id: number): Promise<AlarmChannel> {
    return this.http
      .get<AlarmChannelResult>(
        `${environment.dashboardApiUrl}/alarmChannel/`+id+`/detail`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  saveDetail(channel: AlarmChannel): Promise<AlarmChannel> {
	let customerId = this.store.selectSnapshot(GuiState.selectedCustomer).id;
	channel.customer= {
		id:customerId,
		name: '' 
	}
    return this.http
      .post<AlarmChannelResult>(
        `${environment.dashboardApiUrl}/alarmChannel/detail`,
        channel,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

  delete(id: number): Promise<AlarmChannel> {
    return this.http
      .delete<AlarmChannelResult>(
        `${environment.dashboardApiUrl}/alarmChannel/` + id+`/detail`,
      )
      .pipe(
        map((result) => {
          if (!result.ok) {
            throw new Error(result.errorCode);
          }
          return result.data;
        }),
      )
      .toPromise();
  }

}
