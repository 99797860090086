import { Component, Input, OnInit } from '@angular/core';

export const sensorTypeIconMapping = {
  temperature: 'device_thermostat',
  windChill: 'device_thermostat',
  humidity: 'opacity',
  dewpoint: 'water_drop',
  pressure: 'compress',
  precipDiff: 'thunderstorm',
  precipPerHour: 'thunderstorm',
  precipAbs: 'thunderstorm',
};

@Component({
  selector: 'app-weather-station-sensor',
  templateUrl: './weather-station-sensor.component.html',
  styleUrls: ['./weather-station-sensor.component.scss'],
})
export class WeatherStationSensorComponent {
  @Input() sensorName: string;
  @Input() sensorData: {
    currentDataValue: number;
    unit: string;
  };

  sensorTypeIconMapping = sensorTypeIconMapping;
}
