import { Component, Input } from '@angular/core';
import { SensorBox } from '../../../../../../state/noysee/models/sensorBox';
import { sensorTypeIconMapping } from './weather-station-sensor/weather-station-sensor.component';
import { Router } from '@angular/router';

const sensorViewOrder = Object.keys(sensorTypeIconMapping);

@Component({
  selector: 'app-weather-station',
  templateUrl: './weather-station.component.html',
  styleUrls: ['./weather-station.component.scss'],
})
export class WeatherStationComponent {
  @Input()
  sensorBox: SensorBox;

  constructor(private router: Router) {}

  navigateToSensor(name: string) {
    this.router.navigate(
      ['/dashboard', 'sensors', 'detail', this.sensorBox.id, 'history'],
      {
        queryParams: { sensor: name },
      },
    );
  }

  getAvailableSensors(): string[] {
    return Object.keys(this.sensorBox.sensors)
      .filter(
        (key) =>
          // Filter out wind related sensors, since they will be displayed separately
          key !== 'windDirection' && key !== 'windSpeed' && key !== 'windAvg',
      )
      .sort(
        // Sort based on predefined order
        (a, b) => sensorViewOrder.indexOf(a) - sensorViewOrder.indexOf(b),
      );
  }
}
