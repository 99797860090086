import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationContextService {
  // Current entity being edited
  _selectedUserId = 0;
  _selectedTriggerId = '';

  // tells whether the data sync is currently activated
  _isSyncActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get selectedUserId(): number {
    return this._selectedUserId;
  }

  public set selectedUserId(id: number) {
    this._selectedUserId = id;
  }

  public get selectedTriggerId(): string {
    return this._selectedTriggerId;
  }

  public set selectedTriggerId(name) {
    this._selectedTriggerId = name;
  }

  public get isSyncActiveValue(): boolean {
    return this._isSyncActive.value;
  }

  public set isSyncActiveValue(isSyncActive: boolean) {
    // DEBUG
    isSyncActive
      ? console.log('Sync aktiviert')
      : console.log('Sync deaktiviert');
    this._isSyncActive.next(isSyncActive);
  }

  public get isSyncActive(): Observable<boolean> {
    return this._isSyncActive.asObservable();
  }
}
