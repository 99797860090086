import { GridOptions } from 'ag-grid-community';
import { TranslationHeaderComponent } from '../cell-renderer/translation.header-component';

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  suppressCellFocus: true,
  defaultColDef: {
    flex: 1,
    filter: true,
    sortable: true,
    floatingFilter: true,
    filterParams: {
      maxNumConditions: 1,
    },
    suppressMenu: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellStyle: {
      wordBreak: 'normal',
      lineHeight: 1.5,
      display: 'flex',
      alignItems: 'center',
    },
    headerComponent: TranslationHeaderComponent,
    // TODO: Reactivate this when AgGrid can be updated to version 30
    // useValueFormatterForExport: true,
  },
  pagination: true,
  paginationPageSize: 13,
  suppressPaginationPanel: true,
};
