import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseCellRenderer } from '../util/interfaces';

@Component({
  template: ` {{ params.valueFormatted || params.value | translate }} `,
})
export class TranslateCellRenderer
  implements ICellRendererAngularComp, BaseCellRenderer
{
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
