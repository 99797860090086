import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseColumnComponent} from '../base-column.component';
import {v4 as uuid} from 'uuid';
import {CellEditorColumnDef} from '../data-table.model';

@Component({
  selector: 'app-column-editor',
  templateUrl: './column-editor.component.html',
  styleUrls: ['./column-editor.component.scss']
})
export class ColumnEditorComponent extends BaseColumnComponent {

  value: any;

  @Input()
  public set data(data: any) {
    this._data = data;
    if (this.config.valueTransform) {
      this.value = this.config.valueTransform(data) || '';
    } else {
      this.value = data[this.config.key] || '';
    }
    this.editorForm.controls[this.fieldName].setValue(this.value);
  }

  public get data(): any {
    return this._data;
  }

  protected _data: any;


  @Input()
  config: CellEditorColumnDef<any>;

  @Output() dataChange = new EventEmitter<any>();

  fieldName = uuid.v4();

  editorForm: FormGroup = new FormGroup({});


  constructor() {
    super();
    this.editorForm.addControl(this.fieldName, new FormControl(this.value));
  }

  get f() {
    return this.editorForm.controls;
  }

  submitValue() {
    const colKey: string = this.config.key.toString();
    const rowData = {
      ...this.data
    };
    rowData[colKey] = this.editorForm.value[this.fieldName];
    this.dataChange.emit(rowData);
  }
}
