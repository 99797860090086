export const EN = {
  statistic: {
    headerLabel: 'Statistics',
    charts: {
      selection: {
        placeholder: 'Select Sensor...',
        add: 'Add Sensor',
      },
      header: {
        tooltips: {
          zoom: 'Mode: Zoom',
          marker: 'Mode: Place Marker',
          remove: 'Remove chart',
        },
      },
      noData: 'No data available',
    },
    table: {
      headline: 'Marker',
      actions: {
        delete: 'Delete Marker',
      },
      columns: {
        id: 'ID',
        date: 'Date',
        value: 'Value',
        timeDifference: 'Time Difference',
        name: 'Sensor Name',
        global: 'Global',
      },
    },
  },
};
