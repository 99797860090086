import { Component, Input } from '@angular/core';
import { WeatherService } from 'src/app/state/dashboard/weather.service';

@Component({
  selector: 'app-legacy-weather',
  templateUrl: './legacy-weather.component.html',
  styleUrls: ['./legacy-weather.component.scss'],
})
export class LegacyWeatherComponent {
  @Input()
  isWeatherStation!: boolean;

  weatherData: any;
  weatherCurrent: any;
  weatherToday: any = [];
  weatherNextDays: any;

  date: Date = new Date();
  dateString: string = this.date.toLocaleDateString('de-DE', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  timezone: string = this.date
    .toString()
    .match(/\(([A-Z\s].*)\)/)[1]
    .replace(/[^A-Z]/g, '');

  weatherView: 'DAY' | 'WEEK' = 'WEEK';
  iconUrlPrefix: string = 'https://openweathermap.org/img/wn/';

  constructor(private weatherService: WeatherService) {
    this._updateWeatherData();
  }

  private async _updateWeatherData() {
    this.weatherData = await this.weatherService.getWeatherData();

    if (!this.weatherData) {
      return;
    }

    this.weatherData['hourly'].slice(0, 24).forEach((item, index) => {
      if (index % 2 === 0) {
        const itemDate = new Date(item.dt * 1000);
        if (itemDate.getHours() === new Date().getHours()) {
          item['current'] = ' current';
        } else {
          item['current'] = '';
        }

        item.temp = Math.floor(item.temp);

        item.dt = itemDate.toLocaleDateString('de-DE', {
          hour: 'numeric',
          minute: 'numeric',
        });
        item.dt = item.dt.substr(item.dt.indexOf(' '));
        this.weatherToday.push(item);
      }
    });

    this.weatherNextDays = this.weatherData['daily'];
    this.weatherNextDays.forEach((item) => {
      const itemDate = new Date(item.dt * 1000);
      if (itemDate.getDate() === new Date().getDate()) {
        item['current'] = ' current';
      } else {
        item['current'] = '';
      }

      item.temp['avg'] = Math.floor((item.temp.max + item.temp.min) / 2);

      item.dt = itemDate.toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'numeric',
      });
    });

    this.weatherCurrent = this.weatherData['current'];

    this.weatherCurrent.temp = Math.floor(this.weatherCurrent.temp);
    this.weatherCurrent.wind_speed = Math.floor(this.weatherCurrent.wind_speed);
    this.weatherCurrent['tempMin'] = Math.floor(
      this.weatherNextDays[0].temp.min
    );
    this.weatherCurrent['tempMax'] = Math.floor(
      this.weatherNextDays[0].temp.max
    );
  }

  navigateForward() {
    let first;
    if (this.weatherView === 'DAY') {
      first = this.weatherToday.shift();
      this.weatherToday.push(first);
    } else {
      first = this.weatherNextDays.shift();
      this.weatherNextDays.push(first);
    }
  }

  navigateBackward() {
    let last;
    if (this.weatherView === 'DAY') {
      last = this.weatherToday.pop();
      this.weatherToday.unshift(last);
    } else {
      last = this.weatherNextDays.pop();
      this.weatherNextDays.unshift(last);
    }
  }
}
