<ng-container [ngSwitch]="!!config.link || !!config.actionFn">
  <ng-container *ngSwitchCase="false"><p [ngClass]="{wrap: config.wrap, 'long-text': value.length > 40}" [innerHTML]="value | translate"></p></ng-container>
  <ng-container *ngSwitchCase="true">
    <ng-container *ngIf="!!config.link">
      <a [routerLink]="config.link(data)">{{ value | translate}}</a>
    </ng-container>
    <ng-container *ngIf="!!config.actionFn">
      <a (click)="config.actionFn(data)">{{ value | translate}}</a>
    </ng-container>
  </ng-container>
</ng-container>

